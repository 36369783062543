import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchSynonymName = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_CHECKLIST_SYNONYM, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_CHECKLIST_SYNONYM_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const SynonymName = () => (
  <PageWithTable
    fetchExport={fetchExport}
    fetchData={(args) => fetchSynonymName(args)}
    defaultSortField="SynonymScientificName"
    titleKey="Species:ChecklistSynonym"
    descriptionKey="Species:ChecklistSynonymDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1500 }}
  />
);

export default SynonymName;
