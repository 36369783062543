import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SurveyResultSelect = (props) => {
  const { surveyResultGroupsData, surveyResultLoading, updateSurveyResult } =
    useStore();

  useEffect(() => {
    if (!surveyResultGroupsData.length && !surveyResultLoading)
      updateSurveyResult();
  }, []);

  return (
    <Select
      props={props}
      width={150}
      data={surveyResultGroupsData}
      loading={surveyResultLoading}
    />
  );
};

export default SurveyResultSelect;
