import { useTranslation } from 'react-i18next';

import { POSITIVE_OPTION, NEGATIVE_OPTION } from '../../../../global/constants';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:ownerPermission1'),
      dataIndex: 'ownerPermission1',
      key: 'ownerPermission1',
      sorter: true,
    },
    {
      title: t('table:description'),
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: t('table:display'),
      dataIndex: 'display',
      key: 'display',
      sorter: true,
      render: (text) => (text ? POSITIVE_OPTION : NEGATIVE_OPTION),
    },
  ];
};
