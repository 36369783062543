import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const AspectsSelect = (props) => {
  const { aspectsData, aspectsLoading, updateAspects } = useStore();

  useEffect(() => {
    if (!aspectsData.length && !aspectsLoading) updateAspects();
  }, []);

  return <Select props={props} data={aspectsData} loading={aspectsLoading} />;
};

export default AspectsSelect;
