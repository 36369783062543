export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
];
