import React from 'react';
import { Form, InputNumber, Input } from 'antd';

export const getRandomuserParams = (params) => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

export const isCellEditing = (
  record,
  editingKey,
  data,
  setEditingKey,
  isRefetchDone,
  rowKey,
  rowKeyFunc
) => {
  const isRowInData = data
    ? data.find((el) => (rowKey ? rowKeyFunc(el) : el.eokey) === editingKey)
    : false;

  if ((!isRowInData && setEditingKey) || isRefetchDone) {
    setEditingKey('');
  }
  return (rowKey || record.eokey) === editingKey;
};

export const handleTableChange = (pagination, filters, sorter, fetch) => {
  fetch({
    sortField: sorter.field,
    sortOrder: sorter.order,
    pagination,
    ...filters,
  });
};

export const edit = (record, initialState, form, setEditingKey, rowKey) => {
  form.setFieldsValue({
    ...initialState,
    ...record,
  });

  setEditingKey(rowKey || record.eokey);
};

export const editCell = (inputType, dataIndex, cellsConfig, record) => {
  const InputNode = inputType === 'number' ? InputNumber : Input;
  
  const cell = cellsConfig.find(({ name }) => name === dataIndex);

  if (cell) {
    const { input, addRecordData, ...args } = cell;
    
    const CustomInput = input || InputNode;

    return (
      <Form.Item
        style={{
          margin: 0,
        }}
        {...args}
      >
        {addRecordData ? <CustomInput record={record} /> : <CustomInput />}
      </Form.Item>
    );
  }

  return (
    <Form.Item
      name={dataIndex}
      style={{
        margin: 0,
      }}
      rules={[
        {
          required: true,
          message: `Please Input ${dataIndex}!`,
        },
      ]}
    >
      <InputNode />
    </Form.Item>
  );
};
