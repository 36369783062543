import { ENDPOINT_URLS } from '../../../api';
// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';

export const logInRequest = ({ email, password }) =>
  APIFetch(
    ENDPOINT_URLS.AUTH_SIGN_IN,
    {
      method: 'post',
      body: JSON.stringify({ email, password }),
    },
    { requiresAuth: false }
  );

export const logOutRequest = ({ accessToken, refreshToken }) =>
  APIFetch(ENDPOINT_URLS.AUTH_SIGN_OUT, {
    method: 'delete',
    body: JSON.stringify({
      accessToken,
      refreshToken,
    }),
  });

export const acceptAgreementRequest = (data) =>
  APIFetch(ENDPOINT_URLS.ACCEPT_AGREEMENT, {
    method: 'put',
    body: JSON.stringify(data),
  });

export const refreshTokenRequest = (oldToken) =>
  APIFetch(ENDPOINT_URLS.REFRESH_TOKEN, {
    method: 'post',
    body: JSON.stringify(oldToken),
  });
