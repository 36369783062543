import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useStore } from '../../../../global/stores/store/StoreContext';
import {
  getParentPathname,
  openNotificationWithIcon,
  validateMessages,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchUpdate = (key, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_FIELD_DATA_MANUAL_EDIT(key), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_MANUAL_EDIT(key), {
        method: 'get',
      })
    : null;

const fetchEOsWithDataErrors = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'SpeciesName' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'Eokey', value: `${key}` },
          ],
        }),
      })
    : {};

const FieldDataManualEditForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key: ffkey } = useParams();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [initialValues, setInitialValues] = useState(null);

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(ffkey, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: parentData, refetch: parentDataRefetch } = useAPI(
    fetchEOsWithDataErrors,
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  const { data: parentChildrenData } = useAPI(() => fetch(ffkey), {
    initialState: { loading: false },
  });

  useEffect(() => {
    if (parentData?.items.length) {
      const {
        state: itemState,
        speciesName,
        eonumber,
        subpopulation,
      } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `Edit ${itemState} ${speciesName} ${eonumber} ${subpopulation}`,
        },
        search,
      });

      form.setFieldsValue({
        eokey: `${itemState} ${speciesName} ${eonumber} ${subpopulation}`,
      });
    }
  }, [parentData]);

  const {
    moistureRegimesData,
    updateMoistureRegimes,
    lightsData,
    updateLights,
    aspectsData,
    updateAspects,
    topoPositionsData,
    updateTopoPositions,
  } = useStore();

  useEffect(() => {
    if (!moistureRegimesData.length) updateMoistureRegimes();
    if (!lightsData.length) updateLights();
    if (!aspectsData.length) updateAspects();
    if (!topoPositionsData.length) updateTopoPositions();
  }, []);

  useEffect(() => {
    if (parentChildrenData?.ffkey) {
      const { light, moistureRegime, topoPosition, aspect, ...other } =
        parentChildrenData;

      const date = parentChildrenData.surveyDate
      setInitialValues({
        ...other,
        light: light || lightsData[0].itemKey,
        aspect: aspect || aspectsData[0].itemKey,
        topoPosition: topoPosition || topoPositionsData[0].itemKey,
        moistureRegime: moistureRegime || moistureRegimesData[0].itemKey,
        surveyDate: moment().set({'year': date.slice(0,4), 'month': +date.slice(5,7) - 1, 'date': date.slice(8,10)})
      });
    }
  }, [parentChildrenData]);

  useEffect(() => {
    if (parentChildrenData?.eokey) {
      parentDataRefetch(parentChildrenData.eokey);
    }
  }, [parentChildrenData]);

  const onFinish = (values) => {
    const { fileLink, folder, fullFilename, eokey } = parentChildrenData;

    refetchUpdate({
      ...values,
      fileLink,
      folder,
      fullFilename,
      eokey,
    });
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData?.eokey) {
          type = 'success';
          message = 'Data was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update data';
        }
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        const parentPathname = getParentPathname(pathname);
        history.replace(parentPathname);

        history.replace(parentPathname);
      }
    }
  }, [updateData]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`ElementOccurrence:FieldDataManualEditForm`)}
      </Title>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="FieldDataManualEditForm"
              onFinish={onFinish}
              validateTrigger={['onBlur', 'onChange']}
              initialValues={initialValues}
              validateMessages={validateMessages}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  key={uuidv4()}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateLoading}
                >
                  {t('form:update')}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default FieldDataManualEditForm;
