export const DATA_YES_NO_NONE = [
  { itemKey: 'NONE', item: 'NONE' },
  { itemKey: 'Yes', item: 'Yes' },
  { itemKey: 'No', item: 'No' },
];

export const DATA_YES_NO_WITHOUT_NONE = [
  { itemKey: 'Yes', item: 'Yes' },
  { itemKey: 'No', item: 'No' },
];

export const DATA_FT2_M2 = [
  { itemKey: 'Ft2', item: 'Ft2' },
  { itemKey: 'M2', item: 'M2' },
];

export const DATA_FT_M = [
  { itemKey: 'Ft', item: 'Ft' },
  { itemKey: 'M', item: 'M' },
];

export const DATA_PERCENT = [
  { itemKey: 'NONE', item: 'NONE' },
  { itemKey: '0-25%', item: '0-25%' },
  { itemKey: '25-50%', item: '25-50%' },
  {
    itemKey: '50-75%',
    item: '50-75%',
  },
  { itemKey: '75-100%', item: '75-100%' },
];

export const DATA_FIELD_TYPE = [
  { itemKey: 'FieldFormReviewed', item: 'FieldFormReviewed' },
  { itemKey: 'FieldFormSPROUT', item: 'FieldFormSPROUT' },
];
