import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:survey'),
      width: 100,
      dataIndex: 'survey',
      key: 'survey',
      sorter: true,
    },
    {
      title: t('table:collectSeed'),
      width: 120,
      dataIndex: 'collectSeed',
      key: 'collectSeed',
      sorter: true,
    },
    {
      title: t('table:nextSurveyYear'),
      dataIndex: 'nextSurveyYear',
      key: 'nextSurveyYear',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:nextSeedYear'),
      dataIndex: 'nextSeedYear',
      key: 'nextSeedYear',
      sorter: true,
      width: 120,
      render: (text) => text || '',
    },
    {
      title: t('table:permissionStatus'),
      dataIndex: 'permissionStatus',
      key: 'permissionStatus',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:firstFlower'),
      dataIndex: 'firstFlowerDate',
      key: 'firstFlowerDate',
      sorter: true,
      width: 90,
      render: (text) => (text ? getMomentDate(text).format('MMMM D') : ''),
    },
    {
      title: t('table:lastFlower'),
      dataIndex: 'lastFlowerDate',
      key: 'lastFlowerDate',
      sorter: true,
      width: 90,
      render: (text) => (text ? getMomentDate(text).format('MMMM D') : ''),
    },
    {
      title: t('table:numberOwners'),
      dataIndex: 'numberOwners',
      key: 'numberOwners',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:latitude'),
      dataIndex: 'latitude',
      key: 'latitude',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:longitude'),
      dataIndex: 'longitude',
      key: 'longitude',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:locationPrecision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      width: 90,
      key: 'eotype',
      sorter: true,
    },
  ];
};
