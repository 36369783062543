import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { paramsForBE } from '../../../utils/general';
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';
import { action } from '../../../shared/EditableTable/components/Action';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_HISTORY, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_HISTORY_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const OwnerHistory = () => {
  const { t } = useTranslation();

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:ownerComment'),
      key: 'operation',
      fixed: 'left',
      width: 130,
      renderFunction: (_, record) => actionContent(record),
    }),
    ...allColumns,
  ];

  return (
    <PageWithTable
      fetchData={fetch}
      fetchExport={fetchExport}
      defaultSortField={[
        'LastName',
        'FirstName',
        'Organization',
        'SubOrganization',
        'ContactDate',
      ]}
      titleKey="Owner:OwnerHistory"
      descriptionKey="Owner:OwnerHistoryDescription"
      paramsForBE={paramsForBE}
      beSearchConfig={beSearchConfig}
      searchForm={SearchForm}
      columns={columnsForTable}
      rowKey={(record) => record.eokey}
      scroll={{ x: 1700 }}
    />
  );
};

export default OwnerHistory;
