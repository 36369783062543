import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const Section = ({
  config = [],
  title,
  children,
  pageDescription = false,
  onlyTable = false,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <>
      {!onlyTable && <Title level={2}>{title}</Title>}
      {children}
      {config.map(({ tableTitle, createContent, rowsCount }) => (
        <div className={styles.root} key={`${tableTitle}-${rowsCount}`}>
          <Card>
            <Title level={3}>{t(tableTitle)}</Title>
            {!!pageDescription && (
              <p className={styles.description}>{t(pageDescription)}</p>
            )}
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableHead}>{t('table:page')}</th>
                  <th className={styles.tableHead}>{t('table:description')}</th>
                </tr>
              </thead>
              <tbody>
                {Array(rowsCount)
                  .fill()
                  .map((_, index) => {
                    const link = t(createContent(index).page)
                      .split(' ')
                      .join('')
                      .replace('PCV/TF', 'PCV&TF');
                    const finalLink = [pathname, link].join('/');
                    return (
                      <tr key={t(createContent(index).page)}>
                        <td className={styles.cell}>
                          <Link to={`${finalLink}`}>
                            {t(createContent(index).page)}
                          </Link>
                        </td>
                        <td className={styles.secondSale}>
                          {t(createContent(index).description)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Card>
        </div>
      ))}
    </>
  );
};

export default Section;
