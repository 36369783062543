import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popconfirm, Button } from 'antd';
import classNames from 'clsx';

// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
// eslint-disable-next-line import/no-cycle
import { openNotificationWithIcon } from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
// eslint-disable-next-line import/no-cycle
import { useAuth } from '../../../../global/stores/auth';
import { roleTypes } from '../../../../utils/roles';
// eslint-disable-next-line import/no-cycle
import PageWithSimpleTableAndSearch from '../../../pages/PageWithSimpleTableAndSearch';

import { columns } from './TableColumns';

import classes from '../../../styles.module.scss';

const fetchGetElementOccurrenceOwner = (key, currentOwner) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_OWNER(key, currentOwner), {
        method: 'get',
      })
    : [];

const fetchDeleteElementOccurrenceOwner = (key, ownerKey) =>
  key
    ? APIFetch(ENDPOINT_URLS.DELETE_ELEMENT_OCC_OWNER(key, ownerKey), {
        method: 'delete',
      })
    : null;

const fetchElementOccurrenceOwner = (eokey) =>
  eokey
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [{ orderOption: 'Ascending', propertyName: 'State' }],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'Eokey', value: eokey },
          ],
        }),
      })
    : {};

export const ElementOccurrenceOwnerByKey = () => {
  const { t } = useTranslation();
  const { eokey } = useParams();
  const { pathname, search: searchParams } = useLocation();
  const query = new URLSearchParams(searchParams);
  const currentOwner = query.getAll('currentOwner')[0];
  const history = useHistory();
  const { user } = useAuth();
  const role = user?.role;

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const { data, loading, refetch } = useAPI(
    (ownerKey) => fetchDeleteElementOccurrenceOwner(eokey, ownerKey),
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  const { data: parentData } = useAPI(
    () => fetchElementOccurrenceOwner(eokey),
    {
      initialState: { loading: false },
    }
  );

  useEffect(() => {
    if (parentData?.items.length) {
      const {
        state: itemState,
        speciesName,
        eonumber,
        subpopulation,
      } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `${itemState} ${speciesName} ${eonumber} ${subpopulation}`,
        },
        search: `currentOwner=${currentOwner}`
      });
    }
  }, [parentData]);

  const onClickEdit = (item) => {
    history.push({
      pathname: `/ElementOccurrence/ElementOccurrenceOwner/${eokey}/${item.ownerKey}`,
      search: `?currentOwner=${item.currentOwner}`
    });
  };

  const onConfirm = (ownerKey) => {
    refetch(ownerKey);
  };

  useEffect(() => {
    if (data) {
      if (data?.eokey) {
        openNotificationWithIcon(
          'success',
          'ElementOccurrenceOwner was deleted successfully'
        );

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon(
          'error',
          'Failed delete ElementOccurrenceOwner'
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 130,
      render: (_, record) => (
        <>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => onConfirm(record.ownerKey)}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              style={{
                marginRight: 8,
              }}
            >
              {t('table:delete')}
            </a>
          </Popconfirm>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a onClick={() => onClickEdit(record)}>{t('table:edit')}</a>
        </>
      ),
    },
    ...staticColumns,
  ];

  const createNewOwner = () => {
    history.push({
      pathname: `/ElementOccurrence/ElementOccurrenceOwner/${eokey}/Create`,
    });
  };

  return (
    <PageWithSimpleTableAndSearch
      fetchData={() => fetchGetElementOccurrenceOwner(eokey, currentOwner)}
      defaultSortField="eokey"
      titleKey="ElementOccurrence:ElementOccurrenceOwner"
      columns={role === roleTypes.Heritage ? staticColumns : allColumns}
      scroll={{ x: 1300 }}
      isForceRefetch={isForceRefetch}
      forceLoading={loading}
      additionalMessage
    >
      {role === roleTypes.Administrator ? (
        <div className={classNames(classes.content)}>
          <Button onClick={createNewOwner}>Add New Owner</Button>
        </div>
      ) : null}
      {role === roleTypes.Heritage ? (
        <p className={classNames(classes.title)}>
          {t('No data returned')}
        </p>
      ) : null}
    </PageWithSimpleTableAndSearch>
  );
};
