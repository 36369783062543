import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
      width: 250,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      width: 120,
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      width: 140,
      sorter: true,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      width: 200,
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: true,
    },
  ];
};

export const expandableColumns = (isPastSelect) => {
  const { t } = useTranslation();

  return isPastSelect
    ? [
      {
        title: t('table:owner'),
        dataIndex: 'owner',
        key: 'owner',
      },
      {
        title: t('table:eokey'),
        dataIndex: 'elementOccurrence',
        key: 'elementOccurrence',
      },
      {
        title: t('table:assessorLot'),
        dataIndex: 'assessorLot',
        key: 'assessorLot',
      },
      {
        title: t('table:sourceOwner'),
        dataIndex: 'sourceOwner',
        key: 'sourceOwner',
      },
    ]
    : [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
    },
    {
      title: t('table:ownerNotes'),
      dataIndex: 'ownerNotes',
      key: 'ownerNotes',
    },
    {
      title: t('table:currentOwner'),
      dataIndex: 'currentOwner',
      key: 'currentOwner',
      render: (text) => (text ? 'Yes' : 'No'),
    },
    ];
};
