import React from 'react';
import { Checkbox, DatePicker } from 'antd';
import moment from 'moment';

import StatesSelect from '../../../shared/components/select/StatesSelect';
import { getMomentDate } from '../../../utils/general';

export const currentYear = +moment().format('YYYY');

const disabledDate = (current) => {
  const tooEarly = current < moment().set('year', 1970).startOf('year');

  return tooEarly;
};

export const formConfig = [
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'nextSurveyYear',
    label: 'Year',
    input: ({ value, ...props }) => <DatePicker
      {...props}
      style={{
        width: '150px',
      }}
      picker='year'
      value={value ? getMomentDate(value) : ''}
      format='YYYY'
      disabledDate={disabledDate}
    />,
    operatortype: 'Equals',
    previousSearchBlockConnectionOperator: 'And',
  },
  {
    name: 'LeadSurveyor',
    label: 'EOs that have a lead surveyor',
    operatortype: 'GreaterThan',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props}/>,
    rules: [
      {
        type: 'boolean',
      },
    ],
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Equals',
    propertyName: 'nextSurveyYear',
    previousSearchBlockConnectionOperator: 'And',
  },
  {
    operatortype: 'GreaterThan',
    propertyName: 'LeadSurveyor',
    previousSearchBlockConnectionOperator: 'And'
  }
];
