import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      width: 40,
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      width: 40,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 40,
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      width: 60,
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      width: 70,
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:assocSpecies'),
      dataIndex: 'assocSpecies',
      key: 'assocSpecies',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:genSpeUsed'),
      dataIndex: 'genSpeUsed',
      key: 'genSpeUsed',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:associatedSpecies'),
      dataIndex: 'associatedSpecies',
      key: 'associatedSpecies',
      width: 200,
      sorter: true,
    },
  ];
};
