import React from 'react';
import { InputNumber } from 'antd';

import SurveyorTypeSelect from '../../../shared/components/select/SurveyorTypesSelect';

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: 180,
    }}
  />
);

export const searchFormConfig = [
  {
    name: 'latitude',
    label: 'Latitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'number',
        min: 0,
        max: 47.5,
      },
    ],
  },
  {
    name: 'longitude',
    label: 'Longitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'number',
        min: -74,
        max: 0,
      },
    ],
  },
  {
    name: 'SurveyorType',
    label: 'Surveyor Type',
    input: (props) => <SurveyorTypeSelect {...props} withAll />,
    operatortype: 'Contains',
  },
  {
    name: 'distance',
    label: 'Maximum distance from location(miles) ',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'integer',
        min: 1,
        max: 100,
      },
    ],
  },
];
