export const cleanNamesError = ['firstName', 'lastName'].map((name) => ({
  name,
  errors: null,
}));

export const cleanOrganizationsError = ['organization', 'subOrganization'].map(
  (name) => ({
    name,
    errors: null,
  })
);

export const subOrganizationError = [
  {
    name: 'subOrganization',
    errors: ['Suborganization is needed. Please enter it or enter NONE'],
  },
];
export const organizationError = [
  {
    name: 'organization',
    errors: ['Organization is needed. Please enter it or enter NONE'],
  },
];
export const lastNameError = [
  {
    name: 'lastName',
    errors: ['Last Name is needed. Please enter it or enter NONE'],
  },
];
export const firstNameError = [
  {
    name: 'firstName',
    errors: ['First Name is needed. Please enter it or enter NONE'],
  },
];
export const lastNameLengthEError = [
  {
    name: 'lastName',
    errors: ['Length of Last Name should be less than 50 symbols'],
  },
];
export const firstNameLengthEError = [
  {
    name: 'firstName',
    errors: ['Length of First Name should be less than 50 symbols'],
  },
];
export const organizationLengthError = [
  {
    name: 'organization',
    errors: ['Length of Organization should be less than 100 symbols'],
  },
];
export const subOrganizationLengthError = [
  {
    name: 'subOrganization',
    errors: ['Length of Suborganization should be less than 50 symbols'],
  },
];
export const emailError = [
  {
    name: 'email',
    errors: ['Length of Email should be less than 80 symbols'],
  },
];
