import { ENDPOINT_URLS } from '../../api';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';

export const fetchStates = () =>
  APIFetch(ENDPOINT_URLS.GET_STATE, {
    method: 'get',
  });

export const fetchDaysAvailableToSurvey = () =>
  APIFetch(ENDPOINT_URLS.GET_DAYS_AVAILABLE_TO_SURVEY, {
    method: 'get',
  });

export const fetchStatesForSurveyor = () =>
  APIFetch(ENDPOINT_URLS.GET_STATE_FOR_SURVEYOR, {
    method: 'get',
  });

export const fetchStatesForHeritage = () =>
  APIFetch(ENDPOINT_URLS.GET_STATE_FOR_HERITAGE, {
    method: 'get',
  });

export const fetchEOStatuses = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_EO_STATUS, {
        method: 'get',
      })
    : [];

export const fetchDivisions = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_DIVISION, {
        method: 'get',
      })
    : [];

export const fetchSurveyResults = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_RESULTS, {
        method: 'get',
      })
    : [];

export const fetchTopoPositions = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_TOPO_POSITIONS, {
        method: 'get',
      })
    : [];

export const fetchAspects = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_ASPECTS, {
        method: 'get',
      })
    : [];

export const fetchLights = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_LIGHTS, {
        method: 'get',
      })
    : [];

export const fetchMoistureRegimes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_MOISTURE_REGIMES, {
        method: 'get',
      })
    : [];

export const fetchExistingLights = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_EXCITING_LIGHTS, {
        method: 'get',
      })
    : [];

export const fetchExistingMoistureRegimes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_EXCITING_MOISTURE_REGIMES, {
        method: 'get',
      })
    : [];

export const fetchSurveyorTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYOR_TYPE, {
        method: 'get',
      })
    : [];

export const fetchSurveyorTypesExceptAdmin = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYOR_TYPE_EXCEPT, {
        method: 'get',
      })
    : [];

export const fetchSurveyors = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS, {
        method: 'get',
      })
    : [];

export const fetchTopics = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_TOPICS, {
        method: 'get',
      })
    : [];

export const fetchManagementTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_MANAGEMENT_TYPES, {
        method: 'get',
      })
    : [];

export const fetchNonNoThreatDisturbances = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_NON_NO_THREAT_DISTURBANCES, {
        method: 'get',
      })
    : [];

export const fetchOwners = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_OWNERS, {
        method: 'get',
      })
    : [];

export const fetchCharacterGroups = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_CHARACTER_GROUPS, {
        method: 'get',
      })
    : [];

export const fetchSourceOwners = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SOURCE_OWNERS, {
        method: 'get',
      })
    : [];

export const fetchSpeciesNames = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_NAMES, {
        method: 'get',
      })
    : [];

export const fetchCounties = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_COUNTIES, {
        method: 'get',
      })
    : [];

export const fetchLocationPrecisions = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_LOCATION_PRECISIONS, {
        method: 'get',
      })
    : [];

export const fetchPermitTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_PERMIT_TYPES, {
        method: 'get',
      })
    : [];

export const fetchNonStayOffOwnerPermissions = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_NON_STAY_OFF_OWNER_PERMISSION, {
        method: 'get',
      })
    : [];

export const fetchOwnerPermissions = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_PERMISSION, {
        method: 'get',
      })
    : [];

export const fetchContactMethods = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_CONTACT_METHOD, {
        method: 'get',
      })
    : [];

export const fetchSurveyResult = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_NON_NONE_SURVEY_RESULTS, {
        method: 'get',
      })
    : [];

export const fetchSurveyResultStates = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_RESULTS_STATES_PCVTF, {
        method: 'get',
      })
    : [];

export const fetchPhenologyTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_PHENOLOGY_TYPE, {
        method: 'get',
      })
    : [];

export const fetchCountPrecisions = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_COUNT_PRECISIONS_EXCEPT_NONE, {
        method: 'get',
      })
    : [];

export const fetchSources = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SOURCES, {
        method: 'get',
      })
    : [];

export const fetchFieldDataTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_TYPES, {
        method: 'get',
      })
    : [];

export const fetchSlopes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SLOPES, {
        method: 'get',
      })
    : [];

export const fetchRoles = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_ROLES, {
        method: 'get',
      })
    : [];

export const fetchLocationPrecisionsForFieldEnter = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_LOC_PRECISIONS, {
        method: 'get',
      })
    : [];

export const fetchOutreachOrganizationTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_OUTREACH_ORGANIZATION_TYPES, {
        method: 'get',
      })
    : [];

export const fetchMgtNotesAndEODataSources = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_MGT_NOTES_AND_EO_DATA_SOURCES, {
        method: 'get',
      })
    : [];

export const fetchAllMgtFieldDataTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_ALL_MGT_FIELD_DATA_TYPES, {
        method: 'get',
      })
    : [];

export const fetchAllOwnerTypes = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_ALL_OWNER_TYPES, {
        method: 'get',
      })
    : [];

export const fetchAllOwnerStatuses = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_ALL_OWNER_STATUSES, {
        method: 'get',
      })
    : [];

export const fetchAllReportMethods = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_ALL_REPORT_METHODS, {
        method: 'get',
      })
    : [];

export const fetchTranscribers = (isReset = false) =>
  !isReset
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_TRANSCRIBERS, {
        method: 'get',
      })
    : [];
