import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      width: 60,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      width: 100,
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      width: 60,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:year'),
      width: 60,
      dataIndex: 'year',
      key: 'year',
      sorter: true,
    },
    {
      title: t('table:town'),
      width: 100,
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      width: 100,
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:eoid'),
      width: 70,
      dataIndex: 'eoid',
      key: 'eoid',
      sorter: true,
    },
    {
      title: t('table:leadSurveyorName'),
      width: 120,
      dataIndex: 'leadSurveyorName',
      key: 'leadSurveyorName',
      sorter: true,
    },
    {
      title: t('table:surveyResult'),
      dataIndex: 'surveyResult',
      key: 'surveyResult',
      width: 120,
      sorter: true,
      editable: true,
    },
  ];
};





