export const action = (props) => {
  const { key, title, fixed, width, renderFunction } = props;
  
  return {
    title,
    key,
    fixed,
    width,
    render: renderFunction,
  };
};
