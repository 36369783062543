import React, { useEffect, useState } from 'react';
import { AutoComplete as AntdAutoComplete } from 'antd';
import { Loader } from '../loader';

export const Autocomplete = ({ props, data, loading, width }) => {
  const {
    style = {},
    withoutEmpty,
    width: widthFromProps,
    ...otherProps
  } = props;

  if (width) style.width = width;
  if (widthFromProps) style.width = widthFromProps;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data.length)
      setOptions(
        data
          .filter(({ item }) => (withoutEmpty ? item : true))
          .map(({ item }) => ({ value: item === '' ? '-' : item }))
      );
  }, [data]);

  return !loading ? (
    <AntdAutoComplete
      {...otherProps}
      options={options}
      style={style}
      loading={loading}
      filterOption={(inputValue, option) =>
        option?.value
          .toString()
          .toUpperCase()
          .indexOf(inputValue.toString().toUpperCase()) !== -1
      }
    />
  ) : (
    <Loader>
      <AntdAutoComplete {...otherProps} />
    </Loader>
  );
};
