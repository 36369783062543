import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import PageWithSimpleTable from '../../pages/PageWithSimpleTable';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';

const fields = ['state', 'count'];

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_COUNT_MANUAL_FIELD_FORM, {
    method: 'get',
  });

const CountStateManualForm = () => {
  const { data, loading } = useAPI(() => fetch(), {
    initialState: { data: [], loading: false },
  });

  return (
    <PageWithSimpleTable
      titleKey="ElementOccurrence:CountStateManualForm"
      data={data}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};

export default CountStateManualForm;
