import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import { Tabs } from 'antd';
import classNames from 'clsx';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import TableUnderTab from '../../pages/TableUnderTab';
import PageContent from '../../../shared/components/PageContent';

import {
  fieldDataPhenologyInformationColumns,
  speciesInformationColumns,
  speciesPhenologyNotesColumns,
} from './TableColumns';
import SearchForm from './SearchForm';

import classes from '../../styles.module.scss';

const { TabPane } = Tabs;

const fetchSpeciesInformation = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_INFORMATION_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchSpeciesPhenNotes = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_PHEN_NOTES_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchFieldDataPhenologyInformation = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_PHENOLOGY_INFORMATION_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchSpeciesInformationExcel = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_INFORMATION_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchSpeciesPhenNotesExcel = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_PHEN_NOTES_CONST_STATUS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchFieldDataPhenologyInformationExcel = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_PHENOLOGY_INFORMATION_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const SpeciesInformation = () => {
  const { t } = useTranslation();
  const { search: searchParams } = useLocation();

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('Species:SpeciesInformation')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('Species:SpeciesInformationDescription')}
      </p>

      <SearchForm />

      {searchParams && (
        <Tabs className={classNames(classes.tabs)}>
          <TabPane tab={t('Species:MainTable')} key="MainTable">
            <TableUnderTab
              fetchData={fetchSpeciesInformation}
              fetchDownload={fetchSpeciesInformationExcel}
              defaultSortField={['SpeciesName', 'PhenologyType', 'FirstDate']}
              searchForm={SearchForm}
              columns={speciesInformationColumns}
              scroll={{ x: 800 }}
              paramsForRequest={['SpeciesName', 'PhenologyType']}
            />
          </TabPane>

          <TabPane
            tab={t('Species:SpeciesPhenologyNotesAndConservationStatus')}
            key="SpeciesPhenology"
          >
            <TableUnderTab
              fetchData={fetchSpeciesPhenNotes}
              fetchDownload={fetchSpeciesPhenNotesExcel}
              defaultSortField="SpeciesName"
              searchForm={SearchForm}
              columns={speciesPhenologyNotesColumns}
              scroll={{ x: 800 }}
              paramsForRequest={['SpeciesName']}
            />
          </TabPane>

          <TabPane
            tab={t('Species:FieldDataPhenologyInformation')}
            key="FieldDataPhenology"
          >
            <TableUnderTab
              fetchData={fetchFieldDataPhenologyInformation}
              fetchDownload={fetchFieldDataPhenologyInformationExcel}
              defaultSortField="null"
              searchForm={SearchForm}
              columns={fieldDataPhenologyInformationColumns}
              scroll={{ x: 1500 }}
              paramsForRequest={['SpeciesName']}
            />
          </TabPane>
        </Tabs>
      )}
    </PageContent>
  );
};

export default SpeciesInformation;
