import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { Popconfirm, Button } from 'antd';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { roleTypes } from '../../../../utils/roles';
import { useAuth } from '../../../../global/stores/auth';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import PageWithSimpleTable from '../../../pages/PageWithSimpleTable';
import { openNotificationWithIcon } from '../../../../utils/general';

import classes from '../../../styles.module.scss';

const fields = ['phenologyType', 'firstDate#date', 'lastDate#date'];

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_PHENOLOGY_DETAILS(key), {
        method: 'get',
      })
    : [];

const fetchDelete = (key, type) =>
  key && type
    ? APIFetch(ENDPOINT_URLS.DELETE_SPECIES_INFORMATION_EDIT(key, type), {
        method: 'delete',
      })
    : null;

const fetchParent = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_INFORMATION_EDIT_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'SpeciesName' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'SpeciesKey', value: key },
          ],
        }),
      })
    : {};

const InformationEditDetails = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { key } = useParams();
  const history = useHistory();
  const { user } = useAuth();

  const [pageTitle, setPageTitle] = useState('');

  const { data, loading, refetch } = useAPI(() => fetch(key), {
    initialState: { data: [], loading: false },
  });

  const { data: deleteData, refetch: refetchDelete } = useAPI(
    (type) => fetchDelete(key, type),
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  const { data: parentData } = useAPI(() => fetchParent(key), {
    initialState: { loading: false },
  });

  useEffect(() => {
    if (parentData?.items.length) {
      const { speciesName } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: speciesName,
        },
      });

      setPageTitle(speciesName);
    }
  }, [parentData]);

  useEffect(() => {
    if (deleteData) {
      if (deleteData.speciesKey) {
        openNotificationWithIcon('success', 'Data was deleted successfully');

        refetch();
      } else {
        openNotificationWithIcon('error', 'Failed delete data');
      }
    }
  }, [deleteData]);

  const isAdmin = user.role === roleTypes.Administrator;

  const actionColumn = {
    title: t('table:action'),
    key: 'operation',
    fixed: 'left',
    width: 100,
    render: (_, record) => (
      <>
        <Popconfirm
          placement="top"
          title="Delete this item?"
          onConfirm={() => refetchDelete(record.phenologyType)}
          okText="Yes"
          cancelText="No"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            style={{
              marginRight: 8,
            }}
          >
            {t('table:delete')}
          </a>
        </Popconfirm>

        <Link
          style={{
            marginRight: 8,
          }}
          to={`${pathname}/${record.phenologyType}`}
        >
          {t('table:edit')}
        </Link>
      </>
    ),
  };

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  return (
    <PageWithSimpleTable
      title={pageTitle}
      data={data}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.phenologyType}
      scroll={false}
      actionColumn={isAdmin ? actionColumn : null}
    >
      {isAdmin && (
        <div className={classNames(classes.content)}>
          <Button onClick={create}>Add New Phenology</Button>
        </div>
      )}
    </PageWithSimpleTable>
  );
};

export default InformationEditDetails;
