import { Col, Row } from 'antd';
import React from 'react';

import Card from '../../shared/components/Card';
import PageContent from '../../shared/components/PageContent';
import { useAuth } from '../../global/stores/auth';

import { HomepageConfig } from './config';

export const Home = () => {
  const { user } = useAuth();

  return (
    <PageContent withBreadcrumb={false}>
      <Row gutter={[16, 16]}>
        {HomepageConfig().map((el) => (
          <Col span={8} key={el.title} flex>
            <Card {...el} />
          </Col>
        ))}
      </Row>
    </PageContent>
  );
};
