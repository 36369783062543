import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'speciesTotal',
  'speciesCtstatus',
  'speciesMastatus',
  'speciesMestatus',
  'speciesNhstatus',
  'speciesRistatus',
  'speciesVtstatus',
];

const fetchSpeciesStateStatus = () =>
  APIFetch(ENDPOINT_URLS.GET_SPECIES_STATE_STATUS, {
    method: 'get',
  });

export const SpeciesStateStatus = () => {
  const { data, loading } = useAPI(() => fetchSpeciesStateStatus(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('speciesTotal', data);

  return (
    <PageWithSimpleTable
      titleKey="Species:SpeciesStateStatus"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};
