import React, { useState, useEffect } from 'react';
import { CloseOutlined, DownCircleTwoTone } from '@ant-design/icons';
import classNames from 'clsx';
import { Button } from 'antd';

import classes from './styles.module.scss';

const CloseIcon = () => (
  <CloseOutlined className={classNames(classes.mapMarkerBallonCloseIcon)} />
);

const Marker = ({ lat, lng, color, description, isCloseAllBalloons }) => {
  const [showBallon, setShowBallon] = useState(!!description);

  const onClickIcon = () => {
    if (description) setShowBallon(!showBallon);
  };

  useEffect(() => {
    if (typeof isCloseAllBalloons === 'boolean')
      setShowBallon(!isCloseAllBalloons);
  }, [isCloseAllBalloons]);

  return (
    <div lat={lat} lng={lng} className={classNames(classes.mapMarkerWrapper)}>
      {showBallon && (
        <div className={classNames(classes.mapMarkerBallon)}>
          <Button
            icon={<CloseIcon />}
            className={classNames(classes.mapMarkerBallonClose)}
            onClick={() => setShowBallon(false)}
          />
          {description}
        </div>
      )}
      <DownCircleTwoTone
        className={classNames(classes.mapPin)}
        onClick={onClickIcon}
        twoToneColor={color}
      />
    </div>
  );
};

export default Marker;
