import React, { useEffect } from 'react';
import { Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import classNames from 'clsx';

import PageWithSimpleTable from '../../../pages/PageWithSimpleTable';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { openNotificationWithIcon } from '../../../../utils/general';

import classes from '../../../styles.module.scss';

const ValidationDataTab = ({
  setIsDelete,
  pageKey,
  fetchDelete,
  idKey,
  addBtnTxt,
  data,
  columns,
  pageName,
  scroll,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const { data: deleteData, refetch: refetchDelete } = useAPI(
    (id) => fetchDelete(id),
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  useEffect(() => {
    if (deleteData) {
      if (deleteData[idKey]) {
        openNotificationWithIcon(
          'success',
          `${pageName} was deleted successfully`
        );

        setIsDelete(true);
      } else {
        openNotificationWithIcon('error', `Failed delete ${pageName}`);
      }
    }
  }, [deleteData]);

  const allColumns = () => [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 150,
      render: (_, record) => (
        <>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => refetchDelete(record[idKey])}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              style={{
                marginRight: 8,
              }}
            >
              {t('table:delete')}
            </a>
          </Popconfirm>
          <Link
            style={{
              marginRight: 8,
            }}
            to={`${pathname}/Edit_${pageKey}_${record[idKey]}`}
          >
            {t('table:edit')}
          </Link>
        </>
      ),
    },
    ...columns,
  ];

  const create = () => {
    history.push({
      pathname: `${pathname}/Create_${pageKey}`,
    });
  };

  return (
    <PageWithSimpleTable
      data={data}
      titleKey={`PCVAdministration:${pageKey}`}
      tableColumns={allColumns}
      rowKey={(record) => record[idKey]}
      scroll={scroll}
      isWithoutPageContent
    >
      <div className={classNames(classes.content)}>
        <Button onClick={create}>{addBtnTxt}</Button>
      </div>
    </PageWithSimpleTable>
  );
};

export default ValidationDataTab;
