export function isStatusSuccess(status) {
  return status === 200;
}

export function isStatusFail(status) {
  return status >= 400;
}

export function getContentTypeFromHeaders(headers) {
  return headers.get('Content-Type') || '';
}

export function isJsonContentType(contentType) {
  const contentTypeTransformed = contentType.toLowerCase();

  return (
    contentTypeTransformed.includes('application/json') ||
    contentTypeTransformed.includes('application/problem+json')
  );
}

export function isBlobContentType(contentType) {
  const contentTypeTransformed = contentType.toLowerCase();

  return contentTypeTransformed.includes('application/octet-stream');
}
