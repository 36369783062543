import React from 'react';

import StatesSelect from '../../../../shared/components/select/StatesSelect';

export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'EO species name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'Town',
    label: 'EO town',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'SiteName',
    label: 'EO sitename',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
