import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:ownerComment'),
      dataIndex: 'ownerComment',
      key: 'ownerComment',
    },
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('table:permitStartDate'),
      dataIndex: 'permitStartDate',
      key: 'permitStartDate',
      render: (text) => (text ? getMomentDate(text).format('MM-DD') : ''),
    },
    {
      title: t('table:permitEndDate'),
      dataIndex: 'permitEndDate',
      key: 'permitEndDate',
      render: (text) => (text ? getMomentDate(text).format('MM-DD') : ''),
    },
    {
      title: t('table:permitDocument1'),
      dataIndex: 'permitDocument1',
      key: 'permitDocument1',
      render: (text, { permitDocumentLink1 }) =>
        text ? <a href={permitDocumentLink1}>{text}</a> : '',
    },
    {
      title: t('table:permitDocument2'),
      dataIndex: 'permitDocument2',
      key: 'permitDocument2',
      render: (text, { permitDocumentLink2 }) =>
        text ? <a href={permitDocumentLink2}>{text}</a> : '',
    },
    {
      title: t('table:permitComment'),
      dataIndex: 'permitComment',
      key: 'permitComment',
    },
    {
      title: t('table:ownerPermission'),
      dataIndex: 'ownerPermission',
      key: 'ownerPermission',
    },
  ];
};

const OwnerSurveyInformation = ({ data }) => (
  <ContainerWithSimpleTable
    data={data}
    loading={false}
    rowKey={uuidv4()}
    scroll={{ x: 1800 }}
    fullWidth
    tableColumns={columns}
  />
);

export default OwnerSurveyInformation;
