import React, { useEffect, useState } from 'react';
import { Button, Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import Title from 'antd/lib/typography/Title';

import { getRandomuserParams } from '../../shared/sortAndSearchUtils';
import { EditableTable } from '../../shared/EditableTable';
import PageContent from '../../shared/components/PageContent';

import classes from '../styles.module.scss';

const PageWithEditableTable = ({
  titleKey,
  descriptionKey,
  noteKey,
  isExportContent,
  onExport,
  exportLoading,
  searchForm,
  rowKeyFunc,
  columns,
  editableCellsConfig,
  fetch,
  setParametersForExport,
  tableData,
  setData,
  initialPagination,
  cancel,
  form,
  data,
  loading,
  isDataArray,
  scroll = { x: 1300 },
  withTab = false,
  ...other
}) => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState(initialPagination);

  const handleTableChange = (paginationForRequest, filters, sorter) => {
    const parameters = getRandomuserParams({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: paginationForRequest,
      ...filters,
    });
    if (setParametersForExport) {
      setParametersForExport(parameters);
    }
    fetch(parameters);
    cancel();
  };

  useEffect(() => {
    if (!isDataArray && Object.keys(data).length) {
      setData(data.items);
      setPagination({
        pageSize: data?.pagedInfo?.pageSize,
        pageNumber: data?.pagedInfo?.pageNumber,
        total: data?.totalItemsCount,
      });
    }

    if (isDataArray && data.length) {
      setData(data);
    }
  }, [data]);

  const exportContent = (
    <div
      className={
        withTab
          ? classNames(classes.contentWithTab)
          : classNames(classes.content)
      }
    >
      <Button type="primary" onClick={onExport} loading={exportLoading}>
        Export to Excel
      </Button>
    </div>
  );

  const content = (
    <Form form={form} component={false}>
      {isDataArray ? (
        <>
          {data.length ? (
            <>
              {withTab &&
              isExportContent &&
              (data.length || (Object.keys(data).length && data.items.length))
                ? exportContent
                : ''}
              <EditableTable
                {...other}
                className={classNames(
                  classes.table,
                  withTab ? classes.tableFullWidth : ''
                )}
                columns={columns}
                dataSource={tableData}
                loading={loading}
                rowKey={rowKeyFunc}
                onChange={handleTableChange}
                scroll={scroll}
                pagination={false}
                editableCellsConfig={editableCellsConfig}
              />
              <p className={classNames(classes.simpleTableTotal)}>
                Total:{' '}
                {data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </p>
            </>
          ) : null}
          {!data.length && loading ? (
            <center>
              <Spin />
            </center>
          ) : null}
        </>
      ) : (
        <>
          {Object.keys(data).length && data.items.length ? (
            <>
              {withTab &&
              isExportContent &&
              (data.length || (Object.keys(data).length && data.items.length))
                ? exportContent
                : ''}
              <EditableTable
                {...other}
                className={classNames(
                  classes.table,
                  withTab ? classes.tableFullWidth : ''
                )}
                columns={columns}
                dataSource={tableData}
                loading={loading}
                rowKey={rowKeyFunc}
                onChange={handleTableChange}
                scroll={scroll}
                pagination={{
                  pageSizeOptions: [20, 50, 100],
                  current: pagination.pageNumber,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
                editableCellsConfig={editableCellsConfig}
              />
              <p className={classNames(classes.tableTotal)}>
                Total:{' '}
                {data.totalItemsCount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </p>
            </>
          ) : null}
          {Object.keys(data).length && !data.items.length ? (
            <p className={classNames(classes.noData)}>{t('table:noData')}</p>
          ) : null}
          {!Object.keys(data).length && loading ? (
            <center>
              <Spin />
            </center>
          ) : null}
        </>
      )}
    </Form>
  );

  return withTab ? (
    content
  ) : (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(titleKey)}
      </Title>
      <p className={classNames(classes.title)}>{t(descriptionKey)}</p>
      {noteKey && (
        <p className={classNames(classes.title)}>
          <i>{t(noteKey)}</i>
        </p>
      )}
      {searchForm && searchForm({ tableLoading: loading })}
      {isExportContent &&
      (data.length || (Object.keys(data).length && data.items.length))
        ? exportContent
        : ''}
      {content}
    </PageContent>
  );
};

export default PageWithEditableTable;
