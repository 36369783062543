import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      width: 60,
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:firstObsHrp'),
      dataIndex: 'firstObsHrp',
      key: 'firstObsHrp',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:lastObsHrp'),
      dataIndex: 'lastObsHrp',
      key: 'lastObsHrp',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:firstObsChecklist'),
      dataIndex: 'firstObsChecklist',
      key: 'firstObsChecklist',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:lastObsChecklist'),
      dataIndex: 'lastObsChecklist',
      key: 'lastObsChecklist',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:firstObsEostate'),
      dataIndex: 'firstObsEostate',
      key: 'firstObsEostate',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:lastObsEostate'),
      dataIndex: 'lastObsEostate',
      key: 'lastObsEostate',
      width: 70,
      sorter: true,
    },

    {
      title: t('table:firstObsEonpt'),
      dataIndex: 'firstObsEonpt',
      key: 'firstObsEonpt',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:lastObsEonpt'),
      dataIndex: 'lastObsEonpt',
      key: 'lastObsEonpt',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:firstObsAll'),
      dataIndex: 'firstObsAll',
      key: 'firstObsAll',
      width: 70,
      sorter: true,
    },

    {
      title: t('table:lastObsAll'),
      dataIndex: 'lastObsAll',
      key: 'lastObsAll',
      width: 70,
      sorter: true,
    },
  ];
};
