import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const ExcitingMoistureRegimesSelect = (props) => {
  const {
    existingMoistureRegimesData,
    existingMoistureRegimesLoading,
    updateExistingMoistureRegimes,
  } = useStore();

  useEffect(() => {
    if (!existingMoistureRegimesData.length && !existingMoistureRegimesLoading)
      updateExistingMoistureRegimes();
  }, []);

  return (
    <Select
      props={props}
      data={existingMoistureRegimesData}
      loading={existingMoistureRegimesLoading}
    />
  );
};

export default ExcitingMoistureRegimesSelect;
