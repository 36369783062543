import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { paramsForBE } from '../../../utils/general';
import PageWithNestedTable from '../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';
import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';

const fetchChecklistDistributionPaged = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_CHECKLIST_DISTRIBUTION_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchTracheophyteChecklistDistribution = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_TRACHEOPHYTE_CHECKLIST_DISTRIBUTION(key), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'StateCounty',
            },
          ],
        }),
      })
    : [];

export const ChecklistDistribution = () => (
  <PageWithNestedTable
    fetchData={(args) => fetchChecklistDistributionPaged(args)}
    fetchNestedData={(key) => fetchTracheophyteChecklistDistribution(key)}
    defaultSortField="SpeciesName"
    nestedTableKey="speciesKey"
    titleKey="StatusOfPlants:ChecklistDistribution"
    descriptionKey="StatusOfPlants:ChecklistDistributionDescription"
    paramsForBE={paramsForBE}
    beSearchConfig={beSearchConfig}
    searchForm={SearchForm}
    columns={columns()}
    expandableColumns={expandableColumns()}
    rowKeyFunc={(record) => record.speciesKey}
    scroll={{ x: 1300 }}
  />
);
