import React from 'react';

export const getCoordinates = (data) => {
  const coordinates = [];

  data.map((item, id) => {
    const {
      latitude,
      longitude,
      elementOccurrenceColor,
      eonumberInt,
      siteName,
    } = item;

    coordinates.push({
      id,
      lat: latitude,
      lng: longitude,
      color: elementOccurrenceColor,
      description: (
        // eslint-disable-next-line react/jsx-filename-extension
        <>
          <b>EO number:</b> {eonumberInt || '-'}
          <br />
          <b>Latitude:</b> {latitude || '-'}
          <br />
          <b>Longitude:</b> {longitude || '-'}
          <br />
          <b>Sitename :</b> {siteName || '-'}
        </>
      ),
    });

    return item;
  });

  return coordinates;
};
