import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const ReportMethodsSelect = (props) => {
  const {
    allReportMethodsData,
    allReportMethodsLoading,
    updateAllReportMethods,
  } = useStore();

  useEffect(() => {
    if (!allReportMethodsData.length && !allReportMethodsLoading)
      updateAllReportMethods();
  }, []);

  return (
    <Select
      props={props}
      width={150}
      data={allReportMethodsData}
      loading={allReportMethodsLoading}
    />
  );
};

export default ReportMethodsSelect;
