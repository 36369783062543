import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { createPdfTables, createPdf as createPdfEOData } from '../../../utils/general';
import { expandableColumns } from './TableColumns';

const fetchContent = (key, checked) =>
  APIFetch(ENDPOINT_URLS.GET_SURVEY_ASSIGNMENT_FIELD_DATA_DETAILS(key, checked), {
    method: 'get',
  });

const fetchContentEOData = (key) =>
  APIFetch(ENDPOINT_URLS.GET_SURVEY_ASSIGNMENT_EO_DATA_DETAILS(key), {
    method: 'get',
  });

export const actionContent = (
  record,
  { pageName = '', fileTitle = 'PCV/TF Assignment Summary', checked }
) => {

  const { t } = useTranslation();
  const { data } = useAPI(() => fetchContent(record.eokey, checked), {
    initialState: { data: {}, loading: false },
  });

  const {
    state,
    speciesName,
    eonumber,
    subpopulation,
    town,
    siteName,
    survey,
    collectSeed,
    surveyComment,
  } = record;
  const filenamePostfix = ` ${pageName} ${new Date().getFullYear()}`;
  const intermediateFilename = `${state}_${speciesName}_${eonumber}_${subpopulation}${filenamePostfix}`;
  const filename = intermediateFilename.replace(/\./g, '');

  const headersParentData = [
    'state',
    'speciesName',
    'eonumber',
    'subpopulation',
    'town',
    'siteName',
    'survey',
    'collectSeed',
    'surveyComment',
  ];
  const surveyAssignmentFieldDataHeaders = expandableColumns().map(item => item.key)

  const parentData = [
    {
      state,
      speciesName,
      eonumber,
      subpopulation,
      town,
      siteName,
      survey,
      collectSeed,
      surveyComment,
    },
  ];
  const createPdf = () => {
    if (data?.length > 0) {
      createPdfTables(t, [
        {
          headers: headersParentData,
          data: parentData,
          title: 'PCV/TF Assignment Summary',
          filename,
        },
        {
          headers: surveyAssignmentFieldDataHeaders,
          data,
          title: fileTitle,
          filename,
        },
      ]);
    }
  };

  return (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        style={{
          marginRight: 8,
        }}
        onClick={createPdf}
      >
        Download
      </a>
    </span>
  );
};

export const actionContentEOData = (record) => {
  const { t } = useTranslation();
  const { data } = useAPI(() => fetchContentEOData(record.eokey), {
    initialState: { data: {}, loading: false },
  });

  const {
    state,
    speciesName,
  } = data[0] || {};

  const filenamePostfix = `${t('PCVTF:SurveyAssignmentEOdataFilenamePostfix').split(' ').join('')}`
  const intermediateFilename = `${state}_${speciesName}_${filenamePostfix}`;
  const filename = intermediateFilename.replace(/\./g, '');
  
  const createPdfTable = () => {
    if (data?.length > 0) {
      const dataFromRow = data[0];
      const pdfDataFields = Object.keys(dataFromRow).map((propKey) => {
        const value = dataFromRow[propKey];
        return {
          name: t(`table:${propKey}`),
          value,
        };
      });

      createPdfEOData(
        pdfDataFields,
        'PCV/TF Survey Assignment EO data',
        filename,
        true
      );
    }
  };

  return (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        style={{
          marginRight: 8,
        }}
        onClick={createPdfTable}
      >
        Download
      </a>
    </span>
  );
};