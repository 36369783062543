import React from 'react';

import StatesSelect from '../../../../shared/components/select/StatesSelect';

export const searchFormConfig = [
  {
    name: 'SpeciesNameOrSynonym',
    label: 'Element Occurrence species name',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'Town',
    label: 'Element occurrence town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SiteName',
    label: 'Element Occurrence sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
