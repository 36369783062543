import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetch = (args) => {
  if (!args) return {};

  const { paginationInfo, ...rest } = args;

  return APIFetch(ENDPOINT_URLS.SURVEYOR_BY_DISTANCE, {
    method: 'post',
    body: JSON.stringify({ ...paginationInfo, ...rest }),
  });
};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_BY_DISTANCE_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

export const SurveyorByDistance = () => (
  <PageWithTable
    fetchData={fetch}
    defaultSortField="State"
    titleKey="Surveyors:SurveyorByDistance"
    descriptionKey="Surveyors:SurveyorByDistanceDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1300 }}
    fetchExport={fetchExport}
    fetchExportWithoutPaginationInfo
  />
);
