import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Form, Button, Row, Col, Spin } from 'antd';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import FormItem from '../../../shared/components/formItem';
import {
  openNotificationWithIcon,
  getParentPathname,
  deletePreviousSearch,
  validateMessages,
} from '../../../utils/general';
import { useStore } from '../../../global/stores/store/StoreContext';
import PageContent from '../../../shared/components/PageContent';

import { editFormConfig as formConfig } from './form-config';

import classes from '../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const numberFields = [
  'eokey',
  'minRamet',
  'maxRamet',
  'minGenet',
  'maxGenet',
  'populationArea',
  'slopeMin',
  'slopeMax',
  'elevationMin',
  'elevationMax',
  'pctLeaf',
  'pctBud',
  'pctFlower',
  'pctImmFruit',
  'pctMatFruit',
  'pctSeed',
  'pctSenescent',
  'pctDormant',
  'pctSeedling',
  'pctImmature',
  'pctVegSprout',
  'pct1stYear',
  'pctMature',
  'pctSenesc',
  'pctUnknown',
];

const fetchCreateFieldDataManualEnter = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_FILED_DATA_MANUAL_ENTER, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchEOsWithDataErrors = (eokey) =>
  eokey
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'SpeciesName' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            {
              operatortype: 'Equals',
              propertyName: 'Eokey',
              value: eokey,
            },
          ],
        }),
      })
    : {};

export const CreateFieldDataManualEnter = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { eokey } = useParams();

  const query = new URLSearchParams(search);
  const folder = query.get('folder');
  const fullFilename = query.get('fullFilename');
  const fileLink = query.get('fileLink');
  const page = query.get('page');

  const [form] = Form.useForm();

  const { data, loading, refetch } = useAPI(
    (args) => fetchCreateFieldDataManualEnter(args),
    {
      initialState: { data: {}, loading: false },
      shouldFetch: false,
    }
  );

  const { data: parentData } = useAPI(() => fetchEOsWithDataErrors(eokey), {
    initialState: { loading: false },
  });

  useEffect(() => {
    if (parentData?.items.length) {
      const {
        state: itemState,
        speciesName,
        eonumber,
        subpopulation,
      } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `${itemState} ${speciesName} ${eonumber} ${subpopulation}`,
        },
        search,
      });
    }
  }, [parentData]);

  const onFinish = (values) => {
    const { surveyDate, threats, mgtNeeds, ...other } = values;

    delete other.eo;

    const args = {
      ...other,
      eokey,
      surveyDate: surveyDate.format(),
      threats: threats ? 'y' : 'n',
      mgtNeeds: mgtNeeds ? 'y' : 'n',
      folder: encodeURI(folder),
      fullFilename: decodeURI(fullFilename),
      fileLink: encodeURI(fileLink),
    };

    Object.keys(args)
      .filter((key) => args[key] && numberFields.includes(key))
      .map((key) => {
        args[key] = +args[key];

        return key;
      });

    refetch(args);
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    const final = deletePreviousSearch(parentPathname);
    history.replace(`${final}?page=${page}`);
  };

  const [initialValues, setInitialValues] = useState(null);
  const {
    moistureRegimesData,
    updateMoistureRegimes,
    lightsData,
    updateLights,
    aspectsData,
    updateAspects,
    topoPositionsData,
    updateTopoPositions,
  } = useStore();

  useEffect(() => {
    if (!moistureRegimesData.length) updateMoistureRegimes();
    if (!lightsData.length) updateLights();
    if (!aspectsData.length) updateAspects();
    if (!topoPositionsData.length) updateTopoPositions();
  }, []);

  useEffect(() => {
    if (
      moistureRegimesData.length &&
      lightsData.length &&
      aspectsData.length &&
      topoPositionsData.length &&
      parentData?.items.length
    ) {
      const {
        state: itemState,
        speciesName,
        eonumber,
        subpopulation,
      } = parentData.items[0];

      setInitialValues({
        eo: `${itemState} ${speciesName} ${eonumber}-${subpopulation}`,
        moistureRegime: moistureRegimesData[0].itemKey,
        light: lightsData[0].itemKey,
        aspect: aspectsData[0].itemKey,
        topoPosition: topoPositionsData[0].itemKey,
      });
    }
  }, [
    moistureRegimesData,
    lightsData,
    aspectsData,
    topoPositionsData,
    parentData,
  ]);

  useEffect(() => {
    if (Object.keys(data).length) {
      if (data.eokey) {
        openNotificationWithIcon(
          'success',
          'Field Form was successfully created'
        );

        const parentPathname = getParentPathname(pathname);
        const final = deletePreviousSearch(parentPathname);
        history.replace(`${final}?page=${page}`);
      } else {
        openNotificationWithIcon('error', 'Failed create Field Form');
      }
    }
  }, [data]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('ElementOccurrence:FieldDataManualEnter:editForm:title')}
      </Title>
      <h2 className={classNames(classes.title)}>{fullFilename}</h2>
      <p className={classNames(classes.title)}>
        {t('ElementOccurrence:FieldDataManualEnter:editForm:description')}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="createFieldDataManualEnter"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('form:insert')}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};
