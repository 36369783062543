import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:commonName'),
      dataIndex: 'commonName',
      key: 'commonName',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
      width: 200,
    },
    {
      title: t('table:survey'),
      dataIndex: 'survey',
      key: 'survey',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:collectSeed'),
      dataIndex: 'collectSeed',
      key: 'collectSeed',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      width: 200,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:seedDateSent'),
      dataIndex: 'seedDateSent',
      key: 'seedDateSent',
      sorter: true,
      width: 200,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:reviewComments'),
      dataIndex: 'reviewComments',
      key: 'reviewComments',
      sorter: true,
      width: 180,
    },
    {
      title: t('table:leadSurvName'),
      dataIndex: 'leadSurvName',
      key: 'leadSurvName',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:surv2Name'),
      dataIndex: 'surv2Name',
      key: 'surv2Name',
      sorter: true,
      width: 140,
    },
    {
      title: t('table:surv3Name'),
      dataIndex: 'surv3Name',
      key: 'surv3Name',
      sorter: true,
      width: 140,
    },
    {
      title: t('table:surv4Name'),
      dataIndex: 'surv4Name',
      key: 'surv4Name',
      sorter: true,
      width: 140,
    },
  ];
};
