import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAuth } from '../../../../global/stores/auth';
import { paramsForBE, openNotificationWithIcon } from '../../../../utils/general';
import { roleTypes } from '../../../../utils/roles';
import PageWithNestedTable from '../../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';
import { beSearchConfig } from './form-config';
import SearchForm from './SearchForm';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_EO_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchNestedData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_EXISTING_OWNER_EO(key), {
        method: 'post',
        body: JSON.stringify({
          pagedInfo: {
            pageSize: 100,
            pageNumber: 1,
          },
          orderCriteria: ['State', 'SpeciesName', 'EonumberInt'].map(
            (propertyName) => ({
              propertyName,
              orderOption: 'Ascending',
            })
          ),
        }),
      })
    : {};

const fetchNestedPastData = (key, currentOwner) =>
key
  ? APIFetch(ENDPOINT_URLS.GET_EXISTING_OWNER_EO_FOR_ADD_OR_DELETE(key,currentOwner), {
      method: 'post',
      body: JSON.stringify({
        orderCriteria: [
          {
            orderOption: 'Ascending',
            propertyName: 'Eokey',
          },
        ],
      }),
    })
  : []; 
  
const fetchDelete = (eokey, ownerKey) =>
  eokey && ownerKey
    ? APIFetch(ENDPOINT_URLS.DELETE_EL_OCC_OWNER(eokey, ownerKey), {
        method: 'delete',
      })
    : null;  

const OwnerEO = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const [isPastSelect, setIsPastSelect] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI((eokey, ownerKey) => fetchDelete(eokey, ownerKey), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const selectActions = [
    {
      title: t('table:select'),
      currentOwner: true,
    }
  ];

  const actions = (record, getNestedData) => (
  <>
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        id="past"
        onClick={(e) => getNestedData(record, e.target.id)}
        style={{
          marginLeft: 16,
        }}
      >
        {t(`table:selectPastEOs`)}
      </a>
    </span>
    {role !== roleTypes.Heritage && selectActions.map((item) => 
      <Link
        style={{
          marginLeft: 16,
        }}
        key={uuidv4()}
        to={`${pathname}/${record.ownerKey}?name=${record.ownerName}&currentOwner=${item.currentOwner}`}
      >
        {item.title}
      </Link>
    )}
  </>
  );

  const staticExpandableColumns = expandableColumns(isPastSelect);
  const allExpandableColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 150,
      render: (_, record) => (
        <>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => {
              refetchDelete(record.eokey, record.ownerKey)
            }}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              style={{
                marginRight: 8,
              }}
            >
              {t('table:delete')}
            </a>
          </Popconfirm>
          <Link
            style={{
              marginRight: 8,
            }}
            to={`${pathname}/${record.ownerKey}/${record.eokey}?currentOwner=${false}`}
          >
            {t('table:edit')}
          </Link>
        </>
      ),
    },
    ...staticExpandableColumns,
  ];

  const descriptionKey = role === roleTypes.Heritage
    ? "Owner:OwnerEODisplayDescription"
    : "Owner:OwnerEODescription";

  useEffect(() => {
    if (deleteData) {
      if (deleteData?.ownerKey) {
        openNotificationWithIcon(
          'success',
          'Owner EO was deleted successfully'
        );
      } else {
        openNotificationWithIcon('error', 'Failed delete Owner EO');
      }
    }
  }, [deleteData]);  

  return (
    <PageWithNestedTable
      fetchData={(args) => fetch(args)}
      fetchNestedData={(key) => fetchNestedData(key)}
      fetchNestedPastData={(key) => fetchNestedPastData(key, false)}
      defaultSortField={[
        'LastName',
        'FirstName',
        'Organization',
        'SubOrganization',
      ]}
      nestedTableKey="ownerKey"
      titleKey="Owner:OwnerEO"
      descriptionKey={descriptionKey}
      columns={columns()}
      expandableColumns={
        role === roleTypes.Administrator && isPastSelect
          ? allExpandableColumns
          : staticExpandableColumns
      }
      rowKeyFunc={(record) => record.ownerKey}
      scroll={{ x: 1700 }}
      searchForm={SearchForm}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
      getNestedDataActionText="ShowOwnersEOs"
      actions={actions}
      actionsColWidth={300}
      isPastSelect={isPastSelect}
      setIsPastSelect={setIsPastSelect}
      deleteLoading={deleteLoading}
    />
  );
};

export default OwnerEO;
