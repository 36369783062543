import React, { useEffect, useMemo } from 'react';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import ValidationDataForm from '../../../ValidationData/ValidationDataPages/ValidationDataForm';

import { formConfig } from './form-config';
import { useStore } from '../../../../global/stores/store/StoreContext';

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_OUTREACH_ORGANIZATION, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (id, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_OUTREACH_ORGANIZATION(id), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.GET_OUTREACH_ORGANIZATION_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'OutreachOrganizationType',
            },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            {
              operatortype: 'Equals',
              propertyName: 'Id',
              value: id,
            },
          ],
        }),
      })
    : {};

const OutreachOrganizationForm = () => {
  const {
    outreachOrganizationTypesData,
    updateOutreachOrganizationTypes,
    statesData,
    updateStates,
  } = useStore();

  useEffect(() => {
    if (!outreachOrganizationTypesData.length)
      updateOutreachOrganizationTypes();
    if (!statesData.length) updateStates();
  }, []);

  const getParamsForInitialValues = (isUpdateMode, params) => {
    if (
      !isUpdateMode &&
      outreachOrganizationTypesData.length &&
      statesData.length
    ) {
      return {
        state: statesData[0].itemKey,
        outreachOrganizationType: outreachOrganizationTypesData[0].itemKey,
      };
    }

    return {
      ...params,
    };
  };

  const getParamsForSubmit = (isUpdateMode, params) => ({ ...params });

  const isInitialValuesCanBeSetted = useMemo(
    () => outreachOrganizationTypesData.length && statesData.length,
    [outreachOrganizationTypesData, statesData]
  );

  const fieldsValueOnClear = {
    state: statesData[0].itemKey,
    organization: '',
    outreachOrganizationType: outreachOrganizationTypesData[0].itemKey,
    association: '',
    contact: '',
    notes: '',
  };

  return (
    <ValidationDataForm
      fetch={fetch}
      fetchCreate={fetchCreate}
      fetchUpdate={fetchUpdate}
      pageKey="OutreachOrganization"
      pageName="Outreach Organization"
      idKey="id"
      formConfig={formConfig}
      getParamsForInitialValues={getParamsForInitialValues}
      getParamsForSubmit={getParamsForSubmit}
      disabledFields={['outreachOrganization1']}
      notValidationData="PCVAdministration"
      isInitialValuesCanBeSetted={isInitialValuesCanBeSetted}
      fieldsValueOnClear={fieldsValueOnClear}
      isCancelButton
      isUpdateButton
    />
  );
};

export default OutreachOrganizationForm;
