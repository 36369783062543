import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { useLocation } from 'react-router-dom';
import { Form, Button } from 'antd';

import { useAuth } from '../../../global/stores/auth';
import { roleTypes } from '../../../utils/roles';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import PageContent from '../../../shared/components/PageContent';
import {
  buildSearchParamsForRequest,
  getRandomuserParams,
  paramsToPassInBodyRequest,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { ExpandableEditableTable } from '../../../shared/ExpandableEditableTable';
import {
  openNotificationWithIcon,
  downloadExcel,
  paramsForBE,
} from '../../../utils/general';

import SearchForm from './SearchForm';

import { columns, expandableColumns } from './TableColumns';
import { editableCellsConfig, beSearchConfig } from './form-config';
import { actionContent } from './utils';

import classes from '../../styles.module.scss';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchMultiSpeciesSites = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_MULTI_SPECIES_SITES, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchElementOccurrenceData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_MULTI_SPECIES_SITES(key), {
        method: 'get',
      })
    : [];

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_MULTI_SPECIES_SITES_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

export const MultiSpeciesSites = () => {
  const { t } = useTranslation();
  const { search: searchParams, pathname } = useLocation();
  const { user } = useAuth();
  const role = user?.role;

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);

  const { data, loading, refetch } = useAPI(
    (args) => fetchMultiSpeciesSites(args),
    {
      initialState: { data: [], loading: false },
      shouldFetch: false,
    }
  );
  const defaultSortFields = [
    'State',
    'SpeciesName',
    'Eonumber',
    'Subpopulation',
  ];
  const {
    data: nestedData,
    loading: loadingNestedData,
    refetch: refetchNestedData,
  } = useAPI((key) => fetchElementOccurrenceData(key), {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [searchParameters, setSearchParameters] = useState({
    operatortype: 'Contains',
    value: 'as',
    propertyName: 'SpeciesName',
  });

  const [pagination, setPagination] = useState(initialPagination);
  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;
    refetch(
      paramsToPassInBodyRequest(parameters, searchParam, defaultSortFields)
    );
    setIsRefetchDone(true);
    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  const [rowKey, setRowKey] = useState();
  const getNestedData = ({ eokey }) => {
    if (rowKey && rowKey === eokey) {
      setRowKey();
    } else {
      refetchNestedData(eokey);

      setRowKey(eokey);
    }
  };

  const cancel = () => {
    setEditingKey('');
    setRowKey();
  };

  const handleTableChange = (paginationForRequest, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: paginationForRequest,
      ...filters,
    });

    cancel();
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      setData(data.items);
      setPagination({
        pageSize: data.pagedInfo.pageSize,
        pageNumber: data.pagedInfo.pageNumber,
        total: data.totalItemsCount,
      });
    }
  }, [data]);

  const initialState = {
    speciesName: '',
    town: '',
    siteName: '',
    state: '',
  };

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      cancel();

      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);
    if (searchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          paramsForBE
            ? paramsForBE(searchParamsArray, beSearchConfig)
            : searchParamsArray,
          defaultSortFields
        )
      );
      setSearchParameters(searchParamsArray);
      cancel();
    }
  }, [searchParams]);

  const onExport = () => {
    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    refetchExport(
      paramsToPassInBodyRequest(
        { pagination: initialPagination },
        paramsForBE
          ? paramsForBE(searchParamsArray, beSearchConfig)
          : searchParamsArray,
        defaultSortFields
      )
    );
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  const save = async (key) => {
    try {
      const row = await form.validateFields();

      await APIFetch(ENDPOINT_URLS.EDIT_MULTI_SPECIES_SITES_FIELDS(key), {
        method: 'put',
        body: JSON.stringify(row),
      });

      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.eokey);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
      } else {
        newData.push(row);
      }

      setData(newData);
      setEditingKey('');

      openNotificationWithIcon('success', 'Data was updated successfully');
    } catch (errInfo) {
      openNotificationWithIcon('error', `Validate Failed: ${errInfo}`);
    }
  };

  const allColumns = columns();
  const columnsForTable = [
    {
      title: t('table:action'),
      key: 'operation',
      width: 170,
      render: (_, record) => {
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone
        );

        return (
          <span className={classNames(classes.actionsRow)}>
            {actionContent(
              save,
              editable,
              record,
              cancel,
              editingKey,
              form,
              initialState,
              setEditingKey,
              getNestedData,
              role,
            )}
          </span>
        );
      },
    },
    ...allColumns,
  ];

  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['surveyComment', 'tfpcv'].includes(col.dataIndex)
          ? 'text'
          : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isCellEditing(record, editingKey),
      }),
    };
  });

  const columnsForNestedTable = expandableColumns();

  const descriptionKey = role === roleTypes.Heritage
    ? "ElementOccurrence:MultiSpeciesSitesDisplayDescription"
    : "ElementOccurrence:MultiSpeciesSitesDescription";

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('ElementOccurrence:MultiSpeciesSites')}
      </Title>
      <p className={classNames(classes.title)}>
        {t(descriptionKey)}
      </p>

      <SearchForm tableLoading={loading} />

      <Form form={form} component={false}>
        {Object.keys(data).length && data.items.length ? (
          <>
            <div className={classNames(classes.content)}>
              <Button type="primary" onClick={onExport} loading={exportLoading}>
                Export to Excel
              </Button>
            </div>

            <ExpandableEditableTable
              className={classNames(classes.table)}
              columns={mergedColumns}
              dataSource={tableData}
              loading={loading}
              rowKey={(record) => record.eokey}
              onChange={handleTableChange}
              scroll={{ x: 2065 }}
              tableLayout="fixed"
              pagination={{
                pageSizeOptions: [20, 50, 100],
                current: pagination.pageNumber,
                pageSize: pagination.pageSize,
                total: pagination.total,
              }}
              data={[nestedData]}
              expandableColumns={columnsForNestedTable}
              expandableKey={rowKey ? [rowKey] : []}
              loadingNestedData={loadingNestedData}
              editableCellsConfig={editableCellsConfig}
            />
            <p className={classNames(classes.tableTotal)}>
              Total:{' '}
              {data.totalItemsCount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            </p>
          </>
        ) : null}
        {Object.keys(data).length && !data.items.length ? (
          <p className={classNames(classes.noData)}>{t('table:noData')}</p>
        ) : null}
      </Form>
    </PageContent>
  );
};
