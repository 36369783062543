import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { paramsForBE } from '../../../../utils/general';
import PageWithNestedTable from '../../../pages/PageWithNestedTable';
import { action } from '../../../../shared/EditableTable/components/Action';

import { columns, expandableColumns } from './TableColumns';
import { beSearchConfig } from './form-config';
import SearchForm from './SearchForm';
import { actionContent } from './utils';

let leadSurveyorState = false;

const fetch = (args) => {
  if (!args) return {};
  const { searchCriteria, ...rest } = args;
  const leadSurveyor =
    searchCriteria.find((e) => e.propertyName === 'LeadSurveyor')?.value ||
    false;
  const newSearchCriteria = searchCriteria.filter(
    (e) => e.propertyName !== 'LeadSurveyor'
  );

  leadSurveyorState = leadSurveyor;

  return APIFetch(ENDPOINT_URLS.GET_OWNER_EO_CURRENT_YEAR_PAGED(leadSurveyor), {
    method: 'post',
    body: JSON.stringify({ ...rest, searchCriteria: newSearchCriteria }),
  });
};

const fetchNestedData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_EXISTING_OWNER_EO_CURRENT_YEAR(key, leadSurveyorState), {
        method: 'post',
        body: JSON.stringify({
          pagedInfo: {
            pageSize: 100,
            pageNumber: 1,
          },
          orderCriteria: ['State', 'SpeciesName', 'EonumberInt'].map(
            (propertyName) => ({
              propertyName,
              orderOption: 'Ascending',
            })
          ),
        }),
      })
    : {};

const fetchExport = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_EO_CURRENT_SURVEY_YEAR_EXCEL(key), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: ['State'].map((propertyName) => ({
            propertyName,
            orderOption: 'Ascending',
          })),
        }),
      })
    : null;

const OwnerEOCurrentSurveyYear = () => {
  const { t } = useTranslation();
  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:excel'),
      key: 'operation',
      width: 140,
      renderFunction: (_, record) => actionContent(record, fetchExport),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithNestedTable
      fetchData={(args) => fetch(args)}
      fetchNestedData={(key) => fetchNestedData(key)}
      defaultSortField={[
        'LastName',
        'FirstName',
        'Organization',
        'SubOrganization',
      ]}
      nestedTableKey="ownerKey"
      titleKey="Owner:OwnerEOCurrentSurveyYear"
      descriptionKey="Owner:OwnerEOCurrentSurveyYearDescription"
      columns={allColumns}
      expandableColumns={expandableColumns()}
      rowKeyFunc={(record) => record.ownerKey}
      scroll={{ x: 1700 }}
      searchForm={SearchForm}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
      getNestedDataActionText="ShowOwnersEOs"
      actionsColWidth={150}
    />
  );
};

export default OwnerEOCurrentSurveyYear;
