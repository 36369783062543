import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:eototal'),
      dataIndex: 'eototal',
      key: 'eototal',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:activeTotal'),
      dataIndex: 'activeTotal',
      key: 'activeTotal',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:inactiveSprouttotal'),
      dataIndex: 'inactiveSprouttotal',
      key: 'inactiveSprouttotal',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:inactiveTotal'),
      dataIndex: 'inactiveTotal',
      key: 'inactiveTotal',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:historicalTotal'),
      dataIndex: 'historicalTotal',
      key: 'historicalTotal',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:extirpatedTotal'),
      dataIndex: 'extirpatedTotal',
      key: 'extirpatedTotal',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:pctActive'),
      dataIndex: 'pctActive',
      key: 'pctActive',
      width: 70,
      sorter: true,
    },
  ];
};
