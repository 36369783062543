import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = [
  'speciesName',
  'phenologyNote',
  'phenologyMemo',
  'division',
  'comment',
];

const SpeciesPhenologyNotesConservationStatus = ({ data }) => (
  <ContainerWithSimpleTable
    titleKey="PCVAdministration:SpeciesPhenologyNotesConservationStatus"
    data={data}
    loading={false}
    fields={fields}
    rowKey={uuidv4()}
    fullWidth
  />
);

export default SpeciesPhenologyNotesConservationStatus;
