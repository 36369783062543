import React, { useEffect } from 'react';
import classNames from 'clsx';

import { useStore } from '../../../global/stores/store/StoreContext';
import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';

import { feSearchConfig } from './form-config';

import classes from '../../styles.module.scss';

const SearchForm = ({ tableLoading }) => {
  const { statesLoading = false, updateStates } = useStore();

  let visit = 0;
  useEffect(() => {
    if (visit === 0) {
      updateStates();
      visit += 1;
    }
  }, []);

  return (
    <div className={classNames(classes.searchForm)}>
      <CustomSearchForm
        searchConfig={feSearchConfig}
        initialValues={{
          State: '',
        }}
        loading={statesLoading || tableLoading}
      />
    </div>
  );
};

export default SearchForm;
