import React, { useState } from 'react';
import { Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';

import PageContent from '../../../../shared/components/PageContent';
import { ManualFieldFormInvasiveSpeciesTable } from '../ManualFieldFormInvasiveSpeciesTable';
import { ManualFieldFormAssociatedSpeciesTable } from '../ManualFieldFormAssociatedSpeciesTable';
import { ManualFieldFormDetailTable } from '../ManualFieldFormDetailTable';

import classes from '../../../styles.module.scss';

export const ManualFieldFormDetail = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const ManualFieldFormDetailKey = 'ManualFieldFormDetail';
  const ManualFieldFormAssociatedSpeciesKey =
    'ManualFieldFormAssociatedSpecies';
  const ManualFieldFormInvasiveSpeciesKey = 'ManualFieldFormInvasiveSpecies';

  const [keyTitle, setKeyTitle] = useState(ManualFieldFormDetailKey);
  return (
    <PageContent>
      <div>
        <Title className={classNames(classes.title)} level={3}>
          {t(`FieldDataManual:${keyTitle}`)}
        </Title>
        <p className={classNames(classes.title)}>
          {t(`FieldDataManual:${keyTitle}Description`)}
        </p>
      </div>
      <div className={classNames(classes.title)}>
        <Tabs
          defaultActiveKey={ManualFieldFormDetailKey}
          onChange={(key) => setKeyTitle(key)}
        >
          <TabPane
            tab={t('FieldDataManual:ManualFieldFormDetail')}
            key={ManualFieldFormDetailKey}
          >
            <ManualFieldFormDetailTable />
          </TabPane>
          <TabPane
            tab={t('FieldDataManual:ManualFieldFormAssociatedSpecies')}
            key={ManualFieldFormAssociatedSpeciesKey}
          >
            <ManualFieldFormAssociatedSpeciesTable />
          </TabPane>
          <TabPane
            tab={t('FieldDataManual:ManualFieldFormInvasiveSpecies')}
            key={ManualFieldFormInvasiveSpeciesKey}
          >
            <ManualFieldFormInvasiveSpeciesTable />
          </TabPane>
        </Tabs>
      </div>
    </PageContent>
  );
};
