import React from 'react';
import { Checkbox, DatePicker } from 'antd';
import { getCurrentDate } from '../../../../utils/general'

const today = getCurrentDate();

const disabledDate = (current) => {
  const tooLate = +current.format('YYYYMMDD') > +today;

  return tooLate;
};

export const formConfig = [
  {
    name: 'blocked',
    label: 'Blocked',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
  },
  {
    name: 'startDate',
    label: 'Start Date',
    input: ({ value, ...props }) => (
      <DatePicker
        value={value}
        {...props}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  {
    name: 'endDate',
    label: 'End Date',
    input: ({ value, ...props }) => (
      <DatePicker
        value={value}
        {...props}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
];
