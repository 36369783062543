import fieldFormConfig from '../../../FieldForm/form-config';

const unnecessaryFields = ['eokey'];

export const formConfig = Object.keys(fieldFormConfig)
  .filter((field) => !unnecessaryFields.includes(field))
  .map((name) => ({
    name,
    ...fieldFormConfig[name],
  }));
