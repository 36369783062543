import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: t('table:jobTitle'),
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      sorter: true,
    },
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t('table:fax'),
      dataIndex: 'fax',
      key: 'fax',
      sorter: true,
    },
  ];
};
