import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import { Tabs } from 'antd';
import classNames from 'clsx';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { paramsForBE } from '../../../utils/general';
import TableUnderTab from '../../pages/TableUnderTab';
import PageContent from '../../../shared/components/PageContent';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';

import classes from '../../styles.module.scss';

const { TabPane } = Tabs;

const fetchPastYearPermit = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PAST_YEAR_PERMITS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchCurrentYearPermit = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_CURRENT_YEAR_PERMITS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExportPastYearPermit = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYS_PAST_YEAR_PERMIT_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchExportCurrentYearPermit = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_CURRENT_YEARS_PERMIT_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const PastPermits = () => {
  const { t } = useTranslation();
  const { search: searchParams } = useLocation();
  const defaultSortFields = [
    'LastName',
    'FirstName',
    'Organization',
    'SubOrganization',
  ];
  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdministration:PastPermits')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVAdministration:PastPermitsDescription')}
      </p>

      {SearchForm && SearchForm(false)}

      {searchParams && (
        <Tabs className={classNames(classes.tabs)}>
          <TabPane
            tab={t('PCVAdministration:CurrentPermitsYear')}
            key="CurrentPermits"
          >
            <TableUnderTab
              fetchData={(args) => fetchCurrentYearPermit(args)}
              defaultSortField={defaultSortFields}
              fetchExport={fetchExportCurrentYearPermit}
              searchForm={SearchForm}
              beSearchConfig={beSearchConfig}
              paramsForBE={paramsForBE}
              columns={columns}
              scroll={{ x: 2000 }}
            />
          </TabPane>

          <TabPane
            tab={t('PCVAdministration:PastPermitsYear')}
            key="PastPermits"
          >
            <TableUnderTab
              fetchData={(args) => fetchPastYearPermit(args)}
              defaultSortField={defaultSortFields}
              fetchExport={fetchExportPastYearPermit}
              searchForm={SearchForm}
              beSearchConfig={beSearchConfig}
              paramsForBE={paramsForBE}
              columns={columns}
              scroll={{ x: 2000 }}
            />
          </TabPane>
        </Tabs>
      )}
    </PageContent>
  );
};

export default PastPermits;
