import React from 'react';
import moment from 'moment';

import StatesSelect from '../../../shared/components/select/StatesSelect';
import { getRequiredErrMsg } from '../../../utils/general';

const minYear = +moment().format('YYYY') - 30;
const maxYear = +moment().format('YYYY') + 30;

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'EO Species name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'Town',
    label: 'EO town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SiteName',
    label: 'EO sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const editableCellsConfig = [
  {
    name: 'nextSurveyYear',
    rules: [
      {
        type: 'number',
        validator: (_, value) =>
          (value >= minYear && value <= maxYear) || value === 0
            ? Promise.resolve()
            : Promise.reject(
                new Error(
                  `NextSurveyYear must be between ${minYear} and ${maxYear} or 0`
                )
              ),
      },
      {
        required: true,
        message: getRequiredErrMsg('NextSurveyYear'),
      },
    ],
  },
  {
    name: 'nextSeedYear',
    rules: [
      {
        type: 'number',
        validator: (_, value) =>
          (value >= minYear && value <= maxYear) || value === 0
            ? Promise.resolve()
            : Promise.reject(
                new Error(
                  `NextSeedYear must be between ${minYear} and ${maxYear} or 0`
                )
              ),
      },
      {
        required: true,
        message: getRequiredErrMsg('NextSeedYear'),
      },
    ],
  },
  {
    name: 'surveyInterval',
    rules: [
      {
        type: 'number',
        min: -1,
        max: 30,
        message: `SurveyInterval must be between -1 and 30`,
      },
      {
        required: true,
        message: getRequiredErrMsg('SurveyInterval'),
      },
    ],
  },
  {
    name: 'seedInterval',
    rules: [
      {
        type: 'number',
        min: -1,
        max: 30,
        message: `SeedInterval must be between -1 and 30`,
      },
      {
        required: true,
        message: getRequiredErrMsg('SeedInterval'),
      },
    ],
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'SpeciesName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Synonyms',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
];
