import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/extensions
import './i18n';
import './index.css';
import App from './app/App';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('app')
);
