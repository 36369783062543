import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      width: 60,
      sorter: true,
    },
    {
      title: t('table:ctsRank'),
      dataIndex: 'ctsRank',
      key: 'ctsRank',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:ctCount'),
      dataIndex: 'ctCount',
      key: 'ctCount',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:maSRank'),
      dataIndex: 'maSRank',
      key: 'maSRank',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:maCount'),
      dataIndex: 'maCount',
      key: 'maCount',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:meSRank'),
      dataIndex: 'meSRank',
      key: 'meSRank',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:meCount'),
      dataIndex: 'meCount',
      key: 'meCount',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:nhSRank'),
      dataIndex: 'nhSRank',
      key: 'nhSRank',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:nhCount'),
      dataIndex: 'nhCount',
      key: 'nhCount',
      width: 70,
      sorter: true,
    },

    {
      title: t('table:riSRank'),
      dataIndex: 'riSRank',
      key: 'riSRank',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:riCount'),
      dataIndex: 'riCount',
      key: 'riCount',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:vtSRank'),
      dataIndex: 'vtSRank',
      key: 'vtSRank',
      width: 70,
      sorter: true,
    },

    {
      title: t('table:vtCount'),
      dataIndex: 'vtCount',
      key: 'vtCount',
      width: 70,
      sorter: true,
    },
  ];
};
