import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = ['speciesName', 'fullText'];

const ResearchQuestions = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('AssignmentSummary:ResearchQuestionsDescription')}</p>
      <ContainerWithSimpleTable
        data={data}
        loading={false}
        fields={fields}
        rowKey={uuidv4()}
        scroll={false}
        fullWidth
      />
    </>
  );
};

export default ResearchQuestions;
