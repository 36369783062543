import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const TranscribersSelect = (props) => {
  const { transcribersData, transcribersLoading, updateTranscribers } =
    useStore();

  useEffect(() => {
    if (!transcribersData.length && !transcribersLoading) updateTranscribers();
  }, []);

  return (
    <Select
      props={{
        ...props,
        withNone: true,
      }}
      width={200}
      data={transcribersData}
      loading={transcribersLoading}
    />
  );
};

export default TranscribersSelect;
