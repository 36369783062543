import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const MoistureRegimesSelect = (props) => {
  const { moistureRegimesData, moistureRegimesLoading, updateMoistureRegimes } =
    useStore();

  useEffect(() => {
    if (!moistureRegimesData.length && !moistureRegimesLoading)
      updateMoistureRegimes();
  }, []);

  return (
    <Select
      props={props}
      data={moistureRegimesData}
      loading={moistureRegimesLoading}
    />
  );
};

export default MoistureRegimesSelect;
