import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Space } from 'antd';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { paramsForBE } from '../../../../utils/general';
import { action } from '../../../../shared/EditableTable/components/Action';

import PageWithTable from '../../../pages/PageWithTable';

import { actionContent } from '../utils';
import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYOR_ASSIGNMENT, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const SurveyorAssignment = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 50,
      render: (_, { eokey }) => (
        <Space size="middle">
          <Link to={`${pathname}/${eokey}`}>{t('table:select')}</Link>
        </Space>
      ),
    },
    ...staticColumns,
    action({
      title: t('table:pdf'),
      key: 'operation',
      width: 65,
      renderFunction: (_, record) => actionContent(record),
    }),
  ];

  return (
    <PageWithTable
      fetchData={fetch}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="PCVAdmin:SurveyorAssignment"
      descriptionKey="PCVAdmin:SurveyorAssignmentDescription"
      searchForm={SearchForm}
      columns={allColumns}
      rowKey={(record) => record.eokey}
      scroll={{ x: 2000 }}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
    />
  );
};

export default SurveyorAssignment;
