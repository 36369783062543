import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const EOStatusesSelect = (props) => {
  const { eoStatusesData, eoStatusesLoading, updateEOStatuses } = useStore();

  useEffect(() => {
    if (!eoStatusesData.length && !eoStatusesLoading) updateEOStatuses();
  }, [eoStatusesLoading]);

  return (
    <Select
      props={props}
      width={150}
      data={eoStatusesData}
      loading={eoStatusesLoading}
    />
  );
};

export default EOStatusesSelect;
