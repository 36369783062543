import React from 'react';

import StatesSelect from '../../../../shared/components/select/StatesSelect';
import OwnerTypesSelect from '../../../../shared/components/select/OwnerTypesSelect';

export const feSearchConfig = [
  {
    name: 'LastName',
    label: 'Owner or organization name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'OwnerType',
    label: 'Owner Type',
    input: (props) => <OwnerTypesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'LastName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'OwnerType',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'FirstName',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Equals',
    propertyName: 'OwnerType',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SubOrganization',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Equals',
    propertyName: 'OwnerType',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Organization',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Equals',
    propertyName: 'OwnerType',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
];
