import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'state',
  'totalEos',
  'eosWithNumbers',
  'eosWithoutNumbers',
  'nptsubpops',
  'stateSubpops',
  'eoidmismatch',
  'eoerror',
  'eorejectedFrom',
  'eosentToState',
];
const fetchElementOccurrenceStateDataMismatch = () =>
  APIFetch(ENDPOINT_URLS.GET_EL_OCC_STATE_DATA_MISMATCH, {
    method: 'get',
  });

const fetchExport = () =>
  APIFetch(ENDPOINT_URLS.GET_EL_OCC_STATE_DATA_MISMATCH_EXCEL, {
    method: 'get',
  });

export const StateDataMismatch = () => {
  const { data, loading } = useAPI(
    () => fetchElementOccurrenceStateDataMismatch(),
    {
      initialState: { data: [], loading: false },
    }
  );

  const tableRows = highlightTotalValue('state', data);

  return (
    <PageWithSimpleTable
      titleKey="ElementOccurrence:ElementOccurrenceStateDataMismatch"
      descriptionKey="ElementOccurrence:ElementOccurrenceStateDataMismatchDescription"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
      fetchExport={fetchExport}
    />
  );
};
