import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SurveyResultsSelect = (props) => {
  const { surveyResultsData, surveyResultsLoading, updateSurveyResults } =
    useStore();

  useEffect(() => {
    if (!surveyResultsData.length && !surveyResultsLoading)
      updateSurveyResults();
  }, []);

  return (
    <Select
      width={120}
      props={props}
      data={surveyResultsData}
      loading={surveyResultsLoading}
    />
  );
};

export default SurveyResultsSelect;
