import React, { useState, useEffect } from 'react';
import { Popconfirm, Typography, Button } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';

// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
// eslint-disable-next-line import/no-cycle
import { useAuth } from '../../../../global/stores/auth';
import { roleTypes } from '../../../../utils/roles';
// eslint-disable-next-line import/no-cycle
import PageWithNestedTable from '../../../pages/PageWithNestedTable';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { openNotificationWithIcon } from '../../../../utils/general';

import { columns, expandableColumns } from './TableColumns';

import classes from '../../../styles.module.scss';

const defaultSortFields = ['ProgramDate', 'State', 'ProgramName'];
const defaultBodyRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: defaultSortFields.map((propertyName) => ({
    orderOption: 'Ascending',
    propertyName,
  })),
};

const fetch = (args) =>
  APIFetch(ENDPOINT_URLS.GET_PCV_PROGRAMS_PAGED, {
    method: 'post',
    body: JSON.stringify(args || defaultBodyRequest),
  });

const fetchNestedData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.ATTENDS_PCV_PROGRAM(key), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'LastName',
            },
          ],
        }),
      })
    : {};

const fetchDeletePCVProgram = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.DELETE_PCV_PROGRAM(id), {
        method: 'delete',
      })
    : null;

const PCVPrograms = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const columnsForNestedTable = expandableColumns;

  const { data: deleteData, refetch: refetchDelete } = useAPI(
    (id) => fetchDeletePCVProgram(id),
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  useEffect(() => {
    if (deleteData) {
      if (deleteData?.id) {
        openNotificationWithIcon(
          'success',
          'PCV Program was deleted successfully'
        );

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', 'Failed delete PCV Program');
      }
    }
  }, [deleteData]);

  const actions = ({ id }) => (
    <Typography.Link>
      <Popconfirm
        placement="top"
        title="Delete this item?"
        onConfirm={() => refetchDelete(id)}
        okText="Yes"
        cancelText="No"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a
          style={{
            marginRight: 8,
          }}
        >
          {t('table:delete')}
        </a>
      </Popconfirm>
      <Link
        style={{
          marginRight: 8,
        }}
        to={`${pathname}/${id}`}
      >
        {t('table:edit')}
      </Link>
    </Typography.Link>
  );

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const createPCVProgram = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  return (
    <PageWithNestedTable
      fetchData={fetch}
      fetchNestedData={fetchNestedData}
      defaultSortField={defaultSortFields}
      nestedTableKey="id"
      titleKey="PCVAdmin:PCVPrograms"
      descriptionKey="PCVAdmin:PCVProgramsDescription"
      columns={columns()}
      expandableColumns={
        role === roleTypes.Transcriber
          ? columnsForNestedTable
          : expandableColumns
      }
      rowKeyFunc={(record) => record.id}
      scroll={{ x: 1700 }}
      actionsColWidth={250}
      actions={actions}
      updateTableData={isForceRefetch}
    >
      <div className={classNames(classes.content)}>
        <Button onClick={createPCVProgram}>Add new program</Button>
      </div>
    </PageWithNestedTable>
  );
};

export default PCVPrograms;
