import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Spin, Tooltip, Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { useAPI } from '../../shared/hooks/use-api/useAPI';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
  getSortedFields,
} from '../../shared/sortAndSearchUtils';
import { downloadExcel, getIsForbidden } from '../../utils/general';
// eslint-disable-next-line import/no-cycle
import PageContent from '../../shared/components/PageContent';
import { useAuth } from '../../global/stores/auth';

import classes from '../styles.module.scss';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const PageWithTable = ({
  fetchData,
  fetchExport,
  defaultSortField,
  titleKey,
  descriptionKey,
  paramsForBE,
  beSearchConfig,
  searchForm,
  columns,
  rowKey,
  scroll,
  isForceRefetch,
  forceLoading,
  isDataArray,
  children,
  tableInfo,
  showChildrenWithTable,
  resetOnAction,
  checkFetchParams,
  onlyOrderParams,
  isWithoutPageContent,
  fetchExportWithoutPaginationInfo,
  ...other
}) => {
  const { t } = useTranslation();
  const { pathname, search: searchParams } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading, refetch } = useAPI(fetchData, {
    initialState: { data: isDataArray ? [] : {}, loading: false },
    shouldFetch: false,
  });

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [searchParameters, setSearchParameters] = useState({});

  const [pagination, setPagination] = useState(initialPagination);
  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;

    const { sortField, sortOrder } = parameters;
    refetch(
      onlyOrderParams
        ? getSortedFields(sortField, defaultSortField, sortOrder)
        : paramsToPassInBodyRequest(
            checkFetchParams ? checkFetchParams(parameters) : parameters,
            searchForm ? searchParam : null,
            defaultSortField
          )
    );
  };

  const [paginationForRefetch, setPaginationForRefetch] =
    useState(initialPagination);
  const handleTableChange = (paginationForRequest, filters, sorter) => {
    setPaginationForRefetch(paginationForRequest);
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: paginationForRequest,
      ...filters,
    });
  };

  useEffect(() => {
    if (role) {
      if (!searchForm && !isForbidden) {
        refetch(
          onlyOrderParams
            ? getSortedFields(null, defaultSortField, null)
            : paramsToPassInBodyRequest(
                {
                  pagination: initialPagination,
                },
                null,
                defaultSortField
              )
        );
      }
    }
  }, [role]);

  useEffect(() => {
    if (!isDataArray && Object.keys(data).length) {
      setPagination({
        pageSize: data?.pagedInfo?.pageSize,
        pageNumber: data?.pagedInfo?.pageNumber,
        total: data?.totalItemsCount,
      });
    }
  }, [data]);

  useEffect(() => {
    if (searchForm) {
      if (resetOnAction) resetOnAction();

      if (Object.keys(data).length && !searchParams) {
        refetch();

        return;
      }

      const searchParamsArray = buildSearchParamsForRequest(searchParams);

      if (searchParamsArray.length || Object.keys(data).length) {
        refetch(
          paramsToPassInBodyRequest(
            {
              pagination: initialPagination,
            },
            paramsForBE
              ? paramsForBE(searchParamsArray, beSearchConfig)
              : searchParamsArray,
            defaultSortField
          )
        );

        setSearchParameters(searchParamsArray);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isForceRefetch) {
      const searchArgs = paramsForBE
        ? paramsForBE(searchParameters, beSearchConfig)
        : searchParameters;

      refetch(
        !isDataArray
          ? paramsToPassInBodyRequest(
              {
                pagination: paginationForRefetch,
              },
              Object.keys(searchParameters).length ? searchArgs : null,
              defaultSortField
            )
          : null
      );
    }
  }, [isForceRefetch]);

  const onExport = () => {
    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    refetchExport(
      onlyOrderParams
        ? getSortedFields(null, defaultSortField, null)
        : paramsToPassInBodyRequest(
            {
              pagination: initialPagination,
            },
            paramsForBE
              ? paramsForBE(searchParamsArray, beSearchConfig)
              : searchParamsArray,
            defaultSortField,
            fetchExportWithoutPaginationInfo
          )
    );
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  const renderContent = () => (
    <>
      {showChildrenWithTable ? children : null}

      {!!fetchExport && (
        <div className={classNames(classes.content)}>
          <Button type="primary" onClick={onExport} loading={exportLoading}>
            Export to Excel
          </Button>
        </div>
      )}
    </>
  );

  // separating JSON text in paragraphs
  const textDescriptionParagraphs = t(descriptionKey).split(" /n ");

  const resultContent = (
    <>
      <Title className={classNames(classes.title)} level={3}>
        {t(titleKey)}
      </Title>
      {descriptionKey && (
        textDescriptionParagraphs.map(text => <p key={uuidv4()} className={classNames(classes.title)}>{text}</p>)
      )}
      {tableInfo && (
        <>
          <br />
          <div className={classNames(classes.tableContainer)}>
            <div className={classNames(classes.tableInfo)}>
              <Tooltip placement="bottomRight" title={tableInfo}>
                <InfoCircleOutlined
                  className={classNames(classes.tableInfoIcon)}
                />
              </Tooltip>
            </div>
          </div>
        </>
      )}
      {searchForm && searchForm({ tableLoading: loading })}

      {!showChildrenWithTable ? children : null}

      {isDataArray ? (
        <>
          {data.length ? (
            <>
              {renderContent()}

              <Table
                {...other}
                columns={columns}
                className={classNames(classes.table)}
                rowKey={() => uuidv4()}
                dataSource={data}
                loading={loading || forceLoading}
                onChange={handleTableChange}
                pagination={false}
                scroll={scroll}
              />
              <p className={classNames(classes.simpleTableTotal)}>
                Total:{' '}
                {data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </p>
            </>
          ) : null}
          {!data.length && !loading ? (
            <p className={classNames(classes.noData)}>{t('table:noData')}</p>
          ) : null}
          {!data.length && loading ? (
            <center>
              <Spin />
            </center>
          ) : null}
        </>
      ) : (
        <>
          {Object.keys(data).length && data.items.length ? (
            <>
              {renderContent()}

              <Table
                {...other}
                columns={columns}
                className={classNames(classes.table)}
                rowKey={(record) => record.eokey || uuidv4()}
                dataSource={data.items}
                loading={loading || forceLoading}
                onChange={handleTableChange}
                pagination={{
                  pageSizeOptions: [20, 50, 100],
                  current: pagination.pageNumber,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
                scroll={scroll}
              />
              <p className={classNames(classes.tableTotal)}>
                Total:{' '}
                {data.totalItemsCount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </p>
            </>
          ) : null}
          {Object.keys(data).length && !data.items.length ? (
            <p className={classNames(classes.noData)}>{t('table:noData')}</p>
          ) : null}
          {!Object.keys(data).length && loading ? (
            <center>
              <Spin />
            </center>
          ) : null}
        </>
      )}
    </>
  );

  return isWithoutPageContent ? (
    resultContent
  ) : (
    <PageContent>{resultContent}</PageContent>
  );
};
export default PageWithTable;
