import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../config';
import Section from '../Section';

const SpeciesPage = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Section
        config={sectionsConfig().species}
        title={t('sections:Species:title')}
      />
    </PageContent>
  );
};

export default SpeciesPage;
