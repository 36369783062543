import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Autocomplete } from './Autocomplete';

const SurveyorsAutocomplete = (props) => {
  const { surveyorsData, surveyorsLoading, updateSurveyors } = useStore();

  useEffect(() => {
    if (!surveyorsData.length && !surveyorsLoading) updateSurveyors();
  }, []);

  return (
    <Autocomplete
      props={props}
      data={surveyorsData}
      loading={surveyorsLoading}
    />
  );
};

export default SurveyorsAutocomplete;
