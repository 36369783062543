import React from 'react';

import {
  CLOSED_QUESTIONS,
  POSITIVE_OPTION,
  NEGATIVE_OPTION,
} from '../../../../global/constants';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import ValidationDataForm from '../../ValidationDataPages/ValidationDataForm';

import { formConfig } from './form-config';

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_REPORT_METHOD, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (id, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_REPORT_METHOD(id), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.GET_REPORT_METHOD_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'ReportMethod1' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            {
              operatortype: 'Equals',
              propertyName: 'ReportMethod1',
              value: id,
            },
          ],
        }),
      })
    : {};

const ReportMethodForm = () => {
  const getParamsForInitialValues = (isUpdateMode, params) => {
    if (!isUpdateMode) {
      return {
        display: CLOSED_QUESTIONS[0].itemKey,
      };
    }

    const { display, ...other } = params;

    return {
      ...other,
      display: display ? POSITIVE_OPTION : NEGATIVE_OPTION,
    };
  };

  const getParamsForSubmit = (isUpdateMode, params) => {
    const { description, display } = params;

    return isUpdateMode
      ? { description, display: display === POSITIVE_OPTION }
      : { ...params, display: display === POSITIVE_OPTION };
  };

  const fieldsValueOnClear = {
    description: '',
    display: POSITIVE_OPTION,
  };

  return (
    <ValidationDataForm
      fetch={fetch}
      fetchCreate={fetchCreate}
      fetchUpdate={fetchUpdate}
      pageKey="ReportMethod"
      pageName="Report Method"
      idKey="reportMethod1"
      formConfig={formConfig}
      getParamsForInitialValues={getParamsForInitialValues}
      getParamsForSubmit={getParamsForSubmit}
      disabledFields={['reportMethod1']}
      fieldsValueOnClear={fieldsValueOnClear}
    />
  );
};

export default ReportMethodForm;
