import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const AllMgtFieldDataTypesSelect = (props) => {
  const {
    allMgtFieldDataTypesData,
    allMgtFieldDataTypesLoading,
    updateAllMgtFieldDataTypes,
  } = useStore();

  useEffect(() => {
    if (!allMgtFieldDataTypesData.length && !allMgtFieldDataTypesLoading)
      updateAllMgtFieldDataTypes();
  }, []);

  return (
    <Select
      props={props}
      data={allMgtFieldDataTypesData}
      loading={allMgtFieldDataTypesLoading}
    />
  );
};

export default AllMgtFieldDataTypesSelect;
