import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general'

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:transcriptionDone'),
      dataIndex: 'transcriptionDone',
      key: 'transcriptionDone',
      sorter: true,
      render: (_, { transcriptionDone }) => (transcriptionDone ? 'Yes' : 'No'),
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:fullFilename'),
      dataIndex: 'fullFilename',
      key: 'fullFilename',
      render: (text, { fileLink }) => {
        // eslint-disable-next-line react/destructuring-assignment
        const intermediateResultText = text.replace(/%20/g, ' ');
        const resultText = intermediateResultText.replace(/%/g, ' ');
        const condition = fileLink && text;
        return condition ? <a href={fileLink}>{resultText}</a> : text;
      },
    },
  ];
};
