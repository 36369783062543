const getPropertyName = (name) => name[0].toUpperCase() + name.substring(1);

export const getSortedFields = (field, defaultField, order) => {
  const sortField = field || defaultField;
  const orderOption = order === 'ascend' || !field ? 'Ascending' : 'Descending';

  if (Array.isArray(sortField)) {
    return sortField.map((i) => ({
      orderOption,
      propertyName: getPropertyName(i),
    }));
  }

  return [
    {
      orderOption,
      propertyName: getPropertyName(sortField),
    },
  ];
};

export const paramsToPassInBodyRequest = (
  parameters,
  searchParameters,
  defaultSortField,
  fetchExportWithoutPaginationInfo = false
) => {
  let result = {};
  const { sortField, sortOrder, pagination = {} } = parameters;

  const sortedFields = getSortedFields(sortField, defaultSortField, sortOrder);

  const paginationInfo = {
    orderCriteria: defaultSortField === 'null' ? null : sortedFields,
  };
  let defaultBody = {};

  if (Object.keys(pagination).length) {
    const { pageSize, pageNumber, current } = pagination;

    paginationInfo.pagedInfo = {
      pageSize,
      pageNumber: current || pageNumber,
    };
  }

  if (searchParameters !== undefined && searchParameters !== null) {
    if (Object.keys(searchParameters).length) {
      const searchParams = searchParameters?.filter(
        ({ operatortype, value }) => operatortype && value
      );
      paginationInfo.searchCriteria = searchParams.length
        ? [
            ...searchParams.map(({ value, ...others }) => ({
              ...others,
              value: value === '*' ? '' : value,
            })),
          ]
        : null;

      searchParameters
        .filter(({ operatortype }) => !operatortype)
        .map((param) => {
          const { propertyName: name, value } = param;

          defaultBody[name] = value === '*' ? '' : value;
          return param;
        });
    }
  }

  defaultBody =
    // eslint-disable-next-line no-nested-ternary
    !fetchExportWithoutPaginationInfo
      ? Object.keys(defaultBody).length
        ? { paginationInfo: { ...paginationInfo }, ...defaultBody }
        : { ...paginationInfo }
      : { ...paginationInfo, ...defaultBody };

  if (!sortField) {
    result = defaultBody;
  }

  if (sortField) {
    result = {
      ...defaultBody,
      orderCriteria: sortedFields,
    };
  }

  if (result.paginationInfo && result?.orderCriteria?.length) {
    const correctOrderCriteria = result.orderCriteria;
    delete result.orderCriteria;
    result.paginationInfo.orderCriteria = correctOrderCriteria;
  }

  return result;
};

export const getRandomuserParams = (params) => ({
  results: params?.pagination?.pageSize,
  page: params?.pagination?.current,
  ...params,
});

export const buildSearchParamsForRequest = (searchParams) => {
  const paramsString = searchParams.substring(1);
  const searchParamsFormURL = new URLSearchParams(paramsString);
  const searchParamsArray = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const p of searchParamsFormURL) {
    const [propertyName, propertyDetails] = p;
    const paramValues = propertyDetails.split(',');
    const [operatortype, value, previousSearchBlockConnectionOperator] =
      paramValues;

    const searchParam = {
      operatortype,
      value,
      propertyName,
    };

    if (previousSearchBlockConnectionOperator) {
      searchParam.previousSearchBlockConnectionOperator =
        previousSearchBlockConnectionOperator;
    }

    searchParamsArray.push(searchParam);
  }

  return searchParamsArray;
};
