import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const actionContent = (record, path) => {
  const { t } = useTranslation();

  const { eokey, state, speciesName, eonumber, subpopulation } = record;

  return (
    <Link
      to={`${path}?eokey=${eokey}&name=${state} ${speciesName} ${eonumber} ${subpopulation}`}
    >
      {t('table:select')}
    </Link>
  );
};

export const actionContentFfkey = (record, path) => {
  const { t } = useTranslation();

  const { ffkey } = record;

  return <Link to={`${path}?ffkey=${ffkey}`}>{t('table:select')}</Link>;
};
