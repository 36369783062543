import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Tabs } from 'antd';

import PageContent from '../../../../shared/components/PageContent';

import classes from '../../../styles.module.scss';
import BlockSiteForm from '../BlockSiteForm';
import BlockUsers from '../BlockUsers';

const { TabPane } = Tabs;

const TABS_KEYS = {
  blockSite: 'blockSite',
  blockUsers: 'blockUsers',
};

const Details = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <div className={classNames(classes.title)}>
        <Tabs>
          <TabPane
            tab={t('PCVAdministration:blockSite')}
            key={TABS_KEYS.blockSite}
          >
            <BlockSiteForm />
          </TabPane>
          <TabPane
            tab={t('PCVAdministration:blockUsers')}
            key={TABS_KEYS.blockUsers}
          >
            <BlockUsers />
          </TabPane>
        </Tabs>
      </div>
    </PageContent>
  );
};

export default Details;
