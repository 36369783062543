import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      sorter: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      sorter: true,
    },
    {
      title: t('table:goBotany'),
      dataIndex: 'goBotany',
      key: 'goBotany',
      sorter: true,
    },
    {
      title: t('table:checklist'),
      dataIndex: 'checklist',
      key: 'checklist',
      sorter: true,
    },
    {
      title: t('table:hrp'),
      dataIndex: 'hrp',
      key: 'hrp',
      sorter: true,
    },
    {
      title: t('table:eo'),
      dataIndex: 'eo',
      key: 'eo',
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
    },
    {
      title: t('table:lastObs'),
      dataIndex: 'lastObs',
      key: 'lastObs',
    },
    {
      title: t('table:firstObs'),
      dataIndex: 'firstObs',
      key: 'firstObs',
    },
  ];
};
