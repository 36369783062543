import React, { useEffect } from 'react';

import { useAuth } from '../../../global/stores/auth';
import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SelectWithUserName = (props) => {
  const { surveyorsData, surveyorsLoading, updateSurveyors } = useStore();
  const { user } = useAuth();
  const { firstName, lastName } = user;

  useEffect(() => {
    if (!surveyorsData.length && !surveyorsLoading) updateSurveyors();
  }, []);

  const data = surveyorsData
    .filter((i) => i.item === [lastName, firstName].join(' '))
    .map(({ item }) => ({
      item,
      itemKey: item,
    }));
  data.unshift({ item: '-', itemKey: '-' });

  return (
    <Select props={props} width={110} data={data} loading={surveyorsLoading} />
  );
};

export default SelectWithUserName;
