import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const LocationPrecisionsSelect = (props) => {
  const {
    locationPrecisionsData,
    locationPrecisionsLoading,
    updateLocationPrecisions,
  } = useStore();

  useEffect(() => {
    if (!locationPrecisionsData.length && !locationPrecisionsLoading)
      updateLocationPrecisions();
  }, []);

  return (
    <Select
      props={props}
      data={locationPrecisionsData}
      loading={locationPrecisionsLoading}
    />
  );
};

export default LocationPrecisionsSelect;
