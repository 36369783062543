import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:nextSeedYear'),
      dataIndex: 'nextSeedYear',
      key: 'nextSeedYear',
    },
    {
      title: t('table:nbrEos'),
      dataIndex: 'nbrEos',
      key: 'nbrEos',
    },
  ];
};
