import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:scientificName'),
      dataIndex: 'scientificName',
      key: 'scientificName',
      sorter: true,
    },
    {
      title: t('table:factoid'),
      dataIndex: 'factoid',
      key: 'factoid',
      sorter: true,
    },
    {
      title: t('table:wetlandIndicatorCode'),
      dataIndex: 'wetlandIndicatorCode',
      key: 'wetlandIndicatorCode',
      sorter: true,
      width: 200,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:lookalikeScientificName'),
      dataIndex: 'lookalikeScientificName',
      key: 'lookalikeScientificName',
      width: 250,
    },
    {
      title: t('table:lookalikeCharacteristic'),
      dataIndex: 'lookalikeCharacteristic',
      key: 'lookalikeCharacteristic',
    },
  ];
};
