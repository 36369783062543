import { Fetch } from '../fetch/Fetch';
// eslint-disable-next-line import/no-cycle
import {
  addDefaultFetchOptions,
  addRequestId,
  addAuthHeaders,
  checkForErrors,
} from './APIFetchDecorators';

const chain = (fns) => (arg) => {
  const initial = Promise.resolve(arg);
  return fns.reduce(
    (promise, f) => promise.then((args) => f(...args)),
    initial
  );
};

const preProcess = chain([
  addDefaultFetchOptions,
  addRequestId,
  addAuthHeaders,
]);
const postProcess = chain([checkForErrors]);

// eslint-disable-next-line no-unused-vars
export async function APIFetch(...args) {
  try {
    const proceedArgs = await preProcess(args);
    const [req, options] = proceedArgs;

    const response = await Fetch(req, options);
    const proceedResponse = [proceedArgs, response];
    const [, proceedRet] = await postProcess(proceedResponse);
    return proceedRet;
  } catch (e) {
    return e;
  }
}
