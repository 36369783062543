import React from 'react';

import OwnersAutocomplete from '../../../../shared/components/autocomplete/OwnersAutocomplete';
import SourceOwnersSelect from '../../../../shared/components/select/SourceOwnersSelect';
import { Select } from '../../../../shared/components/select';
import { dataYesNoWithBooleanKeyNone } from '../../../../global/constants';

export const formConfig = [
  {
    name: 'ownerKey',
    label: 'Owner',
    input: (props) => <OwnersAutocomplete {...props} width="120%" />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'assessorLot',
    label: 'AssesorLot',
    rules: [
      {
        max: 50,
        message: 'Length of Assesor Lot should be less than 50 symbols',
      },
    ],
  },
  {
    name: 'sourceOwner',
    label: 'Source Owner',
    input: (props) => <SourceOwnersSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'currentOwner',
    label: 'Current Owner',
    input: (props) => (
      <Select props={props} data={dataYesNoWithBooleanKeyNone} />
    ),
    rules: [
      {
        required: true,
      },
    ],
  },
];
