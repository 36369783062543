export const feSearchConfig = [
  {
    name: 'LastName',
    label: 'Surveyor name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'LastName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'FirstName',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
];
