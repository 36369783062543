import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import classes from '../../views/styles.module.scss';
import { useAPI } from '../hooks/use-api/useAPI';

export const ExpandableTable = ({
  columns,
  expandableColumns,
  dataSource,
  loading,
  fetchNestedData,
}) => {
  const { t } = useTranslation();
  const {
    data: nestedData,
    loading: loadingNestedData,
    refetch: refetchNestedData,
  } = useAPI(fetchNestedData, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });
  const [newData, setNewData] = useState(nestedData);
  const [rowKey, setRowKey] = useState();

  const getNestedData = (record) => {
    const key = record ? record.ffkey : undefined;
    if (rowKey && rowKey === key) {
      setRowKey()
    } else {
      setRowKey(key);
    }
    refetchNestedData(key)
  };

  useEffect(() => nestedData.length && setNewData(nestedData), [nestedData]);

  return (
    <Table
      className={classNames(classes.table)}
      columns={columns(getNestedData)}
      expandable={{
        expandedRowRender: (_, index, indent, expanded) => 
        expanded ? (
          <>
            {newData.length || loadingNestedData ? (
              <Table
                className={classNames(classes.expandedTable)}
                loading={loadingNestedData}
                columns={expandableColumns}
                dataSource={newData}
                scroll={{ x: 1300 }}
                pagination={false}
                rowKey={() => uuidv4()}
              />
            ) : (
              <p className={classNames(classes.noDataNestedTable)}>
                {t('table:noData')}
              </p>
            )}
          </>
        ) : null,
        expandedRowClassName: () => 'expanded-row',
        expandedRowKeys: rowKey ? [rowKey] : [],
        expandIcon: () => null,
      }}
      dataSource={dataSource}
      rowKey={(record) => record.ffkey}
      pagination={false}
      loading={loading}
    />
)};