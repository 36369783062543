import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithNestedTable from '../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchDistributionAllSources = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_DISTRIBUTION_ALL_SOURCES_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_DISTRIBUTION_ALL_SOURCES_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchDistributionAllSourcesDetails = (key, params) => {
  const { State, SpeciesName } = params;
  return key && params
    ? APIFetch(ENDPOINT_URLS.GET_DISTRIBUTION_ALL_SOURCES_DETAILS_PAGED, {
        method: 'post',
        body: JSON.stringify({
          searchCriteria: [
            {
              operatortype: 'Equals',
              propertyName: 'State',
              value: State,
            },
            {
              operatortype: 'Contains',
              propertyName: 'SpeciesName',
              value: SpeciesName,
            },
            {
              operatortype: 'Contains',
              propertyName: 'County',
              value: key,
            },
          ],
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'State',
            },
          ],
        }),
      })
    : [];
};

const DistributionAllSources = () => {
  const parametersForNestedTableRequest = ['state', 'speciesName'];

  return (
    <PageWithNestedTable
      fetchData={(args) => fetchDistributionAllSources(args)}
      fetchNestedData={(key, params) =>
        fetchDistributionAllSourcesDetails(key, params)
      }
      fetchExport={fetchExport}
      defaultSortField={['SpeciesName', 'State', 'County']}
      nestedTableKey="county"
      titleKey="StatusOfPlants:DistributionAllSources"
      descriptionKey="StatusOfPlants:DistributionAllSourcesDescription"
      searchForm={SearchForm}
      columns={columns()}
      expandableColumns={expandableColumns()}
      rowKeyFunc={({ county, speciesName, state }) =>
        `${county}-${speciesName}-${state}`
      }
      scroll={{ x: 1300 }}
      parametersForNestedTableRequest={parametersForNestedTableRequest}
    />
  );
};

export default DistributionAllSources;
