import React from 'react';

import RolesSelect from '../../../../shared/components/select/RolesSelect';

export const formConfig = [
  {
    name: 'email',
    label: 'Email',
    rules: [
      {
        required: true,
      },
      {
        type: 'email',
      },
    ],
  },
  {
    name: 'role',
    label: 'Role',
    input: (props) => <RolesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
];
