import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { useLocation } from 'react-router-dom';
import { Button, Form } from 'antd';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import PageContent from '../../../shared/components/PageContent';
import {
  buildSearchParamsForRequest,
  getRandomuserParams,
  paramsToPassInBodyRequest,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { ExpandableEditableTable } from '../../../shared/ExpandableEditableTable';
import {
  downloadExcel,
  openNotificationWithIcon,
  paramsForBE,
} from '../../../utils/general';

import SearchForm from './SearchForm';
import { columns, expandableColumns } from './TableColumns';
import { editableCellsConfig, beSearchConfig } from './form-config';
import { actionContent } from './utils';

import classes from '../../styles.module.scss';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchReportedData = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_REPORTED_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchReportedDetails = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_REPORTED_DETAILS(key), {
        method: 'post',
        body: JSON.stringify(
          ['State', 'SpeciesName', 'EonumberInt', 'Subpopulation'].map(
            (propertyName) => ({
              propertyName,
              orderOption: 'Ascending',
            })
          )
        ),
      })
    : [];

const fetchSendEmail = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.SEND_OWNER_EMAIL(key), {
        method: 'post',
      })
    : null;

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_REPORT_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const OwnerReport = () => {
  const { t } = useTranslation();
  const { search: searchParams, pathname } = useLocation();

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);

  const { data, loading, refetch } = useAPI((args) => fetchReportedData(args), {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: nestedData,
    loading: loadingNestedData,
    refetch: refetchNestedData,
  } = useAPI((key) => fetchReportedDetails(key), {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: sendEmailData,
    loading: sendEmailLoading,
    refetch: refetchSendEmail,
  } = useAPI(fetchSendEmail, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const defaultSortField = [
    'LastName',
    'FirstName',
    'Organization',
    'SubOrganization',
  ];

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [sortParameters, setSortParameters] = useState({});
  const [searchParameters, setSearchParameters] = useState({});

  const [pagination, setPagination] = useState(initialPagination);
  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;

    refetch(
      paramsToPassInBodyRequest(parameters, searchParam, defaultSortField)
    );

    setIsRefetchDone(true);

    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  const [rowKey, setRowKey] = useState();
  const getNestedData = ({ ownerKey }) => {
    if (rowKey && rowKey === ownerKey) {
      setRowKey();
    } else {
      refetchNestedData(ownerKey);

      setRowKey(ownerKey);
    }
  };

  const cancel = () => {
    setEditingKey('');
    setRowKey();
  };

  const handleTableChange = (paginationForRequest, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: paginationForRequest,
      ...filters,
    });

    setSortParameters({
      sortField: sorter.field,
      sortOrder: sorter.order,
    });

    cancel();
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      setData(data.items);
      setPagination({
        pageSize: data.pagedInfo.pageSize,
        pageNumber: data.pagedInfo.pageNumber,
        total: data.totalItemsCount,
      });
    }
  }, [data]);

  const initialState = {
    dateReportSent: '',
  };

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      cancel();

      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);
    if (searchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          paramsForBE
            ? paramsForBE(searchParamsArray, beSearchConfig)
            : searchParamsArray,
          defaultSortField
        )
      );
      setSearchParameters(searchParamsArray);
      cancel();
    }
  }, [searchParams]);

  const onExport = () => {
    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    refetchExport(
      paramsToPassInBodyRequest(
        { pagination: initialPagination },
        paramsForBE
          ? paramsForBE(searchParamsArray, beSearchConfig)
          : searchParamsArray,
        defaultSortField
      )
    );
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  const save = async (key) => {
    const row = await form.validateFields();

    APIFetch(ENDPOINT_URLS.UPDATE_DATE_REPORT(key), {
      method: 'put',
      body: JSON.stringify(row),
    }).then(({ status }) => {
      if (status) {
        const newData = [...tableData];
        const index = newData.findIndex((item) => key === item.ownerKey);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      }
    });
  };

  useEffect(() => {
    if (typeof sendEmailData === 'boolean' && !sendEmailLoading) {
      if (sendEmailData) {
        openNotificationWithIcon('success', 'Email was sent successfully');

        fetch({ pagination, ...sortParameters });
      } else {
        openNotificationWithIcon('error', 'Failed send email');
      }
    }
  }, [sendEmailLoading]);

  const allColumns = columns();
  const columnsForTable = [
    {
      title: t('table:action'),
      key: 'operation',
      width: 230,
      render: (_, record) => {
        const { ownerKey } = record;

        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone,
          `${ownerKey}`,
          (rec) => `${rec.ownerKey}`
        );

        return (
          <span className={classNames(classes.actionsRow)}>
            {actionContent(
              save,
              editable,
              record,
              cancel,
              editingKey,
              form,
              initialState,
              setEditingKey,
              getNestedData,
              refetchSendEmail
            )}
          </span>
        );
      },
    },
    ...allColumns,
  ];

  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        const { ownerKey } = record;
        return {
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isCellEditing(
            record,
            editingKey,
            null,
            null,
            null,
            `${ownerKey}`
          ),
        };
      },
    };
  });

  const columnsForNestedTable = expandableColumns();

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdministration:OwnerReport')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVAdministration:OwnerReportDescription')}
      </p>

      <SearchForm tableLoading={loading} />

      <Form form={form} component={false}>
        {Object.keys(data).length && data.items.length ? (
          <>
            <div className={classNames(classes.content)}>
              <Button type="primary" onClick={onExport} loading={exportLoading}>
                Export to Excel
              </Button>
            </div>

            <ExpandableEditableTable
              className={classNames(classes.table)}
              columns={mergedColumns}
              dataSource={tableData}
              loading={loading || sendEmailLoading}
              rowKey={(record) => record.ownerKey}
              onChange={handleTableChange}
              scroll={{ x: 1350 }}
              tableLayout="fixed"
              pagination={{
                pageSizeOptions: [20, 50, 100],
                current: pagination.pageNumber,
                pageSize: pagination.pageSize,
                total: pagination.total,
              }}
              data={nestedData}
              expandableColumns={columnsForNestedTable}
              expandableKey={rowKey ? [rowKey] : []}
              loadingNestedData={loadingNestedData}
              editableCellsConfig={editableCellsConfig}
            />
            <p className={classNames(classes.tableTotal)}>
              Total:{' '}
              {data.totalItemsCount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            </p>
          </>
        ) : null}
        {Object.keys(data).length && !data.items.length ? (
          <p className={classNames(classes.noData)}>{t('table:noData')}</p>
        ) : null}
      </Form>
    </PageContent>
  );
};

export default OwnerReport;
