import React from 'react';

import {APIFetch} from '../../../shared/services/api-fetch/APIFetch';
import {useAPI} from '../../../shared/hooks/use-api/useAPI';
import {ENDPOINT_URLS} from '../../../global/api';
import {highlightTotalValue} from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
    "division",
    "speciesNoStateStatus"
];

const fetchSpeciesWithoutStateStatus = () =>
    APIFetch(ENDPOINT_URLS.GET_SPECIES_WITHOUT_STATE_STATUS, {
        method: 'get',
    });

export const SpeciesWithoutStateStatus = () => {
    const {data, loading} = useAPI(() => fetchSpeciesWithoutStateStatus(), {
        initialState: {data: [], loading: false},
    });

    const tableRows = highlightTotalValue('division', data);

    return (
        <PageWithSimpleTable
            titleKey="Species:SpeciesWithoutStateStatus"
            data={tableRows}
            loading={loading}
            fields={fields}
            rowKey={(record) => record.state}
        />
    );
};
