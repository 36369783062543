import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithTable from '../../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_MGT_NOTES_EDIT_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const MgtNotesEdit = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 80,
      render: (_, { eokey, state, speciesName, eonumber, subpopulation }) => (
        <Space size="middle">
          <Link
            to={`${pathname}/${eokey}?name=${state} ${speciesName} ${eonumber} ${subpopulation}`}
          >
            {t('table:select')}
          </Link>
        </Space>
      ),
    },
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={fetch}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="StatusOfPlants:MgtNotesEdit"
      descriptionKey="StatusOfPlants:MgtNotesEditDescription"
      searchForm={SearchForm}
      columns={allColumns}
      scroll={{ x: 1300 }}
    />
  );
};

export default MgtNotesEdit;
