import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Autocomplete } from './Autocomplete';

const SpeciesNamesAutocomplete = (props) => {
  const { speciesNamesData, speciesNamesLoading, updateSpeciesNames } =
    useStore();

  useEffect(() => {
    if (!speciesNamesData.length) updateSpeciesNames();
  }, []);

  return (
    <Autocomplete
      props={props}
      data={speciesNamesData}
      loading={speciesNamesLoading}
    />
  );
};

export default SpeciesNamesAutocomplete;
