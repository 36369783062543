import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
    },
  ];
};
