import React from 'react';
import classNames from 'clsx';

import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';

import { searchFormConfig } from './form-config';

import classes from '../../styles.module.scss';

const fieldWithoutAll = 'NaturalCommunity';

const SearchForm = ({ tableLoading }) => (
  <div className={classNames(classes.searchForm)}>
    <CustomSearchForm
      searchConfig={searchFormConfig}
      loading={tableLoading}
      fieldWithoutAll={fieldWithoutAll}
      initialValues={{
        State: '',
      }}
    />
  </div>
);

export default SearchForm;
