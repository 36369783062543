import React from 'react';
import classNames from 'clsx';

import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';

import { feSearchConfig } from './form-config';

import classes from '../../styles.module.scss';

const SearchForm = ({ tableLoading }) => (
    <div className={classNames(classes.searchForm)}>
        <CustomSearchForm searchConfig={feSearchConfig} loading={tableLoading} />
    </div>
);

export default SearchForm;
