import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, Spin } from 'antd';
import classNames from 'clsx';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC(key), {
        method: 'get',
      })
    : null;

const fetchPaged = (eokey) =>
  eokey && eokey !== 'Create'
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [{ orderOption: 'Ascending', propertyName: 'State' }],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'Eokey', value: eokey },
          ],
        }),
      })
    : {};

export const DisplayForm = () => {
  const [form] = Form.useForm();

  const { eokey } = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState(null);

  const {
    statesData,
    statesLoading = false,
    updateStates,
    speciesNamesData,
    speciesNamesLoading = false,
    updateSpeciesNames,
    countiesData,
    countiesLoading = false,
    updateCounties,
    locationPrecisionsData,
    locationPrecisionsLoading = false,
    updateLocationPrecisions,
    eoStatusesData,
    eoStatusesLoading = false,
    updateEOStatuses,
  } = useStore();

  useEffect(() => {
    if (!statesData.length) updateStates();
    if (!eoStatusesData.length) updateEOStatuses();
    if (!speciesNamesData.length) updateSpeciesNames();
    if (!locationPrecisionsData.length) updateLocationPrecisions();
    if (!countiesData.length) updateCounties();
  }, []);

  const { data, loading } = useAPI(() => fetch(eokey), {
    initialState: { loading: false },
    shouldFetch: true,
  });

  const { data: parentData } = useAPI(() => fetchPaged(eokey), {
    initialState: { loading: false },
    shouldFetch: true,
  });

  useEffect(() => {
    if (parentData?.items.length) {
      const {
        state: itemState,
        speciesName,
        eonumber,
        subpopulation,
      } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `${itemState} ${speciesName} ${eonumber} ${subpopulation}`,
        },
      });
    }
  }, [parentData]);

  useEffect(() => {
    if (
      !statesLoading &&
      statesData.length &&
      !speciesNamesLoading &&
      speciesNamesData.length &&
      !countiesLoading &&
      countiesData.length &&
      !locationPrecisionsLoading &&
      locationPrecisionsData.length &&
      !eoStatusesLoading &&
      eoStatusesData.length &&
      data
    ) {
      const { speciesKey, countyKey, ...other } = data;

      setInitialValues({
        ...other,
        speciesKey: speciesNamesData.find(
          ({ itemKey }) => itemKey === speciesKey
        ).item,
        countyKey: countiesData.find(({ itemKey }) => itemKey === countyKey)
          .item,
      });
    }
  }, [
    data,
    statesData,
    speciesNamesData,
    countiesData,
    locationPrecisionsData,
    eoStatusesData,
  ]);

  return (
    <PageContent>
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues && !loading ? (
            <Form
              {...layout}
              form={form}
              initialValues={initialValues}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  disabled
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};