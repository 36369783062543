import React from 'react';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import ValidationData from '../../../ValidationData/ValidationDataPages/ValidationData';
import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_OUTREACH_ORGANIZATION_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchDelete = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.DELETE_OUTREACH_ORGANIZATION(id), {
        method: 'delete',
      })
    : null;

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_OUTREACH_ORGANIZATION_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const ValidationOutreachOrganization = () => (
  <ValidationData
    pageKey="OutreachOrganization"
    fetchDelete={fetchDelete}
    fetchExport={fetchExport}
    idKey="id"
    addBtnTxt="Add New Outreach Organization"
    fetch={fetch}
    searchForm={SearchForm}
    defaultSortField={['State', 'Organization', 'OutreachOrganizationType']}
    columns={columns()}
    pageName="OutreachOrganization"
    notValidationData="PCVAdministration"
    scroll={{ x: 1000 }}
  />
);

export default ValidationOutreachOrganization;
