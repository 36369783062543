import fieldFormConfig from '../../../FieldForm/form-config';

const unnecessaryFields = ['state', 'speciesKey', 'town', 'siteName', 'eokey'];

export const formConfig = Object.keys(fieldFormConfig)
  .filter((field) => !unnecessaryFields.includes(field))
  .map((name) => ({
    name,
    ...fieldFormConfig[name],
  }));
