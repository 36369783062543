import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      sorter: true,
      width: 60,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      width: 110,
      render: (text) => text ? getMomentDate(text).format('YYYY-MM-DD') : ''
    },
    {
      title: t('table:ramets'),
      dataIndex: 'ramets',
      key: 'ramets',
      sorter: true,
    },
    {
      title: t('table:genets'),
      dataIndex: 'genets',
      key: 'genets',
      sorter: true,
    },
    {
      title: t('table:countPrecision'),
      dataIndex: 'countPrecision',
      key: 'countPrecision',
      sorter: true,
    },
    {
      title: t('table:populationArea'),
      dataIndex: 'populationArea',
      key: 'populationArea',
      sorter: true,
    },
    {
      title: t('table:elevationMin'),
      dataIndex: 'elevationMin',
      key: 'elevationMin',
      sorter: true,
    },
    {
      title: t('table:elevationMax'),
      dataIndex: 'elevationMax',
      key: 'elevationMax',
      sorter: true,
    },
    {
      title: t('table:threatScope'),
      dataIndex: 'threatScope',
      key: 'threatScope',
      sorter: true,
    },
    {
      title: t('table:threatSeverity'),
      dataIndex: 'threatSeverity',
      key: 'threatSeverity',
      sorter: true,
    },
  ];
};
