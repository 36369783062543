import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const FieldDataTypesSelect = (props) => {
  const { fieldDataTypesData, fieldDataTypesLoading, updateFieldDataTypes } =
    useStore();

  useEffect(() => {
    if (!fieldDataTypesData.length && !fieldDataTypesLoading)
      updateFieldDataTypes();
  }, []);

  return (
    <Select
      props={props}
      data={fieldDataTypesData}
      loading={fieldDataTypesLoading}
    />
  );
};

export default FieldDataTypesSelect;
