export const accessByRolesConfig = {
  TF: {
    expect: [
      '/Species/ChecklistSpecies',
      '/Species/ChecklistSynonym',
      '/Species/SpeciesInformation',
      '/Species/SpeciesConservationStatus',
      '/Species/SpeciesInvasiveStatus',
    ],
    notAllowed: [
      '/ElementOccurrence',
      '/Species',
      '/Owners',
      '/Surveyor',
      '/ValidationData',
      '/PCVAdmin',
      '/StatusOfPlants',
    ],
  },
  Transcriber: {
    expect: [
      '/ElementOccurrence/FieldDataManualEnter',
      '/ElementOccurrence/FieldDataManualEdit',
      '/Species/ChecklistSpecies',
      '/Species/ChecklistSynonym',
    ],
    notAllowed: [
      '/ElementOccurrence',
      '/Species',
      '/Owners',
      '/Surveyor',
      '/ValidationData',
      '/PCV',
      '/PCVAdmin',
      '/StatusOfPlants',
    ],
  },
  PCV: {
    expect: [
      '/Species/ChecklistSpecies',
      '/Species/ChecklistSynonym',
      '/Species/SpeciesInformation',
      '/Species/SpeciesConservationStatus',
      '/Species/SpeciesInvasiveStatus',
    ],
    notAllowed: [
      '/ElementOccurrence',
      '/Species',
      '/Owners',
      '/Surveyor',
      '/ValidationData',
      '/PCV/TFSurveyResults',
      '/PCV/TFSurveyPlan',
      '/PCVAdmin',
      '/StatusOfPlants',
    ],
  },
  Heritage: {
    notAllowed: [
      '/ElementOccurrence/FieldDataEdit',
      '/ElementOccurrence/FieldDataManualEnter',
      '/ElementOccurrence/FieldDataManualEdit',
      '/Species/SpeciesInformationEdit',
      '/Owners/OwnerEOCurrentYear',
      '/Surveyor/SurveyorByDistance',
      '/Surveyor/SurveyorsNoState',
      '/ValidationData',
      '/PCV',
      '/PCVAdmin',
      '/StatusOfPlants/EOMaximumExtent',
      '/StatusOfPlants/EOCurrentExtent',
      '/StatusOfPlants/DistributionMap',
      '/StatusOfPlants/MgtNotesEdit',
    ],
  },
};

export const accessByProgressBarConfig = [
  '/FieldFormSubmission/UpdateFieldFormEdit',
  '/FieldFormSubmission/CreateFieldFormEnterPrior',
  '/FieldFormSubmission/CreateFieldFormNew',
  '/FieldFormSubmission/UpdateFieldFormReviewNoEo'
];
