import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useStore } from '../../../../global/stores/store/StoreContext';
import ValidationDataForm from '../../../ValidationData/ValidationDataPages/ValidationDataForm';
import Uploader from '../../../../shared/components/Upload';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';

import { formConfig } from './form-config';

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_FILED_DATA_EDIT, {
        method: 'post',
        initialHeaders: {},
        body: args,
      })
    : null;

const fetchUpdate = (id, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_FILED_DATA_EDIT(id), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = (id, eokey) =>
  id
    ? APIFetch(ENDPOINT_URLS.GET_FILED_DATA_EDIT_PAGED(eokey), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [{ orderOption: 'Ascending', propertyName: 'Year' }],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'Ffkey', value: id },
          ],
        }),
      })
    : {};

const FieldDataForm = () => {
  const { eokey: eokeyFromUrl, key } = useParams();
  const [disabledFields, setDisabledFields] = useState([]);
  const [disabledFieldsInEditMode, setDisabledFieldsInEditMode] = useState([]);

  const IS_CREATE_MODE = key === 'Create';

  const { data: parentChildrenData } = useAPI(() => fetch(key, eokeyFromUrl), {
    initialState: { data: [], loading: false },
    shouldFetch: !IS_CREATE_MODE,
  });

  const {
    fieldDataTypesData,
    fieldDataTypesLoading,
    updateFieldDataTypes,
    sourcesData,
    sourcesLoading,
    updateSources,
  } = useStore();

  useEffect(() => {
    if (!fieldDataTypesData.length) updateFieldDataTypes();
    if (!sourcesData.length) updateSources();
  }, []);

  const getParamsForInitialValues = (isUpdateMode, params) => {
    if (!isUpdateMode) {
      return {
        source: sourcesData[0]?.itemKey,
        fieldDataType: fieldDataTypesData[0]?.itemKey,
      };
    }

    return params;
  };

  const getParamsForSubmit = (isUpdateMode, params) =>
    isUpdateMode ? { ...params } : { ...params, eokey: eokeyFromUrl };

  const config = useMemo(() => {
    if (IS_CREATE_MODE) {
      return [
        ...formConfig,
        {
          name: 'file',
          label: 'File',
          input: (props) => <Uploader {...props} maxCount={1} />,
        },
      ];
    }

    return formConfig;
  }, []);

  const loading = fieldDataTypesLoading || sourcesLoading;

  const onFieldsChange = (form, fieldName, fieldValue) => {
    if (!IS_CREATE_MODE) {
      const { path } = parentChildrenData?.items[0];
      if (path) {
        setDisabledFieldsInEditMode(['source']);
        form.setFieldsValue({
          source: 'SpecFolder',
        });
      }
    }
    if (fieldName === 'file') {
      const { status } = fieldValue.file;

      if (status === 'removed') {
        setDisabledFields([]);
      } else if (!disabledFields.length) {
        setDisabledFields(['source']);

        form.setFieldsValue({
          source: 'SpecFolder',
        });
      }
    }
  };

  return loading ? (
    <Spin />
  ) : (
    <ValidationDataForm
      fetch={fetch}
      fetchCreate={fetchCreate}
      fetchUpdate={fetchUpdate}
      pageKey="FieldData"
      pageName="FieldData"
      idKey="ffkey"
      formConfig={config}
      getParamsForInitialValues={getParamsForInitialValues}
      getParamsForSubmit={getParamsForSubmit}
      keyForParentDataRequest={eokeyFromUrl}
      notValidationData="ElementOccurrence"
      isCreateRequestWithFormData
      isCancelButton
      isUpdateButton
      onFieldsChange={onFieldsChange}
      disabledFieldsInCreateMode={disabledFields}
      disabledFields={disabledFieldsInEditMode}
    />
  );
};

export default FieldDataForm;
