import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      sorter: true,
    },
    {
      title: t('table:propaguleType'),
      dataIndex: 'propaguleType',
      key: 'propaguleType',
      sorter: true,
    },
    {
      title: t('table:seedStorageBehavior'),
      dataIndex: 'seedStorageBehavior',
      key: 'seedStorageBehavior',
      sorter: true,
    },
    {
      title: t('table:collection'),
      dataIndex: 'collection',
      key: 'collection',
      sorter: true,
    },
    {
      title: t('table:seedNotes'),
      dataIndex: 'seedNotes',
      key: 'seedNotes',
      sorter: true,
    },
  ];
};
