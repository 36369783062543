import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:nbrFiles'),
      dataIndex: 'nbrFiles',
      key: 'nbrFiles',
      sorter: true,
    },
    {
      title: t('table:transcriber'),
      dataIndex: 'transcriber',
      key: 'transcriber',
      sorter: true,
      render: (_, { transcriberFirstName, transcriberLastName }) =>
        [transcriberFirstName, transcriberLastName].join(' '),
    },
    {
      title: t('table:transcriptionDone'),
      dataIndex: 'transcriptionDone',
      key: 'transcriptionDone',
      sorter: true,
      render: (_, { transcriptionDone }) => (transcriptionDone ? 'Yes' : 'No'),
    },
  ];
};
