import React from 'react';
import { DatePicker } from 'antd';
import { getMomentDate } from '../../../utils/general';

export const editableCellsConfig = [
  {
    name: 'startDate',
    rules: [
      {
        required: true,
        message: `Start Date is required. please fill it`,
      },
    ],
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={getMomentDate(value)}
        format="YYYY-MM-DD"
        allowClear={false}
      />
    ),
  },
  {
    name: 'endDate',
    rules: [
      {
        required: true,
        message: `End Date is required. Please fill it`,
      },
    ],
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={getMomentDate(value)}
        format="YYYY-MM-DD"
        allowClear={false}
      />
    ),
  },
];
