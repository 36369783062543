import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';

import { downloadExcel } from '../../utils/general';
import { useAPI } from '../../shared/hooks/use-api/useAPI';
import PageContent from '../../shared/components/PageContent';
import ContainerWithSimpleTable from '../containers/ContainerWithSimpleTable';

import classes from '../styles.module.scss';

const PageWithSimpleTable = ({
  title,
  titleKey,
  descriptionKey,
  data,
  loading,
  fields,
  rowKey,
  tableColumns,
  scroll,
  actionColumn,
  children,
  tableInfo,
  isWithoutPageContent,
  fetchExport,
  isWithoutRowCountLabel,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [isFetchExport, setIsFetchExport] = useState(false);

  const onExport = () => {
    refetchExport();
    setIsFetchExport(true);
  };

  useEffect(() => {
    if (exportData && isFetchExport) {
      downloadExcel(pathname, exportData, refetchExport);
      setIsFetchExport(false);
    }
  }, [exportData, isFetchExport]);

  const renderContent = (
    <div>
      <Title className={classNames(classes.title)} level={3}>
        {title || t(titleKey)}
      </Title>
      {descriptionKey && (
        <p className={classNames(classes.title)}>{t(descriptionKey)}</p>
      )}
      {children}

      {!!fetchExport && (
        <div className={classNames(classes.content)}>
          <Button type="primary" onClick={onExport} loading={exportLoading}>
            Export to Excel
          </Button>
        </div>
      )}

      <ContainerWithSimpleTable
        data={data}
        fields={fields}
        rowKey={rowKey}
        loading={loading}
        tableColumns={tableColumns}
        scroll={scroll}
        actionColumn={actionColumn}
        tableInfo={tableInfo}
        isWithoutRowCountLabel={isWithoutRowCountLabel}
      />
    </div>
  );
  return isWithoutPageContent ? (
    renderContent
  ) : (
    <PageContent>{renderContent}</PageContent>
  );
};

export default PageWithSimpleTable;
