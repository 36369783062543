import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = ['division', 'collections', 'speciesCount', 'eocount'];
const fetchElementOccurrenceSeedBankDivision = () =>
  APIFetch(ENDPOINT_URLS.GET_SEED_BANK_DIV, {
    method: 'get',
  });

const fetchExport = () =>
  APIFetch(ENDPOINT_URLS.GET_SEED_BANK_DIV_EXCEL, {
    method: 'get',
  });

export const SeedBankDivision = () => {
  const { data, loading } = useAPI(
    () => fetchElementOccurrenceSeedBankDivision(),
    {
      initialState: { data: [], loading: false },
    }
  );

  const tableRows = highlightTotalValue('division', data);

  return (
    <PageWithSimpleTable
      titleKey="ElementOccurrence:SeedBankDivision"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.division}
      fetchExport={fetchExport}
    />
  );
};
