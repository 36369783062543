import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { getMomentDate, paramsForBE } from '../../../utils/general';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';
import PageWithTable from '../../pages/PageWithTable';
import { beSearchConfig } from './form-config';

const fetch = (args) => {
  if (!args) return {};
  const { orderCriteria, pagedInfo, searchCriteria } = args;
  const leadSurveyor = searchCriteria.find(
    (e) => e.propertyName === 'LeadSurveyor'
  );
  const state = searchCriteria.find((e) => e.propertyName === 'State');
  const surveyYear = searchCriteria.find((e) => e.propertyName === 'nextSurveyYear');

  const newSearch = [];
  if (state) {
    newSearch.push(state);
  }

  if (leadSurveyor && leadSurveyor.value === 'true') {
    newSearch.push({
      operatortype: 'GreaterThan',
      value: '0',
      propertyName: 'LeadSurveyor',
      previousSearchBlockConnectionOperator: 'And',
    });
  }

  if (surveyYear) {
    newSearch.push({
      operatortype: 'Equals',
      value: getMomentDate(surveyYear.value).year().toString(),
      propertyName: 'NextSurveyYear',
      previousSearchBlockConnectionOperator: 'And',
    });
  }

  return APIFetch(ENDPOINT_URLS.GET_SURVEY_PLAN_EOS_WITHOUT_OWNER, {
    method: 'post',
    body: JSON.stringify({
      orderCriteria,
      pagedInfo,
      searchCriteria: newSearch,
    }),
  });
};

const fetchExport = (args) => {
  const { orderCriteria, pagedInfo, searchCriteria } = args;
  const leadSurveyor = searchCriteria.find(
    (e) => e.propertyName === 'LeadSurveyor'
  );
  const state = searchCriteria.find((e) => e.propertyName === 'State');
  const surveyYear = searchCriteria.find((e) => e.propertyName === 'nextSurveyYear');

  const newSearch = [];
  if (state) {
    newSearch.push(state);
  }

  if (leadSurveyor && leadSurveyor.value === 'true') {
    newSearch.push({
      operatortype: 'GreaterThan',
      value: '0',
      propertyName: 'LeadSurveyor',
      previousSearchBlockConnectionOperator: 'And',
    });
  }

  if (surveyYear) {
    newSearch.push({
      operatortype: 'Equals',
      value: getMomentDate(surveyYear.value).year().toString(),
      propertyName: 'NextSurveyYear',
      previousSearchBlockConnectionOperator: 'And',
    });
  }

  return APIFetch(ENDPOINT_URLS.GET_SURVEY_PLAN_EOS_WITHOUT_OWNER_EXCEL, {
    method: 'post',
    body: JSON.stringify({
      orderCriteria,
      pagedInfo,
      searchCriteria: newSearch,
    }),
  });
}

const SurveyPlanEOsWithoutOwner = () => (
  <PageWithTable
    fetchData={fetch}
    fetchExport={fetchExport}
    defaultSortField={['SpeciesName']}
    titleKey="Owner:SurveyPlanEOsWithoutOwner"
    descriptionKey="Owner:SurveyPlanEOsWithoutOwnerDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1700 }}
  />
);

export default SurveyPlanEOsWithoutOwner;
