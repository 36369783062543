import React from 'react';
import { DatePicker } from 'antd';

import fieldFormConfig, { disabledDate } from '../../../FieldForm/form-config';

const unnecessaryFields = ['eokey', 'fieldDataType', 'reviewComments'];


const fieldFormConfigWithValidateDate = {
  ...fieldFormConfig,
  surveyDate: {
    label: 'Survey Date',
    rules: [
      {
        required: true,
      },
    ],
    input: (props) => (
      <DatePicker
        {...props}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
}

export const formConfig = Object.keys(fieldFormConfigWithValidateDate)
  .filter((field) => !unnecessaryFields.includes(field))
  .map((name) => ({
    name,
    ...fieldFormConfigWithValidateDate[name],
  }));
