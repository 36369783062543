import React from 'react';
import { Footer } from 'antd/es/layout/layout';
import classNames from 'clsx';
import classes from '../../views/styles.module.scss';

const supportEmail = 'botanicalcoordinator@nativeplanttrust.org';

export const AppFooter = () => (
  <Footer className={classNames(classes.footer)}>
    <div>
      <div className={classNames(classes.blocks)}>
        <div className={classNames(classes.block)}>
          <h3>
            Support: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </h3>
        </div>
        <div>
          <h3>
            180 Hemenway Road Framingham, MA 01701 <br />
            T: 508-877-7630 ext. 3204 F: 508-877-3658
          </h3>
        </div>
      </div>
    </div>
    <div>
      <h3>
        &copy;
        {new Date().getFullYear()} - Native Plant Trust
      </h3>
    </div>
  </Footer>
);
