import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:scientificName'),
      dataIndex: 'scientificName',
      key: 'scientificName',
      sorter: true,
    },
    {
      title: t('table:commonName'),
      dataIndex: 'commonName',
      key: 'commonName',
      sorter: true,
    },
    {
      title: t('table:checklistGroup'),
      dataIndex: 'checklistGroup',
      key: 'checklistGroup',
      sorter: true,
    },
    {
      title: t('table:family'),
      dataIndex: 'family',
      key: 'family',
      sorter: true,
    },
    {
      title: t('table:familyCommonName'),
      dataIndex: 'familyCommonName',
      key: 'familyCommonName',
      sorter: true,
    },
    {
      title: t('table:origin'),
      dataIndex: 'origin',
      key: 'origin',
      sorter: true,
    },
    {
      title: t('table:carexSection'),
      dataIndex: 'carexSection',
      key: 'carexSection',
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:stateCounty'),
      dataIndex: 'stateCounty',
      key: 'stateCounty',
    },
    {
      title: t('table:distribution'),
      dataIndex: 'distribution',
      key: 'distribution',
    },
  ];
};
