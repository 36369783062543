import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { Popconfirm, Button } from 'antd';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAuth } from '../../../../global/stores/auth';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import PageWithSimpleTable from '../../../pages/PageWithSimpleTable';
import { openNotificationWithIcon } from '../../../../utils/general';
import { roleTypes } from '../../../../utils/roles';

import classes from '../../../styles.module.scss';

const fields = ['year', 'source', 'fieldDataType', 'populationComment'];

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_MGT_NOTES_EDIT_DETAILS(key), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'Year',
            },
          ],
        }),
      })
    : {};

const fetchDelete = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.DELETE_MGT_NOTE(key), {
        method: 'delete',
      })
    : null;

const MgtNotesEditDetails = () => {
  const { t } = useTranslation();
  const { key } = useParams();
  const history = useHistory();
  const { user } = useAuth();

  const { pathname, search: searchKey } = useLocation();
  const query = new URLSearchParams(searchKey);
  const nameFromUrl = query.getAll('name')[0];

  const { data, loading, refetch } = useAPI(() => fetch(key), {
    initialState: { data: {}, loading: false },
  });

  const { data: deleteData, refetch: refetchDelete } = useAPI(fetchDelete, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: nameFromUrl || t('StatusOfPlants:MgtNotesEditDetails'),
      },
      search: searchKey,
    });
  }, []);

  useEffect(() => {
    if (deleteData) {
      if (deleteData?.ffkey) {
        openNotificationWithIcon(
          'success',
          'Management Note was deleted successfully'
        );

        refetch();
      } else {
        openNotificationWithIcon('error', 'Failed delete Management Note');
      }
    }
  }, [deleteData]);

  const actionColumn = {
    title: t('table:action'),
    key: 'operation',
    fixed: 'left',
    width: 100,
    render: (_, record) => (
      <>
        <Popconfirm
          placement="top"
          title="Delete this item?"
          onConfirm={() => refetchDelete(record.ffkey)}
          okText="Yes"
          cancelText="No"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            style={{
              marginRight: 8,
            }}
          >
            {t('table:delete')}
          </a>
        </Popconfirm>

        <Link
          style={{
            marginRight: 8,
          }}
          to={`${pathname}/${record.ffkey}`}
        >
          {t('table:edit')}
        </Link>
      </>
    ),
  };

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  const isAdmin = user.role === roleTypes.Administrator;

  return (
    <PageWithSimpleTable
      titleKey="StatusOfPlants:MgtNotesEditDetails"
      data={data?.mgtNoteData || []}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.ffkey}
      scroll={false}
      actionColumn={isAdmin && actionColumn}
      tableInfo={
        <>
          <p>
            <b>mgt Notes Data</b>
          </p>
          <p>This is the NPT and state data.</p>
          {data?.history && (
            <>
              <br />
              <p>
                <b>History</b>
              </p>
              <p>{data.history}</p>
            </>
          )}
        </>
      }
    >
      {isAdmin && (
        <div className={classNames(classes.content)}>
          <Button onClick={create}>Add New Note to Element Occurrence</Button>
        </div>
      )}
    </PageWithSimpleTable>
  );
};

export default MgtNotesEditDetails;
