import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { action } from '../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../pages/PageWithEditableTable';
import {
  openNotificationWithIcon,
  getRequiredErrMsg,
} from '../../../utils/general';

import { editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchEnterPermission = (args, ownerKey) =>
  args && ownerKey
    ? APIFetch(ENDPOINT_URLS.GET_EO_PERMISSION_PAGED(ownerKey), {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const EnterPermission = () => {
  const { t } = useTranslation();
  const { search: searchKey } = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(searchKey);
  const ownerKeyFromUrl = query.getAll('ownerKey');
  const nameFromUrl = query.getAll('name')[0];
  const [form] = Form.useForm();

  const { data, loading, refetch } = useAPI(
    (args) => fetchEnterPermission(args, ownerKeyFromUrl[0]),
    {
      initialState: { data: {}, loading: false },
      shouldFetch: false,
    }
  );

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: nameFromUrl,
      },
      search: searchKey,
    });
  }, [data]);

  const cancel = () => {
    setEditingKey('');
  };

  const defaultSortFields = ['State', 'SpeciesName', 'EonumberInt'];

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);

    refetch(paramsToPassInBodyRequest(parameters, null, defaultSortFields));

    setIsRefetchDone(true);
    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  useEffect(() => {
    refetch(
      paramsToPassInBodyRequest(
        { pagination: initialPagination },
        null,
        defaultSortFields
      )
    );
  }, []);

  const initialState = {
    contactDate: '',
    contactMethod: '',
    nptcontactPerson: '',
    ownerPermission: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();
    const { contactDate, ownerPermission, nptcontactPerson, contactMethod } =
      row;
    const condition = 'No-NotContacted';

    if (
      (ownerPermission !== condition && contactDate && nptcontactPerson) ||
      ownerPermission === condition
    ) {
      APIFetch(ENDPOINT_URLS.UPDATE_EO_PERMISSION(key, ownerKeyFromUrl[0]), {
        method: 'put',
        body: JSON.stringify({
          contactDate: moment.utc(contactDate).toISOString(),
          ownerPermission,
          nptcontactPerson,
          contactMethod,
        }),
      }).then(() => {
        const newData = [...tableData];
        const index = newData.findIndex((item) => key === item.eokey);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      });
    } else if (!contactDate) {
      form.setFields([
        {
          name: 'contactDate',
          errors: [getRequiredErrMsg('Contact Date')],
        },
      ]);
    } else if (!nptcontactPerson) {
      form.setFields([
        {
          name: 'nptcontactPerson',
          errors: [getRequiredErrMsg('NPT Contact Person')],
        },
      ]);
    } else if (!contactDate && !nptcontactPerson) {
      form.setFields([
        {
          name: 'contactDate',
          errors: [getRequiredErrMsg('Contact Date')],
        },
        {
          name: 'nptcontactPerson',
          errors: [getRequiredErrMsg('NPT Contact Person')],
        },
      ]);
    }
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, record) => {
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone
        );

        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isCellEditing(record, editingKey),
      }),
    };
  });

  return (
    <PageWithEditableTable
      titleKey="PCVAdministration:EnterPermission"
      descriptionKey="PCVAdministration:EnterPermissionDescription"
      columns={mergedColumns}
      rowKeyFunc={(record) => record.eokey}
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
    />
  );
};

export default EnterPermission;
