import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import classNames from 'clsx';
import moment from 'moment';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { action } from '../../../shared/EditableTable/components/Action';

import PageWithNestedTable from '../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';
import { actionContent, actionContentEOData } from './utils';
import classes from '../../styles.module.scss';

const defaultSortFields = ['State', 'SpeciesName', 'Eonumber', 'Subpopulation'];

const bodyForFirstRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: defaultSortFields.map((propertyName) => ({
    orderOption: 'Ascending',
    propertyName,
  })),
};

const fetchSurveyAssignmentFieldData = (args) =>
  APIFetch(ENDPOINT_URLS.GET_SURVEY_ASSIGNMENT_FIELD_DATA, {
    method: 'post',
    body: JSON.stringify(args || bodyForFirstRequest),
  });

const fetchSurveyAssignmentFieldDataDetails = (key, checked) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_ASSIGNMENT_FIELD_DATA_DETAILS(key, checked), {
        method: 'get',
      })
    : [];

const SurveyAssignmentFieldData = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const onCheckboxChange = async (e) => {
    await setChecked(e.target.checked);
  };

  const expandableItem = (key) => ({
    year: +moment().format('YYYY'),
    source: 'SpecFolder',
    fieldDataType: 'EOData',
    fullFilename: 'Download',
    eokey: key,
  });

  const allColumns = [
    action({
      title: t('table:pdf'),
      key: 'operation',
      width: 65,
      renderFunction: (_, record) =>
        actionContent(record, {
          pageName: t('PCVTF:SurveyAssignmentFieldDataPostfix'),
          fileTitle: t('PCVTF:SurveyAssignmentFieldData'),
          checked,
        }),
    }),
    ...columns(),
  ];

  const allExpandableColumns = [
    ...expandableColumns(),
    {
      title: t('table:fullFilename'),
      dataIndex: 'fullFilename',
      key: 'fullFilename',
      render: (text, record) => {
        const { eokey = null, fileLink } = record || {};
        if (eokey) return actionContentEOData(record);

        return text ? <a href={fileLink}>{text}</a> : '';
      }, 
    },
  ];

  return (
    <PageWithNestedTable
      fetchData={(args) => fetchSurveyAssignmentFieldData(args)}
      fetchNestedData={(key) => fetchSurveyAssignmentFieldDataDetails(key, checked).then((data) => [expandableItem(key), ...data])}
      defaultSortField={defaultSortFields}
      nestedTableKey="eokey"
      titleKey="PCVTF:SurveyAssignmentFieldData"
      descriptionKey="PCVTF:SurveyAssignmentFieldDataDescription"
      columns={allColumns}
      expandableColumns={allExpandableColumns}
      rowKeyFunc={(record) => record.eokey}
      scroll={{ x: 1300 }}
    >
      <div className={classNames(classes.title)}>
        <Checkbox
          checked={checked}
          onChange={onCheckboxChange}
        >
          {t("PCVTF:ShowFullData")}
        </Checkbox>
      </div>
    </PageWithNestedTable>
  );
};

export default SurveyAssignmentFieldData;
