import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const StatesForSurveyorSelect = (props) => {
  const {
    statesForSurveyorData,
    statesForSurveyorLoading,
    updateStatesForSurveyor,
  } = useStore();

  useEffect(() => {
    if (!statesForSurveyorData.length && !statesForSurveyorLoading)
      updateStatesForSurveyor();
  }, []);

  return (
    <Select
      props={props}
      width={85}
      data={statesForSurveyorData}
      loading={statesForSurveyorLoading}
    />
  );
};

export default StatesForSurveyorSelect;
