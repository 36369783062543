import { Card as AntCard } from 'antd';
import classNames from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import classes from './styles.module.scss';

const Card = ({ title, description, pathTo, buttonName, className }) => {
  const { t } = useTranslation();

  return (
    <AntCard
      className={classNames(classes['atn-card'], className)}
      bordered={false}
    >
      <h2 className={classes.title}>{t(title)}</h2>
      <p className={classes.description}>{t(description)}</p>
      <Link
        to={pathTo}
        className={classNames(classes.button, 'ant-btn')}
        htmltype="button"
      >
        {t(buttonName)}
      </Link>
    </AntCard>
  );
};

export default Card;
