import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FIRST_LOGIN_KEY, AUTH_DATA_KEY } from '../../../constants';
import {
  ACCOUNT_RESET_PASSWORD,
  AUTH_ROUTE,
  BASE_ROUTE,
  NOT_AUTHORIZED_ROUTE,
} from '../../../../AppRoutes';

import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import {
  acceptAgreementRequest,
  logInRequest,
  logOutRequest,
  refreshTokenRequest,
} from '../services';

const initialAuthState = {
  securityAgreementAccepted: false,
  tokenPair: null,
  user: null,
  authError: null,
};

const mapAuthState = (authData, loading, error) => ({
  securityAgreementAccepted: authData.securityAgreementAccepted,
  tokenPair: authData.tokenPair,
  user: authData.userInfo,
  authError: error,
});

export const useAuthentication = () => {
  const [
    { securityAgreementAccepted, tokenPair, user, authError },
    setAuthState,
  ] = useState(initialAuthState);
  const [checkingAuthentication, setCheckAuthentication] = useState(true);
  const [isRefreshAfterAgreement, setIsRefreshAfterAgreement] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();

  const {
    data: authData,
    loading: authInProgress,
    error: errorInAuth,
    refetch: login,
  } = useAPI((credentials) => logInRequest(credentials), {
    initialState: { loading: false },
    shouldFetch: false,
    mapDataFn: (data) => mapAuthState(data, errorInAuth),
  });

  const {
    data: agreementData,
    loading: acceptAgreementInProgress,
    error: errorInAcceptAgreement,
    refetch: acceptAgreement,
  } = useAPI((data) => acceptAgreementRequest(data, authData), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: refreshedToken,
    loading: refreshedTokenInProgress,
    error: errorRefreshedToken,
    refetch: refreshToken,
  } = useAPI(refreshTokenRequest, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: signOutData,
    error: signOutError,
    refetch: signOut,
  } = useAPI(() => logOutRequest(tokenPair), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const redirectToBaseRoute = () => history.push(BASE_ROUTE);
  const redirectToLoginRoute = () => history.push(AUTH_ROUTE);
  const redirectToErrorPageRoute = () => history.push(NOT_AUTHORIZED_ROUTE);

  const loginFn = async (credentials) => {
    login(credentials);
  };

  useEffect(() => {
    if (errorInAuth) {
      setAuthState({ ...initialAuthState, authError: errorInAuth });
      return;
    }

    if (authData && !authInProgress) {
      setAuthState({ ...authData, authError: null });

      if (authData.securityAgreementAccepted) {
        localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
        redirectToBaseRoute();
      } else {
        localStorage.setItem(
          FIRST_LOGIN_KEY,
          JSON.stringify({
            activeStep: 2,
          })
        );
      }
    }
  }, [authData, authInProgress, errorInAuth]);

  // SignOut logic
  const logout = () => {
    signOut();
    setAuthState(initialAuthState);
  };

  useEffect(() => {
    if (signOutData && signOutData.status === 200 && !signOutError) {
      setAuthState(initialAuthState);
      localStorage.removeItem(AUTH_DATA_KEY);
      redirectToLoginRoute();
    }
  }, [signOutData, signOutError]);

  const storageAuthData = localStorage.getItem(AUTH_DATA_KEY);

  // Sync auth data with local storage
  useEffect(() => {
    if (storageAuthData) {
      setAuthState(JSON.parse(storageAuthData));
      setCheckAuthentication(false);
    } else if (pathname === ACCOUNT_RESET_PASSWORD) {
      return true;
    } else if (pathname === AUTH_ROUTE || pathname === BASE_ROUTE) {
      redirectToLoginRoute();
    } else {
      redirectToErrorPageRoute();
    }
  }, [storageAuthData]);

  // Logic for after accept agreement
  useEffect(() => {
    if (agreementData) {
      setAuthState(JSON.parse(storageAuthData));
      setIsRefreshAfterAgreement(true);
      refreshToken({ ...tokenPair });
      setCheckAuthentication(false);
    }
  }, [agreementData]);

  // Refresh tokens after accept agreement
  useEffect(() => {
    if (refreshedToken && !refreshedTokenInProgress) {
      const oldAuthState = JSON.parse(localStorage.getItem(AUTH_DATA_KEY));
      const updatedState = {
        ...oldAuthState,
        tokenPair: { ...refreshedToken },
        securityAgreementAccepted: true,
      };

      localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(updatedState));
      setAuthState(updatedState);
      setCheckAuthentication(false);

      if (isRefreshAfterAgreement) {
        setIsRefreshAfterAgreement(false);
        redirectToBaseRoute();
      }
    } else if (errorRefreshedToken && !refreshedTokenInProgress) {
      redirectToLoginRoute();
    }
  }, [refreshedToken, refreshedTokenInProgress, errorRefreshedToken]);

  return {
    login: {
      loginFn,
      authData,
      authInProgress,
      authError,
    },
    logout,
    agreement: {
      acceptAgreement,
      acceptAgreementInProgress,
      errorInAcceptAgreement,
    },
    isAuthenticated: !!tokenPair && !!user,
    checkingAuthentication,
    token: tokenPair,
    refreshToken,
    refreshedToken,
    user,
    securityAgreementAccepted,
    redirectToBaseRoute,
    setAuthState,
  };
};
