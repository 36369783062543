import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      sorter: true,
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
      sorter: true,
    },
    {
      title: t('table:fieldDataYear'),
      dataIndex: 'year',
      key: 'year',
      sorter: true,
    },
  ];
};
