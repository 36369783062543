// eslint-disable-next-line max-classes-per-file
export class FetchError extends Error {
  // eslint-disable-next-line no-unused-vars
  constructor(status, statusText, { title, instance, traceId }) {
    super(statusText);
    this.title = title;
    this.instance = instance;
    this.traceId = traceId;
  }
}

export class FetchUnauthError extends FetchError {}
