import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'speciesTotal',
  'speciesDivision1',
  'speciesDivision2',
  'speciesDivision2a',
  'speciesDivision3a',
  'speciesDivision3ab',
  'speciesDivision3b',
  'speciesDivision4',
  'speciesDivisionInd',
  'speciesDivisionNone',
];

const fetchSpeciesDivision = () =>
  APIFetch(ENDPOINT_URLS.GET_SPECIES_DIVISION, {
    method: 'get',
  });

export const SpeciesDivision = () => {
  const { data, loading } = useAPI(() => fetchSpeciesDivision(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('speciesTotal', data);

  return (
    <PageWithSimpleTable
      titleKey="Species:SpeciesDivision"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};
