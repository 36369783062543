import React from 'react';

import TopicsSelect from '../../../shared/components/select/TopicsSelect';

export const searchFormConfig = [
  {
    name: 'ResearchTopic',
    label: 'Topic',
    input: (props) => <TopicsSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
