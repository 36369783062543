import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  paramsToPassInBodyRequest,
  buildSearchParamsForRequest,
  getRandomuserParams,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { action } from '../../../shared/EditableTable/components/Action';
import {
  downloadExcel,
  openNotificationWithIcon,
} from '../../../utils/general';
import PageWithEditableTable from '../../pages/PageWithEditableTable';

import SearchForm from './SearchForm';
import { editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchSurveyPlan = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_PLAN_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : [];

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PCV_SURVEY_PLAN_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const SurveyPlan = () => {
  const { t } = useTranslation();
  const { pathname, search: searchParams } = useLocation();

  const [form] = Form.useForm();

  const { data, loading, refetch } = useAPI((args) => fetchSurveyPlan(args), {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({});

  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  const cancel = () => {
    setEditingKey('');
  };

  const defaultSortFields = [
    'State',
    'SpeciesName',
    'Eonumber',
    'Subpopulation',
  ];

  const [parametersToExportFromFetch, setParametersToExportFromFetch] =
    useState();
  const fetch = (
    params,
    searchParam,
    sortField = ['State', 'SpeciesName', 'Eonumber', 'Subpopulation']
  ) => {
    const updatedParameters = (searchParam || searchParameters).map((param) => {
      const { value, propertyName } = param;
      let updatedValue;
      let updatedPropertyName;

      switch (propertyName) {
        case 'NextSurveyYear':
          updatedValue = +value === 0 ? -1 : moment(value).year() - 1;
          updatedPropertyName = 'NextSurveyYear';
          break;
        case 'NextSeedYear':
          updatedValue = +value === 0 ? 1 : moment(value).year() + 1;
          updatedPropertyName = 'NextSurveyYear';
          break;
        case 'SurveyInterval':
          updatedValue = +value === 0 ? -1 : moment(value).year() - 1;
          updatedPropertyName = 'NextSeedYear';
          break;
        case 'SeedInterval':
          updatedValue = +value === 0 ? 1 : moment(value).year() + 1;
          updatedPropertyName = 'NextSeedYear';
          break;
        default:
          updatedValue = value;
          updatedPropertyName = propertyName;
      }

      return {
        ...param,
        propertyName: updatedPropertyName,
        value: `${updatedValue}`,
      };
    });
    refetch(paramsToPassInBodyRequest(params, updatedParameters, sortField));
    setParametersToExportFromFetch(updatedParameters);
    setIsRefetchDone(true);
    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  const [parametersForExport, setParametersForExport] = useState();
  const onExport = () => {
    if (parametersForExport) {
      const parameters = getRandomuserParams(parametersForExport);

      refetchExport(
        paramsToPassInBodyRequest(
          parameters,
          parametersToExportFromFetch,
          defaultSortFields
        )
      );
    } else {
      const parameters = paramsToPassInBodyRequest(
        {
          pagination: initialPagination,
        },
        parametersToExportFromFetch,
        defaultSortFields
      );
      refetchExport(parameters);
    }
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      cancel();

      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    if (searchParamsArray.length || Object.keys(data).length) {
      fetch(
        {
          pagination: initialPagination,
        },
        searchParamsArray
      );

      setSearchParameters(searchParamsArray);
      cancel();
    }
  }, [searchParams]);

  const initialState = {
    nextSurveyYear: '',
    nextSeedYear: '',
    surveyInterval: '',
    seedInterval: '',
    surveyComment: '',
    tfpcv: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();

    APIFetch(ENDPOINT_URLS.EDIT_SURVEY_PLAN_FIELDS(key), {
      method: 'put',
      body: JSON.stringify(row),
    }).then(({ status }) => {
      if (status) {
        const newData = [...tableData];
        const index = newData.findIndex((item) => key === item.eokey);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      }
    });
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, record) => {
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone
        );
        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: ['surveyComment', 'tfpcv'].includes(col.dataIndex)
          ? 'text'
          : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isCellEditing(record, editingKey),
      }),
    };
  });

  return (
    <PageWithEditableTable
      titleKey="PCVAdministration:SurveyPlan"
      descriptionKey="PCVAdministration:SurveyPlanDescription"
      searchForm={SearchForm}
      columns={mergedColumns}
      rowKeyFunc={(record) => record.eokey}
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      isExportContent
      onExport={onExport}
      exportLoading={exportLoading}
      setParametersForExport={setParametersForExport}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
      scroll={{ x: 3700 }}
      tableLayout="fixed"
    />
  );
};

export default SurveyPlan;
