import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = [
  'speciesName',
  'phenologyType',
  'firstDate#date',
  'lastDate#date',
];

const PhenologySurveyInformation = ({ data }) => (
  <ContainerWithSimpleTable
    titleKey="PCVAdministration:PhenologySurveyInformation"
    data={data}
    loading={false}
    fields={fields}
    rowKey={uuidv4()}
    fullWidth
    formatWithYear
  />
);

export default PhenologySurveyInformation;
