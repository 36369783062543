import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const MgtNotesAndEODataSourcesSelect = (props) => {
  const {
    mgtNotesAndEODataSourcesData,
    mgtNotesAndEODataSourcesLoading,
    updateMgtNotesAndEODataSources,
  } = useStore();

  useEffect(() => {
    if (
      !mgtNotesAndEODataSourcesData.length &&
      !mgtNotesAndEODataSourcesLoading
    )
      updateMgtNotesAndEODataSources();
  }, []);

  return (
    <Select
      props={props}
      data={mgtNotesAndEODataSourcesData}
      loading={mgtNotesAndEODataSourcesLoading}
    />
  );
};

export default MgtNotesAndEODataSourcesSelect;
