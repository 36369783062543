import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
} from '../../../../shared/sortAndSearchUtils';
import { roleTypes } from '../../../../utils/roles';
import { useAuth } from '../../../../global/stores/auth';
import { isCellEditing } from '../../../../shared/EditableTable/tableUtils';
import { action } from '../../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../../pages/PageWithEditableTable';
import {
  openNotificationWithIcon,
  paramsForBE,
} from '../../../../utils/general';

import SearchForm from './SearchForm';
import { beSearchConfig, editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchPaged = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_INFORMATION_EDIT_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const InformationEdit = () => {
  const { t } = useTranslation();
  const { search: searchParams } = useLocation();
  const { user } = useAuth();

  const [form] = Form.useForm();

  const { data, loading, refetch } = useAPI(fetchPaged, {
    initialState: { data: {}, loading: false },
    shouldFetch: false,
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({});
  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  const cancel = () => {
    setEditingKey('');
  };

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;

    refetch(paramsToPassInBodyRequest(parameters, searchParam, 'SpeciesName'));

    setIsRefetchDone(true);

    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);
    if (searchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          paramsForBE
            ? paramsForBE(searchParamsArray, beSearchConfig)
            : searchParamsArray,
          'SpeciesName'
        )
      );
      setSearchParameters(searchParamsArray);
    }
  }, [searchParams]);

  const initialState = {
    phenologyNote: '',
    phenologyMemo: '',
    nptstatus: '',
    comment: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();

    APIFetch(ENDPOINT_URLS.UPDATE_SPECIES_INFORMATION_EDIT(key), {
      method: 'put',
      body: JSON.stringify(row),
    }).then(() => {
      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.speciesKey);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
      } else {
        newData.push(row);
      }

      setData(newData);
      setEditingKey('');

      openNotificationWithIcon('success', 'Data was updated successfully');
    });
  };

  const isAdmin = user.role === roleTypes.Administrator;

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 150,
      renderFunction: (record) => {
        const { speciesKey } = record;

        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone,
          speciesKey,
          (rec) => rec.speciesKey
        );

        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey,
          isAdmin
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        const { speciesKey } = record;

        return {
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isCellEditing(
            record,
            editingKey,
            null,
            null,
            null,
            speciesKey
          ),
        };
      },
    };
  });

  return (
    <PageWithEditableTable
      titleKey="Species:InformationEditTitle"
      descriptionKey="Species:InformationEditDescription"
      searchForm={SearchForm}
      columns={mergedColumns}
      rowKeyFunc={(record) => record.speciesKey}
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
    />
  );
};

export default InformationEdit;
