import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:seedCollsWithLead'),
      dataIndex: 'seedCollsInPlan',
      key: 'seedCollsInPlan',
    },
    {
      title: t('table:seedCollsWithPermission'),
      dataIndex: 'seedCollsWithPermission',
      key: 'seedCollsWithPermission',
    },
    {
      title: t('table:seedCollsWithoutPermission'),
      dataIndex: 'seedCollsWithoutPermission',
      key: 'seedCollsWithoutPermission',
    },
    {
      title: t('table:seedCollsWithPartialPermission'),
      dataIndex: 'seedCollsWithPartialPermission',
      key: 'seedCollsWithPartialPermission',
    },
  ];
};
