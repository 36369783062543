import React from 'react';

export const columns = () => [
  {
    title: 'Link',
    dataIndex: 'link1',
    key: 'link1',
    render: (text, { linkTitle }) => <a href={text}>{linkTitle}</a>,
    width: 250,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];
