import React from 'react';
import { Input } from 'antd';

import { SURVEYOR_TYPE } from '../../../../global/constants';

import { Select } from '../../../../shared/components/select/Select';
import StatesSelect from '../../../../shared/components/select/StatesSelect';

const { TextArea } = Input;

export const formConfig = [
  {
    name: 'announcementTitle',
    label: 'Announcement Title',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
    rules: [
      {
        required: true,
      },
      {
        max: 254,
        message: 'Announcement Title should be less or equal to 254 characters',
      },
    ],
  },
  {
    name: 'announcementText',
    label: 'Announcement Text',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'surveyorType',
    label: 'Surveyor Type',
    input: (props) => <Select props={props} data={SURVEYOR_TYPE} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'state',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
  },
];
