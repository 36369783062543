import React from 'react';
import moment from 'moment';
import { DatePicker, Input } from 'antd';

import ContactMethodsSelect from '../../../../shared/components/select/ContactMethodsSelect';
import OwnerPermissionsSelect from '../../../../shared/components/select/OwnerPermissionsSelect';
import { getRequiredErrMsg, getMomentDate } from '../../../../utils/general';

const previousYear = +moment().format('YYYY') - 1;
const nextYear = +moment().format('YYYY') + 1;
const currentDate = moment.utc().format('YYYY-MM-DD');

const disableDate = (current) => {
  const tooLate = current.utc().format('YYYY-MM-DD') > currentDate;
  const tooEarly = current.year() <= previousYear;

  return tooEarly || tooLate;
};

const { TextArea } = Input;

export const editableCellsConfig = [
  {
    name: 'contactDate',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        format="YYYY-MM-DD"
        disabledDate={disableDate}
      />
    ),
    rules: [
      {
        validator: (_, value) => {
          const year = moment(value).year();

          return (year > previousYear && year < nextYear) || !value
            ? Promise.resolve()
            : Promise.reject(new Error('Contact Date must be in current year'));
        },
      },
    ],
  },
  {
    name: 'contactMethod',
    rules: [
      {
        required: true,
        message: getRequiredErrMsg('Contact Method'),
      },
    ],
    input: (props) => <ContactMethodsSelect {...props} />,
  },
  {
    name: 'nptcontactPerson',
  },
  {
    name: 'ownerPermission',
    rules: [
      {
        required: true,
        message: getRequiredErrMsg('Owner Permission'),
      },
    ],
    input: (props) => <OwnerPermissionsSelect {...props} />,
  },
  {
    name: 'ownerComment',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'currentYearAttempts',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
];
