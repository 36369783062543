import React from 'react';
import { DatePicker, InputNumber, Checkbox, Input } from 'antd';
import moment from 'moment';

import TopoPositionsSelect from '../../../shared/components/select/TopoPositionsSelect';
import AspectsSelect from '../../../shared/components/select/AspectsSelect';
import LightsExistingSelect from '../../../shared/components/select/LightsExistingSelect';
import ExcitingMoistureRegimesSelect from '../../../shared/components/select/ExcitingMoistureRegimesSelect';

const { TextArea } = Input;

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const rametValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 1000000,
  },
];

const genetValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 1000000,
  },
];

const slopeValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 100,
  },
];

const elevationValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 6288,
  },
];

const pctValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 100,
  },
];

export const currentYear = +moment().format('YYYY');

const disabledDate = (current) => {
  const tooLate = current.year() > currentYear;
  const tooEarly = current < moment().set('year', 1970).startOf('year');

  return tooEarly || tooLate;
};

export const editFormConfig = [
  {
    name: 'eo',
    label: 'EO',
    input: (props) => <CustomInputNumber {...props} disabled />,
  },
  {
    name: 'surveyDate',
    label: 'SurveyDate',
    rules: [
      {
        required: true,
      },
    ],
    input: (props) => (
      <DatePicker
        {...props}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  {
    name: 'town',
    label: 'Town',
    rules: [
      {
        required: true,
      },
      {
        max: 60,
      },
    ],
  },
  {
    name: 'siteName',
    label: 'SiteName',
    rules: [
      {
        max: 100,
      },
    ],
  },
  {
    name: 'latitude',
    label: 'Latitude',
    rules: [
      {
        max: 20,
      },
    ],
  },
  {
    name: 'longitude',
    label: 'Longitude',
    rules: [
      {
        max: 20,
      },
    ],
  },
  {
    name: 'latitudeDecimalDegrees',
    label: 'LatitudeDecimalDegrees',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: 40,
        max: 47.5,
      },
    ],
  },
  {
    name: 'longitudeDecimalDegrees',
    label: 'longitudeDecimalDegrees',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: -74,
        max: -66.5,
      },
    ],
  },
  {
    name: 'minRamet',
    label: 'MinRamet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: rametValidationRules,
  },
  {
    name: 'maxRamet',
    label: 'MaxRamet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: rametValidationRules,
  },
  {
    name: 'minGenet',
    label: 'MinGenet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: genetValidationRules,
  },
  {
    name: 'maxGenet',
    label: 'MaxGenet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: genetValidationRules,
  },
  {
    name: 'populationArea',
    label: 'PopulationArea (m2)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'slopeMin',
    label: 'SlopeMin',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: slopeValidationRules,
  },
  {
    name: 'slopeMax',
    label: 'SlopeMax',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: slopeValidationRules,
  },
  {
    name: 'elevationMin',
    label: 'ElevationMin (ft):',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: elevationValidationRules,
  },
  {
    name: 'elevationMax',
    label: 'ElevationMax (ft):',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: elevationValidationRules,
  },
  {
    name: 'topoPosition',
    label: 'TopoPosition',
    input: (props) => <TopoPositionsSelect {...props} />,
  },
  {
    name: 'aspect',
    label: 'Aspect',
    input: (props) => <AspectsSelect {...props} />,
  },
  {
    name: 'light',
    label: 'Light',
    input: (props) => <LightsExistingSelect {...props} />,
  },
  {
    name: 'moistureRegime',
    label: 'MoistureRegime',
    input: (props) => <ExcitingMoistureRegimesSelect {...props} />,
  },
  {
    name: 'associatedSpecies',
    label: 'AssociatedSpecies',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'pctLeaf',
    label: 'Phenology: PctLeaf:',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctBud',
    label: 'PctBud',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctFlower',
    label: 'PctFlower',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctImmFruit',
    label: 'PctImmFruit',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctMatFruit',
    label: 'PctMatFruit',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSeed',
    label: 'PctSeed',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSenescent',
    label: 'PctSenescent',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctDormant',
    label: 'PctDormant',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSeedling',
    label: 'Age Distribution: PctSeedling:',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctImmature',
    label: 'PctImmature',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctVegSprout',
    label: 'PctVegSprout',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pct1stYear',
    label: 'Pct1stYear',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctMature',
    label: 'PctMature',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSenesc',
    label: 'PctSenesc',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctUnknown',
    label: 'PctUnknown',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'naturalCommunity',
    label: 'NaturalCommunity',
    rules: [
      {
        max: 60,
      },
    ],
  },
  {
    name: 'invasiveSpecies',
    label: 'InvasiveSpecies',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'threats',
    label: 'Threats',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
  },
  {
    name: 'mgtNeeds',
    label: 'MgtNeeds',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
  },
];
