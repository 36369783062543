import React from 'react';
import moment from 'moment';
import SurveyResultSelect from '../../../shared/components/select/SurveyResultSelect';
import StatesSelect from '../../../shared/components/select/StatesSelect';

const minYear = +moment().format('YYYY') - 30;
const maxYear = +moment().format('YYYY');

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'Town',
    label: 'Town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SiteName',
    label: 'Sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'Year',
    label: 'Year',
    rules: [
      {
        type: 'number',
        validator: (_, value) => {
          const isValid = ['', undefined, null].includes(value)
            ? true
            : +value >= minYear && +value <= maxYear;

          return isValid
            ? Promise.resolve()
            : Promise.reject(
                new Error(`Year must be between ${minYear} and ${maxYear}`)
              );
        },
      },
      {
        required: true,
      },
    ],
    operatortype: 'Equals',
  },
];

export const editableCellsConfig = [
  {
    name: 'surveyResult',
    input: (props) => <SurveyResultSelect {...props} />,
  },
];

export const beSearchConfig = [
  {
    propertyName: 'SpeciesName',
    operatortype: 'Contains',
  },
  {
    propertyName: 'Town',
    operatortype: 'Contains',
  },
  {
    propertyName: 'SiteName',
    operatortype: 'Contains',
  },
  {
    propertyName: 'State',
    operatortype: 'Equals',
  },
  {
    propertyName: 'Year',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Synonyms',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    propertyName: 'Town',
    operatortype: 'Contains',
  },
  {
    propertyName: 'SiteName',
    operatortype: 'Contains',
  },
  {
    propertyName: 'State',
    operatortype: 'Equals',
  },
  {
    propertyName: 'Year',
  },
];
