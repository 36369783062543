import React from 'react';

import StatesSelect from '../../../../shared/components/select/StatesSelect';
import OutreachOrganizationTypesSelect from '../../../../shared/components/select/OutreachOrganizationTypesSelect';

export const formConfig = [
  {
    name: 'state',
    label: 'State',
    input: (props) => <StatesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'organization',
    label: 'Organization',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'outreachOrganizationType',
    label: 'Type',
    input: (props) => <OutreachOrganizationTypesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'association',
    label: 'Association',
  },
  {
    name: 'contact',
    label: 'Contact',
  },
  {
    name: 'notes',
    label: 'Notes',
  },
];
