import React, { useEffect, useState } from 'react';
import { Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithTable from '../../../pages/PageWithTable';
import { openNotificationWithIcon } from '../../../../utils/general';

import { columns } from './TableColumns';

import classes from '../../../styles.module.scss';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_ANNOUNCEMENTS, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchDelete = (guid) =>
  guid
    ? APIFetch(ENDPOINT_URLS.DELETE_ANNOUNCEMENT(guid), {
        method: 'delete',
      })
    : null;

const fetchSendEmail = (guid) =>
  guid
    ? APIFetch(ENDPOINT_URLS.SEND_EMAIL(guid), {
        method: 'post',
      })
    : null;

const Announcements = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI((guid) => fetchDelete(guid), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: sendEmailData,
    loading: sendEmailLoading,
    refetch: refetchSendEmail,
  } = useAPI(fetchSendEmail, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (deleteData && !deleteLoading) {
      if (deleteData?.id) {
        openNotificationWithIcon(
          'success',
          'Announcement was deleted successfully'
        );

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', 'Failed delete Announcement');
      }
    }
  }, [deleteLoading]);

  useEffect(() => {
    if (typeof sendEmailData === 'boolean' && !sendEmailLoading) {
      if (sendEmailData) {
        openNotificationWithIcon('success', 'Email was sent successfully');
      } else {
        openNotificationWithIcon('error', 'Failed send email');
      }
    }
  }, [sendEmailLoading]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 150,
      render: (_, record) => (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            style={{
              marginRight: 16,
            }}
            onClick={() => refetchSendEmail(record.id)}
          >
            {t('table:sendEmail')}
          </a>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => refetchDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a>{t('table:delete')}</a>
          </Popconfirm>
        </>
      ),
    },
    ...staticColumns,
  ];

  const createAnnouncement = () => {
    history.push({
      pathname: `${pathname}/AddAnnouncement`,
    });
  };

  return (
    <PageWithTable
      fetchData={fetch}
      defaultSortField={['AnnouncementTitle', 'State', 'SurveyorType']}
      titleKey="PCVAdmin:Announcements"
      descriptionKey="PCVAdmin:AnnouncementsDescription"
      columns={allColumns}
      rowKey={(record) => record.id}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading || sendEmailLoading}
    >
      <div className={classNames(classes.content)}>
        <Button onClick={createAnnouncement}>Add new announcement</Button>
      </div>
    </PageWithTable>
  );
};

export default Announcements;
