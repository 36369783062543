import React from 'react';

import { Select } from './Select';

const TFPCVSelect = (props) => {
  const data = [
    {
      item: 'PCV',
      itemKey: 'PCV',
    },
    { item: 'TFPCV', itemKey: 'TFPCV' },
    { item: 'TF', itemKey: 'TF' },
  ];

  return <Select props={props} width={90} data={data} />;
};

export default TFPCVSelect;
