import React from 'react';
import { getMomentDate } from '../../../utils/general';

export const columns = [
  {
    title: 'Organization',
    dataIndex: 'organization',
    key: 'organization',
    sorter: true,
    width: 120,
  },
  {
    title: 'Suborganization',
    dataIndex: 'subOrganization',
    key: 'subOrganization',
    sorter: true,
    width: 120,
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    width: 100,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
    width: 100,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    sorter: true,
    width: 180,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    width: 120,
  },
  {
    title: 'Permit Type',
    dataIndex: 'permitTypeRequested',
    key: 'permitTypeRequested',
    sorter: true,
    width: 100,
  },
  {
    title: 'Date Submitted',
    dataIndex: 'datePermitRequestSubmitted',
    key: 'datePermitRequestSubmitted',
    sorter: true,
    width: 80,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
  {
    title: 'Date Ack',
    dataIndex: 'datePermitRequestAcknowledged',
    key: 'datePermitRequestAcknowledged',
    sorter: true,
    width: 80,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
  {
    title: 'Date Recd',
    dataIndex: 'datePermitResponseRecd',
    key: 'datePermitResponseRecd',
    sorter: true,
    width: 80,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
  {
    title: 'Response',
    dataIndex: 'permitResponse',
    key: 'permitResponse',
    sorter: true,
    width: 80,
  },
  {
    title: 'Permit Start',
    dataIndex: 'permitStartDate',
    key: 'permitStartDate',
    sorter: true,
    width: 80,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
  {
    title: 'Permit End',
    dataIndex: 'permitEndDate',
    key: 'permitEndDate',
    sorter: true,
    width: 80,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
  {
    title: 'Document 1',
    dataIndex: 'permitDocument1',
    key: 'permitDocument1',
    sorter: true,
    width: 80,
    render: (text, { permitDocumentLink1 }) =>
      text ? <a href={permitDocumentLink1}>{text}</a> : '',
  },
  {
    title: 'Document 2',
    dataIndex: 'permitDocument2',
    key: 'permitDocument2',
    sorter: true,
    width: 80,
    render: (text, { permitDocumentLink2 }) =>
      text ? <a href={permitDocumentLink2}>{text}</a> : '',
  },
  {
    title: 'Comment',
    dataIndex: 'permitComment',
    key: 'permitComment',
    sorter: true,
    width: 80,
  },
];
