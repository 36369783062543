import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
} from '../../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../../shared/EditableTable/tableUtils';
import { action } from '../../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../../pages/PageWithEditableTable';
import {
  openNotificationWithIcon,
  getRequiredErrMsg,
} from '../../../../utils/general';

import { editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const bodyForFirstRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: [
    {
      orderOption: 'Ascending',
      propertyName: 'SpeciesName',
    },
  ],
};

const fetchEnterOwnerPermission = (args, key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_PERMISSIONS_BY_OWNER_DETAILS(key), {
        method: 'post',
        body: JSON.stringify(args || bodyForFirstRequest),
      })
    : {};

const fetchParentData = (ownerKey) =>
  ownerKey
    ? APIFetch(ENDPOINT_URLS.GET_PERMISSIONS_BY_OWNER_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'FirstName' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            {
              operatortype: 'Equals',
              propertyName: 'OwnerKey',
              value: ownerKey,
            },
          ],
        }),
      })
    : {};

const EnterOwnerPermission = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { search: searchParams } = useLocation();
  const { ownerKey: ownerKeyFromUrl } = useParams();

  const [form] = Form.useForm();

  const { data: parentData } = useAPI(() => fetchParentData(ownerKeyFromUrl), {
    initialState: { loading: false },
  });

  const { data, loading, refetch } = useAPI(
    (args) => fetchEnterOwnerPermission(args, ownerKeyFromUrl),
    {
      initialState: { data: {}, loading: false },
    }
  );

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [searchParameters, setSearchParameters] = useState([]);
  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  const cancel = () => {
    setEditingKey('');
  };

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);

    refetch(
      paramsToPassInBodyRequest(parameters, searchParameters, 'SpeciesName')
    );

    setIsRefetchDone(true);

    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    if (searchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          searchParamsArray.filter((i) => i.value),
          'SpeciesName'
        )
      );
      setSearchParameters(searchParamsArray);
    }
  }, [searchParams]);

  const initialState = {
    contactDate: '',
    contactMethod: '',
    nptcontactPerson: '',
    ownerPermission: '',
    ownerComment: '',
    currentYearAttempts: '',
  };

  const save = async (key, ownerkey) => {
    const row = await form.validateFields();
    const currentYear = moment.utc().year();

    const {
      contactDate,
      ownerPermission,
      nptcontactPerson,
      contactMethod,
      ownerComment,
      currentYearAttempts,
    } = row;
    const condition = 'No-NotContacted';

    form.setFields(
      Object.keys(row).map((name) => ({
        name,
        errors: null,
      }))
    );

    if (
      ((ownerPermission !== condition && contactDate && nptcontactPerson) ||
        ownerPermission === condition) &&
      (currentYear === moment.utc(contactDate).year() || !contactDate)
    ) {
      APIFetch(
        ENDPOINT_URLS.UPDATE_PERMISSIONS_BY_OWNER_DETAILS(key, ownerkey),
        {
          method: 'put',
          body: JSON.stringify({
            contactDate: moment.utc(contactDate).toISOString(),
            ownerPermission,
            nptcontactPerson,
            contactMethod,
            ownerComment,
            currentYearAttempts,
          }),
        }
      ).then(() => {
        const newData = [...tableData];
        const index = newData.findIndex((item) => key === item.eokey);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      });
    } else if (ownerPermission !== condition && !contactDate) {
      form.setFields([
        {
          name: 'contactDate',
          errors: [getRequiredErrMsg('Contact Date')],
        },
      ]);
    } else if (
      ownerPermission === condition &&
      contactDate &&
      currentYear !== moment.utc(contactDate).year()
    ) {
      form.setFields([
        {
          name: 'contactDate',
          errors: ['Contact Date must be in current year'],
        },
      ]);
    } else if (ownerPermission !== condition && !nptcontactPerson) {
      form.setFields([
        {
          name: 'nptcontactPerson',
          errors: [getRequiredErrMsg('NPT Contact Person')],
        },
      ]);
    } else if (
      ownerPermission !== condition &&
      !contactDate &&
      !nptcontactPerson
    ) {
      form.setFields([
        {
          name: 'contactDate',
          errors: [getRequiredErrMsg('Contact Date')],
        },
        {
          name: 'nptcontactPerson',
          errors: [getRequiredErrMsg('NPT Contact Person')],
        },
      ]);
    }
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, record) => {
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone
        );
        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];

  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isCellEditing(record, editingKey),
      }),
    };
  });

  useEffect(() => {
    if (parentData?.items.length) {
      const { organization, firstName, lastName } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `${organization} ${firstName} ${lastName}`,
        },
      });
    }
  }, [parentData]);

  return (
    <PageWithEditableTable
      titleKey="PCVAdministration:EnterOwnerPermission"
      descriptionKey="PCVAdministration:EnterOwnerPermissionDescription"
      columns={mergedColumns}
      rowKeyFunc={(record) => record.eokey}
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
      isDataArray
    />
  );
};

export default EnterOwnerPermission;
