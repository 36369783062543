import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithSimpleTableAndSearch from '../../pages/PageWithSimpleTableAndSearch';

import { columns } from './TableColumns';

const fetchState = () =>
  APIFetch(ENDPOINT_URLS.GET_VALIDATION_DATA_STATES, {
    method: 'get',
  });

const State = () => (
  <PageWithSimpleTableAndSearch
    fetchData={() => fetchState()}
    titleKey="Validation:State"
    columns={columns()}
    rowKey={uuidv4()}
  />
);

export default State;
