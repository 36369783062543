import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { paramsForBE } from '../../../utils/general';
import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';

const fetchSeedCollectionEOs = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SEED_COLLECTION_EOS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SEED_COLLECTION_EOS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

export const SeedCollectionEOs = () => (
  <PageWithTable
    fetchData={(args) => fetchSeedCollectionEOs(args)}
    defaultSortField={['State', 'SpeciesName', 'EonumberInt', 'Subpopulation']}
    titleKey="ElementOccurrence:SeedCollectionEOs"
    descriptionKey="ElementOccurrence:SeedCollectionEOsDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1300 }}
    beSearchConfig={beSearchConfig}
    paramsForBE={paramsForBE}
    fetchExport={fetchExport}
  />
);
