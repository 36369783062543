import React, { useState, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { useLocation } from 'react-router-dom';

import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import ValidationDataTab from '../../ValidationDataTabPages/ValidationDataTab';
import PageContent from '../../../../shared/components/PageContent';
import { getIsForbidden } from '../../../../utils/general';
import {
  sectionRarePlant,
  sectionStateHeritageProgram,
  sectionTrainingVideos,
  sectionAdditionalResources,
} from '../const';
import { useAuth } from '../../../../global/stores/auth';

import { columns } from './TableColumns';

import classes from '../../../styles.module.scss';

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_LINKS_PCV_ADMIN, {
    method: 'get',
  });

const fetchDelete = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.DELETE_LINKS_PCV_ADMIN(id), {
        method: 'delete',
      })
    : null;

const PCVInformationManagement = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const [isDelete, setIsDelete] = useState(false);

  const { data, refetch } = useAPI(() => fetch(), {
    initialState: { loading: false },
    shouldFetch: !isForbidden,
  });

  useEffect(() => {
    if (isDelete) {
      refetch();
      setIsDelete(false);
    }
  }, [isDelete]);

  const sectionRarePlantLinks = data?.find(
    (sectionItem) => sectionItem.section === sectionRarePlant
  )?.links;
  const sectionTrainingVideosLinks = data?.find(
    (sectionItem) => sectionItem.section === sectionTrainingVideos
  )?.links;
  const sectionStateHeritageProgramLinks = data?.find(
    (sectionItem) => sectionItem.section === sectionStateHeritageProgram
  )?.links;
  const sectionAdditionalResourcesLinks = data?.find(
    (sectionItem) => sectionItem.section === sectionAdditionalResources
  )?.links;

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdministration:PCVInformationManagement')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVAdministration:PCVInformationManagementDescription')}
      </p>
      {data?.length && (
        <>
          <div key={sectionRarePlant}>
            <ValidationDataTab
              setIsDelete={setIsDelete}
              pageKey={sectionRarePlant}
              pageName="Link"
              fetchDelete={fetchDelete}
              idKey="linkId"
              addBtnTxt={`Add new item to ${sectionRarePlant}`}
              data={
                sectionRarePlantLinks?.length > 0 ? sectionRarePlantLinks : []
              }
              columns={columns()}
              scroll={false}
            />
          </div>
          <div key={sectionTrainingVideos}>
            <ValidationDataTab
              setIsDelete={setIsDelete}
              pageKey={sectionTrainingVideos}
              pageName="Link"
              fetchDelete={fetchDelete}
              idKey="linkId"
              addBtnTxt={`Add new item to ${sectionTrainingVideos}`}
              data={
                sectionTrainingVideosLinks?.length > 0
                  ? sectionTrainingVideosLinks
                  : []
              }
              columns={columns()}
              scroll={false}
            />
          </div>
          <div key={sectionStateHeritageProgram}>
            <ValidationDataTab
              setIsDelete={setIsDelete}
              pageKey={sectionStateHeritageProgram}
              pageName="Link"
              fetchDelete={fetchDelete}
              idKey="linkId"
              addBtnTxt={`Add new item to ${sectionStateHeritageProgram}`}
              data={
                sectionStateHeritageProgramLinks?.length > 0
                  ? sectionStateHeritageProgramLinks
                  : []
              }
              columns={columns()}
              scroll={false}
            />
          </div>
          <div key={sectionAdditionalResources}>
            <ValidationDataTab
              setIsDelete={setIsDelete}
              pageKey={sectionAdditionalResources}
              pageName="Link"
              fetchDelete={fetchDelete}
              idKey="linkId"
              addBtnTxt={`Add new item to ${sectionAdditionalResources}`}
              data={
                sectionAdditionalResourcesLinks?.length > 0
                  ? sectionAdditionalResourcesLinks
                  : []
              }
              columns={columns()}
              scroll={false}
            />
          </div>
        </>
      )}
    </PageContent>
  );
};
export default PCVInformationManagement;
