import React from 'react';
import { Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { edit } from '../../../shared/EditableTable/tableUtils';

export const actionContent = (
  save,
  editable,
  record,
  cancel,
  editingKey,
  form,
  initialState,
  setEditingKey
) => {
  const { t } = useTranslation();

  return editable ? (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        onClick={() => save(record.eokey)}
        style={{
          marginRight: 8,
        }}
      >
        {t('table:update')}
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a onClick={() => cancel()}>{t('table:cancel')}</a>
    </span>
  ) : (
    <Space size="middle">
      <Typography.Link
        disabled={editingKey !== ''}
        onClick={() => {
          edit(record, initialState, form, setEditingKey);
        }}
      >
        {t('table:edit')}
      </Typography.Link>
    </Space>
  );
};

export const findSurvKey = (name, data) => {
  if (name === '' || !name) {
    return 0;
  }

  return data.find((i) => i.item === name).itemKey;
};

export const createBodyForRequest = (data, key, row, surveyors) => {
  const { leadSurvName, surv2Name, surv3Name, surv4Name } = row;

  const editableRow = data?.items.filter((i) => i.eokey === key)[0];

  return {
    leadSurveyor:
      leadSurvName === ''
        ? 0
        : findSurvKey(leadSurvName || editableRow.leadSurvName, surveyors),
    surveyor2:
      surv2Name === ''
        ? 0
        : findSurvKey(surv2Name || editableRow.surv2Name, surveyors),
    surveyor3:
      surv3Name === ''
        ? 0
        : findSurvKey(surv3Name || editableRow.surv3Name, surveyors),
    surveyor4:
      surv4Name === ''
        ? 0
        : findSurvKey(surv4Name || editableRow.surv4Name, surveyors),
  };
};
