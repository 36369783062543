import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import JoditEditor from 'jodit-react';

import { SURVEYOR_TYPE } from '../../../../global/constants';
import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchCreateAnnouncement = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_ANNOUNCEMENT, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const AnnouncementsForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const editor = useRef(null);

  const [initialValues, setInitialValues] = useState(null);
  const [content, setContent] = useState('');

  const { statesData, updateStates } = useStore();

  useEffect(() => {
    if (!statesData.length) updateStates();
  }, []);

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI((args) => fetchCreateAnnouncement(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (statesData.length) {
      setInitialValues({
        surveyorType: SURVEYOR_TYPE[0].itemKey,
        state: '',
      });
    }
  }, [statesData]);

  const onFinish = (values) => {
    const { state } = values;
    refetchCreate({ ...values, state: state || null });
  };

  const onClickCancel = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (createData) {
      const type = createData?.id ? 'success' : 'error';
      const message = createData?.id
        ? 'Announcement was created successfully'
        : 'Failed create Announcement';

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        let parentPathname = pathname.split('/');
        parentPathname = parentPathname
          .slice(0, parentPathname.length - 1)
          .join('/');

        history.replace(parentPathname);
      }
    }
  }, [createData]);

  const buttons =
    'paragraph,|,bold,italic,underline,strikethrough,|,ul,ol,|,align,|,fontsize,brush,|,link,|,undo,redo';

  const config = {
    buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    statusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_clear_html',
    readonly: false,
    useSearch: false,
    spellcheck: false,
    enter: 'DIV',
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdmin:AnnouncementAdd')}
      </Title>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="addAnnouncement"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={['onBlur', 'onChange']}
            >
              {formConfig.map(({ name, label, input, ...rest }) =>
                name === 'announcementText' ? (
                  <Item
                    name="announcementText"
                    label="Announcement Text"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={config}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={() => {}}
                    />
                  </Item>
                ) : (
                  <FormItem
                    key={name}
                    name={name}
                    label={label}
                    input={input}
                    {...rest}
                  />
                )
              )}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading}
                >
                  {t('form:insert')}
                </Button>
                <Button onClick={onClickCancel}>{t('form:clear')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default AnnouncementsForm;
