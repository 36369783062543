import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Popconfirm, Button } from 'antd';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { openNotificationWithIcon } from '../../../../utils/general';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithTable from '../../../pages/PageWithTable';

import { columns } from './TableColumns';

import classes from '../../../styles.module.scss';

const bodyForFirstRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: [
    {
      orderOption: 'Ascending',
      propertyName: 'Year',
    },
  ],
};

const fetchDetails = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_EOS_FOR_FILED_DATA_EDIT_PAGED(key), {
        method: 'get',
      })
    : {};

const fetch = (args, key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FILED_DATA_EDIT_PAGED(key), {
        method: 'post',
        body: JSON.stringify(args || bodyForFirstRequest),
      })
    : {};

const fetchParentData = (eokey) =>
  eokey
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'SpeciesName' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            {
              operatortype: 'Equals',
              propertyName: 'Eokey',
              value: eokey,
            },
          ],
        }),
      })
    : {};

const fetchDelete = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.DELETE_FILED_DATA_EDIT(id), {
        method: 'delete',
      })
    : null;

const FieldData = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { eokey: eokeyFromUrl } = useParams();
  const key = 'ffkey';

  const { data: parentData } = useAPI(() => fetchParentData(eokeyFromUrl), {
    initialState: { loading: false },
  });
  const { data: infoData } = useAPI(() => fetchDetails(eokeyFromUrl), {
    initialState: { loading: false },
  });

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI((id) => fetchDelete(id), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (deleteData) {
      if (deleteData[key]) {
        openNotificationWithIcon(
          'success',
          `Field data was deleted successfully`
        );

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', `Failed delete field data`);
      }
    }
  }, [deleteData]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  useEffect(() => {
    if (parentData?.items?.length) {
      const { state, speciesName, subpopulation, eonumber } =
        parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `${state} ${speciesName} ${eonumber} ${subpopulation}`,
        },
      });
    }
  }, [parentData]);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 90,
      render: (_, record) => (
        <>
          <Link
            style={{
              marginRight: 8,
            }}
            to={`${pathname}/${record[key]}`}
          >
            {t('table:edit')}
          </Link>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => refetchDelete(record[key])}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              style={{
                marginRight: 8,
              }}
            >
              {t('table:delete')}
            </a>
          </Popconfirm>
        </>
      ),
    },
    ...staticColumns,
  ];

  const render = ({ gisPopulationData, historyData }) =>
    gisPopulationData || historyData ? (
      <>
        {gisPopulationData ? (
          <>
            <p>
              <b>GIS population Data</b>
            </p>
            <p>{gisPopulationData}</p>
          </>
        ) : null}

        {gisPopulationData && historyData ? <br /> : null}

        {historyData ? (
          <>
            <p>
              <b>History Data</b>
            </p>
            <p>{historyData}</p>
          </>
        ) : null}
      </>
    ) : null;

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  return (
    <PageWithTable
      fetchData={(args) => fetch(args, eokeyFromUrl)}
      defaultSortField="Year"
      titleKey="ElementOccurrence:FieldData"
      descriptionKey="ElementOccurrence:FieldDataDescription"
      columns={allColumns}
      rowKey={(record) => record.ffkey}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading}
      scroll={{ x: 600 }}
      tableInfo={infoData ? render(infoData) : null}
    >
      <div className={classNames(classes.content)}>
        <Button onClick={create}>Add Field Data</Button>
      </div>
    </PageWithTable>
  );
};

export default FieldData;
