import moment from 'moment';

import { getRequiredErrMsg } from '../../../utils/general';

const minYear = +moment().format('YYYY') - 30;
const maxYear = +moment().format('YYYY') + 30;

export const editableCellsConfig = [
  {
    name: 'nextSurveyYear',
    rules: [
      {
        type: 'number',
        validator: (_, value) =>
          (value >= minYear && value <= maxYear) || value === 0
            ? Promise.resolve()
            : Promise.reject(
                new Error(
                  `NextSurveyYear must be between ${minYear} and ${maxYear} or 0`
                )
              ),
      },
      {
        required: true,
        message: getRequiredErrMsg('NextSurveyYear'),
      },
    ],
  },
  {
    name: 'nextSeedYear',
    rules: [
      {
        type: 'number',
        validator: (_, value) =>
          (value >= minYear && value <= maxYear) || value === 0
            ? Promise.resolve()
            : Promise.reject(
                new Error(
                  `NextSeedYear must be between ${minYear} and ${maxYear} or 0`
                )
              ),
      },
      {
        required: true,
        message: getRequiredErrMsg('NextSeedYear'),
      },
    ],
  },
  {
    name: 'surveyInterval',
    rules: [
      {
        type: 'number',
        min: -1,
        max: 30,
        message: `SurveyInterval must be between -1 and 30`,
      },
      {
        required: true,
        message: getRequiredErrMsg('SurveyInterval'),
      },
    ],
  },
  {
    name: 'seedInterval',
    rules: [
      {
        type: 'number',
        min: -1,
        max: 30,
        message: `SeedInterval must be between -1 and 30`,
      },
      {
        required: true,
        message: getRequiredErrMsg('SeedInterval'),
      },
    ],
  },
  {
    name: 'surveyComment',
    rules: [
      {
        max: 2000,
        message: `Should contain less than 2000 characters.`,
      },
    ],
  },
];
