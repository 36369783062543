import { Card } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../config';
import styles from './styles.module.scss';

const ValidationPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <PageContent>
      <Title level={2}>{t('sections:Validation:title')}</Title>
      {sectionsConfig().validation.map(({ createContent, rowsCount, extra }) => (
        <div className={styles.root} key={`${extra}-${rowsCount}`}>
          <Card>
            <Text strong>{t(extra)}</Text>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableHead}>{t('table:page')}</th>
                  <th className={styles.tableHead}>
                    {t('table:tableValidated')}
                  </th>
                  <th className={styles.tableHead}>{t('table:description')}</th>
                </tr>
              </thead>
              <tbody>
                {Array(rowsCount)
                  .fill()
                  .map((_, index) => {
                    const link = t(createContent(index).page)
                      .split(' ')
                      .join('');
                    const finalLink = [pathname, link].join('/');

                    return (
                      <tr key={t(createContent(index).page)}>
                        <td className={styles.cell}>
                          <Link to={finalLink}>
                            {t(createContent(index).page)}
                          </Link>
                        </td>
                        <td className={styles.cell}>
                          {t(createContent(index).tableValidated)}
                        </td>
                        <td className={styles.cell}>
                          {t(createContent(index).description)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Card>
        </div>
      ))}
    </PageContent>
  );
};

export default ValidationPage;
