import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Space } from 'antd';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { action } from '../../../../shared/EditableTable/components/Action';

import PageWithTable from '../../../pages/PageWithTable';

import { columns } from './TableColumns';
// eslint-disable-next-line import/named
import { actionContent } from '../utils';

const fetchAssignmentSummary = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PCV_TF_ASSIGNMENT_SUMMARY, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const AssignmentSummary = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 50,
      renderFunction: (_, { eokey }) => (
        <Space size="middle">
          <Link to={`${pathname}/${eokey}`}>{t('table:select')}</Link>
        </Space>
      ),
    }),
    action({
      title: t('table:pdf'),
      key: 'operation',
      width: 65,
      renderFunction: (_, record) => actionContent(record, {
        pageName: t('PCVTF:AssignmentSummaryFilenamePostfix'),
        fileTitle: t('PCVTF:AssignmentSummary')
      }),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={(args) => fetchAssignmentSummary(args)}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="PCVTF:AssignmentSummary"
      descriptionKey="PCVTF:AssignmentSummaryDescription"
      columns={allColumns}
      rowKey={(record) => record.eokey}
      scroll={{ x: 2100 }}
    />
  );
};

export default AssignmentSummary;
