import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:latitude'),
      dataIndex: 'latitude1',
      key: 'latitude1',
      sorter: true,
    },
    {
      title: t('table:longitude'),
      dataIndex: 'longitude1',
      key: 'longitude1',
      sorter: true,
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
      sorter: true,
    },
    {
      title: t('table:reviewComments'),
      dataIndex: 'reviewComments',
      key: 'reviewComments',
      sorter: true,
    },
  ];
};
