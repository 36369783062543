import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const DisturbancesSelect = (props) => {
  const { disturbancesData, disturbancesLoading, updateDisturbances } =
    useStore();

  useEffect(() => {
    if (!disturbancesData.length && !disturbancesLoading) updateDisturbances();
  }, []);

  return (
    <Select
      props={props}
      width={170}
      data={disturbancesData}
      loading={disturbancesLoading}
    />
  );
};

export default DisturbancesSelect;
