import React from 'react';
import { useLocation } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import PageWithSimpleTable from '../../pages/PageWithSimpleTable';
import { highlightTotalValue, getIsForbidden } from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';

const fields = ['state', 'surveysAssigned', 'surveysDone', 'surveysNewPop'];

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_FIELD_FORM_STATE, {
    method: 'get',
  });

const FieldFormState = () => {
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading } = useAPI(fetch, {
    initialState: { data: [], loading: false },
    shouldFetch: !isForbidden,
  });

  const tableRows = highlightTotalValue('state', data);

  return (
    <PageWithSimpleTable
      titleKey="PCVAdministration:FieldFormState"
      descriptionKey="PCVAdministration:FieldFormStateDescription"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
      isWithoutRowCountLabel
    />
  );
};

export default FieldFormState;
