import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      sorter: true,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('table:source'),
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
    },
    {
      title: t('table:populationComment'),
      dataIndex: 'populationComment',
      key: 'populationComment',
    },
  ];
};
