import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:contactDate'),
      dataIndex: 'contactDate',
      key: 'contactDate',
      sorter: true,
      editable: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:contactMethod'),
      dataIndex: 'contactMethod',
      key: 'contactMethod',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:nptcontactPerson'),
      dataIndex: 'nptcontactPerson',
      key: 'nptcontactPerson',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:ownerPermission'),
      dataIndex: 'ownerPermission',
      key: 'ownerPermission',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
  ];
};
