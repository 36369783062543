import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
      sorter: true,
      width: 60,
    },
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      sorter: true,
      width: 60,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      width: 100,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:leadSurveyor'),
      dataIndex: 'leadSurveyor',
      key: 'leadSurveyor',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:ramets'),
      dataIndex: 'ramets',
      key: 'ramets',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:genets'),
      dataIndex: 'genets',
      key: 'genets',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:populationArea'),
      dataIndex: 'populationArea',
      key: 'populationArea',
      sorter: true,
      width: 200,
    },
    {
      title: t('table:popAreaUnits'),
      dataIndex: 'popAreaUnits',
      key: 'popAreaUnits',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:populationComment'),
      dataIndex: 'populationComment',
      key: 'populationComment',
      sorter: true,
      width: 200,
    },
    {
      title: t('table:disturbanceComment'),
      dataIndex: 'disturbanceComment',
      key: 'disturbanceComment',
      sorter: true,
      width: 200,
    },
    {
      title: t('table:seedPlantsInFruit'),
      dataIndex: 'seedPlantsInFruit',
      key: 'seedPlantsInFruit',
      sorter: true,
      width: 150,
    },
  ];
};
