import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Form, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { buildSearchParamsForRequest } from '../../sortAndSearchUtils';
import { validateMessages } from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';
import { useStore } from '../../../global/stores/store/StoreContext';
import { roleTypes } from '../../../utils/roles';
import { LIST_OF_PATH_FOR_ALL_STATES } from '../../../global/constants';

import FormItem from '../formItem';

const { Item } = Form;

const timeProps = [
  'NextSurveyYear',
  'NextSeedYear',
  'SurveyInterval',
  'SeedInterval',
  'Year',
];

export const SearchForm = ({
  searchConfig,
  initialValues = {},
  clear = true,
  loading,
  fieldWithoutAll,
  additionalRulesForSurveyPlan,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { search: searchParams, pathname } = useLocation();
  const { user } = useAuth();
  const role = user?.role;
  const isShowAllStates = LIST_OF_PATH_FOR_ALL_STATES.includes(pathname);

  const [initialValue, setInitialValue] = useState(null);

  if (role === roleTypes.Heritage && !isShowAllStates) {
    const {
      statesForSurveyorData,
      statesForSurveyorLoading,
      updateStatesForSurveyor,
    } = useStore();
    
    useEffect(() => {
      if (
        !statesForSurveyorData.length &&
        !statesForSurveyorLoading
      )
      updateStatesForSurveyor();
    }, []);
  
    useEffect(() => {
      if (statesForSurveyorData.length) {
        setInitialValue({
          State: statesForSurveyorData[0].itemKey,
        });
      }
    }, [statesForSurveyorData]);
  } else {
    useEffect(() => {
      setInitialValue(initialValues);
    }, []);
  };
 
  const onFinish = (values) => {
    form.setFields(
      Object.keys(values).map((name) => ({
        name,
        errors: null,
      }))
    );

    const fulfilledFinis = () => {
      const mappedSearchStates = [];

      const mappedSearchParams = Object.keys(values).forEach(
        (searchParam, index) => {
          const { name, operatortype, previousSearchBlockConnectionOperator } =
          searchConfig[index];

          console.log(typeof values.State)
          if (searchParam === 'State' && typeof values.State !== 'string') {
            values.State.map(state => mappedSearchStates.push({ State: [operatortype, state] }));
          } else {
            const value = [operatortype, values[searchParam]];
            
            if (previousSearchBlockConnectionOperator) {
              value.push(previousSearchBlockConnectionOperator);
            }

            mappedSearchStates.push({[name]: value});
          }
        }
      );

      const newSearchParams = mappedSearchStates?.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        return { ...acc, [key]: item[key] };
      }, {});
  
      const searchParamsString = new URLSearchParams();

      mappedSearchStates?.map(item => searchParamsString.append(Object.keys(item)[0], item[Object.keys(item)[0]]))

      history.push({
        pathname: history.location.pathname,
        search: searchParamsString.toString(),
      });
    };

    if (additionalRulesForSurveyPlan) {
      const { NextSeedYear, NextSurveyYear, SurveyInterval, SeedInterval } =
        values;
      const NextSeedYearNumber = Number(NextSeedYear);
      const NextSurveyYearNumber = Number(NextSurveyYear);
      const SurveyIntervalNumber = Number(SurveyInterval);
      const SeedIntervalNumber = Number(SeedInterval);

      if (
        (moment.utc(NextSurveyYearNumber).isBefore(NextSeedYearNumber) ||
          NextSurveyYearNumber === 0 ||
          NextSeedYearNumber === 0 ||
          moment.utc(NextSurveyYearNumber).isSame(NextSeedYearNumber)) &&
        (moment.utc(SurveyIntervalNumber).isBefore(SeedIntervalNumber) ||
          SurveyIntervalNumber === 0 ||
          SeedIntervalNumber === 0 ||
          moment.utc(SurveyIntervalNumber).isSame(SeedIntervalNumber))
      ) {
        fulfilledFinis();
      } else {
        if (
          !moment.utc(NextSurveyYearNumber).isBefore(NextSeedYearNumber) &&
          !moment.utc(NextSurveyYearNumber).isSame(NextSeedYearNumber) &&
          NextSurveyYearNumber !== 0 &&
          NextSeedYearNumber !== 0
        ) {
          form.setFields([
            {
              name: 'NextSurveyYear',
              errors: [
                `Survey year from should be less or equal to Survey year to`,
              ],
            },
          ]);
        }
        if (
          !moment.utc(SurveyIntervalNumber).isBefore(SeedIntervalNumber) &&
          !moment.utc(SurveyIntervalNumber).isSame(SeedIntervalNumber) &&
          SurveyIntervalNumber !== 0 &&
          SeedIntervalNumber !== 0
        ) {
          form.setFields([
            {
              name: 'SurveyInterval',
              errors: [
                `Seed year from should be less or equal to Seed year to`,
              ],
            },
          ]);
        }
      }
    } else if (values[fieldWithoutAll] === '*') {
      form.setFields([
        {
          name: fieldWithoutAll,
          errors: ['Disallow *'],
        },
      ]);
    } else {
      fulfilledFinis();
    }
  };

  const onReset = () => {
    form.resetFields();

    history.push({
      pathname: history.location.pathname,
      search: '',
    });
  };

  useEffect(() => {
    if (searchParams) {
      const searchParamsArray = buildSearchParamsForRequest(searchParams);

      const multStates = searchParamsArray.filter(item => item.propertyName === 'State').map(item => item.value);
      searchParamsArray.map((searchParam) => {
        const { value, propertyName } = searchParam;

        const itemConfig = searchConfig.find(
          ({ name }) => name === propertyName
        );

        const isNumber = !!itemConfig?.rules?.find(
          ({ type }) => type === 'number' || type === 'integer'
        );

        const isBoolean = !!itemConfig?.rules?.find(
          ({ type }) => type === 'boolean'
        );

        let resultValue = value;
        
        if (value) {
          if (timeProps.includes(propertyName)) resultValue = moment.utc(value);
          if (isNumber) resultValue = +value;
          if (isBoolean) resultValue = value === 'true';
        }

        if (propertyName) {
          if (propertyName === 'State' && multStates.length > 1) {
            form.setFieldsValue({
              [propertyName]: multStates,
            });
          } else {
            form.setFieldsValue({
              [propertyName]: resultValue,
            });
          }
        }

        return searchParam;
      });
    } else {
      form.resetFields();
    }
  }, [searchParams]);

  return (
    <Row>
      <Col span={24}>
      {initialValue && (
        <Form
          form={form}
          name="search-form"
          layout="inline"
          onFinish={onFinish}
          initialValues={initialValue}
          validateMessages={validateMessages}
          validateTrigger={['onBlur', 'onChange']}
          className="search-form"
        >
          {(searchConfig || []).map(
            ({ name, label, input, ...rest }) => (
              <FormItem
                key={uuidv4()}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            )
          )}

          <Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Search
            </Button>
            {clear && (
              <Button type="link" onClick={onReset}>
                Clear
              </Button>
            )}
          </Item>
        </Form>
      )}
      </Col>
    </Row>
  );
};
