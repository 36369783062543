import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      width: 100,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      width: 80,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:leadSurveyor'),
      dataIndex: 'leadSurveyor',
      key: 'leadSurveyor',
      width: 100,
    },
    {
      title: t('table:surveyor2'),
      dataIndex: 'surveyor2',
      key: 'surveyor2',
      width: 80,
    },
    {
      title: t('table:surveyor3'),
      dataIndex: 'surveyor3',
      key: 'surveyor3',
      width: 100,
    },
    {
      title: t('table:surveyor4'),
      dataIndex: 'surveyor4',
      key: 'surveyor4',
      width: 120,
    },
    {
      title: t('table:directions'),
      dataIndex: 'directions',
      key: 'directions',
      width: 400,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:speciesDiagnostics'),
      dataIndex: 'speciesDiagnostics',
      key: 'speciesDiagnostics',
      width: 400,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:genetRametSize'),
      dataIndex: 'genetRametSize',
      key: 'genetRametSize',
      width: 70,
    },
    {
      title: t('table:confidenceInId'),
      dataIndex: 'confidenceInId',
      key: 'confidenceInId',
      width: 120,
    },
    {
      title: t('table:referenceUsed'),
      dataIndex: 'referenceUsed',
      key: 'referenceUsed',
      width: 120,
    },
    {
      title: t('table:observedSitePrev'),
      dataIndex: 'observedSitePrev',
      key: 'observedSitePrev',
      width: 70,
    },
    {
      title: t('table:ramets'),
      dataIndex: 'ramets',
      key: 'ramets',
      width: 70,
    },
    {
      title: t('table:genets'),
      dataIndex: 'genets',
      key: 'genets',
      width: 70,
    },
    {
      title: t('table:countPrecision'),
      dataIndex: 'countPrecision',
      key: 'countPrecision',
      width: 70,
    },
    {
      title: t('table:latitude1'),
      dataIndex: 'latitude1',
      key: 'latitude1',
      width: 70,
    },
    {
      title: t('table:longitude1'),
      dataIndex: 'longitude1',
      key: 'longitude1',
      width: 70,
    },
    {
      title: t('table:description1'),
      dataIndex: 'description1',
      key: 'description1',
      width: 70,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:latitude2'),
      dataIndex: 'latitude2',
      key: 'latitude2',
      width: 500,
    },
    {
      title: t('table:longitude2'),
      dataIndex: 'longitude2',
      key: 'longitude2',
      width: 80,
    },
    {
      title: t('table:description2'),
      dataIndex: 'description2',
      key: 'description2',
      width: 80,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:latitude3'),
      dataIndex: 'latitude3',
      key: 'latitude3',
      width: 80,
    },
    {
      title: t('table:longitude3'),
      dataIndex: 'longitude3',
      key: 'longitude3',
      width: 80,
    },
    {
      title: t('table:description3'),
      dataIndex: 'description3',
      key: 'description3',
      width: 80,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:locationPrecision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      width: 80,
    },
    {
      title: t('table:populationComment'),
      dataIndex: 'populationComment',
      key: 'populationComment',
      width: 80,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:populationArea'),
      dataIndex: 'populationArea',
      key: 'populationArea',
      width: 80,
    },
    {
      title: t('table:popAreaUnits'),
      dataIndex: 'popAreaUnits',
      key: 'popAreaUnits',
      width: 80,
    },
    {
      title: t('table:entirePopulation'),
      dataIndex: 'entirePopulation',
      key: 'entirePopulation',
      width: 80,
    },
    {
      title: t('table:elevationMin'),
      dataIndex: 'elevationMin',
      key: 'elevationMin',
      width: 80,
    },
    {
      title: t('table:elevationMax'),
      dataIndex: 'elevationMax',
      key: 'elevationMax',
      width: 80,
    },
    {
      title: t('table:elevUnits'),
      dataIndex: 'elevUnits',
      key: 'elevUnits',
      width: 80,
    },
    {
      title: t('table:topoPosition'),
      dataIndex: 'topoPosition',
      key: 'topoPosition',
      width: 80,
    },
    {
      title: t('table:aspect'),
      dataIndex: 'aspect',
      key: 'aspect',
      width: 80,
    },
    {
      title: t('table:slope'),
      dataIndex: 'slope',
      key: 'slope',
      width: 80,
    },
    {
      title: t('table:light'),
      dataIndex: 'light',
      key: 'light',
      width: 80,
    },
    {
      title: t('table:moistureRegime'),
      dataIndex: 'moistureRegime',
      key: 'moistureRegime',
      width: 80,
    },
    {
      title: t('table:siteCharComment'),
      dataIndex: 'siteCharComment',
      key: 'siteCharComment',
      width: 80,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:pctLeaf'),
      dataIndex: 'pctLeaf',
      key: 'pctLeaf',
      width: 80,
    },
    {
      title: t('table:pctBud'),
      dataIndex: 'pctBud',
      key: 'pctBud',
      width: 120,
    },
    {
      title: t('table:pctFlower'),
      dataIndex: 'pctFlower',
      key: 'pctFlower',
      width: 120,
    },
    {
      title: t('table:pctImmFruit'),
      dataIndex: 'pctImmFruit',
      key: 'pctImmFruit',
      width: 120,
    },
    {
      title: t('table:pctMatFruit'),
      dataIndex: 'pctMatFruit',
      key: 'pctMatFruit',
      width: 80,
    },
    {
      title: t('table:pctSeed'),
      dataIndex: 'pctSeed',
      key: 'pctSeed',
      width: 120,
    },
    {
      title: t('table:pctDormant'),
      dataIndex: 'pctDormant',
      key: 'pctDormant',
      width: 120,
    },
    {
      title: t('table:pctSeedling'),
      dataIndex: 'pctSeedling',
      key: 'pctSeedling',
      width: 120,
    },
    {
      title: t('table:pctImmature'),
      dataIndex: 'pctImmature',
      key: 'pctImmature',
      width: 80,
    },
    {
      title: t('table:pctMature'),
      dataIndex: 'pctMature',
      key: 'pctMature',
      width: 120,
    },
    {
      title: t('table:pctSenesc'),
      dataIndex: 'pctSenesc',
      key: 'pctSenesc',
      width: 120,
    },
    {
      title: t('table:pctUnknown'),
      dataIndex: 'pctUnknown',
      key: 'pctUnknown',
      width: 120,
    },
    {
      title: t('table:naturalCommunity'),
      dataIndex: 'naturalCommunity',
      key: 'naturalCommunity',
      width: 80,
    },
    {
      title: t('table:mgtComment'),
      dataIndex: 'mgtComment',
      key: 'mgtComment',
      width: 120,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:otherComment'),
      dataIndex: 'otherComment',
      key: 'otherComment',
      width: 120,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:ownerComment'),
      dataIndex: 'ownerComment',
      key: 'ownerComment',
      width: 120,
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:alteredHydrology'),
      dataIndex: 'alteredHydrology',
      key: 'alteredHydrology',
      width: 80,
    },
    {
      title: t('table:directHumanThreat'),
      dataIndex: 'directHumanThreat',
      key: 'directHumanThreat',
      width: 120,
    },
    {
      title: t('table:erosion'),
      dataIndex: 'erosion',
      key: 'erosion',
      width: 120,
    },
    {
      title: t('table:fireSuppression'),
      dataIndex: 'fireSuppression',
      key: 'fireSuppression',
      width: 120,
    },
    {
      title: t('table:herbivory'),
      dataIndex: 'herbivory',
      key: 'herbivory',
      width: 80,
    },
    {
      title: t('table:invasiveSpecies'),
      dataIndex: 'invasiveSpecies',
      key: 'invasiveSpecies',
      width: 120,
    },
    {
      title: t('table:succession'),
      dataIndex: 'succession',
      key: 'succession',
      width: 120,
    },
    {
      title: t('table:noThreat'),
      dataIndex: 'noThreat',
      key: 'noThreat',
      width: 120,
    },
    {
      title: t('table:threatScope'),
      dataIndex: 'threatScope',
      key: 'threatScope',
      width: 80,
    },
    {
      title: t('table:threatSeverity'),
      dataIndex: 'threatSeverity',
      key: 'threatSeverity',
      width: 120,
    },
    {
      title: t('table:assocSpecies'),
      dataIndex: 'assocSpecies',
      key: 'assocSpecies',
      width: 120,
    },
    {
      title: t('table:invSpecies'),
      dataIndex: 'invSpecies',
      key: 'invSpecies',
      width: 120,
    },
  ];
};
