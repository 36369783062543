import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general'

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:hrptaxonName'),
      dataIndex: 'hrptaxonName',
      key: 'hrptaxonName',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:township'),
      dataIndex: 'township',
      key: 'township',
      sorter: true,
    },
    {
      title: t('table:primaryCollector'),
      dataIndex: 'primaryCollector',
      key: 'primaryCollector',
      sorter: true,
    },
    {
      title: t('table:collectionNumber'),
      dataIndex: 'collectionNumber',
      key: 'collectionNumber',
      sorter: true,
    },
    {
      title: t('table:herbariumAcronym'),
      dataIndex: 'herbariumAcronym',
      key: 'herbariumAcronym',
      sorter: true,
    },
    {
      title: t('table:collectionNotes'),
      dataIndex: 'collectionNotes',
      key: 'collectionNotes',
      sorter: true,
    },
    {
      title: t('table:collectionDate'),
      dataIndex: 'collectionDate',
      key: 'collectionDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:labelData'),
      dataIndex: 'labelData',
      key: 'labelData',
      sorter: true,
    },
    {
      title: t('table:locationNotes'),
      dataIndex: 'locationNotes',
      key: 'locationNotes',
      sorter: true,
    },
    {
      title: t('table:primaryDetermination'),
      dataIndex: 'primaryDetermination',
      key: 'primaryDetermination',
      sorter: true,
    },
    {
      title: t('table:lastDetermination'),
      dataIndex: 'lastDetermination',
      key: 'lastDetermination',
      sorter: true,
    },
    {
      title: t('table:annotator'),
      dataIndex: 'annotator',
      key: 'annotator',
      sorter: true,
    },
    {
      title: t('table:annotationNotes'),
      dataIndex: 'annotationNotes',
      key: 'annotationNotes',
      sorter: true,
    },
    {
      title: t('table:collectionYear'),
      dataIndex: 'collectionYear',
      key: 'collectionYear',
      sorter: true,
    },
  ];
};
