import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Form, Button, Row, Col, Spin, Progress } from 'antd';
import _ from 'underscore';
import { useHistory, useLocation } from 'react-router-dom';

import { dataPercent, dataYesNo } from '../../../global/constants';
// eslint-disable-next-line import/no-cycle
import { PCV_ROUTE } from '../../../AppRoutes';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAuth } from '../../../global/stores/auth';
import FormItem from '../../../shared/components/formItem';
import {
  openNotificationWithIcon,
  validateMessages,
  getFormData,
  validateSeedPlants,
  validateUser,
  handleUnits,
  validateRametsGenets,
  validateNoThreat,
  getIsForbidden,
  validateSurveyors,
  validateSurveyor,
} from '../../../utils/general';
import { useStore } from '../../../global/stores/store/StoreContext';
// eslint-disable-next-line import/no-cycle
import PageContent from '../../../shared/components/PageContent';
import { DATA_YES_NO_NONE } from '../../FieldForm/constants';

import { editFormConfig as formConfig } from './form-config';

import classes from '../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const numberFields = ['ramets', 'genets'];

const fetchSurveyors = () =>
  APIFetch(ENDPOINT_URLS.GET_SURVEYORS, { method: 'get' });

const fetchCreate = (args, setProgress) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_FIELD_FORM_NEW, {
        method: 'post',
        initialHeaders: {},
        body: args,
        setProgress,
      })
    : {};

const FieldFormNew = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const [form] = Form.useForm();

  const isForbidden = getIsForbidden(role, pathname);

  const [progress, setProgress] = useState(0);
  const { data, loading, refetch } = useAPI((args) => fetchCreate(args, setProgress), {
    initialState: { data: {}, loading: false },
    shouldFetch: false,
  });

  const { data: surveyors } = useAPI(fetchSurveyors, {
    initialState: { data: [] },
    shouldFetch: !isForbidden,
  });

  const [newState, setNewState] = useState(null);
  const initialDisabledFields = ['popAreaUnits', 'elevUnits'];
  const [disabledFields, setDisabledFields] = useState(initialDisabledFields);
  const [initialValues, setInitialValues] = useState(null);

  const {
    moistureRegimesData,
    updateMoistureRegimes,
    lightsData,
    updateLights,
    aspectsData,
    updateAspects,
    topoPositionsData,
    updateTopoPositions,
    countPrecisionsData,
    updateCountPrecisions,
    locationPrecisionsForFieldEnterData,
    updateLocationPrecisionsForFieldEnter,
    slopesData,
    updateSlopes,
    statesData,
    updateStates,
    speciesNamesData,
    updateSpeciesNames,
  } = useStore();

  const clearErrorMessages = (values) => {
    form.setFields(
      Object.keys(values).map((name) => ({
        name,
        errors: null,
      }))
    );
  };

  const onFinish = (values) => {
    const {
      surveyDate,
      seedDateSent,
      signatureDate,
      leadSurveyor,
      surveyor2,
      surveyor3,
      surveyor4,
      speciesName,
      files,
      ...other
    } = values;

    const genets = form.getFieldValue('genets');
    const ramets = form.getFieldValue('ramets');

    const rametsGenetsValidation = validateRametsGenets(ramets, genets, t);
    if (rametsGenetsValidation) {
      form.setFields(rametsGenetsValidation);
      form.scrollToField('ramets');

      return;
    }

    clearErrorMessages(values);

    delete other.eokey;

    const surveyorsValidation = validateSurveyors(
      surveyors,
      { fieldName: 'leadSurveyor', fieldValue: leadSurveyor },
      { fieldName: 'surveyor2', fieldValue: surveyor2 },
      { fieldName: 'surveyor3', fieldValue: surveyor3 },
      { fieldName: 'surveyor4', fieldValue: surveyor4 }
    );

    if (surveyorsValidation.length) {
      form.setFields(surveyorsValidation);
      form.scrollToField(surveyorsValidation[0].name);

      return;
    }

    const args = {
      ...other,
      surveyDate: surveyDate ? surveyDate.format('YYYY-MM-DD') : null,
      seedDateSent: seedDateSent ? seedDateSent.format('YYYY-MM-DD') : null,
      signatureDate: signatureDate ? signatureDate.format('YYYY-MM-DD') : null,
      speciesKey: _.findWhere(speciesNamesData, {
        item: speciesName,
      })?.itemKey,
      leadSurveyor: _.findWhere(surveyors, { item: leadSurveyor })?.itemKey,
      surveyor2: surveyor2
        ? _.findWhere(surveyors, { item: surveyor2 })?.itemKey
        : 0,
      surveyor3: surveyor3
        ? _.findWhere(surveyors, { item: surveyor3 })?.itemKey
        : 0,
      surveyor4: surveyor4
        ? _.findWhere(surveyors, { item: surveyor4 })?.itemKey
        : 0,
      files: files
        ? files.fileList.map((file) => file?.originFileObj || file)
        : null,
    };

    Object.keys(args)
      .filter((key) => !args[key] && numberFields.includes(key))
      .map((key) => {
        args[key] = 0;

        return key;
      });

    const formData = getFormData(args);

    refetch(formData);
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      if (data.state) {
        openNotificationWithIcon(
          'success',
          'Field Form was created successfully'
        );

        setDisabledFields(initialDisabledFields);
        form.resetFields();
        history.push(PCV_ROUTE);
      }
    }
  }, [data]);

  const changeFields = (e) => {
    const fieldName = e[0].name[0];
    const fieldValue = e[0].value;

    const ramets = form.getFieldValue('ramets');
    const genets = form.getFieldValue('genets');
    const seedPlantsSampled = form.getFieldValue('seedPlantsSampled');
    const seedPlantsInFruit = form.getFieldValue('seedPlantsInFruit');
    const populationArea = form.getFieldValue('populationArea');
    const elevationMin = form.getFieldValue('elevationMin');
    const elevationMax = form.getFieldValue('elevationMax');

    const seedPlantsValidation = validateSeedPlants(fieldName, fieldValue, {
      ramets,
      seedPlantsSampled,
      seedPlantsInFruit,
      genets
    });
    if (seedPlantsValidation) {
      form.setFields(seedPlantsValidation);
    }

    const userValidation = validateUser(fieldName, fieldValue, user);
    if (userValidation) {
      form.setFields(userValidation);
    }

    const { newDisabledFields, fields: unitsFieldsValue } = handleUnits(
      fieldName,
      fieldValue,
      disabledFields,
      { elevationMin, elevationMax }
    );
    if (newDisabledFields) {
      setDisabledFields(newDisabledFields);
    }
    if (unitsFieldsValue) {
      form.setFieldsValue(unitsFieldsValue);
    }

    const fields = [
      'alteredHydrology',
      'directHumanThreat',
      'erosion',
      'herbivory',
      'fireSuppression',
      'invasiveSpecies',
      'succession',
      'threatScope',
      'threatSeverity',
    ];

    const noThreatValidation = validateNoThreat(fieldName, fieldValue, fields);
    if (noThreatValidation) form.setFields(noThreatValidation);

    if (fieldName === 'noThreat' && fieldValue === 'Yes') {
      form.setFields(
        fields.map((name) => ({ name, value: DATA_YES_NO_NONE[2].itemKey }))
      );

      const resultArray = disabledFields.filter((el) => !fields.includes(el));
      setDisabledFields([...fields, ...resultArray]);
    }

    if (fieldName === 'noThreat' && fieldValue !== 'Yes') {
      setDisabledFields([]);

      if (!populationArea) {
        setDisabledFields(initialDisabledFields);
      }

      if (fieldValue === 'NONE') {
        form.setFields(
          fields.map((name) => ({ name, value: DATA_YES_NO_NONE[0].itemKey }))
        );
      }
    }

    if (
      fieldName === 'leadSurveyor' ||
      fieldName === 'surveyor2' ||
      fieldName === 'surveyor3' ||
      fieldName === 'surveyor4'
    ) {
      const surveyorValidation = validateSurveyor(
        surveyors,
        fieldName,
        fieldValue
      );

      if (surveyorValidation) {
        form.setFields([surveyorValidation]);
      } else {
        form.setFields([{ name: fieldName, value: fieldValue, errors: [] }]);
      }
    }
  };

  useEffect(() => {
    if (!isForbidden) {
      if (!moistureRegimesData.length) updateMoistureRegimes();
      if (!lightsData.length) updateLights();
      if (!aspectsData.length) updateAspects();
      if (!topoPositionsData.length) updateTopoPositions();
      if (!countPrecisionsData.length) updateCountPrecisions();
      if (!locationPrecisionsForFieldEnterData.length)
        updateLocationPrecisionsForFieldEnter();
      if (!slopesData.length) updateSlopes();
      if (!speciesNamesData.length) updateSpeciesNames();
      if (!statesData.length) updateStates();
    }
  }, []);

  useEffect(() => {
    if (
      moistureRegimesData.length &&
      lightsData.length &&
      aspectsData.length &&
      topoPositionsData.length &&
      countPrecisionsData.length &&
      locationPrecisionsForFieldEnterData.length &&
      slopesData.length &&
      statesData.length &&
      speciesNamesData.length
    ) {
      const locationPrecisionNoneIndex = locationPrecisionsForFieldEnterData
        .map((e) => e.itemKey)
        .indexOf('NONE');

      setInitialValues({
        state: statesData[0].itemKey,
        moistureRegime: moistureRegimesData[0].itemKey,
        light: lightsData[0].itemKey,
        aspect: aspectsData[0].itemKey,
        topoPosition: topoPositionsData[0].itemKey,
        locationPrecision:
          locationPrecisionsForFieldEnterData[locationPrecisionNoneIndex]
            ?.itemKey || locationPrecisionsForFieldEnterData[0].itemKey,
        slope: slopesData[0].itemKey,
        alteredHydrology: dataYesNo[0].itemKey,
        directHumanThreat: dataYesNo[0].itemKey,
        erosion: dataYesNo[0].itemKey,
        fireSuppression: dataYesNo[0].itemKey,
        herbivory: dataYesNo[0].itemKey,
        invasiveSpecies: dataYesNo[0].itemKey,
        succession: dataYesNo[0].itemKey,
        noThreat: dataYesNo[0].itemKey,
        threatScope: dataPercent[0].itemKey,
        threatSeverity: dataPercent[0].itemKey,
        entirePopulation: dataYesNo[0].itemKey,
        observedSitePrev: dataYesNo[0].itemKey,
      });
    }
  }, [
    moistureRegimesData,
    lightsData,
    aspectsData,
    topoPositionsData,
    countPrecisionsData,
    locationPrecisionsForFieldEnterData,
    slopesData,
    statesData,
    speciesNamesData,
  ]);

  const onClickCancel = () => {
    form.resetFields();
    history.push(PCV_ROUTE);
  };

  const onValuesChange = (values) => {
    if (values.state) {
      setNewState(values.state);
    }
  };

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVTF:FieldFormNew')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVTF:FieldFormNewDescription')}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              onFieldsChange={(e) => changeFields(e)}
              {...layout}
              scrollToFirstError
              form={form}
              name="createFieldFormEnter"
              onFinish={onFinish}
              initialValues={initialValues}
              validateTrigger={['onBlur', 'onChange']}
              validateMessages={validateMessages}
              onValuesChange={onValuesChange}
            >
              {formConfig.map(
                ({ name, label, input, infoKey, text, ...rest }) => (
                  <>
                    {text && (
                      <>
                        <p className={classNames(classes.infoMessage)}>
                          {t(`info:${text}`)}
                        </p>
                        <br />
                      </>
                    )}
                    <FormItem
                      disabled={disabledFields.includes(name)}
                      key={name}
                      name={name}
                      label={label}
                      input={input}
                      {...rest}
                    />
                    {infoKey && (newState === 'MA' || infoKey === 'uploadFilesNew') && (
                      <>
                        <p className={classNames(classes.infoMessage)}>
                          {t(`info:${infoKey}`)}
                        </p>
                        <br />
                      </>
                    )}
                  </>
                )
              )}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <div>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {t('form:submit')}
                  </Button>
                  <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
                  {loading && <Progress percent={progress} steps={10} />}
                </div>

              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default FieldFormNew;
