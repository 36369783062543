import React from 'react';

import StatesSelect from '../../../shared/components/select/StatesSelect';

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'Element Occurrence species name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'Town',
    label: 'Element occurrence town',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'SiteName',
    label: 'Element occurrence sitename',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'SpeciesName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Synonyms',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
];
