import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'speciesWithNoEototal',
  'speciesWithNoEodivision1',
  'speciesWithNoEodivision2',
  'speciesWithNoEodivision2a',
  'speciesWithNoEodivision3a',
  'speciesWithNoEodivision3ab',
  'speciesWithNoEodivision3b',
  'speciesWithNoEodivision4',
  'speciesWithNoEodivisionInd',
  'speciesWithNoEodivisionNone',
];

const fetchSpeciesWithoutEOs = () =>
  APIFetch(ENDPOINT_URLS.GET_SPECIES_WITHOUT_EOS, {
    method: 'get',
  });

export const SpeciesWithoutEOs = () => {
  const { data, loading } = useAPI(() => fetchSpeciesWithoutEOs(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('speciesWithEototal', data);

  return (
    <PageWithSimpleTable
      titleKey="Species:SpeciesWithoutEOs"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};
