import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { paramsForBE } from '../../../utils/general';
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';
import { columns } from './TableColumns';

const fetchSurveyorHistory = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYOR_HISTORY, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const SurveyorHistory = () => (
  <PageWithTable
    fetchData={(args) => fetchSurveyorHistory(args)}
    defaultSortField={[
      'State',
      'SpeciesName',
      'EonumberInt',
      'Subpopulation',
      'SurveyDate',
    ]}
    titleKey="PCVAdmin:SurveyorHistory"
    descriptionKey="PCVAdmin:SurveyorHistoryDescription"
    paramsForBE={paramsForBE}
    beSearchConfig={beSearchConfig}
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1500 }}
  />
);

export default SurveyorHistory;
