import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = [
  'ownerComment',
  'organization',
  'subOrganization',
  'firstName',
  'lastName',
  'phone',
  'email',
  'permitTypeRequested',
  'permitStartDate#date',
  'permitEndDate#date',
  'permitDocument1',
  'permitDocument2',
  'permitComment',
  'ownerPermission',
];

const OwnerSurveyInformation = ({ data }) => (
  <ContainerWithSimpleTable
    titleKey="PCVAdministration:OwnerSurveyInformation"
    data={data}
    loading={false}
    fields={fields}
    rowKey={uuidv4()}
    scroll={{ x: 1800 }}
    fullWidth
    formatWithYear
  />
);

export default OwnerSurveyInformation;
