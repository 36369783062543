import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import moment from 'moment';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { action } from '../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../pages/PageWithEditableTable';
import {
  downloadExcel,
  openNotificationWithIcon,
  paramsForBE,
} from '../../../utils/general';

import SearchForm from './SearchForm';
import { beSearchConfig, editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchSurveyResultPriorYear = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_RESULT_PRIOR_YEAR_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_RESULT_PRIOR_YEAR_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const SurveyResultPriorYear = () => {
  const { t } = useTranslation();
  const { pathname, search: searchParams } = useLocation();

  const [form] = Form.useForm();

  const { data, loading, refetch } = useAPI(
    (args) => fetchSurveyResultPriorYear(args),
    {
      initialState: { data: {}, loading: false, shouldFetch: false },
    }
  );

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({});

  const cancel = () => {
    setEditingKey('');
  };
  const defaultSortField = [
    'State',
    'SpeciesName',
    'Eonumber',
    'Subpopulation',
  ];
  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;

    refetch(
      paramsToPassInBodyRequest(parameters, searchParam, defaultSortField)
    );
  };

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);
    if (searchParamsArray.length || Object.keys(data).length) {
      const searchParamsWithYearValue = searchParamsArray.map((i) =>
        i.propertyName === 'Year'
          ? {
              propertyName: 'Year',
              value: moment(i.value).year(),
            }
          : i
      );
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          paramsForBE
            ? paramsForBE(searchParamsWithYearValue, beSearchConfig).filter(
                (i) => i.value
              )
            : searchParamsWithYearValue.filter((i) => i.value),
          defaultSortField
        )
      );
      setSearchParameters(searchParamsWithYearValue);
    }
  }, [searchParams]);

  const [parametersForExport, setParametersForExport] = useState();
  const onExport = () => {
    if (parametersForExport) {
      const parameters = getRandomuserParams(parametersForExport);
      const searchParam = paramsForBE(searchParameters, beSearchConfig).filter(
        ({ value }) => !!value
      );

      refetchExport(
        paramsToPassInBodyRequest(parameters, searchParam, defaultSortField)
      );
    } else {
      const searchParamsArray = buildSearchParamsForRequest(searchParams);
      const parameters = paramsToPassInBodyRequest(
        {
          pagination: initialPagination,
        },
        paramsForBE
          ? paramsForBE(searchParamsArray, beSearchConfig)
          : searchParamsArray,
        defaultSortField
      );
      if (parameters.State === '') {
        parameters.State = null;
      }
      refetchExport(parameters);
    }
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  const initialState = {
    surveyResult: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();

    const index = tableData.findIndex((item) => key === item.eokey);
    const { year } = tableData[index];

    APIFetch(ENDPOINT_URLS.UPDATE_SURVEY_RESULT_PRIOR_YEAR(key, year), {
      method: 'put',
      body: JSON.stringify(row),
    }).then(({ status }) => {
      if (status) {
        const newData = [...tableData];

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      }
    });
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 90,
      renderFunction: (record) => {
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey
        );

        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isCellEditing(record, editingKey),
      }),
    };
  });

  return (
    <PageWithEditableTable
      titleKey="PCVAdmin:SurveyResultPriorYear"
      descriptionKey="PCVAdmin:SurveyResultPriorYearDescription"
      searchForm={SearchForm}
      columns={mergedColumns}
      rowKeyFunc={(record) => record.eokey}
      editableCellsConfig={editableCellsConfig}
      setParametersForExport={setParametersForExport}
      fetch={fetch}
      isExportContent
      onExport={onExport}
      exportLoading={exportLoading}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
    />
  );
};

export default SurveyResultPriorYear;
