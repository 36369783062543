import React, { useEffect, useState, useRef } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Row, Col, Form, Button, Spin } from 'antd';
import JoditEditor from 'jodit-react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../shared/components/formItem';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../utils/general';

import SendEmailForm from './SendEmailForm';

import classes from '../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchUpdate = (key, args) =>
  APIFetch(ENDPOINT_URLS.UPDATE_EMAIL(key), {
    method: 'put',
    body: JSON.stringify(args),
  });

const EmailForm = ({ typeOfEmail, subject, body }) => {
  const editor = useRef(null);

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState(null);
  const [content, setContent] = useState('');

  const {
    data: updateData,
    loading: updateLoading,
    refetch: updateRefetch,
  } = useAPI((args) => fetchUpdate(typeOfEmail, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (body) {
      setInitialValues({ body, subject });
      setContent(body);
    }
  }, [body]);

  const onFinish = (values) => {
    const { subject: subjectValue } = values;

    const val = {
      body: content,
      subject: subjectValue || '*',
    };

    updateRefetch(val);
  };

  useEffect(() => {
    if (updateData) {
      const { typeOfEmail: emailType, ...other } = updateData;

      let type = '';
      let message = '';

      if (emailType) {
        type = 'success';
        message = 'Email was updated successfully';

        setInitialValues(other);
      } else {
        type = 'error';
        message = 'Failed update Email';
      }

      openNotificationWithIcon(type, message);
    }
  }, [updateData]);

  const onClickCancel = () => {
    form.resetFields();
  };

  const buttons =
    'paragraph,|,bold,italic,underline,strikethrough,|,ul,ol,|,align,|,fontsize,brush,|,link,|,undo,redo';

  const config = {
    buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    statusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_clear_html',
    readonly: false,
    useSearch: false,
    spellcheck: false,
    enter: 'DIV',
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };

  return (
    <div>
      <Title level={4}>{t(`PCVAdmin:${typeOfEmail}Title`)}</Title>
      <p>{t(`PCVAdmin:${typeOfEmail}Description`)}</p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={24} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="updateEmail"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
            >
              {!!subject && subject !== '*' && (
                <FormItem
                  name="subject"
                  label="Subject"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              )}
              <Item
                name="body"
                label="Body"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  onBlur={(newContent) => setContent(newContent)}
                  onChange={() => {}}
                />
              </Item>

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateLoading}
                >
                  {t('form:save')}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}

          {typeOfEmail === 'Organization' ? (
            <SendEmailForm typeOfEmail={typeOfEmail} />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default EmailForm;
