import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general'

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      sorter: true,
    },
    {
      title: t('table:accNbr'),
      dataIndex: 'accNbr',
      key: 'accNbr',
      sorter: true,
    },
    {
      title: t('table:packageNbr'),
      dataIndex: 'packageNbr',
      key: 'packageNbr',
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:rcvdDate'),
      dataIndex: 'rcvdDate',
      key: 'rcvdDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:collector'),
      dataIndex: 'collector',
      key: 'collector',
      sorter: true,
    },
    {
      title: t('table:seedTotal'),
      dataIndex: 'seedTotal',
      key: 'seedTotal',
      sorter: true,
    },
    {
      title: t('table:seedNumberPlants'),
      dataIndex: 'seedNumberPlants',
      key: 'seedNumberPlants',
      sorter: true,
    },
    {
      title: t('table:seedsInPacket1'),
      dataIndex: 'seedsInPacket1',
      key: 'seedsInPacket1',
      sorter: true,
    },
    {
      title: t('table:seedsForTrials'),
      dataIndex: 'seedsForTrials',
      key: 'seedsForTrials',
      sorter: true,
    },
    {
      title: t('table:seedDatePackaged'),
      dataIndex: 'seedDatePackaged',
      key: 'seedDatePackaged',
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
      sorter: true,
    },
    {
      title: t('table:notes'),
      dataIndex: 'notes',
      key: 'notes',
      sorter: true,
    },
    {
      title: t('table:speciesNameOriginal'),
      dataIndex: 'speciesNameOriginal',
      key: 'speciesNameOriginal',
      sorter: true,
    },
    {
      title: t('table:lastUpdate'),
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:lastSpecCountTotal'),
      dataIndex: 'lastSpecCountTotal',
      key: 'lastSpecCountTotal',
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      sorter: true,
    },
    {
      title: t('table:materialType'),
      dataIndex: 'materialType',
      key: 'materialType',
      sorter: true,
    },
    {
      title: t('table:condition'),
      dataIndex: 'condition',
      key: 'condition',
      sorter: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      sorter: true,
    },
  ];
};
