import React from 'react';
import PhenologyTypesSelect from '../../../shared/components/select/PhenologyTypesSelect';

export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'PhenologyType',
    label: 'Phenology',
    input: (props) => <PhenologyTypesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
