import React from 'react';
import { DatePicker, Input } from 'antd';

import PermitTypesSelect from '../../../shared/components/select/PermitTypesSelect';
import NonStayOffOwnerPermissionsSelect from '../../../shared/components/select/NonStayOffOwnerPermissionsSelect';
import Uploader from '../../../shared/components/Upload';
import { ENDPOINT_URLS } from '../../../global/api';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { getMomentDate } from '../../../utils/general';

const fetchDelete = (ownerKey, permitFileNumber) =>
  APIFetch(ENDPOINT_URLS.DELETE_PERMIT_DOC(ownerKey, permitFileNumber), {
    method: 'post',
  });

const { TextArea } = Input;

const disabledDate = (current) => current?.valueOf() > Date.now();

export const feSearchConfig = [
  {
    name: 'LastName',
    label: 'Owner Name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
];

export const beSearchConfig = [
  {
    propertyName: 'LastName ',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
  },
  {
    propertyName: 'FirstName ',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    propertyName: 'Organization ',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    propertyName: 'SubOrganization',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
];

export const editableCellsConfig = [
  {
    name: 'permitTypeRequested',
    input: (props) => <PermitTypesSelect {...props} />,
  },
  {
    name: 'datePermitRequestSubmitted',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        format="YYYY-MM-DD"
        disabledDate={disabledDate}
      />
    ),
  },
  {
    name: 'datePermitRequestAcknowledged',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        format="YYYY-MM-DD"
        disabledDate={disabledDate}
      />
    ),
  },
  {
    name: 'datePermitResponseRecd',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        format="YYYY-MM-DD"
        disabledDate={disabledDate}
      />
    ),
  },
  {
    name: 'permitResponse',
    input: (props) => <NonStayOffOwnerPermissionsSelect {...props} />,
  },
  {
    name: 'permitStartDate',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        format="YYYY-MM-DD"
      />
    ),
  },
  {
    name: 'permitEndDate',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        format="YYYY-MM-DD"
      />
    ),
  },
  {
    name: 'permitDocument1',
    input: ({ record, ...props }) => {
      const { ownerKey, permitDocumentLink1 } = record;

      const onDelete = () => fetchDelete(ownerKey, 1);

      return (
        <Uploader
          {...props}
          record={record}
          maxCount={1}
          onDelete={permitDocumentLink1 ? onDelete : null}
        />
      );
    },
    addRecordData: true,
  },
  {
    name: 'permitDocument2',
    input: ({ record, ...props }) => {
      const { ownerKey, permitDocumentLink2 } = record;

      const onDelete = () => fetchDelete(ownerKey, 2);

      return (
        <Uploader
          {...props}
          record={record}
          maxCount={1}
          onDelete={permitDocumentLink2 ? onDelete : null}
        />
      );
    },
    addRecordData: true,
  },
  {
    name: 'permitComment',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
];
