import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';

const defaultBodyRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: [
    {
      orderOption: 'Ascending',
      propertyName: 'FirstName',
    },
  ],
};

const fetchSurveyorsNoState = (args) =>
  APIFetch(ENDPOINT_URLS.GET_SURVEYORS_NO_STATE, {
    method: 'post',
    body: JSON.stringify(args || defaultBodyRequest),
  });

export const SurveyorsNoState = () => (
  <PageWithTable
    fetchData={(args) => fetchSurveyorsNoState(args)}
    defaultSortField={['LastName', 'FirstName']}
    titleKey="Surveyors:SurveyorsNoState"
    descriptionKey="Surveyors:SurveyorsNoStateDescription"
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1500 }}
  />
);
