import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const DaysAvailableToSurveySelect = (props) => {
  const { daysAvailableData, daysAvailableLoading, updateDaysAvailable } = useStore();
  const { multiple } = props;

  useEffect(() => {
    if (!daysAvailableData?.length && !daysAvailableLoading) updateDaysAvailable();
  }, []);

  return (
    <Select
      props={props}
      width={multiple ? 150 : 150}
      data={daysAvailableData}
      loading={daysAvailableLoading}
    />
  );
};

export default DaysAvailableToSurveySelect;