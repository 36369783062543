import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useStore } from '../../../../global/stores/store/StoreContext';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import ValidationDataForm from '../../../ValidationData/ValidationDataPages/ValidationDataForm';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

const fetchParentData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_STATE_DETAILS(key), {
        method: 'get',
      })
    : {};

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_SURVEYOR_STATE, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const AddStateForSurveyor = () => {
  const { surveyorKey: surveyorKeyFromUrl } = useParams();

  const { statesData, updateStates } = useStore();

  useEffect(() => {
    if (!statesData.length) updateStates();
  }, []);

  const [configOptions, setConfigOptions] = useState();

  const { data: parentData } = useAPI(
    () => fetchParentData(surveyorKeyFromUrl),
    {
      initialState: { loading: false },
    }
  );

  useEffect(() => {
    if (parentData && statesData.length) {
      let options = statesData;

      parentData.map((item) => {
        options = options.filter((i) => i.item !== item.state);

        return item;
      });

      setConfigOptions(options);
    }
  }, [parentData, statesData]);

  const [initialValue, setInitialValue] = useState();

  useEffect(() => {
    if (configOptions?.length) {
      setInitialValue(configOptions[0]);
    }
  }, [configOptions]);

  const getParamsForInitialValues = (isUpdateMode, params) => {
    if (!isUpdateMode) {
      return {
        state: initialValue?.itemKey,
      };
    }

    return params;
  };

  const getParamsForSubmit = (isUpdateMode, params) =>
    isUpdateMode
      ? { ...params }
      : { ...params, surveyorKey: surveyorKeyFromUrl };

  return initialValue ? (
    <ValidationDataForm
      fetch={fetch}
      fetchCreate={fetchCreate}
      pageKey="StateForSurveyor"
      pageName="State For Surveyor"
      idKey="surveyorKey"
      formConfig={formConfig(configOptions)}
      getParamsForSubmit={getParamsForSubmit}
      getParamsForInitialValues={getParamsForInitialValues}
      notValidationData="Surveyors"
      isCancelButton
    />
  ) : (
    <PageContent>
      <Spin />
    </PageContent>
  );
};

export default AddStateForSurveyor;
