import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';

import {
  openNotificationWithIcon,
  validateMessages,
  getMomentDate
} from '../../../../utils/general';
import FormItem from '../../../../shared/components/formItem';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchUpdate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_SITE_BLOCKING_STATUS, {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_SITE_BLOCKING_STATUS, {
    method: 'get',
  });

const BlockSiteForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { data, loading } = useAPI(() => fetch(), {
    initialState: { loading: false },
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [initialValues, setInitialValues] = useState(null);
  const [disabledFields, setDisabledFields] = useState([]);
  useEffect(() => {
    if (data) {
      setInitialValues({
        blocked: data.blocked,
        startDate:
          data.startDate ? getMomentDate(data.startDate) : '',
        endDate: data.endDate ? getMomentDate(data.endDate) : '',
      });
    }
    if (!data?.blocked) {
      setDisabledFields(['startDate', 'endDate']);
    }
  }, [data]);

  const onFinish = (values) => {
    const { blocked, startDate, endDate } = values;
    if (blocked && !startDate) {
      form.setFields([
        {
          name: 'startDate',
          errors: ['Start date is required'],
        },
      ]);
    }
    if (blocked && !endDate) {
      form.setFields([
        {
          name: 'endDate',
          errors: ['End date is required'],
        },
      ]);
    }
    if ((blocked && endDate && startDate) || !blocked) {
      refetchUpdate(values);
    }
  };

  const changeFields = (e) => {
    const fields = ['blocked', 'startDate', 'endDate'];
    form.setFields(
      fields.map((name) => ({
        name,
        errors: null,
      }))
    );
    const fieldName = e[0]?.name[0];
    const fieldValue = e[0]?.value;
    if (fieldName === 'blocked' && !fieldValue) {
      form.setFields([
        {
          name: 'startDate',
          value: null,
        },
        {
          name: 'endDate',
          value: null,
        },
      ]);
      setDisabledFields(['startDate', 'endDate']);
    }
    if (fieldName === 'blocked' && fieldValue) {
      setDisabledFields([]);
    }
  };

  useEffect(() => {
    if (updateData) {
      let type = '';
      let message = '';
      type = 'success';
      message = 'Data was updated successfully';
      openNotificationWithIcon(type, message);
    }
  }, [updateData]);

  return (
    <>
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues && !loading ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="createFieldDataManualEnter"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={['onBlur', 'onChange']}
              onFieldsChange={changeFields}
            >
              {formConfig.map(({ name, input, label, ...rest }) => (
                <FormItem
                  disabled={disabledFields.includes(name)}
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateLoading}
                >
                  {t('form:update')}
                </Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default BlockSiteForm;
