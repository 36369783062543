import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import classNames from 'clsx';

import { roleTypes } from '../../../../utils/roles';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { openNotificationWithIcon } from '../../../../utils/general';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAuth } from '../../../../global/stores/auth';
import { useStore } from '../../../../global/stores/store/StoreContext';

import { columns } from './TableColumns';
import PageWithTable from '../../../pages/PageWithTable';

import classes from '../../../styles.module.scss';

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_STATE_DETAILS(key), {
        method: 'get',
      })
    : {};

const fetchParentData = (surveyorKey) =>
  surveyorKey
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_STATE_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'FirstName' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            {
              operatortype: 'Equals',
              propertyName: 'SurveyorKey',
              value: surveyorKey,
            },
          ],
        }),
      })
    : {};

const fetchDelete = (key, state) =>
  key && state
    ? APIFetch(ENDPOINT_URLS.DELETE_SOURCE_OWNER_SURVEYORS(key, state), {
        method: 'delete',
      })
    : null;

const AddOrDeleteState = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { surveyorKey: surveyorKeyFromUrl } = useParams();
  const key = 'surveyorKey';
  const state = 'state';

  const { data: parentData } = useAPI(
    () => fetchParentData(surveyorKeyFromUrl),
    {
      initialState: { loading: false },
    }
  );

  const { data: currentData, refetch: refetchCurrentData } = useAPI(
    () => fetch(surveyorKeyFromUrl),
    {
      initialState: { loading: false },
    }
  );

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI((id, stateForDelete) => fetchDelete(id, stateForDelete), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (deleteData) {
      if (deleteData[key]) {
        openNotificationWithIcon(
          'success',
          `State for Surveyor successfully deleted.`
        );

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', `Failed delete surveyor`);
      }
    }
  }, [deleteData]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  useEffect(() => {
    if (parentData?.items?.length) {
      const { lastName } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: lastName,
        },
      });
    }
  }, [parentData]);

  const isAdmin = user?.role === roleTypes.Administrator;

  const { statesData, updateStates } = useStore();

  useEffect(() => {
    if (!statesData.length) updateStates();
  }, []);

  const [configOptions, setConfigOptions] = useState();
  const [initialValue, setInitialValue] = useState();
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (currentData && statesData.length) {
      let options = statesData;

      currentData?.map((item) => {
        options = options.filter((i) => i.item !== item.state);

        return item;
      });

      setConfigOptions(options);
    }
  }, [statesData, currentData]);

  useEffect(() => {
    if (configOptions?.length) {
      setInitialValue(configOptions[0]);
    }
  }, [configOptions]);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 90,
      render: (_, record) => (
        <>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => {
              refetchDelete(record[key], record[state]);
              setIsDelete(true);
              refetchCurrentData();
            }}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              style={{
                marginRight: 8,
              }}
            >
              {t('table:delete')}
            </a>
          </Popconfirm>
        </>
      ),
    },
    ...staticColumns,
  ];

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  return (
    <PageWithTable
      fetchData={() => fetch(surveyorKeyFromUrl)}
      defaultSortField="State"
      titleKey="Surveyors:AddOrDeleteState"
      descriptionKey="Surveyors:AddOrDeleteStateDescription"
      columns={isAdmin ? allColumns : staticColumns}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading}
      scroll={{ x: 600 }}
      isDataArray
    >
      {isAdmin && (
        <div className={classNames(classes.content)}>
          <Button onClick={create} disabled={!initialValue && !isDelete}>
            Add State
          </Button>
        </div>
      )}
    </PageWithTable>
  );
};

export default AddOrDeleteState;
