import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';
import StatesSelect from '../../../../shared/components/select/StatesSelect';
import SourceOwnersSelect from '../../../../shared/components/select/SourceOwnersSelect';
import { Autocomplete } from '../../../../shared/components/autocomplete/Autocomplete';
import { Select } from '../../../../shared/components/select';
import { dataYesNoWithBooleanKeyNone } from '../../../../global/constants';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_OWNER_EO_ASSOSIATION, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (key, ownerKey, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_EL_OCC_OWNER(key, ownerKey), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetchParentChildren = (key, currentOwner) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_EXISTING_OWNER_EO_FOR_ADD_OR_DELETE(key, currentOwner), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'Eokey',
            },
          ],
        }),
      })
    : [];

const fetchEOs = (state) =>
  APIFetch(ENDPOINT_URLS.GET_EOS, {
    method: 'post',
    body: JSON.stringify([
      {
        operatortype: 'Equals',
        value: state || 'CT',
        propertyName: 'State',
      },
    ]),
  });

const OwnerEOForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { eokey, key } = useParams();
  const history = useHistory();
  const { search: searchParams } = useLocation();

  const query = new URLSearchParams(searchParams);
  const ownerName = query.getAll('ownerName')[0];
  const currentOwnerQuery = query.getAll('currentOwner')[0];

  const [initialValues, setInitialValues] = useState(null);

  const IS_UPDATE_MODE = !eokey.includes('Create');

  const { sourceOwnersData, updateSourceOwners, statesData, updateStates } =
    useStore();

  useEffect(() => {
    if (!sourceOwnersData.length) updateSourceOwners();
    if (!statesData.length) updateStates();
  }, []);

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI((args) => fetchCreate(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(eokey, key, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: parentChildrenData } = useAPI(() => fetchParentChildren(key, currentOwnerQuery), {
    initialState: { data: [], loading: false },
  });

  const {
    data: eosData,
    loading: eosLoading,
    refetch: refetchEOs,
  } = useAPI(fetchEOs, {
    initialState: { data: [], loading: false },
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: `${IS_UPDATE_MODE ? 'Edit' : 'Add'} Owner EO`,
      },
      search: searchParams,
    });
  }, []);

  useEffect(() => {
    if (parentChildrenData?.length && IS_UPDATE_MODE) {
      const { elementOccurrence, currentOwner, ...other } = parentChildrenData.find(
        (item) => item.eokey === +eokey
      );

      setInitialValues({
        ...other,
        eo: elementOccurrence,
        currentOwner: currentOwner ? 'Yes' : 'No',
      });
    }
  }, [parentChildrenData]);

  useEffect(() => {
    if (
      sourceOwnersData.length &&
      statesData.length &&
      (parentChildrenData?.length || ownerName) &&
      !IS_UPDATE_MODE
    ) {
      const owner = parentChildrenData.find(
        (item) => item.ownerKey === +key
      )?.owner;

      setInitialValues({
        state: statesData[0].itemKey,
        owner: owner || ownerName,
        sourceOwner: sourceOwnersData[0].itemKey,
        currentOwner: dataYesNoWithBooleanKeyNone[0].itemKey,
      });
    }
  }, [sourceOwnersData, parentChildrenData, statesData]);

  const onFinish = (values) => {
    const { assessorLot, sourceOwner, currentOwner } = values;
    const currentOwnerValue = ['true', 'Yes'].includes(currentOwner);

    if (IS_UPDATE_MODE) {
      refetchUpdate({
        assessorLot,
        sourceOwner,
        currentOwner: currentOwnerValue,
      });
    } else {
      const { eo, currentOwner: currentOwnerForCreate, ...others } = values;

      delete others.state;
      delete others.owner;
      const currentOwnerForCreateValue = currentOwner === 'true';
      refetchCreate({
        eokey: eosData.find(({ item }) => item === eo).itemKey,
        ownerKey: +key,
        currentOwner: currentOwnerForCreateValue,
        ...others,
      });
    }
  };

  const onClickCancel = () => {
    form.resetFields();
    form.setFieldsValue({
      assessorLot: '',
    });

    // const parentPathname = getParentPathname(pathname);
    // history.replace(parentPathname);
    history.goBack();
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData?.eokey) {
          type = 'success';
          message = 'OwnerEO was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update OwnerEO';
        }
      } else if (createData?.eokey) {
        type = 'success';
        message = 'OwnerEO was created successfully';
      } else {
        type = 'error';
        message = 'Failed create OwnerEO';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        // const parentPathname = getParentPathname(pathname);

        // history.replace(parentPathname);
        history.goBack();
      }
    }
  }, [createData, updateData]);

  const onValuesChange = (values) => {
    if (values.state) {
      form.setFieldsValue({
        eo: '',
      });

      refetchEOs(values.state);
    }
  };

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`Owner:OwnerEO${IS_UPDATE_MODE ? 'Edit' : 'Create'}Form`)}
      </Title>
      <p className={classNames(classes.title)}>
        {t(`Owner:OwnerEO${IS_UPDATE_MODE ? 'Edit' : 'Create'}FormDescription`)}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="createOwnerEO"
              onFinish={onFinish}
              initialValues={initialValues}
              onValuesChange={onValuesChange}
              validateMessages={validateMessages}
            >
              {!IS_UPDATE_MODE && (
                <FormItem
                  disabled
                  name="owner"
                  label="Owner"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
              )}
              {!IS_UPDATE_MODE && (
                <FormItem
                  name="state"
                  label="State"
                  input={(props) => <StatesSelect {...props} />}
                />
              )}
              <FormItem
                disabled={IS_UPDATE_MODE}
                name="eo"
                label="EO"
                input={(props) => (
                  <Autocomplete
                    props={props}
                    data={eosData}
                    loading={eosLoading}
                  />
                )}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />
              <FormItem
                name="assessorLot"
                label="Assesor Lot"
                rules={[
                  {
                    max: 1000,
                    message:
                      'Length of Assesor Lot should be less than 1000 symbols',
                  },
                ]}
              />
              <FormItem
                name="sourceOwner"
                label="Source Owner"
                input={(props) => <SourceOwnersSelect {...props} />}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />
              <FormItem
                name="currentOwner"
                label="Current Owner"
                input={(props) => <Select props={props} data={dataYesNoWithBooleanKeyNone} />}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default OwnerEOForm;
