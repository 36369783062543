import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SourcesSelect = (props) => {
  const { sourcesData, sourcesLoading, updateSources } = useStore();

  useEffect(() => {
    if (!sourcesData.length && !sourcesLoading) updateSources();
  }, []);

  return <Select props={props} data={sourcesData} loading={sourcesLoading} />;
};

export default SourcesSelect;
