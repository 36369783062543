import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
  getParentPathname,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_MGT_NOTE, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (ffkey, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_MGT_NOTE(ffkey), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_MGT_NOTES_EDIT_DETAILS(key), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'Year',
            },
          ],
        }),
      })
    : {};

const MgtNotesEditForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key, ffkey } = useParams();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const [initialValues, setInitialValues] = useState(null);

  const IS_UPDATE_MODE = ffkey !== 'Create';

  const {
    mgtNotesAndEODataSourcesData,
    updateMgtNotesAndEODataSources,
    allMgtFieldDataTypesData,
    updateAllMgtFieldDataTypes,
  } = useStore();

  useEffect(() => {
    if (!mgtNotesAndEODataSourcesData.length) updateMgtNotesAndEODataSources();
    if (!allMgtFieldDataTypesData.length) updateAllMgtFieldDataTypes();
  }, []);

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI((args) => fetchCreate(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(ffkey, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: parentChildrenData } = useAPI(() => fetch(key), {
    initialState: { loading: false },
    shouldFetch: IS_UPDATE_MODE,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: `${
          IS_UPDATE_MODE ? 'Edit' : 'Add New'
        } Note to Element Occurrence`,
      },
    });
  }, []);

  useEffect(() => {
    if (parentChildrenData?.mgtNoteData.length) {
      const { fieldDataType, populationComment, source, year } =
        parentChildrenData.mgtNoteData.find((item) => item.ffkey === +ffkey);

      setInitialValues({
        fieldDataType,
        populationComment,
        source,
        year,
      });
    }
  }, [parentChildrenData]);

  useEffect(() => {
    if (
      mgtNotesAndEODataSourcesData.length &&
      allMgtFieldDataTypesData.length &&
      !IS_UPDATE_MODE
    ) {
      setInitialValues({
        fieldDataType: allMgtFieldDataTypesData[0].item,
        source: mgtNotesAndEODataSourcesData[0].itemKey,
      });
    }
  }, [mgtNotesAndEODataSourcesData, allMgtFieldDataTypesData]);

  const onFinish = (values) => {
    if (IS_UPDATE_MODE) {
      const { fieldDataType, populationComment, year } = values;

      refetchUpdate({ fieldDataType, populationComment, year });
    } else {
      refetchCreate({
        eokey: +key,
        ...values,
      });
    }
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData?.eokey) {
          type = 'success';
          message = 'Note to Element Occurrence was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update Note to Element Occurrence';
        }
      } else if (createData?.eokey) {
        type = 'success';
        message = 'Note to Element Occurrence was created successfully';
      } else {
        type = 'error';
        message = 'Failed create Note to Element Occurrence';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        const parentPathname = getParentPathname(pathname);
        history.replace(parentPathname);
      }
    }
  }, [createData, updateData]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`StatusOfPlants:MgtNotes${IS_UPDATE_MODE ? 'Edit' : 'Create'}Form`)}
      </Title>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="mgtNotesForm"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  disabled={IS_UPDATE_MODE && name === 'source'}
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default MgtNotesEditForm;
