import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchSpeciesWithNoEOs = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_WITH_NO_EOS, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const SpeciesWithNoEOs = () => (
  <PageWithTable
    fetchData={(args) => fetchSpeciesWithNoEOs(args)}
    defaultSortField="SpeciesName"
    titleKey="Species:SpeciesWithNoEOs"
    descriptionKey="Species:SpeciesWithNoEOsDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1100 }}
  />
);

export default SpeciesWithNoEOs;
