import React from 'react';

import { Select } from '../../../shared/components/select/Select';
import { LIST_OF_NUMBERS } from '../../../global/constants';

export const searchFormConfig = [
  {
    name: 'nbrEos',
    label: 'Number of seed collections',
    input: (props) => (
      <Select props={props} data={LIST_OF_NUMBERS} width={60} />
    ),
    operatortype: 'Equals',
  },
];
