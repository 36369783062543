import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = [
  'speciesName',
  'lookalikeScientificName',
  'lookalikeCharacteristic',
];

const LookalikeSpecies = ({ data }) => (
  <ContainerWithSimpleTable
    titleKey="PCVAdministration:LookalikeSpecies"
    descriptionKey="PCVAdministration:LookalikeSpeciesDescription"
    data={data}
    loading={false}
    fields={fields}
    rowKey={uuidv4()}
    fullWidth
  />
);

export default LookalikeSpecies;
