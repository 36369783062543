import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../config';
import Section from '../Section';

const SurveyStatusPage = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Section
        config={sectionsConfig().surveyStatus}
        title={t('SurveyStatus:title')}
        pageDescription={t('SurveyStatus:description')}
      />
    </PageContent>
  );
};

export default SurveyStatusPage;
