import React from 'react';
import { Input, DatePicker, Checkbox } from 'antd';

import OwnerTypesSelect from '../../../../shared/components/select/OwnerTypesSelect';
import OwnerStatusesSelect from '../../../../shared/components/select/OwnerStatusesSelect';
import ReportMethodsSelect from '../../../../shared/components/select/ReportMethodsSelect';
import { getMomentDate, getCurrentDate } from '../../../../utils/general'

const { TextArea } = Input;

const today = getCurrentDate();

const disabledDate = (current) => {
  const tooLate = +current.format('YYYYMMDD') > +today;

  return tooLate;
};

export const formConfig = [
  {
    name: 'ownerType',
    label: 'Owner Type',
    input: (props) => <OwnerTypesSelect {...props} width="100%" />,
    rules: [
      {
        required: true,
        max: 20,
      },
    ],
  },
  {
    name: 'organization',
    label: 'Organization',
    rules: [
      {
        max: 100,
      },
    ],
  },
  {
    name: 'subOrganization',
    label: 'Suborganization',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'firstName',
    label: 'First Name',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'lastName',
    label: 'Last Name',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'address1',
    label: 'Address 1',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'address2',
    label: 'Address 2',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'city',
    label: 'City',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'state',
    label: 'State',
    rules: [
      {
        max: 2,
      },
    ],
  },

  {
    name: 'postalCode',
    label: 'Postal Code',
    rules: [
      {
        max: 20,
      },
    ],
  },
  {
    name: 'phone',
    label: 'Phone',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'email',
    label: 'Email',
    rules: [
      {
        type: 'email',
        max: 80,
      },
    ],
  },
  {
    name: 'nptnotes',
    label: 'NPT Notes',
    input: (props) => <TextArea {...props} />,
  },
  {
    name: 'reportMethod',
    label: 'Report Method',
    input: (props) => <ReportMethodsSelect {...props} width="100%" />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'ownerStatus',
    label: 'Owner Status',
    input: (props) => <OwnerStatusesSelect {...props} width="100%" />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'pastContactYear',
    label: 'Past Contact Year',
  },
  {
    name: 'pastContactMethod',
    label: 'Past Contact Method',
  },
  {
    name: 'dateReportSent',
    label: 'Past Date Report Sent',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  {
    name: 'deleted',
    label: 'Mark as delete',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
  },
];
