import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import moment from 'moment';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAuth } from '../../../../global/stores/auth';
import {
  openNotificationWithIcon,
  validateMessages,
  getParentPathname,
  getFormData,
  validateUser,
  validateSeedPlants,
  handleUnits,
  validateRametsGenets,
  createPdf,
  validateNoThreat,
  getMomentDate,
  standardizePdf,
  getCurrentDateSeparatedByHyphens,
  getPdfNameByEOKey,
  splitEoKeyIntoComponents,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
// eslint-disable-next-line import/no-cycle
import PageContent from '../../../../shared/components/PageContent';
import { DATA_YES_NO_NONE } from '../../../FieldForm/constants';
import Files from '../../../FieldForm/Files';
import Uploader from '../../../../shared/components/Upload';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_FORM_REVIEW_DETAILS(key), {
        method: 'get',
      })
    : null;

const fetchUpdate = (key, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_FIELD_FORM_REVIEW(key), {
        method: 'put',
        initialHeaders: {},
        body: args,
      })
    : null;

const fetchTopoFields = (eokey) =>
  eokey
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCCURACE_TOPO_FIELDS(eokey), {
        method: 'get',
      })
    : {};

const FieldFormReviewForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key } = useParams();
  const history = useHistory();

  const { pathname, search: searchKey } = useLocation();
  const query = new URLSearchParams(searchKey);
  const eoFromUrl = query.getAll('eo')[0];
  const eokeyFromUrl = query.getAll('eokey')[0];
  const townFromUrl = query.getAll('town')[0];
  const siteNameFromUrl = query.getAll('siteName')[0];

  const [initialValues, setInitialValues] = useState(null);
  const [disabledFields, setDisabledFields] = useState([
    'popAreaUnits',
    'elevUnits',
    'firstName',
    'lastName',
    'signatureDate',
  ]);

  const { user } = useAuth();
  const { surveyorsData, updateSurveyors } = useStore();

  useEffect(() => {
    if (!surveyorsData.length) updateSurveyors();
  }, []);

  const { data, loading } = useAPI(() => fetch(key), {
    initialState: { loading: false },
  });

  const { data: topoFields } = useAPI(() => fetchTopoFields(eokeyFromUrl), {
    initialValues: { data: {} },
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(key, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: eoFromUrl,
      },
      search: searchKey,
    });
  }, []);

  useEffect(() => {
    if (surveyorsData.length && data) {
      const {
        leadSurveyor,
        surveyor2,
        surveyor3,
        surveyor4,
        populationArea,
        popAreaUnits,
        elevationMin,
        elevationMax,
        elevUnits,
        eokey,
        ...other
      } = data;

      if (populationArea || elevationMin || elevationMax) {
        let updatedDisabledFields = [...disabledFields];

        if (populationArea) {
          updatedDisabledFields = updatedDisabledFields.filter(
            (name) => name !== 'popAreaUnits'
          );
        }

        if (elevationMin || elevationMax) {
          updatedDisabledFields = updatedDisabledFields.filter(
            (name) => name !== 'elevUnits'
          );
        }

        setDisabledFields(updatedDisabledFields);
      }

      const correctPopAreaUnits = (popAreaUnits || '')
        .toLowerCase()
        .includes('ft')
        ? 'Ft2'
        : 'M2';
      const correctElevUnits = (elevUnits || '').toLowerCase().includes('ft')
        ? 'Ft'
        : 'M';

      setInitialValues({
        ...other,
        populationArea,
        popAreaUnits: popAreaUnits ? correctPopAreaUnits : popAreaUnits,
        elevationMin,
        elevationMax,
        elevUnits: elevUnits ? correctElevUnits : elevUnits,
        leadSurveyor: surveyorsData.find(
          ({ itemKey }) => itemKey === leadSurveyor
        ).item,
        surveyor2: surveyorsData.find(({ itemKey }) => itemKey === surveyor2)
          .item,
        surveyor3: surveyorsData.find(({ itemKey }) => itemKey === surveyor3)
          .item,
        surveyor4: surveyorsData.find(({ itemKey }) => itemKey === surveyor4)
          .item,
        eokey: eoFromUrl || eokey,
        surveyDate: data.surveyDate ? getMomentDate(data.surveyDate) : '',
        seedDateSent: data.seedDateSent ? getMomentDate(data.seedDateSent) : '',
        signatureDate: data.signatureDate
          ? getMomentDate(data.signatureDate)
          : '',
      });
    }
  }, [data, surveyorsData]);

  const clearErrorMessages = (values) => {
    form.setFields(
      Object.keys(values).map((name) => ({
        name,
        errors: null,
      }))
    );
  };

  const state = eoFromUrl[0] + eoFromUrl[1];

  const onFinish = (values) => {
    const {
      leadSurveyor,
      surveyor2 = '',
      surveyor3 = '',
      surveyor4 = '',
      popAreaUnits,
      genets,
      ramets,
      surveyDate,
      seedDateSent,
      signatureDate,
      files,
      ...others
    } = values;

    const rametsGenetsValidation = validateRametsGenets(ramets, genets, t);
    if (rametsGenetsValidation) {
      form.setFields(rametsGenetsValidation);
      form.scrollToField('ramets');

      return;
    }

    clearErrorMessages(values);

    const { eokey } = data;

    const props = {
      ...others,
      leadSurveyor: surveyorsData.find(({ item }) => item === leadSurveyor)
        .itemKey,
      surveyor2: surveyorsData.find(({ item }) => item === surveyor2).itemKey,
      surveyor3: surveyorsData.find(({ item }) => item === surveyor3).itemKey,
      surveyor4: surveyorsData.find(({ item }) => item === surveyor4).itemKey,
      popAreaUnits: popAreaUnits || null,
      genets,
      ramets,
      surveyDate: surveyDate ? moment(surveyDate).format('YYYY-MM-DD') : null,
      seedDateSent: seedDateSent
        ? moment(seedDateSent).format('YYYY-MM-DD')
        : null,
      signatureDate: signatureDate
        ? moment(signatureDate).format('YYYY-MM-DD')
        : null,
      eokey,
      files: files
        ? files.fileList.map((file) => file?.originFileObj || file)
        : null,
    };

    const pdfDataFields = Object.keys(props).map((propKey) => {
      let value = props[propKey];

      if (
        [
          'leadSurveyor',
          'surveyor2',
          'surveyor3',
          'surveyor4',
          'eokey',
        ].includes(propKey)
      ) {
        value = values[propKey] || null;
      }

      if (propKey === 'files') {
        value = props[propKey]
          ? props[propKey].map(({ name }) => name).join(';\n')
          : null;

        if (data?.fieldDataParseds) {
          const { fieldDataParseds } = data;

          const allFiles = fieldDataParseds.map(
            ({ fullFilename }) => fullFilename
          );

          value = (value ? [...allFiles, value] : allFiles).join(';\n');
        }
      }

      return {
        name: t(`form:${propKey}`),
        value,
      };
    });

    const fileTitle = `Field Form: ${values.eokey}`;

    const {
      state: stateFromEoKey,
      speciesName,
      eoNumber,
      subpopulation,
    } = splitEoKeyIntoComponents(values.eokey);

    const sortedAndRenamedPdfDataFields = standardizePdf(
      [
        ...pdfDataFields,
        { name: t('form:state'), value: stateFromEoKey },
        { name: t('form:speciesKey'), value: speciesName },
        { name: t('form:eoNumber'), value: eoNumber },
        { name: t('form:subpopulation'), value: subpopulation },
        { name: t('form:town'), value: townFromUrl },
        { name: t('form:siteName'), value: siteNameFromUrl },

        { name: t('form:topoNames'), value: topoFields?.topO_NAMES },
        { name: t('form:topoNums'), value: topoFields?.topO_NUMS },
      ],
      state
    );

    const fieldFormPdf = createPdf(
      sortedAndRenamedPdfDataFields,
      fileTitle,
      `${getPdfNameByEOKey(
        values.eokey
      )}-FF-review(${getCurrentDateSeparatedByHyphens()}).pdf`
    );

    const formData = getFormData({
      ...props,
      fieldFormPdf,
    });

    refetchUpdate(formData);
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData) {
      let type = '';
      let message = '';

      const { ffkey } = updateData;

      if (ffkey) {
        type = 'success';
        message = 'Data was updated successfully';
      } else {
        type = 'error';
        message = 'Failed update Data';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        const parentPathname = getParentPathname(pathname);
        history.replace(parentPathname);
      }
    }
  }, [updateData]);

  const changeFields = (e) => {
    const fieldName = e[0].name[0];
    const fieldValue = e[0].value;

    const ramets = form.getFieldValue('ramets');
    const genets = form.getFieldValue('genets');
    const seedPlantsSampled = form.getFieldValue('seedPlantsSampled');
    const seedPlantsInFruit = form.getFieldValue('seedPlantsInFruit');
    const elevationMin = form.getFieldValue('elevationMin');
    const elevationMax = form.getFieldValue('elevationMax');

    const seedPlantsValidation = validateSeedPlants(fieldName, fieldValue, {
      ramets,
      seedPlantsSampled,
      seedPlantsInFruit,
      genets,
    });
    if (seedPlantsValidation) {
      form.setFields(seedPlantsValidation);
    }

    const userValidation = validateUser(fieldName, fieldValue, user);
    if (userValidation) {
      form.setFields(userValidation);
    }

    const { newDisabledFields, fields: unitsFieldsValue } = handleUnits(
      fieldName,
      fieldValue,
      disabledFields,
      { elevationMin, elevationMax }
    );
    if (newDisabledFields) {
      setDisabledFields(newDisabledFields);
    }
    if (unitsFieldsValue) {
      form.setFieldsValue(unitsFieldsValue);
    }

    const fields = [
      'alteredHydrology',
      'directHumanThreat',
      'erosion',
      'herbivory',
      'fireSuppression',
      'invasiveSpecies',
      'succession',
      'threatScope',
      'threatSeverity',
    ];

    const noThreatValidation = validateNoThreat(fieldName, fieldValue, fields);
    if (noThreatValidation) form.setFields(noThreatValidation);

    if (fieldName === 'noThreat' && fieldValue === 'Yes') {
      form.setFields(
        fields.map((name) => ({ name, value: DATA_YES_NO_NONE[2].itemKey }))
      );

      const resultArray = disabledFields.filter((el) => !fields.includes(el));
      setDisabledFields([...fields, ...resultArray]);
    }

    if (fieldName === 'noThreat' && fieldValue !== 'Yes') {
      setDisabledFields([]);

      if (fieldValue === 'NONE') {
        form.setFields(
          fields.map((name) => ({ name, value: DATA_YES_NO_NONE[0].itemKey }))
        );
      }
    }
  };

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdmin:EditFieldFormReviewTitle')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVAdmin:EditFieldFormReviewDescription')}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues && !loading ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="createFieldFormReviewForm"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={['onBlur', 'onChange']}
              onFieldsChange={changeFields}
            >
              {formConfig.map(({ name, input, infoKey, text, ...rest }) => (
                <>
                  {text && (
                    <>
                      <p className={classNames(classes.infoMessage)}>
                        {t(`info:${text}`)}
                      </p>
                      <br />
                    </>
                  )}
                  <FormItem
                    disabled={disabledFields.includes(name)}
                    key={name}
                    name={name}
                    label={t(`form:${name}`)}
                    input={input}
                    {...rest}
                  />
                  {infoKey && state === 'MA' && (
                    <>
                      <p className={classNames(classes.infoMessage)}>
                        {t(`info:${infoKey}`)}
                      </p>
                      <br />
                    </>
                  )}
                </>
              ))}

              <FormItem
                key="files"
                name="files"
                label={t('form:files')}
                input={(props) => (
                  <Uploader
                    {...props}
                    maxCount={
                      data?.fieldDataParseds.length
                        ? 25 - data.fieldDataParseds.length
                        : 24
                    }
                  />
                )}
              />

              <Files files={data?.fieldDataParseds} />
              <p className={classNames(classes.infoMessage)}>
                {t('form:additionalToUpload')}
              </p>
              <br />
              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updateLoading}
                >
                  {t('form:update')}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default FieldFormReviewForm;
