import React, { useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'clsx';
import { Button, Col, Form, Input, Row } from 'antd';

// eslint-disable-next-line import/no-cycle
import { AUTH_ROUTE } from '../../../AppRoutes';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../utils/general';

import classes from '../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.FORGOT_PASSWORD, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();

  const { data, loading, refetch } = useAPI((args) => fetch(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const onClickCancel = () => {
    history.push(AUTH_ROUTE);
  };

  const onFinish = (values) => {
    refetch(values);
  };

  useEffect(() => {
    if (data && !loading) {
      if (data.ok) {
        openNotificationWithIcon(
          'success',
          'Please check your email to reset password'
        );

        form.resetFields();
      } else {
        openNotificationWithIcon('error', 'Invalid email');
      }
    }
  }, [loading]);

  return (
    <div>
      <Title className={classNames(classes.title)} level={3}>
        {t(`forgotPassword:forgotPasswordTitle`)}
      </Title>
      <p className={classNames(classes.title)}>
        {t(`forgotPassword:forgotPasswordDescription`)}
      </p>
      <br />
      <Row justify="space-around" align="middle">
        <Col span={12}>
          <Form
            {...layout}
            form={form}
            name="forgotPassword"
            onFinish={onFinish}
            validateTrigger={['onBlur']}
            validateMessages={validateMessages}
          >
            <Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: t('login:errorEmailIsRequired'),
                },
                {
                  type: 'email',
                },
              ]}
            >
              <Input />
            </Item>

            <Item {...tailLayout} className={classNames(classes.formFooter)}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('form:sendEmail')}
              </Button>
              <Button onClick={onClickCancel}>{t('form:goBack')}</Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
