import React from 'react';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';

import PageWithTableByQueryKey from '../../../pages/PageWithTableByQueryKey';

import { columns } from './TableColumns';

const fetchManualFieldFormInvasiveSpeciesTable = (key) =>
  APIFetch(ENDPOINT_URLS.GET_FIELD_MANUAL_INVASIVE_SPECIES(key), {
    method: 'get',
  });

export const ManualFieldFormInvasiveSpeciesTable = () => (
  <PageWithTableByQueryKey
    fetchData={fetchManualFieldFormInvasiveSpeciesTable}
    queryKey="eokey"
    columns={columns()}
    rowKey={(record) => record.eokey}
  />
);
