import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';

import PageContent from '../../../shared/components/PageContent';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { sectionsConfig } from '../config';
import Section from '../Section';

import styles from '../Section/styles.module.scss';

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_ANNOUNCEMENTS_PCVTF, {
    method: 'get',
  });

const PCVPage = () => {
  const { t } = useTranslation();

  const { data, loading } = useAPI(fetch, {
    initialState: { data: [], loading: false },
  });

  return (
    <PageContent>
      <Title level={2}>PCV and TF Workspace</Title>
      <div className={styles.root} key="announcementTitle">
        <Card>
          <Title level={3}>Announcements</Title>
          {data.length || loading ? (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.tableHead}>Announcement Title</th>
                  <th className={styles.tableHead}>Announcement Text</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) =>
                    a.announcementTitle.localeCompare(b.announcementTitle)
                  )
                  .map(({ id, announcementTitle, announcementText }) => (
                    <tr key={id}>
                      <td className={styles.cell} style={{wordBreak: 'break-all'}}>{announcementTitle}</td>
                      <td
                        className={styles.secondSale}
                        dangerouslySetInnerHTML={{ __html: announcementText }}
                      />
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : null}
        </Card>
      </div>
      <Section
        config={sectionsConfig().PCV}
        title={t('sections:PCV:title')}
        onlyTable
      />
    </PageContent>
  );
};

export default PCVPage;
