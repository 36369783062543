import { openNotificationWithIcon } from '../../../utils/general';

import { FetchError, FetchUnauthError } from './FetchErrors';
import {
  isStatusFail,
  getContentTypeFromHeaders,
  isJsonContentType,
  isBlobContentType,
} from './FetchUtils';

export async function resolveDataByContentType(response) {
  const { headers } = response;
  const contentType = getContentTypeFromHeaders(headers);

  if (isJsonContentType(contentType)) {
    return response.json();
  }

  if (isBlobContentType(contentType)) {
    return response.blob();
  }

  return response;
}

export async function parseResponse(response) {
  const { status, statusText } = response;
  const data = await resolveDataByContentType(response);

  if (isStatusFail(status)) {
    openNotificationWithIcon('error', statusText, data.title);

    if (status === 401) {
      return new FetchUnauthError(status, statusText, data);
    }

    return new FetchError(status, statusText, data);
  }
  return data;
};

export async function parseResponseXMLH(response) {
  const { status, statusText, data } = response;

  if (isStatusFail(status)) {
    openNotificationWithIcon('error', statusText, data.title);

    if (status === 401) {
      return new FetchUnauthError(status, statusText, data);
    }

    return new FetchError(status, statusText, data);
  }
  return data;
};