import React, { useEffect, useState } from 'react';
import { Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import classNames from 'clsx';

import { roleTypes } from '../../../../utils/roles';
import { useAuth } from '../../../../global/stores/auth';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import PageWithTable from '../../../pages/PageWithTable';
import { openNotificationWithIcon } from '../../../../utils/general';

import classes from '../../../styles.module.scss';

const ValidationData = ({
  pageKey,
  fetchDelete,
  idKey,
  addBtnTxt,
  fetchExport,
  fetch,
  searchForm,
  defaultSortField,
  columns,
  pageName,
  notValidationData,
  scroll,
  isWithoutActionColumns,
  isWithoutDescription,
  beSearchConfig,
  paramsForBE,
  actionColumn,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useAuth();

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI((id) => fetchDelete(id), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (deleteData) {
      if (deleteData[idKey] || deleteData.ok) {
        openNotificationWithIcon(
          'success',
          `${pageName} was deleted successfully`
        );
        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', `Failed delete ${pageName}`);
      }
    }
  }, [deleteData]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const allColumns = actionColumn
    ? [actionColumn(refetchDelete), ...columns]
    : [
        {
          title: t('table:action'),
          key: 'operation',
          fixed: 'left',
          width: 150,
          render: (_, record) => (
            <>
              <Popconfirm
                placement="top"
                title="Delete this item?"
                onConfirm={() => refetchDelete(record[idKey])}
                okText="Yes"
                cancelText="No"
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <a
                  style={{
                    marginRight: 8,
                  }}
                >
                  {t('table:delete')}
                </a>
              </Popconfirm>
              <Link
                style={{
                  marginRight: 8,
                }}
                to={`${pathname}/${record[idKey]?.replace('%', 'percent')}`}
              >
                {t('table:edit')}
              </Link>
            </>
          ),
        },
        ...columns,
      ];

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  const isAdmin = user.role === roleTypes.Administrator;
  const condition = !isAdmin && !notValidationData;

  return (
    <PageWithTable
      fetchData={(args) => fetch(args)}
      fetchExport={fetchExport}
      defaultSortField={defaultSortField}
      titleKey={`${notValidationData || 'Validation'}:${pageKey}`}
      descriptionKey={
        isWithoutDescription
          ? null
          : `${notValidationData || 'Validation'}:${pageKey}Description`
      }
      searchForm={searchForm}
      columns={isWithoutActionColumns || condition ? columns : allColumns}
      rowKey={(record) => record[idKey]}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading}
      scroll={scroll}
      beSearchConfig={beSearchConfig || null}
      paramsForBE={paramsForBE || null}
    >
      {condition || (
        <div className={classNames(classes.content)}>
          <Button onClick={create}>{addBtnTxt}</Button>
        </div>
      )}
    </PageWithTable>
  );
};

export default ValidationData;
