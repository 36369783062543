import React, { useEffect, useState } from 'react';
import { Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAuth } from '../../../../global/stores/auth';
import PageWithTable from '../../../pages/PageWithTable';
import { openNotificationWithIcon } from '../../../../utils/general';
import { roleTypes } from '../../../../utils/roles';

import { columns } from './TableColumns';

import classes from '../../../styles.module.scss';

const fetch = (key, currentOwner) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_EXISTING_OWNER_EO_FOR_ADD_OR_DELETE(key,currentOwner), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'Eokey',
            },
          ],
        }),
      })
    : [];

const fetchDelete = (eokey, ownerKey) =>
  eokey && ownerKey
    ? APIFetch(ENDPOINT_URLS.DELETE_EL_OCC_OWNER(eokey, ownerKey), {
        method: 'delete',
      })
    : null;

const OwnerEODetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { key } = useParams();
  const { user } = useAuth();
  const role = user?.role;
  const { pathname, search: searchParams } = useLocation();
  const query = new URLSearchParams(searchParams);
  const nameFromUrl = query.getAll('name')[0];
  const currentOwner = query.getAll('currentOwner')[0];

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI((eokey) => fetchDelete(eokey, key), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: nameFromUrl,
      },
      search: searchParams,
    });
  }, []);

  useEffect(() => {
    if (deleteData) {
      if (deleteData?.ownerKey) {
        openNotificationWithIcon(
          'success',
          'Owner EO was deleted successfully'
        );

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', 'Failed delete Owner EO');
      }
    }
  }, [deleteData]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 150,
      render: (_, record) => (
        <>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => refetchDelete(record.eokey)}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
              style={{
                marginRight: 8,
              }}
            >
              {t('table:delete')}
            </a>
          </Popconfirm>
          <Link
            style={{
              marginRight: 8,
            }}
            to={`${pathname}/${record.eokey}?currentOwner=${currentOwner}`}
          >
            {t('table:edit')}
          </Link>
        </>
      ),
    },
    ...staticColumns,
  ];

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
      search: nameFromUrl ? `?ownerName=${nameFromUrl}&currentOwner=${currentOwner}` : '',
    });
  };

  return (
    <PageWithTable
      fetchData={() => fetch(key, currentOwner)}
      defaultSortField={['State', 'SpeciesName', 'EonumberInt']}
      titleKey="Owner:OwnerEO"
      columns={role === roleTypes.Administrator ? allColumns : staticColumns}
      rowKey={(record) => record.id}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading}
      isDataArray
    >
      {role === roleTypes.Administrator && (
        <div className={classNames(classes.content)}>
          <Button onClick={create}>Add New EO for Owner</Button>
        </div>
      )}
    </PageWithTable>
  );
};

export default OwnerEODetails;
