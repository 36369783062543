import React from 'react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';

const fetchContent = (key) =>
  APIFetch(ENDPOINT_URLS.GET_NPT_NOTES(key), {
    method: 'get',
  });

export const actionContent = (record) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { eokey } = record;

  const { data } = useAPI(() => fetchContent(record.eokey), {
    initialState: { data: {}, loading: false },
  });

  const resultContent = data?.nptNotes?.filter((e) => e);
  const popover = (
    <Popover
      content={resultContent?.length ? resultContent : 'No data was returned'}
      trigger="click"
      overlayInnerStyle={{ maxWidth: 500, marginLeft: 80 }}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        style={{
          marginRight: 8,
        }}
      >
        {t('table:nptNotes')}
      </a>
    </Popover>
  );

  return (
    <span>
      {popover}
      <Link to={`${pathname}/${eokey}`}>{t('table:select')}</Link>
    </span>
  );
};
