import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general'

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      sorter: true,
      width: 60,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      width: 130,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:fullFilename'),
      dataIndex: 'fullFilename',
      key: 'fullFilename',
      sorter: true,
    },
    {
      title: t('table:minRamet'),
      dataIndex: 'minRamet',
      key: 'minRamet',
      sorter: true,
    },
    {
      title: t('table:maxRamet'),
      dataIndex: 'maxRamet',
      key: 'maxRamet',
      sorter: true,
    },
    {
      title: t('table:minGenet'),
      dataIndex: 'minGenet',
      key: 'minGenet',
      sorter: true,
    },
    {
      title: t('table:maxGenet'),
      dataIndex: 'maxGenet',
      key: 'maxGenet',
      sorter: true,
    },
    {
      title: t('table:populationArea'),
      dataIndex: 'populationArea',
      key: 'populationArea',
      sorter: true,
    },
    {
      title: t('table:elevationMin'),
      dataIndex: 'elevationMin',
      key: 'elevationMin',
      sorter: true,
    },
    {
      title: t('table:elevationMax'),
      dataIndex: 'elevationMax',
      key: 'elevationMax',
      sorter: true,
    },
    {
      title: t('table:threats'),
      dataIndex: 'threats',
      key: 'threats',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:mgtNeeds'),
      dataIndex: 'mgtNeeds',
      key: 'mgtNeeds',
      sorter: true,
      width: 80,
    },
  ];
};
