import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const NonStayOffOwnerPermissionsSelect = (props) => {
  const {
    nonStayOffOwnerPermissionsData,
    nonStayOffOwnerPermissionsLoading,
    updateNonStayOffOwnerPermissions,
  } = useStore();

  useEffect(() => {
    if (
      !nonStayOffOwnerPermissionsData.length &&
      !nonStayOffOwnerPermissionsLoading
    )
      updateNonStayOffOwnerPermissions();
  }, []);

  return (
    <Select
      props={props}
      data={nonStayOffOwnerPermissionsData}
      loading={nonStayOffOwnerPermissionsLoading}
    />
  );
};

export default NonStayOffOwnerPermissionsSelect;
