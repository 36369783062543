import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Popover } from 'antd';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';

const fetchContent = (key) =>
  APIFetch(ENDPOINT_URLS.GET_SURVEY_RESULTS_HISTORY_PCVTF(key), {
    method: 'get',
  });

export const actionContent = (record) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { eokey, state, speciesName, eonumberInt, subpopulation } = record;

  const { data } = useAPI(() => fetchContent(eokey), {
    initialState: { data: {}, loading: false },
  });

  return (
    <span>
      <Link
        to={`${pathname}/SproutFieldForm?eokey=${eokey}&name=${state} ${speciesName} ${eonumberInt} ${subpopulation}`}
        style={{
          marginRight: 8,
        }}
      >
        {t('table:select')}
      </Link>
      <Popover
        content={data?.history || 'No data was returned'}
        trigger="click"
        overlayInnerStyle={{ maxWidth: 500, marginLeft: 80 }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a>{t('table:history')}</a>
      </Popover>
    </span>
  );
};
