export const searchFormConfig = [
  {
    name: 'ScientificName',
    label: 'Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
];
