import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
    },
    {
      title: t('table:collections'),
      dataIndex: 'collections',
      key: 'collections',
    },
    {
      title: t('table:speciesCount'),
      dataIndex: 'speciesCount',
      key: 'speciesCount',
    },
    {
      title: t('table:eocount'),
      dataIndex: 'eocount',
      key: 'eocount',
    },
  ];
};
