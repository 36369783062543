import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:synonymName'),
      dataIndex: 'synonymName',
      key: 'synonymName',
      sorter: true,
    },
    {
      title: t('table:synonymScientificName'),
      dataIndex: 'synonymScientificName',
      key: 'synonymScientificName',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:scientificName'),
      dataIndex: 'scientificName',
      key: 'scientificName',
      sorter: true,
    },
  ];
};
