import React from 'react';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import ValidationData from '../../ValidationDataPages/ValidationData';

import { columns } from './TableColumns';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_REPORT_METHOD_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchDelete = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.DELETE_REPORT_METHOD(id), {
        method: 'delete',
      })
    : null;

const ValidationReportMethod = () => (
  <ValidationData
    pageKey="ReportMethod"
    fetchDelete={fetchDelete}
    idKey="reportMethod1"
    addBtnTxt="Add new type"
    fetch={fetch}
    defaultSortField="ReportMethod1"
    columns={columns()}
    pageName="Report Method"
  />
);

export default ValidationReportMethod;
