export const feSearchConfig = [
  {
    name: 'FirstName',
    label: 'Owner name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'FirstName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'LastName',
    equivalentPropertyName: 'FirstName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Organization',
    equivalentPropertyName: 'FirstName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SubOrganization',
    equivalentPropertyName: 'FirstName',
    previousSearchBlockConnectionOperator: 'Or',
  },
];
