import React, { useEffect, useState } from 'react';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithTable from '../../../pages/PageWithTable';
import { openNotificationWithIcon } from '../../../../utils/general';

import { columns } from './TableColumns';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_FORM_REVIEW_NO_EO_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_FORM_REVIEW_NO_EO_XLSX, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;    

const fetchDelete = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.DELETE_FIELD_FORM_REVIEW_NO_EO(key), {
        method: 'delete',
      })
    : null;

const FieldFormReviewNoEO = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI(fetchDelete, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (deleteData) {
      const { ffkey } = deleteData;

      if (ffkey) {
        openNotificationWithIcon('success', 'Data was deleted successfully');

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', 'Failed delete Data');
      }
    }
  }, [deleteData]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 150,
      render: (_, { ffkey }) => (
        <>
          <Link
            style={{
              marginRight: 8,
            }}
            to={`${pathname}/${ffkey}`}
          >
            {t('table:edit')}
          </Link>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => refetchDelete(ffkey)}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a>{t('table:delete')}</a>
          </Popconfirm>
        </>
      ),
    },
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={fetch}
      fetchExport={fetchExport}
      defaultSortField={['State', 'Town', 'SiteName']}
      titleKey="PCVAdmin:FieldFormReviewNoEOTitle"
      descriptionKey="PCVAdmin:FieldFormReviewNoEODescription"
      columns={allColumns}
      rowKey={(record) => record.ffkey}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading}
      scroll={{ x: 1300 }}
    />
  );
};

export default FieldFormReviewNoEO;
