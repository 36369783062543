import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithNestedTable from '../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchFieldDataSeedPaged = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_SEED_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchFieldDataSeed = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_SEED(key), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'Year',
            },
            {
              orderOption: 'Ascending',
              propertyName: 'SeedDatePackaged',
            },
          ],
        }),
      })
    : [];

export const FieldDataSeed = () => (
  <PageWithNestedTable
    fetchData={(args) => fetchFieldDataSeedPaged(args)}
    fetchNestedData={(key) => fetchFieldDataSeed(key)}
    defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
    nestedTableKey="eokey"
    titleKey="ElementOccurrence:FieldDataSeed"
    descriptionKey="ElementOccurrence:FieldDataSeedDescription"
    searchForm={SearchForm}
    columns={columns()}
    expandableColumns={expandableColumns()}
    rowKeyFunc={(record) => record.eokey}
    scroll={{ x: 1300 }}
  />
);
