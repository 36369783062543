import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:programName'),
      dataIndex: 'programName',
      key: 'programName',
    },
    {
      title: t('table:programDate'),
      dataIndex: 'programDate',
      key: 'programDate',
      render: (text) => getMomentDate(text).format('YYYY-MM-DD'),
    },
    {
      title: t('table:programDescription'),
      dataIndex: 'programDescription',
      key: 'programDescription',
      render(text) {
        const html = { __html: text };
        return <div dangerouslySetInnerHTML={html} />;
      },
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      render: (text) => text || 'All',
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:spotReserved'),
      dataIndex: 'spotReserved',
      key: 'spotReserved',
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
  ];
};
