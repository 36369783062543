import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import 'antd/dist/antd.css';

import { BREADCRUMBS_DETAILS_KEY } from './global/constants';

import { AppFooter } from './global/app-footer';
import { AppHeader } from './global/app-header';
import { Stores } from './global/stores/Stores';
import Store from './global/stores/Store';

import { AppRoutes } from './AppRoutes';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import './App.scss';
import PageNotFound from './views/errorsPages/PageNotFound';
import ScrollToTop from './scrollTo';
import { ENDPOINT_URLS } from './global/api';

const supportsHistory = 'pushState' in window.history;
const { Content } = Layout;

const defaultHeaders = new Headers({
  'Content-Type': 'application/json',
  'X-Frame-Options': 'DENY',
  'X-Content-Type-Options': 'nosniff',
  'X-XSS-Protection': '1; mode=block',
  'Content-Security-Policy': 'frame-ancestors none',
  'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
});

const checkSecurity = async () => {
  await fetch(ENDPOINT_URLS.CHECK_SECURITY, {
    headers: defaultHeaders,
    method: 'GET',
  });
}

const App = () => {
  useEffect(() => {
    localStorage.setItem(BREADCRUMBS_DETAILS_KEY, JSON.stringify({}));
    checkSecurity();
  }, []);

  return (
    <div className="App">
      <Router forceRefresh={!supportsHistory}>
        <ScrollToTop />
        <Stores>
          <Store>
            <Layout>
              <AppHeader />
              <Content>
                <Switch>
                  {AppRoutes.map((routeConfig) =>
                    routeConfig.requiresAuth ? (
                      <AuthenticatedRoute
                        key={routeConfig.path}
                        exact={routeConfig.exact}
                        path={routeConfig.path}
                        {...routeConfig}
                      />
                    ) : (
                      <Route
                        key={routeConfig.path}
                        exact={routeConfig.exact}
                        path={routeConfig.path}
                        component={routeConfig.component}
                      />
                    )
                  )}
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </Content>
              <AppFooter />
            </Layout>
          </Store>
        </Stores>
      </Router>
    </div>
  );
};

export default App;
