import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetchHerbariumRecovery = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_HERBARIUM_RECOVERY, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

export const HerbariumRecovery = () => (
  <PageWithTable
    fetchData={(args) => fetchHerbariumRecovery(args)}
    defaultSortField={['State', 'SpeciesName', 'Township', 'CollectionYear']}
    titleKey="ElementOccurrence:HerbariumRecovery"
    descriptionKey="ElementOccurrence:HerbariumRecoveryDescription"
    searchForm={SearchForm}
    columns={columns()}
    scroll={{ x: 1600 }}
  />
);
