import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const OwnerStatusesSelect = (props) => {
  const {
    allOwnerStatusesData,
    allOwnerStatusesLoading,
    updateAllOwnerStatuses,
  } = useStore();

  useEffect(() => {
    if (!allOwnerStatusesData.length && !allOwnerStatusesLoading)
      updateAllOwnerStatuses();
  }, []);

  return (
    <Select
      props={props}
      width={150}
      data={allOwnerStatusesData}
      loading={allOwnerStatusesLoading}
    />
  );
};

export default OwnerStatusesSelect;
