import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import { getMomentDate } from '../../../../utils/general'

import styles from '../../../sections/Section/styles.module.scss';

const fetchSPROUTFieldFormDetailTable = (args, key) =>
  APIFetch(ENDPOINT_URLS.GET_FIELD_SPROUT_DETAILS(key), {
    method: 'get',
  });

export const SPROUTFieldFormDetailTable = () => {
  const { t } = useTranslation();
  const { search: searchKey } = useLocation();

  const query = new URLSearchParams(searchKey);
  const keyFromUrl = query.getAll('ffkey');

  const { data } = useAPI(
    (args) => fetchSPROUTFieldFormDetailTable(args, keyFromUrl[0]),
    {
      initialState: { data: [], loading: false },
    }
  );

  delete data.ffkey;
  delete data.eokey;
  delete data.fieldDataType;
  delete data.source;
  data.surveyDate = data.surveyDate
    ? getMomentDate(data.surveyDate).format('YYYY-MM-DD')
    : data.surveyDate;

  const realResult = Object.keys(data).map((key) => (
    <tr key={key}>
      <td className={styles.cell}>{t(`table:${key}`)}</td>
      <td className={styles.secondSale}>{data[key]}</td>
    </tr>
  ));

  return realResult;
};
