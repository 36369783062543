import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const PermitTypesSelect = (props) => {
  const { permitTypesData, permitTypesLoading, updatePermitTypes } = useStore();

  useEffect(() => {
    if (!permitTypesData.length && !permitTypesLoading) updatePermitTypes();
  }, []);

  return (
    <Select props={props} data={permitTypesData} loading={permitTypesLoading} />
  );
};

export default PermitTypesSelect;
