import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import JoditEditor from 'jodit-react';

import { SURVEYOR_TYPE } from '../../../../global/constants';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  getParentPathname,
  openNotificationWithIcon,
  validateMessages,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchCreatePCVProgram = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_PCV_PROGRAM, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdatePCVProgram = (id, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_PCV_PROGRAM(id), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetchPCVPrograms = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.GET_PCV_PROGRAMS_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [{ orderOption: 'Ascending', propertyName: 'State' }],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'ID', value: id },
          ],
        }),
      })
    : {};

const PCVProgramsForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const editor = useRef(null);

  const [initialValues, setInitialValues] = useState(null);
  const [content, setContent] = useState('');

  const { statesData, updateStates } = useStore();

  useEffect(() => {
    if (!statesData.length) updateStates();
  }, []);

  const IS_UPDATE_MODE = id !== 'Create';

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI((args) => fetchCreatePCVProgram(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdatePCVProgram(id, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: parentData } = useAPI(() => fetchPCVPrograms(id), {
    initialState: { data: {}, loading: false },
    shouldFetch: IS_UPDATE_MODE,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: `${IS_UPDATE_MODE ? 'Edit' : 'Add'} Program`,
      },
    });
  }, []);

  useEffect(() => {
    if (parentData?.items) {
      const { state, ...other } = parentData.items[0];

      setInitialValues({
        ...other,
        state: state || '',
      });
    }
  }, [parentData]);

  useEffect(() => {
    if (statesData.length && !IS_UPDATE_MODE) {
      setInitialValues({
        surveyorType: SURVEYOR_TYPE[0].itemKey,
        state: '',
      });
    }
  }, [statesData]);

  const onFinish = (values) => {
    const { state } = values;
    if (IS_UPDATE_MODE) {
      refetchUpdate({ ...values, state: state || null });
    } else {
      refetchCreate({ ...values, state: state || null });
    }
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData?.id) {
          type = 'success';
          message = 'PCV Program was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update PCV Program';
        }
      } else if (createData?.id) {
        type = 'success';
        message = 'PCV Program was created successfully';
      } else {
        type = 'error';
        message = 'Failed create PCV Program';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        let parentPathname = pathname.split('/');
        parentPathname = parentPathname
          .slice(0, parentPathname.length - 1)
          .join('/');

        history.replace(parentPathname);
      }
    }
  }, [createData, updateData]);

  const buttons =
    'paragraph,|,bold,italic,underline,strikethrough,|,ul,ol,|,align,|,fontsize,brush,|,link,|,undo,redo';

  const config = {
    buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    statusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_clear_html',
    readonly: false,
    useSearch: false,
    spellcheck: false,
    enter: 'DIV',
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`PCVAdmin:PCVProgram${IS_UPDATE_MODE ? 'Edit' : 'Add'}`)}
      </Title>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name={`${IS_UPDATE_MODE ? 'edit' : 'add'}PCVProgram`}
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={['onBlur', 'onChange']}
            >
              {formConfig.map(({ name, label, input, ...rest }) =>
                name === 'programDescription' ? (
                  <Item
                    name="programDescription"
                    label="Program Description"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={config}
                      onBlur={(newContent) => setContent(newContent)}
                      onChange={() => {}}
                    />
                  </Item>
                ) : (
                  <FormItem
                    key={name}
                    name={name}
                    label={label}
                    input={input}
                    {...rest}
                  />
                )
              )}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default PCVProgramsForm;
