import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../../sections/config';
import Section from '../../sections/Section';

const OwnerStatusPage = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Section
        config={sectionsConfig().ownerStatus}
        title={t('OwnerStatus:title')}
        pageDescription={t('OwnerStatus:description')}
        onlyTable
      />
    </PageContent>
  );
};

export default OwnerStatusPage;
