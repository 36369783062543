import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Form, Button, Row, Col } from 'antd';
import { useLocation } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import FormItem from '../../../shared/components/formItem';
import { useAuth } from '../../../global/stores/auth';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../utils/general';

import { formConfig } from './form-config';

import classes from '../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const ResetPassword = () => {
  const { t } = useTranslation();

  const { search: searchKey } = useLocation();
  const query = new URLSearchParams(searchKey);
  const codeFromUrl = query.getAll('Code')[0].split(' ').join('+');
  const emailFromUrl = query.getAll('Email')[0].split(' ').join('+');

  const [form] = Form.useForm();

  const {
    login: { loginFn },
  } = useAuth();

  const onFinish = (values) => {
    const { password } = values;
    APIFetch(ENDPOINT_URLS.RESET_PASSWORD, {
      method: 'post',
      body: JSON.stringify({
        ...values,
        code: codeFromUrl,
        email: emailFromUrl,
      }),
    }).then(() => {
      openNotificationWithIcon('success', 'Password changed successfully');
      loginFn({ password, email: emailFromUrl });
    });
  };

  return (
    <>
      <Title className={classNames(classes.title)} level={3}>
        {t(`forgotPassword:createPasswordTitle`)}
      </Title>
      <p className={classNames(classes.title)}>
        {t(`forgotPassword:createPasswordDescription`)}
      </p>
      <br />
      <Row justify="space-around" align="middle">
        <Col span={12}>
          <Form
            {...layout}
            scrollToFirstError
            form={form}
            name="changePassword"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            {formConfig.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            ))}

            <Item {...tailLayout} className={classNames(classes.formFooter)}>
              <Button type="primary" htmlType="submit">
                {t('form:submit')}
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
