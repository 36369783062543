import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:invasiveSpecies'),
      dataIndex: 'invasiveSpecies',
      key: 'invasiveSpecies',
    },
    {
      title: t('table:item'),
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: t('table:genSpeUsed'),
      dataIndex: 'genSpeUsed',
      key: 'genSpeUsed',
    },
  ];
};
