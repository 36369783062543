import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 40,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
      width: 50,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
      sorter: true,
      width: 30,
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      sorter: true,
      width: 60,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
      sorter: true,
      width: 40,
    },
  ];
};
