import React from 'react';
import { Input, InputNumber } from 'antd';

import AllMgtFieldDataTypesSelect from '../../../../shared/components/select/AllMgtFieldDataTypesSelect';
import MgtNotesAndEODataSourcesSelect from '../../../../shared/components/select/MgtNotesAndEODataSourcesSelect';
import { dateValidationRules } from '../../../../utils/general';

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const { TextArea } = Input;

export const formConfig = [
  {
    name: 'year',
    label: 'Year',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [dateValidationRules('Year')],
  },
  {
    name: 'source',
    label: 'Source',
    input: (props) => <MgtNotesAndEODataSourcesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'fieldDataType',
    label: 'Field Data Type',
    input: (props) => <AllMgtFieldDataTypesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'populationComment',
    label: 'Population Comment',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
];
