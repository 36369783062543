import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:ctstatus'),
      dataIndex: 'ctstatus',
      key: 'ctstatus',
      sorter: true,
    },
    {
      title: t('table:mastatus'),
      dataIndex: 'mastatus',
      key: 'mastatus',
      sorter: true,
    },
    {
      title: t('table:mestatus'),
      dataIndex: 'mestatus',
      key: 'mestatus',
      sorter: true,
    },
    {
      title: t('table:nhstatus'),
      dataIndex: 'nhstatus',
      key: 'nhstatus',
      sorter: true,
    },
    {
      title: t('table:ristatus'),
      dataIndex: 'ristatus',
      key: 'ristatus',
      sorter: true,
    },
    {
      title: t('table:vtstatus'),
      dataIndex: 'vtstatus',
      key: 'vtstatus',
      sorter: true,
    },
    {
      title: t('table:ctrank'),
      dataIndex: 'ctrank',
      key: 'ctrank',
      sorter: true,
    },
    {
      title: t('table:marank'),
      dataIndex: 'marank',
      key: 'marank',
      sorter: true,
    },
    {
      title: t('table:merank'),
      dataIndex: 'merank',
      key: 'merank',
      sorter: true,
    },
    {
      title: t('table:nhrank'),
      dataIndex: 'nhrank',
      key: 'nhrank',
      sorter: true,
    },
    {
      title: t('table:rirank'),
      dataIndex: 'rirank',
      key: 'rirank',
      sorter: true,
    },
    {
      title: t('table:vtrank'),
      dataIndex: 'vtrank',
      key: 'vtrank',
      sorter: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      sorter: true,
    },
    {
      title: t('table:nptstatus'),
      dataIndex: 'nptstatus',
      key: 'nptstatus',
      sorter: true,
    },
    {
      title: t('table:comment'),
      dataIndex: 'comment',
      key: 'comment',
      sorter: true,
    },
  ];
};
