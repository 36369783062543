export const BREADCRUMBS_DETAILS_KEY = 'BREADCRUMBS_DETAILS';
export const FIRST_LOGIN_KEY = 'FIRST_LOGIN';
export const AUTH_DATA_KEY = 'AUTH_DATA';

export const SURVEYOR_TYPE = [
  {
    item: 'PCVTF',
    itemKey: 'PCVTF',
  },
  {
    item: 'PCV',
    itemKey: 'PCV',
  },
  {
    item: 'TF',
    itemKey: 'TF',
  },
];

export const POSITIVE_OPTION = 'Yes';
export const NEGATIVE_OPTION = 'No';

export const CLOSED_QUESTIONS = [
  {
    item: POSITIVE_OPTION,
    itemKey: POSITIVE_OPTION,
  },
  {
    item: NEGATIVE_OPTION,
    itemKey: NEGATIVE_OPTION,
  },
];

export const LIST_OF_NUMBERS = [
  { item: '5', itemKey: '5' },
  { item: '10', itemKey: '10' },
  { item: '15', itemKey: '15' },
];

export const dataYesNo = [
  { itemKey: 'NONE', item: 'NONE' },
  { itemKey: 'Yes', item: 'Yes' },
  { itemKey: 'No', item: 'No' },
];

export const dataUnknown = [{ itemKey: '0', item: 'unknown' }];

export const dataYesNoWithoutNone = [
  { itemKey: 'Yes', item: 'Yes' },
  { itemKey: 'No', item: 'No' },
];

export const dataYesNoWithBooleanKeyNone = [
  { itemKey: 'true', item: 'Yes' },
  { itemKey: 'false', item: 'No' },
];

export const dataPercent = [
  { itemKey: 'NONE', item: 'NONE' },
  { itemKey: '0-25%', item: '0-25%' },
  { itemKey: '25-50%', item: '25-50%' },
  {
    itemKey: '50-75%',
    item: '50-75%',
  },
  { itemKey: '75-100%', item: '75-100%' },
];
export const dataFieldType = [
  { itemKey: 'FieldFormReviewed', item: 'FieldFormReviewed' },
  { itemKey: 'FieldFormSPROUT', item: 'FieldFormSPROUT' },
];
export const DATA_YES_NO_ALL = [
  { itemKey: '', item: 'All' },
  { itemKey: 'True', item: 'Yes' },
  { itemKey: 'False', item: 'No' },
];
export const DATA_YES_NO = [
  { itemKey: 'Yes', item: 'Yes' },
  { itemKey: 'No', item: 'No' },
];

export const LIST_OF_PATH_FOR_ALL_STATES = [
  '/Owners/OwnerDisplay',
  '/Surveyor/SurveyorDisplay',
  '/StatusOfPlants/EOsOutsideDistribution',
  '/StatusOfPlants/DistributionWithoutEOs',
  '/StatusOfPlants/DistributionAllSources',
  '/StatusOfPlants/DistributionYear',
  '/ElementOccurrence/ElementOccurrenceStatus/ElementOccurrenceDivisionStateStatus',
  '/ElementOccurrence/ElementOccurrenceStatus/SeedBankDivisionState',
  '/ElementOccurrence/HerbariumRecovery',
];

export const PDF_FIELDS_ORDER = [
  'State',
  'Species name',
  'EO number',
  'Subpopulation',
  'Town',
  'Site Name',
  'Quad Name',
  'Quad Code',
  'Survey Date',
  'Lead Surveyor',
  'Surveyor 2',
  'Surveyor 3',
  'Surveyor 4',
  'Directions',
  'Ramets',
  'Genets',
  'Count Precision',
  'How were genets/ramets determined',
  'Species Diagnostics',
  'Confidence In ID',
  'Observed Site Previously',
  'Reference Used',
  'Latitude 1 (decimal degrees)',
  'Longitude 1 (decimal degrees)',
  'Description 1',
  'Latitude 2 (decimal degrees)',
  'Longitude 2 (decimal degrees)',
  'Description 2',
  'Latitude 3 (decimal degrees)',
  'Longitude 3 (decimal degrees)',
  'Description 3',
  'Location Precision',
  'Population Comment',
  'Pct Leaf/Vegetative Only',
  'Pct Bud',
  'Pct Flower',
  'Pct Immature Fruit',
  'Pct Mature Fruit',
  'Pct Seed',
  'Pct Dormant',
  'Pct Seedling',
  'Pct Immature',
  'Pct Mature',
  'Pct Senescent',
  'Pct Unknown',
  'Elevation Min',
  'Elevation Max',
  'Elev Units',
  'Population Area',
  'Pop Area Units',
  'Entire Population',
  'Topo Position',
  'Aspect',
  'Slope',
  'Light',
  'Moisture Regime',
  'Site Characteristics Comment',
  'Associated Species',
  'Invasive Species',
  'Natural Community',
  'Altered Hydrology',
  'Direct Human Threat',
  'Erosion',
  'Fire Suppression',
  'Herbivory',
  'Invasive Species',
  'Succession',
  'No Threat',
  'Threat Scope',
  'Threat Severity',
  'Disturbance Comment',
  'Management Comment',
  'Other Comment',
  'Owner Comment',
  'Seed Plants Sampled',
  'Seed Plants In Fruit',
  'Seed Date Sent',
  'Seed Comment',
  'First Name',
  'Last Name',
  'Signature Date',
  'Files',
];

export const RENAMABLE_FIELDS = [
  { current: 'Inv Species', target: 'Invasive Species' },
  { current: 'Mgt Comment', target: 'Management Comment' },
  { current: 'PCt Imm Fruit', target: 'Pct Immature Fruit' },
  { current: 'pct leaf', target: 'Pct Leaf/Vegetative Only' },
  { current: 'pctImmature', target: 'Pct Immature' },
  { current: 'Pct Mat Fruit', target: 'Pct Mature Fruit' },
  { current: 'Pctseedling', target: 'Pct Seedling' },
  { current: 'Pct Senesc', target: 'Pct Senescent' },
  { current: 'Population Area (m2)', target: 'Population Area' },
  { current: 'Site Char Comment', target: 'Site Characteristics Comment' },
  { current: 'TopoPosition', target: 'Topo Position' },
  { current: 'EO Number', target: 'EO number' },
  { current: 'Genet Ramet Size', target: 'How were genets/ramets determined' },
  { current: 'Species Name', target: 'Species name' },
];

export const RENAMABLE_TARGET_FIELDS = RENAMABLE_FIELDS.map(
  (pairOfFields) => pairOfFields.target
);
