import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const OutreachOrganizationTypesSelect = (props) => {
  const {
    outreachOrganizationTypesData,
    outreachOrganizationTypesLoading,
    updateOutreachOrganizationTypes,
  } = useStore();

  useEffect(() => {
    if (
      !outreachOrganizationTypesData.length &&
      !outreachOrganizationTypesLoading
    )
      updateOutreachOrganizationTypes();
  }, []);

  return (
    <Select
      width={120}
      props={props}
      data={outreachOrganizationTypesData}
      loading={outreachOrganizationTypesLoading}
    />
  );
};

export default OutreachOrganizationTypesSelect;
