import React from 'react';
import classNames from 'clsx';

import { useStore } from '../../../global/stores/store/StoreContext';
import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';

import { searchFormConfig } from './form-config';

import classes from '../../styles.module.scss';

const SearchForm = ({ tableLoading }) => {
    const { divisionsLoading = false } = useStore();

    return (
        <div className={classNames(classes.searchForm)}>
            <CustomSearchForm
                searchConfig={searchFormConfig}
                initialValues={{
                    Division: '',
                }}
                loading={divisionsLoading || tableLoading}
            />
        </div>
    );
};

export default SearchForm;
