import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const CharacterGroupSelect = (props) => {
  const { characterGroupsData, characterGroupsLoading, updateCharacterGroups } =
    useStore();

  useEffect(() => {
    if (!characterGroupsData.length && !characterGroupsLoading)
      updateCharacterGroups();
  }, []);

  return (
    <Select
      props={props}
      width={150}
      data={characterGroupsData}
      loading={characterGroupsLoading}
    />
  );
};

export default CharacterGroupSelect;
