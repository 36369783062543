import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
  getParentPathname,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

import { useAuth } from '../../../../global/stores/auth';
import { roleTypes } from '../../../../utils/roles';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYOR_DISPLAY_AND_EDIT_DETAILS(key), {
        method: 'get',
      })
    : null;

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_SURVEYOR, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (key, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_SURVEYOR(key), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const SurveyorDisplayAndEditForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const [initialValues, setInitialValues] = useState(null);

  const { surveyorTypesExceptAdminData, updateSurveyorTypesExceptAdmin } =
    useStore();

  const { user } = useAuth();
  const role = user?.role;
  
  const privateFields = [
    'emergencyContactName',
    'emergencyContactPhoneNumber',
    'emergencyContactEmailAddress',
  ];

  const filteredFields = useMemo(
    () =>
      role !== roleTypes.Administrator
        ? formConfig.filter(
            (formField) => !privateFields.includes(formField.name)
          )
        : formConfig,
    [role, formConfig]
  );

  useEffect(() => {
    if (!updateSurveyorTypesExceptAdmin.length)
      updateSurveyorTypesExceptAdmin();
  }, []);

  const IS_UPDATE_MODE = key !== 'Create';

  const { data, loading } = useAPI(() => fetch(key), {
    initialState: { loading: false },
    shouldFetch: IS_UPDATE_MODE,
  });

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI(fetchCreate, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(key, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: t(
          `Surveyors:${IS_UPDATE_MODE ? 'Edit' : 'Add'}DisplayAndEditTitle`
        ),
      },
    });
  }, []);

  useEffect(() => {
    if (
      surveyorTypesExceptAdminData.length &&
      (IS_UPDATE_MODE ? data?.length : true)
    ) {
      if (IS_UPDATE_MODE) {
        setInitialValues(data[0]);
      } else {
        setInitialValues({
          surveyorType: surveyorTypesExceptAdminData[0].itemKey,
        });
      }
    }
  }, [data, surveyorTypesExceptAdminData]);

  const onFinish = (values) => {
    if (IS_UPDATE_MODE) {
      refetchUpdate(values);
    } else {
      refetchCreate(values);
    }
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData.surveyorKey) {
          type = 'success';
          message = 'Data was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update Data';
        }
      } else if (createData.surveyorKey) {
        type = 'success';
        message = 'Data was created successfully';
      } else {
        type = 'error';
        message = 'Failed create Data';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        const parentPathname = getParentPathname(pathname);
        history.replace(parentPathname);
      }
    }
  }, [createData, updateData]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`Surveyors:${IS_UPDATE_MODE ? 'Edit' : 'Add'}DisplayAndEditTitle`)}
      </Title>
      <p className={classNames(classes.title)}>
        {t(
          `Surveyors:${
            IS_UPDATE_MODE ? 'Edit' : 'Add'
          }DisplayAndEditDescription`
        )}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues && !loading ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="surveyorDisplayAndEditForm"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={['onBlur', 'onChange']}
            >
              {filteredFields.map(({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default SurveyorDisplayAndEditForm;
