import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { createPdfTables } from '../../../utils/general';

const fetchContent = (key) =>
  APIFetch(ENDPOINT_URLS.GET_SURVEYOR_ASSIGNMENT_DETAILS(key), {
    method: 'get',
  });

export const actionContentFfkey = (record, path) => {
  const { t } = useTranslation();

  const { ffkey } = record;

  return <Link to={`${path}?eokey=${ffkey}`}>{t('table:select')}</Link>;
};

export const actionContent = (record) => {
  const { t } = useTranslation();
  const { data } = useAPI(() => fetchContent(record.eokey), {
    initialState: { data: {}, loading: false },
  });

  const {
    state,
    speciesName,
    eonumber,
    subpopulation,
    town,
    siteName,
    survey,
    collectSeed,
    surveyComment,
  } = record;
  const intermediateFilename = `${state}_${speciesName}_${eonumber}_${subpopulation}`;
  const filename = intermediateFilename.replace(/\./g, '');
  const headersParentData = [
    'state',
    'speciesName',
    'eonumber',
    'subpopulation',
    'town',
    'siteName',
    'survey',
    'collectSeed',
    'surveyComment',
  ];
  const fieldsOwnerNotesAndHistory = ['ownerNotes', 'history'];
  const headersLookalikeSpecies = [
    'speciesName',
    'lookalikeScientificName',
    'lookalikeCharacteristic',
  ];
  const headersNearbySpecies = ['speciesName', 'commonName'];
  const headersOwnerSurveyInformation = [
    'ownerComment',
    'organization',
    'subOrganization',
    'firstName',
    'lastName',
    'phone',
    'email',
    'permitTypeRequested',
    'permitStartDate',
    'permitEndDate',
    'permitDocument1',
    'permitDocument2',
    'permitComment',
    'ownerPermission',
  ];
  const fieldsPhenologySurveyInformation = [
    'speciesName',
    'phenologyType',
    'firstDate',
    'lastDate',
  ];
  const fieldsSpeciesPhenologyNotesConservationStatus = [
    'speciesName',
    'phenologyNote',
    'phenologyMemo',
    'division',
    'comment',
  ];
  const fieldsResearchQuestions = ['speciesName', 'fullText'];

  const parentData = [
    {
      state,
      speciesName,
      eonumber,
      subpopulation,
      town,
      siteName,
      survey,
      collectSeed,
      surveyComment,
    },
  ];
  const createPdf = () => {
    if (data?.ownerSurveyInformation?.length > 0) {
      createPdfTables(t, [
        {
          headers: headersParentData,
          data: parentData,
          title: 'PCV/TF Assignment Summary',
          filename,
        },
        {
          headers: fieldsOwnerNotesAndHistory,
          data:
            data.ownerNotes || data.history
              ? [{ ownerNotes: data.ownerNotes, history: data.history }]
              : null,
          title: 'Owner Notes and History',
          filename,
        },
        {
          headers: headersOwnerSurveyInformation,
          data: data.ownerSurveyInformation,
          fieldsWillDeleteFromObj: [
            'permitDocumentLink1',
            'permitDocumentLink2',
          ],
          title: 'Owner Information for survey',
          filename,
        },
        {
          headers: fieldsPhenologySurveyInformation,
          data: data.phenologySurveyInformation,
          title: 'Phenology Information for survey',
          filename,
        },
        {
          headers: fieldsSpeciesPhenologyNotesConservationStatus,
          data: data.speciesPhenologyNotesConservationStatus,
          title: 'Species phenology notes and conservation status',
          filename,
        },
        {
          headers: headersLookalikeSpecies,
          data: data.lookalikeSpecies,
          title: 'Lookalike Species',
          filename,
        },
        {
          headers: fieldsResearchQuestions,
          data: data.researchQuestions,
          title: 'Research questions',
          filename,
        },
        {
          headers: headersNearbySpecies,
          data: data.nearbySpecies,
          title: 'Nearby species',
          filename,
        },
      ]);
    }
  };

  return (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        style={{
          marginRight: 8,
        }}
        onClick={createPdf}
      >
        Download
      </a>
    </span>
  );
};
