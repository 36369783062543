import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 40,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:commonName'),
      dataIndex: 'commonName',
      key: 'commonName',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:survey'),
      dataIndex: 'survey',
      key: 'survey',
      sorter: true,
      width: 50,
    },
    {
      title: t('table:collectSeed'),
      dataIndex: 'collectSeed',
      key: 'collectSeed',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:surveyComment'),
      dataIndex: 'surveyComment',
      key: 'surveyComment',
      sorter: true,
      width: 120,
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 120,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-filename-extension
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 400,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:leadSurvName'),
      dataIndex: 'leadSurvName',
      key: 'leadSurvName',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:surv2Name'),
      dataIndex: 'surv2Name',
      key: 'surv2Name',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:surv3Name'),
      dataIndex: 'surv3Name',
      key: 'surv3Name',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:surv4Name'),
      dataIndex: 'surv4Name',
      key: 'surv4Name',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:sproutfieldForm'),
      dataIndex: 'sproutfieldForm',
      key: 'sproutfieldForm',
      sorter: true,
      width: 100,
    },
  ];
};
