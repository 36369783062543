import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Table, Spin, Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import { useAuth } from '../../global/stores/auth';
import { roleTypes } from '../../utils/roles';
import { useAPI } from '../../shared/hooks/use-api/useAPI';
import { downloadExcel, highlightTotalValue } from '../../utils/general';
import { buildSearchParamsForRequest } from '../../shared/sortAndSearchUtils';
import PageContent from '../../shared/components/PageContent';

import classes from '../styles.module.scss';

const PageWithSimpleTableAndSearch = ({
  fetchData,
  fetchExport,
  isDataObject = false,
  searchParamKey,
  titleKey,
  descriptionKey,
  totalValueKey,
  searchForm,
  columns,
  rowKey,
  scroll,
  isForceRefetch,
  forceLoading,
  additionalMessage,
  children,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { key } = useParams();
  const { user } = useAuth();
  const role = user?.role;

  const { pathname, search: searchParams } = useLocation();
  const query = new URLSearchParams(searchParams);
  const nameFromUrl = query.getAll('name')[0];

  const { data, loading, refetch } = useAPI(fetchData, {
    initialState: { data: isDataObject ? {} : [], loading: false },
    shouldFetch: false,
  });

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (data?.length && data[0].ffkey === +key) {
      history.replace({
        state: {
          breadcrumbsName: nameFromUrl || data[0].eoinfo,
        },
        search: searchParams,
      });
    }
  }, [data]);

  const [searchParameters, setSearchParameters] = useState({
    [searchParamKey]: '',
  });

  useEffect(() => {
    refetch(searchParamKey ? searchParameters[searchParamKey] : null);
  }, [searchParameters]);

  useEffect(() => {
    if (searchForm) {
      if (data && !searchParams) {
        refetch();

        return;
      }

      const searchParamsArray = buildSearchParamsForRequest(searchParams);

      if (searchParamsArray?.length) {
        setSearchParameters({
          [searchParamKey]: searchParamsArray[0].value,
        });
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isForceRefetch) {
      refetch(searchParamKey ? searchParameters[searchParamKey] : null);
    }
  }, [isForceRefetch]);

  let tableRows = data || [];
  if (isDataObject) {
    tableRows = data ? [data] : [];
  }
  if (tableRows.length && totalValueKey) {
    tableRows = highlightTotalValue(totalValueKey, tableRows);
  }

  const additionalMessageToPage = additionalMessage ? (
    ' '
  ) : (
    <p className={classNames(classes.noData)}>{t('table:noData')}</p>
  );

  const onExport = () => {
    refetchExport(searchParamKey ? searchParameters[searchParamKey] : null);
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(titleKey)}
      </Title>
      {descriptionKey && (
        <p className={classNames(classes.title)}>{t(descriptionKey)}</p>
      )}

      {searchForm && searchForm({ tableLoading: loading })}

      {!tableRows.length && role === roleTypes.Heritage && children}
      {role === roleTypes.Administrator && children}

      {tableRows.length ? (
        <>
          {!!fetchExport && (
            <div className={classNames(classes.content)}>
              <Button type="primary" onClick={onExport} loading={exportLoading}>
                Export to Excel
              </Button>
            </div>
          )}
          <Table
            className={classNames(classes.table)}
            columns={columns}
            rowKey={rowKey || uuidv4()}
            dataSource={tableRows}
            loading={loading || forceLoading}
            pagination={false}
            scroll={scroll}
          />
        </>
      ) : null}

      {(
        isDataObject
          ? Object.keys(data).length && !data?.items.length
          : data?.length === 0 && !loading
      )
        ? additionalMessageToPage
        : null}

      {(isDataObject ? !Object.keys(data).length : !data?.length) && loading ? (
        <center>
          <Spin />
        </center>
      ) : null}
    </PageContent>
  );
};

export default PageWithSimpleTableAndSearch;
