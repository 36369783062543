import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Typography, Col, Row } from 'antd';

import { useAuth } from '../../global/stores/auth';
import logotype from '../../global/shared/SPROUT lockup_rgb.png';

// eslint-disable-next-line import/no-cycle
import { ACCOUNT_SETTINGS } from '../../AppRoutes';
import { FIRST_LOGIN_KEY } from '../../global/constants';

import classes from './styles.module.scss';

const { Title } = Typography;
const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
};

const LogIn = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isValuesChanged, setIsValuesChanged] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [credentials, setCredentials] = useState();

  const {
    login: { loginFn, authInProgress, authError },
  } = useAuth();

  const firstLogin = JSON.parse(localStorage.getItem(FIRST_LOGIN_KEY));

  useEffect(() => {
    if (firstLogin?.activeStep === 2) {
      history.push(ACCOUNT_SETTINGS);
      localStorage.setItem(
        FIRST_LOGIN_KEY,
        JSON.stringify({
          ...firstLogin,
          activeStep: null,
          userCredentials: credentials,
        })
      );
    }
  }, [firstLogin]);

  const onFinish = (values) => {
    setIsValuesChanged(false);
    setCredentials(values);
    loginFn(values);
  };

  const isError = !isValuesChanged && !authInProgress && authError;
  const errorMessage = isError && t('login:error');

  useEffect(() => {
    if (firstLogin?.userCredentials) {
      setInitialValues({ ...firstLogin.userCredentials });

      localStorage.setItem(
        FIRST_LOGIN_KEY,
        JSON.stringify({
          ...firstLogin,
          userCredentials: null,
        })
      );
    } else {
      setInitialValues({});
    }
  }, []);

  return (
    <Row justify="space-around" align="middle" className={classes.formWrapper}>
      <Col span={12}>
        <div className={classes.logotype}>
          <img src={logotype} alt="logotype" />
        </div>
        <Title level={2} className={classes.title}>
          {t('login:login')}
        </Title>
        {initialValues && (
          <Form
            {...layout}
            name="login"
            onValuesChange={() => setIsValuesChanged(true)}
            onFinish={onFinish}
            initialValues={initialValues}
            validateTrigger={['onBlur', 'onChange']}
          >
            {isError ? (
              <Item
                label="Email"
                name="email"
                validateStatus={isError && 'error'}
              >
                <Input />
              </Item>
            ) : (
              <Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: t('login:errorEmailIsRequired'),
                  },
                ]}
              >
                <Input />
              </Item>
            )}

            {isError ? (
              <Item
                label="Password"
                name="password"
                validateStatus={isError && 'error'}
                help={errorMessage}
              >
                <Input.Password />
              </Item>
            ) : (
              <Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('login:errorPasswordIsRequired'),
                  },
                ]}
              >
                <Input.Password />
              </Item>
            )}

            <Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={authInProgress}>
                Submit
              </Button>
              <span className={classes.forgotPassword}>
                <Link to="/ForgotPassword">{t('login:forgotPassword')}</Link>
              </span>
            </Item>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default LogIn;
