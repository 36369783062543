import React from 'react';
import StatesSelect from '../../../shared/components/select/StatesSelect';

export const feSearchConfig = [
  {
    name: 'Organization',
    label: 'Surveyor or Organization name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Organization',
  },
  {
    operatortype: 'Contains',
    propertyName: 'FirstName',
    equivalentPropertyName: 'Organization',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'LastName',
    equivalentPropertyName: 'Organization',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
];
