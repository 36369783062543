import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_DETAILS(key), {
        method: 'get',
      })
    : null;

const OwnerDisplayForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key } = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState(null);

  const {
    allOwnerTypesData,
    updateAllOwnerTypes,
    allOwnerStatusesData,
    updateAllOwnerStatuses,
    allReportMethodsData,
    updateAllReportMethods,
  } = useStore();

  useEffect(() => {
    if (!allOwnerTypesData.length) updateAllOwnerTypes();
    if (!allOwnerStatusesData.length) updateAllOwnerStatuses();
    if (!allReportMethodsData.length) updateAllReportMethods();
  }, []);

  const { data, loading } = useAPI(() => fetch(key), {
    initialState: { data: null, loading: false },
    shouldFetch: true,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: t(
          `Owner`
        ),
      },
    });
  }, []);

  useEffect(() => {
    if (
      allOwnerTypesData.length &&
      allOwnerStatusesData.length &&
      allReportMethodsData.length &&
      data
    ) {
      setInitialValues(data);
    }
  }, [data, allOwnerTypesData, allOwnerStatusesData, allReportMethodsData]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`Owner`)}
      </Title>
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues && !loading ? (
            <Form
              {...layout}
              form={form}
              name="ownersDisplayAndEditForm"
              initialValues={initialValues}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  disabled
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default OwnerDisplayForm;
