import { useAuth } from '../../global/stores/auth';
import { roleTypes } from '../../utils/roles';

export const HomepageConfig = () => {
  const { user } = useAuth();
  const role = user?.role;
  let sections = [];
  switch (role) {
    case roleTypes.PCV:
      sections = [
        {
          title: 'homepage:species:title',
          description: 'homepage:species:description',
          buttonName: 'homepage:species:buttonName',
          pathTo: '/Species',
        },
        {
          title: 'homepage:PCV:title',
          description: 'homepage:PCV:description',
          buttonName: 'homepage:PCV:buttonName',
          pathTo: '/PCV',
        },
      ];
      break;
    case roleTypes.TF:
      sections = [
        {
          title: 'homepage:species:title',
          description: 'homepage:species:description',
          buttonName: 'homepage:species:buttonName',
          pathTo: '/Species',
        },
        {
          title: 'homepage:PCV:title',
          description: 'homepage:PCV:description',
          buttonName: 'homepage:PCV:buttonName',
          pathTo: '/PCV',
        },
      ];
      break;
    case roleTypes.Transcriber:
      sections = [
        {
          title: 'homepage:element-occurrences:title',
          description: 'homepage:element-occurrences:description',
          buttonName: 'homepage:element-occurrences:buttonName',
          pathTo: '/ElementOccurrence',
        },
        {
          title: 'homepage:species:title',
          description: 'homepage:species:description',
          buttonName: 'homepage:species:buttonName',
          pathTo: '/Species',
        },
      ];
      break;
    case roleTypes.Administrator:
      sections = [
        {
          title: 'homepage:element-occurrences:title',
          description: 'homepage:element-occurrences:description',
          buttonName: 'homepage:element-occurrences:buttonName',
          pathTo: '/ElementOccurrence',
        },
        {
          title: 'homepage:species:title',
          description: 'homepage:species:description',
          buttonName: 'homepage:species:buttonName',
          pathTo: '/Species',
        },
        {
          title: 'homepage:owners:title',
          description: 'homepage:owners:description',
          buttonName: 'homepage:owners:buttonName',
          pathTo: '/Owners',
        },
        {
          title: 'homepage:surveyors:title',
          description: 'homepage:surveyors:description',
          buttonName: 'homepage:surveyors:buttonName',
          pathTo: '/Surveyor',
        },
        {
          title: 'homepage:validation:title',
          description: 'homepage:validation:description',
          buttonName: 'homepage:validation:buttonName',
          pathTo: '/ValidationData',
        },
        {
          title: 'homepage:PCV:title',
          description: 'homepage:PCV:description',
          buttonName: 'homepage:PCV:buttonName',
          pathTo: '/PCV',
        },
        {
          title: 'homepage:PCVAdmin:title',
          description: 'homepage:PCVAdmin:description',
          buttonName: 'homepage:PCVAdmin:buttonName',
          pathTo: '/PCVAdmin',
        },
        {
          title: 'homepage:status:title',
          description: 'homepage:status:description',
          buttonName: 'homepage:status:buttonName',
          pathTo: '/StatusOfPlants',
        },
      ];
      break;
    case roleTypes.Inactive:
      sections = [];
      break;
    case roleTypes.Heritage:
      sections = [
        {
          title: 'homepage:element-occurrences:title',
          description: 'homepage:element-occurrences:description',
          buttonName: 'homepage:element-occurrences:buttonName',
          pathTo: '/ElementOccurrence',
        },
        {
          title: 'homepage:species:title',
          description: 'homepage:species:description',
          buttonName: 'homepage:species:buttonName',
          pathTo: '/Species',
        },
        {
          title: 'homepage:owners:title',
          description: 'homepage:owners:description',
          buttonName: 'homepage:owners:buttonName',
          pathTo: '/Owners',
        },
        {
          title: 'homepage:surveyors:title',
          description: 'homepage:surveyors:description',
          buttonName: 'homepage:surveyors:buttonName',
          pathTo: '/Surveyor',
        },
        {
          title: 'homepage:status:title',
          description: 'homepage:status:description',
          buttonName: 'homepage:status:buttonName',
          pathTo: '/StatusOfPlants',
        },
      ];
      break;
    default:
      sections = [];
  }
  return sections;
};
