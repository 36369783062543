import React from 'react';
import { InputNumber, Input, DatePicker } from 'antd';
import moment from 'moment';

import { Select } from '../../shared/components/select';
import CountPrecisionsSelect from '../../shared/components/select/CountPrecisionsSelect';
import LocationPrecisionsForFieldEnterSelect from '../../shared/components/select/LocationPrecisionsForFieldEnterSelect';
import StatesSelect from '../../shared/components/select/StatesSelect';
import SpeciesNamesAutocomplete from '../../shared/components/autocomplete/SpeciesNamesAutocomplete';
import SurveyorsAutocomplete from '../../shared/components/autocomplete/SurveyorsAutocomplete';
import TopoPositionsSelect from '../../shared/components/select/TopoPositionsSelect';
import AspectsSelect from '../../shared/components/select/AspectsSelect';
import LightsSelect from '../../shared/components/select/LightsSelect';
import MoistureRegimesSelect from '../../shared/components/select/MoistureRegimesSelect';
import SlopesSelect from '../../shared/components/select/SlopesSelect';
// import { getCurrentDate } from '../../utils/general';

import {
  DATA_YES_NO_NONE,
  DATA_FT2_M2,
  DATA_FT_M,
  DATA_PERCENT,
  DATA_FIELD_TYPE,
  DATA_YES_NO_WITHOUT_NONE,
} from './constants';

const { TextArea } = Input;

const previousYear = +moment().format('YYYY') - 1;
// const today = getCurrentDate();
export const disabledDate = (current) => {
  // const tooLate = +current.format('YYYYMMDD') > +today;
  const tooEarly = current.year() < previousYear;

  // return tooEarly || tooLate;
  return tooEarly;
};

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const pctValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 100,
  },
];

const latitudeRules = [
  {
    type: 'number',
    min: 0,
    max: 47.5,
  },
];

const longitudeRules = [
  {
    type: 'number',
    min: -74,
    max: 0,
  },
];

const positiveRules = [
  {
    type: 'integer',
    min: 0,
  },
];

const genetsRametsRules = [
  {
    type: 'integer',
    message: 'Number is too large',
  },
];

const formConfig = {
  eokey: {
    label: 'EO',
    input: (props) => <CustomInputNumber {...props} disabled />,
  },
  state: {
    label: 'State',
    input: (props) => <StatesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  speciesKey: {
    label: 'Species Name',
    input: (props) => <SpeciesNamesAutocomplete {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  town: {
    label: 'Town',
    rules: [
      {
        max: 60,
      },
    ],
  },
  siteName: {
    label: 'Site Name',
    rules: [
      {
        max: 100,
      },
    ],
  },
  surveyDate: {
    label: 'Survey Date',
    rules: [
      {
        required: true,
      },
    ],
    input: (props) => (
      <DatePicker
        {...props}
        // disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  leadSurveyor: {
    label: 'Lead Surveyor',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
    rules: [
      {
        required: true,
      },
    ],
  },
  surveyor2: {
    label: 'Surveyor 2',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
  },
  surveyor3: {
    label: 'Surveyor 3',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
  },
  surveyor4: {
    label: 'Surveyor 4',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
  },
  directions: {
    label: 'Directions',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  ramets: {
    label: 'Ramets',
    input: (props) => <CustomInputNumber {...props} />,
    rules: genetsRametsRules,
  },
  genets: {
    label: 'Genets',
    input: (props) => <CustomInputNumber {...props} />,
    rules: genetsRametsRules,
  },
  countPrecision: {
    label: 'Count Precision',
    input: (props) => <CountPrecisionsSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
    text: 'countPrecision',
  },
  genetRametSize: {
    label: 'How were genets/ramets determined',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  speciesDiagnostics: {
    label: 'Species Diagnostics',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
    rules: [
      {
        required: true,
      },
      {
        min: 20,
        max: 1000,
      },
    ],
  },
  confidenceInId: {
    label: 'Confidence In ID',
    input: (props) => <Select props={props} data={DATA_YES_NO_WITHOUT_NONE} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  observedSitePrev: {
    label: 'Observed Site Previously',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  referenceUsed: {
    label: 'Reference Used',
  },
  latitude1: {
    label: 'Latitude 1 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: latitudeRules,
  },
  longitude1: {
    label: 'Longitude 1 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: longitudeRules,
  },
  description1: {
    label: 'Description 1',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  latitude2: {
    label: 'Latitude 2 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: latitudeRules,
  },
  longitude2: {
    label: 'Longitude 2 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: longitudeRules,
  },
  description2: {
    label: 'Description 2',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  latitude3: {
    label: 'Latitude 3 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: latitudeRules,
  },
  longitude3: {
    label: 'Longitude 3 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: longitudeRules,
  },
  description3: {
    label: 'Description 3',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  locationPrecision: {
    label: 'Location Precision',
    withNoneAsEmpty: true,
    input: (props) => <LocationPrecisionsForFieldEnterSelect {...props} />,
  },
  populationComment: {
    label: 'Population Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
    text: 'populationComment',
  },
  pctLeaf: {
    label: 'Pct Leaf/Vegetative Only',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
    text: 'pctLeaf',
  },
  pctBud: {
    label: 'Pct Bud',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctFlower: {
    label: 'Pct Flower',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctImmFruit: {
    label: 'Pct Immature Fruit',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctMatFruit: {
    label: 'Pct Mature Fruit',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctSeed: {
    label: 'Pct Seed',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctDormant: {
    label: 'Pct Dormant',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctSeedling: {
    label: 'Pct Seedling',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
    text: 'pctSeedling',
  },
  pctImmature: {
    label: 'Pct Immature',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctMature: {
    label: 'Pct Mature',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctSenesc: {
    label: 'Pct Senescent',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  pctUnknown: {
    label: 'Pct Unknown',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },

  elevationMin: {
    label: 'Elevation Min',
    input: (props) => <CustomInputNumber {...props} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  elevationMax: {
    label: 'Elevation Max',
    input: (props) => <CustomInputNumber {...props} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  elevUnits: {
    label: 'Elev Units',
    input: (props) => <Select props={props} data={DATA_FT_M} />,
  },

  populationArea: {
    label: 'Population Area',
    input: (props) => <CustomInputNumber {...props} />,
    rules: [
      {
        type: 'number',
        min: 0,
        max: 1000000,
      },
    ],
  },
  popAreaUnits: {
    label: 'Pop Area Units',
    input: (props) => <Select props={props} data={DATA_FT2_M2} />,
  },
  entirePopulation: {
    label: 'Entire Population',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },

  topoPosition: {
    label: 'TopoPosition',
    withNoneAsEmpty: true,
    input: (props) => <TopoPositionsSelect {...props} />,
    text: 'topoPosition',
  },
  aspect: {
    label: 'Aspect',
    withNoneAsEmpty: true,
    input: (props) => <AspectsSelect {...props} />,
  },
  slope: {
    label: 'Slope',
    withNoneAsEmpty: true,
    input: (props) => <SlopesSelect {...props} />,
  },
  light: {
    label: 'Light',
    withNoneAsEmpty: true,
    input: (props) => <LightsSelect {...props} />,
  },
  moistureRegime: {
    label: 'Moisture Regime',
    withNoneAsEmpty: true,
    input: (props) => <MoistureRegimesSelect {...props} />,
  },
  siteCharComment: {
    label: 'Site Characteristics Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  assocSpecies: {
    label: 'Associated Species',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  invSpecies: {
    label: 'Invasive Species',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  naturalCommunity: {
    label: 'Natural Community',
  },
  alteredHydrology: {
    label: 'Altered Hydrology',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
    text: 'alteredHydrology',
  },
  directHumanThreat: {
    label: 'Direct Human Threat',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  erosion: {
    label: 'Erosion',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  fireSuppression: {
    label: 'Fire Suppression',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  herbivory: {
    label: 'Herbivory',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  invasiveSpecies: {
    label: 'Invasive Species',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  succession: {
    label: 'Succession',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  noThreat: {
    label: 'No Threat',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_YES_NO_NONE} />,
  },
  threatScope: {
    label: 'Threat Scope',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_PERCENT} />,
  },
  threatSeverity: {
    label: 'Threat Severity',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={DATA_PERCENT} />,
  },

  disturbanceComment: {
    label: 'Disturbance Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  mgtComment: {
    label: 'Management Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  otherComment: {
    label: 'Other Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  ownerComment: {
    label: 'Owner Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
    rules: [
      {
        min: 20,
        max: 1000,
      },
    ],
  },
  seedPlantsSampled: {
    label: 'Seed Plants Sampled',
    input: (props) => <CustomInputNumber {...props} />,
    rules: positiveRules,
    text: 'seedPlantsSampled',
  },
  seedPlantsInFruit: {
    label: 'Seed Plants In Fruit',
    input: (props) => <CustomInputNumber {...props} />,
    rules: positiveRules,
  },
  seedDateSent: {
    label: 'Seed Date Sent',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  seedComment: {
    label: 'Seed Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  firstName: {
    label: 'First Name',
    rules: [
      {
        required: true,
      },
    ],
  },
  lastName: {
    label: 'Last Name',
    rules: [
      {
        required: true,
      },
    ],
  },
  signatureDate: {
    label: 'Signature Date',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
    rules: [
      {
        required: true,
      },
    ],
    infoKey: 'signatureDate',
  },
  fieldDataType: {
    label: 'Field Data Type',
    input: (props) => <Select props={props} data={DATA_FIELD_TYPE} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  reviewComments: {
    label: 'Review Comments',
  },
};

export default formConfig;
