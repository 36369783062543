import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import { Form, Tabs } from 'antd';
import classNames from 'clsx';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import {
  buildSearchParamsForRequest,
  getRandomuserParams,
  paramsToPassInBodyRequest,
} from '../../../shared/sortAndSearchUtils';
import {
  downloadExcel,
  openNotificationWithIcon,
  paramsForBE,
} from '../../../utils/general';
import { action } from '../../../shared/EditableTable/components/Action';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import PageWithEditableTable from '../../pages/PageWithEditableTable';
import TableUnderTab from '../../pages/TableUnderTab';
import PageContent from '../../../shared/components/PageContent';

import { columns, columnsWithEditableField } from './TableColumns';
import SearchForm from './SearchForm';
import { editableCellsConfig, beSearchConfig } from './form-config';
import { actionContent } from './utils';

import classes from '../../styles.module.scss';

const { TabPane } = Tabs;

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchSurveysWithFieldForms = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYS_WITH_FIELD_FORMS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchSurveysWithoutPermission = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYS_WITHOUT_PERMISSION_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchSurveysWithoutFieldFormsButWithPermission = (args) =>
  args
    ? APIFetch(
        ENDPOINT_URLS.GET_SURVEYS_WITHOUT_FIELD_FORMS_BUT_WITH_PERMISSION_PAGED,
        {
          method: 'post',
          body: JSON.stringify(args),
        }
      )
    : {};

const fetchExportSurveysWithFieldForms = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYS_WITH_FIELD_FORMS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchExportSurveysWithoutPermission = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYS_WITHOUT_PERMISSION_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchExport = (args) =>
  args
    ? APIFetch(
        ENDPOINT_URLS.GET_SURVEYS_WITHOUT_FIELD_FORMS_BUT_WITH_PERMISSION_EXCEL,
        {
          method: 'post',
          body: JSON.stringify(args),
        }
      )
    : null;

const defaultSortField = [
  'State',
  'SpeciesName',
  'EonumberInt',
  'Subpopulation',
];

const SurveyResultCurrentYearWithTabs = () => {
  const { t } = useTranslation();
  const { pathname, search: searchParams } = useLocation();
  const [form] = Form.useForm();

  const { data, loading, refetch } = useAPI(
    (args) => fetchSurveysWithoutFieldFormsButWithPermission(args),
    {
      initialState: { data: {}, loading: false },
      shouldFetch: false,
    }
  );

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({});

  const cancel = () => {
    setEditingKey('');
  };

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE(searchParameters, beSearchConfig);

    refetch(
      paramsToPassInBodyRequest(parameters, searchParam, defaultSortField)
    );
  };

  const [parametersForExport, setParametersForExport] = useState();
  const onExport = () => {
    if (parametersForExport) {
      const parameters = getRandomuserParams(parametersForExport);
      const searchParam = paramsForBE(searchParameters, beSearchConfig).filter(
        ({ value }) => !!value
      );

      refetchExport(
        paramsToPassInBodyRequest(parameters, searchParam, defaultSortField)
      );
    } else {
      const searchParamsArray = buildSearchParamsForRequest(searchParams);
      const parameters = paramsToPassInBodyRequest(
        {
          pagination: initialPagination,
        },
        paramsForBE
          ? paramsForBE(searchParamsArray, beSearchConfig)
          : searchParamsArray,
        defaultSortField
      );
      if (parameters.State === '') {
        parameters.State = null;
      }
      refetchExport(parameters);
    }
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();

      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    if (searchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          paramsForBE(searchParamsArray, beSearchConfig).filter((i) => i.value),
          defaultSortField
        )
      );
      setSearchParameters(searchParamsArray);
    }
  }, [searchParams]);

  const initialState = {
    surveyResult: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();

    APIFetch(
      ENDPOINT_URLS.UPDATE_SURVEYS_WITHOUT_FIELD_FORMS_BUT_WITH_PERMISSION(key),
      {
        method: 'put',
        body: JSON.stringify(row),
      }
    ).then(({ status }) => {
      if (status) {
        const newData = [...tableData];
        const index = newData.findIndex((item) => key === item.eokey);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      }
    });
  };

  const allColumns = columnsWithEditableField;
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 90,
      renderFunction: (_, record) => {
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey
        );
        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];

  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isCellEditing(record, editingKey),
      }),
    };
  });

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdministration:SurveyResultCurrentYearWithTab')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVAdministration:SpeciesInformationWithTabDescription')}
      </p>

      {SearchForm && SearchForm(false)}

      {searchParams && (
        <Tabs className={classNames(classes.tabs)}>
          <TabPane
            tab={t('PCVAdministration:SurveysWithoutPermission')}
            key="SurveysWithoutPermission"
          >
            <TableUnderTab
              fetchExport={fetchExportSurveysWithoutPermission}
              fetchData={fetchSurveysWithoutPermission}
              defaultSortField="SpeciesName"
              searchForm={SearchForm}
              columns={columns}
              scroll={{ x: 800 }}
              beSearchConfig={beSearchConfig}
              paramsForBE={paramsForBE}
            />
          </TabPane>

          <TabPane
            tab={t('PCVAdministration:SurveysWithFieldForms')}
            key="SurveysWithFieldForms"
          >
            <TableUnderTab
              fetchExport={fetchExportSurveysWithFieldForms}
              fetchData={fetchSurveysWithFieldForms}
              defaultSortField="SpeciesName"
              searchForm={SearchForm}
              columns={columns}
              scroll={{ x: 800 }}
              beSearchConfig={beSearchConfig}
              paramsForBE={paramsForBE}
            />
          </TabPane>

          <TabPane
            tab={t(
              'PCVAdministration:SurveysWithoutFieldFormsButWithPermission'
            )}
            key="SurveysWithoutFieldFormsButWithPermission"
          >
            <PageWithEditableTable
              searchForm={SearchForm}
              columns={mergedColumns}
              rowKeyFunc={(record) => record.eokey}
              editableCellsConfig={editableCellsConfig}
              fetch={fetch}
              isExportContent
              setParametersForExport={setParametersForExport}
              onExport={onExport}
              exportLoading={exportLoading}
              tableData={tableData}
              setData={setData}
              initialPagination={initialPagination}
              cancel={cancel}
              form={form}
              data={data}
              loading={loading}
              withTab
            />
          </TabPane>
        </Tabs>
      )}
    </PageContent>
  );
};

export default SurveyResultCurrentYearWithTabs;
