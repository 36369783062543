export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'SpeciesName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'CommonName',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'ScientificName',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Synonyms',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
];
