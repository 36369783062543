import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { action } from '../../../shared/EditableTable/components/Action';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';

const defaultSortFields = ['State', 'SpeciesName', 'Eonumber', 'Subpopulation'];
const bodyForFirstRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: defaultSortFields.map((propertyName) => ({
    orderOption: 'Ascending',
    propertyName,
  })),
};

const fetchFieldFormEnter = (args) =>
  APIFetch(ENDPOINT_URLS.GET_FIELD_FORM_ENTER, {
    method: 'post',
    body: JSON.stringify(args || bodyForFirstRequest),
  });

const FieldFormEnter = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, { eokey }) => (
        <Space size="middle">
          <Link
            to={{
              pathname: `${pathname}/${eokey}`,
            }}
          >
            {t('table:select')}
          </Link>
        </Space>
      ),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={(args) => fetchFieldFormEnter(args)}
      defaultSortField={defaultSortFields}
      titleKey="PCVTF:FieldFormEnter:title"
      descriptionKey="PCVTF:FieldFormEnter:description"
      columns={allColumns}
      rowKey={(record) => record.eokey}
      scroll={{ x: 1300 }}
    />
  );
};

export default FieldFormEnter;
