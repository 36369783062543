import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import {
  getParentPathname,
  openNotificationWithIcon,
  validateMessages,
  getFormData,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
// eslint-disable-next-line import/no-cycle
import PageContent from '../../../../shared/components/PageContent';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const ValidationDataForm = ({
  fetch,
  fetchCreate,
  fetchUpdate,
  pageKey,
  pageName,
  idKey,
  formConfig,
  getParamsForInitialValues,
  getParamsForSubmit,
  disabledFields = [],
  notValidationData,
  isInitialValuesCanBeSetted = true,
  keyForParentDataRequest,
  titleTxt,
  breadcrumbsTxt,
  fieldsValueOnClear,
  isCreateRequestWithFormData,
  isCancelButton,
  isFormWithEmail,
  onFieldsChange,
  isUpdateButton,
  disabledFieldsInCreateMode = [],
}) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key: keyFromUrl } = useParams();
  const key = keyFromUrl?.replace('percent', '%');

  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const [initialValues, setInitialValues] = useState(null);

  const IS_UPDATE_MODE = key !== 'Create';

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI((args) => fetchCreate(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(key, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: parentData } = useAPI(
    () => fetch(key, keyForParentDataRequest),
    {
      initialState: { data: {}, loading: false },
      shouldFetch: IS_UPDATE_MODE,
    }
  );

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName:
          breadcrumbsTxt || `${IS_UPDATE_MODE ? 'Edit' : 'Add'} ${pageName}`,
      },
    });
  }, []);

  useEffect(() => {
    if (parentData?.items?.length) {
      const params = getParamsForInitialValues(
        IS_UPDATE_MODE,
        parentData.items[0]
      );

      setInitialValues(params);
    }
  }, [parentData]);

  useEffect(() => {
    if (!IS_UPDATE_MODE && isInitialValuesCanBeSetted) {
      const params = getParamsForInitialValues(IS_UPDATE_MODE);

      setInitialValues(params);
    }
  }, [isInitialValuesCanBeSetted]);

  const onFinish = (values) => {
    const params = getParamsForSubmit(IS_UPDATE_MODE, values);

    if (IS_UPDATE_MODE) {
      refetchUpdate(params);
    } else {
      let args = params;

      if (isCreateRequestWithFormData) {
        const { file, ...rest } = params;
        const data = rest;

        if (file) {
          data.file = file.file?.originFileObj || file.file;
        }

        args = getFormData(data);
      }

      refetchCreate(args);
    }
  };

  const onClickClear = () => {
    form.resetFields();

    if (fieldsValueOnClear) form.setFieldsValue({ ...fieldsValueOnClear });
  };

  const onClickCancel = () => {
    history.replace(getParentPathname(pathname));
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData[idKey]) {
          type = 'success';
          message = `${pageName} was updated successfully`;
        } else {
          type = 'error';
          message = `Failed update ${pageName}`;
        }
      } else if (
        (idKey && createData && createData[idKey]) ||
        (!idKey && createData && createData.ok)
      ) {
        type = 'success';
        message = `${pageName} was created successfully`;
      } else {
        type = 'error';
        message = `Failed create ${pageName}`;
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        history.replace(getParentPathname(pathname));
      }
    }
  }, [createData, updateData]);

  const section = notValidationData || 'Validation';
  const condition = notValidationData ? 'edit' : 'update';
  const editButton = isUpdateButton ? 'update' : condition;

  const changeFields = (e) => {
    const fieldName = e[0].name[0];
    const fieldValue = e[0].value;

    if (onFieldsChange) onFieldsChange(form, fieldName, fieldValue);
  };

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {titleTxt ||
          t(`${section}:${pageKey}${IS_UPDATE_MODE ? 'Edit' : 'Create'}`)}
      </Title>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name={`${IS_UPDATE_MODE ? 'edit' : 'add'}${pageKey}`}
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={
                isFormWithEmail ? ['onBlur'] : ['onBlur', 'onChange']
              }
              onFieldsChange={changeFields}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  disabled={(IS_UPDATE_MODE
                    ? disabledFields
                    : disabledFieldsInCreateMode
                  ).includes(name)}
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? `${editButton}` : 'insert'}`)}
                </Button>
                {isCancelButton ? (
                  <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
                ) : (
                  <Button onClick={onClickClear}>{t('form:clear')}</Button>
                )}
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default ValidationDataForm;
