import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithNestedTable from '../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchManagementNotesPaged = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_MANAGEMENT_NOTES_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchDataFromNorm = (key, args = {}) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_DATA_FROM_NORM(key), {
        method: 'post',
        body: JSON.stringify({
          orderInfo: {
            orderCriteria: [
              {
                orderOption: 'Ascending',
                propertyName: 'Year',
              },
            ],
          },
          ...args,
        }),
      })
    : [];

export const ManagementNotes = () => (
  <PageWithNestedTable
    fetchData={(args) => fetchManagementNotesPaged(args)}
    fetchNestedData={(key, args) => fetchDataFromNorm(key, args)}
    defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
    nestedTableKey="eokey"
    titleKey="StatusOfPlants:ManagementNotes"
    descriptionKey="StatusOfPlants:ManagementNotesDescription"
    searchForm={SearchForm}
    columns={columns()}
    expandableColumns={expandableColumns()}
    rowKeyFunc={(record) => record.eokey}
    scroll={{ x: 1300 }}
  />
);
