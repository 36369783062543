import React from 'react';
import { Table } from 'antd';

import { EditableCell } from './components/EditableCell';

export const EditableTable = (props) => {
  const {
    className,
    columns,
    dataSource,
    loading,
    rowKey,
    onChange,
    scroll,
    pagination,
    editableCellsConfig,
  } = props;

  return (
    <Table
      components={{
        body: {
          cell: (args) =>
            EditableCell({ ...args, cellsConfig: editableCellsConfig }),
        },
      }}
      className={className}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      rowKey={rowKey}
      onChange={onChange}
      scroll={scroll}
      pagination={pagination}
    />
  );
};
