import React from 'react';

import StatesSelect from '../../../../shared/components/select/StatesSelect';
import SurveyorTypesExceptAdminSelect from '../../../../shared/components/select/SurveyorTypesExceptAdminSelect';

export const feSearchConfig = [
  {
    name: 'LastName',
    label: 'Surveyor name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'Organization',
    label: 'Organization name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SurveyorType',
    label: 'Surveyor Type',
    input: (props) => <SurveyorTypesExceptAdminSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'LastName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Organization',
  },
  {
    propertyName: 'SurveyorType',
    operatortype: 'Equals',
  },
  {
    propertyName: 'State',
    operatortype: 'Equals',
  },
  {
    operatortype: 'Contains',
    propertyName: 'FirstName',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Organization',
  },
  {
    propertyName: 'SurveyorType',
    operatortype: 'Equals',
  },
  {
    propertyName: 'State',
    operatortype: 'Equals',
  },
];
