import React from 'react';
import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:transcriptionDone'),
      dataIndex: 'transcriptionDone',
      key: 'transcriptionDone',
      sorter: true,
      render: (_, { transcriptionDone }) => (transcriptionDone ? 'Yes' : 'No'),
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:folder'),
      dataIndex: 'folder',
      key: 'folder',
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:fullFilename'),
      dataIndex: 'fullFilename',
      key: 'fullFilename',
      render: (text, { fileLink }) =>
        fileLink && text ? <a href={fileLink}>{text}</a> : text,
    },
  ];
};
