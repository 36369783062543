import { Breadcrumb } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import {
  BREADCRUMBS_DETAILS_KEY,
  FIRST_LOGIN_KEY,
} from '../../../global/constants';
import { useAuth } from '../../../global/stores/auth';
// eslint-disable-next-line import/no-cycle
import PageNotAuthorized from '../../../views/errorsPages/PageNotAuthorized';
import { getIsForbidden } from '../../../utils/general';

import classes from './styles.module.scss';

const { Item } = Breadcrumb;

const PageContent = ({ children, withBreadcrumb = true, styles = {} }) => {
  const location = useLocation();
  const { pathname, search, state } = location;
  const { t } = useTranslation();

  const {
    isAuthenticated,
    user,
    login: { loginFn },
  } = useAuth();

  const [breadcrumbsDetails, setBreadcrumbsDetails] = useState(
    JSON.parse(localStorage.getItem(BREADCRUMBS_DETAILS_KEY))
  );
  const dataFromFirstLogin = JSON.parse(localStorage.getItem(FIRST_LOGIN_KEY));

  const isForbidden = getIsForbidden(user?.role, pathname);

  useEffect(() => {
    if (user && !user?.lastName && dataFromFirstLogin) {
      const { userInfo, userCredentials } = dataFromFirstLogin;
      loginFn({ email: userCredentials.email, password: userInfo.password });
      localStorage.removeItem(FIRST_LOGIN_KEY);
    }
  }, [user]);

  useEffect(() => {
    const updatedBreadcrumbsDetails = {
      ...breadcrumbsDetails,
      [pathname]: {
        search,
        name: state?.breadcrumbsName,
      },
    };

    localStorage.setItem(
      BREADCRUMBS_DETAILS_KEY,
      JSON.stringify(updatedBreadcrumbsDetails)
    );
    setBreadcrumbsDetails(updatedBreadcrumbsDetails);
  }, [pathname, search, state]);

  const pathSnippets = pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const result = _.split(/(?=[A-Z][a-z])/).join(' ');
    const re = /E Os/gi;
    const reEo = /EO/gi;
    const reSPROUT = /SPROUT/gi;
    const firstIntermediateResult = result.replace(reSPROUT, ' SPROUT');
    const secondIntermediateResult = firstIntermediateResult.replace(
      reEo,
      ' EO'
    );
    const finalString = secondIntermediateResult.replace(re, ' EOs');
    const name = breadcrumbsDetails[url]?.name || finalString;

    return (
      <Item key={url}>
        {index === pathSnippets.length - 1 ? (
          name
        ) : (
          <Link to={`${url}${breadcrumbsDetails[url]?.search || ''}`}>
            {name}
          </Link>
        )}
      </Item>
    );
  });

  const breadcrumbItems = [
    <Item key="home">
      <Link to="/">Home</Link>
    </Item>,
  ].concat(extraBreadcrumbItems);

  const isUserAuthenticated = isAuthenticated && Object.keys(user).length;
  const content = !isUserAuthenticated ? <PageNotAuthorized /> : children;

  return (
    <div className={classes.root} style={styles}>
      {(!isForbidden || !isUserAuthenticated) && withBreadcrumb && (
        <div className={classes.breadcrumb} id="breadcrumb">
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </div>
      )}
      {!isForbidden ? (
        content
      ) : (
        <>
          <Title className={classNames(classes.title)} level={3}>
            {t(`errorsPages:forbidden`)}
          </Title>
          <p className={classNames(classes.title)}>
            {t(`errorsPages:forbiddenDescription`)}
          </p>
        </>
      )}
    </div>
  );
};
export default PageContent;
