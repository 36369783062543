import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'state',
  'eooutsideDistributionTotal',
  'eoactive',
  'eoinactive',
  'eoinactiveSprout',
  'eohistorical',
  'eoextirpated',
];

const fetchOwnerType = () =>
  APIFetch(ENDPOINT_URLS.GET_EO_OUTSIDE_DISTRIBUTION, {
    method: 'get',
  });

const ElementOccurrenceOutsideDistribution = () => {
  const { data, loading } = useAPI(() => fetchOwnerType(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('state', data);

  return (
    <PageWithSimpleTable
      titleKey="StatusOfPlants:ElementOccurrenceOutsideDistribution"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};

export default ElementOccurrenceOutsideDistribution;
