import React from 'react';
import { useLocation } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import PageWithSimpleTable from '../../pages/PageWithSimpleTable';
import { highlightTotalValue, getIsForbidden } from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';

const fields = [
  'state',
  'totalOwnerContacts',
  'havePermission',
  'noNeedPermission',
  'surveyorPermission',
  'stayOffPermission',
  'deniedPermission',
  'notContactedPermission',
  'noResponsePermission',
];

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_SEED_COLL_OWNER_PERMISSION, {
    method: 'get',
  });

const SeedCollOwnerPermission = () => {
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading } = useAPI(fetch, {
    initialState: { data: [], loading: false },
    shouldFetch: !isForbidden,
  });

  const tableRows = highlightTotalValue('state', data);

  return (
    <PageWithSimpleTable
      titleKey="PCVAdministration:SeedCollOwnerPermission"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
      isWithoutRowCountLabel
    />
  );
};

export default SeedCollOwnerPermission;
