import React, { useState } from 'react';
import { Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';

import PageContent from '../../../../shared/components/PageContent';
import { SPROUTFieldFormDetailTable } from '../SPROUTFieldFormDetailTable';

import classes from '../../../styles.module.scss';

export const SPROUTFieldFormDetail = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const SPROUTFieldFormDetailKey = 'SPROUTFieldFormDetail';

  const [keyTitle, setKeyTitle] = useState(SPROUTFieldFormDetailKey);

  return (
    <PageContent>
      <div>
        <Title className={classNames(classes.title)} level={3}>
          {t(`FieldDataSPROUT:${keyTitle}`)}
        </Title>
        <p className={classNames(classes.title)}>
          {t(`FieldDataSPROUT:${keyTitle}Description`)}
        </p>
      </div>
      <div className={classNames(classes.title)}>
        <Tabs
          defaultActiveKey={SPROUTFieldFormDetailKey}
          onChange={(key) => setKeyTitle(key)}
        >
          <TabPane
            tab={t('FieldDataSPROUT:SPROUTFieldFormDetail')}
            key={SPROUTFieldFormDetailKey}
          >
            <SPROUTFieldFormDetailTable />
          </TabPane>
          {/* Maybe will need in future! */}
          {/* <TabPane */}
          {/*  tab={t('FieldDataSPROUT:SPROUTFieldFormPhenology')} */}
          {/*  key={SPROUTFieldFormPhenologyKey} */}
          {/* > */}
          {/*  <SPROUTFieldFormPhenology /> */}
          {/* </TabPane> */}
          {/* <TabPane */}
          {/*  tab={t('FieldDataSPROUT:SPROUTFieldFormAssociatedSpecies')} */}
          {/*  key={SPROUTFieldFormAssociatedSpeciesKey} */}
          {/* > */}
          {/*  <SPROUTFieldFormAssociatedSpecies /> */}
          {/* </TabPane> */}
          {/* <TabPane */}
          {/*  tab={t('FieldDataSPROUT:SPROUTFieldFormInvasiveSpecies')} */}
          {/*  key={SPROUTFieldFormInvasiveSpeciesKey} */}
          {/* > */}
          {/*  <SPROUTFieldFormInvasiveSpecies /> */}
          {/* </TabPane> */}
          {/* <TabPane */}
          {/*  tab={t('FieldDataSPROUT:SPROUTFieldFormAge')} */}
          {/*  key={SPROUTFieldFormAgeKey} */}
          {/* > */}
          {/*  <SPROUTFieldFormAge /> */}
          {/* </TabPane> */}
        </Tabs>
      </div>
    </PageContent>
  );
};
