import { Config } from '../config';

const {
  api: { root: apiRoot },
} = Config;

const account = `Account`;
const surveyInformation = `SurveyInformation`;
const elementOccurrenceData = 'ElementOccurrenceData';
const seedBankData = 'SeedBankData';
const validationData = 'ValidationData';
const surveyAdministration = 'SurveyAdministration';
const surveyor = 'Surveyors';
const fieldData = 'FieldData';
const dropdownLists = 'DropdownLists';
const speciesStatus = 'SpeciesStatus';
const goBotanyData = 'GoBotanyData';
const populationCondition = 'PopulationCondition';
const distributionData = 'DistributionData';
const fieldFormSubmission = 'FieldFormSubmission';
const species = 'SpeciesStatus';
const tracheophyteChecklist = 'TracheophyteChecklist';
const owners = 'Owners';
const fieldFormReview = 'FieldFormReview';
const backgroundActivities = 'BackgroundActivities';
const surveyPlansResults = 'SurveyPlansResults';
const eoPopulationSizeAndStatus = 'EoPopulationSizeAndStatus';
const backgroundInformation = 'BackgroundInformation';
const fileAndEmailManagement = 'FileAndEmailManagement';
const accountSettings = 'AccountSettings';
const announcements = 'Announcements';
const priorYearFieldDataEntry = 'PriorYearFieldDataEntry';
const xlsx = 'Xlsx';
const summary = 'Summary';
const naturalCommunitiesAndAssociatedSpecies =
  'NaturalCommunitiesAndAssociatedSpecies';

export const ENDPOINT_URLS = {
  CHECK_SECURITY: `${apiRoot}${account}/CheckSecurity`,

  AUTH_SIGN_IN: `${apiRoot}${account}/SignIn`,
  AUTH_SIGN_UP: `${apiRoot}${account}/SignUp`,
  AUTH_SIGN_OUT: `${apiRoot}${account}/SignOut`,
  ACCEPT_AGREEMENT: `${apiRoot}${account}/AcceptTermsConditionsAgreement`,
  REFRESH_TOKEN: `${apiRoot}${account}/RefreshToken`,
  FORGOT_PASSWORD: `${apiRoot}${account}/ForgotPassword`,
  RESET_PASSWORD: `${apiRoot}${account}/ResetPassword`,

  CHANGE_PASSWORD: `${apiRoot}${accountSettings}/ChangePassword`,
  CHANGE_ACCOUNT_SETTINGS: `${apiRoot}${accountSettings}/ChangeAccountSettings`,

  GET_ANNOUNCEMENTS_PCVTF: `${apiRoot}${announcements}/GetAnnouncements`,

  GET_ASSOCIATED_SPECIES_MANUAL_PAGED: `${apiRoot}${naturalCommunitiesAndAssociatedSpecies}/GetAssociatedSpeciesManualPaged`,
  GET_ASSOCIATED_SPECIES_SPROUT_PAGED: `${apiRoot}${naturalCommunitiesAndAssociatedSpecies}/GetAssociatedSpeciesSproutPaged`,
  GET_INVASIVE_SPECIES_MANUAL_PAGED: `${apiRoot}${naturalCommunitiesAndAssociatedSpecies}/GetInvasiveSpeciesManualPaged`,
  GET_INVASIVE_SPECIES_SPROUT_PAGED: `${apiRoot}${naturalCommunitiesAndAssociatedSpecies}/GetInvasiveSpeciesSproutPaged`,
  GET_NATURAL_COMMUNITIES_SPECIES_PAGED: `${apiRoot}${naturalCommunitiesAndAssociatedSpecies}/GetNaturalCommunitiesSpeciesPaged`,
  GET_NATURAL_COMMUNITIES_EOS_PAGED: `${apiRoot}${naturalCommunitiesAndAssociatedSpecies}/GetNaturalCommunitiesEosPaged`,

  GET_SURVEY_RESULTS_STATES_PCVTF: `${apiRoot}${dropdownLists}/GetStatesForTfSurvey`,
  GET_PHENOLOGY_TYPE: `${apiRoot}${dropdownLists}/GetPhenologyTypes`,
  GET_DAYS_AVAILABLE_TO_SURVEY: `${apiRoot}${dropdownLists}/GetDaysAvailableToSurvey`,
  GET_STATE: `${apiRoot}${dropdownLists}/GetStates`,
  GET_STATE_FOR_SURVEYOR: `${apiRoot}${dropdownLists}/GetStatesForSurveyor`,
  GET_STATE_FOR_HERITAGE: `${apiRoot}${dropdownLists}/GetStatesForHeritage`,
  GET_SOURCES: `${apiRoot}${dropdownLists}/GetSources`,
  GET_FIELD_DATA_TYPES: `${apiRoot}${dropdownLists}/GetFieldDataTypes`,
  GET_EO_STATUS: `${apiRoot}${dropdownLists}/GetEOStatuses`,
  GET_DIVISION: `${apiRoot}${dropdownLists}/GetDivisions`,
  GET_SURVEY_RESULTS: `${apiRoot}${dropdownLists}/GetSurveyResults`,
  GET_OUTREACH_ORGANIZATION_TYPES: `${apiRoot}${dropdownLists}/GetOutreachOrganizationTypes`,
  GET_TOPO_POSITIONS: `${apiRoot}${dropdownLists}/GetTopoPositions`,
  GET_COUNT_PRECISIONS: `${apiRoot}${dropdownLists}/GetCountPrecisions`,
  GET_COUNT_PRECISIONS_EXCEPT_NONE: `${apiRoot}${dropdownLists}/GetCountPrecisionsExceptNone`,
  GET_SLOPES: `${apiRoot}${dropdownLists}/GetSlopes`,
  GET_ROLES: `${apiRoot}${dropdownLists}/GetRoles`,
  GET_LOC_PRECISIONS: `${apiRoot}${dropdownLists}/GetLocationPrecisionsForFieldEnterPriorForm`,
  GET_ASPECTS: `${apiRoot}${dropdownLists}/GetAspects`,
  GET_LIGHTS: `${apiRoot}${dropdownLists}/GetLights`,
  GET_EXCITING_LIGHTS: `${apiRoot}${dropdownLists}/GetAllExistingLights`,
  GET_MOISTURE_REGIMES: `${apiRoot}${dropdownLists}/GetMoistureRegimes`,
  GET_EXCITING_MOISTURE_REGIMES: `${apiRoot}${dropdownLists}/GetAllExistingMoistureRegimes`,
  GET_TOPICS: `${apiRoot}${dropdownLists}/GetResearchTopics`,
  GET_SURVEYOR_TYPE: `${apiRoot}${dropdownLists}/GetSurveyorTypes`,
  GET_SURVEYOR_TYPE_EXCEPT: `${apiRoot}${dropdownLists}/GetSurveyorTypesExceptAdmin`,
  GET_SURVEYORS: `${apiRoot}${dropdownLists}/GetSurveyors`,
  GET_MANAGEMENT_TYPES: `${apiRoot}${dropdownLists}/GetMgtFieldDataTypes`,
  GET_NON_NO_THREAT_DISTURBANCES: `${apiRoot}${dropdownLists}/GetNonNoThreatDisturbances`,
  GET_NON_NONE_SURVEY_RESULTS: `${apiRoot}${dropdownLists}/GetNonNoneSurveyResults`,
  GET_OWNERS: `${apiRoot}${dropdownLists}/GetOwners`,
  GET_SOURCE_OWNERS: `${apiRoot}${dropdownLists}/GetSourceOwners`,
  GET_SPECIES_NAMES: `${apiRoot}${dropdownLists}/GetSpeciesNames`,
  GET_COUNTIES: `${apiRoot}${dropdownLists}/GetCounties`,
  GET_LOCATION_PRECISIONS: `${apiRoot}${dropdownLists}/GetLocationPrecisions`,
  GET_CHARACTER_GROUPS: `${apiRoot}${dropdownLists}/GetCharacterGroups`,
  GET_PERMIT_TYPES: `${apiRoot}${dropdownLists}/GetPermitTypes`,
  GET_NON_STAY_OFF_OWNER_PERMISSION: `${apiRoot}${dropdownLists}/GetNonStayOffOwnerPermissions`,
  GET_OWNER_PERMISSION: `${apiRoot}${dropdownLists}/GetOwnerPermissions`,
  GET_CONTACT_METHOD: `${apiRoot}${dropdownLists}/GetContactMethods`,
  GET_EOS: `${apiRoot}${dropdownLists}/GetEos`,
  GET_MGT_NOTES_AND_EO_DATA_SOURCES: `${apiRoot}${dropdownLists}/GetMgtNotesAndEODataSources`,
  GET_ALL_MGT_FIELD_DATA_TYPES: `${apiRoot}${dropdownLists}/GetAllMgtFieldDataTypes`,
  GET_ALL_OWNER_TYPES: `${apiRoot}${dropdownLists}/GetAllOwnerTypes`,
  GET_ALL_OWNER_STATUSES: `${apiRoot}${dropdownLists}/GetAllOwnerStatuses`,
  GET_ALL_REPORT_METHODS: `${apiRoot}${dropdownLists}/GetAllReportMethods`,
  GET_ALL_EO_FOR_STATE: (state) =>
    `${apiRoot}${dropdownLists}/GetAllElementOccurencesForState/state?state=${state}`,
  GET_SURVEYORS_TRANSCRIBERS: `${apiRoot}${dropdownLists}/GetSurveyorsTranscribers`,

  GET_EL_OCC_STATE_SOURCE: `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceStateSource`,
  GET_EL_OCC_STATE_DIV: `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceStateDivision`,
  GET_EL_OCC_STATE_STATUS: `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceStateStatus`,
  GET_EL_OCC_DIV_STATUS: `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceDivisionStatus`,
  GET_EL_OCC_DIV_STATE_STATUS: (state) =>
    `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceDivisionStateStatus/${state}`,
  GET_EL_OCC_STATE_OWNER: `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceStateOwner`,
  GET_EL_OCC_STATE_DATA_MISMATCH: `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceStateDataMismatch`,
  GET_SEED_BANK_DIV: `${apiRoot}${elementOccurrenceData}/GetSeedBankDivision`,
  GET_SEED_BANK_DIV_STATE: (state) =>
    `${apiRoot}${elementOccurrenceData}/GetSeedBankDivisionState/${state}`,
  GET_EOS_WITH_DATA_ERRORS_PAGED: `${apiRoot}${elementOccurrenceData}/GetEosWithDataErrorsPaged`,
  GET_EOS_WITHOUT_EO_NUMBER_PAGED: `${apiRoot}${elementOccurrenceData}/GetEosWithoutEoNumberPaged`,
  GET_COUNT_MANUAL_FIELD_FORM: `${apiRoot}${elementOccurrenceData}/GetCountManualFieldForm`,

  DELETE_LINKS_PCV_ADMIN: (key) =>
    `${apiRoot}${surveyAdministration}/DeleteLink/${key}`,
  GET_LINKS_PCV_ADMIN: `${apiRoot}${surveyAdministration}/GetAllLinks`,
  GET_ONE_LINK_PCV_ADMIN: (key) =>
    `${apiRoot}${surveyAdministration}/GetLinkInformation/${key}`,
  UPDATE_LINK_PCV_ADMIN: (key) =>
    `${apiRoot}${surveyAdministration}/UpdateLink/${key}`,
  CREATE_LINK_PCV_ADMIN: `${apiRoot}${surveyAdministration}/CreateLink`,
  GET_SURVEY_PLAN_PAGED: `${apiRoot}${surveyAdministration}/GetSurveyPlansPaged`,
  EDIT_SURVEY_PLAN_FIELDS: (key) =>
    `${apiRoot}${surveyAdministration}/UpdateSurveyPlan/${key}`,
  GET_SURVEY_SIGNUP_PAGED: `${apiRoot}${surveyAdministration}/GetSurveySignupPaged`,
  EDIT_SURVEY_SIGNUP_FIELDS: (key) =>
    `${apiRoot}${surveyAdministration}/UpdateSurveySignup/${key}`,
  GET_SURVEYOR_HISTORY: `${apiRoot}${surveyAdministration}/GetSurveyorHistoryPaged`,
  GET_PLAN_QA_SURVEY_10_YEAR: `${apiRoot}${surveyAdministration}/GetPlanQASurvey10YearPaged`,
  GET_PLAN_QA_SURVEY_15_YEAR: `${apiRoot}${surveyAdministration}/GetPlanQASurvey15YearPaged`,
  GET_PLAN_QA_SURVEY_INACTIVE: `${apiRoot}${surveyAdministration}/GetPlanQASurveyInactivePaged`,
  GET_PLAN_QA_SURVEY_NO_STATUS: `${apiRoot}${surveyAdministration}/GetPlanQASurveyNoStatusPaged`,
  GET_PLAN_QA_SEED_COLL_NO: `${apiRoot}${surveyAdministration}/GetPlanQASeedCollNoPaged`,
  GET_PLAN_QA_SURVEY_SEED_YEAR_SYNC: `${apiRoot}${surveyAdministration}/GetPlanQASurveySeedYearSyncPaged`,
  GET_PLAN_QA_SEED_YEAR_INTERVAL: `${apiRoot}${surveyAdministration}/GetPlanQASeedYearIntervalPaged`,
  GET_PLAN_QA_SURVEY_YEAR_INTERVAL: `${apiRoot}${surveyAdministration}/GetPlanQASurveyYearIntervalPaged`,
  GET_PLAN_QA_SEED_COLL_YES: `${apiRoot}${surveyAdministration}/GetPlanQASeedCollYesPaged`,
  GET_PLAN_QA_SURVEY_IF_SEED: `${apiRoot}${surveyAdministration}/GetPlanQASurveyIfSeedPaged`,
  UPDATE_SURVEY_PLAN_QA: (eokey) =>
    `${apiRoot}${surveyAdministration}/UpdateSurveyPlanQA/${eokey}`,
  GET_PERMITS_PAGED: `${apiRoot}${surveyAdministration}/GetPermitsPaged`,
  UPDATE_PERMIT: (key) =>
    `${apiRoot}${surveyAdministration}/UpdatePermit/${key}`,
  GET_EO_PERMISSION_PAGED: (key) =>
    `${apiRoot}${surveyAdministration}/GetEOPermissionsPaged/${key}`,
  UPDATE_EO_PERMISSION: (eokey, ownerKey) =>
    `${apiRoot}${surveyAdministration}/UpdateEOPermission/${eokey}/${ownerKey}`,
  GET_SURVEYOR_ASSIGNMENT: `${apiRoot}${surveyAdministration}/GetSurveyorAssignmentPaged`,
  GET_SURVEYOR_ASSIGNMENT_DETAILS: (key) =>
    `${apiRoot}${surveyAdministration}/GetSurveyorAssignmentDetails/${key}`,
  GET_SEED_PLAN_FUTURE_SPECIES: (key) =>
    `${apiRoot}${surveyAdministration}/GetSeedPlanFutureSpecies/${key}`,
  GET_SURVEY_PLAN_FUTURE_SPECIES: (key) =>
    `${apiRoot}${surveyAdministration}/GetSurveyPlanFutureSpecies/${key}`,
  GET_SURVEY_PLAN_STATUS: `${apiRoot}${surveyAdministration}/GetSurveyPlanStatus`,
  GET_SEED_COLL_STATE_YEAR: `${apiRoot}${surveyAdministration}/GetSeedCollStateYear`,
  GET_PAST_YEAR_PERMITS_PAGED: `${apiRoot}${surveyAdministration}/GetPastYearsPermitsPaged`,
  GET_CURRENT_YEAR_PERMITS_PAGED: `${apiRoot}${surveyAdministration}/GetCurrentYearsPermitsPaged`,
  GET_PERMISSIONS_BY_OWNER_PAGED: `${apiRoot}${surveyAdministration}/GetPermissionsByOwnerPaged`,
  GET_PERMISSIONS_BY_OWNER_DETAILS: (key) =>
    `${apiRoot}${surveyAdministration}/GetPermissionsByOwnerDetails/${key}`,
  UPDATE_PERMISSIONS_BY_OWNER_DETAILS: (key, ownerKey) =>
    `${apiRoot}${surveyAdministration}/UpdatePermissionsByOwnerDetails/${key}/${ownerKey}`,
  GET_PERMISSIONS_BY_EO_PAGED: `${apiRoot}${surveyAdministration}/GetPermissionsByEOPaged`,
  GET_PERMISSIONS_BY_EO_DETAILS: (key) =>
    `${apiRoot}${surveyAdministration}/GetPermissionByEODetailsPaged/${key}`,
  UPDATE_PERMISSIONS_BY_EO_DETAILS: (key, ownerKey) =>
    `${apiRoot}${surveyAdministration}/UpdatePermissionByEODetails/${key}/${ownerKey}`,
  DELETE_PERMIT_DOC: (ownerKey, permitFileNumber) =>
    `${apiRoot}${surveyAdministration}/DeletePermitDocument/${ownerKey}/${permitFileNumber}`,
  GET_NPT_NOTES: (key) =>
    `${apiRoot}${surveyAdministration}/GetNptNotes/${key}`,

  EDIT_MULTI_SPECIES_SITES_FIELDS: (key) =>
    `${apiRoot}${elementOccurrenceData}/UpdateMultiSpeciesSite/${key}`,
  GET_EL_OCC: (key) =>
    `${apiRoot}${elementOccurrenceData}/GetElementOccurrence/${key}`,
  DELETE_ELEMENT_OCC: (key) =>
    `${apiRoot}${elementOccurrenceData}/DeleteElementOccurrence/${key}`,
  UPDATE_ELEMENT_OCC: (key) =>
    `${apiRoot}${elementOccurrenceData}/UpdateElementOccurrence/${key}`,
  GET_INACTIVE_EOS_WITH_OBSERVATION: `${apiRoot}${elementOccurrenceData}/GetInactiveEOsWithObservationsPaged`,
  GET_ACTIVE_EOS_WITH_CONFLICTING_FIELD_DATA: `${apiRoot}${elementOccurrenceData}/GetActiveEOsWithConflictingFieldDataPaged`,
  GET_MULTI_SPECIES_SITES: `${apiRoot}${elementOccurrenceData}/GetMultiSpeciesSitesPaged`,
  GET_ELEMENT_OCC_MULTI_SPECIES_SITES: (key) =>
    `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceMultispeciesSites/${key}`,
  GET_ELEMENT_OCC_PAGED: `${apiRoot}${elementOccurrenceData}/GetElementOccurrencesPaged`,
  DELETE_ELEMENT_OCCURENCE_DISPLAY_AND_EDIT: (key) =>
    `${apiRoot}${elementOccurrenceData}/DeleteElementOccurrence/${key}`,
  GET_ELEMENT_OCC: (eokey) =>
    `${apiRoot}${elementOccurrenceData}/GetElementOccurrence/${eokey}`,
  CREATE_ELEMENT_OCC: `${apiRoot}${elementOccurrenceData}/CreateElementOccurrence`,
  GET_ELEMENT_OCC_OWNER: (eokey, currentOwner) =>
    `${apiRoot}${elementOccurrenceData}/GetElementOccurrenceOwner/${eokey}/${currentOwner}`,
  CREATE_ELEMENT_OCC_OWNER: `${apiRoot}${elementOccurrenceData}/CreateElementOccurrenceOwner`,
  UPDATE_ELEMENT_OCC_OWNER: (eokey, ownerKey) =>
    `${apiRoot}${elementOccurrenceData}/UpdateElementOccurrenceOwner/${eokey}/${ownerKey}`,
  DELETE_ELEMENT_OCC_OWNER: (eokey, ownerKey) =>
    `${apiRoot}${elementOccurrenceData}/DeleteElementOccurrenceOwner/${eokey}/${ownerKey}`,
  GET_FIELD_DATA_SEED_BY_SPECIES: `${apiRoot}${seedBankData}/GetFieldDataSeedBySpeciesPaged`,
  GET_FIELD_DATA_CONSERVATION_SPECIES_NO_SEED: `${apiRoot}${seedBankData}/GetFieldDataConservationSpeciesNoSeedPaged`,
  GET_SEED_COLLECTION_EOS_PAGED: `${apiRoot}${seedBankData}/GetSeedCollectionEOsPaged`,
  GET_FIELD_DATA_SEED: (eokey) =>
    `${apiRoot}${seedBankData}/GetFieldDataSeed/${eokey}`,
  GET_FIELD_DATA_SEED_PAGED: `${apiRoot}${seedBankData}/GetFieldDataSeedPaged`,

  GET_HERBARIUM_RECOVERY: `${apiRoot}HerbariumRecoveryProject/GetHerbariumRecoveryPaged`,

  GET_FIELD_DATA_SUMMARY_WITH_FILES_DETAILS: (eokey) =>
    `${apiRoot}${fieldData}/GetFieldDataSummaryWithFilesDetails/${eokey}`,
  GET_MANUAL_FIELD_FORM: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataManualPaged/${key}`,
  DELETE_FILE: (key) => `${apiRoot}${fieldData}/DeleteFieldDataEdit/${key}`,
  GET_FIELD_MANUAL_INVASIVE_SPECIES: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataManualInvasiveSpecies/${key}`,
  GET_FIELD_MANUAL_ASSOCIATED_SPECIES: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataManualAssociatedSpecies/${key}`,
  GET_FIELD_MANUAL_DETAILS: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataManualDetails/${key}`,
  GET_FILED_DATA_SPROUT: `${apiRoot}${fieldData}/GetFieldDataSproutElementOccurrencesPaged`,
  GET_FILED_DATA_SPROUT_PAGED: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataSproutPaged/${key}`,
  GET_FIELD_SPROUT_PHENOLOGY: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataSproutPhenology/${key}`,
  GET_FIELD_SPROUT_INVASIVE_SPECIES: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataSproutInvasiveSpecies/${key}`,
  GET_FIELD_SPROUT_AGE: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataSproutAge/${key}`,
  GET_FIELD_SPROUT_ASSOCIATED_SPECIES: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataSproutAssociatedSpecies/${key}`,
  GET_FIELD_SPROUT_DETAILS: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataSproutDetails/${key}`,
  GET_FIELD_DATA_MANUAL_PAGED: `${apiRoot}${fieldData}/GetFieldDataManualPaged`,
  GET_FILED_DATA_EDIT_PAGED: (key) =>
    `${apiRoot}${fieldData}/GetFieldDataEditPaged/${key}`,
  UPDATE_FILED_DATA_EDIT: (key) =>
    `${apiRoot}${fieldData}/UpdateFieldDataEdit/${key}`,
  DELETE_FILED_DATA_EDIT: (key) =>
    `${apiRoot}${fieldData}/DeleteFieldDataEdit/${key}`,
  CREATE_FILED_DATA_EDIT: `${apiRoot}${fieldData}/CreateFieldDataEdit`,
  GET_EOS_FOR_FILED_DATA_EDIT_PAGED: (key) =>
    `${apiRoot}${fieldData}/GetEOsDetailsForFieldDataEdit/${key}`,
  GET_FILED_DATA_MANUAL_DETAILS: (key) =>
    `${apiRoot}${priorYearFieldDataEntry}/GetFieldDataManualDetails/${key}`,

  CREATE_FILED_DATA_MANUAL_ENTER: `${apiRoot}${priorYearFieldDataEntry}/CreateFieldDataManualEnter`,
  CREATE_FILED_DATA_PCV_ADMIN: `${apiRoot}${fieldFormReview}/CreateFieldFormEnterPrior`,

  GET_COUNTY: `${apiRoot}${validationData}/GetCounties`,
  GET_VALIDATION_DATA_STATES: `${apiRoot}${validationData}/GetStates`,
  GET_SURVEYOR_TYPE_DATA: `${apiRoot}${validationData}/GetSurveyorTypePaged`,
  GET_OWNER_STATUS_PAGED: `${apiRoot}${validationData}/GetOwnerStatusPaged`,
  CREATE_OWNER_STATUS: `${apiRoot}${validationData}/CreateOwnerStatus`,
  UPDATE_OWNER_STATUS: (key) =>
    `${apiRoot}${validationData}/UpdateOwnerStatus/${key}`,
  DELETE_OWNER_STATUS: (key) =>
    `${apiRoot}${validationData}/DeleteOwnerStatus/${key}`,
  GET_OWNER_TYPE_PAGED: `${apiRoot}${validationData}/GetOwnerTypePaged`,
  CREATE_OWNER_TYPE: `${apiRoot}${validationData}/CreateOwnerType`,
  UPDATE_OWNER_TYPE: (key) =>
    `${apiRoot}${validationData}/UpdateOwnerType/${key}`,
  DELETE_OWNER_TYPE: (key) =>
    `${apiRoot}${validationData}/DeleteOwnerType/${key}`,
  GET_PERMIT_TYPE_PAGED: `${apiRoot}${validationData}/GetPermitTypePaged`,
  CREATE_PERMIT_TYPE: `${apiRoot}${validationData}/CreatePermitType`,
  UPDATE_PERMIT_TYPE: (key) =>
    `${apiRoot}${validationData}/UpdatePermitType/${key}`,
  DELETE_PERMIT_TYPE: (key) =>
    `${apiRoot}${validationData}/DeletePermitType/${key}`,
  GET_PHENOLOGY_PAGED: `${apiRoot}${validationData}/GetPhenologyPaged`,
  CREATE_PHENOLOGY: `${apiRoot}${validationData}/CreatePhenology`,
  UPDATE_PHENOLOGY: (key) =>
    `${apiRoot}${validationData}/UpdatePhenology/${key}`,
  DELETE_PHENOLOGY: (key) =>
    `${apiRoot}${validationData}/DeletePhenology/${key}`,
  GET_PHENOLOGY_TYPE_PAGED: `${apiRoot}${validationData}/GetPhenologyTypePaged`,
  CREATE_PHENOLOGY_TYPE: `${apiRoot}${validationData}/CreatePhenologyType`,
  UPDATE_PHENOLOGY_TYPE: (key) =>
    `${apiRoot}${validationData}/UpdatePhenologyType/${key}`,
  DELETE_PHENOLOGY_TYPE: (key) =>
    `${apiRoot}${validationData}/DeletePhenologyType/${key}`,
  GET_REPORT_METHOD_PAGED: `${apiRoot}${validationData}/GetReportMethodPaged`,
  CREATE_REPORT_METHOD: `${apiRoot}${validationData}/CreateReportMethod`,
  UPDATE_REPORT_METHOD: (key) =>
    `${apiRoot}${validationData}/UpdateReportMethod/${key}`,
  DELETE_REPORT_METHOD: (key) =>
    `${apiRoot}${validationData}/DeleteReportMethod/${key}`,
  GET_SLOPE_PAGED: `${apiRoot}${validationData}/GetSlopePaged`,
  CREATE_SLOPE: `${apiRoot}${validationData}/CreateSlope`,
  UPDATE_SLOPE: `${apiRoot}${validationData}/UpdateSlope`,
  DELETE_SLOPE: `${apiRoot}${validationData}/DeleteSlope`,
  GET_RESEARCH_TOPIC_PAGED: `${apiRoot}${validationData}/GetResearchTopicPaged`,
  CREATE_RESEARCH_TOPIC: `${apiRoot}${validationData}/CreateResearchTopic`,
  UPDATE_RESEARCH_TOPIC: (key) =>
    `${apiRoot}${validationData}/UpdateResearchTopic/${key}`,
  DELETE_RESEARCH_TOPIC: (key) =>
    `${apiRoot}${validationData}/DeleteResearchTopic/${key}`,
  GET_SOURCE_PAGED: `${apiRoot}${validationData}/GetSourcePaged`,
  CREATE_SOURCE: `${apiRoot}${validationData}/CreateSource`,
  UPDATE_SOURCE: (key) => `${apiRoot}${validationData}/UpdateSource/${key}`,
  DELETE_SOURCE: (key) => `${apiRoot}${validationData}/DeleteSource/${key}`,
  GET_SOURCE_OWNER_PAGED: `${apiRoot}${validationData}/GetSourceOwnerPaged`,
  CREATE_SOURCE_OWNER: `${apiRoot}${validationData}/CreateSourceOwner`,
  UPDATE_SOURCE_OWNER: (key) =>
    `${apiRoot}${validationData}/UpdateSourceOwner/${key}`,
  DELETE_SOURCE_OWNER: (key) =>
    `${apiRoot}${validationData}/DeleteSourceOwner/${key}`,
  GET_SRANK_PAGED: `${apiRoot}${validationData}/GetSrankPaged`,
  CREATE_SRANK: `${apiRoot}${validationData}/CreateSrank`,
  UPDATE_SRANK: (key) => `${apiRoot}${validationData}/UpdateSrank/${key}`,
  DELETE_SRANK: (key) => `${apiRoot}${validationData}/DeleteSrank/${key}`,
  GET_ATTACHMENT_TYPE_PAGED: `${apiRoot}${validationData}/GetAttachmentTypePaged`,
  CREATE_ATTACHMENT_TYPE: `${apiRoot}${validationData}/CreateAttachmentType`,
  UPDATE_ATTACHMENT_TYPE: (key) =>
    `${apiRoot}${validationData}/UpdateAttachmentType/${key}`,
  DELETE_ATTACHMENT_TYPE: (key) =>
    `${apiRoot}${validationData}/DeleteAttachmentType/${key}`,
  GET_FIELD_DATA_TYPE_PAGED: `${apiRoot}${validationData}/GetFieldDataTypePaged`,
  CREATE_FIELD_DATA_TYPE: `${apiRoot}${validationData}/CreateFieldDataType`,
  UPDATE_FIELD_DATA_TYPE: (key) =>
    `${apiRoot}${validationData}/UpdateFieldDataType/${key}`,
  DELETE_FIELD_DATA_TYPE: (key) =>
    `${apiRoot}${validationData}/DeleteFieldDataType/${key}`,
  GET_EO_STATUS_PAGED: `${apiRoot}${validationData}/GetEoStatusPaged`,
  CREATE_EO_STATUS: `${apiRoot}${validationData}/CreateEoStatus`,
  UPDATE_EO_STATUS: (key) =>
    `${apiRoot}${validationData}/UpdateEoStatus/${key}`,
  DELETE_EO_STATUS: (key) =>
    `${apiRoot}${validationData}/DeleteEoStatus/${key}`,
  GET_ENDANGERMENT_CODE_PAGED: `${apiRoot}${validationData}/GetEndangermentCodePaged`,
  CREATE_ENDANGERMENT_CODE: `${apiRoot}${validationData}/CreateEndangermentCode`,
  UPDATE_ENDANGERMENT_CODE: (key) =>
    `${apiRoot}${validationData}/UpdateEndangermentCode/${key}`,
  DELETE_ENDANGERMENT_CODE: (key) =>
    `${apiRoot}${validationData}/DeleteEndangermentCode/${key}`,
  GET_SURVEY_RESULT_PAGED: `${apiRoot}${validationData}/GetSurveyResultPaged`,
  CREATE_SURVEY_RESULT: `${apiRoot}${validationData}/CreateSurveyResult`,
  UPDATE_SURVEY_RESULT: (key) =>
    `${apiRoot}${validationData}/UpdateSurveyResult/${key}`,
  DELETE_SURVEY_RESULT: (key) =>
    `${apiRoot}${validationData}/DeleteSurveyResult/${key}`,
  GET_TOPO_POSITION_PAGED: `${apiRoot}${validationData}/GetTopoPositionPaged`,
  CREATE_TOPO_POSITION: `${apiRoot}${validationData}/CreateTopoPosition`,
  UPDATE_TOPO_POSITION: (key) =>
    `${apiRoot}${validationData}/UpdateTopoPosition/${key}`,
  DELETE_TOPO_POSITION: (key) =>
    `${apiRoot}${validationData}/DeleteTopoPosition/${key}`,
  GET_CONTACT_METHOD_PAGED: `${apiRoot}${validationData}/GetContactMethodPaged`,
  CREATE_CONTACT_METHOD: `${apiRoot}${validationData}/CreateContactMethod`,
  UPDATE_CONTACT_METHOD: (key) =>
    `${apiRoot}${validationData}/UpdateContactMethod/${key}`,
  DELETE_CONTACT_METHOD: (key) =>
    `${apiRoot}${validationData}/DeleteContactMethod/${key}`,
  GET_COUNT_PRECISION_PAGED: `${apiRoot}${validationData}/GetCountPrecisionPaged`,
  CREATE_COUNT_PRECISION: `${apiRoot}${validationData}/CreateCountPrecision`,
  UPDATE_COUNT_PRECISION: (key) =>
    `${apiRoot}${validationData}/UpdateCountPrecision/${key}`,
  DELETE_COUNT_PRECISION: (key) =>
    `${apiRoot}${validationData}/DeleteCountPrecision/${key}`,
  GET_DISTURBANCES_PAGED: `${apiRoot}${validationData}/GetDisturbancesPaged`,
  CREATE_DISTURBANCE: `${apiRoot}${validationData}/CreateDisturbance`,
  UPDATE_DISTURBANCE: (key) =>
    `${apiRoot}${validationData}/UpdateDisturbance/${key}`,
  DELETE_DISTURBANCE: (key) =>
    `${apiRoot}${validationData}/DeleteDisturbance/${key}`,
  GET_DIVISION_PAGED: `${apiRoot}${validationData}/GetDivisionPaged`,
  CREATE_DIVISION_TYPE: `${apiRoot}${validationData}/CreateDivision`,
  UPDATE_DIVISION_TYPE: (key) =>
    `${apiRoot}${validationData}/UpdateDivision/${key}`,
  DELETE_DIVISION: (key) => `${apiRoot}${validationData}/DeleteDivision/${key}`,
  GET_AGE_PAGED: `${apiRoot}${validationData}/GetAgePaged`,
  CREATE_AGE: `${apiRoot}${validationData}/CreateAge`,
  UPDATE_AGE: (key) => `${apiRoot}${validationData}/UpdateAge/${key}`,
  DELETE_AGE: (key) => `${apiRoot}${validationData}/DeleteAge/${key}`,
  GET_ASPECT_PAGED: `${apiRoot}${validationData}/GetAspectPaged`,
  CREATE_ASPECT: `${apiRoot}${validationData}/CreateAspect`,
  UPDATE_ASPECT: (key) => `${apiRoot}${validationData}/UpdateAspect/${key}`,
  DELETE_ASPECT: (key) => `${apiRoot}${validationData}/DeleteAspect/${key}`,
  GET_LIGHT_PAGED: `${apiRoot}${validationData}/GetLightPaged`,
  CREATE_LIGHT: `${apiRoot}${validationData}/CreateLight`,
  UPDATE_LIGHT: (key) => `${apiRoot}${validationData}/UpdateLight/${key}`,
  DELETE_LIGHT: (key) => `${apiRoot}${validationData}/DeleteLight/${key}`,
  GET_LOCATION_PRECISION_PAGED: `${apiRoot}${validationData}/GetLocationPrecisionPaged`,
  CREATE_LOCATION_PRECISION: `${apiRoot}${validationData}/CreateLocationPrecision`,
  UPDATE_LOCATION_PRECISION: (key) =>
    `${apiRoot}${validationData}/UpdateLocationPrecision/${key}`,
  DELETE_LOCATION_PRECISION: (key) =>
    `${apiRoot}${validationData}/DeleteLocationPrecision/${key}`,
  GET_MOISTURE_REGIME_PAGED: `${apiRoot}${validationData}/GetMoistureRegimePaged`,
  CREATE_MOISTURE_REGIME: `${apiRoot}${validationData}/CreateMoistureRegime`,
  UPDATE_MOISTURE_REGIME: (key) =>
    `${apiRoot}${validationData}/UpdateMoistureRegime/${key}`,
  DELETE_MOISTURE_REGIME: (key) =>
    `${apiRoot}${validationData}/DeleteMoistureRegime/${key}`,
  GET_OUT_REACH_ORGANIZATION_TYPE_PAGED: `${apiRoot}${validationData}/GetOutreachOrganizationTypePaged`,
  CREATE_OUT_REACH_ORGANIZATION_TYPE: `${apiRoot}${validationData}/CreateOutreachOrganizationType`,
  UPDATE_OUT_REACH_ORGANIZATION_TYPE: (key) =>
    `${apiRoot}${validationData}/UpdateOutreachOrganizationType/${key}`,
  DELETE_OUT_REACH_ORGANIZATION_TYPE: (key) =>
    `${apiRoot}${validationData}/DeleteOutreachOrganizationType/${key}`,
  GET_OWNER_PERMISSION_PAGED: `${apiRoot}${validationData}/GetOwnerPermissionPaged`,
  CREATE_OWNER_PERMISSION: `${apiRoot}${validationData}/CreateOwnerPermission`,
  UPDATE_OWNER_PERMISSION: (key) =>
    `${apiRoot}${validationData}/UpdateOwnerPermission/${key}`,
  DELETE_OWNER_PERMISSION: (key) =>
    `${apiRoot}${validationData}/DeleteOwnerPermission/${key}`,

  GET_SURVEY_STATUS_DETAIL: `${apiRoot}${fieldFormReview}/GetSurveyStatusDetailPaged`,
  GET_SURVEY_RESULT_PRIOR_YEAR_PAGED: `${apiRoot}${fieldFormReview}/GetSurveyResultPriorYearPaged`,
  UPDATE_SURVEY_RESULT_PRIOR_YEAR: (eokey, year) =>
    `${apiRoot}${fieldFormReview}/UpdateSurveyResultPriorYear/${eokey}/${year}`,
  GET_SURVEY_STATE_PERMISSION: `${apiRoot}${fieldFormReview}/GetSurveyStatePermission`,
  GET_SURVEY_OWNER_PERMISSION: `${apiRoot}${fieldFormReview}/GetSurveyOwnerPermission`,
  GET_SURVEY_STATE: `${apiRoot}${fieldFormReview}/GetSurveyState`,
  GET_SEED_COLL_STATE: `${apiRoot}${fieldFormReview}/GetSeedCollState`,
  GET_SEED_COLL_STATE_PERMISSION: `${apiRoot}${fieldFormReview}/GetSeedCollStatePermission`,
  GET_SEED_COLL_OWNER_PERMISSION: `${apiRoot}${fieldFormReview}/GetSeedCollOwnerPermission`,
  GET_FIELD_FORM_STATE: `${apiRoot}${fieldFormReview}/GetFieldFormState`,
  GET_SURVEY_RESULT_CURRENT_YEAR: `${apiRoot}${fieldFormReview}/GetSurveyResultCurrentYear`,
  GET_SURVEY_RESULT_YEAR: `${apiRoot}${fieldFormReview}/GetSurveyResultYear`,
  GET_OWNER_REPORTED_PAGED: `${apiRoot}${fieldFormReview}/GetOwnerReportPaged`,
  GET_OWNER_REPORT_STATUS_STATE: `${apiRoot}${fieldFormReview}/GetReportStatusState`,
  GET_OWNER_REPORTED_DETAILS: (key) =>
    `${apiRoot}${fieldFormReview}/GetOwnerReportDetails/${key}`,
  UPDATE_DATE_REPORT: (key) =>
    `${apiRoot}${fieldFormReview}/UpdateDateReportSent/${key}`,
  GET_SURVEYS_WITH_FIELD_FORMS_PAGED: `${apiRoot}${fieldFormReview}/GetSurveysWithFieldFormsPaged`,
  GET_SURVEYS_WITHOUT_PERMISSION_PAGED: `${apiRoot}${fieldFormReview}/GetSurveysWithoutPermissionPaged`,
  GET_SURVEYS_WITHOUT_FIELD_FORMS_BUT_WITH_PERMISSION_PAGED: `${apiRoot}${fieldFormReview}/GetSurveysWithoutFieldFormsButWithPermissionPaged`,
  UPDATE_SURVEYS_WITHOUT_FIELD_FORMS_BUT_WITH_PERMISSION: (key) =>
    `${apiRoot}${fieldFormReview}/UpdateSurveysWithoutFieldFormsButWithPermission/${key}`,
  SEND_OWNER_EMAIL: (key) =>
    `${apiRoot}${fieldFormReview}/SendOwnerEmail/${key}`,
  SEND_OWNER_WITH_PROBLEMS_EMAIL: (key) =>
    `${apiRoot}${fieldFormReview}/SendOwnerWithProblemsEmail/${key}`,

  GET_OWNER_REPORT_WITH_PROBLEMS_PAGED: `${apiRoot}${fieldFormReview}/GetOwnerReportWithProblemPaged`,
  GET_OWNER_REPORT_WITH_PROBLEMS_DETAILS: (key) =>
    `${apiRoot}${fieldFormReview}/GetOwnerReportWithProblemDetails/${key}`,
  UPDATE_REPORT_WITH_PROBLEMS: (key) =>
    `${apiRoot}${fieldFormReview}/UpdateOwnerReportWithProblemDateReportSent/${key}`,

  GET_FIELD_FORM_REVIEW_NO_EO_PAGED: `${apiRoot}${fieldFormReview}/GetFieldFormReviewNoEoPaged`,
  GET_FIELD_FORM_REVIEW_NO_EO_DETAILS: (key) =>
    `${apiRoot}${fieldFormReview}/GetFieldFormReviewNoEoDetails/${key}`,
  CREATE_FIELD_FORM_REVIEW_NO_EO: `${apiRoot}${fieldFormReview}/CreateFieldFormReviewNoEo`,
  UPDATE_FIELD_FORM_REVIEW_NO_EO: (key) =>
    `${apiRoot}${fieldFormReview}/UpdateFieldFormReviewNoEo/${key}`,
  DELETE_FIELD_FORM_REVIEW_NO_EO: (key) =>
    `${apiRoot}${fieldFormReview}/DeleteFieldFormReviewNoEo/${key}`,

  GET_FIELD_DATA_MANUAL_EDIT_PAGED: `${apiRoot}${priorYearFieldDataEntry}/GetFieldDataManualEditPaged`,
  GET_FIELD_DATA_MANUAL_EDIT: (key) =>
    `${apiRoot}${priorYearFieldDataEntry}/GetSurveyAssignmentFieldDataSproutDetails/${key}`,
  UPDATE_FIELD_DATA_MANUAL_EDIT: (key) =>
    `${apiRoot}${priorYearFieldDataEntry}/UpdateManualFieldForm/${key}`,
  DELETE_FIELD_DATA_MANUAL_EDIT: (key) =>
    `${apiRoot}${priorYearFieldDataEntry}/DeleteManualFieldForm/${key}`,
  GET_FIELD_DATA_MANUAL_EDIT_DETAILS: (key) =>
    `${apiRoot}${priorYearFieldDataEntry}/GetFieldDataManualEditDetails/${key}`,
  UPDATE_TRANSCRIPTION_STATUS: (key) =>
    `${apiRoot}${priorYearFieldDataEntry}/UpdateTranscriptionStatus/${key}`,

  GET_FIELD_DATA_MANUAL: `${apiRoot}${priorYearFieldDataEntry}/GetFieldDataManualPaged`,

  GET_FIELD_FORM_EDIT_PRIOR_PAGED: `${apiRoot}${fieldFormReview}/GetFieldFormEditPriorPaged`,
  GET_FIELD_FORM_EDIT_PRIOR_DETAILS: (key) =>
    `${apiRoot}${fieldFormReview}/GetFieldFormEditPriorDetails/${key}`,
  CREATE_FIELD_FORM_EDIT_PRIOR: `${apiRoot}${fieldFormReview}/CreateFieldFormEditPrior`,
  UPDATE_FIELD_FORM_EDIT_PRIOR: (key) =>
    `${apiRoot}${fieldFormReview}/UpdateFieldFormEditPrior/${key}`,
  DELETE_FIELD_FORM_EDIT_PRIOR: (key) =>
    `${apiRoot}${fieldFormReview}/DeleteFieldFormEditPrior/${key}`,

  GET_FIELD_FORM_REVIEW_PAGED: `${apiRoot}${fieldFormReview}/GetFieldFormReviewPaged`,
  GET_FIELD_FORM_REVIEW_DETAILS: (key) =>
    `${apiRoot}${fieldFormReview}/GetFieldFormReviewDetails/${key}`,
  CREATE_FIELD_FORM_REVIEW: `${apiRoot}${fieldFormReview}/CreateFieldFormReview`,
  UPDATE_FIELD_FORM_REVIEW: (key) =>
    `${apiRoot}${fieldFormReview}/UpdateFieldFormReview/${key}`,
  DELETE_FIELD_FORM_REVIEW: (key) =>
    `${apiRoot}${fieldFormReview}/DeleteFieldFormReview/${key}`,

  SURVEYOR_BY_DISTANCE: `${apiRoot}${surveyor}/GetSurveyorByDistancePaged`,
  SURVEYOR_STATE_STATUS: `${apiRoot}${surveyor}/GetSurveyorStateStatus`,
  SURVEYOR_TYPE_STATE_STATUS: `${apiRoot}${surveyor}/GetSurveyorTypeStateStatus`,
  GET_SURVEYORS_NO_STATE: `${apiRoot}${surveyor}/GetSurveyorsNoStatePaged`,
  GET_SURVEYORS_STATE_PAGED: `${apiRoot}${surveyor}/GetSurveyorsStatePaged`,
  GET_SURVEYORS_STATE_DETAILS: (key) =>
    `${apiRoot}${surveyor}/GetSurveyStateDetails/${key}`,
  CREATE_SURVEYOR_STATE: `${apiRoot}${surveyor}/CreateSurveyorState`,
  DELETE_SOURCE_OWNER_SURVEYORS: (key, state) =>
    `${apiRoot}${surveyor}/DeleteSourceOwner/${key}/${state}`,

  GET_SURVEYORS_PAGED: `${apiRoot}${surveyor}/GetSurveyorsPaged`,
  GET_SURVEYOR_DISPLAY_AND_EDIT_DETAILS: (key) =>
    `${apiRoot}${surveyor}/GetSurveyorDisplayAndEditDetails/${key}`,
  CREATE_SURVEYOR: `${apiRoot}${surveyor}/CreateSurveyor`,
  UPDATE_SURVEYOR: (key) => `${apiRoot}${surveyor}/UpdateSurveyor/${key}`,
  DELETE_SURVEYOR: (key) => `${apiRoot}${surveyor}/DeleteSurveyor/${key}`,

  GET_RESEARCH_QUESTIONS_BY_SPECIES_PAGED: `${apiRoot}${speciesStatus}/GetResearchQuestionsBySpeciesPaged`,
  GET_RESEARCH_QUESTION_BY_SPECIES_EOS: (questionNbr) =>
    `${apiRoot}${speciesStatus}/GetResearchQuestionBySpeciesEOs/${questionNbr}`,
  GET_RESEARCH_QUESTIONS_BY_TOPIC_PAGED: `${apiRoot}${speciesStatus}/GetResearchQuestionsByTopicPaged`,

  GET_MANAGEMENT_NOTES_PAGED: `${apiRoot}${populationCondition}/GetManagementNotesPaged`,
  GET_DATA_FROM_NORM: (eokey) =>
    `${apiRoot}${populationCondition}/GetDataFromNorm/${eokey}`,
  GET_MANAGEMENT_NOTES_SPROUT_DETAILS: (eokey) =>
    `${apiRoot}${populationCondition}/GetManagementNotesSproutDetails/${eokey}`,
  GET_MANAGEMENT_NOTES_SPROUT_PAGED: `${apiRoot}${populationCondition}/GetManagementNotesSproutPaged`,
  GET_MGT_NOTES_EDIT_PAGED: `${apiRoot}${populationCondition}/GetMgtNotesEditPaged`,
  GET_MGT_NOTES_EDIT_DETAILS: (key) =>
    `${apiRoot}${populationCondition}/GetMgtNotesEditDetails/${key}`,
  CREATE_MGT_NOTE: `${apiRoot}${populationCondition}/CreateMgtNote`,
  UPDATE_MGT_NOTE: (key) =>
    `${apiRoot}${populationCondition}/UpdateMgtNote/${key}`,
  DELETE_MGT_NOTE: (key) =>
    `${apiRoot}${populationCondition}/DeleteMgtNote/${key}`,

  GET_CHECKLIST_DISTRIBUTION_PAGED: `${apiRoot}${distributionData}/GetChecklistDistributionPaged`,
  GET_TRACHEOPHYTE_CHECKLIST_DISTRIBUTION: (speciesKey) =>
    `${apiRoot}${distributionData}/GetTracheophyteChecklistDistribution/${speciesKey}`,
  GET_EOS_OUTSIDE_DISTRIBUTION_PAGED: `${apiRoot}${distributionData}/GetEOsOutsideDistributionPaged`,
  GET_DISTRIBUTION_WITHOUT_EOS_PAGED: `${apiRoot}${distributionData}/GetDistributionWithoutEOsPaged`,
  GET_DISTRIBUTION_YEAR_PAGED: `${apiRoot}${distributionData}/GetDistributionYearPaged`,
  GET_EO_MAXIMUM_EXTENT_PAGED: `${apiRoot}${distributionData}/GetEoMaximumExtentPaged`,
  GET_EO_CURRENT_EXTENT_PAGED: `${apiRoot}${distributionData}/GetEoCurrentExtentPaged`,
  GET_SRANK_NUMBER_EOS_PAGED: `${apiRoot}${distributionData}/GetSRankNumberEOsPaged`,

  GET_POPULATION_SIZE_PAGED: `${apiRoot}${eoPopulationSizeAndStatus}/GetPopulationSizePaged`,
  GET_SPECIES_STATUS_PAGED: `${apiRoot}${eoPopulationSizeAndStatus}/GetSpeciesStatusPaged`,
  GET_DISTRIBUTION_ALL_SOURCES_PAGED: `${apiRoot}${distributionData}/GetDistributionAllSourcesPaged`,
  GET_DISTRIBUTION_ALL_SOURCES_DETAILS_PAGED: `${apiRoot}${distributionData}/GetDistributionAllSourcesDetails`,
  GET_ALL_SPECIES: `${apiRoot}${distributionData}/GetAllSpecies`,
  GET_EOS_COORDINATES_BY_SPECIES_KEY: (key) =>
    `${apiRoot}${distributionData}/GetElementOccurrencesCoordinates/speciesKey?speciesKey=${key}`,

  GET_SPECIES_DIVISION: `${apiRoot}${species}/GetSpeciesDivision`,
  GET_SPECIES_PHENOLOGY: `${apiRoot}${species}/GetSpeciesPhenology`,
  GET_SPECIES_WITHOUT_EOS: `${apiRoot}${species}/GetSpeciesWithoutEOs`,
  GET_SPECIES_WITHOUT_STATE_STATUS: `${apiRoot}${species}/GetSpeciesWithoutStateStatus`,
  GET_SPECIES_WITH_EOS: `${apiRoot}${species}/GetSpeciesWithEOs`,
  GET_SPECIES_STATE_STATUS: `${apiRoot}${species}/GetSpeciesStateStatus`,
  GET_SPECIES_WITH_NO_EOS: `${apiRoot}${species}/GetSpeciesWithNoEOsPaged`,
  GET_SPECIES_WITHOUT_STATE_CONSERVATION_STATUS: `${apiRoot}${species}/GetSpeciesWithoutStateConservationStatus`,

  GET_PCV_TF_STATUS: `${apiRoot}${fieldFormSubmission}/GetPcvTFStatusPaged`,
  GET_FIELD_FORM_ENTER: `${apiRoot}${fieldFormSubmission}/GetFieldFormEnterPriorPaged`,
  GET_FIELD_FORM_ENTER_PCV_ADMIN: `${apiRoot}${fieldFormReview}/GetFieldFormEnterPriorPaged`,
  CREATE_FIELD_FORM_ENTER: `${apiRoot}${fieldFormSubmission}/CreateFieldFormEnterPrior`,
  CREATE_FIELD_FORM_NEW: `${apiRoot}${fieldFormSubmission}/CreateFieldFormNew`,

  GET_FIELD_FORM_ENTER_PRIOR: (key) =>
    `${apiRoot}${fieldFormSubmission}/GetFieldFormEnterPriorDetails/${key}`,

  GET_FIELD_FORM_EDIT_PAGED: `${apiRoot}${fieldFormSubmission}/GetFieldFormEditPaged`,
  GET_FIELD_FORM_EDIT_DETAILS: (key) =>
    `${apiRoot}${fieldFormSubmission}/GetFieldFormEditDetails/${key}`,
  CREATE_FIELD_FORM_EDIT: `${apiRoot}${fieldFormSubmission}/CreateFieldFormEdit`,
  UPDATE_FIELD_FORM_EDIT: (key) =>
    `${apiRoot}${fieldFormSubmission}/UpdateFieldFormEdit/${key}`,
  DELETE_FIELD_FORM_EDIT: (key) =>
    `${apiRoot}${fieldFormSubmission}/DeleteFieldFormEdit/${key}`,

  GET_FIELD_FORM_NO_EO: `${apiRoot}${fieldFormSubmission}/GetFieldFormNoEo`,
  GET_FIELD_FORM_NO_EO_DETAILS: (key) =>
    `${apiRoot}${fieldFormSubmission}/GetFieldFormReviewNoEoDetails/${key}`,
  FIELD_FORM_NO_EO_EDIT: (key) =>
    `${apiRoot}${fieldFormSubmission}/UpdateFieldFormReviewNoEo/${key}`,
  DELETE_FIELD_FORM_NO_EO: (key) =>
    `${apiRoot}${fieldFormSubmission}/DeleteFieldFormReviewNoEo/${key}`,

  GET_CHECKLIST_SYNONYM: `${apiRoot}${tracheophyteChecklist}/GetChecklistSynonymPaged`,
  GET_SPECIES_CONSERVATION_STATUS: `${apiRoot}${tracheophyteChecklist}/GetSpeciesConservationStatusPaged`,
  GET_SPECIES_INVASIVE_STATUS: `${apiRoot}${tracheophyteChecklist}/GetSpeciesInvasiveStatusPaged`,
  GET_CHECKLIST_SPECIES: `${apiRoot}${tracheophyteChecklist}/GetChecklistSpeciesPaged`,
  GET_CHECKLIST_SPECIES_DETAILS: (key) =>
    `${apiRoot}${tracheophyteChecklist}/GetTracheophyteChecklistSynonyms/${key}`,
  GET_SPECIES_INFORMATION_PAGED: `${apiRoot}${tracheophyteChecklist}/GetSpeciesInformationPaged`,
  GET_SPECIES_PHEN_NOTES_PAGED: `${apiRoot}${tracheophyteChecklist}/GetSpeciesPhenNotesConsStatusPaged`,
  GET_FIELD_DATA_PHENOLOGY_INFORMATION_PAGED: `${apiRoot}${tracheophyteChecklist}/GetFieldDataPhenologyInformationPaged`,

  GET_SPECIES_INFORMATION_EDIT_PAGED: `${apiRoot}${tracheophyteChecklist}/GetSpeciesInformationEditPaged`,
  UPDATE_SPECIES_INFORMATION_EDIT: (key) =>
    `${apiRoot}${tracheophyteChecklist}/UpdateSpeciesInformationEdit/${key}`,
  GET_SPECIES_PHENOLOGY_DETAILS: (key) =>
    `${apiRoot}${tracheophyteChecklist}/GetSpeciesPhenologyDetails/${key}`,
  CREATE_SPECIES_PHENOLOGY_DETAILS: `${apiRoot}${tracheophyteChecklist}/CreateSpeciesPhenologyDetails`,
  UPDATE_SPECIES_PHENOLOGY_DETAILS: (key, type) =>
    `${apiRoot}${tracheophyteChecklist}/UpdateSpeciesPhenologyDetails/${key}/${type}`,
  DELETE_SPECIES_INFORMATION_EDIT: (key, type) =>
    `${apiRoot}${tracheophyteChecklist}/DeleteSpeciesInformationEdit/${key}/${type}`,

  GET_GO_BOTANY_DISTRIBUTION: `${apiRoot}${goBotanyData}/GetGoBotanyDistributionPaged`,
  GET_GO_BOTANY_SPECIES_CHARACTER: `${apiRoot}${goBotanyData}/GetGoBotanySpeciesCharacterPaged`,
  GET_GO_BOTANY_SPECIES_PAGED: `${apiRoot}${goBotanyData}/GetGoBotanySpeciesPaged`,
  GET_GO_BOTANY_SPECIES_LOOKALIKE: (taxonID) =>
    `${apiRoot}${goBotanyData}/GetGoBotanySpeciesLookalike/${taxonID}`,

  GET_OWNER_BY_STATUS: `${apiRoot}${owners}/GetOwnerStatus`,
  GET_OWNER_TYPE: `${apiRoot}${owners}/GetOwnerType`,
  GET_OWNER_TYPE_STATE: `${apiRoot}${owners}/GetOwnerTypeState`,
  GET_OWNER_HISTORY: `${apiRoot}${owners}/GetOwnerHistoryPaged`,
  GET_SURVEY_PLAN_EOS_WITHOUT_OWNER: `${apiRoot}${owners}/GetSurveyPlanEOsWithoutOwnerPaged`,
  GET_OWNER_EO_PAGED: `${apiRoot}${owners}/GetOwnerEoPaged`,
  GET_EXISTING_OWNER_EO: (key) =>
    `${apiRoot}${owners}/GetExistingOwnerEo/${key}`,
  GET_OWNER_EO_CURRENT_YEAR_PAGED:(LeadSurveyor)=> `${apiRoot}${owners}/GetOwnerEoCurrentSurveyYearPaged/${LeadSurveyor}`,
  GET_EXISTING_OWNER_EO_CURRENT_YEAR: (key, leadSurveyor) =>
    `${apiRoot}${owners}/GetExistingOwnerEoCurrentSurveyYear/${key}/${leadSurveyor}`,
  GET_EXISTING_OWNER_EO_FOR_ADD_OR_DELETE: (key, currentOwner) =>
    `${apiRoot}${owners}/GetExistingOwnerEoForAddOrDelete/${key}/${currentOwner}`,
  CREATE_OWNER_EO_ASSOSIATION: `${apiRoot}${owners}/CreateOwnerEoAssosiation`,
  UPDATE_EL_OCC_OWNER: (eokey, ownerKey) =>
    `${apiRoot}${owners}/UpdateElementOccurrenceOwner/${eokey}/${ownerKey}`,
  DELETE_EL_OCC_OWNER: (eokey, ownerKey) =>
    `${apiRoot}${owners}/DeleteElementOccurrenceOwner/${eokey}/${ownerKey}`,

  GET_OWNERS_PAGED: `${apiRoot}${owners}/GetOwnersPaged`,
  GET_OWNER_DETAILS: (key) => `${apiRoot}${owners}/GetOwnerDetails/${key}`,
  CREATE_OWNER: `${apiRoot}${owners}/CreateOwner`,
  UPDATE_OWNER: (key) => `${apiRoot}${owners}/UpdateOwner/${key}`,
  DELETE_OWNER: (key) => `${apiRoot}${owners}/DeleteOwner/${key}`,

  GET_SURVEY_SIGNUP_PAGED_PCVTF: `${apiRoot}${surveyInformation}/GetSurveySignupPaged`,
  EDIT_SURVEY_SIGNUP_FIELDS_PCVTF: (key) =>
    `${apiRoot}${surveyInformation}/UpdateSurveySurveyors/${key}`,
  GET_SURVEY_ASSIGNMENT_EO_DATA: `${apiRoot}${surveyInformation}/GetSurveyAssignmentEODataPaged`,
  GET_SURVEY_ASSIGNMENT_EO_DATA_DETAILS: (key) =>
    `${apiRoot}${surveyInformation}/GetSurveyAssignmentEODataDetails/${key}`,
  GET_SURVEY_ASSIGNMENT_FIELD_DATA: `${apiRoot}${surveyInformation}/GetSurveyAssignmentFieldDataPaged`,
  GET_SURVEY_ASSIGNMENT_FIELD_DATA_DETAILS: (key, flag) =>
    `${apiRoot}${surveyInformation}/GetSurveyAssignmentFieldDataDetails/${key}/${flag}`,

  GET_PCV_TF_SIGNUP_STATUS: `${apiRoot}${surveyInformation}/GetSurveySignUpStatus`,
  GET_PCV_TF_ASSIGNMENT_SUMMARY: `${apiRoot}${surveyInformation}/GetSurveyAssignmentSummaryPaged`,
  GET_PCV_TF_ASSIGNMENT_SUMMARY_DETAILS: (eokey) =>
    `${apiRoot}${surveyInformation}/GetSurveyAssignmentSummaryDetails/${eokey}`,
  GET_SURVEY_ASSIGNMENT_SPROUT_DATA_PAGED: `${apiRoot}${surveyInformation}/GetSurveyAssignmentSproutDataPaged`,
  GET_FIELD_FORM_SPROUT_DATA_PAGED_BY_KEY: (key) =>
    `${apiRoot}${surveyInformation}/GetFieldFormSproutDataPaged/${key}`,
  GET_SURVEY_ASSIGNMENT_FIELD_DATA_SPROUT_DETAILD: (key) =>
    `${apiRoot}${surveyInformation}/GetSurveyAssignmentFieldDataSproutDetails/${key}`,

  GET_USERS_PAGED: `${apiRoot}${backgroundActivities}/GetUsersPaged`,
  GET_SURVEYORS_PAGED_NEW_USER: `${apiRoot}${backgroundActivities}/GetUsersPaged`,
  DELETE_NEW_USER: (id) => `${apiRoot}${backgroundActivities}/DeleteUser/${id}`,
  SIGN_UP: `${apiRoot}${backgroundActivities}/SignUp`,
  GET_SIGNUP_DATES: `${apiRoot}${backgroundActivities}/GetSignupDatesPaged`,
  UPDATE_SIGNUP_DATES: (surveyorType, signupType) =>
    `${apiRoot}${backgroundActivities}/UpdateSignupDate/${surveyorType}/${signupType}`,
  GET_ANNOUNCEMENTS: `${apiRoot}${backgroundActivities}/GetAnnouncements`,
  CREATE_ANNOUNCEMENT: `${apiRoot}${backgroundActivities}/CreateAnnouncements`,
  DELETE_ANNOUNCEMENT: (guid) =>
    `${apiRoot}${backgroundActivities}/DeleteAnnouncements/${guid}`,
  GET_PCV_PROGRAMS_PAGED: `${apiRoot}${backgroundActivities}/GetPcvProgramsPaged`,
  CREATE_PCV_PROGRAM: `${apiRoot}${backgroundActivities}/CreatePcvProgram`,
  UPDATE_PCV_PROGRAM: (id) =>
    `${apiRoot}${backgroundActivities}/UpdatePcvProgram/${id}`,
  ATTENDS_PCV_PROGRAM: (id) =>
    `${apiRoot}${backgroundActivities}/GetAttendees/${id}`,
  DELETE_PCV_PROGRAM: (id) =>
    `${apiRoot}${backgroundActivities}/DeletePcvProgram/${id}`,
  GET_OUTREACH_ORGANIZATION_PAGED: `${apiRoot}${backgroundActivities}/GetOutreachOrganizationsPaged`,
  CREATE_OUTREACH_ORGANIZATION: `${apiRoot}${backgroundActivities}/CreateOutreachOrganization`,
  UPDATE_OUTREACH_ORGANIZATION: (key) =>
    `${apiRoot}${backgroundActivities}/UpdateOutreachOrganization/${key}`,
  DELETE_OUTREACH_ORGANIZATION: (key) =>
    `${apiRoot}${backgroundActivities}/DeleteOutreachOrganization/${key}`,
  SEND_EMAIL: (id) => `${apiRoot}${backgroundActivities}/SendEmails/${id}`,
  GET_BA_EO_COORDINATES: (key) =>
    `${apiRoot}${backgroundActivities}/GetElementOccurrenceCoordinates/eokey?eokey=${key}`,
  GET_BA_ALL_EOS_FOR_STATE: (state) =>
    `${apiRoot}${backgroundActivities}/GetAllElementOccurencesForState/state?state=${state}`,
  GET_SITE_BLOCKING_STATUS: `${apiRoot}${backgroundActivities}/GetSiteBlockingStatus`,
  UPDATE_SITE_BLOCKING_STATUS: `${apiRoot}${backgroundActivities}/UpdateSiteBlockingStatus`,
  BLOCK_USER: (userId, loginAllowed) =>
    `${apiRoot}${backgroundActivities}/UpdateUserLoginAllowStatus/${userId}/${loginAllowed}`,

  GET_SURVEY_RESULTS_PAGED_PCVTF: `${apiRoot}${surveyPlansResults}/GetSurveyResultsPaged`,
  GET_SURVEY_RESULTS_HISTORY_PCVTF: (key) =>
    `${apiRoot}${surveyPlansResults}/GetSurveyResultsHistory/${key}`,
  GET_FIELD_DATA_SPROUT_PCVTF: (key) =>
    `${apiRoot}${surveyPlansResults}/GetFieldDataSproutPaged/${key}`,
  GET_FIELD_DATA_SPROUT_DETAILS_PCVTF: (key) =>
    `${apiRoot}${surveyPlansResults}/GetFieldDataSproutDetails/${key}`,

  GET_HISTORY_SPROUT_PAGED_PCVTF: `${apiRoot}${backgroundInformation}/GetHistorySproutPaged`,
  GET_HISTORY_SPROUT_DETAILS_PCVTF: (key) =>
    `${apiRoot}${backgroundInformation}/GetHistorySproutDetails/${key}`,
  GET_HISTORY_FOR_NEW_EOS_PAGED_PCVTF: `${apiRoot}${backgroundInformation}/GetHistoryForNewEosPaged`,
  GET_HISTORY_FOR_NEW_EOS_DETAILS_PCVTF: (key) =>
    `${apiRoot}${backgroundInformation}/GetHistoryForNewEosDetails/${key}`,
  GET_PROGRAMS_PCVTF: `${apiRoot}${backgroundInformation}/GetPrograms`,
  GET_ATTENDEES: (key) =>
    `${apiRoot}${backgroundInformation}/GetAttendees/${key}`,
  UPDATE_SURVEYOR_PCV_PROGRAM: (programId, participate) =>
    `${apiRoot}${backgroundInformation}/UpdateSurveyorPcvProgram/${programId}/${participate}`,
  GET_LINKS: `${apiRoot}${backgroundInformation}/GetLinks`,
  GET_CONTACT_LIST: `${apiRoot}${backgroundInformation}/GetPcvTfContactListPaged`,
  GET_ALL_EOS_FOR_STATE: (state) =>
    `${apiRoot}${backgroundInformation}/GetAllElementOccurencesForState/state?state=${state}`,
  GET_ALL_EOS_FOR_STATE_ALL: `${apiRoot}${backgroundInformation}/GetAllElementOccurencesForState/state`,
  GET_EO_COORDINATES: (key) =>
    `${apiRoot}${backgroundInformation}/GetElementOccurrenceCoordinates/eokey?eokey=${key}`,
  GET_ASSOCIATED_FILES: (key) =>
    `${apiRoot}${backgroundInformation}/GetFieldFormAssociatedFiles/${key}`,
  GET_NO_EO_ASSOCIATED_FILES: (key) =>
    `${apiRoot}${backgroundInformation}/GetFieldFormNoEoAssociatedFiles/${key}`,

  GET_SURVEY_PLAN_PAGED_PCVTF: `${apiRoot}${surveyPlansResults}/GetSurveyPlansPaged`,
  GET_SURVEY_PLAN_HISTORY_PCVTF: (key) =>
    `${apiRoot}${surveyPlansResults}/GetSurveyPlanHistory/${key}`,
  UPDATE_SURVEY_PLAN_PCV: (key) =>
    `${apiRoot}${surveyPlansResults}/UpdateSurveyPlan/${key}`,

  GET_ALL_EMAILS: `${apiRoot}${fileAndEmailManagement}/GetAllEmails`,
  GET_EMAIL: (type) => `${apiRoot}${fileAndEmailManagement}/GetEmail/${type}`,
  UPDATE_EMAIL: (type) =>
    `${apiRoot}${fileAndEmailManagement}/UpdateEmail/${type}`,
  SEND_ORGANIZATIONS_EMAILS: `${apiRoot}${fileAndEmailManagement}/SendOrganizationsEmails`,
  GET_TRANSCRIBTIONS_PAGED: `${apiRoot}${fileAndEmailManagement}/GetTranscriptionsPaged`,
  UPDATE_TRANSCRIBTIONS: `${apiRoot}${fileAndEmailManagement}/UpdateTranscription`,

  GET_FIELD_FORM_REVIEW_NO_EO_XLSX: `${apiRoot}${xlsx}/GetFieldFormReviewNoEoXlsx`,
  GET_PERMISSIONS_BY_EO_EXCEL: `${apiRoot}${xlsx}/GetPermissionsByEOXlsx`,
  GET_PERMISSIONS_BY_OWNER_EXCEL: `${apiRoot}${xlsx}/GetPermissionsByOwnerXlsx`,
  GET_EOS_EXCEL: `${apiRoot}${xlsx}/GetElementOccurrencesXlsx`,
  GET_INACTIVE_EOS_WITH_OBSERVATION_EXCEL: `${apiRoot}${xlsx}/GetInactiveEOsWithObservationsXlsx`,
  GET_ACTIVE_EOS_WITH_CONFLICTING_FIELD_DATA_EXCEL: `${apiRoot}${xlsx}/GetActiveEOsWithConflictingFieldDataXlsx`,
  GET_EOS_WITH_DATA_ERRORS_EXCEL: `${apiRoot}${xlsx}/GetEosWithDataErrorsXlsx`,
  GET_EOS_WITHOUT_EO_NUMBER_EXCEL: `${apiRoot}${xlsx}/GetEosWithoutEoNumberXlsx`,
  GET_MULTI_SPECIES_SITES_EXCEL: `${apiRoot}${xlsx}/GetMultiSpeciesSitesXlsx`,
  GET_OWNER_REPORT_EXCEL: `${apiRoot}${xlsx}/GetOwnerReportXlsx`,
  GET_CHECKLIST_SYNONYM_EXCEL: `${apiRoot}${xlsx}/GetChecklistSynonymXlsx`,
  GET_SURVEY_SIGNUP_STATUS_EXCEL: `${apiRoot}${xlsx}/GetSurveySignUpStatusXlsx`,
  GET_SRANK_NUMBER_EOS_EXCEL: `${apiRoot}${xlsx}/GetSRankNumberEOsXlsx`,
  GET_OWNER_REPORT_WITH_PROBLEM_EXCEL: `${apiRoot}${xlsx}/GetOwnerReportWithProblemXlsx`,
  GET_FIELD_DATA_SEED_BY_SPECIES_EXCEL: `${apiRoot}${xlsx}/GetFieldDataSeedBySpeciesXlsx`,
  GET_SPECIES_CONSERVATION_STATUS_EXCEL: `${apiRoot}${xlsx}/GetSpeciesConservationStatusXlsx`,
  GET_SPECIES_INVASIVE_STATUS_EXCEL: `${apiRoot}${xlsx}/GetSpeciesInvasiveStatusXlsx`,
  GET_GO_BOTANY_DISTRIBUTION_EXCEL: `${apiRoot}${xlsx}/GetGoBotanyDistributionXlsx`,
  GET_OWNERS_EXCEL: `${apiRoot}${xlsx}/GetOwnersXlsx`,
  GET_OWNER_HISTORY_EXCEL: `${apiRoot}${xlsx}/GetOwnerHistoryXlsx`,
  GET_SURVEYORS_EXCEL: `${apiRoot}${xlsx}/GetSurveyorsXlsx`,
  GET_CONTACT_LIST_EXCEL: `${apiRoot}${xlsx}/GetPcvTfContactListXlsx`,
  GET_SURVEY_SIGNUP_EXCEL: `${apiRoot}${xlsx}/GetSurveySignupXlsx`,
  GET_SURVEY_RESULT_EXCEL: `${apiRoot}${xlsx}/GetSurveyResultsXlsx`,
  GET_SURVEY_PLAN_EXCEL: `${apiRoot}${xlsx}/GetSurveyPlansXlsx`,
  GET_PCV_SURVEY_SIGNUP_EXCEL: `${apiRoot}${xlsx}/GetPcvSurveySignupXlsx`,
  GET_PCV_SURVEY_PLAN_EXCEL: `${apiRoot}${xlsx}/GetPcvSurveyPlansXlsx`,
  GET_POPULATION_SIZE_EXCEL: `${apiRoot}${xlsx}/GetPopulationSizeXlsx`,
  GET_DISTRIBUTION_YEAR_EXCEL: `${apiRoot}${xlsx}/GetDistributionYearXlsx`,
  GET_DISTRIBUTION_ALL_SOURCES_EXCEL: `${apiRoot}${xlsx}/GetDistributionAllSourcesXlsx`,
  GET_OUTREACH_ORGANIZATION_EXCEL: `${apiRoot}${xlsx}/GetOutreachOrganizationsXlsx`,
  GET_SURVEYS_WITHOUT_PERMISSION_EXCEL: `${apiRoot}${xlsx}/GetSurveysWithoutPermissionXlsx`,
  GET_SURVEYS_WITH_FIELD_FORMS_EXCEL: `${apiRoot}${xlsx}/GetSurveysWithFieldFormsXlsx`,
  GET_SURVEY_RESULT_PRIOR_YEAR_EXCEL: `${apiRoot}${xlsx}/GetSurveyResultPriorYearXlsx`,
  GET_SURVEYS_WITHOUT_FIELD_FORMS_BUT_WITH_PERMISSION_EXCEL: `${apiRoot}${xlsx}/GetSurveysWithoutFieldFormsButWithPermissionXlsx`,
  GET_SURVEY_BY_DISTANCE_EXCEL: `${apiRoot}${xlsx}/GetSurveyorByDistanceXlsx`,
  GET_SURVEYS_PAST_YEAR_PERMIT_EXCEL: `${apiRoot}${xlsx}/GetPastYearsPermitsXlsx`,
  GET_CURRENT_YEARS_PERMIT_EXCEL: `${apiRoot}${xlsx}/GetCurrentYearsPermitsXlsx`,

  GET_COUNT_MANUAL_FIELD_FORM_EXCEL: `${apiRoot}${xlsx}/GetCountManualFieldFormXlsx`,
  GET_EL_OCC_STATE_SOURCE_EXCEL: `${apiRoot}${xlsx}/GetElementOccurrenceStateSourceXlsx`,
  GET_EL_OCC_STATE_DIV_EXCEL: `${apiRoot}${xlsx}/GetElementOccurrenceStateDivisionXlsx`,
  GET_EL_OCC_STATE_STATUS_EXCEL: `${apiRoot}${xlsx}/GetElementOccurrenceStateStatusXlsx`,
  GET_EL_OCC_DIV_STATUS_EXCEL: `${apiRoot}${xlsx}/GetElementOccurrenceDivisionStatusXlsx`,
  GET_EL_OCC_DIV_STATE_STATUS_EXCEL: (state) =>
    `${apiRoot}${xlsx}/GetElementOccurrenceDivisionStateStatusXlsx/${state}`,
  GET_EL_OCC_STATE_OWNER_EXCEL: `${apiRoot}${xlsx}/GetElementOccurrenceStateOwnerXlsx`,
  GET_EL_OCC_STATE_DATA_MISMATCH_EXCEL: `${apiRoot}${xlsx}/GetElementOccurrenceStateDataMismatchXlsx`,
  GET_SEED_BANK_DIV_EXCEL: `${apiRoot}${xlsx}/GetSeedBankDivisionXlsx`,
  GET_SEED_BANK_DIV_STATE_EXCEL: (state) =>
    `${apiRoot}${xlsx}/GetSeedBankDivisionStateXlsx/${state}`,
  GET_SEED_COLLECTION_EOS_EXCEL: `${apiRoot}${xlsx}/GetSeedCollectionEOsXlsx`,
  GET_ASSOCIATED_SPECIES_MANUAL_EXCEL: `${apiRoot}${xlsx}/GetAssociatedSpeciesManualXlsx`,
  GET_ASSOCIATED_SPECIES_SPROUT_EXCEL: `${apiRoot}${xlsx}/GetAssociatedSpeciesSproutXlsx`,
  GET_INVASIVE_SPECIES_MANUAL_EXCEL: `${apiRoot}${xlsx}/GetInvasiveSpeciesManualXlsx`,
  GET_INVASIVE_SPECIES_SPROUT_EXCEL: `${apiRoot}${xlsx}/GetInvasiveSpeciesSproutXlsx`,
  GET_NATURAL_COMMUNITIES_SPECIES_EXCEL: `${apiRoot}${xlsx}/GetNaturalCommunitiesSpeciesXlsx`,
  GET_NATURAL_COMMUNITIES_EOS_EXCEL: `${apiRoot}${xlsx}/GetNaturalCommunitiesEosXlsx`,
  GET_OWNER_EO_CURRENT_SURVEY_YEAR_EXCEL: (key) =>
    `${apiRoot}${xlsx}/GetExistingOwnerEoCurrentSurveyYearXlsx/${key}`,
  GET_HISTORY_SPROUT_PAGED_PCVTF_EXCEL: `${apiRoot}${xlsx}/GetHistorySproutPaged`,
  GET_HISTORY_NEW_EOS_PAGED_PCVTF_EXCEL: `${apiRoot}${xlsx}/GetHistoryForNewEosXlsx`,
  GET_SURVEY_PLAN_EOS_WITHOUT_OWNER_EXCEL: `${apiRoot}${xlsx}/GetSurveyPlanEOsWithoutOwnerXlsx`,
  GET_SPECIES_INFORMATION_EXCEL: `${apiRoot}${xlsx}/GetSpeciesInformationXlsx`,
  GET_SPECIES_PHEN_NOTES_CONST_STATUS_EXCEL: `${apiRoot}${xlsx}/GetSpeciesPhenNotesConsStatusXlsx`,
  GET_FIELD_DATA_PHENOLOGY_INFORMATION_EXCEL:`${apiRoot}${xlsx}/GetFieldDataPhenologyInformationXlsx`,
  

  GET_EO_OUTSIDE_DISTRIBUTION: `${apiRoot}${summary}/GetElementOccurrenceOutsideDistribution`,
  GET_COUNTIES_WITHOUT_EOS: `${apiRoot}${summary}/GetCountiesWithoutEOs`,
  GET_EO_MAX_EXTENT: `${apiRoot}${summary}/GetElementOccurrenceMaxExtent`,
  GET_EO_CURRENT_EXTENT: `${apiRoot}${summary}/GetElementOccurrenceCurrentExtent`,
  GET_EO_STATE_MGT_NOTES: `${apiRoot}${summary}/GetElementOccurrenceStateMgtNotes`,
  GET_EO_STATE_MGT_NOTES_SPROUT: `${apiRoot}${summary}/GetElementOccurrenceStateMgtNotesSprout`,
  GET_MGT_NOTES_TYPE_DIVISION: `${apiRoot}${summary}/GetMgtNotesTypeDivision`,
  GET_MGT_NOTES_TYPE_DIVISION_SPROUT: `${apiRoot}${summary}/GetMgtNotesTypeDivisionSprout`,

  GET_ELEMENT_OCCURACE_TOPO_FIELDS: (eokey) =>
    `${apiRoot}Shared/GetElementOccurrenceTopoFields/${eokey}`,
};
