import React from 'react';

import StatesSelect from '../../../shared/components/select/StatesSelect';
import DivisionsSelect from "../../../shared/components/select/DivisionsSelect";

export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'Division',
    label: 'Division',
    input: (props) => <DivisionsSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
