import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      sorter: true,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('table:alteredHydrology'),
      dataIndex: 'alteredHydrology',
      key: 'alteredHydrology',
    },
    {
      title: t('table:directHumanThreat'),
      dataIndex: 'directHumanThreat',
      key: 'directHumanThreat',
    },
    {
      title: t('table:erosion'),
      dataIndex: 'erosion',
      key: 'erosion',
    },
    {
      title: t('table:fireSuppression'),
      dataIndex: 'fireSuppression',
      key: 'fireSuppression',
    },
    {
      title: t('table:herbivory'),
      dataIndex: 'herbivory',
      key: 'herbivory',
    },
    {
      title: t('table:invasiveSpecies'),
      dataIndex: 'invasiveSpecies',
      key: 'invasiveSpecies',
    },
    {
      title: t('table:succession'),
      dataIndex: 'succession',
      key: 'succession',
    },
    {
      title: t('table:noThreat'),
      dataIndex: 'noThreat',
      key: 'noThreat',
    },
    {
      title: t('table:threatScope'),
      dataIndex: 'threatScope',
      key: 'threatScope',
    },
    {
      title: t('table:threatSeverity'),
      dataIndex: 'threatSeverity',
      key: 'threatSeverity',
    },
    {
      title: t('table:disturbanceComment'),
      dataIndex: 'disturbanceComment',
      key: 'disturbanceComment',
    },
    {
      title: t('table:mgtComment'),
      dataIndex: 'mgtComment',
      key: 'mgtComment',
    },
  ];
};
