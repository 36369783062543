import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../config';
import Section from '../Section';

const ElementOccurrencePage = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Section
        config={sectionsConfig().elementOccurrence}
        title={t('sections:ElementOccurrence:title')}
      />
    </PageContent>
  );
};

export default ElementOccurrencePage;
