import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Typography, Row, Col } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';

import { FIRST_LOGIN_KEY, AUTH_DATA_KEY } from '../../global/constants';

import { useAuth } from '../../global/stores/auth';
import logotype from '../../global/shared/SPROUT lockup_rgb.png';

import classes from './styles.module.scss';

const { Title, Paragraph } = Typography;
const { Item } = Form;

const SecurityAgreement = () => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  const onCheckboxChange = async (e) => {
    await setChecked(e.target.checked);
  };

  const {
    login: { authData },
    agreement: {
      acceptAgreement,
      acceptAgreementInProgress,
      errorInAcceptAgreement,
    },
  } = useAuth();

  const firstLogin = JSON.parse(localStorage.getItem(FIRST_LOGIN_KEY));

  const isError = !acceptAgreementInProgress && errorInAcceptAgreement;
  const errorMessage = isError && errorInAcceptAgreement.title;

  const validation = (rule, value, callback) => {
    if (checked) {
      return callback();
    }
    return callback(t('securityAgreement:acceptAgreement'));
  };

  const lastName = firstLogin?.userInfo?.lastName;
  const firstName = firstLogin?.userInfo?.firstName;
  const onFinish = (values) => {
    localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
    acceptAgreement({ userInfo: { ...firstLogin.userInfo }, ...values });
  };

  return (
    <Row justify="space-around" align="middle">
      <Col span={12}>
        <div className={classes.logotype}>
          <img src={logotype} alt="logotype" />
        </div>
        <Title level={2} className={classes.title}>
          {t('securityAgreement:title')}
        </Title>
        <Paragraph>{t('securityAgreement:paragraph')}</Paragraph>
        <Paragraph>{t('securityAgreement:additionalParagraph')}</Paragraph>
        <Form
          name="basic"
          initialValues={{
            agreementAcceptanceDate: moment().format('YYYY-MM-DD'),
            securityAgreementAccepted: true,
          }}
          onFinish={onFinish}
          validateTrigger={['onBlur', 'onChange']}
        >
          <div>
            <Item
              label={t('securityAgreement:firstName')}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t('securityAgreement:errorMessageFirstName'),
                },
                {
                  validator: (_, value) => {
                    if (!value || firstName === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('securityAgreement:sameFirstName'))
                    );
                  },
                },
              ]}
            >
              <Input />
            </Item>

            <Item
              label={t('securityAgreement:lastName')}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t('securityAgreement:errorMessageLastName'),
                },
                {
                  validator: (_, value) => {
                    if (!value || lastName === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t('securityAgreement:sameLastName'))
                    );
                  },
                },
              ]}
            >
              <Input />
            </Item>

            <Item
              label={t('securityAgreement:dateLabel')}
              name="agreementAcceptanceDate"
              rules={[
                {
                  required: true,
                  message: t('securityAgreement:errorMessageDate'),
                },
              ]}
            >
              <Input disabled placeholder={t('securityAgreement:date')} />
            </Item>
          </div>

          {isError ? (
            <Item
              name="securityAgreementAccepted"
              validateStatus={isError && 'error'}
              help={errorMessage}
            >
              <Checkbox checked={checked} onChange={onCheckboxChange}>
                {t('securityAgreement:agree')}
              </Checkbox>
            </Item>
          ) : (
            <Item
              name="securityAgreementAccepted"
              rules={[{ validator: validation }]}
            >
              <Checkbox checked={checked} onChange={onCheckboxChange}>
                {t('securityAgreement:agree')}
              </Checkbox>
            </Item>
          )}

          <Item>
            <Button
              className={classes.button}
              type="primary"
              htmlType="submit"
              loading={acceptAgreementInProgress}
            >
              {t('accountSettings:submit')}
            </Button>
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SecurityAgreement;
