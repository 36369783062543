import React from 'react';

import { CLOSED_QUESTIONS } from '../../../../global/constants';

import { Select } from '../../../../shared/components/select/Select';

export const formConfig = [
  {
    name: 'phenologyType1',
    label: 'Phenology Type',
    rules: [
      {
        required: true,
      },
      {
        max: 20,
      },
    ],
  },
  {
    name: 'description',
    label: 'Description',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'display',
    label: 'Display',
    input: (props) => <Select props={props} data={CLOSED_QUESTIONS} />,
    rules: [
      {
        required: true,
      },
    ],
  },
];
