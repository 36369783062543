import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const OwnerTypesSelect = (props) => {
  const { allOwnerTypesData, allOwnerTypesLoading, updateAllOwnerTypes } =
    useStore();

  useEffect(() => {
    if (!allOwnerTypesData.length && !allOwnerTypesLoading) updateAllOwnerTypes();
  }, []);

  return (
    <Select
      props={props}
      width={150}
      data={allOwnerTypesData}
      loading={allOwnerTypesLoading}
    />
  );
};

export default OwnerTypesSelect;
