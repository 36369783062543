import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import classNames from 'clsx';

import { APIFetch } from '../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../global/api';
import { useAPI } from '../../shared/hooks/use-api/useAPI';

import classes from '../styles.module.scss';

const { Item } = Form;

const fetchDelete = (ffkey) =>
  ffkey
    ? APIFetch(ENDPOINT_URLS.DELETE_FILE(ffkey), {
        method: 'delete',
      })
    : null;

const Files = ({ files }) => {
  const [activeFiles, setActiveFiles] = useState(files);
  const [clickDelete, setClickDelete] = useState(null);
  const { data: deleteData, refetch: refetchDelete } = useAPI(
    (id) => fetchDelete(id),
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  useEffect(() => {
    refetchDelete(clickDelete);
  }, [clickDelete]);

  useEffect(() => {
    if (deleteData?.ffkey) {
      if (activeFiles.length === 1) {
        setActiveFiles([]);
      } else {
        setActiveFiles(
          activeFiles.filter((f) => f.ffkey !== deleteData?.ffkey)
        );
      }
      setClickDelete(null);
    }
  }, [deleteData]);

  return activeFiles.length ? (
    <Item label="Added files:">
      {activeFiles.map(({ fullFilename, fileLink, ffkey }) => {
        const deleteFunction = () => setClickDelete(ffkey);
        return (
          <div className={classNames(classes.fileContainer)}>
            <a href={fileLink}>{fullFilename}</a>
            <br />
            <DeleteOutlined
              className={classNames(classes.icon)}
              onClick={deleteFunction}
            />
          </div>
        );
      })}
    </Item>
  ) : null;
};

export default Files;
