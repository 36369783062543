import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../../sections/config';
import Section from '../../sections/Section';

const SurveyPlanStatusPage = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Section
        config={sectionsConfig().surveyPlanStatus}
        title={t('SurveyPlanStatus:title')}
        pageDescription={t('SurveyPlanStatus:description')}
        onlyTable
      />
    </PageContent>
  );
};

export default SurveyPlanStatusPage;
