import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AUTH_ROUTE } from './AppRoutes';
import { useAuth } from './global/stores/auth';

const AcceptAgreementsRoute = ({ ...rest }) => {
  const { component: View } = rest;

  return (
    <Route {...rest}>
      <View {...rest} />
      {/* {path !== SECURITY_AGREEMENT && <Redirect to={ACCOUNT_SETTINGS} />} */}
    </Route>
  );
};

export function AuthenticatedRoute({ ...rest }) {
  const { component: View, requiresAuth } = rest;

  const { isAuthenticated, checkingAuthentication, securityAgreementAccepted } =
    useAuth();

  if (isAuthenticated && !securityAgreementAccepted) {
    return <AcceptAgreementsRoute {...rest} />;
  }

  if (checkingAuthentication) return null;

  if (requiresAuth && !isAuthenticated) {
    return <Redirect to={AUTH_ROUTE} />;
  }

  if (isAuthenticated && securityAgreementAccepted) {
    return <View {...rest} />;
  }

  return null;
}
