import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithSimpleTableAndSearch from '../../pages/PageWithSimpleTableAndSearch';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchSeedBankDivisionState = (state) =>
  state
    ? APIFetch(ENDPOINT_URLS.GET_SEED_BANK_DIV_STATE(state), {
        method: 'get',
      })
    : null;

const fetchExport = (state) =>
  state
    ? APIFetch(ENDPOINT_URLS.GET_SEED_BANK_DIV_STATE_EXCEL(state), {
        method: 'get',
      })
    : null;

export const SeedBankDivisionState = () => (
  <PageWithSimpleTableAndSearch
    fetchData={(state) => fetchSeedBankDivisionState(state)}
    searchParamKey="state"
    titleKey="ElementOccurrence:SeedBankDivisionState"
    totalValueKey="division"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={({ state, division }) => `${state}-${division}`}
    fetchExport={fetchExport}
  />
);
