import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:surveyTF'),
      width: 70,
      dataIndex: 'nextSurveyYear',
      key: 'nextSurveyYear',
      sorter: true,
    },
    {
      title: t('table:seedTF'),
      width: 70,
      dataIndex: 'nextSeedYear',
      key: 'nextSeedYear',
      sorter: true,
    },
    {
      title: t('table:surveyComment'),
      dataIndex: 'surveyComment',
      key: 'surveyComment',
      width: 120,
      sorter: true,
      editable: true,
    },
    {
      title: t('table:tfpcv'),
      dataIndex: 'tfpcv',
      key: 'tfpcv',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:state'),
      width: 70,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      width: 120,
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      width: 70,
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      width: 120,
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:siteName'),
      width: 100,
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:status'),
      width: 70,
      dataIndex: 'eostatus',
      key: 'eostatus',
      sorter: true,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:latestFieldForm'),
      dataIndex: 'latestFieldForm',
      key: 'latestFieldForm',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:latestSeed'),
      width: 80,
      dataIndex: 'latestSeedColl',
      key: 'latestSeedColl',
      sorter: true,
    },
    {
      title: t('table:latestObserved'),
      width: 80,
      dataIndex: 'latestObserved',
      key: 'latestObserved',
      sorter: true,
    },
    {
      title: t('table:nbrPastSurveys'),
      dataIndex: 'nbrPastSurveys',
      key: 'nbrPastSurveys',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:latestEventYear'),
      dataIndex: 'latestEventYear',
      key: 'latestEventYear',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:latestEvent'),
      width: 120,
      dataIndex: 'latestEvent',
      key: 'latestEvent',
      sorter: true,
    },
    {
      title: t('table:div'),
      width: 80,
      dataIndex: 'division',
      key: 'division',
      sorter: true,
    },
    {
      title: t('table:precision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:stateRank'),
      dataIndex: 'stateRank',
      key: 'stateRank',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:extent'),
      width: 80,
      dataIndex: 'extent',
      key: 'extent',
      sorter: true,
    },
    {
      title: t('table:latestGenet'),
      width: 80,
      dataIndex: 'latestGenet',
      key: 'latestGenet',
      sorter: true,
    },
    {
      title: t('table:latestRamet'),
      dataIndex: 'latestRamet',
      key: 'latestRamet',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:genet'),
      dataIndex: 'maxGenet',
      key: 'maxGenet',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:ramet'),
      dataIndex: 'maxRamet',
      key: 'maxRamet',
      width: 80,
      sorter: true,
    },
  ];
};
