import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
// eslint-disable-next-line import/no-cycle
import { useAuth } from '../../../global/stores/auth';
import { roleTypes } from '../../../utils/roles';
// eslint-disable-next-line import/no-cycle
import PageWithNestedTable from '../../pages/PageWithNestedTable';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';

import { columns, expandableColumns } from './TableColumns';

const fetchNestedData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FILED_DATA_MANUAL_DETAILS(key), {
        method: 'get',
      })
    : {};

const fetchUpdate = (key, args) =>
  APIFetch(ENDPOINT_URLS.UPDATE_TRANSCRIPTION_STATUS(key), {
    method: 'put',
    body: JSON.stringify(args),
  });

export const FieldDataManualEnter = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const [currentPage, setCurrentPage] = useState(1);
  
  const defaultSortFields = [
    'State',
    'SpeciesName',
    'Eonumber',
    'Subpopulation',
  ];
  const defaultBodyRequest = {
    pagedInfo: {
      pageSize: 20,
      pageNumber: currentPage,
    },
    orderCriteria: defaultSortFields.map((propertyName) => ({
      orderOption: 'Ascending',
      propertyName,
    })),
  };

  const fetch = (args) =>
    APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_MANUAL, {
      method: 'post',
      body: JSON.stringify(args || defaultBodyRequest),
    });

  const { user } = useAuth();
  const role = user?.role;
  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const { loading, refetch: refetchUpdate } = useAPI(fetchUpdate, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const columnsForNestedTable = [
    {
      title: t('table:action'),
      key: 'operation',
      width: 120,
      render: (_, { eokey, folder, fullFilename, fileLink }) => (
        <Space size="middle">
          <Link
            to={`${pathname}/${eokey}?page=${currentPage}&folder=${folder}&fullFilename=${fullFilename}&fileLink=${fileLink}`}
          >
            {t('table:transcribeFile')}
          </Link>
        </Space>
      ),
    },
    ...expandableColumns(),
  ];

  const actions = ({ transcriptionDone, eokey }) => (
    <Typography.Link
      loading={loading}
      onClick={() =>
        refetchUpdate(eokey, {
          transcriptionDone: !transcriptionDone,
        }).then(() => {
          setIsForceRefetch(true);
        })
      }
      style={{
        marginLeft: 16,
      }}
    >
      {t(
        `table:${
          transcriptionDone ? 'setEoToUntranscribed' : 'setEoToTranscribed'
        }`
      )}
    </Typography.Link>
  );

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const roleAccess =
    role === roleTypes.Transcriber || role === roleTypes.Administrator;

  return (
    <PageWithNestedTable
      fetchData={fetch}
      fetchNestedData={fetchNestedData}
      defaultSortField={defaultSortFields}
      nestedTableKey="eokey"
      titleKey="ElementOccurrence:FieldDataManualEnter:title"
      descriptionKey="ElementOccurrence:FieldDataManualEnter:description"
      columns={columns()}
      expandableColumns={
        roleAccess ? columnsForNestedTable : expandableColumns()
      }
      withPageRedirect
      setCurrentPage={setCurrentPage}
      rowKeyFunc={(record) => record.eokey}
      scroll={{ x: 1700 }}
      getNestedDataActionText="showFiles"
      actionsColWidth={250}
      actions={roleAccess ? actions : null}
      updateTableData={isForceRefetch}
      orderCriteria={[
        {
          orderOption: 'Ascending',
          propertyName: 'State',
        },
        {
          orderOption: 'Ascending',
          propertyName: 'SpeciesName',
        },
        {
          orderOption: 'Ascending',
          propertyName: 'Eonumber',
        },
        {
          orderOption: 'Ascending',
          propertyName: 'Subpopulation',
        },
      ]}
    />
  );
};
