import React from 'react';

import { Form, Input } from 'antd';

const { Item } = Form;

const FormItem = ({ name, label, input = Input, disabled, withNoneAsEmpty, ...rest }) => {
  const CustomInput = input;

  return (
    <Item label={label} name={name} {...rest}>
      <CustomInput disabled={disabled} withnoneasempty={withNoneAsEmpty} />
    </Item>
  );
};

export default FormItem;
