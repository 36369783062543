import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general'

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:ownerType'),
      dataIndex: 'ownerType',
      key: 'ownerType',
      sorter: true,
    },
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      sorter: true,
    },
    {
      title: t('table:contactDate'),
      dataIndex: 'contactDate',
      key: 'contactDate',
      sorter: true,
      render: (text) => text ? getMomentDate(text).format('YYYY-MM-DD') : ''
    },
    {
      title: t('table:contactMethod'),
      dataIndex: 'contactMethod',
      key: 'contactMethod',
      sorter: true,
    },
    {
      title: t('table:nptcontactPerson'),
      dataIndex: 'nptcontactPerson',
      key: 'nptcontactPerson',
      sorter: true,
    },
    {
      title: t('table:ownerPermission'),
      dataIndex: 'ownerPermission',
      key: 'ownerPermission',
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      sorter: true,
    },
  ];
};
