import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { action } from '../../../../shared/EditableTable/components/Action';
import { actionContent } from '../utils';
import PageWithTable from '../../../pages/PageWithTable';
import { paramsStateForBE } from '../../../../utils/general';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetchPermissionByEO = (args) => {
  if (!args) return {};

  return APIFetch(ENDPOINT_URLS.GET_PERMISSIONS_BY_EO_PAGED, {
    method: 'post',
    body: JSON.stringify(args),
  })
}

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PERMISSIONS_BY_EO_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const PermissionByEO = () => {
  const { t } = useTranslation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, record) => actionContent(record),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={(args) => fetchPermissionByEO(args)}
      fetchExport={fetchExport}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="PCVAdministration:PermissionByEO"
      descriptionKey="PCVAdministration:PermissionByEODescription"
      searchForm={SearchForm}
      columns={allColumns}
      scroll={{ x: 1600 }}
      paramsForBE={paramsStateForBE}
      beSearchConfig={beSearchConfig}
    />
  );
};

export default PermissionByEO;
