import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PCV_TF_SIGNUP_STATUS, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : [];

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_SIGNUP_STATUS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const SignUpStatus = () => (
  <PageWithTable
    fetchExport={fetchExport}
    titleKey="PCVTF:SignUpStatus"
    descriptionKey="PCVTF:SignUpStatusDescription"
    fetchData={fetch}
    columns={columns()}
    rowKey={(record) => record.eokey}
    defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
    onlyOrderParams
    isDataArray
    scroll={{ x: 1500 }}
  />
);

export default SignUpStatus;
