import React from 'react';
import { DatePicker, Input, InputNumber } from 'antd';
import moment from 'moment';

import {
  dataPercent,
  dataYesNo,
  dataYesNoWithoutNone,
} from '../../../global/constants';
// import { getCurrentDate } from '../../../utils/general'
import CountPrecisionsSelect from '../../../shared/components/select/CountPrecisionsSelect';
import LocationPrecisionsForFieldEnterSelect from '../../../shared/components/select/LocationPrecisionsForFieldEnterSelect';
import SlopesSelect from '../../../shared/components/select/SlopesSelect';
import SurveyorsAutocomplete from '../../../shared/components/autocomplete/SurveyorsAutocomplete';
import TopoPositionsSelect from '../../../shared/components/select/TopoPositionsSelect';
import AspectsSelect from '../../../shared/components/select/AspectsSelect';
import LightsSelect from '../../../shared/components/select/LightsSelect';
import MoistureRegimesSelect from '../../../shared/components/select/MoistureRegimesSelect';
import { Select } from '../../../shared/components/select';
import StatesSelect from '../../../shared/components/select/StatesSelect';
import SpeciesNamesAutocomplete from '../../../shared/components/autocomplete/SpeciesNamesAutocomplete';
import { DATA_FT2_M2, DATA_FT_M } from '../../FieldForm/constants';
import Uploader from '../../../shared/components/Upload';

const { TextArea } = Input;

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const pctValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 100,
  },
];

const latitudeRules = [
  {
    type: 'number',
    min: 0,
    max: 47.5,
  },
];

const longitudeRules = [
  {
    type: 'number',
    min: -74,
    max: 0,
  },
];

const positiveRules = [
  {
    type: 'integer',
    min: 0,
  },
];

const genetsRametsRules = [
  {
    type: 'integer',
    message: 'Number is too large',
  },
];

const previousYear = +moment().format('YYYY') - 1;
// const today = getCurrentDate();

const disabledDate = (current) => {
  // const tooLate = +current.format('YYYYMMDD') > +today;
  const tooEarly = current.year() < previousYear;

  // return tooEarly || tooLate;
  return tooEarly;
};

export const editFormConfig = [
  {
    name: 'state',
    label: 'State',
    input: (props) => <StatesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'speciesName',
    label: 'Species Name',
    input: (props) => <SpeciesNamesAutocomplete {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'town',
    label: 'Town',
    rules: [
      {
        required: true,
        max: 60,
      },
    ],
  },
  {
    name: 'siteName',
    label: 'Site Name',
    rules: [
      {
        max: 100,
      },
    ],
  },
  {
    name: 'surveyDate',
    label: 'Survey Date',
    rules: [
      {
        required: true,
      },
    ],
    input: (props) => (
      <DatePicker
        {...props}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  {
    name: 'leadSurveyor',
    label: 'Lead Surveyor',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'surveyor2',
    label: 'Surveyor 2',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
  },
  {
    name: 'surveyor3',
    label: 'Surveyor 3',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
  },
  {
    name: 'surveyor4',
    label: 'Surveyor 4',
    input: (props) => <SurveyorsAutocomplete {...props} withoutEmpty />,
  },
  {
    name: 'directions',
    label: 'Directions',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'ramets',
    label: 'Ramets',
    input: (props) => <CustomInputNumber {...props} />,
    rules: genetsRametsRules,
  },
  {
    name: 'genets',
    label: 'Genets',
    input: (props) => <CustomInputNumber {...props} />,
    rules: genetsRametsRules,
  },
  {
    name: 'countPrecision',
    label: 'Count Precision',
    input: (props) => <CountPrecisionsSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
    text: 'countPrecision',
  },
  {
    name: 'genetRametSize',
    label: `How were genets/ramets determined`,
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'speciesDiagnostics',
    label: 'Species Diagnostics',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
    rules: [
      {
        required: true,
      },
      {
        min: 20,
        max: 1000,
      },
    ],
  },
  {
    name: 'confidenceInId',
    label: 'Confidence InID',
    input: (props) => <Select props={props} data={dataYesNoWithoutNone} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'observedSitePrev',
    label: 'Observed Site Previously',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'referenceUsed',
    label: 'Reference Used',
  },
  {
    name: 'latitude1',
    label: 'Latitude 1 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: latitudeRules,
  },
  {
    name: 'longitude1',
    label: 'Longitude 1 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: longitudeRules,
  },
  {
    name: 'description1',
    label: 'Description 1',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'latitude2',
    label: 'Latitude 2 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: latitudeRules,
  },
  {
    name: 'longitude2',
    label: 'Longitude 2 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: longitudeRules,
  },
  {
    name: 'description2',
    label: 'Description 2',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'latitude3',
    label: 'Latitude 3 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: latitudeRules,
  },
  {
    name: 'longitude3',
    label: 'Longitude 3 (decimal degrees)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: longitudeRules,
  },
  {
    name: 'description3',
    label: 'Description 3',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'locationPrecision',
    label: 'Location Precision',
    withNoneAsEmpty: true,
    input: (props) => <LocationPrecisionsForFieldEnterSelect {...props} />,
  },
  {
    name: 'populationComment',
    label: 'Population Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'pctLeaf',
    label: 'Pct Leaf/Vegetative Only',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
    text: 'pctLeaf',
  },
  {
    name: 'pctBud',
    label: 'Pct Bud',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctFlower',
    label: 'Pct Flower',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctImmFruit',
    label: 'Pct Immature Fruit',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctMatFruit',
    label: 'Pct Mature Fruit',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSeed',
    label: 'Pct Seed',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctDormant',
    label: 'Pct Dormant',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSeedling',
    label: 'Pct Seedling',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
    text: 'pctSeedling',
  },
  {
    name: 'pctImmature',
    label: 'Pct Immature',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctMature',
    label: 'Pct Mature',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSenesc',
    label: 'Pct Senescent',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctUnknown',
    label: 'Pct Unknown',
    input: (props) => <CustomInputNumber {...props} />,
    rules: pctValidationRules,
  },
  {
    name: 'elevationMin',
    label: 'Elevation Min',
    input: (props) => <CustomInputNumber {...props} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  {
    name: 'elevationMax',
    label: 'Elevation Max',
    input: (props) => <CustomInputNumber {...props} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  {
    name: 'elevUnits',
    label: 'Elev Units',
    input: (props) => <Select props={props} data={DATA_FT_M} />,
  },
  {
    name: 'populationArea',
    label: 'Population Area',
    input: (props) => <CustomInputNumber {...props} />,
    rules: [
      {
        type: 'number',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'popAreaUnits',
    label: 'Pop Area Units',
    input: (props) => <Select props={props} data={DATA_FT2_M2} />,
  },
  {
    name: 'entirePopulation',
    label: 'Entire Population',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },

  {
    name: 'topoPosition',
    label: 'TopoPosition',
    withNoneAsEmpty: true,
    input: (props) => <TopoPositionsSelect {...props} />,
  },
  {
    name: 'aspect',
    label: 'Aspect',
    withNoneAsEmpty: true,
    input: (props) => <AspectsSelect {...props} />,
  },
  {
    name: 'slope',
    label: 'Slope',
    withNoneAsEmpty: true,
    input: (props) => <SlopesSelect {...props} />,
  },
  {
    name: 'light',
    label: 'Light',
    withNoneAsEmpty: true,
    input: (props) => <LightsSelect {...props} />,
  },
  {
    name: 'moistureRegime',
    label: 'Moisture Regime',
    withNoneAsEmpty: true,
    input: (props) => <MoistureRegimesSelect {...props} />,
  },
  {
    name: 'siteCharComment',
    label: 'Site Characteristics Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },

  {
    name: 'assocSpecies',
    label: 'Associated Species',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'invSpecies',
    label: 'Invasive Species',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'naturalCommunity',
    label: 'Natural Community',
  },

  {
    name: 'alteredHydrology',
    label: 'Altered Hydrology',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'directHumanThreat',
    label: 'Direct Human Threat',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'erosion',
    label: 'Erosion',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'fireSuppression',
    label: 'Fire Suppression',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'herbivory',
    label: 'Herbivory',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'invasiveSpecies',
    label: 'Invasive Species',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'succession',
    label: 'Succession',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'noThreat',
    label: 'No Threat',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataYesNo} />,
  },
  {
    name: 'threatScope',
    label: 'Threat Scope',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataPercent} />,
  },
  {
    name: 'threatSeverity',
    label: 'Threat Severity',
    withNoneAsEmpty: true,
    input: (props) => <Select props={props} data={dataPercent} />,
  },

  {
    name: 'disturbanceComment',
    label: 'Disturbance Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'mgtComment',
    label: 'Management Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'otherComment',
    label: 'Other Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'ownerComment',
    label: 'Owner Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
    rules: [
      {
        required: true,
      },
      {
        min: 20,
        max: 1000,
      },
    ],
  },
  {
    name: 'seedPlantsSampled',
    label: 'Seed Plants Sampled',
    input: (props) => <CustomInputNumber {...props} />,
    rules: positiveRules,
  },
  {
    name: 'seedPlantsInFruit',
    label: 'Seed Plants In Fruit',
    input: (props) => <CustomInputNumber {...props} />,
    rules: positiveRules,
  },
  {
    name: 'seedDateSent',
    label: 'Seed Date Sent',
    input: (props) => (
      <DatePicker
        {...props}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  {
    name: 'seedComment',
    label: 'Seed Comment',
    input: (props) => <TextArea {...props} autoSize={{ minRows: 3 }} />,
  },
  {
    name: 'firstName',
    label: 'First Name',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'lastName',
    label: 'Last Name',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'signatureDate',
    label: 'Signature Date',
    input: (props) => (
      <DatePicker
        {...props}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
    rules: [
      {
        required: true,
      },
    ],
    infoKey: 'signatureDate',
  },
  {
    name: 'files',
    label: 'Files',
    input: (props) => (
      <Uploader {...props} manually maxCount={25} maxSize={20 * 1024 * 1024} />
    ),
    infoKey: 'uploadFilesNew',
  },
];
