import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const LightsExistingSelect = (props) => {
  const { existingLightsData, existingLightsLoading, updateExistingLights } =
    useStore();

  useEffect(() => {
    if (!existingLightsData.length && !existingLightsLoading)
      updateExistingLights();
  }, []);

  return (
    <Select
      props={props}
      data={existingLightsData}
      loading={existingLightsLoading}
    />
  );
};

export default LightsExistingSelect;
