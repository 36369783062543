import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const CountPrecisionsSelect = (props) => {
  const { countPrecisionsData, countPrecisionsLoading, updateCountPrecisions } =
    useStore();

  useEffect(() => {
    if (!countPrecisionsData.length && !countPrecisionsLoading)
      updateCountPrecisions();
  }, []);

  return (
    <Select
      props={props}
      data={countPrecisionsData}
      loading={countPrecisionsLoading}
    />
  );
};

export default CountPrecisionsSelect;
