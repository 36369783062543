import React from 'react';

import CharacterGroupSelect from "../../../shared/components/select/CharacterGroupSelect";

export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'CharacterGroupID',
    label: 'Character Group',
    input: (props) => <CharacterGroupSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
