import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      width: 60,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      width: 150,
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 120,
      sorter: true,
    },
    {
      title: t('table:town'),
      width: 90,
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      width: 100,
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      width: 70,
      dataIndex: 'eostatus',
      key: 'eostatus',
      sorter: true,
    },
    {
      title: t('table:eotype'),
      width: 80,
      dataIndex: 'eotype',
      key: 'eotype',
      sorter: true,
    },
    {
      title: t('table:latitude'),
      dataIndex: 'latitude',
      key: 'latitude',
      width: 110,
      sorter: true,
    },
    {
      title: t('table:longitude'),
      dataIndex: 'longitude',
      key: 'longitude',
      width: 110,
      sorter: true,
    },
    {
      title: t('table:locPrecision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:firstObs'),
      width: 80,
      dataIndex: 'firstObs',
      key: 'firstObs',
      sorter: true,
    },
    {
      title: t('table:lastObs'),
      width: 80,
      dataIndex: 'lastObs',
      key: 'lastObs',
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      width: 75,
      sorter: true,
    },
    {
      title: t('table:nextSurveyYear'),
      dataIndex: 'nextSurveyYear',
      key: 'nextSurveyYear',
      width: 95,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:nextSeedYear'),
      width: 95,
      dataIndex: 'nextSeedYear',
      key: 'nextSeedYear',
      editable: true,
      sorter: true,
    },
    {
      title: t('table:surveyInterval'),
      width: 95,
      dataIndex: 'surveyInterval',
      key: 'surveyInterval',
      editable: true,
      sorter: true,
    },
    {
      title: t('table:seedInterval'),
      dataIndex: 'seedInterval',
      key: 'seedInterval',
      width: 95,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:firstFlower'),
      dataIndex: 'firstFlower',
      key: 'firstFlower',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:lastFlower'),
      width: 100,
      dataIndex: 'lastFlower',
      key: 'lastFlower',
      sorter: true,
    },
    {
      title: t('table:firstFruit'),
      width: 100,
      dataIndex: 'firstFruit',
      key: 'firstFruit',
      sorter: true,
    },
    {
      title: t('table:lastFruit'),
      dataIndex: 'lastFruit',
      key: 'lastFruit',
      width: 100,
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:countyKey'),
      width: 60,
      dataIndex: 'county',
      key: 'county',
    },
    {
      title: t('table:ownerNotes'),
      width: 210,
      dataIndex: 'ownerNotes',
      key: 'ownerNotes',
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 210,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 400,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:commentsRegardingSite'),
      dataIndex: 'commentsRegardingSite',
      key: 'commentsRegardingSite',
      width: 210,
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 210,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 400,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:eoid'),
      width: 60,
      dataIndex: 'eoid',
      key: 'eoid',
    },
    {
      title: t('table:popOrigin'),
      dataIndex: 'popOrigin',
      key: 'popOrigin',
      width: 90,
    },
    {
      title: t('table:directions'),
      width: 210,
      dataIndex: 'directions',
      key: 'directions',
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 210,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 400,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:surveyDate'),
      width: 80,
      dataIndex: 'surveyDate',
      key: 'surveyDate',
    },
    {
      title: t('table:minElev'),
      dataIndex: 'minElev',
      key: 'minElev',
      width: 80,
    },
    {
      title: t('table:maxElev'),
      width: 80,
      dataIndex: 'maxElev',
      key: 'maxElev',
    },
    {
      title: t('table:mgtNotes'),
      dataIndex: 'mgtNotes',
      key: 'mgtNotes',
      width: 210,
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 210,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 600,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:specimens'),
      width: 100,
      dataIndex: 'specimens',
      key: 'specimens',
    },
    {
      title: t('table:sensitive'),
      width: 80,
      dataIndex: 'sensitive',
      key: 'sensitive',
    },
    {
      title: t('table:eorank'),
      dataIndex: 'eorank',
      key: 'eorank',
      width: 80,
    },
    {
      title: t('table:eorankDate'),
      width: 80,
      dataIndex: 'eorankDate',
      key: 'eorankDate',
    },
    {
      title: t('table:popArea'),
      dataIndex: 'popArea',
      key: 'popArea',
      width: 80,
    },
    {
      title: t('table:subEo'),
      width: 80,
      dataIndex: 'subEo',
      key: 'subEo',
    },
    {
      title: t('table:parentEo'),
      width: 80,
      dataIndex: 'parentEo',
      key: 'parentEo',
    },
    {
      title: t('table:parentYn'),
      dataIndex: 'parentYn',
      key: 'parentYn',
      width: 80,
    },
    {
      title: t('table:idconfirm'),
      width: 80,
      dataIndex: 'idconfirm',
      key: 'idconfirm',
    },
    {
      title: t('table:eocondition'),
      dataIndex: 'eocondition',
      key: 'eocondition',
      width: 80,
    },
    {
      title: t('table:eosize'),
      width: 80,
      dataIndex: 'eosize',
      key: 'eosize',
    },
    {
      title: t('table:eolandscape'),
      width: 80,
      dataIndex: 'eolandscape',
      key: 'eolandscape',
    },
    {
      title: t('table:eotracking'),
      dataIndex: 'eotracking',
      key: 'eotracking',
      width: 90,
    },
    {
      title: t('table:normspecies'),
      width: 80,
      dataIndex: 'normspecies',
      key: 'normspecies',
    },
    {
      title: t('table:gisspecies'),
      dataIndex: 'gisspecies',
      key: 'gisspecies',
      width: 80,
    },
    {
      title: t('table:stateSpecies'),
      width: 80,
      dataIndex: 'stateSpecies',
      key: 'stateSpecies',
    },
  ];
};
