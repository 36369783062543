import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  
  return [
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      width: 110,
      sorter: true,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
      width: 130,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 160,
    },
    {
      title: t('table:permitTypeRequested'),
      dataIndex: 'permitTypeRequested',
      key: 'permitTypeRequested',
      editable: true,
      sorter: true,
      width: 120,
    },
    {
      title: t('table:datePermitRequestSubmitted'),
      dataIndex: 'datePermitRequestSubmitted',
      key: 'datePermitRequestSubmitted',
      editable: true,
      sorter: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:datePermitRequestAcknowledged'),
      dataIndex: 'datePermitRequestAcknowledged',
      key: 'datePermitRequestAcknowledged',
      editable: true,
      sorter: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:datePermitResponseRecd'),
      dataIndex: 'datePermitResponseRecd',
      key: 'datePermitResponseRecd',
      editable: true,
      sorter: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:permitResponse'),
      dataIndex: 'permitResponse',
      key: 'permitResponse',
      editable: true,
      sorter: true,
      width: 150,
    },
    {
      title: t('table:permitStartDateShort'),
      dataIndex: 'permitStartDate',
      key: 'permitStartDate',
      editable: true,
      sorter: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:permitEndDateShort'),
      dataIndex: 'permitEndDate',
      key: 'permitEndDate',
      editable: true,
      sorter: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:permitDocument1Short'),
      dataIndex: 'permitDocument1',
      key: 'permitDocument1',
      editable: true,
      sorter: true,
      width: 150,
      render: (text, { permitDocumentLink1 }) =>
        text ? <a href={permitDocumentLink1}>{text}</a> : '',
    },
    {
      title: t('table:permitDocument2Short'),
      dataIndex: 'permitDocument2',
      key: 'permitDocument2',
      editable: true,
      sorter: true,
      width: 150,
      render: (text, { permitDocumentLink2 }) =>
        text ? <a href={permitDocumentLink2}>{text}</a> : '',
    },
    {
      title: t('table:permitCommentShort'),
      dataIndex: 'permitComment',
      key: 'permitComment',
      editable: true,
      sorter: true,
      width: 120,
    },
  ];
};
