import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:owner'),
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: t('table:eokey'),
      dataIndex: 'elementOccurrence',
      key: 'elementOccurrence',
    },
    {
      title: t('table:assessorLot'),
      dataIndex: 'assessorLot',
      key: 'assessorLot',
    },
    {
      title: t('table:sourceOwner'),
      dataIndex: 'sourceOwner',
      key: 'sourceOwner',
    },
  ];
};
