import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetch = (args) => {
  if (!args) return {};
  const { orderCriteria, pagedInfo, searchCriteria } = args;
  const division =
    searchCriteria.find((e) => e.propertyName === 'Division') || 'error';
  const speciesName = searchCriteria.find(
    (e) => e.propertyName === 'SpeciesName'
  );
  return APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_SEED_BY_SPECIES, {
    method: 'post',
    body: JSON.stringify({
      paginationInfo: {
        pagedInfo,
        orderCriteria,
        searchCriteria: null,
      },
      speciesName: speciesName.value,
      division: division === 'error' ? null : division.value,
    }),
  });
};

const fetchExport = (args) => {
  if (!args) return null;
  const { orderCriteria, pagedInfo, searchCriteria } = args;
  const division =
    searchCriteria.find((e) => e.propertyName === 'Division') || 'error';
  const speciesName = searchCriteria.find(
    (e) => e.propertyName === 'SpeciesName'
  );
  return APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_SEED_BY_SPECIES_EXCEL, {
    method: 'post',
    body: JSON.stringify({
      paginationInfo: {
        pagedInfo,
        orderCriteria,
        searchCriteria: null,
      },
      speciesName: speciesName.value,
      division: division === 'error' ? null : division.value,
    }),
  });
};

export const FieldDataSeedBySpecies = () => (
  <PageWithTable
    fetchData={fetch}
    fetchExport={fetchExport}
    defaultSortField={['SpeciesName', 'Year']}
    titleKey="ElementOccurrence:FieldDataSeedBySpecies"
    descriptionKey="ElementOccurrence:FieldDataSeedBySpeciesDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 3300 }}
  />
);
