import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'state',
  'ownerTotal',
  'ownerPrivateIndiv',
  'ownerPrivateOrg',
  'ownerNonprofit',
  'ownerLandTrust',
  'ownerFederal',
  'ownerState',
  'ownerTown',
  'ownerTribal',
  'ownerUtility',
  'ownerUnknown',
];

const fetchOwnerTypeState = () =>
  APIFetch(ENDPOINT_URLS.GET_OWNER_TYPE_STATE, {
    method: 'get',
  });

export const OwnerTypeState = () => {
  const { data, loading } = useAPI(() => fetchOwnerTypeState(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('state', data);

  return (
    <PageWithSimpleTable
      titleKey="Owner:OwnerTypeState"
      descriptionKey="Owner:OwnerTypeStateDescription"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};
