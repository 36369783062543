export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'FullText',
    label: 'Research question',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
];
