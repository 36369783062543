import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const OwnerPermissionsSelect = (props) => {
  const {
    ownerPermissionsData,
    ownerPermissionsLoading,
    updateOwnerPermissions,
  } = useStore();

  useEffect(() => {
    if (!ownerPermissionsData.length && !ownerPermissionsLoading)
      updateOwnerPermissions();
  }, []);

  return (
    <Select
      props={props}
      data={ownerPermissionsData}
      loading={ownerPermissionsLoading}
    />
  );
};

export default OwnerPermissionsSelect;
