import React, { useEffect, useState } from 'react';
import { Select as AntdSelect } from 'antd';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';

import { Loader } from '../loader';

import classes from './styles.module.scss'

const { Option } = AntdSelect;

export const Select = ({ props, data, loading, width }) => {
  const { t } = useTranslation();

  const {
    multiple,
    style = {},
    withAll,
    withAllValueNull,
    withNone,
    withNoneAsEmpty,
    width: widthFromProps,
    ...otherProps
  } = props;
  if (width) style.width = width;
  if (widthFromProps) style.width = widthFromProps;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data.length) {
      const resultOptions = withNone
        ? [{ itemKey: 0, item: t('form:none') }, ...data]
        : [...data];

      setOptions(
        withAll
          ? [{ itemKey: '', item: t('form:all') }, ...resultOptions]
          : [...resultOptions]
      );

      if (withAllValueNull) {
        setOptions(
          withAllValueNull
            ? [{ itemKey: 'null', item: t('form:all') }, ...resultOptions]
            : [...resultOptions]
        );
      }

      if (withNoneAsEmpty) {
        setOptions(
          withNoneAsEmpty
            ? data.map(e => e.itemKey === 'NONE' ? { ...e, item: '' } : e)
            : [...resultOptions]
        )
      }
    }
  }, [data]);

  return !loading ? (
    <AntdSelect
      {...otherProps}
      dropdownClassName={classNames(classes.select)}
      virtual={false}
      allowClear={multiple}
      mode={multiple ? 'multiple' : undefined}
      style={style}
      loading={loading}
    >
      {options?.length &&
        options.map(({ itemKey, item }) => (
          <Option key={itemKey} value={itemKey.toString()}>
            {itemKey === 'NONE' ? ' ' : item}
          </Option>
        ))}
    </AntdSelect>
  ) : (
    <Loader>
      <AntdSelect allowClear mode="multiple" {...otherProps}>
        <Option value=" "> </Option>
      </AntdSelect>
    </Loader>
  );
};
