import React from 'react';

import StatesSelect from '../../../../shared/components/select/StatesSelect';

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'EO species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'Town',
    label: 'EO town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SiteName',
    label: 'EO sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'SpeciesName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Synonyms',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
];
