import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SurveyorTypesExceptAdminSelect = (props) => {
  const {
    surveyorTypesExceptAdminData,
    surveyorTypesExceptAdminLoading,
    updateSurveyorTypesExceptAdmin,
  } = useStore();

  useEffect(() => {
    if (
      !surveyorTypesExceptAdminData.length &&
      !surveyorTypesExceptAdminLoading
    )
      updateSurveyorTypesExceptAdmin();
  }, []);

  return (
    <Select
      props={props}
      width={150}
      data={surveyorTypesExceptAdminData}
      loading={surveyorTypesExceptAdminLoading}
    />
  );
};

export default SurveyorTypesExceptAdminSelect;
