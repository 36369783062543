import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
    },
    {
      title: t('table:outreachOrganizationType'),
      dataIndex: 'outreachOrganizationType',
      key: 'outreachOrganizationType',
      sorter: true,
    },
    {
      title: t('table:association'),
      dataIndex: 'association',
      key: 'association',
      sorter: true,
    },
    {
      title: t('table:contact'),
      dataIndex: 'contact',
      key: 'contact',
      sorter: true,
    },
    {
      title: t('table:notes'),
      dataIndex: 'notes',
      key: 'notes',
      sorter: true,
    },
  ];
};
