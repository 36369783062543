import { Input } from 'antd';

import { validatePassword, confirmPassword } from '../utils';

export const formConfig = [
  {
    name: 'password',
    label: 'New Password',
    input: Input.Password,
    rules: [
      {
        required: true,
      },
      ...validatePassword,
    ],
  },
  {
    name: 'confirmPassword',
    label: 'Confirm New Password',
    input: Input.Password,
    dependencies: ['password'],
    hasFeedback: true,
    rules: [
      {
        required: true,
      },
      ...confirmPassword,
    ],
  },
];
