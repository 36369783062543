import React from 'react';

import DivisionsSelect from '../../../shared/components/select/DivisionsSelect';

export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'Division',
    label: 'Division',
    input: (props) => <DivisionsSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
