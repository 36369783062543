import React from 'react';

import { Select } from '../../../../shared/components/select';

export const formConfig = (data) => [
  {
    name: 'state',
    label: 'State',
    input: (props) => <Select props={props} data={data} />,
    rules: [
      {
        required: true,
      },
    ],
  },
];
