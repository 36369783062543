import React from 'react';
import _ from 'lodash';
import { notification } from 'antd';
import JSPDF from 'jspdf';
import moment from 'moment';

import {
  BREADCRUMBS_DETAILS_KEY,
  PDF_FIELDS_ORDER,
  RENAMABLE_FIELDS,
  RENAMABLE_TARGET_FIELDS,
} from '../global/constants';
import {
  DATA_FT2_M2,
  DATA_FT_M,
  DATA_YES_NO_NONE,
} from '../views/FieldForm/constants';
import { accessByRolesConfig } from '../global/accessByRolesConfig';

import { roleTypes } from './roles';

const minYear = +moment().format('YYYY') - 30;
const maxYear = +moment().format('YYYY') + 30;

export const highlightTotalValue = (propName, data, fieldChange) => {
  const valueRow = data.filter((item) =>
    fieldChange ? item[propName] === fieldChange : !item[propName]
  );
  const totalValueRow = valueRow[valueRow.length - 1];

  if (!totalValueRow) {
    return data;
  }

  const rowsWithoutTotalValue = data.filter(
    (item) => !_.isEqual(item, totalValueRow)
  );
  const newTotalValueRow = {};

  Object.keys(totalValueRow).map((key) => {
    newTotalValueRow[key] = (
      <strong>{propName === key ? 'Total' : totalValueRow[key]}</strong>
    );

    return key;
  });
  return [...rowsWithoutTotalValue, { ...newTotalValueRow }];
};

export const setUnknownValue = (propName, data) => {
  const valueRow = data.filter((item) => !item[propName]);

  if (!valueRow) {
    return data;
  }
  const rowsWithValue = valueRow.map((valueRowItem) =>
    data.filter((item) => !_.isEqual(item, valueRowItem))
  );
  const newValueRow = {};
  valueRow.map((item) =>
    Object.keys(item).map((key) => {
      newValueRow[key] = (
        <p>{propName === key ? `Other ${propName}` : item[key]}</p>
      );

      return key;
    })
  );

  const allRowsWithValue = rowsWithValue[0];
  if (allRowsWithValue) {
    return [{ ...newValueRow }, ...allRowsWithValue];
  }
  return [];
};

export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

export const paramsForBE = (searchParameters, beSearchConfig) =>
  searchParameters.length
    ? beSearchConfig.map((param) => {
        const { equivalentPropertyName, propertyName, ...others } = param;
        const key = equivalentPropertyName || propertyName;
        const { value } = searchParameters.find(
          ({ propertyName: searchParamPropName }) => searchParamPropName === key
        );

        return {
          ...others,
          propertyName,
          value,
        };
      })
    : null;

export const paramsStateForBE = (searchParameters, beSearchConfig) => {
  const params = searchParameters.length
    ? beSearchConfig.map((param) => {
        const { propertyName, ...others } = param;
        const key = propertyName;

        const { value } = searchParameters.find(
          ({ propertyName: searchParamPropName }) => searchParamPropName === key
        );

        return {
          ...others,
          propertyName,
          value,
        };
      })
    : null;

  const parameters = [];

  searchParameters
    .filter((item) => item.propertyName === 'State')
    ?.map((item) => parameters.push(...params, item));

  return parameters.length ? parameters : params;
};

export const getParentPathname = (pathname) => {
  const breadcrumbsDetails = JSON.parse(
    localStorage.getItem(BREADCRUMBS_DETAILS_KEY)
  );
  let parentPathname = pathname.split('/');
  parentPathname = parentPathname.slice(0, parentPathname.length - 1).join('/');

  const { search } = breadcrumbsDetails[parentPathname] || {};

  return search ? [parentPathname, search].join('') : parentPathname;
};

export const deletePreviousSearch = (pathname) => {
  const arrayOfPath = pathname.split('?');
  return arrayOfPath[0];
};

export const validateMessages = {
  /* eslint-disable-next-line no-template-curly-in-string */
  required: '${label} is required. Please enter a value.',
  string: {
    /* eslint-disable-next-line no-template-curly-in-string */
    len: '${label} should contain ${len} characters',
    /* eslint-disable-next-line no-template-curly-in-string */
    max: 'Length of ${label} should be less than ${max} symbols',
    /* eslint-disable-next-line no-template-curly-in-string */
    range: '${label} should have at least ${min} and up to ${max} characters',
  },
  number: {
    /* eslint-disable-next-line no-template-curly-in-string */
    min: '${label} cannot be less than ${min}',
    /* eslint-disable-next-line no-template-curly-in-string */
    max: '${label} cannot be greater than ${max}',
    /* eslint-disable-next-line no-template-curly-in-string */
    range: 'Should contain values from ${min} to ${max}',
  },
  types: {
    /* eslint-disable-next-line no-template-curly-in-string */
    email: '${label} is not valid. Please check it',
    /* eslint-disable-next-line no-template-curly-in-string */
    integer: 'Should be only Integer',
  },
};

export const getFormData = (props) => {
  const { files, ...other } = props;

  const formData = new FormData();

  Object.keys(other).map((propsKey) => {
    if (props[propsKey] !== null && props[propsKey] !== undefined) {
      formData.append(propsKey, props[propsKey]);
    }

    return propsKey;
  });

  if (files) {
    files.map((file) => {
      formData.append('files', file);

      return file;
    });
  }

  return formData;
};

export const validateSeedPlants = (fieldName, fieldValue, values) => {
  const { ramets, seedPlantsSampled, seedPlantsInFruit, genets } = values;

  let fields;

  const isRamets = !!ramets;
  const isSeedPlantsSampled = seedPlantsSampled || seedPlantsSampled === 0;
  const isSeedPlantsInFruit = seedPlantsInFruit || seedPlantsInFruit === 0;

  if (fieldName === 'seedPlantsSampled' && isRamets && fieldValue > ramets) {
    fields = [
      {
        name: fieldName,
        errors: ['Seed Plants Sampled should be less than Ramets or equal'],
      },
    ];
  }

  if (
    fieldName === 'ramets' &&
    isSeedPlantsSampled &&
    fieldValue < seedPlantsSampled
  ) {
    fields = [
      {
        name: fieldName,
        errors: ['Ramets should be more than Seed Plants Sampled'],
      },
    ];
  }

  if (
    fieldName === 'seedPlantsInFruit' &&
    isSeedPlantsSampled &&
    fieldValue < seedPlantsSampled
  ) {
    fields = [
      {
        name: fieldName,
        errors: [
          'Seed Plants In Fruit should be more than Seed Plants Sampled or equal',
        ],
      },
    ];
  }

  if (
    fieldName === 'seedPlantsInFruit' &&
    ramets &&
    genets &&
    Math.max(ramets, genets) < fieldValue
  ) {
    fields = [
      {
        name: fieldName,
        errors: [
          'Seed Plants In Fruit should be less than max(ramets, genets) or equal',
        ],
      },
    ];
  }

  if (
    fieldName === 'seedPlantsInFruit' &&
    ramets &&
    !genets &&
    fieldValue > ramets
  ) {
    fields = [
      {
        name: fieldName,
        errors: ['Seed Plants In Fruit should be less than ramets or equal'],
      },
    ];
  }

  if (
    fieldName === 'ramets' &&
    ramets &&
    !genets &&
    seedPlantsInFruit &&
    seedPlantsInFruit > fieldValue
  ) {
    fields = [
      {
        name: fieldName,
        errors: ['Ramets should be more than Seed Plants In Fruit or equal'],
      },
    ];
  }

  if (
    fieldName === 'seedPlantsInFruit' &&
    !ramets &&
    genets &&
    fieldValue > genets
  ) {
    fields = [
      {
        name: fieldName,
        errors: ['Seed Plants In Fruit should be less than genets or equal'],
      },
    ];
  }

  if (
    fieldName === 'genets' &&
    !ramets &&
    genets &&
    seedPlantsInFruit &&
    seedPlantsInFruit > fieldValue
  ) {
    fields = [
      {
        name: fieldName,
        errors: ['Genets should be more than Seed Plants In Fruit or equal'],
      },
    ];
  }

  if (
    fieldName === 'seedPlantsSampled' &&
    isSeedPlantsInFruit &&
    fieldValue > seedPlantsInFruit
  ) {
    fields = [
      {
        name: fieldName,
        errors: [
          'Seed Plants Sampled should be less than Seed Plants In Fruit or equal',
        ],
      },
    ];
  }

  return fields;
};

export const validateUser = (fieldName, fieldValue, user) => {
  const { firstName, lastName } = user;

  let fields;

  if (fieldName === 'lastName' && fieldValue !== lastName) {
    fields = [
      {
        name: fieldName,
        errors: ['Last Name should be the same as in your Account Settings'],
      },
    ];
  }

  if (fieldName === 'firstName' && fieldValue !== firstName) {
    fields = [
      {
        name: fieldName,
        errors: ['First Name should be the same as in your Account Settings'],
      },
    ];
  }

  return fields;
};

export const handleUnits = (fieldName, fieldValue, disabledFields, values) => {
  const { elevationMin, elevationMax } = values;

  let newDisabledFields;
  let fields;

  if (fieldName === 'populationArea') {
    if (fieldValue && disabledFields.includes('popAreaUnits')) {
      newDisabledFields = disabledFields.filter(
        (name) => name !== 'popAreaUnits'
      );

      fields = {
        popAreaUnits: DATA_FT2_M2[0].itemKey,
      };
    } else if (!fieldValue && !disabledFields.includes('popAreaUnits')) {
      newDisabledFields = [...disabledFields, 'popAreaUnits'];

      fields = {
        popAreaUnits: '',
      };
    }
  }

  if (fieldName === 'elevationMin' || fieldName === 'elevationMax') {
    if (fieldValue && disabledFields.includes('elevUnits')) {
      newDisabledFields = disabledFields.filter((name) => name !== 'elevUnits');

      fields = {
        elevUnits: DATA_FT_M[0].itemKey,
      };
    } else if (
      !elevationMin &&
      !elevationMax &&
      !disabledFields.includes('elevUnits')
    ) {
      newDisabledFields = [...disabledFields, 'elevUnits'];

      fields = {
        elevUnits: '',
      };
    }
  }

  return { newDisabledFields, fields };
};

export const validateRametsGenets = (ramets, genets, t) => {
  const isGenets = genets || genets === 0;
  const isRamets = ramets || ramets === 0;

  let fields;

  if (!isGenets && !isRamets) {
    fields = [
      {
        name: 'ramets',
        errors: [t('errors:ramets')],
      },
    ];
  }

  if (isGenets > 1000000000) {
    fields = [
      {
        name: 'genets',
        errors: [t('errors:genetsInterval')],
      },
    ];
  }

  if (isRamets > 1000000000) {
    fields = [
      {
        name: 'ramets',
        errors: [t('errors:rametsInterval')],
      },
    ];
  }

  if (isGenets < 0) {
    fields = [
      {
        name: 'genets',
        errors: [t('errors:genetsMin')],
      },
    ];
  }

  if (isRamets < 0) {
    fields = [
      {
        name: 'ramets',
        errors: [t('errors:rametsMin')],
      },
    ];
  }

  return fields;
};

export const validateSurveyor = (surveyors, fieldName, fieldValue) => {
  let field;

  if (fieldValue && !surveyors.some(({ item }) => item === fieldValue)) {
    field = {
      name: fieldName,
      errors: [`${fieldName} should be from list of surveyors`],
    };
  }

  return field;
};

export const validateSurveyors = (surveyors, ...fields) =>
  fields.reduce((prev, { fieldName, fieldValue }) => {
    const surveyorValidate = validateSurveyor(surveyors, fieldName, fieldValue);

    if (surveyorValidate) {
      return [...prev, surveyorValidate];
    }
    return prev;
  }, []);

export const downloadExcel = (pathname, exportData, refetchExport) => {
  if (exportData) {
    try {
      const directories = pathname.split('/');

      const link = document.createElement('a');
      const url = URL.createObjectURL(exportData);

      link.href = url;
      link.download = `${directories[directories.length - 1]}.xlsx`;
      link.click();

      refetchExport();
    } catch (error) {
      console.log(error);
    }
  }
};

export const getUTCTimeDiff = () => new Date().getTimezoneOffset() / 60;
export const getMomentDate = (date) =>
  moment.utc(date).add(getUTCTimeDiff(), 'hours');
export const getCurrentDate = () =>
  +moment().add(getUTCTimeDiff(), 'hours').format('YYYYMMDD');
export const getCurrentDateSeparatedByHyphens = () =>
  moment().add(getUTCTimeDiff(), 'hours').format('MM-DD-YYYY');

export const createPdfTables = (t, array) => {
  const PAGE_WIDTH = 1060;
  const CELL_PADDING = 3;
  const DEFAULT_FONT_SIZE = 14;
  const TITLE_FONT_SIZE = 18;
  const X_OFFSET = 20;
  const TITLE_Y_OFFSET = 55;
  const TABLE_Y_OFFSET = 65;
  const PAGE_FORMAT = 'a4';

  const doc = new JSPDF({
    orientation: 'l',
    unit: 'pt',
    format: PAGE_FORMAT,
    putOnlyUsedFonts: true,
  });

  const dateFields = [
    'firstDate',
    'lastDate',
    'permitStartDate',
    'permitEndDate',
    'startDate',
    'seedDateSent',
  ];

  let resultFilename;
  const tablesData = array.filter(({ data }) => data?.length);
  tablesData.map((table, index) => {
    const { headers, data, fieldsWillDeleteFromObj, title, filename } = table;
    const resultHeaders = headers.map((key) => ({
      id: key,
      name: key,
      prompt: t(`table:${key}`),
      width: PAGE_WIDTH / headers.length,
      align: 'center',
      padding: CELL_PADDING,
    }));

    const pdfData = data.map((prop) => {
      const row = { ...prop };

      function isNumber(val) {
        return val === +val;
      }

      fieldsWillDeleteFromObj?.map((i) => delete row[i]);
      dateFields.map((dateField) => {
        if (row[dateField]) {
          row[`${dateField}`] = getMomentDate(row[dateField]).format(
            'YYYY-MM-DD'
          );
        }
        return row[`${dateField}`];
      });
      Object.keys(row).map((key) => {
        if (isNumber(row[key])) {
          row[key] = `${row[key]}`;
        } else if (row[key] === null || row[key] === undefined) {
          row[key] = '-';
        }

        return key;
      });
      return row;
    });

    doc.setFontSize(TITLE_FONT_SIZE).text(title, X_OFFSET, TITLE_Y_OFFSET);
    doc
      .setFontSize(DEFAULT_FONT_SIZE)
      .table(X_OFFSET, TABLE_Y_OFFSET, pdfData, resultHeaders, {
        padding: CELL_PADDING,
      });

    if (index < tablesData.length - 1) {
      doc.addPage(PAGE_FORMAT);
    }
    resultFilename = filename;
    return table;
  });
  doc.save(resultFilename);
};

export const standardizePdf = (previousFields, state = '') => {
  const previousFieldsNames = previousFields.map(
    (previousField) => previousField.name
  );
  return PDF_FIELDS_ORDER.reduce((standartilazedFields, fieldName) => {
    if (fieldName === 'Quad Name' || fieldName === 'Quad Code') {
      if (state === 'ME') {
        standartilazedFields.push({
          name: fieldName,
          value: previousFields.find(
            (previousField) => previousField.name === fieldName
          )?.value,
        });
      }

      return standartilazedFields;
    }

    if (RENAMABLE_TARGET_FIELDS.includes(fieldName)) {
      standartilazedFields.push({
        name: fieldName,
        value: previousFieldsNames.includes(fieldName)
          ? previousFields.find(
              (previousField) => previousField.name === fieldName
            )?.value
          : previousFields.find(
              (previousField) =>
                previousField.name ===
                RENAMABLE_FIELDS.find(
                  (pairOfFields) => pairOfFields.target === fieldName
                )?.current
            )?.value,
      });

      return standartilazedFields;
    }

    standartilazedFields.push({
      name: fieldName,
      value: previousFields.find(
        (previousField) => previousField.name === fieldName
      )?.value,
    });

    return standartilazedFields;
  }, []);
};
export const createPdf = (props, title, filename, save = false) => {
  const pdfData = [];

  const dateFields = [
    'Permit Start Date',
    'Permit End Date',
    'Seed Date Sent',
    'Start Date',
    'Survey Date',
    'signatureDate',
  ];

  props.map((prop) => {
    const { name, value } = prop;

    const formattedValue = dateFields.includes(name)
      ? getMomentDate(value).format('YYYY-MM-DD')
      : `${value}`;

    pdfData.push({
      ...prop,
      value:
        value === null || value === undefined || value === ''
          ? '-'
          : `${formattedValue}`,
    });

    return prop;
  });

  const headers = [
    {
      id: 1,
      name: 'name',
      prompt: 'Property',
      width: 60,
    },
    {
      id: 2,
      name: 'value',
      prompt: 'Value',
      width: 190,
    },
  ].map((item) => ({
    ...item,
    padding: 0,
  }));

  const doc = new JSPDF({ putOnlyUsedFonts: true });

  doc.text(title, 10, 10);
  doc.table(10, 15, pdfData, headers, { padding: 1 });

  if (save) {
    doc.save(filename);
  }
  return doc.output('blob', { filename });
};

export const validateNoThreat = (fieldName, fieldValue, fieldsList) => {
  let fields;

  if (fieldsList.includes(fieldName) && fieldValue === 'Yes') {
    fields = [{ name: 'noThreat', value: DATA_YES_NO_NONE[2].itemKey }];
  }

  return fields;
};

export const dateValidationRules = (label) => ({
  type: 'number',
  validator: (rule, value) => {
    const isValid = ['', undefined, null].includes(value)
      ? true
      : (+value >= minYear && +value <= maxYear) || +value === 0;

    return isValid
      ? Promise.resolve()
      : Promise.reject(
          new Error(`${label} must be between ${minYear} and ${maxYear} or 0`)
        );
  },
});

export const dateValidationRulesFrom1800 = (label) => ({
  type: 'number',
  validator: (rule, value) => {
    const isValid = ['', undefined, null].includes(value)
      ? true
      : (+value >= 1800 && +value <= maxYear) || +value === 0;

    return isValid
      ? Promise.resolve()
      : Promise.reject(
          new Error(`${label} must be between 1800 and ${maxYear} or 0`)
        );
  },
});

export const getRequiredErrMsg = (label) =>
  `${label} is required. Please enter a value.`;

export const getIsForbidden = (role, pathname) => {
  const accessFilteringByExpect = accessByRolesConfig[role]?.expect?.find(
    (path) => {
      if (pathname === '/') {
        return true;
      }
      const splitPathname = pathname.split('/');
      const splitPath = path.split('/');
      splitPathname.splice(0, 1);
      splitPath.splice(0, 1);
      if (splitPathname.length === 1) {
        const intersection = splitPathname.filter((element) =>
          splitPath.includes(element)
        );
        return intersection.length;
      }
      if (!splitPathname.length) {
        return false;
      }
      if (splitPathname.length > 1) {
        return pathname.includes(path);
      }
      return false;
    }
  );

  const accessFilteringByNotAllowed = role
    ? !accessByRolesConfig[role]?.notAllowed?.find((path) =>
        pathname.includes(path)
      )
    : false;

  const resultAccessByRoles =
    accessFilteringByExpect || accessFilteringByNotAllowed;

  const isNotForbidden =
    role === roleTypes.Administrator ? true : resultAccessByRoles;

  return !isNotForbidden;
};

export const splitEoKeyIntoComponents = (eokey) => {
  const updatedEokey = eokey.includes('--')
    ? eokey.replace('--', ' ')
    : eokey.replace('-', ' ');

  const separatedEokey = updatedEokey.split(' ');

  const subpopulation = separatedEokey.pop();
  const eoNumber = separatedEokey.pop();
  const state = separatedEokey[0];
  const speciesName = separatedEokey.slice(1).join(' ');

  return { state, speciesName, eoNumber, subpopulation };
};

export const getPdfNameByEOKey = (eokey) => {
  const { state, speciesName, eoNumber } = splitEoKeyIntoComponents(eokey);

  return `${state}_${speciesName}_${eoNumber}`
};
