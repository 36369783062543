import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
    },
    {
      title: t('table:eototal'),
      dataIndex: 'eototal',
      key: 'eototal',
    },
    {
      title: t('table:eoactive'),
      dataIndex: 'eoactive',
      key: 'eoactive',
    },
    {
      title: t('table:eoinactive'),
      dataIndex: 'eoinactive',
      key: 'eoinactive',
    },
    {
      title: t('table:eoinactiveSprout'),
      dataIndex: 'eoinactiveSprout',
      key: 'eoinactiveSprout',
    },
    {
      title: t('table:eohistorical'),
      dataIndex: 'eohistorical',
      key: 'eohistorical',
    },
    {
      title: t('table:eoextirpated'),
      dataIndex: 'eoextirpated',
      key: 'eoextirpated',
    },
  ];
};
