import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const LightsSelect = (props) => {
  const { lightsData, lightsLoading, updateLights } = useStore();

  useEffect(() => {
    if (!lightsData.length && !lightsLoading) updateLights();
  }, []);

  return <Select props={props} data={lightsData} loading={lightsLoading} />;
};

export default LightsSelect;
