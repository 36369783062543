import { getMomentDate } from '../../../utils/general';

export const speciesInformationColumns = [
  {
    title: 'Species Name',
    dataIndex: 'speciesName',
    key: 'speciesName',
    sorter: true,
    width: 120,
  },
  {
    title: 'Phenology type',
    dataIndex: 'phenologyType',
    key: 'phenologyType',
    sorter: true,
    width: 120,
  },
  {
    title: 'First Date',
    dataIndex: 'firstDate',
    key: 'firstDate',
    sorter: true,
    width: 100,
    render: (text) => (text ? getMomentDate(text).format('MM-DD') : ''),
  },
  {
    title: 'Last Date',
    dataIndex: 'lastDate',
    key: 'lastDate',
    sorter: true,
    width: 100,
    render: (text) => (text ? getMomentDate(text).format('MM-DD') : ''),
  },
];

export const fieldDataPhenologyInformationColumns = [
  {
    title: 'Species Name',
    dataIndex: 'speciesName',
    key: 'speciesName',
    sorter: true,
    width: 120,
  },
  {
    title: '% Leaf',
    dataIndex: 'pctLeaf',
    key: 'state',
    sorter: true,
    width: 120,
  },
  {
    title: '% Bud',
    dataIndex: 'pctBud',
    key: 'pctBud',
    sorter: true,
    width: 120,
  },
  {
    title: '% Flower',
    dataIndex: 'pctFlower',
    key: 'pctFlower',
    sorter: true,
    width: 120,
  },
  {
    title: '% Imm Fruit',
    dataIndex: 'pctImmFruit',
    key: 'pctImmFruit',
    sorter: true,
    width: 120,
  },
  {
    title: '% Mat Fruit',
    dataIndex: 'pctMatFruit',
    key: 'pctMatFruit',
    sorter: true,
    width: 120,
  },
  {
    title: '% Seed',
    dataIndex: 'pctSeed',
    key: 'pctSeed',
    sorter: true,
    width: 90,
  },
  {
    title: '% Senescent',
    dataIndex: 'pctSenescent',
    key: 'pctSenescent',
    sorter: true,
    width: 100,
  },
  {
    title: '% Dormant',
    dataIndex: 'pctDormant',
    key: 'pctDormant',
    sorter: true,
    width: 100,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    sorter: true,
    width: 90,
  },
  {
    title: 'EO Number',
    dataIndex: 'eonumberInt',
    key: 'eonumberInt',
    sorter: true,
    width: 100,
  },
  {
    title: 'Survey Date',
    dataIndex: 'surveyDate',
    key: 'surveyDate',
    sorter: true,
    width: 120,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
  {
    title: 'Elevation (ft)',
    dataIndex: 'elevationMin',
    key: 'elevationMin',
    sorter: true,
    width: 120,
  },
  {
    title: 'Aspect',
    dataIndex: 'aspect',
    key: 'aspect',
    sorter: true,
    width: 120,
  },
  {
    title: 'Light',
    dataIndex: 'light',
    key: 'light',
    sorter: true,
    width: 120,
  },
  {
    title: 'Topo Position',
    dataIndex: 'topoPosition',
    key: 'topoPosition',
    sorter: true,
    width: 100,
  },
  {
    title: 'Moisture Regime',
    dataIndex: 'moistureRegime',
    key: 'moistureRegime',
    sorter: true,
    width: 100,
  },
];

export const speciesPhenologyNotesColumns = [
  {
    title: 'Species Name',
    dataIndex: 'speciesName',
    key: 'speciesName',
    sorter: true,
    width: 120,
  },
  {
    title: 'Phenology Note',
    dataIndex: 'phenologyNote',
    key: 'phenologyNote',
    sorter: true,
    width: 120,
  },
  {
    title: 'Phenology memo',
    dataIndex: 'phenologyMemo',
    key: 'phenologyMemo',
    sorter: true,
    width: 100,
  },
  {
    title: 'Division',
    dataIndex: 'division',
    key: 'division',
    sorter: true,
    width: 100,
  },
  {
    title: 'NPT Status',
    dataIndex: 'nptstatus',
    key: 'nptstatus',
    sorter: true,
    width: 120,
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    sorter: true,
    width: 120,
  },
  {
    title: 'Propagule Type',
    dataIndex: 'propaguleType',
    key: 'propaguleType',
    sorter: true,
    width: 100,
  },
  {
    title: 'Seed Storage Behaviour',
    dataIndex: 'seedStorageBehavior',
    key: 'seedStorageBehavior',
    sorter: true,
    width: 100,
  },
  {
    title: 'Collection',
    dataIndex: 'collection',
    key: 'collection',
    sorter: true,
    width: 100,
  },
  {
    title: 'Seed Notes',
    dataIndex: 'seedNotes',
    key: 'seedNotes',
    sorter: true,
    width: 100,
  },
];
