import React from 'react';
import { Checkbox } from 'antd';

export const feSearchConfig = [
  {
    name: 'LastName',
    label: 'Owner name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'LeadSurveyor',
    label: 'EOs that have a lead surveyor',
    operatortype: 'GreaterThan',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
    rules: [
      {
        type: 'boolean',
      },
    ],
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'LastName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'FirstName',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Organization',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SubOrganization',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'GreaterThan',
    propertyName: 'LeadSurveyor',
    previousSearchBlockConnectionOperator: 'And'
  }
];
