import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:address1'),
      dataIndex: 'address1',
      key: 'address1',
      sorter: true,
    },
    {
      title: t('table:address2'),
      dataIndex: 'address2',
      key: 'address2',
      sorter: true,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:postalCode'),
      dataIndex: 'postalCode',
      key: 'postalCode',
      sorter: true,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t('table:surveyorType'),
      dataIndex: 'surveyorType',
      key: 'surveyorType',
      sorter: true,
    },
    {
      title: t('table:yearLastActive'),
      dataIndex: 'yearLastActive',
      key: 'yearLastActive',
      sorter: true,
    },
    {
      title: t('table:latitude'),
      dataIndex: 'latitude',
      key: 'latitude',
      sorter: true,
    },
    {
      title: t('table:longitude'),
      dataIndex: 'longitude',
      key: 'longitude',
      sorter: true,
    },
    {
      title: t('table:yearTrained'),
      dataIndex: 'yearTrained',
      key: 'yearTrained',
      sorter: true,
    },
    {
      title: t('table:deleted'),
      dataIndex: 'deleted',
      key: 'deleted',
      sorter: true,
      render: (text) => (text ? 'Yes' : 'No'),
    },
  ];
};
