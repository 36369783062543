export const createPredicate = (predicate, index) => ({
  page: `${predicate}:${index}:page`,
  description: `${predicate}:${index}:description`,
});

export const createValidationPredicate = (predicate, index) => ({
  page: `${predicate}:${index}:page`,
  description: `${predicate}:${index}:description`,
  tableValidated: `${predicate}:${index}:validated`,
});
