import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import Title from 'antd/lib/typography/Title';

import { ENDPOINT_URLS } from '../../../../global/api';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { getMomentDate } from '../../../../utils/general';
import styles from '../../../sections/Section/styles.module.scss';
import PageContent from '../../../../shared/components/PageContent';
import classes from '../../../styles.module.scss';

const fetchFieldFormDetail = (key) =>
  APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_SPROUT_DETAILS_PCVTF(key), {
    method: 'get',
  });

const SurveySPROUTFieldFormDetail = () => {
  const { t } = useTranslation();
  const { search: searchKey } = useLocation();

  const query = new URLSearchParams(searchKey);
  const keyFromUrl = query.getAll('ffkey');

  const { data } = useAPI(() => fetchFieldFormDetail(keyFromUrl[0]), {
    initialState: { data: {}, loading: false },
  });

  delete data.ffkey;
  delete data.eokey;
  delete data.source;
  delete data.fieldDataType;

  data.surveyDate = data.surveyDate
    ? getMomentDate(data.surveyDate).format('YYYY-MM-DD')
    : data.surveyDate;

  const result = Object.keys(data).map((key) => (
    <tr key={key}>
      <td className={styles.cell}>{t(`table:${key}`)}</td>
      <td className={styles.secondSale}>{data[key]}</td>
    </tr>
  ));

  return (
    <PageContent>
      <div>
        <Title className={classNames(classes.title)} level={3}>
          {t(`PCVTF:SPROUTFieldFormDetail`)}
        </Title>
        <p className={classNames(classes.title)}>
          {t(`PCVTF:SPROUTFieldFormDetailDescription`)}
        </p>
      </div>
      <table className={classNames(classes.title)}>
        <tbody>{result}</tbody>
      </table>
    </PageContent>
  );
};

export default SurveySPROUTFieldFormDetail;
