import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:assocSpecies'),
      dataIndex: 'assocSpecies',
      key: 'assocSpecies',
    },
    {
      title: t('table:item'),
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: t('table:genSpeUsed'),
      dataIndex: 'genSpeUsed',
      key: 'genSpeUsed',
    },
  ];
};
