import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithSimpleTableAndSearch from '../../pages/PageWithSimpleTableAndSearch';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchSurveyPlanFutureSpecies = (nbrEos) =>
  nbrEos
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_PLAN_FUTURE_SPECIES(nbrEos), {
        method: 'get',
      })
    : null;

const SurveyPlanFutureSpecies = () => (
  <PageWithSimpleTableAndSearch
    fetchData={(nbrEos) => fetchSurveyPlanFutureSpecies(nbrEos)}
    searchParamKey="nbrEos"
    titleKey="PCVAdministration:SurveyPlanFutureSpecies"
    searchForm={SearchForm}
    columns={columns()}
  />
);

export default SurveyPlanFutureSpecies;
