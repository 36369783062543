import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      render: (text) => text || 'All',
    },
    {
      title: t('table:programName'),
      dataIndex: 'programName',
      key: 'programName',
      sorter: true,
    },
    {
      title: t('table:programDate'),
      dataIndex: 'programDate',
      key: 'programDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:programDescription'),
      dataIndex: 'programDescription',
      key: 'programDescription',
      sorter: true,
    },
    {
      title: t('table:surveyorType'),
      dataIndex: 'surveyorType',
      key: 'surveyorType',
      sorter: true,
    },
    {
      title: t('table:maximumAttendees'),
      dataIndex: 'maximumAttendees',
      key: 'maximumAttendees',
      sorter: true,
    },
  ];
};

export const expandableColumns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Surveyor Type',
    dataIndex: 'surveyorType',
    key: 'surveyorType',
  },
];
