import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import moment from 'moment';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
  getParentPathname,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC(key), {
        method: 'get',
      })
    : null;

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_ELEMENT_OCC, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (key, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_ELEMENT_OCC(key), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetchPaged = (eokey) =>
  eokey && eokey !== 'Create'
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [{ orderOption: 'Ascending', propertyName: 'State' }],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'Eokey', value: eokey },
          ],
        }),
      })
    : {};

export const DisplayAndEditForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { eokey } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const [initialValues, setInitialValues] = useState(null);
  const [state, setState] = useState('');

  const {
    statesData,
    statesLoading = false,
    updateStates,
    speciesNamesData,
    speciesNamesLoading = false,
    updateSpeciesNames,
    countiesData,
    countiesLoading = false,
    updateCounties,
    locationPrecisionsData,
    locationPrecisionsLoading = false,
    updateLocationPrecisions,
    eoStatusesData,
    eoStatusesLoading = false,
    updateEOStatuses,
  } = useStore();

  useEffect(() => {
    if (!statesData.length) updateStates();
    if (!eoStatusesData.length) updateEOStatuses();
    if (!speciesNamesData.length) updateSpeciesNames();
    if (!locationPrecisionsData.length) updateLocationPrecisions();
    if (!countiesData.length) updateCounties();
  }, []);

  const IS_UPDATE_MODE = eokey !== 'Create';

  const { data, loading } = useAPI(() => fetch(eokey), {
    initialState: { loading: false },
    shouldFetch: IS_UPDATE_MODE,
  });

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI(fetchCreate, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(eokey, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: parentData } = useAPI(() => fetchPaged(eokey), {
    initialState: { loading: false },
    shouldFetch: IS_UPDATE_MODE,
  });

  useEffect(() => {
    if (IS_UPDATE_MODE && parentData?.items.length) {
      const {
        state: itemState,
        speciesName,
        eonumber,
        subpopulation,
      } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `Edit ${itemState} ${speciesName} ${eonumber} ${subpopulation}`,
        },
      });
    }
  }, [parentData]);

  useEffect(() => {
    if (
      !statesLoading &&
      statesData.length &&
      !speciesNamesLoading &&
      speciesNamesData.length &&
      !countiesLoading &&
      countiesData.length &&
      !locationPrecisionsLoading &&
      locationPrecisionsData.length &&
      !eoStatusesLoading &&
      eoStatusesData.length &&
      (IS_UPDATE_MODE ? data : true)
    ) {
      if (IS_UPDATE_MODE) {
        const { speciesKey, countyKey, ...other } = data;

        setInitialValues({
          ...other,
          speciesKey: speciesNamesData.find(
            ({ itemKey }) => itemKey === speciesKey
          ).item,
          countyKey: countiesData.find(({ itemKey }) => itemKey === countyKey)
            .item,
        });
      } else {
        setState(statesData[0].itemKey);
        setInitialValues({
          state: statesData[0].itemKey,
          speciesKey: speciesNamesData[0].item,
          countyKey: countiesData[0].item,
          locationPrecision: locationPrecisionsData[0].itemKey,
          eostatus: eoStatusesData[0].itemKey,
          tfpcv: 'TFPCV',
        });
      }
    }
  }, [
    data,
    statesData,
    speciesNamesData,
    countiesData,
    locationPrecisionsData,
    eoStatusesData,
  ]);

  const onFinish = (values) => {
    const { speciesKey, countyKey, ...others } = values;

    const props = {
      ...others,
      speciesKey: speciesNamesData.find(({ item }) => item === speciesKey)
        .itemKey,
      countyKey: countiesData.find(({ item }) => item === countyKey).itemKey,
    };

    if (IS_UPDATE_MODE) {
      refetchUpdate(props);
    } else {
      refetchCreate(props);
    }
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData?.eokey) {
          type = 'success';
          message = 'ElementOccurrence was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update ElementOccurrence';
        }
      } else if (createData?.eokey) {
        type = 'success';
        message = 'ElementOccurrence was created successfully';
      } else {
        type = 'error';
        message = 'Failed create ElementOccurrence';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        const parentPathname = getParentPathname(pathname);
        history.replace(parentPathname);
      }
    }
  }, [createData, updateData]);

  const title = speciesNamesData.find(
    ({ itemKey }) => itemKey === data?.speciesKey
  )?.item;

  const fields = [
    'specimens',
    'eorank',
    'eorankDate',
    'subEo',
    'parentEo',
    'parentYn',
    'idconfirm',
    'eocondition',
    'eosize',
    'eolandscape',
    'eotracking',
    'normspecies',
    'gisspecies',
    'stateSpecies',
    'owners',
    'sF_LAT_LONG',
    'shape_Leng',
    'shape_Area',
    'otherRef',
  ];
  const disabledFields = IS_UPDATE_MODE
    ? ['state', 'topO_NAMES', 'topO_NUMS', ...fields]
    : [...fields];

  const [disabledFieldsState, setDisabledFieldsState] =
    useState(disabledFields);

  const changeFields = (e) => {
    const fieldName = e[0].name[0];
    const fieldValue = e[0].value;
    const currentYear = +moment().format('YYYY');
    const maxYear = currentYear + 30;
    const minYear = currentYear - 30;

    if (
      fieldName === 'nextSurveyYear' &&
      (fieldValue > maxYear || fieldValue < minYear) &&
      !!fieldValue
    ) {
      form.setFields([
        {
          name: 'nextSurveyYear',
          errors: [
            `Next Survey Year must be between ${minYear} and ${maxYear} or 0`,
          ],
        },
      ]);

      form.scrollToField('nextSurveyYear');
    }

    if (
      fieldName === 'nextSeedYear' &&
      (fieldValue > maxYear || fieldValue < minYear) &&
      !!fieldValue
    ) {
      form.setFields([
        {
          name: 'nextSeedYear',
          errors: [
            `Next Seed Year must be between ${minYear} and ${maxYear} or 0`,
          ],
        },
      ]);

      form.scrollToField('nextSeedYear');
    }

    if (fieldName === 'state') {
      setState(fieldValue);
    }
  };

  useEffect(() => {
    if (!IS_UPDATE_MODE) {
      if (state === 'ME') {
        setDisabledFieldsState(disabledFields);
      } else {
        setDisabledFieldsState([...disabledFields, 'topO_NAMES', 'topO_NUMS']);
        form.setFields([
          { name: 'topO_NAMES', value: '' },
          { name: 'topO_NUMS', value: '' },
        ]);
      }
    }
  }, [state, form]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {initialValues &&
          (title || t('ElementOccurrence:ElOccDisplayAndEditForm'))}
      </Title>
      <p className={classNames(classes.title)}>
        {t(
          `ElementOccurrence:ElOccDisplayAndEdit${
            IS_UPDATE_MODE ? 'Edit' : 'Create'
          }FormDescription`
        )}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues && !loading ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="createFieldDataManualEnter"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={['onBlur', 'onChange']}
              onFieldsChange={changeFields}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  disabled={disabledFieldsState.includes(name)}
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};
