import moment from 'moment';

export const COLORS_CONFIG = () => {
  const currentYear = +moment().format('YYYY');

  return {
    red: currentYear,
    orange: currentYear - 1,
    yellow: currentYear - 2,
    green: currentYear - 3,
    pink: currentYear - 4,
    purple: currentYear - 5,
    gray: currentYear - 6,
    black: currentYear - 7,
    blue: `years before ${currentYear - 7}`,
  };
};
