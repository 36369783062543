import React from 'react';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import classes from '../../styles.module.scss';

const PageNotAuthorized = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={classNames(classes.title)}>
      <Title level={3}>{t(`errorsPages:notAuthorized`)}</Title>
      <p>{t(`errorsPages:notAuthorizedDescription`)}</p>
      <br />
      <Button type="primary" onClick={() => history.push('/auth')}>
        {t('errorsPages:login')}
      </Button>
    </div>
  );
};

export default PageNotAuthorized;
