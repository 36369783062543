import React from 'react';
import moment from 'moment';

export const getCoordinates = (data) => {
  const {
    eoCoordinates,
    eoFieldFormCoordinates,
    eoFieldDataManualCoordinates,
  } = data;

  const coordinates = [];

  const colorDefinition = (date) => {
    const currentYear = +moment().format('YYYY');
    let color = [];

    switch (date) {
      case currentYear:
        color = 'red';
        break;
      case currentYear - 1:
        color = 'orange';
        break;
      case currentYear - 2:
        color = 'yellow';
        break;
      case currentYear - 3:
        color = 'green';
        break;
      case currentYear - 4:
        color = 'pink';
        break;
      case currentYear - 5:
        color = 'purple';
        break;
      case currentYear - 6:
        color = 'gray';
        break;
      case currentYear - 7:
        color = 'black';
        break;
      default:
        color = 'blue';
    }

    return color;
  };

  if (eoCoordinates) {
    coordinates.push({
      id: 0,
      lat: eoCoordinates.latitude,
      lng: eoCoordinates.longitude,
      description: (
        <>
          <b>Survey Date:</b> {eoCoordinates.surveyDate || '-'}
          <br />
          <b>Latitude:</b> {eoCoordinates.latitude || '-'}
          <br />
          <b>Longitude:</b> {eoCoordinates.longitude || '-'}
          <br />
          <b>Location Precision:</b> {eoCoordinates.locationPrecision || '-'}
        </>
      ),
    });
  }

  if (eoFieldFormCoordinates.length) {
    eoFieldFormCoordinates.map((item, id) => {
      const {
        latitude1,
        longitude1,
        description1,
        latitude2,
        longitude2,
        description2,
        latitude3,
        longitude3,
        description3,
        year,
      } = item;

      const list = [
        {
          lat: latitude1,
          lng: longitude1,
          description: description1,
        },
        {
          lat: latitude2,
          lng: longitude2,
          description: description2,
        },
        {
          lat: latitude3,
          lng: longitude3,
          description: description3,
        },
      ]
        .filter(({ lat, lng }) => lat && lng)
        .map(({ lat, lng, description }, index) => ({
          id: `${id + 1}${index}`,
          lat,
          lng,
          color: colorDefinition(year),
          description: (
            <>
              <b>Description:</b> {description || '-'}
              <br />
              <b>Latitude:</b> {lat || '-'}
              <br />
              <b>Longitude:</b> {lng || '-'}
              <br />
              <b>Year:</b> {year || '-'}
            </>
          ),
        }));

      coordinates.push(...list);

      return item;
    });
  }

  if (eoFieldDataManualCoordinates.length) {
    eoFieldDataManualCoordinates.map((item, id) => {
      const { year, naturalCommunity, latitude, longitude } = item;

      coordinates.push({
        id: `${id + 1}`,
        lat: latitude,
        lng: longitude,
        color: colorDefinition(year),
        description: (
          <>
            <b>Description:</b> {naturalCommunity || '-'}
            <br />
            <b>Latitude:</b> {latitude || '-'}
            <br />
            <b>Longitude:</b> {longitude || '-'}
            <br />
            <b>Year:</b> {year || '-'}
          </>
        ),
      });

      return item;
    });
  }

  return coordinates;
};
