import React from 'react';

import Uploader from '../../../../shared/components/Upload';

export const formConfig = [
  {
    name: 'link1',
    label: 'Link',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'linkTitle',
    label: 'Link Title',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'description',
    label: 'Description',
    rules: [
      {
        required: true,
      },
      {
        max: 2000,
      },
    ],
  },
];

export const formConfigWithFile = [
  {
    name: 'linkTitle',
    label: 'Link Title',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'file',
    label: 'File',
    input: (props) => <Uploader {...props} maxCount={1} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'description',
    label: 'Description',
    rules: [
      {
        required: true,
      },
      {
        max: 2000,
      },
    ],
  },
];
