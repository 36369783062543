import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useStore } from '../../../../global/stores/store/StoreContext';
// eslint-disable-next-line import/no-cycle
import PageContent from '../../../../shared/components/PageContent';
// eslint-disable-next-line import/no-cycle
import ValidationDataForm from '../../../ValidationData/ValidationDataPages/ValidationDataForm';

import { formConfig } from './form-config';

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.SIGN_UP, {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const UsersForm = () => {
  const { rolesData, updateRoles } = useStore();

  const [initialValue, setInitialValue] = useState(false);

  useEffect(() => {
    if (rolesData.length) {
      setInitialValue(rolesData[0]);
    }
  }, [rolesData]);

  useEffect(() => {
    if (!rolesData.length) updateRoles();
  }, []);

  const getParamsForInitialValues = (isUpdateMode, params) => {
    if (!isUpdateMode) {
      return {
        role: initialValue?.itemKey,
      };
    }

    return params;
  };

  const getParamsForSubmit = (isUpdateMode, params) =>
    isUpdateMode ? {} : { ...params };

  return initialValue ? (
    <ValidationDataForm
      fetchCreate={fetchCreate}
      pageKey="Users"
      pageName="User"
      formConfig={formConfig}
      getParamsForInitialValues={getParamsForInitialValues}
      getParamsForSubmit={getParamsForSubmit}
      titleTxt="Add New User"
      breadcrumbsTxt="Add New User"
      isFormWithEmail
      isCancelButton
    />
  ) : (
    <PageContent>
      <Spin />
    </PageContent>
  );
};

export default UsersForm;
