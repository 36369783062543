import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:ctstatus'),
      dataIndex: 'ctstatus',
      key: 'ctstatus',
      sorter: true,
    },
    {
      title: t('table:mastatus'),
      dataIndex: 'mastatus',
      key: 'mastatus',
      sorter: true,
    },
    {
      title: t('table:mestatus'),
      dataIndex: 'mestatus',
      key: 'mestatus',
      sorter: true,
    },
    {
      title: t('table:nhstatus'),
      dataIndex: 'nhstatus',
      key: 'nhstatus',
      sorter: true,
    },
    {
      title: t('table:ristatus'),
      dataIndex: 'ristatus',
      key: 'ristatus',
      sorter: true,
    },
    {
      title: t('table:vtstatus'),
      dataIndex: 'vtstatus',
      key: 'vtstatus',
      sorter: true,
    },
  ];
};
