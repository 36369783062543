import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
      width: 140,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:address1'),
      dataIndex: 'address1',
      key: 'address1',
      sorter: true,
      width: 140,
    },
    {
      title: t('table:address2'),
      dataIndex: 'address2',
      key: 'address2',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 60,
    },
    {
      title: t('table:postalCode'),
      dataIndex: 'postalCode',
      key: 'postalCode',
      sorter: true,
      width: 110,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      width: 140,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 150,
    },
    {
      title: t('table:reportMethod'),
      dataIndex: 'reportMethod',
      key: 'reportMethod',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:dateReportWithProblemSent'),
      dataIndex: 'dateReportWithProblemSent',
      key: 'dateReportWithProblemSent',
      sorter: true,
      editable: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      width: 40,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      width: 40,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      width: 40,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 100,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      width: 100,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      width: 100,
    },
    {
      title: t('table:surveyResult'),
      dataIndex: 'surveyResult',
      key: 'surveyResult',
      width: 40,
    },

    {
      title: t('table:phrase'),
      dataIndex: 'phrase',
      key: 'phrase',
      width: 210,
    },
  ];
};
