import {
  SPECIES_DATA_ROUTE,
  VALIDATION_ROUTE,
  PCV_ADMIN_ROUTE,
  STATUS_ROUTE,
  PCV_ROUTE,
  OWNER_ROUTE,
  ELEMENT_OCCURRENCE_ROUTE,
  AppRouteSections,
  SURVEYOR_ROUTE,
} from '../../AppRoutes';
import { roleTypes } from '../../utils/roles';

export const menuData = (user) => {
  const role = user?.role;
  let navigation = [];

  switch (role) {
    case roleTypes.PCV:
      navigation = [
        { path: SPECIES_DATA_ROUTE, section: AppRouteSections.SPECIES },
        {
          path: PCV_ROUTE,
          section: AppRouteSections.PCV_TF,
        },
      ];

      break;
    case roleTypes.TF:
      navigation = [
        { path: SPECIES_DATA_ROUTE, section: AppRouteSections.SPECIES },
        {
          path: PCV_ROUTE,
          section: AppRouteSections.PCV_TF,
        },
      ];

      break;
    case roleTypes.Transcriber:
      navigation = [
        {
          path: ELEMENT_OCCURRENCE_ROUTE,
          section: AppRouteSections.ELEMENT_OCCURRENCES,
        },
        { path: SPECIES_DATA_ROUTE, section: AppRouteSections.SPECIES },
      ];

      break;
    case roleTypes.Administrator:
      navigation = [
        {
          path: ELEMENT_OCCURRENCE_ROUTE,
          section: AppRouteSections.ELEMENT_OCCURRENCES,
        },
        { path: SPECIES_DATA_ROUTE, section: AppRouteSections.SPECIES },
        { path: OWNER_ROUTE, section: AppRouteSections.OWNERS },
        { path: SURVEYOR_ROUTE, section: AppRouteSections.SURVEYORS },
        { path: VALIDATION_ROUTE, section: AppRouteSections.VALIDATION_DATA },
        {
          path: PCV_ROUTE,
          section: AppRouteSections.PCV_TF,
        },
        { path: PCV_ADMIN_ROUTE, section: AppRouteSections.PCV_ADMINISTRATION },
        { path: STATUS_ROUTE, section: AppRouteSections.STATUS_OF_THE_PLANTS },
      ];

      break;
    case roleTypes.Inactive:
      navigation = [];

      break;
    case roleTypes.Heritage:
      navigation = [
        {
          path: ELEMENT_OCCURRENCE_ROUTE,
          section: AppRouteSections.ELEMENT_OCCURRENCES,
        },
        { path: SPECIES_DATA_ROUTE, section: AppRouteSections.SPECIES },
        { path: OWNER_ROUTE, section: AppRouteSections.OWNERS },
        { path: SURVEYOR_ROUTE, section: AppRouteSections.SURVEYORS },
        { path: STATUS_ROUTE, section: AppRouteSections.STATUS_OF_THE_PLANTS },
      ];
  
      break;
    default:
      navigation = [];
  }

  return navigation;
};
