import React, { useState, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import PageContent from '../../../shared/components/PageContent';
import FormItem from '../../../shared/components/formItem';
import { Autocomplete } from '../../../shared/components/autocomplete/Autocomplete';
import { validateMessages } from '../../../utils/general';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { Map as CustomMap } from '../../../shared/components/Map';

import { getCoordinates } from './utils';
import { COLORS_CONFIG } from './constants';

import classes from '../../styles.module.scss';

const { Item } = Form;

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_EOS_COORDINATES_BY_SPECIES_KEY(key), {
        method: 'get',
      })
    : [];

const fetchAllSpecies = () =>
  APIFetch(ENDPOINT_URLS.GET_ALL_SPECIES, {
    method: 'get',
  });

const Map = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, search: searchParams } = useLocation();

  const [form] = Form.useForm();

  const [coordinates, setCoordinates] = useState(false);
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState();

  const { data, loading, refetch } = useAPI(fetch, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: allSpecies, loading: allSpeciesLoading } = useAPI(
    fetchAllSpecies,
    {
      initialState: { data: [], loading: false },
    }
  );

  useEffect(() => {
    if (searchParams) {
      const query = new URLSearchParams(searchParams);
      const speciesName = query.get('speciesName');

      form.setFieldsValue({ speciesName });
    }
  }, []);

  const onFinish = (values) => {
    const mappedSearchParams = Object.keys(values).map((key) => ({
      [key]: values[key],
    }));

    const newSearchParams = mappedSearchParams.reduce((acc, item) => {
      const key = Object.keys(item)[0];
      return { ...acc, [key]: item[key] };
    }, {});

    const searchParamsString = new URLSearchParams(newSearchParams).toString();

    history.push({
      pathname,
      search: searchParamsString,
    });
  };

  const onClickCancel = () => {
    form.resetFields();
    setCoordinates([]);

    history.push({
      pathname,
      search: '',
    });
  };

  useEffect(() => {
    if (data?.length && !searchParams) {
      refetch();

      return;
    }

    const query = new URLSearchParams(searchParams);
    const speciesName = query.getAll('speciesName')[0];

    if (speciesName && allSpecies.length) {
      const speciesKey = allSpecies.find(
        ({ item }) => item === speciesName
      )?.itemKey;

      if (speciesKey) refetch(speciesKey);
    }
  }, [searchParams, allSpecies]);

  useEffect(() => {
    if (data?.length) {
      setZoom();

      const newCoordinates = getCoordinates(data);

      setCoordinates(newCoordinates);
      setCenter({ lat: newCoordinates[0].lat, lng: newCoordinates[0].lng });
      if (newCoordinates.length > 0) setZoom(6);
    }
  }, [data]);

  const legend = (
    <ul className={classNames(classes.colorsMap)}>
      {Object.keys(COLORS_CONFIG).map((color) => (
        <li key={color} className={classNames(classes.colorsMapItem)}>
          <span style={{ background: color }} /> {COLORS_CONFIG[color]}
        </li>
      ))}
    </ul>
  );

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('StatusOfPlants:Map')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('StatusOfPlants:MapDescription')}
      </p>
      <br />
      <Row>
        <Col span={24}>
          <Form
            scrollToFirstError
            form={form}
            name="search-map"
            layout="inline"
            onFinish={onFinish}
            validateMessages={validateMessages}
            className={classNames(classes.formWrapper)}
          >
            <FormItem
              className={classNames(classes.formItem)}
              name="speciesName"
              label="Species Name"
              input={(props) => (
                <Autocomplete
                  props={{ ...props, style: { width: 250 } }}
                  data={allSpecies}
                  loading={allSpeciesLoading}
                />
              )}
              rules={[
                {
                  required: true,
                },
              ]}
            />

            <Item className={classNames(classes.formFooter)}>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t('form:showPointsOnTheMap')}
              </Button>
              <Button onClick={onClickCancel}>{t('form:clearMap')}</Button>
            </Item>
          </Form>
        </Col>
      </Row>
      <br />
      {coordinates?.length >= 0 && (
        <>
          <CustomMap
            coordinates={coordinates}
            center={center}
            zoom={zoom}
            legend={legend}
            info="sn"
          />
        </>
      )}
      {!coordinates && !loading && searchParams ? (
        <p className={classNames(classes.noData)}>{t('table:noData')}</p>
      ) : null}
    </PageContent>
  );
};

export default Map;
