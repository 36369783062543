import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  
  return [
    {
      title: t('table:state'),
      width: 70,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      width: 100,
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 150,
      sorter: true,
    },
    {
      title: t('table:town'),
      width: 100,
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      width: 130,
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:eoid'),
      width: 130,
      dataIndex: 'eoid',
      key: 'eoid',
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      width: 130,
      dataIndex: 'eostatus',
      key: 'eostatus',
      sorter: true,
    },
    {
      title: t('table:eotype'),
      width: 130,
      dataIndex: 'eotype',
      key: 'eotype',
      sorter: true,
    },
    {
      title: t('table:nextSurveyYear'),
      width: 130,
      dataIndex: 'nextSurveyYear',
      key: 'nextSurveyYear',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:nextSeedYear'),
      width: 130,
      dataIndex: 'nextSeedYear',
      key: 'nextSeedYear',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:surveyComment'),
      width: 100,
      dataIndex: 'surveyComment',
      key: 'surveyComment',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:surveyInterval'),
      width: 100,
      dataIndex: 'surveyInterval',
      key: 'surveyInterval',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:seedInterval'),
      dataIndex: 'seedInterval',
      key: 'seedInterval',
      width: 100,
      sorter: true,
      editable: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:collection'),
      dataIndex: 'collection',
      key: 'collection',
      width: 100,
      sorter: true,
    },
  ];
};
