import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithSimpleTableAndSearch from '../../pages/PageWithSimpleTableAndSearch';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchSeedPlanFutureSpecies = (nbrEos) =>
  nbrEos
    ? APIFetch(ENDPOINT_URLS.GET_SEED_PLAN_FUTURE_SPECIES(nbrEos), {
        method: 'get',
      })
    : null;

const SeedPlanFutureSpecies = () => (
  <PageWithSimpleTableAndSearch
    fetchData={(nbrEos) => fetchSeedPlanFutureSpecies(nbrEos)}
    searchParamKey="nbrEos"
    titleKey="PCVAdministration:SeedPlanFutureSpecies"
    searchForm={SearchForm}
    columns={columns()}
  />
);

export default SeedPlanFutureSpecies;
