import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import { Form, Button, Row, Col } from 'antd';

import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import FormItem from '../../../shared/components/formItem';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../utils/general';

import { formConfig } from './form-config';

import classes from '../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchUpdate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CHANGE_PASSWORD, {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const ChangePassword = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const {
    data: updatedData,
    loading: updateLoading,
    refetch: updateRefetch,
  } = useAPI(fetchUpdate, {
    initialState: { data: null, loading: false },
    shouldFetch: false,
  });

  const onFinish = (values) => {
    updateRefetch(values);
  };

  useEffect(() => {
    if (updatedData && !updateLoading) {
      if (updatedData.ok) {
        openNotificationWithIcon('success', 'Password changed successfully');

        form.resetFields();
      }
    }
  }, [updateLoading]);

  return (
    <>
      <Title className={classNames(classes.title)} level={3}>
        Change password
      </Title>

      <Row justify="space-around" align="middle">
        <Col span={12}>
          <Form
            {...layout}
            scrollToFirstError
            form={form}
            name="changePassword"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            {formConfig.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                name={name}
                label={label}
                input={input}
                validateFirst
                {...rest}
              />
            ))}

            <Item {...tailLayout} className={classNames(classes.formFooter)}>
              <Button type="primary" htmlType="submit">
                {t('form:submit')}
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ChangePassword;
