import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const TopicsSelect = (props) => {
  const { topicsData, topicsLoading, updateTopics } = useStore();

  useEffect(() => {
    if (!topicsData.length && !topicsLoading) updateTopics();
  }, []);

  return (
    <Select
      props={props}
      width={230}
      data={topicsData}
      loading={topicsLoading}
    />
  );
};

export default TopicsSelect;
