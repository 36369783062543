import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Typography, Row, Col } from 'antd';
import { useHistory } from 'react-router';
import Geocode from 'react-geocode';

import logotype from '../../global/shared/SPROUT lockup_rgb.png';

// eslint-disable-next-line import/no-cycle
import { SECURITY_AGREEMENT } from '../../AppRoutes';
import { FIRST_LOGIN_KEY } from '../../global/constants';
import { GOOGLE_GEOCODING_API_KEY } from '../../shared/components/Map/constants';
import FormItem from '../../shared/components/formItem';
import {
  validateMessages,
  openNotificationWithIcon,
} from '../../utils/general';

import { formConfig } from './form-config';

import classes from './styles.module.scss';

Geocode.setApiKey(GOOGLE_GEOCODING_API_KEY);
Geocode.enableDebug();

const { Title } = Typography;
const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const AccountSettings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState(null);

  const firstLogin = JSON.parse(localStorage.getItem(FIRST_LOGIN_KEY));

  const onFinish = (values) => {
    const { state, city, ...other } = values;

    const address = [city, state].join(', ').replaceAll(' ', '+');
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;

        localStorage.setItem(
          FIRST_LOGIN_KEY,
          JSON.stringify({
            ...firstLogin,
            userInfo: {
              state,
              city,
              latitude: lat || 0,
              longitude: lng || 0,
              ...other,
            },
          })
        );

        history.push(SECURITY_AGREEMENT);
      },
      () => openNotificationWithIcon('error', 'Failed get address coordinates')
    );
  };

  useEffect(() => {
    if (firstLogin?.userInfo) {
      setInitialValues({ ...firstLogin.userInfo });

      localStorage.setItem(
        FIRST_LOGIN_KEY,
        JSON.stringify({
          ...firstLogin,
          userInfo: null,
        })
      );
    } else {
      setInitialValues({});
    }
  }, []);

  return (
    <Row justify="space-around" align="middle">
      <Col span={12}>
        <div className={classes.logotype}>
          <img src={logotype} alt="logotype" />
        </div>
        <Title level={2} className={classes.title}>
          {t('accountSettings:title')}
        </Title>
        {initialValues && (
          <Form
            {...layout}
            name="basic"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            validateMessages={validateMessages}
            validateTrigger={['onBlur', 'onChange']}
          >
            {formConfig.map(({ name, label, input, ...rest }) => (
              <FormItem
                key={name}
                name={name}
                label={label}
                input={input}
                {...rest}
              />
            ))}

            <Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                {t('accountSettings:submit')}
              </Button>
            </Item>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default AccountSettings;
