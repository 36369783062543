import { getMomentDate } from '../../../utils/general';

export const columns = [
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    sorter: true,
    width: 120,
  },
  {
    title: 'Species Name',
    dataIndex: 'speciesName',
    key: 'speciesName',
    sorter: true,
    width: 120,
  },
  {
    title: 'EO Number',
    dataIndex: 'eonumberInt',
    key: 'eonumberInt',
    sorter: true,
    width: 100,
  },
  {
    title: 'Subpopulation',
    dataIndex: 'subpopulation',
    key: 'subpopulation',
    sorter: true,
    width: 100,
  },
  {
    title: 'Town',
    dataIndex: 'town',
    key: 'town',
    sorter: true,
    width: 120,
  },
  {
    title: 'Site name',
    dataIndex: 'siteName',
    key: 'siteName',
    sorter: true,
    width: 120,
  },
  {
    title: 'Survey Result',
    dataIndex: 'surveyResult',
    key: 'surveyResult',
    sorter: true,
    width: 100,
  },
  {
    title: 'Survey Date',
    dataIndex: 'surveyDate',
    key: 'surveyDate',
    sorter: true,
    width: 100,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
];

export const columnsWithEditableField = [
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    sorter: true,
    width: 120,
  },
  {
    title: 'Species Name',
    dataIndex: 'speciesName',
    key: 'speciesName',
    sorter: true,
    width: 120,
  },
  {
    title: 'EO Number',
    dataIndex: 'eonumberInt',
    key: 'eonumberInt',
    sorter: true,
    width: 100,
  },
  {
    title: 'Subpopulation',
    dataIndex: 'subpopulation',
    key: 'subpopulation',
    sorter: true,
    width: 100,
  },
  {
    title: 'Town',
    dataIndex: 'town',
    key: 'town',
    sorter: true,
    width: 120,
  },
  {
    title: 'Site name',
    dataIndex: 'siteName',
    key: 'siteName',
    sorter: true,
    width: 120,
  },
  {
    title: 'Survey Result',
    dataIndex: 'surveyResult',
    key: 'surveyResult',
    sorter: true,
    width: 100,
    editable: true,
  },
  {
    title: 'Survey Date',
    dataIndex: 'surveyDate',
    key: 'surveyDate',
    sorter: true,
    width: 100,
    render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
  },
];
