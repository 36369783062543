import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const DivisionsSelect = (props) => {
  const { divisionsData, divisionsLoading, updateDivisions } = useStore();

  useEffect(() => {
    if (!divisionsData.length && !divisionsLoading) updateDivisions();
  }, [divisionsLoading]);

  return (
    <Select
      props={props}
      width={100}
      data={divisionsData}
      loading={divisionsLoading}
    />
  );
};

export default DivisionsSelect;
