import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';
// eslint-disable-next-line import/no-cycle
import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'state',
  'surveyorAdministrator',
  'surveyorInactive',
  'surveyorPcv',
  'surveyorTf',
  'surveyorTranscriber',
  'surveyorTotal',
];

const fetchSurveyorTypeStateStatus = () =>
  APIFetch(ENDPOINT_URLS.SURVEYOR_TYPE_STATE_STATUS, {
    method: 'get',
  });

export const SurveyorTypeStateStatus = () => {
  const { data, loading } = useAPI(() => fetchSurveyorTypeStateStatus(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('state', data, 'All');

  return (
    <PageWithSimpleTable
      titleKey="Surveyors:SurveyorTypeStateStatus"
      descriptionKey="Surveyors:SurveyorTypeStateStatusDescription"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};
