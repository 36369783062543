import React from 'react';
import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      editable: true,
      sorter: true,
      width: 80,
    },
    {
      title: t('table:source'),
      dataIndex: 'source',
      key: 'source',
      width: 80,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
      editable: true,
      sorter: true,
      width: 80,
    },
    {
      title: t('table:minRamet'),
      dataIndex: 'minRamet',
      key: 'minRamet',
      sorter: true,
      editable: true,
      width: 80,
    },
    {
      title: t('table:maxRamet'),
      dataIndex: 'maxRamet',
      key: 'maxRamet',
      sorter: true,
      editable: true,
      width: 80,
    },
    {
      title: t('table:minGenet'),
      dataIndex: 'minGenet',
      key: 'minGenet',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:maxGenet'),
      dataIndex: 'maxGenet',
      key: 'maxGenet',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:populationComment'),
      dataIndex: 'populationComment',
      key: 'populationComment',
      sorter: true,
      width: 140,
    },
    {
      title: t('table:path'),
      dataIndex: 'path',
      key: 'path',
      width: 150,
      render: (text, { fileLink, path }) => {
        const folders = path ? path.split('/') : [];

        return text ? (
          <a href={fileLink} target="_blank" rel="noreferrer">
            {decodeURIComponent(folders[folders.length - 1])}
          </a>
        ) : (
          ''
        );
      },
    },
  ];
};
