import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { action } from '../../../../shared/EditableTable/components/Action';
import { actionContent } from '../utils';
import { paramsForBE } from '../../../../utils/general';
import PageWithTable from '../../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_RESULTS_PAGED_PCVTF, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_RESULT_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const SurveyResults = () => {
  const { t } = useTranslation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 130,
      renderFunction: (_, record) => actionContent(record),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={fetch}
      fetchExport={fetchExport}
      defaultSortField={[
        'State',
        'SpeciesName',
        'EonumberInt',
        'Subpopulation',
      ]}
      titleKey="PCVTF:SurveyResults"
      descriptionKey="PCVTF:SurveyResultsDescription"
      searchForm={SearchForm}
      columns={allColumns}
      scroll={{ x: 1500 }}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
    />
  );
};

export default SurveyResults;
