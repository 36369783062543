import React from 'react';

import StatesSelect from '../../../shared/components/select/StatesSelect';
import SurveyorsAutocomplete from '../../../shared/components/autocomplete/SurveyorsAutocomplete';

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'EO species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'Town',
    label: 'EO town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SiteName',
    label: 'EO sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'surveyorName',
    label: 'Surveyor name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const editableCellsConfig = [
  {
    name: 'leadSurvName',
    input: (props) => <SurveyorsAutocomplete {...props} />,
  },
  {
    name: 'surv2Name',
    input: (props) => <SurveyorsAutocomplete {...props} />,
  },
  {
    name: 'surv3Name',
    input: (props) => <SurveyorsAutocomplete {...props} />,
  },
  {
    name: 'surv4Name',
    input: (props) => <SurveyorsAutocomplete {...props} />,
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SpeciesName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Synonyms',
    equivalentPropertyName: 'SpeciesName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Town',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SiteName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'CommonName',
    equivalentPropertyName: 'SpeciesName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  },
  {
    propertyName: 'surveyorName',
  },
];