import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
// eslint-disable-next-line import/no-cycle
import PageWithNestedTable from '../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';
import SearchForm from './SearchForm';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchNestedData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_SUMMARY_WITH_FILES_DETAILS(key), {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            {
              orderOption: 'Ascending',
              propertyName: 'Year',
            },
          ],
        }),
      })
    : [];

export const FieldDataSummaryWithFiles = () => (
  <PageWithNestedTable
    fetchData={fetch}
    fetchNestedData={fetchNestedData}
    defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
    nestedTableKey="eokey"
    titleKey="ElementOccurrence:FieldDataSummaryWithFiles"
    descriptionKey="ElementOccurrence:FieldDataSummaryWithFilesDescription"
    searchForm={SearchForm}
    columns={columns()}
    expandableColumns={expandableColumns()}
    rowKeyFunc={(record) => record.eokey}
    scroll={{ x: 1300 }}
  />
);
