import React from 'react';
import moment from 'moment';
import {DatePicker} from "antd";
import { getMomentDate } from '../../../utils/general';

const previousYear = +moment().format('YYYY') - 1;
const currentDate = moment.utc().format('YYYY-MM-DD');

const disableDate = (current) => {
  const tooLate = current.format('YYYY-MM-DD') > currentDate;
  const tooEarly = current.year() < previousYear;
  console.log({ tooLate, current: current.format('YYYY-MM-DD'), today: currentDate })

  return tooEarly || tooLate;
};

export const feSearchConfig = [
  {
    name: 'LastName',
    label: 'Owner Name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
];

export const editableCellsConfig = [
  {
    name: 'dateReportSent',
    input: ({ value, ...props }) => (
        <DatePicker
            {...props}
            value={value ? getMomentDate(value) : ''}
            format="YYYY-MM-DD"
            disabledDate={disableDate}
        />
    ),
  },
];

export const beSearchConfig = [
  {
    propertyName: 'LastName ',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
  },
  {
    propertyName: 'FirstName ',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    propertyName: 'Organization ',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    propertyName: 'SubOrganization',
    operatortype: 'Contains',
    equivalentPropertyName: 'LastName',
    previousSearchBlockConnectionOperator: 'Or',
  },
];