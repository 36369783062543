import React from 'react';
import { InputNumber, Input, Checkbox } from 'antd';

import SurveyorTypesExceptAdminSelect from '../../../../shared/components/select/SurveyorTypesExceptAdminSelect';
import DaysAvailableToSurveySelect from '../../../../shared/components/select/DaysAvailableToSurveySelect';

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const { TextArea } = Input;

export const formConfig = [
  {
    name: 'title',
    label: 'Title',
    rules: [
      {
        max: 15,
      },
    ],
  },
  {
    name: 'firstName',
    label: 'First Name',
    rules: [
      {
        required: true,
        max: 50,
      },
    ],
  },
  {
    name: 'lastName',
    label: 'Last Name',
    rules: [
      {
        required: true,
        max: 50,
      },
    ],
  },
  {
    name: 'jobTitle',
    label: 'Job Title',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'organization',
    label: 'Organization',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'address1',
    label: 'Address 1',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'address2',
    label: 'Address 2',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'city',
    label: 'City',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'state',
    label: 'State',
    rules: [
      {
        required: true,
        max: 2,
      },
    ],
  },

  {
    name: 'postalCode',
    label: 'Postal Code',
    rules: [
      {
        max: 5,
      },
    ],
  },
  {
    name: 'phone',
    label: 'Phone',
    extra: 'Please separate multiple phone numbers by commas.',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'fax',
    label: 'Fax',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'email',
    label: 'Email',
    rules: [
      {
        max: 80,
      },
    ],
  },
  {
    name: 'comments',
    label: 'Comments',
    input: (props) => <TextArea {...props} />,
  },
  {
    name: 'surveyorType',
    label: 'Surveyor Type',
    input: (props) => (
      <SurveyorTypesExceptAdminSelect {...props} width="100%" />
    ),
    rules: [
      {
        required: true,
        max: 20,
      },
    ],
  },
  {
    name: 'yearTrained',
    label: 'Year Trained',
  },
  {
    name: 'yearLastActive',
    label: 'Year Last Active',
  },
  {
    name: 'latitude',
    label: 'Latitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: 0,
        max: 47.5,
      },
    ],
  },
  {
    name: 'longitude',
    label: 'Longitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: -74,
        max: 0,
      },
    ],
  },
  {
    name: 'daysAvailableToSurvey',
    label: 'Days available to survey',
    input: (props) => <DaysAvailableToSurveySelect {...props} />,
    rules: [{ required: true }],
    operatortype: 'Equals',
  },
  {
    name: 'emergencyContactName',
    label: 'Emergency contact name',
    rules: [
      {
        required: false,
      },
    ],
  },
  {
    name: 'emergencyContactPhoneNumber',
    label: 'Emergency contact phone number',
    rules: [
      {
        required: false,
      },
    ],
  },
  {
    name: 'emergencyContactEmailAddress',
    label: 'Emergency contact email address',
    rules: [
      {
        type: 'email',
        max: 80,
      },
    ],
  },
  {
    name: 'deleted',
    label: 'Mark as delete',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
  },
];
