import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithSimpleTableAndSearch from '../../pages/PageWithSimpleTableAndSearch';

import { columns } from './TableColumns';

const fetchCounty = () =>
  APIFetch(ENDPOINT_URLS.GET_COUNTY, {
    method: 'get',
  });

const County = () => (
  <PageWithSimpleTableAndSearch
    fetchData={() => fetchCounty()}
    titleKey="Validation:County"
    descriptionKey="Validation:CountyDescription"
    columns={columns()}
    rowKey={uuidv4()}
  />
);

export default County;
