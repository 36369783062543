import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SurveyorTypeSelect = (props) => {
  const { surveyorTypesData, surveyorTypesLoading, updateSurveyorTypes } =
    useStore();

  useEffect(() => {
    if (!surveyorTypesData.length && !surveyorTypesLoading)
      updateSurveyorTypes();
  }, []);

  return (
    <Select
      props={props}
      width={150}
      data={surveyorTypesData}
      loading={surveyorTypesLoading}
    />
  );
};

export default SurveyorTypeSelect;
