import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../../../global/stores/auth';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { action } from '../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../pages/PageWithEditableTable';
import {
  downloadExcel,
  openNotificationWithIcon,
  paramsForBE,
  getIsForbidden,
} from '../../../utils/general';
import { useStore } from '../../../global/stores/store/StoreContext';

import SearchForm from './SearchForm';
import { beSearchConfig, editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent, createBodyForRequest } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchSurveySignup = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_SIGNUP_PAGED_PCVTF, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_SIGNUP_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchSurveyors = () =>
  APIFetch(ENDPOINT_URLS.GET_SURVEYORS, { method: 'get' });

const SurveySignupPCV = () => {
  const { t } = useTranslation();
  const { pathname, search: searchParams } = useLocation();

  const { user } = useAuth();
  const { firstName, lastName, role } = user;
  const fullName = [lastName, firstName].join(' ');

  const [form] = Form.useForm();

  const { countiesData } = useStore();

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading, refetch } = useAPI(fetchSurveySignup, {
    initialState: { data: {}, loading: false },
    shouldFetch: false,
  });

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: surveyors } = useAPI(fetchSurveyors, {
    initialState: { data: [] },
    shouldFetch: !isForbidden,
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({});

  const cancel = () => {
    setEditingKey('');
  };

  const defaultSortFields = [
    'State',
    'SpeciesName',
    'Eonumber',
    'Subpopulation',
  ];

  const changeParamsValue = () => {
    const initialSearchParamsArray = buildSearchParamsForRequest(searchParams);
    const elementForChangeValue = initialSearchParamsArray.find(
      (item) => item.propertyName === 'CountyKey'
    );
    const countyValue = elementForChangeValue?.value;
    const index = initialSearchParamsArray.findIndex(
      (n) => n.propertyName === 'CountyKey'
    );
    if (index !== -1) {
      initialSearchParamsArray.splice(index, 1);
    }
    const countyItemKey = countiesData.find(
      (i) => i?.item === countyValue
    )?.itemKey;

    const searchParamsArray = [
      ...initialSearchParamsArray,
      {
        operatortype: 'Equals',
        value: countyItemKey,
        propertyName: 'CountyKey',
      },
    ];
    return searchParamsArray;
  };

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE(searchParameters, beSearchConfig).filter(
      ({ value }) => !!value
    );

    refetch(
      paramsToPassInBodyRequest(parameters, searchParam, defaultSortFields)
    );
  };

  const [parametersForExport, setParametersForExport] = useState();
  const onExport = () => {
    if (parametersForExport) {
      const parameters = getRandomuserParams(parametersForExport);
      const searchParam = paramsForBE(searchParameters, beSearchConfig).filter(
        ({ value }) => !!value
      );
      refetchExport(
        paramsToPassInBodyRequest(parameters, searchParam, defaultSortFields)
      );
    } else {
      const searchParamsArray = changeParamsValue();
      const parameters = paramsToPassInBodyRequest(
        {
          pagination: initialPagination,
        },
        paramsForBE
          ? paramsForBE(searchParamsArray, beSearchConfig)
          : searchParamsArray,
        defaultSortFields
      );
      if (parameters.State === '') {
        parameters.State = null;
      }
      refetchExport(parameters);
    }
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      return;
    }

    const initialSearchParamsArray = buildSearchParamsForRequest(searchParams);

    const searchParamsArray = changeParamsValue();
    if (initialSearchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          paramsForBE(searchParamsArray, beSearchConfig).filter(
            ({ value }) => !!value
          ),
          defaultSortFields
        )
      );

      setSearchParameters(searchParamsArray);
      cancel();
    }
  }, [searchParams]);

  const initialState = {
    leadSurvName: '-',
    surv2Name: '-',
    surv3Name: '-',
    surv4Name: '-',
  };

  const save = async (key) => {
    const row = await form.validateFields();
    const { leadSurvName, surv2Name, surv3Name, surv4Name } = row;

    const resultRow = {
      leadSurvName: leadSurvName === '-' ? '' : leadSurvName,
      surv2Name: surv2Name === '-' ? '' : surv2Name,
      surv3Name: surv3Name === '-' ? '' : surv3Name,
      surv4Name: surv4Name === '-' ? '' : surv4Name,
    };
    const result = createBodyForRequest(data, key, resultRow, surveyors);

    APIFetch(ENDPOINT_URLS.EDIT_SURVEY_SIGNUP_FIELDS_PCVTF(key), {
      method: 'put',
      body: JSON.stringify(result),
    }).then(({ status }) => {
      if (status) {
        const newData = [...tableData];
        const index = newData.findIndex((item) => key === item.eokey);

        if (index > -1) {
          const item = newData[index];

          const updatedParams = {};
          Object.keys(resultRow)
            .filter((i) => resultRow[i] !== undefined)
            .map((i) => {
              updatedParams[i] = resultRow[i];

              return i;
            });

          newData.splice(index, 1, { ...item, ...updatedParams });
        } else {
          newData.push(resultRow);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      }
    });
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: ({
        leadSurvName,
        surv2Name,
        surv3Name,
        surv4Name,
        ...other
      }) => {
        const record = {
          ...other,
          leadSurvName: leadSurvName || '-',
          surv2Name: surv2Name || '-',
          surv3Name: surv3Name || '-',
          surv4Name: surv4Name || '-',
        };

        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey
        );

        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        let editing = isCellEditing(record, editingKey);

        if (
          ['leadSurvName', 'surv2Name', 'surv3Name', 'surv4Name'].includes(
            col.key
          ) &&
          !['', fullName].includes(record[col.key])
        ) {
          editing = false;
        }

        return {
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing,
        };
      },
    };
  });

  return (
    <PageWithEditableTable
      titleKey="PCVTF:SurveySignUp"
      descriptionKey="PCVTF:SurveySignUpDescription"
      searchForm={SearchForm}
      columns={mergedColumns}
      setParametersForExport={setParametersForExport}
      rowKeyFunc={(record) => record.eokey}
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      isExportContent
      onExport={onExport}
      exportLoading={exportLoading}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
    />
  );
};

export default SurveySignupPCV;
