import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { actionContent } from '../utils';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const FieldDataEdit = () => {
  const { t } = useTranslation();
  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 60,
      render: (_, record) => actionContent(record),
    },
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={fetch}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="ElementOccurrence:FieldDataEditPaged"
      descriptionKey="ElementOccurrence:FieldDataEditDescription"
      searchForm={SearchForm}
      columns={allColumns}
      scroll={{ x: 1600 }}
      rowKey={(record) => record.eokey}
    />
  );
};

export default FieldDataEdit;
