import React from 'react';
import { useParams } from 'react-router-dom';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { getFormData } from '../../../../utils/general';
import ValidationDataForm from '../../ValidationDataTabPages/ValidationDataForm';

import { formConfig, formConfigWithFile } from './form-config';

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_LINK_PCV_ADMIN, {
        method: 'post',
        initialHeaders: {},
        body: getFormData(args),
      })
    : null;

const fetchUpdate = (id, args) =>
  id && args
    ? APIFetch(ENDPOINT_URLS.UPDATE_LINK_PCV_ADMIN(id), {
        method: 'put',
        initialHeaders: {},
        body: getFormData(args),
      })
    : null;

const fetch = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.GET_ONE_LINK_PCV_ADMIN(id), {
        method: 'get',
      })
    : {};

const PCVManagementForm = () => {
  const { key } = useParams();
  const section = key.split('_')[1];

  const getParamsForInitialValues = (isUpdateMode, params) => {
    if (!isUpdateMode) {
      return {};
    }

    const { fileAttached, link1, linkId, ...other } = params;
    const folders = link1.split('/');

    return {
      ...other,
      [link1 ? 'file' : 'link1']: link1
        ? {
            uid: linkId,
            name: folders[folders.length - 1],
            url: link1,
          }
        : link1,
    };
  };

  const getParamsForSubmit = (params) => {
    const { file, ...other } = params;
    
    return file
      ? {
          ...other,
          section,
          [file.file ? 'file' : 'link1']: file.file
            ? file.file?.originFileObj || file.file
            : file.url,
        }
      : { ...other, section };
  };

  const sectionWithFile =
    'Rare plant reporting form, PCV handbook and state supplements';

  const condition = section === sectionWithFile;

  return (
    <ValidationDataForm
      fetch={fetch}
      fetchCreate={fetchCreate}
      fetchUpdate={fetchUpdate}
      pageKey={section}
      pageName="Link"
      idKey="link1"
      formConfig={condition ? formConfigWithFile : formConfig}
      getParamsForInitialValues={getParamsForInitialValues}
      getParamsForSubmit={getParamsForSubmit}
    />
  );
};

export default PCVManagementForm;
