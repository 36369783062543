import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

export const actionContent = (record) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { eokey } = record;

  return <Link to={`${pathname}/${eokey}`}>{t('table:select')}</Link>;
};
