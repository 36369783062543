import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { downloadExcel } from '../../../../utils/general';

export const actionContent = (record, fetchExport) => {
  const { pathname } = useLocation();

  const { data: exportData, refetch: refetchExport } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const onExport = () => {
    refetchExport(record?.ownerKey);
  };

  const name = `${pathname}_${record?.ownerKey}`;
  useEffect(() => downloadExcel(name, exportData, refetchExport), [exportData]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid
    <a onClick={onExport}>Export to Excel</a>
  );
};
