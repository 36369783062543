import React from 'react';
import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
      width: 250,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      width: 120,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 140,
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      width: 200,
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
      width: 100,
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('table:source'),
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
    },
    {
      title: t('table:populationComment'),
      dataIndex: 'populationComment',
      key: 'populationComment',
    },
    {
      title: t('table:minRamet'),
      dataIndex: 'minRamet',
      key: 'minRamet',
    },
    {
      title: t('table:maxRamet'),
      dataIndex: 'maxRamet',
      key: 'maxRamet',
    },
    {
      title: t('table:minGenet'),
      dataIndex: 'minGenet',
      key: 'minGenet',
    },
    {
      title: t('table:maxGenet'),
      dataIndex: 'maxGenet',
      key: 'maxGenet',
    },
    {
      title: t('table:seedTotal'),
      dataIndex: 'seedTotal',
      key: 'seedTotal',
    },
    {
      title: t('table:seedNumberOfPlants'),
      dataIndex: 'seedNumberPlants',
      key: 'seedNumberPlants',
    },
    {
      title: t('table:filename'),
      dataIndex: 'fullFilename',
      key: 'fullFilename',
      render: (text, { fileLink }) =>
        text ? <a href={fileLink}>{text}</a> : '',
    },
  ];
};
