import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useStore } from '../../../global/stores/store/StoreContext';
import { useAuth } from '../../../global/stores/auth';
import { roleTypes } from '../../../utils/roles';
import { LIST_OF_PATH_FOR_ALL_STATES } from '../../../global/constants';

import { Select } from './Select';
import StatesForSurveyorSelect from './StatesForSurveyorSelect';

const StatesSelect = (props) => {
  const { user, securityAgreementAccepted } = useAuth();
  const { pathname } = useLocation();

  const isShowAllStates = LIST_OF_PATH_FOR_ALL_STATES.includes(pathname);
  const isHeritageRole = user.role === roleTypes.Heritage;

  if (isHeritageRole && securityAgreementAccepted && !isShowAllStates) return <StatesForSurveyorSelect {...props} withAll={false} />;

  const { statesData, statesLoading, updateStates } = useStore();
  const { multiple } = props;

  useEffect(() => {
    if (!statesData.length && !statesLoading) updateStates();
  }, []);

  return (
    <Select
      props={props}
      width={multiple ? 150 : 85}
      data={statesData}
      loading={statesLoading}
    />
  );
};

export default StatesSelect;
