import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_ACTIVE_EOS_WITH_CONFLICTING_FIELD_DATA, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_ACTIVE_EOS_WITH_CONFLICTING_FIELD_DATA_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

export const ActiveEOsWithConflictingFieldData = () => (
  <PageWithTable
    fetchData={fetch}
    fetchExport={fetchExport}
    defaultSortField={['State', 'SpeciesName', 'EonumberInt']}
    titleKey="ElementOccurrence:ActiveEOsWithConflictingFieldData"
    descriptionKey="ElementOccurrence:ActiveEOsWithConflictingFieldDataDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
  />
);
