import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:collectSeed'),
      dataIndex: 'collectSeed',
      key: 'collectSeed',
      sorter: true,
    },
    {
      title: t('table:leadSurvName'),
      dataIndex: 'leadSurvName',
      key: 'leadSurvName',
      sorter: true,
    },
    {
      title: t('table:surv2Name'),
      dataIndex: 'surv2Name',
      key: 'surv2Name',
      sorter: true,
    },
    {
      title: t('table:surv3Name'),
      dataIndex: 'surv3Name',
      key: 'surv3Name',
      sorter: true,
    },
    {
      title: t('table:surv4Name'),
      dataIndex: 'surv4Name',
      key: 'surv4Name',
      sorter: true,
    },
  ];
};
