import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SlopesSelect = (props) => {
  const { slopesData, slopesLoading, updateSlopes } = useStore();

  useEffect(() => {
    if (!slopesData.length && !slopesLoading) updateSlopes();
  }, []);

  return <Select props={props} data={slopesData} loading={slopesLoading} />;
};

export default SlopesSelect;
