import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
      width: 250,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      width: 120,
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      width: 140,
      sorter: true,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      width: 200,
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      width: 150,
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
    },
    {
      title: t('table:survey'),
      dataIndex: 'survey',
      key: 'survey',
    },
    {
      title: t('table:collectSeed'),
      dataIndex: 'collectSeed',
      key: 'collectSeed',
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
    },
    {
      title: t('table:latitude'),
      dataIndex: 'latitude',
      key: 'latitude',
    },
    {
      title: t('table:longitude'),
      dataIndex: 'longitude',
      key: 'longitude',
    },
    {
      title: t('table:locationPrecision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
    },
    {
      title: t('table:ownerNotes'),
      dataIndex: 'ownerNotes',
      key: 'ownerNotes',
    },
  ];
};
