import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const TopoPositionsSelect = (props) => {
  const { topoPositionsData, topoPositionsLoading, updateTopoPositions } =
    useStore();

  useEffect(() => {
    if (!topoPositionsData.length && !topoPositionsLoading)
      updateTopoPositions();
  }, []);

  return (
    <Select
      props={props}
      data={topoPositionsData}
      loading={topoPositionsLoading}
    />
  );
};

export default TopoPositionsSelect;
