import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetchEOsOutsideDistribution = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_EOS_OUTSIDE_DISTRIBUTION_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const EOsOutsideDistribution = () => (
  <PageWithTable
    fetchData={(args) => fetchEOsOutsideDistribution(args)}
    defaultSortField={['State', 'SpeciesName', 'EonumberInt']}
    titleKey="StatusOfPlants:EOsOutsideDistribution"
    descriptionKey="StatusOfPlants:EOsOutsideDistributionDescription"
    searchForm={SearchForm}
    columns={columns()}
  />
);

export default EOsOutsideDistribution;
