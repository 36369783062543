import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { action } from '../../../../shared/EditableTable/components/Action';
// eslint-disable-next-line import/named
import { actionContentFfkey } from '../utils';

import PageWithTable from '../../../pages/PageWithTable';

import { columns } from './TableColumns';

const bodyForFirstRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: [
    {
      orderOption: 'Ascending',
      propertyName: 'Threats',
    },
  ],
};

const fetchManualFieldForm = (args, key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_MANUAL_FIELD_FORM(key), {
        method: 'post',
        body: args ? JSON.stringify(args) : JSON.stringify(bodyForFirstRequest),
      })
    : {};

export const ManualFieldForm = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { search: searchKey } = useLocation();
  const query = new URLSearchParams(searchKey);
  const keyFromUrl = query.getAll('eokey');
  const nameFromUrl = query.getAll('name')[0];

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 70,
      renderFunction: (_, record) =>
        actionContentFfkey(
          record,
          '/ElementOccurrence/FieldDataManual/ManualFieldForm/ManualFieldFormDetail'
        ),
    }),
    ...staticColumns,
  ];

  useEffect(() => {
    if (nameFromUrl) {
      history.replace({
        state: {
          breadcrumbsName: nameFromUrl,
        },
        search: searchKey,
      });
    }
  }, []);

  return (
    <PageWithTable
      fetchData={(args) => fetchManualFieldForm(args, keyFromUrl[0])}
      defaultSortField={['Year', 'SurveyDate']}
      titleKey="ElementOccurrence:ManualFieldForm"
      descriptionKey="ElementOccurrence:ManualFieldFormDescription"
      columns={allColumns}
      scroll={{ x: 1300 }}
    />
  );
};
