import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchGoBotanySpeciesCharacter = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_GO_BOTANY_SPECIES_CHARACTER, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const GoBotanySpeciesCharacter = () => (
  <PageWithTable
    fetchData={(args) => fetchGoBotanySpeciesCharacter(args)}
    defaultSortField={['SpeciesName', 'CharacterGroup', 'CharacterName']}
    titleKey="Species:GoBotanySpeciesCharacter"
    descriptionKey="Species:GoBotanySpeciesCharacterDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1100 }}
  />
);

export default GoBotanySpeciesCharacter;
