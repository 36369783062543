import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      width: 60,
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      width: 60,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:maxGenet'),
      dataIndex: 'maxGenet',
      key: 'maxGenet',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:maxRamet'),
      dataIndex: 'maxRamet',
      key: 'maxRamet',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:source'),
      dataIndex: 'source',
      key: 'source',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:populationComment'),
      dataIndex: 'populationComment',
      key: 'populationComment',
      width: 100,
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 100,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 400,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {text}
          </div>
        </Tooltip>
      ),
      sorter: true,
    },

    {
      title: t('table:seedTotal'),
      dataIndex: 'seedTotal',
      key: 'seedTotal',
      width: 70,
      sorter: true,
    },
  ];
};
