import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetchSpeciesStatus = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_STATUS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const SpeciesStatus = () => (
  <PageWithTable
    fetchData={(args) => fetchSpeciesStatus(args)}
    defaultSortField="SpeciesName"
    titleKey="StatusOfPlants:SpeciesStatus"
    descriptionKey="StatusOfPlants:SpeciesStatusDescription"
    searchForm={SearchForm}
    columns={columns()}
  />
);

export default SpeciesStatus;
