import React from 'react';
import classNames from 'clsx';

import { useStore } from '../../../global/stores/store/StoreContext';
import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';

import { searchFormConfig } from './form-config';

import classes from '../../styles.module.scss';

const SearchForm = ({ tableLoading }) => {
  const { statesLoading = false, divisionsLoading = false } = useStore();

  return (
    <div className={classNames(classes.searchForm)}>
      <CustomSearchForm
        searchConfig={searchFormConfig}
        initialValues={{
          State: '',
          Division: '',
        }}
        loading={statesLoading || tableLoading || divisionsLoading}
      />
    </div>
  );
};

export default SearchForm;
