export const validatePassword = [
  {
    validator: (_, value) => {
      const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
      if (lowerCaseRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          'The string must contain at least 1 lowercase alphabetical character'
        )
      );
    },
  },
  {
    validator: (_, value) => {
      const upperCaseRegex = new RegExp('^(?=.*[A-Z])');
      if (upperCaseRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          'The string must contain at least 1 uppercase alphabetical character'
        )
      );
    },
  },
  {
    validator: (_, value) => {
      const numericRegex = new RegExp('^(?=.*[0-9])');
      if (numericRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error('The string must contain at least 1 numeric character')
      );
    },
  },
  {
    validator: (_, value) => {
      const numericRegex = new RegExp('^(?=.*[!@#$%^&*])');
      if (numericRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error('The string must contain at least one special character')
      );
    },
  },
  {
    validator: (_, value) => {
      const lengthRegex = new RegExp('^(?=.{6,})');
      if (lengthRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error('The string must be six characters or longer')
      );
    },
  },
];

export const confirmPassword = [
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      const password =
        getFieldValue('password') || getFieldValue('newPassword');

      if (!value || password === value) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('Does not match new password'));
    },
  }),
];
