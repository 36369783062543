import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:researchTopic'),
      dataIndex: 'researchTopic',
      key: 'researchTopic',
      sorter: true,
    },
    {
      title: t('table:fullText'),
      dataIndex: 'fullText',
      key: 'fullText',
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
    },
  ];
};
