import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import PageWithTable from '../../pages/PageWithTable';
import { paramsForBE } from '../../../utils/general';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetchPopulationSize = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_POPULATION_SIZE_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_POPULATION_SIZE_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const PopulationSize = () => (
  <PageWithTable
    fetchData={(args) => fetchPopulationSize(args)}
    fetchExport={fetchExport}
    defaultSortField={[
      'SpeciesName',
      'State',
      'Town',
      'SiteName',
      'EonumberInt',
      'Subpopulation',
      'Year',
    ]}
    titleKey="StatusOfPlants:PopulationSize"
    descriptionKey="StatusOfPlants:PopulationSizeDescription"
    searchForm={SearchForm}
    columns={columns()}
    beSearchConfig={beSearchConfig}
    paramsForBE={paramsForBE}
    scroll={{ x: 1700 }}
  />
);

export default PopulationSize;
