import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';

const defaultSortFields = ['State', 'SpeciesName', 'Eonumber', 'Subpopulation'];

const defaultBodyRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: defaultSortFields.map((propertyName) => ({
    orderOption: 'Ascending',
    propertyName,
  })),
};

const fetchStatus = (args) =>
  APIFetch(ENDPOINT_URLS.GET_PCV_TF_STATUS, {
    method: 'post',
    body: JSON.stringify(args || defaultBodyRequest),
  });

export const Status = () => (
  <PageWithTable
    fetchData={(args) => fetchStatus(args)}
    defaultSortField={defaultSortFields}
    titleKey="PCVTF:Status"
    descriptionKey="PCVTF:StatusDescription"
    columns={columns()}
    scroll={{ x: 1500 }}
  />
);
