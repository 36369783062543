// eslint-disable-next-line import/no-cycle
import LogIn from './views/auth/LogIn';
import SecurityAgreement from './views/auth/SecurityAgreements';
// eslint-disable-next-line import/no-cycle
import AccountSettings from './views/auth/AccountSettings';
import { Home } from './views/home';
import ElementOccurrencePage from './views/sections/ElementOccurrencePage';
import SpeciesPage from './views/sections/SpeciesPage';
import OwnersPage from './views/sections/OwnersPage';
import SurveyorPage from './views/sections/SurveyorPage';
import ValidationPage from './views/sections/ValidationPage';
import CountyPage from './views/ValidationData/County';
import StatePage from './views/ValidationData/State';
import SurveyorType from './views/ValidationData/SurveyorType';
import ValidationOwnerStatus from './views/ValidationData/OwnerStatusPaged/OwnerStatus';
import OwnerStatusForm from './views/ValidationData/OwnerStatusPaged/OwnerStatusForm';
import ValidationOwnerType from './views/ValidationData/OwnerTypePaged/OwnerType';
import OwnerTypeForm from './views/ValidationData/OwnerTypePaged/OwnerTypeForm';
import PCVPage from './views/sections/PCVPage';
import PCVAdminPage from './views/sections/PCVAdminPage';
import PlantsStatusPage from './views/sections/PlantsStatusPage';
import SurveyPlan from './views/PCVAdmin/SurveyPlan';
import SurveyStatusDetail from './views/PCVAdmin/SurveyStatusDetail';
import SurveyorHistory from './views/PCVAdmin/SurveyorHistory';
import { StateSource } from './views/ElementOccurrence/ElementOccurrenceStateSource';
import { StateDivision } from './views/ElementOccurrence/ElementOccurrenceStateDivision';
import { StateStatus } from './views/ElementOccurrence/ElementOccurrenceStateStatus';
import { DivisionStatus } from './views/ElementOccurrence/ElementOccurrenceDivisionStatus';
import { DivisionStateStatus } from './views/ElementOccurrence/ElementOccurrenceDivisionStateStatus';
import { StateOwner } from './views/ElementOccurrence/ElementOccurrenceStateOwner';
import { StateDataMismatch } from './views/ElementOccurrence/ElementOccurrenceStateDataMismatch';
import { SeedBankDivision } from './views/ElementOccurrence/SeedBankDivision';
import { SeedCollectionEOs } from './views/ElementOccurrence/SeedCollectionEOs';
import { SeedBankDivisionState } from './views/ElementOccurrence/SeedBankDivisionState';
import { InactiveEOsWithObservations } from './views/ElementOccurrence/InactiveEOsWithObservations';
import { ActiveEOsWithConflictingFieldData } from './views/ElementOccurrence/ActiveEOsWithConflictingFieldData';
import { EOsWithDataErrors } from './views/ElementOccurrence/EOsWithDataErrors';
import { EOsWithoutEONumbers } from './views/ElementOccurrence/EOsWithoutEONumbers';
import { FieldDataSeedBySpecies } from './views/ElementOccurrence/FieldDataSeedBySpecies';
import { FieldDataConservationSpeciesNoSeed } from './views/ElementOccurrence/FieldDataConservationSpeciesNoSeed';
import { FieldDataManualEnter } from './views/ElementOccurrence/FieldDataManualEnter';
import { CreateFieldDataManualEnter } from './views/ElementOccurrence/CreateFieldDataManualEnter';
import { HerbariumRecovery } from './views/ElementOccurrence/HerbariumRecovery';
import ElementOccurrenceStatusPage from './views/ElementOccurrenceStatusPage';
import { MultiSpeciesSites } from './views/ElementOccurrence/MultiSpeciesSites';
import { FieldDataManual } from './views/ElementOccurrence/FieldDataManualPages/FieldDataManual';
import { ManualFieldForm } from './views/ElementOccurrence/FieldDataManualPages/ManualFieldForm';
import { ManualFieldFormDetail } from './views/ElementOccurrence/FieldDataManualPages/ManualFieldFormDetail/ManualFieldFormDetail';
import { FieldDataSPROUT } from './views/ElementOccurrence/FieldDataSPROUTPages/FieldDataSPROUT';
import { SPROUTFieldForm } from './views/ElementOccurrence/FieldDataSPROUTPages/SPROUTFieldForm';
import { SPROUTFieldFormDetail } from './views/ElementOccurrence/FieldDataSPROUTPages/SPROUTFieldFormDetail/SPROUTFieldFormDetail';
import { FieldDataSeed } from './views/ElementOccurrence/FieldDataSeed';
import { FieldDataSummaryWithFiles } from './views/ElementOccurrence/FieldDataSummaryWithFiles';
import { ElementOccurrenceOwner } from './views/ElementOccurrence/ElementOccurrenceOwnerPages/ElementOccurrenceOwner';
import { ElementOccurrenceOwnerByKey } from './views/ElementOccurrence/ElementOccurrenceOwnerPages/ElementOccurrenceOwnerByKey';
import { ElementOccurrenceOwnerForm } from './views/ElementOccurrence/ElementOccurrenceOwnerPages/ElementOccurrenceOwnerForm';
import { DisplayAndEdit } from './views/ElementOccurrence/ElementOccurrenceDisplayAndEditPages/DisplayAndEdit';
import { DisplayAndEditForm } from './views/ElementOccurrence/ElementOccurrenceDisplayAndEditPages/DisplayAndEditForm';
import { DisplayForm } from './views/ElementOccurrence/ElementOccurrenceDisplayAndEditPages/DisplayForm';
import { SurveyorStateStatus } from './views/Surveyors/SurveyorStateStatus';
import { SurveyorTypeStateStatus } from './views/Surveyors/SurveyorTypeStateStatus';
import { SurveyorsNoState } from './views/Surveyors/SurveyorsNoState';
import { SurveyorByDistance } from './views/Surveyors/SurveyorByDistance';
import { ResearchQuestionsBySpecies } from './views/Species/ResearchQuestionsBySpecies';
import { ResearchQuestionsByTopic } from './views/Species/ResearchQuestionsByTopic';
import { GoBotanySpeciesInformation } from './views/Species/GoBotanySpeciesInformation';
import { ManagementNotes } from './views/StatusOfPlants/ManagementNotes';
import { ChecklistDistribution } from './views/StatusOfPlants/ChecklistDistribution';
import { ManagementNotesSPROUT } from './views/StatusOfPlants/ManagementNotesSPROUT';
import SurveyorStatusPage from './views/SurveyorStatusPage';
import SynonymName from './views/Species/ChecklistSynonym';
import SpeciesConservationStatus from './views/Species/SpeciesConservationStatus';
import SpeciesInvasiveStatus from './views/Species/SpeciesInvasiveStatus';
import SpeciesWithNoEOs from './views/Species/SpeciesWithNoEOs';
import GetGoBotanySpeciesCharacter from './views/Species/GoBotanySpeciesCharacter';
import GoBotanyDistribution from './views/Species/GoBotanyDistribution';
import SurveySignup from './views/PCVAdmin/SurveySignup';
import SurveyPlanQA from './views/PCVAdmin/SurveyPlanQA';
import PlanQASurvey15Year from './views/PCVAdmin/PlanQASurvey15Year';
import PlanQASurveyInactive from './views/PCVAdmin/PlanQASurveyInactive';
import PlanQASeedCollNo from './views/PCVAdmin/PlanQASeedCollNo';
import PlanQASeedCollYes from './views/PCVAdmin/PlanQASeedCollYes';
import PlanQASeedYearInterval from './views/PCVAdmin/PlanQASeedYearInterval';
import PlanQASurvey10Year from './views/PCVAdmin/PlanQASurvey10Year';
import PlanQASurveyIfSeed from './views/PCVAdmin/PlanQASurveyIfSeed';
import PlanQASurveyNoStatus from './views/PCVAdmin/PlanQASurveyNoStatus';
import PlanQASurveySeedYearSync from './views/PCVAdmin/PlanQASurveySeedYearSync';
import PlanQASurveyYearInterval from './views/PCVAdmin/PlanQASurveyYearInterval';
import SignupDate from './views/PCVAdmin/SignupDate';
import SurveyResultPriorYear from './views/PCVAdmin/SurveyResultPriorYear';
import Permit from './views/PCVAdmin/Permit/Permit';
import EnterPermission from './views/PCVAdmin/EnterPermission';
import Announcements from './views/PCVAdmin/AnnouncementsPaged/Announcements';
import AnnouncementsForm from './views/PCVAdmin/AnnouncementsPaged/AnnouncementsForm';
import PCVPrograms from './views/PCVAdmin/PCVProgramsPaged/PCVPrograms';
import PCVProgramsForm from './views/PCVAdmin/PCVProgramsPaged/PCVProgramsForm';
import SurveyorAssignment from './views/PCVAdmin/SurveyorAssignmentPages/SurveyorAssignment';
import SurveyorAssignmentDetail from './views/PCVAdmin/SurveyorAssignmentPages/SurveyorAssignmentDetails';
import { Status } from './views/PCVTF/Status';
import AssignmentSummary from './views/PCVTF/AssignmentSummaryPages/AssignmentSummary';
import AssignmentSummaryDetails from './views/PCVTF/AssignmentSummaryPages/AssignmentSummaryDetails';
import { SpeciesDivision } from './views/Species/SpeciesDivision';
import { SpeciesStateStatus } from './views/Species/SpeciesStateStatus';
import { SpeciesWithEOs } from './views/Species/SpeciesWithEOs';
import { SpeciesWithoutStateStatus } from './views/Species/SpeciesWithoutStateStatus';
import { SpeciesWithoutEOs } from './views/Species/SpeciesWithoutEOs';
import { SpeciesPhenology } from './views/Species/SpeciesPhenology';
import SpeciesStatusPage from './views/Species/SpeciesStatusPage';
import { SpeciesWithoutStateConservationStatus } from './views/Species/SpeciesWithoutStateConservationStatus';
import OwnerStatusPage from './views/Owners/OwnerStatusPage';
import { OwnerStatus } from './views/Owners/OwnerStatus';
import { OwnerType } from './views/Owners/OwnerType';
import { OwnerTypeState } from './views/Owners/OwnerTypeState';
import SurveySignupPCV from './views/PCVTF/SurveySignup';
import OwnerHistory from './views/Owners/OwnerHistory';
import ContactList from './views/PCVTF/ContactList';
import SignUpStatus from './views/PCVTF/SignUpStatus';
import SurveyResults from './views/PCVTF/SurveyResultsPages/SurveyResults';
import SurveySPROUTFieldFormDetail from './views/PCVTF/SurveyResultsPages/SPROUTFieldFormDetail';
import SurveySPROUTFieldForm from './views/PCVTF/SurveyResultsPages/SPROUTFieldForm';
import SurveyPlanPCV from './views/PCVTF/SurveyPlan';
import FieldFormEnter from './views/PCVTF/FieldFormEnter';
import CreateFieldFormEnter from './views/PCVTF/CreateFieldFormEnter';
import FieldFormEnterPrior from './views/PCVAdmin/FieldFormEnterPrior';
import EnterFieldFormData from './views/PCVAdmin/EnterFieldFormData';
import SurveyStatusPage from './views/sections/SurveyStatusPages';
import ValidationPermitType from './views/ValidationData/PermitTypePaged/PermitType';
import PermitTypeForm from './views/ValidationData/PermitTypePaged/PermitTypeForm';
import PhenologyForm from './views/ValidationData/PhenologyPaged/PhenologyForm';
import ValidationPhenology from './views/ValidationData/PhenologyPaged/Phenology';
import ValidationPhenologyType from './views/ValidationData/PhenologyTypePaged/PhenologyType';
import PhenologyTypeForm from './views/ValidationData/PhenologyTypePaged/PhenologyTypeForm';
import ReportMethodForm from './views/ValidationData/ReportMethodPaged/ReportMethodForm';
import ValidationReportMethod from './views/ValidationData/ReportMethodPaged/ReportMethod';
import ValidationResearchTopic from './views/ValidationData/ResearchTopicPaged/ResearchTopic';
import ResearchTopicForm from './views/ValidationData/ResearchTopicPaged/ResearchTopicForm';
import SurveyState from './views/PCVAdmin/SurveyState';
import SurveyStatePermission from './views/PCVAdmin/SurveyStatePermission';
import SurveyOwnerPermission from './views/PCVAdmin/SurveyOwnerPermission';
import SeedCollState from './views/PCVAdmin/SeedCollState';
import SeedCollStatePermission from './views/PCVAdmin/SeedCollStatePermission';
import SeedCollOwnerPermission from './views/PCVAdmin/SeedCollOwnerPermission';
import FieldFormState from './views/PCVAdmin/FieldFormState';
import SurveyResultCurrentYear from './views/PCVAdmin/SurveyResultCurrentYear';
import SurveyResultYear from './views/PCVAdmin/SurveyResultYear';
import OwnerReport from './views/PCVAdmin/OwnerReport';
import SurveyAssignmentFieldData from './views/PCVTF/SurveyAssignmentFieldData';
import ValidationFieldDataType from './views/ValidationData/FieldDataTypePaged/FieldDataType';
import FieldDataTypeForm from './views/ValidationData/FieldDataTypePaged/FieldDataTypeForm';
import SRankForm from './views/ValidationData/SRankPaged/SRankForm';
import ValidationSRank from './views/ValidationData/SRankPaged/SRank';
import SourceOwnerForm from './views/ValidationData/SourceOwnerPaged/SourceOwnerForm';
import SourceForm from './views/ValidationData/SourcePaged/SourceForm';
import ValidationSourceOwner from './views/ValidationData/SourceOwnerPaged/SourceOwner';
import ValidationSource from './views/ValidationData/SourcePaged/Source';
import ValidationSlope from './views/ValidationData/SlopePaged/Slope';
import SlopeForm from './views/ValidationData/SlopePaged/SlopeForm';
import ValidationOutreachOrganization from './views/PCVAdmin/OutreachOrganizationPaged/OutreachOrganization';
import OutreachOrganizationForm from './views/PCVAdmin/OutreachOrganizationPaged/OutreachOrganizationForm';
import ValidationEOStatus from './views/ValidationData/EOStatusPaged/EOStatus';
import EOStatusForm from './views/ValidationData/EOStatusPaged/EOStatusForm';
import EndangermentCodeForm from './views/ValidationData/EndangermentCodePaged/EndangermentCodeForm';
import ValidationEndangermentCode from './views/ValidationData/EndangermentCodePaged/EndangermentCode';
import ValidationSurveyResult from './views/ValidationData/SurveyResultPaged/SurveyResult';
import SurveyResultForm from './views/ValidationData/SurveyResultPaged/SurveyResultForm';
import ValidationTopoPosition from './views/ValidationData/TopoPositionPaged/TopoPosition';
import TopoPositionForm from './views/ValidationData/TopoPositionPaged/TopoPositionForm';
import ContactMethodForm from './views/ValidationData/ContactMethodPaged/ContactMethodForm';
import ValidationContactMethod from './views/ValidationData/ContactMethodPaged/ContactMethod';
import ValidationCountPrecision from './views/ValidationData/CountPrecisionPaged/CountPrecision';
import CountPrecisionForm from './views/ValidationData/CountPrecisionPaged/CountPrecisionForm';
import ValidationDisturbance from './views/ValidationData/DisturbancePaged/Disturbance';
import DisturbanceForm from './views/ValidationData/DisturbancePaged/DisturbanceForm';
import ValidationDivision from './views/ValidationData/DivisionPaged/Division';
import DivisionForm from './views/ValidationData/DivisionPaged/DivisionForm';
import ValidationAge from './views/ValidationData/AgePaged/Age';
import AgeForm from './views/ValidationData/AgePaged/AgeForm';
import ValidationAspect from './views/ValidationData/AspectPaged/Aspect';
import AspectForm from './views/ValidationData/AspectPaged/AspectForm';
import ValidationLight from './views/ValidationData/LightPaged/Light';
import LightForm from './views/ValidationData/LightPaged/LightForm';
import ValidationLocationPrecision from './views/ValidationData/LocationPrecisionPaged/LocationPrecision';
import LocationPrecisionForm from './views/ValidationData/LocationPrecisionPaged/LocationPrecisionForm';
import ValidationMoistureRegime from './views/ValidationData/MoistureRegimePaged/MoistureRegime';
import MoistureRegimeForm from './views/ValidationData/MoistureRegimePaged/MoistureRegimeForm';
import ValidationOutreachOrganizationType from './views/ValidationData/OutreachOrganizationTypePaged/OutreachOrganizationType';
import OutreachOrganizationTypeForm from './views/ValidationData/OutreachOrganizationTypePaged/OutreachOrganizationTypeForm';
import ValidationOwnerPermission from './views/ValidationData/OwnerPermissionPaged/OwnerPermission';
import OwnerPermissionForm from './views/ValidationData/OwnerPermissionPaged/OwnerPermissionForm';
import SurveyPlanStatusPage from './views/PCVAdmin/SurveyPlanStatusPage';
import SurveyStateYear from './views/PCVAdmin/SurveyStateYear';
import SeedCollStateYear from './views/PCVAdmin/SeedCollStateYear';
import SurveyPlanFutureSpecies from './views/PCVAdmin/SurveyPlanFutureSpecies';
import SeedPlanFutureSpecies from './views/PCVAdmin/SeedPlanFutureSpecies';
import OwnerEO from './views/Owners/OwnerEOPaged/OwnerEO';
import OwnerEODetails from './views/Owners/OwnerEOPaged/OwnerEODetails';
import OwnerEOForm from './views/Owners/OwnerEOPaged/OwnerEOForm';
import EOsOutsideDistribution from './views/StatusOfPlants/EOsOutsideDistribution';
import DistributionWithoutEOs from './views/StatusOfPlants/DistributionWithoutEOs';
import EOMaximumExtent from './views/StatusOfPlants/EOMaximumExtent';
import PopulationSize from './views/StatusOfPlants/PopulationSize';
import EOCurrentExtent from './views/StatusOfPlants/EOCurrentExtent';
import SpeciesStatus from './views/StatusOfPlants/SpeciesStatus';
import DistributionYear from './views/StatusOfPlants/DistributionYear';
import SRankNumberEOs from './views/StatusOfPlants/SRankNumberEOs';
import OwnerReportWithProblem from './views/PCVAdmin/OwnerReportWithProblem';
import PCVTFHistorySPROUT from './views/PCVTF/PCVTFHistorySPROUT';
import PCVTFHistoryNewEOs from './views/PCVTF/PCVTFHistoryNewEOs';
import ChecklistSpecies from './views/Species/ChecklistSpecies';
import DistributionAllSources from './views/StatusOfPlants/DistributionAllSources/DistributionAllSources';
import ProgramSignup from './views/PCVTF/ProgramSignup';
import MgtNotesEdit from './views/StatusOfPlants/MgtNotesEditPaged/MgtNotesEdit';
import MgtNotesEditDetails from './views/StatusOfPlants/MgtNotesEditPaged/MgtNotesEditDetails';
import MgtNotesEditForm from './views/StatusOfPlants/MgtNotesEditPaged/MgtNotesEditForm';
import EmailManagement from './views/PCVAdmin/EmailManagement';
import PastPermits from './views/PCVAdmin/PastPermits';
import SpeciesInformation from './views/Species/SpeciesInformation';
import SurveyResultCurrentYearWithTabs from './views/PCVAdmin/SurveyResultCurrentYearWithTabs';
import PermissionByOwner from './views/PCVAdmin/PermissionByOwnerPages/PermissionByOwner';
import EnterOwnerPermission from './views/PCVAdmin/PermissionByOwnerPages/EnterOwnerPermission';
import FieldFormReviewNoEO from './views/PCVAdmin/FieldFormReviewNoEOPages/FieldFormReviewNoEO';
import FieldFormReviewNoEOForm from './views/PCVAdmin/FieldFormReviewNoEOPages/FieldFormReviewNoEOForm';
import PermissionByEO from './views/PCVAdmin/PermissionByEOPages/PermissionByEO';
import EnterOwnerPermissionPermissionByEO from './views/PCVAdmin/PermissionByEOPages/EnterOwnerPermission';
import FieldDataEdit from './views/ElementOccurrence/FieldDataEditPages/FieldDataEdit';
import FieldData from './views/ElementOccurrence/FieldDataEditPages/FieldData';
import FieldDataForm from './views/ElementOccurrence/FieldDataEditPages/FieldDataForm';
import ChangePassword from './views/auth/ChangePassword';
import Profile from './views/auth/Profile';
import SurveyorState from './views/Surveyors/SurveyorsStatePages/SurveyorState';
import AddOrDeleteState from './views/Surveyors/SurveyorsStatePages/AddOrDeleteState';
import AddStateForSurveyor from './views/Surveyors/SurveyorsStatePages/AddStateForSurveyor';
import FieldFormEditPrior from './views/PCVAdmin/FieldFormEditPriorPages/FieldFormEditPrior';
import FieldFormEditPriorForm from './views/PCVAdmin/FieldFormEditPriorPages/FieldFormEditPriorForm';
// eslint-disable-next-line import/no-cycle
import FieldFormNew from './views/PCVTF/FieldFormNew';
import FieldFormReview from './views/PCVAdmin/FieldFormReviewPages/FieldFormReview';
// eslint-disable-next-line import/no-cycle
import FieldFormReviewForm from './views/PCVAdmin/FieldFormReviewPages/FieldFormReviewForm';
import FieldFormEdit from './views/PCVTF/FieldFormEditPages/FieldFormEdit';
import FieldFormEditForm from './views/PCVTF/FieldFormEditPages/FieldFormEditForm';
// eslint-disable-next-line import/no-cycle
import SurveyorDisplayAndEdit from './views/Surveyors/SurveyorDisplayAndEditPages/SurveyorDisplayAndEdit';
import SurveyorDisplayAndEditForm from './views/Surveyors/SurveyorDisplayAndEditPages/SurveyorDisplayAndEditForm';
import OwnerDisplayAndEdit from './views/Owners/OwnerDisplayAndEditPages/OwnerDisplayAndEdit';
import OwnerDisplayAndEditForm from './views/Owners/OwnerDisplayAndEditPages/OwnerDisplayAndEditForm';
import OwnerDisplayForm from './views/Owners/OwnerDisplayAndEditPages/OwnerDisplayForm';
import Information from './views/PCVTF/Information';
import FieldDataManualEdit from './views/ElementOccurrence/FieldDataManualEditPages/FieldDataManualEdit';
import FieldDataManualEditForm from './views/ElementOccurrence/FieldDataManualEditPages/FieldDataManualEditForm';
import SpeciesInformationEdit from './views/Species/InformationEditPages/InformationEdit';
import SpeciesInformationEditDetails from './views/Species/InformationEditPages/InformationEditDetails';
import SpeciesInformationEditForm from './views/Species/InformationEditPages/InformationEditForm';
import Users from './views/PCVAdmin/UsersPaged/Users';
import UsersForm from './views/PCVAdmin/UsersPaged/UsersForm';
import PCVInformationManagement from './views/PCVAdmin/PCVInformationManagementPages/PCVInformationManagement';
import PCVManagementForm from './views/PCVAdmin/PCVInformationManagementPages/PCVManagementForm';
import CountStateManualForm from './views/ElementOccurrence/CountStateManualForm';
import TranscriptionManagement from './views/PCVAdmin/TranscriptionManagement';
// eslint-disable-next-line import/no-cycle
import ForgotPassword from './views/auth/ForgotPassword';
// eslint-disable-next-line import/no-cycle
import ResetPassword from './views/auth/ResetPassword';
// eslint-disable-next-line import/no-cycle
import PageNotAuthorized from './views/errorsPages/PageNotAuthorized';
import PCVTFMap from './views/PCVTF/Map';
import StatusOfPlantsMap from './views/StatusOfPlants/Map';
import PCVAdminMap from './views/PCVAdmin/Map';
import StatusSummaryPage from './views/StatusOfPlants/SummaryPage';
import ElementOccurrenceOutsideDistribution from './views/StatusOfPlants/ElementOccurrenceOutsideDistribution';
import CountiesWithoutEOs from './views/StatusOfPlants/CountiesWithoutEOs';
import ElementOccurrenceMaxExtent from './views/StatusOfPlants/ElementOccurrenceMaxExtent';
import ElementOccurrenceCurrentExtent from './views/StatusOfPlants/ElementOccurrenceCurrentExtent';
import ElementOccurrenceStateMgtNotes from './views/StatusOfPlants/ElementOccurrenceStateMgtNotes';
import ElementOccurrenceStateMgtNotesSPROUT from './views/StatusOfPlants/ElementOccurrenceStateMgtNotesSPROUT';
import MgtNotesTypeDivision from './views/StatusOfPlants/MgtNotesTypeDivision';
import MgtNotesTypeDivisionSPROUT from './views/StatusOfPlants/MgtNotesTypeDivisionSPROUT';
import PCVTFFieldFormNewEdit from './views/PCVTF/PCVTFFieldFormNewEditPages/PCVTFFieldFormNewEdit';
import PCVTFFieldFormNewEditForm from './views/PCVTF/PCVTFFieldFormNewEditPages/PCVTFFieldFormNewEditForms';
import ReportStatusState from './views/PCVAdmin/ReportStatusState';
import OwnerEOCurrentSurveyYear from './views/Owners/OwnerEOCurrentSurveyYear/OwnerEO';
import Details from './views/PCVAdmin/ControlPanel/Details';
import AssociatedSpeciesManual from './views/StatusOfPlants/AssociatedSpeciesManual';
import AssociatedSpeciesSPROUT from './views/StatusOfPlants/AssociatedSpeciesSPROUT';
import InvasiveSpeciesManual from './views/StatusOfPlants/InvasiveSpeciesManual';
import InvasiveSpeciesSPROUT from './views/StatusOfPlants/InvasiveSpeciesSPROUT';
import NaturalCommunities from './views/StatusOfPlants/NaturalCommunities-EOs';
import NaturalCommunitiesSpecies from './views/StatusOfPlants/NaturalCommunitiesSpecies';
import SurveyPlanEOsWithoutOwner from './views/Owners/SurveyPlanEOsWithoutOwner';

export const AppRouteSections = {
  HOME: 'Home',
  ELEMENT_OCCURRENCES: 'Element Occurrence',
  SPECIES: 'Species',
  OWNERS: 'Owners',
  SURVEYORS: 'Surveyors',
  VALIDATION_DATA: 'Validation Data',
  PCV_TF: 'PCV/TF',
  PCV_ADMINISTRATION: 'PCV Admin',
  SPROUT_ADMINISTRATION: 'SPROUT Admin',
  STATUS_OF_THE_PLANTS: 'Status of Plants',
  CONTACT: 'Contact',
};

const surveyor = '/Surveyor';
const elementOccurrence = '/ElementOccurrence';
const elementOccurrenceStatus = `${elementOccurrence}/ElementOccurrenceStatus`;
const fieldDataSPROUT = `${elementOccurrence}/FieldDataSPROUT`;
const validationData = '/ValidationData';
const pcvAdmin = '/PCVAdmin';
const surveyPlanQA = `${pcvAdmin}/SurveyPlanQA`;
const surveyStatus = `${pcvAdmin}/SurveyStatus`;
const permit = `${pcvAdmin}/Permit`;
const surveyorAssignment = `${pcvAdmin}/SurveyorAssignment`;
const species = '/Species';
const speciesStatus = '/SpeciesStatus';
const statusOfPlants = '/StatusOfPlants';
const statusSummary = '/StatusOfPlants/StatusSummary';
const pcvTf = '/PCV';
const owner = '/Owners';
const ownerStatus = `${owner}/OwnerStatus`;
const ownerEO = `${owner}/OwnerEO`;
const surveyResults = `${pcvTf}/TFSurveyResults`;
const pcvAdminSurveyPlanStatus = `${pcvAdmin}/SurveyPlanStatus`;
const accountSettings = '/accountSettings';
const forgotPassword = '/ForgotPassword';

export const BASE_ROUTE = '/';
export const NOT_AUTHORIZED_ROUTE = '/NotAuthorized';
export const AUTH_ROUTE = '/auth';
export const SECURITY_AGREEMENT = '/securityAgreement';
export const ADMIN_ROUTE = '/admin';

export const ACCOUNT_RESET_PASSWORD = '/Account/ResetPassword';

export const ACCOUNT_SETTINGS = `${accountSettings}`;
export const ACCOUNT_SETTINGS_CHANGE_PASSWORD = `${accountSettings}/changePassword`;
export const ACCOUNT_SETTINGS_PROFILE = `${accountSettings}/profile`;

export const ELEMENT_OCCURRENCE_ROUTE = `${elementOccurrence}`;
export const SEED_COLLECTION_EOS = `${elementOccurrence}/SeedCollectionEOs`;
export const FIELD_DATA_MANUAL_EDIT = `${elementOccurrence}/FieldDataManualEdit`;
export const INACTIVE_EOS_WITH_OBSERVATION = `${elementOccurrence}/InactiveEOsWithObservations`;
export const ACTIVE_EOS_WITH_CONFLICTING_FIELD_DATA = `${elementOccurrence}/ActiveEOsWithConflictingFieldData`;
export const EOS_WITH_DATA_ERRORS = `${elementOccurrence}/EOsWithDataErrors`;
export const EOS_WITHOUT_EO_NUMBERS = `${elementOccurrence}/EOsWithoutEONumbers`;
export const FIELD_DATA_SEED_BY_SPECIES = `${elementOccurrence}/FieldDataSeedBySpecies`;
export const FIELD_DATA_CONSERVATION_SPECIES_NO_SEED = `${elementOccurrence}/FieldDataConservationSpeciesNoSeed`;
export const FIELD_DATA_MANUAL_ENTER = `${elementOccurrence}/FieldDataManualEnter`;
export const MULTI_SPECIES_SITES = `${elementOccurrence}/MultispeciesSites`;
export const HERBARIUM_RECOVERY = `${elementOccurrence}/HerbariumRecovery`;
export const FIELD_DATA_MANUAL = `${elementOccurrence}/FieldDataManual`;
export const MANUAL_FIELD_FORM = `${elementOccurrence}/FieldDataManual/ManualFieldForm`;
export const MANUAL_FIELD_FORM_DETAIL = `${elementOccurrence}/FieldDataManual/ManualFieldForm/ManualFieldFormDetail`;
export const FIELD_DATA_SEED = `${elementOccurrence}/FieldDataSeed`;
export const FIELD_DATA_SUMMARY_WITH_FILES = `${elementOccurrence}/FieldDataSummaryWithFiles`;
export const EL_OCC_OWNER = `${elementOccurrence}/ElementOccurrenceOwner`;
export const EL_OCC_OWNER_BY_EOKEY = `${elementOccurrence}/ElementOccurrenceOwner/:eokey`;
export const CREATE_EL_OCC_OWNER = `${elementOccurrence}/ElementOccurrenceOwner/Create`;
export const EDIT_EL_OCC_OWNER = `${elementOccurrence}/ElementOccurrenceOwner/:eokey/:ownerKey`;
export const EL_OCC_DISPLAY_AND_EDIT = `${elementOccurrence}/ElementOccurrenceDisplayAndEdit`;
export const EL_OCC_DISPLAY = `${elementOccurrence}/ElementOccurrenceDisplay`;
export const EL_OCC_DISPLAY_AND_EDIT_FORM = `${elementOccurrence}/ElementOccurrenceDisplayAndEdit/:eokey`;
export const EL_OCC_DISPLAY_FORM = `${elementOccurrence}/ElementOccurrenceDisplay/:eokey`;
export const FIELD_DATA_EDIT = `${elementOccurrence}/FieldDataEdit`;

export const ELEMENT_OCCURRENCE_STATUS = `${elementOccurrenceStatus}`;
export const EL_OCC_STATE_SOURCE = `${elementOccurrenceStatus}/ElementOccurrenceStateSource`;
export const EL_OCC_STATE_DIV = `${elementOccurrenceStatus}/ElementOccurrenceStateDivision`;
export const EL_OCC_STATE_STATUS = `${elementOccurrenceStatus}/ElementOccurrenceStateStatus`;
export const EL_OCC_DIV_STATUS = `${elementOccurrenceStatus}/ElementOccurrenceDivisionStatus`;
export const EL_OCC_DIV_STATE_STATUS = `${elementOccurrenceStatus}/ElementOccurrenceDivisionStateStatus`;
export const EL_OCC_STATE_OWNER = `${elementOccurrenceStatus}/ElementOccurrenceStateOwner`;
export const EL_OCC_STATE_DATA_MISMATCH = `${elementOccurrenceStatus}/ElementOccurrenceStateDataMismatch`;
export const SEED_BANK_DIV = `${elementOccurrenceStatus}/SeedBankDivision`;
export const SEED_BANK_DIV_STATE = `${elementOccurrenceStatus}/SeedBankDivisionState`;
export const COUNT_STATE_MANUAL_FORM = `${elementOccurrenceStatus}/CountStateManualForm`;

export const FIELD_DATA_SPROUT = `${fieldDataSPROUT}`;
export const SPROUT_FIELD_FORM = `${fieldDataSPROUT}/SPROUTFieldForm`;
export const SPROUT_FIELD_FORM_DETAIL = `${fieldDataSPROUT}/SPROUTFieldForm/SPROUTFieldFormDetail`;

export const SURVEYOR_ROUTE = `${surveyor}`;
export const SURVEYOR_BY_DISTANCE = `${surveyor}/SurveyorByDistance`;
export const SURVEYOR_STATE_STATUS = `${surveyor}/SurveyorStatus/SurveyorStateStatus`;
export const SURVEYOR_TYPE_STATE_STATUS = `${surveyor}/SurveyorStatus/SurveyorTypeStateStatus`;
export const SURVEYORS_NO_STATE = `${surveyor}/SurveyorsNoState`;
export const SURVEYOR_STATUS_PAGE = `${surveyor}/SurveyorStatus`;
export const SURVEYOR_STATE = `${surveyor}/SurveyorState`;
export const SURVEYOR_DISPLAY_AND_EDIT = `${surveyor}/SurveyorDisplayAndEdit`;
export const SURVEYOR_DISPLAY = `${surveyor}/SurveyorDisplay`;

export const VALIDATION_ROUTE = `${validationData}`;
export const COUNTY = `${validationData}/County`;
export const STATE = `${validationData}/State`;
export const SURVEYOR_TYPE = `${validationData}/SurveyorType`;
export const VALIDATION_OWNER_STATUS = `${validationData}/OwnerStatus`;
export const OWNER_STATUS_FORM = `${validationData}/OwnerStatus/:key`;
export const VALIDATION_OWNER_TYPE = `${validationData}/OwnerType`;
export const OWNER_TYPE_FORM = `${validationData}/OwnerType/:key`;
export const VALIDATION_PERMIT_TYPE = `${validationData}/PermitType`;
export const PERMIT_TYPE_FORM = `${validationData}/PermitType/:key`;
export const VALIDATION_PHENOLOGY = `${validationData}/Phenology`;
export const PHENOLOGY_FORM = `${validationData}/Phenology/:key`;
export const VALIDATION_PHENOLOGY_TYPE = `${validationData}/PhenologyType`;
export const PHENOLOGY_TYPE_FORM = `${validationData}/PhenologyType/:key`;
export const VALIDATION_REPORT_METHOD = `${validationData}/ReportMethod`;
export const REPORT_METHOD_FORM = `${validationData}/ReportMethod/:key`;
export const VALIDATION_RESEARCH_TOPIC = `${validationData}/ResearchTopic`;
export const RESEARCH_TOPIC_FORM = `${validationData}/ResearchTopic/:key`;
export const VALIDATION_EO_STATUS = `${validationData}/EOStatus`;
export const EO_STATUS_FORM = `${validationData}/EOStatus/:key`;
export const VALIDATION_ENDANGERMENT_CODE = `${validationData}/EndangermentCode`;
export const ENDANGERMENT_CODE_FORM = `${validationData}/EndangermentCode/:key`;
export const VALIDATION_SURVEY_RESULT = `${validationData}/SurveyResult`;
export const SURVEY_RESULT_FORM = `${validationData}/SurveyResult/:key`;
export const VALIDATION_TOPO_POSITION = `${validationData}/TopoPosition`;
export const TOPO_POSITION_FORM = `${validationData}/TopoPosition/:key`;
export const VALIDATION_CONTACT_METHOD = `${validationData}/ContactMethod`;
export const CONTACT_METHOD_FORM = `${validationData}/ContactMethod/:key`;
export const VALIDATION_COUNT_PRECISION = `${validationData}/CountPrecision`;
export const COUNT_PRECISION_FORM = `${validationData}/CountPrecision/:key`;
export const VALIDATION_DISTURBANCES = `${validationData}/Disturbance`;
export const DISTURBANCES_FORM = `${validationData}/Disturbance/:key`;
export const VALIDATION_DIVISION = `${validationData}/Division`;
export const DIVISION_FORM = `${validationData}/Division/:key`;
export const VALIDATION_AGE = `${validationData}/Age`;
export const AGE_FORM = `${validationData}/Age/:key`;
export const VALIDATION_ASPECT = `${validationData}/Aspect`;
export const ASPECT_FORM = `${validationData}/Aspect/:key`;
export const VALIDATION_LIGHT = `${validationData}/Light`;
export const LIGHT_FORM = `${validationData}/Light/:key`;
export const VALIDATION_LOCATION_PRECISION = `${validationData}/LocationPrecision`;
export const LOCATION_PRECISION_FORM = `${validationData}/LocationPrecision/:key`;
export const VALIDATION_MOISTURE_REGIME = `${validationData}/MoistureRegime`;
export const MOISTURE_REGIME_FORM = `${validationData}/MoistureRegime/:key`;
export const VALIDATION_REACH_ORGANIZATION = `${validationData}/OutreachOrganizationType`;
export const REACH_ORGANIZATION_FORM = `${validationData}/OutreachOrganizationType/:key`;
export const VALIDATION_OWNER_PERMISSION = `${validationData}/OwnerPermission`;
export const OWNER_PERMISSION_FORM = `${validationData}/OwnerPermission/:key`;
export const VALIDATION_SLOPE = `${validationData}/Slope`;
export const SLOPE_FORM = `${validationData}/Slope/:key`;
export const VALIDATION_SOURCE = `${validationData}/Source`;
export const SOURCE_FORM = `${validationData}/Source/:key`;
export const VALIDATION_SOURCE_OWNER = `${validationData}/SourceOwner`;
export const SOURCE_OWNER_FORM = `${validationData}/SourceOwner/:key`;
export const VALIDATION_SRANK = `${validationData}/SRank`;
export const SRANK_FORM = `${validationData}/SRank/:key`;
export const VALIDATION_ATTACHMENT_TYPE = `${validationData}/AttachmentType`;
export const ATTACHMENT_TYPE_FORM = `${validationData}/AttachmentType/:key`;
export const VALIDATION_FIELD_DATA_TYPE = `${validationData}/FieldDataType`;
export const FIELD_DATA_TYPE_FORM = `${validationData}/FieldDataType/:key`;

export const PCV_ADMIN_ROUTE = `${pcvAdmin}`;
export const PCV_ADMIN_USERS = `${pcvAdmin}/Users`;
export const PCV_INFORMATION = `${pcvAdmin}/PCVInformationManagement`;
export const PCV_ADMIN_SURVEY_PLAN_STATUS = `${pcvAdminSurveyPlanStatus}`;
export const PCV_ADMIN_SURVEY_STATE_YEAR = `${pcvAdminSurveyPlanStatus}/SurveyStateYear`;
export const PCV_ADMIN_SEED_COLL_STATE_YEAR = `${pcvAdminSurveyPlanStatus}/SeedCollStateYear`;
export const PCV_ADMIN_SURVEY_PLAN_FUTURE_SPECIES = `${pcvAdminSurveyPlanStatus}/SurveyPlanFutureSpecies`;
export const PCV_ADMIN_SEED_PLAN_FUTURE_SPECIES = `${pcvAdminSurveyPlanStatus}/SeedPlanFutureSpecies`;
export const VALIDATION_PCV_ADMIN_OUTREACH_ORGANIZATION = `${pcvAdmin}/OutreachOrganization`;
export const PCV_ADMIN_OUTREACH_ORGANIZATION_FORM = `${pcvAdmin}/OutreachOrganization/:key`;
export const SURVEY_STATUS_DETAIL = `${pcvAdmin}/SurveyStatusDetail`;
export const FIELD_FORM_ENTER_PRIOR = `${pcvAdmin}/FieldFormEnterPrior`;
export const SURVEYOR_HISTORY = `${pcvAdmin}/SurveyorHistory`;
export const SURVEY_PLAN = `${pcvAdmin}/SurveyPlan`;
export const SURVEY_SIGNUP = `${pcvAdmin}/SurveySignup`;
export const PCV_ADMIN_SURVEY_RESULT_PRIOR_YEAR = `${pcvAdmin}/SurveyResultPriorYear`;
export const SIGNUP_DATE = `${pcvAdmin}/SignupDate`;
export const ANNOUNCEMENTS = `${pcvAdmin}/Announcements`;
export const ANNOUNCEMENTS_FORM = `${pcvAdmin}/Announcements/AddAnnouncement`;
export const PCV_PROGRAMS = `${pcvAdmin}/PCVPrograms`;
export const PCV_PROGRAMS_FORM = `${pcvAdmin}/PCVPrograms/:id`;
export const OWNER_REPORT = `${pcvAdmin}/OwnerReport`;
export const OWNER_REPORT_WITH_PROBLEM = `${pcvAdmin}/OwnerReportWithProblem`;
export const EMAIL_MANAGEMENT = `${pcvAdmin}/EmailManagement`;
export const PAST_PERMIT = `${pcvAdmin}/PastPermits`;
export const SURVEY_RESULT_CURRENT_YEAR_WITH_TABS = `${pcvAdmin}/SurveyResultCurrentYear`;
export const PERMISSIONS_BY_OWNER = `${pcvAdmin}/PermissionByOwner`;
export const FIELD_FORM_REVIEW_NO_EO = `${pcvAdmin}/FieldFormReviewNoEO`;
export const PERMISSIONS_BY_EO = `${pcvAdmin}/PermissionByEO`;
export const FIELD_FORM_EDIT_PRIOR = `${pcvAdmin}/FieldFormEditPrior`;
export const FIELD_FORM_REVIEW = `${pcvAdmin}/FieldFormReview`;
export const TRANSCRIPTION_MANAGEMENT = `${pcvAdmin}/TranscriptionManagement`;
export const PCV_ADMIN_MAP = `${pcvAdmin}/PCVAdminMap`;
export const PCV_CONTROL_PANEL = `${pcvAdmin}/ControlPanel`;

export const SURVEY_STATUS = `${surveyStatus}`;
export const SURVEY_STATE_PERMISSION = `${surveyStatus}/SurveyStatePermission`;
export const SURVEY_OWNER_PERMISSION = `${surveyStatus}/SurveyOwnerPermission`;
export const SURVEY_STATE = `${surveyStatus}/SurveyState`;
export const REPORT_STATUS_STATE = `${surveyStatus}/ReportStatusState`;
export const SEED_COLL_STATE = `${surveyStatus}/SeedCollState`;
export const SEED_COLL_STATE_PERMISSION = `${surveyStatus}/SeedCollStatePermission`;
export const SEED_COLL_OWNER_PERMISSION = `${surveyStatus}/SeedCollOwnerPermission`;
export const FIELD_FORM_STATE = `${surveyStatus}/FieldFormState`;
export const SURVEY_RESULT_CURRENT_YEAR = `${surveyStatus}/SurveyResultCurrentYear`;
export const SURVEY_RESULT_YEAR = `${surveyStatus}/SurveyResultYear`;

export const PERMIT = `${permit}`;
export const ENTER_PERMISSION = `${permit}/EnterPermission`;

export const SURVEY_PLAN_QA = `${surveyPlanQA}`;
export const PLAN_QA_SURVEY_15_YEAR = `${surveyPlanQA}/PlanQASurvey15Year`;
export const PLAN_QA_SURVEY_INACTIVE = `${surveyPlanQA}/PlanQASurveyInactive`;
export const PLAN_QA_SURVEY_NO_STATUS = `${surveyPlanQA}/PlanQASurveyNoStatus`;
export const PLAN_QA_SEED_COLL_YES = `${surveyPlanQA}/PlanQASeedCollYes`;
export const PLAN_QA_SEED_COLL_NO = `${surveyPlanQA}/PlanQASeedCollNo`;
export const PLAN_QA_SURVEY_10_YEAR = `${surveyPlanQA}/PlanQASurvey10Year`;
export const PLAN_QA_SURVEY_IF_SEED = `${surveyPlanQA}/PlanQASurveyIfSeed`;
export const PLAN_QA_SURVEY_SEED_YEAR_SYNC = `${surveyPlanQA}/PlanQASurveySeedYearSync`;
export const PLAN_QA_SEED_YEAR_INTERVAL = `${surveyPlanQA}/PlanQASeedYearInterval`;
export const PLAN_QA_SURVEY_YEAR_INTERVAL = `${surveyPlanQA}/PlanQASurveyYearInterval`;

export const SURVEYOR_ASSIGNMENT = `${surveyorAssignment}`;
export const SURVEYOR_ASSIGNMENT_DETAIL = `${surveyorAssignment}/:key`;

export const SPECIES_DATA_ROUTE = `${species}`;
export const SPECIES_CHECKLIST = `${species}/ChecklistSpecies`;
export const SPECIES_INFORMATION = `${species}/SpeciesInformation`;
export const RESEARCH_QUESTIONS_BY_SPECIES = `${species}/ResearchQuestionsBySpecies`;
export const RESEARCH_QUESTIONS_BY_TOPIC = `${species}/ResearchQuestionsByTopic`;
export const GO_BOTANY_SPECIES_INFO = `${species}/GoBotanySpeciesInformation`;
export const SPECIES_WITHOUT_STATE_CONSERVATION_STATUS = `${species}/SpeciesWithoutStateConservationStatus`;
export const CHECKLIST_SYNONYM = `${species}/ChecklistSynonym`;
export const SPECIES_CONSERVATION_STATUS = `${species}/SpeciesConservationStatus`;
export const SPECIES_INVASIVE_STATUS = `${species}/SpeciesInvasiveStatus`;
export const SPECIES_WITH_NO_EOS = `${species}/SpeciesWithNoEOs`;
export const GO_BOTANY_DISTRIBUTION = `${species}/GoBotanyDistribution`;
export const GO_BOTANY_SPECIES_CHARACTER = `${species}/GoBotanySpeciesCharacter`;
export const SPECIES_INFORMATION_EDIT = `${species}/SpeciesInformationEdit`;

export const SPECIES_DIVISION = `${species}${speciesStatus}/SpeciesDivision`;
export const SPECIES_PHENOLOGY = `${species}${speciesStatus}/SpeciesPhenology`;
export const SPECIES_WITHOUT_EOS = `${species}${speciesStatus}/SpeciesWithoutEOs`;
export const SPECIES_WITHOUT_STATE_STATUS = `${species}${speciesStatus}/SpeciesWithoutStateStatus`;
export const SPECIES_WITH_EOS = `${species}${speciesStatus}/SpeciesWithEOs`;
export const SPECIES_STATE_STATUS = `${species}${speciesStatus}/SpeciesStateStatus`;
export const SPECIES_STATE_STATUS_PAGE = `${species}${speciesStatus}`;

export const STATUS_ROUTE = `${statusOfPlants}`;
export const MANAGEMENT_NOTES = `${statusOfPlants}/ManagementNotes`;
export const CHECKLIST_DISTRIBUTION = `${statusOfPlants}/ChecklistDistribution`;
export const MANAGEMENT_NOTES_SPROUT = `${statusOfPlants}/ManagementNotesSPROUT`;
export const EOS_OUTSIDE_DISTRIBUTION = `${statusOfPlants}/EOsOutsideDistribution`;
export const DISTRIBUTION_WITHOUT_EOS = `${statusOfPlants}/DistributionWithoutEOs`;
export const EO_MAXIMUM_EXTENT = `${statusOfPlants}/EOMaximumExtent`;
export const POPULATION_SIZE = `${statusOfPlants}/PopulationSize`;
export const EO_CURRENT_EXTENT = `${statusOfPlants}/EOCurrentExtent`;
export const SPECIES_STATUS = `${statusOfPlants}/SpeciesStatus`;
export const DISTRIBUTION_YEAR = `${statusOfPlants}/DistributionYear`;
export const SRANK_NUMBER_EOS = `${statusOfPlants}/SRankNumberEOs`;
export const MGT_NOTES_EDIT = `${statusOfPlants}/MgtNotesEdit`;
export const MGT_NOTES_EDIT_DETAILS = `${statusOfPlants}/MgtNotesEdit/:key`;
export const MGT_NOTES_EDIT_FORM = `${statusOfPlants}/MgtNotesEdit/:key/:ffkey`;
export const DISTRIBUTION_ALL_SOURCES = `${statusOfPlants}/DistributionAllSources`;
export const STATUS_OF_PLANTS_MAP = `${statusOfPlants}/DistributionMap`;
export const STATUS_OF_PLANTS_ASSOCIATED_SPECIES_MANUAL = `${statusOfPlants}/Associatedspecies-manual`;
export const STATUS_OF_PLANTS_ASSOCIATED_SPECIES_SPROUT = `${statusOfPlants}/Associatedspecies-SPROUT`;
export const STATUS_OF_PLANTS_INVASIVE_SPECIES_MANUAL = `${statusOfPlants}/Invasivespecies-manual`;
export const STATUS_OF_PLANTS_INVASIVE_SPECIES_SPROUT = `${statusOfPlants}/Invasivespecies-SPROUT`;
export const STATUS_OF_PLANTS_NATURAL_COMMUNITIES_EOS = `${statusOfPlants}/Naturalcommunities-EOs`;
export const STATUS_OF_PLANTS_NATURAL_COMMUNITIES_SPECIES = `${statusOfPlants}/Naturalcommunities-species`;

export const STATUS_SUMMARY = `${statusSummary}`;
export const EO_OUTSIDE_DISTRIBUTION = `${statusSummary}/ElementOccurrenceOutsideDistribution`;
export const COUNTIES_WITHOUT_EOS = `${statusSummary}/CountiesWithoutEOs`;
export const EO_MAX_EXTENT_STATUS_OF_PLANTS = `${statusSummary}/ElementOccurrenceMaxExtent`;
export const EO_CURRENT_EXTENT_STATUS_OF_PLANTS = `${statusSummary}/ElementOccurrenceCurrentExtent`;
export const EO_STATE_MGT_NOTES = `${statusSummary}/ElementOccurrenceStateMgtNotes`;
export const EO_STATE_MGT_NOTES_SPROUT = `${statusSummary}/ElementOccurrenceStateMgtNotesSPROUT`;
export const MGT_NOTES_TYPE_DIVISION = `${statusSummary}/MgtNotesTypeDivision`;
export const MGT_NOTES_TYPE_DIVISION_SPROUT = `${statusSummary}/MgtNotesTypeDivisionSPROUT`;

export const OWNER_ROUTE = `${owner}`;
export const OWNER_HISTORY = `${owner}/OwnerHistory`;
export const OWNER_DISPLAY_AND_EDIT = `${owner}/OwnerDisplayAndEdit`;
export const OWNER_DISPLAY = `${owner}/OwnerDisplay`;
export const OWNER_EO_CURRENT_YEAR = `${owner}/OwnerEOCurrentSurveyYear`;
export const SURVEY_PLAN_EOS_WITHOUT_OWNER = `${owner}/SurveyPlanEOsWithoutOwner`;

export const OWNER_STATUS = `${ownerStatus}`;
export const OWNER_BY_STATUS = `${ownerStatus}/OwnerStatus`;
export const OWNER_TYPE = `${ownerStatus}/OwnerType`;
export const OWNER_TYPE_STATE = `${ownerStatus}/OwnerTypeState`;

export const OWNER_EO = `${ownerEO}`;
export const OWNER_EO_DETAILS = `${ownerEO}/:key`;
export const OWNER_EO_FORM = `${ownerEO}/:key/:eokey`;

export const PCV_ROUTE = `${pcvTf}`;
export const PCV_TF_INFORMATION = `${pcvTf}/PCV&TFInformation`;
export const PCV_TF_PROGRAM_SIGNUP = `${pcvTf}/PCV&TFProgramSignup`;
export const PCV_TF_FIELD_FORM_NEW = `${pcvTf}/PCV&TFFieldFormNew`;
export const PCV_TF_STATUS = `${pcvTf}/PCV&TFStatus`;
export const PCV_TF_HISTORY_SPROUT = `${pcvTf}/PCV&TFHistorySPROUT`;
export const PCV_TF_HISTORY_NEW_EOS = `${pcvTf}/PCV&TFHistoryforNewEOs`;
export const PCV_TF_CONTACT_LIST = `${pcvTf}/PCV&TFContactList`;
export const PCV_TF_SURVEY_SIGNUP = `${pcvTf}/PCV&TFSurveySignup`;
export const PCV_TF_SURVEY_PLAN = `${pcvTf}/TFSurveyPlan`;
export const PCV_TF_FIELD_FORM_ENTER = `${pcvTf}/PCV&TFFieldFormEnter`;
export const PCV_TF_SIGNUP_STATUS = `${pcvTf}/PCV&TFSurveySignUpStatus`;
export const PCV_TF_ASSIGNMENT_SUMMARY = `${pcvTf}/PCV&TFSurveyAssignmentSummary`;
export const PCV_TF_ASSIGNMENT_FIELD_DATA = `${pcvTf}/PCV&TFSurveyAssignmentFieldData`;
export const PCV_TF_ASSIGNMENT_SUMMARY_BY_EOKEY = `${pcvTf}/PCV&TFSurveyAssignmentSummary/:eokey`;
export const FIELD_FORM_EDIT = `${pcvTf}/PCV&TFFieldFormEdit`;
export const PCV_TF_MAP = `${pcvTf}/PCV&TFMap`;
export const PCV_TF_FIELD_FORM_NEW_EDIT = `${pcvTf}/PCV&TFFieldFormNewEdit`;

export const SURVEY_RESULTS = `${surveyResults}`;
export const SURVEY_FIELD_FORM = `${surveyResults}/SproutFieldForm`;
export const SURVEY_FIELD_FORM_DETAIL = `${surveyResults}/SproutFieldForm/SproutFieldFormDetail`;

export const AppRoutes = [
  {
    path: NOT_AUTHORIZED_ROUTE,
    component: PageNotAuthorized,
    exact: true,
    name: 'Page Not Authorized',
  },
  {
    path: AUTH_ROUTE,
    component: LogIn,
    exact: true,
    name: 'Auth View',
  },
  {
    path: forgotPassword,
    component: ForgotPassword,
    exact: true,
    name: 'Forgot Password',
  },
  {
    path: ACCOUNT_RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
    name: 'Reset Password',
  },
  {
    path: SECURITY_AGREEMENT,
    component: SecurityAgreement,
    exact: true,
    name: 'Security Agreement View',
    requiresAuth: true,
  },
  {
    path: ACCOUNT_SETTINGS,
    component: AccountSettings,
    exact: true,
    name: 'Account Settings View',
    requiresAuth: true,
  },
  {
    path: ACCOUNT_SETTINGS_CHANGE_PASSWORD,
    component: ChangePassword,
    exact: true,
    name: 'Change Password',
    requiresAuth: true,
  },
  {
    path: ACCOUNT_SETTINGS_PROFILE,
    component: Profile,
    exact: true,
    name: 'Profile',
    requiresAuth: true,
  },
  {
    path: `${BASE_ROUTE}`,
    component: Home,
    exact: true,
    section: AppRouteSections.HOME,
    name: 'Home View',
    requiresAuth: true,
  },
  {
    path: ELEMENT_OCCURRENCE_STATUS,
    component: ElementOccurrenceStatusPage,
    exact: true,
    name: 'Element Occurrences Status',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_STATUS_PAGE,
    component: SurveyorStatusPage,
    exact: true,
    name: 'Surveyor Status Page',
    requiresAuth: true,
  },
  {
    path: ELEMENT_OCCURRENCE_ROUTE,
    component: ElementOccurrencePage,
    section: AppRouteSections.ELEMENT_OCCURRENCES,
    exact: true,
    name: 'Element Occurrences View',
    requiresAuth: true,
  },
  {
    path: SPECIES_DATA_ROUTE,
    component: SpeciesPage,
    section: AppRouteSections.SPECIES,
    exact: true,
    name: 'Species View',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_SURVEY_PLAN_STATUS,
    component: SurveyPlanStatusPage,
    exact: true,
    name: 'Survey Plan Status',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_USERS,
    component: Users,
    exact: true,
    name: 'Users',
    requiresAuth: true,
  },
  {
    path: `${PCV_ADMIN_USERS}/:key`,
    component: UsersForm,
    exact: true,
    name: 'Users Form',
  },
  {
    path: PCV_INFORMATION,
    component: PCVInformationManagement,
    exact: true,
    name: 'PCV Information Management',
  },
  {
    path: `${PCV_INFORMATION}/:key`,
    component: PCVManagementForm,
    exact: true,
    name: 'PCV Information Management Form',
  },
  {
    path: RESEARCH_QUESTIONS_BY_SPECIES,
    component: ResearchQuestionsBySpecies,
    exact: true,
    name: 'Research Questions By Species',
    requiresAuth: true,
  },
  {
    path: SPECIES_CHECKLIST,
    component: ChecklistSpecies,
    exact: true,
    name: 'Checklist Species',
    requiresAuth: true,
  },
  {
    path: SPECIES_INFORMATION,
    component: SpeciesInformation,
    exact: true,
    name: 'Species Information',
    requiresAuth: true,
  },
  {
    path: RESEARCH_QUESTIONS_BY_TOPIC,
    component: ResearchQuestionsByTopic,
    exact: true,
    name: 'Research Questions By Topic',
    requiresAuth: true,
  },
  {
    path: GO_BOTANY_SPECIES_INFO,
    component: GoBotanySpeciesInformation,
    exact: true,
    name: 'GoBotany Species Information',
    requiresAuth: true,
  },
  {
    path: OWNER_ROUTE,
    component: OwnersPage,
    section: AppRouteSections.OWNERS,
    exact: true,
    name: 'Owners View',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_ROUTE,
    component: SurveyorPage,
    section: AppRouteSections.SURVEYORS,
    exact: true,
    name: 'Surveyor View',
    requiresAuth: true,
  },
  {
    path: VALIDATION_ROUTE,
    component: ValidationPage,
    section: AppRouteSections.VALIDATION_DATA,
    exact: true,
    name: 'Validation View',
    requiresAuth: true,
  },
  {
    path: COUNTY,
    component: CountyPage,
    exact: true,
    name: 'County',
    requiresAuth: true,
  },
  {
    path: STATE,
    component: StatePage,
    exact: true,
    name: 'State',
    requiresAuth: true,
  },
  {
    path: PCV_ROUTE,
    component: PCVPage,
    section: AppRouteSections.PCV_TF,
    exact: true,
    name: 'PCV and TF View',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_SURVEY_STATE_YEAR,
    component: SurveyStateYear,
    exact: true,
    name: 'Survey State Year',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_SEED_COLL_STATE_YEAR,
    component: SeedCollStateYear,
    exact: true,
    name: 'Survey State Year',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_SURVEY_PLAN_FUTURE_SPECIES,
    component: SurveyPlanFutureSpecies,
    exact: true,
    name: 'Survey Plan Future Species',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_SEED_PLAN_FUTURE_SPECIES,
    component: SeedPlanFutureSpecies,
    exact: true,
    name: 'Seed Plan Future Species',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_ROUTE,
    component: PCVAdminPage,
    section: AppRouteSections.PCV_ADMINISTRATION,
    exact: true,
    name: 'PCV Admin View',
    requiresAuth: true,
  },
  {
    path: STATUS_ROUTE,
    component: PlantsStatusPage,
    section: AppRouteSections.STATUS_OF_THE_PLANTS,
    exact: true,
    name: 'Plants Status View',
    requiresAuth: true,
  },
  {
    path: MANAGEMENT_NOTES,
    component: ManagementNotes,
    exact: true,
    name: 'Management Notes',
    requiresAuth: true,
  },
  {
    path: CHECKLIST_DISTRIBUTION,
    component: ChecklistDistribution,
    exact: true,
    name: 'Checklist Distribution',
    requiresAuth: true,
  },
  {
    path: MANAGEMENT_NOTES_SPROUT,
    component: ManagementNotesSPROUT,
    exact: true,
    name: 'Management Notes SPROUT',
    requiresAuth: true,
  },
  {
    path: DISTRIBUTION_ALL_SOURCES,
    component: DistributionAllSources,
    exact: true,
    name: 'Distribution All Sources',
    requiresAuth: true,
  },
  {
    path: EOS_OUTSIDE_DISTRIBUTION,
    component: EOsOutsideDistribution,
    exact: true,
    name: 'EOs Outside Distribution',
    requiresAuth: true,
  },
  {
    path: DISTRIBUTION_WITHOUT_EOS,
    component: DistributionWithoutEOs,
    exact: true,
    name: 'Distribution Without EOs',
    requiresAuth: true,
  },
  {
    path: EO_MAXIMUM_EXTENT,
    component: EOMaximumExtent,
    exact: true,
    name: 'EO Maximum Extent',
    requiresAuth: true,
  },
  {
    path: POPULATION_SIZE,
    component: PopulationSize,
    exact: true,
    name: 'Population Size',
    requiresAuth: true,
  },
  {
    path: EO_CURRENT_EXTENT,
    component: EOCurrentExtent,
    exact: true,
    name: 'EO CurrentExtent',
    requiresAuth: true,
  },
  {
    path: SPECIES_STATUS,
    component: SpeciesStatus,
    exact: true,
    name: 'Species Status',
    requiresAuth: true,
  },
  {
    path: DISTRIBUTION_YEAR,
    component: DistributionYear,
    exact: true,
    name: 'Distribution Year',
    requiresAuth: true,
  },
  {
    path: SRANK_NUMBER_EOS,
    component: SRankNumberEOs,
    exact: true,
    name: 'SRank Number EOs',
    requiresAuth: true,
  },
  {
    path: MGT_NOTES_EDIT,
    component: MgtNotesEdit,
    exact: true,
    name: 'Management Notes Edit',
    requiresAuth: true,
  },
  {
    path: MGT_NOTES_EDIT_DETAILS,
    component: MgtNotesEditDetails,
    exact: true,
    name: 'Management Notes Edit Details',
  },
  {
    path: MGT_NOTES_EDIT_FORM,
    component: MgtNotesEditForm,
    exact: true,
    name: 'Add New Note to Element Occurrence',
  },
  {
    path: SURVEY_PLAN,
    component: SurveyPlan,
    exact: true,
    name: 'Survey Plan',
    requiresAuth: true,
  },
  {
    path: SURVEY_SIGNUP,
    component: SurveySignup,
    exact: true,
    name: 'Survey Signup',
    requiresAuth: true,
  },
  {
    path: SURVEY_STATUS_DETAIL,
    component: SurveyStatusDetail,
    exact: true,
    name: 'Survey Status Detail',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_HISTORY,
    component: SurveyorHistory,
    exact: true,
    name: 'Surveyor History',
    requiresAuth: true,
  },
  {
    path: SURVEY_PLAN_QA,
    component: SurveyPlanQA,
    exact: true,
    name: 'Survey Plan QA',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SURVEY_15_YEAR,
    component: PlanQASurvey15Year,
    exact: true,
    name: 'Plan QA Survey 15 Year',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SURVEY_10_YEAR,
    component: PlanQASurvey10Year,
    exact: true,
    name: 'Plan QA Survey 10 Year',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SURVEY_INACTIVE,
    component: PlanQASurveyInactive,
    exact: true,
    name: 'Plan QA Survey Inactive',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SEED_COLL_NO,
    component: PlanQASeedCollNo,
    exact: true,
    name: 'Plan QA Seed Coll No',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SEED_COLL_YES,
    component: PlanQASeedCollYes,
    exact: true,
    name: 'Plan QA Seed Coll Yes',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SEED_YEAR_INTERVAL,
    component: PlanQASeedYearInterval,
    exact: true,
    name: 'Plan QA Seed Year Interval',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SURVEY_IF_SEED,
    component: PlanQASurveyIfSeed,
    exact: true,
    name: 'Plan QA Survey If Seed',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SURVEY_NO_STATUS,
    component: PlanQASurveyNoStatus,
    exact: true,
    name: 'Plan QA Survey No Status',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SURVEY_SEED_YEAR_SYNC,
    component: PlanQASurveySeedYearSync,
    exact: true,
    name: 'Plan QA Survey Seed Year Sync',
    requiresAuth: true,
  },
  {
    path: PLAN_QA_SURVEY_YEAR_INTERVAL,
    component: PlanQASurveyYearInterval,
    exact: true,
    name: 'Plan QA Survey Year Interval',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_ASSIGNMENT,
    component: SurveyorAssignment,
    exact: true,
    name: 'Surveyor Assignment',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_ASSIGNMENT_DETAIL,
    component: SurveyorAssignmentDetail,
    exact: true,
    name: 'Surveyor Assignment Detail',
  },
  {
    path: EL_OCC_STATE_SOURCE,
    component: StateSource,
    exact: true,
    name: 'Element Occurrence State Source',
    requiresAuth: true,
  },
  {
    path: EL_OCC_STATE_DIV,
    component: StateDivision,
    exact: true,
    name: 'Element Occurrence State Division',
    requiresAuth: true,
  },
  {
    path: EL_OCC_STATE_STATUS,
    component: StateStatus,
    exact: true,
    name: 'Element Occurrence State Status',
    requiresAuth: true,
  },
  {
    path: SPECIES_STATE_STATUS_PAGE,
    component: SpeciesStatusPage,
    exact: true,
    name: 'Species Status',
    requiresAuth: true,
  },
  {
    path: EL_OCC_DIV_STATUS,
    component: DivisionStatus,
    exact: true,
    name: 'Element Occurrence Division Status',
    requiresAuth: true,
  },
  {
    path: EL_OCC_DIV_STATE_STATUS,
    component: DivisionStateStatus,
    exact: true,
    name: 'Element Occurrence Division State Status',
    requiresAuth: true,
  },
  {
    path: EL_OCC_STATE_OWNER,
    component: StateOwner,
    exact: true,
    name: 'Element Occurrence State Owner',
    requiresAuth: true,
  },
  {
    path: EL_OCC_STATE_DATA_MISMATCH,
    component: StateDataMismatch,
    exact: true,
    name: 'Element Occurrence State Data Mismatch',
    requiresAuth: true,
  },
  {
    path: SEED_BANK_DIV,
    component: SeedBankDivision,
    exact: true,
    name: 'Seed Bank Division',
    requiresAuth: true,
  },
  {
    path: SEED_COLLECTION_EOS,
    component: SeedCollectionEOs,
    exact: true,
    name: 'Seed Collection EOs',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_MANUAL_EDIT,
    component: FieldDataManualEdit,
    exact: true,
    name: 'Field Data Manual Edit',
    requiresAuth: true,
  },
  {
    path: `${FIELD_DATA_MANUAL_EDIT}/:key`,
    component: FieldDataManualEditForm,
    exact: true,
    name: 'Field Data Manual Edit Form',
  },
  {
    path: PAST_PERMIT,
    component: PastPermits,
    exact: true,
    name: 'Past Permits',
    requiresAuth: true,
  },
  {
    path: SURVEY_RESULT_CURRENT_YEAR_WITH_TABS,
    component: SurveyResultCurrentYearWithTabs,
    exact: true,
    name: 'Survey Result Current Year',
    requiresAuth: true,
  },
  {
    path: PERMISSIONS_BY_OWNER,
    component: PermissionByOwner,
    exact: true,
    name: 'Permission By Owner',
    requiresAuth: true,
  },
  {
    path: `${PERMISSIONS_BY_OWNER}/:ownerKey`,
    component: EnterOwnerPermission,
    exact: true,
    name: 'Enter Owner Permission Details',
  },
  {
    path: PERMISSIONS_BY_EO,
    component: PermissionByEO,
    exact: true,
    name: 'Permission By Owner',
    requiresAuth: true,
  },
  {
    path: `${PERMISSIONS_BY_EO}/:eokey`,
    component: EnterOwnerPermissionPermissionByEO,
    exact: true,
    name: 'Enter Owner Permission Details',
  },
  {
    path: FIELD_DATA_EDIT,
    component: FieldDataEdit,
    exact: true,
    name: 'Field Data Edit',
    requiresAuth: true,
  },
  {
    path: `${FIELD_DATA_EDIT}/:eokey`,
    component: FieldData,
    exact: true,
    name: 'Field Data',
  },
  {
    path: `${FIELD_DATA_EDIT}/:eokey/:key`,
    component: FieldDataForm,
    exact: true,
    name: 'Field Data Form',
  },
  {
    path: SEED_BANK_DIV_STATE,
    component: SeedBankDivisionState,
    name: 'Seed Bank Division State',
    requiresAuth: true,
  },
  {
    path: INACTIVE_EOS_WITH_OBSERVATION,
    component: InactiveEOsWithObservations,
    exact: true,
    name: 'Inactive EOs With Observations',
    requiresAuth: true,
  },
  {
    path: ACTIVE_EOS_WITH_CONFLICTING_FIELD_DATA,
    component: ActiveEOsWithConflictingFieldData,
    exact: true,
    name: 'Active EOs With Conflicting Field Data',
    requiresAuth: true,
  },
  {
    path: EOS_WITH_DATA_ERRORS,
    component: EOsWithDataErrors,
    exact: true,
    name: 'EOs With Data Errors',
    requiresAuth: true,
  },
  {
    path: EOS_WITHOUT_EO_NUMBERS,
    component: EOsWithoutEONumbers,
    exact: true,
    name: 'EOs Without EO Numbers',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_SEED_BY_SPECIES,
    component: FieldDataSeedBySpecies,
    exact: true,
    name: 'Field Data Seed By Species',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_CONSERVATION_SPECIES_NO_SEED,
    component: FieldDataConservationSpeciesNoSeed,
    exact: true,
    name: 'Field Data Conservation Species No Seed',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_MANUAL_ENTER,
    component: FieldDataManualEnter,
    exact: true,
    name: 'Field Data Manual Enter',
    requiresAuth: true,
  },
  {
    path: `${FIELD_DATA_MANUAL_ENTER}/:eokey`,
    component: CreateFieldDataManualEnter,
    exact: true,
    name: 'Enter field form data',
  },
  {
    path: MULTI_SPECIES_SITES,
    component: MultiSpeciesSites,
    exact: true,
    name: 'Multi Species Sites',
    requiresAuth: true,
  },
  {
    path: HERBARIUM_RECOVERY,
    component: HerbariumRecovery,
    exact: true,
    name: 'Herbarium Recovery Project',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_MANUAL,
    component: FieldDataManual,
    exact: true,
    name: 'Field Data Manual',
    requiresAuth: true,
  },
  {
    path: MANUAL_FIELD_FORM,
    component: ManualFieldForm,
    exact: true,
    name: 'Manual Field Form',
    requiresAuth: true,
  },
  {
    path: MANUAL_FIELD_FORM_DETAIL,
    component: ManualFieldFormDetail,
    exact: true,
    name: 'Manual Field Form Detail',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_SEED,
    component: FieldDataSeed,
    exact: true,
    name: 'Field Data Seed',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_SUMMARY_WITH_FILES,
    component: FieldDataSummaryWithFiles,
    exact: true,
    name: 'Field Data Summary With Files',
    requiresAuth: true,
  },
  {
    path: EL_OCC_OWNER,
    component: ElementOccurrenceOwner,
    exact: true,
    name: 'Element Occurrence Owner',
    requiresAuth: true,
  },
  {
    path: EL_OCC_OWNER_BY_EOKEY,
    component: ElementOccurrenceOwnerByKey,
    exact: true,
    name: 'Element Occurrence Owner By Key',
  },
  {
    path: CREATE_EL_OCC_OWNER,
    component: ElementOccurrenceOwnerForm,
    exact: true,
    name: 'Create Element Occurrence Owner',
    requiresAuth: true,
  },
  {
    path: EDIT_EL_OCC_OWNER,
    component: ElementOccurrenceOwnerForm,
    exact: true,
    name: 'Edit Element Occurrence Owner',
  },
  {
    path: EL_OCC_DISPLAY_AND_EDIT,
    component: DisplayAndEdit,
    exact: true,
    name: 'Element Occurrence Display and Edit',
    requiresAuth: true,
  },
  {
    path: EL_OCC_DISPLAY,
    component: DisplayAndEdit,
    exact: true,
    name: 'Element Occurrence Display',
    requiresAuth: true,
  },
  {
    path: EL_OCC_DISPLAY_AND_EDIT_FORM,
    component: DisplayAndEditForm,
    exact: true,
    name: 'Element Occurrence Display and Edit Form',
  },
  {
    path: EL_OCC_DISPLAY_FORM,
    component: DisplayForm,
    exact: true,
    name: 'Element Occurrence Display Form',
  },
  {
    path: FIELD_DATA_SPROUT,
    component: FieldDataSPROUT,
    exact: true,
    name: 'Field Data SPROUT',
    requiresAuth: true,
  },
  {
    path: SPROUT_FIELD_FORM,
    component: SPROUTFieldForm,
    exact: true,
    name: 'SPROUT Field Form',
    requiresAuth: true,
  },
  {
    path: SPROUT_FIELD_FORM_DETAIL,
    component: SPROUTFieldFormDetail,
    exact: true,
    name: 'SPROUT Field Form Detail',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_BY_DISTANCE,
    component: SurveyorByDistance,
    exact: true,
    name: 'Surveyor By Distance',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_STATE_STATUS,
    component: SurveyorStateStatus,
    exact: true,
    name: 'Surveyor State Status',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_TYPE_STATE_STATUS,
    component: SurveyorTypeStateStatus,
    exact: true,
    name: 'Surveyor Type State Status',
    requiresAuth: true,
  },
  {
    path: SURVEYORS_NO_STATE,
    component: SurveyorsNoState,
    exact: true,
    name: 'Surveyors No State',
    requiresAuth: true,
  },
  {
    path: SPECIES_DIVISION,
    component: SpeciesDivision,
    exact: true,
    name: 'Species Division',
    requiresAuth: true,
  },
  {
    path: SPECIES_STATE_STATUS,
    component: SpeciesStateStatus,
    exact: true,
    name: 'Species State Status',
    requiresAuth: true,
  },
  {
    path: SPECIES_PHENOLOGY,
    component: SpeciesPhenology,
    exact: true,
    name: 'Species Phenology',
    requiresAuth: true,
  },
  {
    path: SPECIES_WITHOUT_EOS,
    component: SpeciesWithoutEOs,
    exact: true,
    name: 'Species Without EOs',
    requiresAuth: true,
  },
  {
    path: SPECIES_WITHOUT_STATE_STATUS,
    component: SpeciesWithoutStateStatus,
    exact: true,
    name: 'Species Without State Status',
    requiresAuth: true,
  },
  {
    path: SPECIES_WITH_EOS,
    component: SpeciesWithEOs,
    exact: true,
    name: 'Species With EOs',
    requiresAuth: true,
  },
  {
    path: SPECIES_WITHOUT_STATE_CONSERVATION_STATUS,
    component: SpeciesWithoutStateConservationStatus,
    exact: true,
    name: 'Species Without State Conservation Status',
    requiresAuth: true,
  },
  {
    path: CHECKLIST_SYNONYM,
    component: SynonymName,
    exact: true,
    name: 'Synonym Name',
    requiresAuth: true,
  },
  {
    path: SPECIES_CONSERVATION_STATUS,
    component: SpeciesConservationStatus,
    exact: true,
    name: 'Species Conservation Status',
    requiresAuth: true,
  },
  {
    path: SPECIES_INVASIVE_STATUS,
    component: SpeciesInvasiveStatus,
    exact: true,
    name: 'Species Invasive Status',
    requiresAuth: true,
  },
  {
    path: SPECIES_WITH_NO_EOS,
    component: SpeciesWithNoEOs,
    exact: true,
    name: 'Species With No EOs',
    requiresAuth: true,
  },
  {
    path: GO_BOTANY_DISTRIBUTION,
    component: GoBotanyDistribution,
    exact: true,
    name: 'Go Botany Distribution',
    requiresAuth: true,
  },
  {
    path: GO_BOTANY_SPECIES_CHARACTER,
    component: GetGoBotanySpeciesCharacter,
    exact: true,
    name: 'Get Go Botany Species Character Paged',
    requiresAuth: true,
  },
  {
    path: PCV_TF_STATUS,
    component: Status,
    exact: true,
    name: 'Status',
    requiresAuth: true,
  },
  {
    path: PCV_TF_INFORMATION,
    component: Information,
    exact: true,
    name: 'Information',
    requiresAuth: true,
  },
  {
    path: PCV_TF_PROGRAM_SIGNUP,
    component: ProgramSignup,
    exact: true,
    name: 'Program Signup',
    requiresAuth: true,
  },
  {
    path: PCV_TF_FIELD_FORM_NEW,
    component: FieldFormNew,
    exact: true,
    name: 'Field Form New',
    requiresAuth: true,
  },
  {
    path: PCV_TF_HISTORY_SPROUT,
    component: PCVTFHistorySPROUT,
    exact: true,
    name: 'PCVTF History SPROUT',
    requiresAuth: true,
  },
  {
    path: PCV_TF_HISTORY_NEW_EOS,
    component: PCVTFHistoryNewEOs,
    exact: true,
    name: 'PCVTF History for New EOs',
    requiresAuth: true,
  },
  {
    path: OWNER_STATUS,
    component: OwnerStatusPage,
    exact: true,
    name: 'Owner Status',
    requiresAuth: true,
  },
  {
    path: STATUS_SUMMARY,
    component: StatusSummaryPage,
    exact: true,
    name: 'Status Summary',
    requiresAuth: true,
  },
  {
    path: EO_OUTSIDE_DISTRIBUTION,
    component: ElementOccurrenceOutsideDistribution,
    exact: true,
    name: 'Element Occurrence Outside Distribution',
    requiresAuth: true,
  },
  {
    path: COUNTIES_WITHOUT_EOS,
    component: CountiesWithoutEOs,
    exact: true,
    name: 'Counties Without EOs',
    requiresAuth: true,
  },
  {
    path: EO_MAX_EXTENT_STATUS_OF_PLANTS,
    component: ElementOccurrenceMaxExtent,
    exact: true,
    name: 'Element Occurrence Max Extent',
    requiresAuth: true,
  },
  {
    path: EO_CURRENT_EXTENT_STATUS_OF_PLANTS,
    component: ElementOccurrenceCurrentExtent,
    exact: true,
    name: 'Element Occurrence Current Extent',
    requiresAuth: true,
  },
  {
    path: EO_STATE_MGT_NOTES,
    component: ElementOccurrenceStateMgtNotes,
    exact: true,
    name: 'Element Occurrence State Mgt Notes',
    requiresAuth: true,
  },
  {
    path: EO_STATE_MGT_NOTES_SPROUT,
    component: ElementOccurrenceStateMgtNotesSPROUT,
    exact: true,
    name: 'Element Occurrence State Mgt Notes SPROUT',
    requiresAuth: true,
  },
  {
    path: MGT_NOTES_TYPE_DIVISION,
    component: MgtNotesTypeDivision,
    exact: true,
    name: 'Mgt Notes Type Division',
    requiresAuth: true,
  },
  {
    path: MGT_NOTES_TYPE_DIVISION_SPROUT,
    component: MgtNotesTypeDivisionSPROUT,
    exact: true,
    name: 'Mgt Notes Type Division SPROUT',
    requiresAuth: true,
  },
  {
    path: OWNER_BY_STATUS,
    component: OwnerStatus,
    exact: true,
    name: 'Owner Status',
    requiresAuth: true,
  },
  {
    path: OWNER_TYPE,
    component: OwnerType,
    exact: true,
    name: 'Owner Type',
    requiresAuth: true,
  },
  {
    path: OWNER_TYPE_STATE,
    component: OwnerTypeState,
    exact: true,
    name: 'Owner Type State',
    requiresAuth: true,
  },
  {
    path: OWNER_HISTORY,
    component: OwnerHistory,
    exact: true,
    name: 'Owner History',
    requiresAuth: true,
  },
  {
    path: OWNER_EO,
    component: OwnerEO,
    exact: true,
    name: 'Owner EO',
    requiresAuth: true,
  },
  {
    path: OWNER_EO_DETAILS,
    component: OwnerEODetails,
    exact: true,
    name: 'Owner EO Details',
  },
  {
    path: OWNER_EO_FORM,
    component: OwnerEOForm,
    exact: true,
    name: 'Owner EO Form',
  },
  {
    path: PCV_TF_CONTACT_LIST,
    component: ContactList,
    exact: true,
    name: 'Contact List',
    requiresAuth: true,
  },
  {
    path: PCV_TF_SIGNUP_STATUS,
    component: SignUpStatus,
    exact: true,
    name: 'Signup Status',
    requiresAuth: true,
  },
  {
    path: PCV_TF_ASSIGNMENT_SUMMARY,
    component: AssignmentSummary,
    exact: true,
    name: 'PCV/TF Assignment Summary',
    requiresAuth: true,
  },
  {
    path: PCV_TF_ASSIGNMENT_SUMMARY_BY_EOKEY,
    component: AssignmentSummaryDetails,
    exact: true,
    name: 'PCV/TF Assignment Summary Details',
  },
  // {
  //   path: PCV_TF_SURVEY_ASSIGNMENT_SPROUT_DATA,
  //   component: SurveyAssignmentSPROUTdata,
  //   exact: true,
  //   name: 'Surveyor Assignment',
  //   requiresAuth: true,
  // },
  // {
  //   path: PCV_TF_SURVEY_ASSIGNMENT_SPROUT_DATA_DETAILS,
  //   component: SurveyAssignmentSPROUTdataDetails,
  //   exact: true,
  //   name: 'Surveyor Assignment Details',
  // },
  {
    path: ANNOUNCEMENTS,
    component: Announcements,
    exact: true,
    name: 'Announcements',
  },
  {
    path: ANNOUNCEMENTS_FORM,
    component: AnnouncementsForm,
    exact: true,
    name: 'Announcements Form',
  },
  {
    path: PCV_PROGRAMS,
    component: PCVPrograms,
    exact: true,
    name: 'PCV Programs',
  },
  {
    path: PCV_PROGRAMS_FORM,
    component: PCVProgramsForm,
    exact: true,
    name: 'PCV Programs Form',
  },
  {
    path: PCV_ADMIN_SURVEY_RESULT_PRIOR_YEAR,
    component: SurveyResultPriorYear,
    exact: true,
    name: 'Survey Result Prior Year',
    requiresAuth: true,
  },
  {
    path: SIGNUP_DATE,
    component: SignupDate,
    exact: true,
    name: 'Signup Date',
    requiresAuth: true,
  },
  {
    path: PERMIT,
    component: Permit,
    exact: true,
    name: 'Permit',
    requiresAuth: true,
  },
  {
    path: ENTER_PERMISSION,
    component: EnterPermission,
    exact: true,
    name: 'Enter Permission',
    requiresAuth: true,
  },
  {
    path: PCV_TF_CONTACT_LIST,
    component: ContactList,
    exact: true,
    name: 'Contact List',
    requiresAuth: true,
  },
  {
    path: PCV_TF_SURVEY_SIGNUP,
    component: SurveySignupPCV,
    exact: true,
    name: 'Survey Signup',
    requiresAuth: true,
  },
  {
    path: SURVEY_RESULTS,
    component: SurveyResults,
    exact: true,
    name: 'Survey Signup',
    requiresAuth: true,
  },
  {
    path: SURVEY_FIELD_FORM,
    component: SurveySPROUTFieldForm,
    exact: true,
    name: 'Survey Signup',
    requiresAuth: true,
  },
  {
    path: SURVEY_FIELD_FORM_DETAIL,
    component: SurveySPROUTFieldFormDetail,
    exact: true,
    name: 'Survey Signup',
    requiresAuth: true,
  },
  {
    path: PCV_TF_SURVEY_PLAN,
    component: SurveyPlanPCV,
    exact: true,
    name: 'Survey Plan',
    requiresAuth: true,
  },
  {
    path: SURVEY_STATUS,
    component: SurveyStatusPage,
    exact: true,
    name: 'Survey Status',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_TYPE,
    component: SurveyorType,
    exact: true,
    name: 'Surveyor Type',
    requiresAuth: true,
  },
  {
    path: VALIDATION_OWNER_STATUS,
    component: ValidationOwnerStatus,
    exact: true,
    name: 'Owner Status',
    requiresAuth: true,
  },
  {
    path: OWNER_STATUS_FORM,
    component: OwnerStatusForm,
    exact: true,
    name: 'Owner Status Form',
  },
  {
    path: VALIDATION_OWNER_TYPE,
    component: ValidationOwnerType,
    exact: true,
    name: 'Owner Type',
    requiresAuth: true,
  },
  {
    path: OWNER_TYPE_FORM,
    component: OwnerTypeForm,
    exact: true,
    name: 'Owner Type Form',
  },
  {
    path: VALIDATION_PERMIT_TYPE,
    component: ValidationPermitType,
    exact: true,
    name: 'Permit Type',
    requiresAuth: true,
  },
  {
    path: PERMIT_TYPE_FORM,
    component: PermitTypeForm,
    exact: true,
    name: 'Permit Type Form',
  },
  {
    path: VALIDATION_PHENOLOGY,
    component: ValidationPhenology,
    exact: true,
    name: 'Phenology',
    requiresAuth: true,
  },
  {
    path: PHENOLOGY_FORM,
    component: PhenologyForm,
    exact: true,
    name: 'Phenology Form',
  },
  {
    path: VALIDATION_PHENOLOGY_TYPE,
    component: ValidationPhenologyType,
    exact: true,
    name: 'Phenology',
    requiresAuth: true,
  },
  {
    path: PHENOLOGY_TYPE_FORM,
    component: PhenologyTypeForm,
    exact: true,
    name: 'Phenology Form',
  },
  {
    path: VALIDATION_REPORT_METHOD,
    component: ValidationReportMethod,
    exact: true,
    name: 'Report Method',
    requiresAuth: true,
  },
  {
    path: REPORT_METHOD_FORM,
    component: ReportMethodForm,
    exact: true,
    name: 'Report Method Form',
  },
  {
    path: VALIDATION_RESEARCH_TOPIC,
    component: ValidationResearchTopic,
    exact: true,
    name: 'Research Topic',
    requiresAuth: true,
  },
  {
    path: RESEARCH_TOPIC_FORM,
    component: ResearchTopicForm,
    exact: true,
    name: 'Research Topic Form',
  },
  {
    path: VALIDATION_SLOPE,
    component: ValidationSlope,
    exact: true,
    name: 'Slope',
    requiresAuth: true,
  },
  {
    path: SLOPE_FORM,
    component: SlopeForm,
    exact: true,
    name: 'Slope Form',
  },
  {
    path: VALIDATION_SOURCE,
    component: ValidationSource,
    exact: true,
    name: 'Source',
    requiresAuth: true,
  },
  {
    path: SOURCE_FORM,
    component: SourceForm,
    exact: true,
    name: 'Source Form',
  },
  {
    path: VALIDATION_EO_STATUS,
    component: ValidationEOStatus,
    exact: true,
    name: 'EO Status',
    requiresAuth: true,
  },
  {
    path: EO_STATUS_FORM,
    component: EOStatusForm,
    exact: true,
    name: 'EO Status Form',
  },
  {
    path: VALIDATION_ENDANGERMENT_CODE,
    component: ValidationEndangermentCode,
    exact: true,
    name: 'Endangerment Code',
    requiresAuth: true,
  },
  {
    path: ENDANGERMENT_CODE_FORM,
    component: EndangermentCodeForm,
    exact: true,
    name: 'Endangerment Code Form',
  },
  {
    path: VALIDATION_SURVEY_RESULT,
    component: ValidationSurveyResult,
    exact: true,
    name: 'Survey Result',
    requiresAuth: true,
  },
  {
    path: SURVEY_RESULT_FORM,
    component: SurveyResultForm,
    exact: true,
    name: 'Survey Result Form',
  },
  {
    path: VALIDATION_TOPO_POSITION,
    component: ValidationTopoPosition,
    exact: true,
    name: 'Topo Position',
    requiresAuth: true,
  },
  {
    path: TOPO_POSITION_FORM,
    component: TopoPositionForm,
    exact: true,
    name: 'Topo Position Form',
  },
  {
    path: VALIDATION_SOURCE_OWNER,
    component: ValidationSourceOwner,
    exact: true,
    name: 'Source Owner',
    requiresAuth: true,
  },
  {
    path: SOURCE_OWNER_FORM,
    component: SourceOwnerForm,
    exact: true,
    name: 'Source Owner',
  },
  {
    path: VALIDATION_SRANK,
    component: ValidationSRank,
    exact: true,
    name: 'SRank',
    requiresAuth: true,
  },
  {
    path: SRANK_FORM,
    component: SRankForm,
    exact: true,
    name: 'SRank Form',
  },
  {
    path: VALIDATION_CONTACT_METHOD,
    component: ValidationContactMethod,
    exact: true,
    name: 'SRank',
    requiresAuth: true,
  },
  {
    path: CONTACT_METHOD_FORM,
    component: ContactMethodForm,
    exact: true,
    name: 'Contact Method Form',
  },
  {
    path: VALIDATION_COUNT_PRECISION,
    component: ValidationCountPrecision,
    exact: true,
    name: 'CountPrecision',
    requiresAuth: true,
  },
  {
    path: COUNT_PRECISION_FORM,
    component: CountPrecisionForm,
    exact: true,
    name: 'Count Precision Form',
  },
  {
    path: VALIDATION_DISTURBANCES,
    component: ValidationDisturbance,
    exact: true,
    name: 'Disturbance',
    requiresAuth: true,
  },
  {
    path: DISTURBANCES_FORM,
    component: DisturbanceForm,
    exact: true,
    name: 'Disturbance Form',
  },
  {
    path: VALIDATION_DIVISION,
    component: ValidationDivision,
    exact: true,
    name: 'Division',
    requiresAuth: true,
  },
  {
    path: DIVISION_FORM,
    component: DivisionForm,
    exact: true,
    name: 'Division Form',
  },
  {
    path: VALIDATION_AGE,
    component: ValidationAge,
    exact: true,
    name: 'Age',
    requiresAuth: true,
  },
  {
    path: AGE_FORM,
    component: AgeForm,
    exact: true,
    name: 'Age Form',
  },
  {
    path: VALIDATION_ASPECT,
    component: ValidationAspect,
    exact: true,
    name: 'Aspect',
    requiresAuth: true,
  },
  {
    path: ASPECT_FORM,
    component: AspectForm,
    exact: true,
    name: 'Aspect Form',
  },
  {
    path: VALIDATION_LIGHT,
    component: ValidationLight,
    exact: true,
    name: 'Light',
    requiresAuth: true,
  },
  {
    path: LIGHT_FORM,
    component: LightForm,
    exact: true,
    name: 'Light Form',
  },
  {
    path: VALIDATION_LOCATION_PRECISION,
    component: ValidationLocationPrecision,
    exact: true,
    name: 'Location Precision',
    requiresAuth: true,
  },
  {
    path: LOCATION_PRECISION_FORM,
    component: LocationPrecisionForm,
    exact: true,
    name: 'Location Precision',
  },
  {
    path: VALIDATION_MOISTURE_REGIME,
    component: ValidationMoistureRegime,
    exact: true,
    name: 'Moisture Regime',
    requiresAuth: true,
  },
  {
    path: MOISTURE_REGIME_FORM,
    component: MoistureRegimeForm,
    exact: true,
    name: 'Moisture Regime Form',
  },
  {
    path: VALIDATION_REACH_ORGANIZATION,
    component: ValidationOutreachOrganizationType,
    exact: true,
    name: 'Outreach Organization Type',
    requiresAuth: true,
  },
  {
    path: REACH_ORGANIZATION_FORM,
    component: OutreachOrganizationTypeForm,
    exact: true,
    name: 'Outreach Organization Type Form',
  },
  {
    path: VALIDATION_OWNER_PERMISSION,
    component: ValidationOwnerPermission,
    exact: true,
    name: 'Owner Permission',
    requiresAuth: true,
  },
  {
    path: OWNER_PERMISSION_FORM,
    component: OwnerPermissionForm,
    exact: true,
    name: 'Owner Permission Form',
  },
  {
    path: VALIDATION_FIELD_DATA_TYPE,
    component: ValidationFieldDataType,
    exact: true,
    name: 'Field Data Type',
    requiresAuth: true,
  },
  {
    path: FIELD_DATA_TYPE_FORM,
    component: FieldDataTypeForm,
    exact: true,
    name: 'Field Data Type Form',
  },
  {
    path: VALIDATION_PCV_ADMIN_OUTREACH_ORGANIZATION,
    component: ValidationOutreachOrganization,
    exact: true,
    name: 'Outreach Organization',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_OUTREACH_ORGANIZATION_FORM,
    component: OutreachOrganizationForm,
    exact: true,
    name: 'Outreach Organization Form',
  },
  {
    path: PCV_TF_FIELD_FORM_ENTER,
    component: FieldFormEnter,
    exact: true,
    name: 'Field Form Enter',
    requiresAuth: true,
  },
  {
    path: `${PCV_TF_FIELD_FORM_ENTER}/:eokey`,
    component: CreateFieldFormEnter,
    exact: true,
    name: 'Enter field form data',
  },
  {
    path: FIELD_FORM_ENTER_PRIOR,
    component: FieldFormEnterPrior,
    exact: true,
    name: 'Field Form Enter Prior',
    requiresAuth: true,
  },
  {
    path: `${FIELD_FORM_ENTER_PRIOR}/:eokey`,
    component: EnterFieldFormData,
    exact: true,
    name: 'Enter field form data',
  },
  {
    path: OWNER_REPORT,
    component: OwnerReport,
    exact: true,
    name: 'Owner Report',
    requiresAuth: true,
  },
  {
    path: OWNER_REPORT_WITH_PROBLEM,
    component: OwnerReportWithProblem,
    exact: true,
    name: 'Owner Report With Problem',
    requiresAuth: true,
  },
  {
    path: EMAIL_MANAGEMENT,
    component: EmailManagement,
    exact: true,
    name: 'Email Management',
    requiresAuth: true,
  },
  {
    path: SURVEY_STATE,
    component: SurveyState,
    exact: true,
    name: 'Survey State',
  },
  {
    path: REPORT_STATUS_STATE,
    component: ReportStatusState,
    exact: true,
    name: 'Report Status State',
  },
  {
    path: SURVEY_STATE_PERMISSION,
    component: SurveyStatePermission,
    exact: true,
    name: 'Survey State Permission',
  },
  {
    path: SURVEY_OWNER_PERMISSION,
    component: SurveyOwnerPermission,
    exact: true,
    name: 'Survey Owner Permission',
  },
  {
    path: SEED_COLL_STATE,
    component: SeedCollState,
    exact: true,
    name: 'Seed Coll State',
  },
  {
    path: SEED_COLL_STATE_PERMISSION,
    component: SeedCollStatePermission,
    exact: true,
    name: 'Seed Coll State Permission',
  },
  {
    path: SEED_COLL_OWNER_PERMISSION,
    component: SeedCollOwnerPermission,
    exact: true,
    name: 'Seed Coll Owner Permission',
  },
  {
    path: FIELD_FORM_STATE,
    component: FieldFormState,
    exact: true,
    name: 'Field Form State',
  },
  {
    path: SURVEY_RESULT_CURRENT_YEAR,
    component: SurveyResultCurrentYear,
    exact: true,
    name: 'Survey Result Current Year',
  },
  {
    path: SURVEY_RESULT_YEAR,
    component: SurveyResultYear,
    exact: true,
    name: 'Survey Result Year',
  },
  {
    path: PCV_TF_ASSIGNMENT_FIELD_DATA,
    component: SurveyAssignmentFieldData,
    exact: true,
    name: 'Survey Assignment Field data',
  },
  {
    path: FIELD_FORM_REVIEW_NO_EO,
    component: FieldFormReviewNoEO,
    exact: true,
    name: 'Field Form Review No EO',
    requiresAuth: true,
  },
  {
    path: `${FIELD_FORM_REVIEW_NO_EO}/:key`,
    component: FieldFormReviewNoEOForm,
    exact: true,
    name: 'Field Form Review No EO Form',
  },
  {
    path: FIELD_FORM_EDIT_PRIOR,
    component: FieldFormEditPrior,
    exact: true,
    name: 'Field Form Edit Prior',
    requiresAuth: true,
  },
  {
    path: `${FIELD_FORM_EDIT_PRIOR}/:key`,
    component: FieldFormEditPriorForm,
    exact: true,
    name: 'Field Form Edit Prior Form',
  },
  {
    path: FIELD_FORM_REVIEW,
    component: FieldFormReview,
    exact: true,
    name: 'Field Form Review',
    requiresAuth: true,
  },
  {
    path: `${FIELD_FORM_REVIEW}/:key`,
    component: FieldFormReviewForm,
    exact: true,
    name: 'Field Form Review Form',
  },
  {
    path: SURVEYOR_STATE,
    component: SurveyorState,
    exact: true,
    name: 'Surveyor State',
    requiresAuth: true,
  },
  {
    path: `${SURVEYOR_STATE}/:surveyorKey`,
    component: AddOrDeleteState,
    exact: true,
    name: 'Add Or Delete State',
  },
  {
    path: `${SURVEYOR_STATE}/:surveyorKey/:key`,
    component: AddStateForSurveyor,
    exact: true,
    name: 'Add State For Surveyor',
  },
  {
    path: FIELD_FORM_EDIT,
    component: FieldFormEdit,
    exact: true,
    name: 'Field Form Edit',
    requiresAuth: true,
  },
  {
    path: `${FIELD_FORM_EDIT}/:key`,
    component: FieldFormEditForm,
    exact: true,
    name: 'Field Form Edit Form',
  },
  {
    path: SURVEYOR_DISPLAY_AND_EDIT,
    component: SurveyorDisplayAndEdit,
    exact: true,
    name: 'Surveyor Display and Edit',
    requiresAuth: true,
  },
  {
    path: SURVEYOR_DISPLAY,
    component: SurveyorDisplayAndEdit,
    exact: true,
    name: 'Surveyor Display',
    requiresAuth: true,
  },
  {
    path: `${SURVEYOR_DISPLAY_AND_EDIT}/:key`,
    component: SurveyorDisplayAndEditForm,
    exact: true,
    name: 'Surveyor Display and Edit Form',
  },
  {
    path: OWNER_DISPLAY_AND_EDIT,
    component: OwnerDisplayAndEdit,
    exact: true,
    name: 'Owner Display and Edit',
    requiresAuth: true,
  },
  {
    path: OWNER_DISPLAY,
    component: OwnerDisplayAndEdit,
    exact: true,
    name: 'Owner Display',
    requiresAuth: true,
  },
  {
    path: `${OWNER_DISPLAY_AND_EDIT}/:key`,
    component: OwnerDisplayAndEditForm,
    exact: true,
    name: 'Owner Display and Edit Form',
  },
  {
    path: `${OWNER_DISPLAY}/:key`,
    component: OwnerDisplayForm,
    exact: true,
    name: 'Owner Display Form',
  },
  {
    path: OWNER_EO_CURRENT_YEAR,
    component: OwnerEOCurrentSurveyYear,
    exact: true,
    name: 'Owner EO Current Survey Year',
    requiresAuth: true,
  },
  {
    path: SURVEY_PLAN_EOS_WITHOUT_OWNER,
    component: SurveyPlanEOsWithoutOwner,
    exact: true,
    name: 'Survey Plan EOs Without Owner',
    requiresAuth: true,
  },
  {
    path: SPECIES_INFORMATION_EDIT,
    component: SpeciesInformationEdit,
    exact: true,
    name: 'Species Information Edit',
    requiresAuth: true,
  },
  {
    path: `${SPECIES_INFORMATION_EDIT}/:key`,
    component: SpeciesInformationEditDetails,
    exact: true,
    name: 'Species Information Edit Details',
  },
  {
    path: `${SPECIES_INFORMATION_EDIT}/:key/:type`,
    component: SpeciesInformationEditForm,
    exact: true,
    name: 'Species Information Edit Form',
  },
  {
    path: COUNT_STATE_MANUAL_FORM,
    component: CountStateManualForm,
    exact: true,
    name: 'Count State Manual Form',
    requiresAuth: true,
  },
  {
    path: TRANSCRIPTION_MANAGEMENT,
    component: TranscriptionManagement,
    exact: true,
    name: 'Transcription Management',
    requiresAuth: true,
  },
  {
    path: PCV_TF_MAP,
    component: PCVTFMap,
    exact: true,
    name: 'Map',
    requiresAuth: true,
  },
  {
    path: PCV_TF_FIELD_FORM_NEW_EDIT,
    component: PCVTFFieldFormNewEdit,
    exact: true,
    name: 'PCVTFFieldFormNewEdit',
    requiresAuth: true,
  },
  {
    path: `${PCV_TF_FIELD_FORM_NEW_EDIT}/:key`,
    component: PCVTFFieldFormNewEditForm,
    exact: true,
    name: 'PCVTFFieldFormNewEditForm',
  },
  {
    path: STATUS_OF_PLANTS_MAP,
    component: StatusOfPlantsMap,
    exact: true,
    name: 'Map',
    requiresAuth: true,
  },
  {
    path: STATUS_OF_PLANTS_ASSOCIATED_SPECIES_MANUAL,
    component: AssociatedSpeciesManual,
    exact: true,
    name: 'AssociatedSpeciesManual',
    requiresAuth: true,
  },
  {
    path: STATUS_OF_PLANTS_ASSOCIATED_SPECIES_SPROUT,
    component: AssociatedSpeciesSPROUT,
    exact: true,
    name: 'AssociatedSpeciesSPROUT',
    requiresAuth: true,
  },
  {
    path: STATUS_OF_PLANTS_INVASIVE_SPECIES_MANUAL,
    component: InvasiveSpeciesManual,
    exact: true,
    name: 'InvasiveSpeciesManual',
    requiresAuth: true,
  },
  {
    path: STATUS_OF_PLANTS_INVASIVE_SPECIES_SPROUT,
    component: InvasiveSpeciesSPROUT,
    exact: true,
    name: 'InvasiveSpeciesSPROUT',
    requiresAuth: true,
  },
  {
    path: STATUS_OF_PLANTS_NATURAL_COMMUNITIES_EOS,
    component: NaturalCommunities,
    exact: true,
    name: 'NaturalCommunities',
    requiresAuth: true,
  },
  {
    path: STATUS_OF_PLANTS_NATURAL_COMMUNITIES_SPECIES,
    component: NaturalCommunitiesSpecies,
    exact: true,
    name: 'NaturalCommunitiesSpecies',
    requiresAuth: true,
  },
  {
    path: PCV_ADMIN_MAP,
    component: PCVAdminMap,
    exact: true,
    name: 'Map',
    requiresAuth: true,
  },
  {
    path: PCV_CONTROL_PANEL,
    component: Details,
    exact: true,
    name: 'BlockSiteForm',
    requiresAuth: true,
  },
];
