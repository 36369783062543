import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import { Form, Button, Row, Col, Spin } from 'antd';
import Geocode from 'react-geocode';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAuth } from '../../../global/stores/auth';
import FormItem from '../../../shared/components/formItem';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../utils/general';
import PageContent from '../../../shared/components/PageContent';
import { GOOGLE_GEOCODING_API_KEY } from '../../../shared/components/Map/constants';

import { formConfig } from './form-config';

import classes from '../../styles.module.scss';

Geocode.setApiKey(GOOGLE_GEOCODING_API_KEY);
Geocode.enableDebug();

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchData = () =>
  APIFetch(ENDPOINT_URLS.CHANGE_ACCOUNT_SETTINGS, {
    method: 'get',
  });

const fetchUpdate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CHANGE_ACCOUNT_SETTINGS, {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const Profile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setAuthState, securityAgreementAccepted, token, login } = useAuth();
  const { authError } = login;
  const { data: accountSettingsData, refetch } = useAPI(
    (args) => fetchData(args),
    {
      initialState: { data: {}, loading: false },
    }
  );

  const {
    data: updatedData,
    loading: updateLoading,
    refetch: updateRefetch,
  } = useAPI(fetchUpdate, {
    initialState: { data: null, loading: false },
    shouldFetch: false,
  });

  const initialDisabledFields = ['statesForSurveys'];
  const [disabledFields] = useState(initialDisabledFields);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (Object.keys(accountSettingsData).length) {
      const {
        address1,
        address2,
        city,
        firstName,
        jobTitle,
        lastName,
        organization,
        phone,
        postalCode,
        state,
        surveyorStates,
        title,
        daysAvailableToSurvey,
        emergencyContactEmailAddress,
        emergencyContactName,
        emergencyContactPhoneNumber,
      } = accountSettingsData;

      const surveyorStatesResult = surveyorStates.join(', ');

      setInitialValues({
        address1,
        address2,
        city,
        firstName,
        jobTitle,
        lastName,
        organization,
        phone,
        postalCode,
        state,
        statesForSurveys: surveyorStatesResult,
        title,
        daysAvailableToSurvey,
        emergencyContactEmailAddress,
        emergencyContactName,
        emergencyContactPhoneNumber,
      });
    }
  }, [accountSettingsData]);

  const onFinish = async (values) => {
    const { statesForSurveys, state, city, ...other } = values;

    const address = [city, state].join(', ').replaceAll(' ', '+');
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        updateRefetch({
          state,
          city,
          latitude: lat || 0,
          longitude: lng || 0,
          ...other,
        });
      },
      () => openNotificationWithIcon('error', 'Failed get address coordinates')
    );
  };

  useEffect(() => {
    if (updatedData && !updateLoading) {
      if (updatedData.lastName) {
        openNotificationWithIcon(
          'success',
          'Profile setting changed successfully'
        );
        refetch();
        setAuthState({
          securityAgreementAccepted,
          tokenPair: token,
          authError,
          user: updatedData,
        });
      }
    }
  }, [updateLoading]);

  return (
    <PageContent withBreadcrumb={false} styles={{ padding: 0 }}>
      <Title
        className={classNames(classes.title, classes.titlePadding)}
        level={3}
      >
        Profile settings
      </Title>
      <Row justify="space-around" align="middle">
        <Col span={12}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="profileSettings"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  disabled={disabledFields.includes(name)}
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button type="primary" htmlType="submit">
                  {t('form:submit')}
                </Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default Profile;
