import React from 'react';
import { InputNumber, Input, Checkbox } from 'antd';

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const { TextArea } = Input;

export const formConfig = [
  {
    name: 'state',
    label: 'State',
  },
  {
    name: 'speciesKey',
    label: 'Species Name',
  },
  {
    name: 'eonumberInt',
    label: 'EO Number',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'subpopulation',
    label: 'Subpopulation',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'countyKey',
    label: 'County',
  },
  {
    name: 'town',
    label: 'Town',
  },
  {
    name: 'siteName',
    label: 'Site Name',
  },
  {
    name: 'ownerNotes',
    label: 'Owner Notes',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'commentsRegardingSite',
    label: 'Comments Regarding Site',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'history',
    label: 'History',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'eoid',
    label: 'EOID',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'latitude',
    label: 'Latitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
  },
  {
    name: 'longitude',
    label: 'Longitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
  },
  {
    name: 'locationPrecision',
    label: 'Location Precision',
  },
  {
    name: 'eostatus',
    label: 'EO Status',
  },
  {
    name: 'nextSurveyYear',
    label: 'Next Survey Year',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'nextSeedYear',
    label: 'Next Seed Year',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'surveyInterval',
    label: 'Survey Interval',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'seedInterval',
    label: 'Seed Interval',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'popOrigin',
    label: 'Pop Origin',
  },
  {
    name: 'directions',
    label: 'Directions',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'surveyDate',
    label: 'Survey Date',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'tfpcv',
    label: 'TFPCV',
  },
  {
    name: 'lastObs',
    label: 'Last Obs',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'firstObs',
    label: 'First Obs',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'minElev',
    label: 'Min Elev (ft)',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'maxElev',
    label: 'Max Elev (ft)',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'mgtNotes',
    label: 'Mgt Notes',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'sensitive',
    label: 'Sensitive',
  },
  {
    name: 'popArea',
    label: 'Pop Area (m2)',
    input: (props) => <CustomInputNumber {...props} step={1} />,
  },
  {
    name: 'specimens',
    label: 'Specimens',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'eorank',
    label: 'EO Rank',
  },
  {
    name: 'eorankDate',
    label: 'EO Rank Date',
  },
  {
    name: 'subEo',
    label: 'Sub EO',
  },
  {
    name: 'parentEo',
    label: 'Parent EO',
  },
  {
    name: 'parentYn',
    label: 'Parent YN',
  },
  {
    name: 'idconfirm',
    label: 'ID Confirm',
  },
  {
    name: 'eocondition',
    label: 'EO Condition',
  },
  {
    name: 'eosize',
    label: 'EO Size',
  },
  {
    name: 'eolandscape',
    label: 'EO Landscape',
  },
  {
    name: 'eotracking',
    label: 'EO Tracking',
  },
  {
    name: 'normspecies',
    label: 'NORM Species',
  },
  {
    name: 'gisspecies',
    label: 'GIS Species',
  },
  {
    name: 'stateSpecies',
    label: 'State Species',
  },
  {
    name: 'owners',
    label: 'Owners',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'sF_LAT_LONG',
    label: 'Additional Locations',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'shape_Leng',
    label: 'Shape Leng',
  },
  {
    name: 'shape_Area',
    label: 'Shape Area',
  },
  {
    name: 'otherRef',
    label: 'Other Ref',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'deleted',
    label: 'Mark as delete',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
  },
];
