import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { createPdf } from '../../../utils/general';

const fetchContent = (key) =>
  APIFetch(ENDPOINT_URLS.GET_PCV_TF_ASSIGNMENT_SUMMARY_DETAILS(key), {
    method: 'get',
  });

export const actionContentFfkey = (record, path) => {
  const { t } = useTranslation();

  const { ffkey } = record;

  return <Link to={`${path}?eokey=${ffkey}`}>{t('table:select')}</Link>;
};

export const actionContent = (
  record,
  { pageName = '', fileTitle = 'PCV/TF Assignment Summary' }
) => {
  const { t } = useTranslation();
  const { data } = useAPI(() => fetchContent(record.eokey), {
    initialState: { data: {}, loading: false },
  });

  const {
    state,
    speciesName,
    eonumber,
    subpopulation,
    town,
    siteName,
    survey,
    collectSeed,
    surveyComment,
    leadSurvName,
    surv2Name,
    surv3Name,
    surv4Name,
  } = record;
  const filenamePostfix = pageName
    ? ` ${pageName} ${new Date().getFullYear()}`
    : '';
  const intermediateFilename = `${state}_${speciesName}_${eonumber}_${subpopulation}${filenamePostfix}`;
  const filename = intermediateFilename.replace(/\./g, '');

  const parentData = {
    state,
    speciesName,
    eonumber,
    subpopulation,
    town,
    siteName,
    survey,
    collectSeed,
    surveyComment,
    leadSurvName,
    surv2Name,
    surv3Name,
    surv4Name,
  };

  const ownerSurveyInformation = [
    'organization',
    'subOrganization',
    'firstName',
    'lastName',
    'ownerComment',
    'phone',
    'email',
    'permitStartDate',
    'permitEndDate',
    'permitDocument1',
    'permitDocument2',
    'permitDocumentLink1',
    'permitDocumentLink2',
    'permitComment',
    'ownerPermission',
  ];

  const createPdfTable = () => {
    if (data?.ownerSurveyInformation?.length > 0) {
      const pdfDataFields = Object.keys(parentData).map((propKey) => {
        const value = parentData[propKey];
        return {
          name: t(`table:${propKey}`),
          value,
        };
      });

      if (data.ownerNotes || data.history) {
        pdfDataFields.push({
          name: t(`table:ownerNotes`),
          value: data.ownerNotes,
        });
        pdfDataFields.push({
          name: t(`table:history`),
          value: data.history,
        });
      }

      data.ownerSurveyInformation?.map((itemInfo) =>
        ownerSurveyInformation.map((item) => {
          const value = itemInfo[item];
          return pdfDataFields.push({
            name: t(`table:${item}`),
            value,
          });
        })
      );

      data.phenologySurveyInformation?.map((item) =>
        Object.keys(item).map((propKey) => {
          const value = item[propKey];
          return pdfDataFields.push({
            name: t(`table:${propKey}`),
            value:
              value && (propKey === 'firstDate' || propKey === 'lastDate')
                ? `${value.slice(5, 7)}/${value.slice(8, 10)}`
                : value,
          });
        })
      );

      data.speciesPhenologyNotesConservationStatus?.map((item) =>
        Object.keys(item).map((propKey) => {
          const value = item[propKey];
          return pdfDataFields.push({
            name: t(`table:${propKey}`),
            value,
          });
        })
      );

      data.lookalikeSpecies?.map((item) =>
        Object.keys(item).map((propKey) => {
          const value = item[propKey];
          return pdfDataFields.push({
            name: t(`table:${propKey}`),
            value,
          });
        })
      );

      if (!data.lookalikeSpecies?.length) {
        pdfDataFields.push({
          name: t(`table:speciesName`),
          value: null,
        });
        pdfDataFields.push({
          name: t(`table:lookalikeScientificName`),
          value: null,
        });
        pdfDataFields.push({
          name: t(`table:lookalikeCharacteristic`),
          value: null,
        });
      }

      data.researchQuestions?.map((item) =>
        Object.keys(item).map((propKey) => {
          const value = item[propKey];
          return pdfDataFields.push({
            name: t(`table:${propKey}`),
            value,
          });
        })
      );

      if (!data.researchQuestions?.length) {
        pdfDataFields.push({
          name: t(`table:speciesName`),
          value: null,
        });
        pdfDataFields.push({
          name: t(`table:fullText`),
          value: null,
        });
      }

      data.nearbySpecies?.map((item) =>
        Object.keys(item).map((propKey) => {
          const value = item[propKey];
          return pdfDataFields.push({
            name: t(`table:${propKey}`),
            value,
          });
        })
      );

      if (!data.nearbySpecies?.length) {
        pdfDataFields.push({
          name: t(`table:speciesName`),
          value: null,
        });
        pdfDataFields.push({
          name: t(`table:commonName`),
          value: null,
        });
      }

      createPdf(pdfDataFields, fileTitle, filename, true);
    }
  };

  return (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        style={{
          marginRight: 8,
        }}
        onClick={createPdfTable}
      >
        Download
      </a>
    </span>
  );
};
