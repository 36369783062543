import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

import PhenologyTypesSelect from '../../../../shared/components/select/PhenologyTypesSelect';
import { getMomentDate } from '../../../../utils/general';

const minPickerValue = moment().set('year', 2000).startOf('year');
const maxPickerValue = moment().set('year', 2000).endOf('year');

const disabledDate = (current) => {
  const tooLate = current > maxPickerValue;
  const tooEarly = current < minPickerValue;

  return tooEarly || tooLate;
};

const renderDatePicker = ({ value, ...props }) => (
  <DatePicker
    {...props}
    value={value ? getMomentDate(value) : ''}
    disabledDate={disabledDate}
    format="MM-DD"
    style={{ width: '100%' }}
    allowClear={false}
    defaultPickerValue={value ? getMomentDate(value) : minPickerValue}
  />
);

export const formConfig = [
  {
    name: 'phenologyType',
    label: 'Phenology Type',
    input: (props) => <PhenologyTypesSelect {...props} width="100%" />,
  },
  {
    name: 'firstDate',
    label: 'First Date',
    input: renderDatePicker,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'lastDate',
    label: 'Last Date',
    input: renderDatePicker,
    rules: [
      {
        required: true,
      },
    ],
  },
];
