import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const ContactMethodsSelect = (props) => {
  const { contactMethodsData, contactMethodsLoading, updateContactMethods } =
    useStore();

  useEffect(() => {
    if (!contactMethodsData.length && !contactMethodsLoading)
      updateContactMethods();
  }, []);

  return (
    <Select
      props={props}
      data={contactMethodsData}
      loading={contactMethodsLoading}
    />
  );
};

export default ContactMethodsSelect;
