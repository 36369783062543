import React from 'react';
import { InputNumber } from 'antd';
import StatesSelect from '../../../shared/components/select/StatesSelect';
import { dateValidationRules } from '../../../utils/general';

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

export const feSearchConfig = [
  {
    name: 'Town',
    label: 'Town',
    rules: [
      {
        required: false,
        max: 60,
      },
    ],
  },
  {
    name: 'SpeciesName',
    label: 'Species',
    operatortype: 'Contains',
    rules: [
      {
        required: false,
      },
    ],
  },
  {
    name: 'Year',
    label: 'Year',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [dateValidationRules('Year')],
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'Town',
  },
  {
    operatortype: 'Contains',
    propertyName: 'SpeciesName',
  },
  {
    operatortype: 'Equals',
    propertyName: 'Year',
  },
  {
    operatortype: 'Equals',
    propertyName: 'State',
  }
];
