import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import { Button } from 'antd';
import { useReactToPrint } from 'react-to-print';

import Marker from './Marker';
import { DEFAULT_ZOOM, DEFAULT_CENTER, GOOGLE_MAP_API_KEY } from './constants';
import { getMapOptions } from './utils';

import classes from './styles.module.scss';

export const Map = ({
  coordinates,
  center,
  zoom,
  cancel,
  balloonsToggle = true,
  legend,
  info,
  documentTitle = 'SPROUT'
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const { search: searchParams } = useLocation();
  const query = new URLSearchParams(searchParams);
  const eo = query.get('eo');
  const sn = query.get('speciesName');
  const [isCloseAllBalloons, setIsCloseAllBalloons] = useState();
  const [isClickPrint, setIsClickPrint] = useState(false);

  const handlePrintClick = useReactToPrint({
    content: () => componentRef.current,
    documentTitle,
  });

  const handlePrint = () => {
    handlePrintClick();
  };

  const showEO = () => {
    setIsClickPrint(true);
    setTimeout(() => setIsClickPrint(false), 1000);
  };

  useEffect(() => {
    const mapBox = document.getElementById('mapBox');
    mapBox.addEventListener('click', (e) => showEO(), true);
  }, []);

  return (
    <div className={classNames(classes.mapWrapper)}>
      <>
        {balloonsToggle ? (
          <div className={classNames(classes.content)}>
            <Button
              type="primary"
              onClick={() => setIsCloseAllBalloons(!isCloseAllBalloons)}
            >
              {isCloseAllBalloons ? 'Open' : 'Close'} All Balloons
            </Button>
            <div id="mapBox">
              <Button
                className={classNames(classes.printButton)}
                onClick={handlePrintClick}
              >
                {t('form:Print')}
              </Button>
            </div>
          </div>
        ) : (
          <div id="mapBox">
            <Button onClick={handlePrint}>{t('form:Print')}</Button>
          </div>
        )}
        <div className={classNames(classes.mapBox)} ref={componentRef}>
          <div className={classNames(classes.map)}>
            {isClickPrint && <h2>{info === 'eo' ? eo : sn}</h2>}
            {cancel ? (
              <>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                  defaultCenter={DEFAULT_CENTER}
                  defaultZoom={DEFAULT_ZOOM}
                  center={center}
                  zoom={zoom}
                  options={getMapOptions}
                />
                {legend}
              </>
            ) : (
              <>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
                  defaultCenter={DEFAULT_CENTER}
                  defaultZoom={DEFAULT_ZOOM}
                  center={center}
                  zoom={zoom}
                  options={getMapOptions}
                >
                  {coordinates.length &&
                    coordinates.map(({ id, ...rest }) => (
                      <Marker
                        key={id}
                        isCloseAllBalloons={isCloseAllBalloons}
                        {...rest}
                      />
                    ))}
                </GoogleMapReact>
                {legend}
              </>
            )}
            {!cancel && !coordinates.length && (
              <p className={classNames(classes.noDataNestedTable)}>
                {t('table:noData')}
              </p>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default Map;
