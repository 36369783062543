import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_GO_BOTANY_DISTRIBUTION, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_GO_BOTANY_DISTRIBUTION_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const GoBotanyDistribution = () => (
  <PageWithTable
    fetchData={fetch}
    fetchExport={fetchExport}
    defaultSortField={['ScientificName', 'State', 'County']}
    titleKey="Species:GoBotanyDistribution"
    descriptionKey="Species:GoBotanyDistributionDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
  />
);

export default GoBotanyDistribution;
