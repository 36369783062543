import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SurveyResultStatesSelect = (props) => {
  const {
    surveyResultStatesData,
    surveyResultStatesLoading,
    updateSurveyResultStates,
  } = useStore();

  useEffect(() => {
    if (!surveyResultStatesData.length && !surveyResultStatesLoading)
      updateSurveyResultStates();
  }, []);

  return (
    <Select
      props={props}
      width={85}
      data={surveyResultStatesData}
      loading={surveyResultStatesLoading}
    />
  );
};

export default SurveyResultStatesSelect;
