import React, { useEffect } from 'react';

import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { useAuth } from '../auth';
import { roleTypes } from '../../../utils/roles';

import {
  fetchStates,
  fetchDaysAvailableToSurvey,
  fetchStatesForSurveyor,
  fetchEOStatuses,
  fetchDivisions,
  fetchTopoPositions,
  fetchAspects,
  fetchLights,
  fetchExistingLights,
  fetchExistingMoistureRegimes,
  fetchMoistureRegimes,
  fetchSurveyorTypes,
  fetchSurveyors,
  fetchTopics,
  fetchManagementTypes,
  fetchNonNoThreatDisturbances,
  fetchOwners,
  fetchSourceOwners,
  fetchCharacterGroups,
  fetchSpeciesNames,
  fetchCounties,
  fetchLocationPrecisions,
  fetchPermitTypes,
  fetchNonStayOffOwnerPermissions,
  fetchContactMethods,
  fetchOwnerPermissions,
  fetchSurveyResult,
  fetchSurveyResultStates,
  fetchLocationPrecisionsForFieldEnter,
  fetchSlopes,
  fetchCountPrecisions,
  fetchOutreachOrganizationTypes,
  fetchMgtNotesAndEODataSources,
  fetchAllMgtFieldDataTypes,
  fetchPhenologyTypes,
  fetchSurveyResults,
  fetchFieldDataTypes,
  fetchSources,
  fetchAllOwnerTypes,
  fetchAllOwnerStatuses,
  fetchAllReportMethods,
  fetchRoles,
  fetchTranscribers,
  fetchSurveyorTypesExceptAdmin,
} from './services';

import { StoreContext } from './StoreContext';

const StoreProvider = (props) => {
  const { children } = props;
  const { user, securityAgreementAccepted } = useAuth();

  const {
    data: statesData,
    loading: statesLoading,
    refetch: updateStates,
  } = useAPI(fetchStates, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: daysAvailableData,
    loading: daysAvailableLoading,
    refetch: updateDaysAvailable,
  } = useAPI(fetchDaysAvailableToSurvey, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: statesForSurveyorData,
    loading: statesForSurveyorLoading,
    refetch: updateStatesForSurveyor,
  } = useAPI(fetchStatesForSurveyor, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: eoStatusesData,
    loading: eoStatusesLoading,
    refetch: updateEOStatuses,
  } = useAPI(fetchEOStatuses, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: divisionsData,
    loading: divisionsLoading,
    refetch: updateDivisions,
  } = useAPI(fetchDivisions, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: surveyResultsData,
    loading: surveyResultsLoading,
    refetch: updateSurveyResults,
  } = useAPI(fetchSurveyResults, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: topoPositionsData,
    loading: topoPositionsLoading,
    refetch: updateTopoPositions,
  } = useAPI(fetchTopoPositions, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: aspectsData,
    loading: aspectsLoading,
    refetch: updateAspects,
  } = useAPI(fetchAspects, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: lightsData,
    loading: lightsLoading,
    refetch: updateLights,
  } = useAPI(fetchLights, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: moistureRegimesData,
    loading: moistureRegimesLoading,
    refetch: updateMoistureRegimes,
  } = useAPI(fetchMoistureRegimes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: existingLightsData,
    loading: existingLightsLoading,
    refetch: updateExistingLights,
  } = useAPI(fetchExistingLights, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: existingMoistureRegimesData,
    loading: existingMoistureRegimesLoading,
    refetch: updateExistingMoistureRegimes,
  } = useAPI(fetchExistingMoistureRegimes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: surveyorTypesData,
    loading: surveyorTypesLoading,
    refetch: updateSurveyorTypes,
  } = useAPI(fetchSurveyorTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: surveyorTypesExceptAdminData,
    loading: surveyorTypesExceptAdminLoading,
    refetch: updateSurveyorTypesExceptAdmin,
  } = useAPI(fetchSurveyorTypesExceptAdmin, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: surveyorsData,
    loading: surveyorsLoading,
    refetch: updateSurveyors,
  } = useAPI(fetchSurveyors, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: topicsData,
    loading: topicsLoading,
    refetch: updateTopics,
  } = useAPI(fetchTopics, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: managementTypesData,
    loading: managementTypesLoading,
    refetch: updateManagementTypes,
  } = useAPI(fetchManagementTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: disturbancesData,
    loading: disturbancesLoading,
    refetch: updateDisturbances,
  } = useAPI(fetchNonNoThreatDisturbances, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: ownersData,
    loading: ownersLoading,
    refetch: updateOwners,
  } = useAPI(fetchOwners, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: sourceOwnersData,
    loading: sourceOwnersLoading,
    refetch: updateSourceOwners,
  } = useAPI(fetchSourceOwners, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: speciesNamesData,
    loading: speciesNamesLoading,
    refetch: updateSpeciesNames,
  } = useAPI(fetchSpeciesNames, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: countiesData,
    loading: countiesLoading,
    refetch: updateCounties,
  } = useAPI(fetchCounties, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: locationPrecisionsData,
    loading: locationPrecisionsLoading,
    refetch: updateLocationPrecisions,
  } = useAPI(fetchLocationPrecisions, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: characterGroupsData,
    loading: characterGroupsLoading,
    refetch: updateCharacterGroups,
  } = useAPI(fetchCharacterGroups, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: permitTypesData,
    loading: permitTypesLoading,
    refetch: updatePermitTypes,
  } = useAPI(fetchPermitTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: nonStayOffOwnerPermissionsData,
    loading: nonStayOffOwnerPermissionsLoading,
    refetch: updateNonStayOffOwnerPermissions,
  } = useAPI(fetchNonStayOffOwnerPermissions, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: ownerPermissionsData,
    loading: ownerPermissionsLoading,
    refetch: updateOwnerPermissions,
  } = useAPI(fetchOwnerPermissions, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: contactMethodsData,
    loading: contactMethodsLoading,
    refetch: updateContactMethods,
  } = useAPI(fetchContactMethods, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: surveyResultGroupsData,
    loading: surveyResultLoading,
    refetch: updateSurveyResult,
  } = useAPI(fetchSurveyResult, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: surveyResultStatesData,
    loading: surveyResultStatesLoading,
    refetch: updateSurveyResultStates,
  } = useAPI(fetchSurveyResultStates, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: phenologyTypesData,
    loading: phenologyTypesLoading,
    refetch: updatePhenologyTypes,
  } = useAPI(fetchPhenologyTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: locationPrecisionsForFieldEnterData,
    loading: locationPrecisionsForFieldEnterLoading,
    refetch: updateLocationPrecisionsForFieldEnter,
  } = useAPI(fetchLocationPrecisionsForFieldEnter, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: slopesData,
    loading: slopesLoading,
    refetch: updateSlopes,
  } = useAPI(fetchSlopes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: rolesData,
    loading: rolesLoading,
    refetch: updateRoles,
  } = useAPI(fetchRoles, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: countPrecisionsData,
    loading: countPrecisionsLoading,
    refetch: updateCountPrecisions,
  } = useAPI(fetchCountPrecisions, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: outreachOrganizationTypesData,
    loading: outreachOrganizationTypesLoading,
    refetch: updateOutreachOrganizationTypes,
  } = useAPI(fetchOutreachOrganizationTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: sourcesData,
    loading: sourcesLoading,
    refetch: updateSources,
  } = useAPI(fetchSources, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: fieldDataTypesData,
    loading: fieldDataTypesLoading,
    refetch: updateFieldDataTypes,
  } = useAPI(fetchFieldDataTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: mgtNotesAndEODataSourcesData,
    loading: mgtNotesAndEODataSourcesLoading,
    refetch: updateMgtNotesAndEODataSources,
  } = useAPI(fetchMgtNotesAndEODataSources, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: allMgtFieldDataTypesData,
    loading: allMgtFieldDataTypesLoading,
    refetch: updateAllMgtFieldDataTypes,
  } = useAPI(fetchAllMgtFieldDataTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: allOwnerTypesData,
    loading: allOwnerTypesLoading,
    refetch: updateAllOwnerTypes,
  } = useAPI(fetchAllOwnerTypes, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: allOwnerStatusesData,
    loading: allOwnerStatusesLoading,
    refetch: updateAllOwnerStatuses,
  } = useAPI(fetchAllOwnerStatuses, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: allReportMethodsData,
    loading: allReportMethodsLoading,
    refetch: updateAllReportMethods,
  } = useAPI(fetchAllReportMethods, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: transcribersData,
    loading: transcribersLoading,
    refetch: updateTranscribers,
  } = useAPI(fetchTranscribers, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const clearStore = () => {
    if (eoStatusesData.length) updateEOStatuses(true);
    if (divisionsData.length) updateDivisions(true);
    if (topoPositionsData.length) updateTopoPositions(true);
    if (aspectsData.length) updateAspects(true);
    if (lightsData.length) updateLights(true);
    if (moistureRegimesData.length) updateMoistureRegimes(true);
    if (surveyorTypesData.length) updateSurveyorTypes(true);
    if (surveyorsData.length) updateSurveyors(true);
    if (topicsData.length) updateTopics(true);
    if (managementTypesData.length) updateManagementTypes(true);
    if (disturbancesData.length) updateDisturbances(true);
    if (ownersData.length) updateOwners(true);
    if (sourceOwnersData.length) updateSourceOwners(true);
    if (speciesNamesData.length) updateSpeciesNames(true);
    if (countiesData.length) updateCounties(true);
    if (locationPrecisionsData.length) updateLocationPrecisions(true);
    if (characterGroupsData.length) updateCharacterGroups(true);
    if (surveyResultGroupsData.length) updateSurveyResult(true);
    if (permitTypesData.length) updatePermitTypes(true);
    if (nonStayOffOwnerPermissionsData.length)
      updateNonStayOffOwnerPermissions(true);
    if (ownerPermissionsData.length) updateOwnerPermissions(true);
    if (contactMethodsData.length) updateContactMethods(true);
    if (surveyResultStatesData.length) updateSurveyResultStates(true);
    if (locationPrecisionsForFieldEnterData.length)
      updateLocationPrecisionsForFieldEnter(true);
    if (slopesData.length) updateSlopes(true);
    if (countPrecisionsData.length) updateCountPrecisions(true);
    if (outreachOrganizationTypesData.length)
      updateOutreachOrganizationTypes(true);
    if (mgtNotesAndEODataSourcesData.length)
      updateMgtNotesAndEODataSources(true);
    if (allMgtFieldDataTypesData.length) updateAllMgtFieldDataTypes(true);
    if (phenologyTypesData.length) updatePhenologyTypes(true);
    if (surveyResultsData.length) updateSurveyResults(true);
    if (sourcesData.length) updateSources(true);
    if (fieldDataTypesData.length) updateFieldDataTypes(true);
    if (allOwnerTypesData.length) updateAllOwnerTypes(true);
    if (allOwnerStatusesData.length) updateAllOwnerStatuses(true);
    if (allReportMethodsData.length) updateAllReportMethods(true);
    if (rolesData.length) updateRoles(true);
    if (transcribersData.length) updateTranscribers(true);
    if (statesForSurveyorData.length) updateStatesForSurveyor(true);
    if (existingLightsData.length) updateExistingLights(true);
    if (existingMoistureRegimesData.length) updateExistingMoistureRegimes(true);
    if (surveyorTypesExceptAdminData.length)
      updateSurveyorTypesExceptAdmin(true);
  };

  useEffect(() => {
    if (securityAgreementAccepted && user) {
      if (!Object.keys(roleTypes).includes(user.role)) clearStore();
    } else {
      clearStore();
    }
  }, [user]);

  return (
    <StoreContext.Provider
      value={{
        statesData,
        statesLoading,
        updateStates,
        daysAvailableData,
        daysAvailableLoading,
        updateDaysAvailable,
        statesForSurveyorData,
        statesForSurveyorLoading,
        updateStatesForSurveyor,
        eoStatusesData,
        eoStatusesLoading,
        updateEOStatuses,
        divisionsData,
        divisionsLoading,
        updateDivisions,
        topoPositionsData,
        topoPositionsLoading,
        updateTopoPositions,
        aspectsData,
        aspectsLoading,
        updateAspects,
        lightsData,
        lightsLoading,
        updateLights,
        moistureRegimesData,
        moistureRegimesLoading,
        updateMoistureRegimes,
        surveyorTypesData,
        surveyorTypesLoading,
        updateSurveyorTypes,
        topicsData,
        topicsLoading,
        updateTopics,
        managementTypesData,
        managementTypesLoading,
        updateManagementTypes,
        disturbancesData,
        disturbancesLoading,
        updateDisturbances,
        surveyorsData,
        surveyorsLoading,
        updateSurveyors,
        ownersData,
        ownersLoading,
        updateOwners,
        sourceOwnersData,
        sourceOwnersLoading,
        updateSourceOwners,
        characterGroupsData,
        characterGroupsLoading,
        updateCharacterGroups,
        speciesNamesData,
        speciesNamesLoading,
        updateSpeciesNames,
        countiesData,
        countiesLoading,
        updateCounties,
        locationPrecisionsData,
        locationPrecisionsLoading,
        updateLocationPrecisions,
        surveyResultGroupsData,
        surveyResultLoading,
        updateSurveyResult,
        permitTypesData,
        permitTypesLoading,
        updatePermitTypes,
        nonStayOffOwnerPermissionsData,
        nonStayOffOwnerPermissionsLoading,
        updateNonStayOffOwnerPermissions,
        ownerPermissionsData,
        ownerPermissionsLoading,
        updateOwnerPermissions,
        contactMethodsData,
        contactMethodsLoading,
        updateContactMethods,
        surveyResultStatesData,
        surveyResultStatesLoading,
        updateSurveyResultStates,
        countPrecisionsData,
        countPrecisionsLoading,
        updateCountPrecisions,
        slopesData,
        slopesLoading,
        updateSlopes,
        locationPrecisionsForFieldEnterData,
        locationPrecisionsForFieldEnterLoading,
        updateLocationPrecisionsForFieldEnter,
        outreachOrganizationTypesData,
        outreachOrganizationTypesLoading,
        updateOutreachOrganizationTypes,
        mgtNotesAndEODataSourcesData,
        mgtNotesAndEODataSourcesLoading,
        updateMgtNotesAndEODataSources,
        allMgtFieldDataTypesData,
        allMgtFieldDataTypesLoading,
        updateAllMgtFieldDataTypes,
        phenologyTypesData,
        phenologyTypesLoading,
        updatePhenologyTypes,
        surveyResultsData,
        surveyResultsLoading,
        updateSurveyResults,
        fieldDataTypesData,
        fieldDataTypesLoading,
        updateFieldDataTypes,
        sourcesData,
        sourcesLoading,
        updateSources,
        allOwnerTypesData,
        allOwnerTypesLoading,
        updateAllOwnerTypes,
        allOwnerStatusesData,
        allOwnerStatusesLoading,
        updateAllOwnerStatuses,
        allReportMethodsData,
        allReportMethodsLoading,
        updateAllReportMethods,
        rolesData,
        rolesLoading,
        updateRoles,
        transcribersData,
        transcribersLoading,
        updateTranscribers,
        existingLightsData,
        existingLightsLoading,
        updateExistingLights,
        existingMoistureRegimesData,
        existingMoistureRegimesLoading,
        updateExistingMoistureRegimes,
        surveyorTypesExceptAdminData,
        surveyorTypesExceptAdminLoading,
        updateSurveyorTypesExceptAdmin,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
