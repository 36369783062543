import React, { useEffect, useState } from 'react';
import classNames from 'clsx';

import { useStore } from '../../../global/stores/store/StoreContext';
import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';

import { searchFormConfig } from './search-form-config';

import classes from '../../styles.module.scss';

const SearchForm = ({ tableLoading }) => {
  const [initialValues, setInitialValues] = useState(null);
  const { statesData, statesLoading, updateStates } = useStore();

  useEffect(() => {
    if (!statesData.length && !statesLoading) updateStates();
  }, []);

  useEffect(() => {
    if (statesData.length) {
      setInitialValues({
        State: [statesData[0].itemKey],
      });
    }
  }, [statesData]);

  return (
    <div className={classNames(classes.searchForm)}>
      {initialValues && (
        <CustomSearchForm
          searchConfig={searchFormConfig}
          initialValues={initialValues}
          loading={statesLoading || tableLoading}
        />
      )}
    </div>
  );
};

export default SearchForm;
