import React, { useState } from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithNestedTable from '../../pages/PageWithNestedTable';

import { columns, expandableColumns } from './TableColumns';

const fetchProgramSignupPaged = () =>
  APIFetch(ENDPOINT_URLS.GET_PROGRAMS_PCVTF, {
    method: 'get',
  });

const fetchProgramSignupDetails = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ATTENDEES(key), {
        method: 'get',
      })
    : [];

const fetchUpdateParticipate = (id, participate) =>
  id
    ? APIFetch(ENDPOINT_URLS.UPDATE_SURVEYOR_PCV_PROGRAM(id, participate), {
        method: 'put',
      })
    : null;

const ProgramSignup = () => {
  const { refetch: refetchUpdateParticipate } = useAPI(
    (id, participate) => fetchUpdateParticipate(id, participate),
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  const [updateTableData, setUpdateTableData] = useState(false);
  const actions = (record) => {
    const { id, participate } = record;

    const label = participate ? 'Leave Program' : 'Sign up for program';

    return (
      <span
        style={{
          marginLeft: 20,
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a
          onClick={() => {
            refetchUpdateParticipate(id, !participate).then(() => {
              setUpdateTableData(!updateTableData);
            });
          }}
        >
          <span>{label}</span>
        </a>
      </span>
    );
  };

  return (
    <PageWithNestedTable
      fetchData={() => fetchProgramSignupPaged()}
      fetchNestedData={(key) => fetchProgramSignupDetails(key)}
      nestedTableKey="id"
      titleKey="PCVTF:ProgramSignup"
      descriptionKey="PCVTF:ProgramSignupDescription"
      columns={columns()}
      expandableColumns={expandableColumns()}
      rowKeyFunc={(record) => record.programName}
      scroll={{ x: 800 }}
      getNestedDataActionText="showParticipants"
      isDataArray
      actions={actions}
      actionsColWidth={270}
      updateTableData={updateTableData}
    />
  );
};

export default ProgramSignup;
