import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetchFieldDataConservationSpeciesNoSeed = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_CONSERVATION_SPECIES_NO_SEED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

export const FieldDataConservationSpeciesNoSeed = () => (
  <PageWithTable
    fetchData={(args) => fetchFieldDataConservationSpeciesNoSeed(args)}
    defaultSortField="SpeciesName"
    titleKey="ElementOccurrence:FieldDataConservationSpeciesNoSeed"
    descriptionKey="ElementOccurrence:FieldDataSeedBySpeciesDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1300 }}
  />
);
