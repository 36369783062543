import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { action } from '../../../../shared/EditableTable/components/Action';
import { actionContent } from '../utils';

import PageWithTable from '../../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetchFieldDataSPROUT = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FILED_DATA_SPROUT, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

export const FieldDataSPROUT = () => {
  const { t } = useTranslation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 30,
      renderFunction: (_, record) =>
        actionContent(
          record,
          '/ElementOccurrence/FieldDataSPROUT/SPROUTFieldForm'
        ),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={(args) => fetchFieldDataSPROUT(args)}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="FieldDataSPROUT:FieldDataSPROUT"
      descriptionKey="FieldDataSPROUT:FieldDataSPROUTDescription"
      searchForm={SearchForm}
      columns={allColumns}
      rowKey={(record) => record.eokey}
      scroll={{ x: 1300 }}
    />
  );
};
