import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_SPECIES_PHENOLOGY_DETAILS, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (key, type, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_SPECIES_PHENOLOGY_DETAILS(key, type), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SPECIES_PHENOLOGY_DETAILS(key), {
        method: 'get',
      })
    : [];

const InformationEditForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key, type } = useParams();
  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const [initialValues, setInitialValues] = useState(null);

  const IS_UPDATE_MODE = type !== 'Create';

  const { phenologyTypesData, updatePhenologyTypes } = useStore();

  useEffect(() => {
    if (!phenologyTypesData.length) updatePhenologyTypes();
  }, []);

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI((args) => fetchCreate(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(key, type, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: parentChildrenData } = useAPI(() => fetch(key), {
    initialState: { loading: false },
    shouldFetch: IS_UPDATE_MODE,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: t(
          `Species:Information${IS_UPDATE_MODE ? 'Edit' : 'Create'}Form`
        ),
      },
    });
  }, []);

  useEffect(() => {
    if (parentChildrenData?.length) {
      const details = parentChildrenData.find(
        (item) => item.phenologyType === type
      );

      setInitialValues(details);
    }
  }, [parentChildrenData]);

  useEffect(() => {
    if (phenologyTypesData.length && !IS_UPDATE_MODE) {
      setInitialValues({
        phenologyType: phenologyTypesData[0].itemKey,
      });
    }
  }, [phenologyTypesData]);

  const onFinish = (values) => {
    if (IS_UPDATE_MODE) {
      const { firstDate, lastDate } = values;

      refetchUpdate({ firstDate, lastDate });
    } else {
      refetchCreate({
        speciesKey: +key,
        ...values,
      });
    }
  };

  const onClickCancel = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (updateData || createData) {
      let messagetype = '';
      let message = '';

      if (updateData) {
        if (updateData.speciesKey) {
          messagetype = 'success';
          message = 'Data was updated successfully';
        } else {
          messagetype = 'error';
          message = 'Failed update Data';
        }
      } else if (createData?.speciesKey) {
        messagetype = 'success';
        message = 'Data was created successfully';
      } else {
        messagetype = 'error';
        message = 'Failed create Data';
      }

      openNotificationWithIcon(messagetype, message);

      if (messagetype === 'success') {
        let parentPathname = pathname.split('/');
        parentPathname = parentPathname
          .slice(0, parentPathname.length - 1)
          .join('/');

        history.replace(parentPathname);
      }
    }
  }, [createData, updateData]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`Species:Information${IS_UPDATE_MODE ? 'Edit' : 'Create'}Form`)}
      </Title>
      <p className={classNames(classes.title)}>
        {t('Species:InformationEditFormDescription')}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="speciesInformationEditForm"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  disabled={IS_UPDATE_MODE && name === 'phenologyType'}
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:clear')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default InformationEditForm;
