import React from 'react';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { edit } from '../../../shared/EditableTable/tableUtils';

export const actionContent = (
  save,
  editable,
  record,
  cancel,
  editingKey,
  form,
  initialState,
  setEditingKey
) => {
  const { t } = useTranslation();

  return editable ? (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        onClick={() => save(record.eokey)}
        style={{
          marginRight: 8,
        }}
      >
        {t('table:update')}
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a onClick={() => cancel()}>{t('table:cancel')}</a>
    </span>
  ) : (
    <Space size="middle">
      <Typography.Link
        disabled={editingKey !== ''}
        onClick={() => {
          edit(record, initialState, form, setEditingKey);
        }}
      >
        {t('table:edit')}
      </Typography.Link>
    </Space>
  );
};
