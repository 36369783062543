import React from 'react';

import { editCell } from '../../tableUtils';

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  cellsConfig,
  ...restProps
}) => (
  <td {...restProps}>
    {editing ? editCell(inputType, dataIndex, cellsConfig, record) : children}
  </td>
);
