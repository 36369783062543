import React from 'react';

import StatesForSurveyorSelect from '../../../shared/components/select/StatesForSurveyorSelect';
import SelectWithUserName from '../../../shared/components/select/SelectWithUserName';
import CountiesAutocomplete from '../../../shared/components/autocomplete/CountiesAutocomplete';

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'EO species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'EoTown',
    label: 'EO town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'EoSitename',
    label: 'EO sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SurveourName',
    label: 'Surveyor Name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'CountyKey',
    label: 'County',
    input: (props) => <CountiesAutocomplete {...props} />,
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Equals',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesForSurveyorSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const editableCellsConfig = [
  {
    name: 'leadSurvName',
    input: (props) => <SelectWithUserName {...props} />,
  },
  {
    name: 'surv2Name',
    input: (props) => <SelectWithUserName {...props} />,
  },
  {
    name: 'surv3Name',
    input: (props) => <SelectWithUserName {...props} />,
  },
  {
    name: 'surv4Name',
    input: (props) => <SelectWithUserName {...props} />,
  },
];

export const beSearchConfig = [
  {
    propertyName: 'SpeciesName',
  },
  {
    propertyName: 'State',
  },
  {
    propertyName: 'EoTown',
  },
  {
    propertyName: 'EoSitename',
  },
  {
    propertyName: 'SurveourName',
  },
  {
    propertyName: 'CountyKey',
  },
];
