import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = ['ownerNotes', 'history'];

const OwnerNotesAndHistory = ({ data }) => (
  <ContainerWithSimpleTable
    data={data}
    loading={false}
    fields={fields}
    rowKey={uuidv4()}
    scroll={false}
    fullWidth
  />
);

export default OwnerNotesAndHistory;
