import { createPredicate, createValidationPredicate } from './utils';
import { useAuth } from '../../global/stores/auth';
import { roleTypes } from '../../utils/roles';

export const sectionsConfig = () => {
  const { user } = useAuth();
  const role = user?.role;

  const elementOccurrenceDependsOnRole = () => {
    switch (role) {
      case roleTypes.Transcriber:
        return [
          {
            tableTitle:
              'sections:ElementOccurrence:PriorYearFieldDataEntry:title',
            rowsCount: 2,
            createContent: (index) =>
              createPredicate(
                'sections:ElementOccurrence:PriorYearFieldDataEntry',
                index
              ),
          },
        ];

      case roleTypes.Heritage:
        return [
          {
            tableTitle:
              'sections:ElementOccurrence:ElementOccurrenceData:title',
            rowsCount: 8,
            createContent: (index) =>
              createPredicate(
                'sections:ElementOccurrence:ElementOccurrenceDataHeritage',
                index
              ),
          },
          {
            tableTitle: 'sections:ElementOccurrence:FieldDataHeritage:title',
            rowsCount: 3,
            createContent: (index) =>
              createPredicate('sections:ElementOccurrence:FieldDataHeritage', index),
          },
          {
            tableTitle: 'sections:ElementOccurrence:SeedBankData:title',
            rowsCount: 4,
            createContent: (index) =>
              createPredicate('sections:ElementOccurrence:SeedBankData', index),
          },
          {
            tableTitle:
              'sections:ElementOccurrence:HerbariumRecoveryProject:title',
            rowsCount: 1,
            createContent: (index) =>
              createPredicate(
                'sections:ElementOccurrence:HerbariumRecoveryProject',
                index
              ),
          },
        ];
      default:
        return [
          {
            tableTitle:
              'sections:ElementOccurrence:ElementOccurrenceData:title',
            rowsCount: 8,
            createContent: (index) =>
              createPredicate(
                'sections:ElementOccurrence:ElementOccurrenceData',
                index
              ),
          },
          {
            tableTitle: 'sections:ElementOccurrence:FieldData:title',
            rowsCount: 4,
            createContent: (index) =>
              createPredicate('sections:ElementOccurrence:FieldData', index),
          },
          {
            tableTitle: 'sections:ElementOccurrence:SeedBankData:title',
            rowsCount: 4,
            createContent: (index) =>
              createPredicate('sections:ElementOccurrence:SeedBankData', index),
          },
          {
            tableTitle:
              'sections:ElementOccurrence:PriorYearFieldDataEntry:title',
            rowsCount: 2,
            createContent: (index) =>
              createPredicate(
                'sections:ElementOccurrence:PriorYearFieldDataEntry',
                index
              ),
          },
          {
            tableTitle:
              'sections:ElementOccurrence:HerbariumRecoveryProject:title',
            rowsCount: 1,
            createContent: (index) =>
              createPredicate(
                'sections:ElementOccurrence:HerbariumRecoveryProject',
                index
              ),
          },
        ];
    }
  };

  const PCVDependsOnRole =
    role === roleTypes.PCV
      ? [
          {
            tableTitle: 'sections:PCV:BackgroundInformation:title',
            rowsCount: 5,
            createContent: (index) =>
              createValidationPredicate(
                'sections:PCV:BackgroundInformation',
                index
              ),
          },
          {
            tableTitle: 'sections:PCV:SurveyInformation:title',
            rowsCount: 5,
            createContent: (index) =>
              createValidationPredicate(
                'sections:PCV:SurveyInformation',
                index
              ),
          },
          {
            tableTitle: 'sections:PCV:FieldSubmission:title',
            rowsCount: 5,
            createContent: (index) =>
              createValidationPredicate('sections:PCV:FieldSubmission', index),
          },
        ]
      : [
          {
            tableTitle: 'sections:PCV:BackgroundInformation:title',
            rowsCount: 5,
            createContent: (index) =>
              createValidationPredicate(
                'sections:PCV:BackgroundInformation',
                index
              ),
          },
          {
            tableTitle: 'sections:PCV:SurveyInformation:title',
            rowsCount: 5,
            createContent: (index) =>
              createValidationPredicate(
                'sections:PCV:SurveyInformation',
                index
              ),
          },
          {
            tableTitle: 'sections:PCV:FieldSubmission:title',
            rowsCount: 5,
            createContent: (index) =>
              createValidationPredicate('sections:PCV:FieldSubmission', index),
          },
          {
            tableTitle: 'sections:PCV:TaskForceOnly:title',
            rowsCount: 2,
            createContent: (index) =>
              createValidationPredicate('sections:PCV:TaskForceOnly', index),
          },
        ];

  const SpeciesDataDependsOnRole = () => {
    let result = [];

    switch (role) {
      case roleTypes.PCV:
      case roleTypes.TF:
        result = [
          {
            tableTitle:
              'sections:Species:TracheophyteChecklistCoreSpeciesDataPCVandTF:title',
            rowsCount: 5,
            createContent: (index) =>
              createPredicate(
                'sections:Species:TracheophyteChecklistCoreSpeciesDataPCVandTF',
                index
              ),
          },
        ];
        break;
      case roleTypes.Administrator:
        result = [
          {
            tableTitle:
              'sections:Species:TracheophyteChecklistCoreSpeciesDataAll:title',
            rowsCount: 6,
            createContent: (index) =>
              createPredicate(
                'sections:Species:TracheophyteChecklistCoreSpeciesDataAll',
                index
              ),
          },
          {
            tableTitle: 'sections:Species:SpeciesStatusResearchData:title',
            rowsCount: 5,
            createContent: (index) =>
              createPredicate(
                'sections:Species:SpeciesStatusResearchData',
                index
              ),
          },
          {
            tableTitle: 'sections:Species:GoBotanyData:title',
            rowsCount: 3,
            createContent: (index) =>
              createPredicate('sections:Species:GoBotanyData', index),
          },
        ];
        break;
      case roleTypes.Heritage:
        result = [
          {
            tableTitle:
                'sections:Species:TracheophyteChecklistCoreSpeciesDataHeritage:title',
            rowsCount: 5,
            createContent: (index) =>
              createPredicate(
                'sections:Species:TracheophyteChecklistCoreSpeciesDataHeritage',
                index
              ),
          },
          {
            tableTitle: 'sections:Species:SpeciesStatusResearchData:title',
            rowsCount: 5,
            createContent: (index) =>
              createPredicate(
                'sections:Species:SpeciesStatusResearchData',
                index
              ),
          },
          {
            tableTitle: 'sections:Species:GoBotanyData:title',
            rowsCount: 3,
            createContent: (index) =>
              createPredicate('sections:Species:GoBotanyData', index),
          },
        ];
        break;
      case roleTypes.Transcriber:
        result = [
          {
            tableTitle:
              'sections:Species:TracheophyteChecklistCoreSpeciesDataTranscriber:title',
            rowsCount: 2,
            createContent: (index) =>
              createPredicate(
                'sections:Species:TracheophyteChecklistCoreSpeciesDataTranscriber',
                index
              ),
          },
        ];
        break;
      default:
        result = [];
    }
    return result;
  };

  const OwnersDependsOnRole = () => {
    switch (role) {
      case roleTypes.Heritage:
        return [
          {
            tableTitle: 'sections:Owners:table:title',
            rowsCount: 4,
            createContent: (index) =>
              createPredicate('sections:Owners:tableHeritage', index),
          },
        ]
      default:
        return [
          {
            tableTitle: 'sections:Owners:table:title',
            rowsCount: 6,
            createContent: (index) =>
              createPredicate('sections:Owners:table', index),
          },
        ]
    }
  };

  const SurveyorsDependsOnRole = () => {
    switch (role) {
      case roleTypes.Heritage:
        return [
          {
            tableTitle: 'sections:Surveyors:tableHaritage:title',
            rowsCount: 3,
            createContent: (index) =>
              createPredicate('sections:Surveyors:tableHaritage', index),
          },
        ]
      default:
        return [
          {
            tableTitle: 'sections:Surveyors:table:title',
            rowsCount: 5,
            createContent: (index) =>
              createPredicate('sections:Surveyors:table', index),
          },
        ]
    }
  };
  
  const DistributionDataDependsOnRole = () => {
    switch (role) {
      case roleTypes.Heritage:
        return {
          tableTitle: 'sections:PlantsStatus:DistributionDataHeritage:title',
          rowsCount: 6,
          createContent: (index) =>
            createValidationPredicate(
              'sections:PlantsStatus:DistributionDataHeritage',
              index
            ),
        }
      default:
        return {
          tableTitle: 'sections:PlantsStatus:DistributionData:title',
          rowsCount: 9,
          createContent: (index) =>
            createValidationPredicate(
              'sections:PlantsStatus:DistributionData',
              index
            ),
        }
    }
  }

  const PopulationConditionDependsOnRole = () => {
    switch (role) {
      case roleTypes.Heritage:
        return {
          tableTitle: 'sections:PlantsStatus:PopulationConditionHeritage:title',
          rowsCount: 2,
          createContent: (index) =>
            createValidationPredicate(
              'sections:PlantsStatus:PopulationConditionHeritage',
              index
            ),
        }
      default:
        return {
          tableTitle: 'sections:PlantsStatus:PopulationCondition:title',
          rowsCount: 3,
          createContent: (index) =>
            createValidationPredicate(
              'sections:PlantsStatus:PopulationCondition',
              index
            ),
        }
    }
  }
  return {
    elementOccurrenceStatus: [
      {
        tableTitle: 'ElementOccurrenceStatus:title',
        rowsCount: 10,
        createContent: (index) =>
          createPredicate('ElementOccurrenceStatus', index),
      },
    ],
    surveyPlanStatus: [
      {
        tableTitle: 'SurveyPlanStatus:title',
        rowsCount: 4,
        createContent: (index) => createPredicate('SurveyPlanStatus', index),
      },
    ],
    speciesStatus: [
      {
        tableTitle: 'SpeciesStatus:title',
        rowsCount: 6,
        createContent: (index) => createPredicate('SpeciesStatus', index),
      },
    ],
    ownerStatus: [
      {
        tableTitle: 'OwnerStatus:title',
        rowsCount: 3,
        createContent: (index) => createPredicate('OwnerStatus', index),
      },
    ],
    statusSummary: [
      {
        tableTitle: 'StatusSummary:title',
        rowsCount: 8,
        createContent: (index) => createPredicate('StatusSummary', index),
      },
    ],
    surveyorStatus: [
      {
        tableTitle: 'SurveyorStatus:title',
        rowsCount: 2,
        createContent: (index) => createPredicate('SurveyorStatus', index),
      },
    ],
    elementOccurrence: elementOccurrenceDependsOnRole(),
    species: SpeciesDataDependsOnRole(),
    owners: OwnersDependsOnRole(),
    surveyors: SurveyorsDependsOnRole(),
    surveyStatus: [
      {
        tableTitle: 'SurveyStatus:title',
        rowsCount: 10,
        createContent: (index) => createPredicate('SurveyStatus', index),
      },
    ],
    validation: [
      {
        extra: 'sections:Validation:extra',
        rowsCount: 30,
        createContent: (index) =>
          createValidationPredicate('sections:Validation', index),
      },
    ],
    PCV: PCVDependsOnRole,
    PCVAdmin: [
      {
        tableTitle: 'sections:PCVAdmin:SurveyAdministration:title',
        rowsCount: 10,
        createContent: (index) =>
          createValidationPredicate(
            'sections:PCVAdmin:SurveyAdministration',
            index
          ),
      },
      {
        tableTitle: 'sections:PCVAdmin:FieldFormReview:title',
        rowsCount: 11,
        createContent: (index) =>
          createValidationPredicate('sections:PCVAdmin:FieldFormReview', index),
      },
      {
        tableTitle: 'sections:PCVAdmin:FileEmailManagement:title',
        rowsCount: 2,
        createContent: (index) =>
          createValidationPredicate(
            'sections:PCVAdmin:FileEmailManagement',
            index
          ),
      },
      {
        tableTitle: 'sections:PCVAdmin:BackgroundActivities:title',
        rowsCount: 7,
        createContent: (index) =>
          createValidationPredicate(
            'sections:PCVAdmin:BackgroundActivities',
            index
          ),
      },
    ],
    surveyPlanQA: [
      {
        tableTitle: 'SurveyPlanQA:title',
        rowsCount: 10,
        createContent: (index) => createPredicate('SurveyPlanQA', index),
      },
    ],
    plantsStatus: [
      DistributionDataDependsOnRole(),
      {
        tableTitle: 'sections:PlantsStatus:EOPS:title',
        rowsCount: 2,
        createContent: (index) =>
          createValidationPredicate('sections:PlantsStatus:EOPS', index),
      },
      {
        tableTitle:
          'sections:PlantsStatus:NaturalCommunitiesAndAssociatedSpecies:title',
        rowsCount: 6,
        createContent: (index) =>
          createValidationPredicate(
            'sections:PlantsStatus:NaturalCommunitiesAndAssociatedSpecies',
            index
          ),
      },
      PopulationConditionDependsOnRole(),
      {
        tableTitle: 'sections:PlantsStatus:Summary:title',
        rowsCount: 1,
        createContent: (index) =>
          createValidationPredicate('sections:PlantsStatus:Summary', index),
      },
    ],
  };
};
