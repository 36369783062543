import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { action } from '../../../shared/EditableTable/components/Action';
import PageWithNestedTable from '../../pages/PageWithNestedTable';
import { paramsForBE } from '../../../utils/general';

import { actionContent } from './utils';
import { columns, expandableColumns } from './TableColumns';
import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';

const bodyForFirstRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: [
    {
      orderOption: 'Ascending',
      propertyName: 'SurveyDate',
    },
  ],
};

const fetchHistorySPROUTdata = (args) =>
  APIFetch(ENDPOINT_URLS.GET_HISTORY_SPROUT_PAGED_PCVTF, {
    method: 'post',
    body: JSON.stringify(args || bodyForFirstRequest),
  });

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_HISTORY_SPROUT_PAGED_PCVTF_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchHistorySPROUTDetails = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_HISTORY_SPROUT_DETAILS_PCVTF(key), {
        method: 'get',
      })
    : [];

const fetchAttachments = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ASSOCIATED_FILES(key), {
        method: 'get',
      })
    : [];

const PCVTFHistorySPROUT = () => {
  const { t } = useTranslation();

  const staticColumns = expandableColumns();
  const allExpandableColumns = (handleClick) => ([
    action({
      title: t('table:pdf'),
      key: 'operation',
      width: 65,
      renderFunction: (_, record) => actionContent(record),
    }),
    {
      title: t('table:attachments'),
      key: 'operation',
      width: 120,
      render: (_, record) => (
        <>
          <span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a onClick={() => handleClick(record)}>
              {t(`table:select_attachments`)}
            </a>
          </span>
        </>
      ),
    },
    ...staticColumns,
  ]);

  const expandableSubColumns = [{
    title: t('table:attachments'),
    dataIndex: 'fullFilename',
    key: 'fullFilename',
    render: (text, record) => {
      const { fileLink } = record || {};
      return text ? <a href={fileLink}>{text}</a> : '';
    },
  }]

  return (
    <PageWithNestedTable
      fetchData={(args) => fetchHistorySPROUTdata(args)}
      fetchExport={fetchExport}
      fetchNestedData={(key) => fetchHistorySPROUTDetails(key)}
      fetchSubNestedData={(key) => fetchAttachments(key)}
      defaultSortField="SurveyDate"
      nestedTableKey="ffkey"
      titleKey="PCVTF:HistorySPROUT"
      descriptionKey="PCVTF:HistorySPROUTDescription"
      searchForm={SearchForm}
      columns={columns()}
      expandableColumns={allExpandableColumns}
      expandableSubColumns={expandableSubColumns}
      rowKeyFunc={(record) => record.ffkey}
      scroll={{ x: 800 }}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
    />
  );
};

export default PCVTFHistorySPROUT;
