import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:scientificName'),
      dataIndex: 'scientificName',
      key: 'scientificName',
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      sorter: true,
    },
    {
      title: t('table:native'),
      dataIndex: 'native',
      key: 'native',
      sorter: true,
    },
  ];
};
