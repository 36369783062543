import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Autocomplete } from './Autocomplete';

const CountiesAutocomplete = (props) => {
  const { countiesData, countiesLoading, updateCounties } = useStore();

  useEffect(() => {
    if (!countiesData.length && !countiesLoading) updateCounties();
  }, []);

  return (
    <Autocomplete
      props={props}
      data={countiesData}
      loading={countiesLoading}
      width={200}
    />
  );
};

export default CountiesAutocomplete;
