import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const PhenologyTypesSelect = (props) => {
  const { phenologyTypesData, phenologyTypesLoading, updatePhenologyTypes } =
    useStore();

  useEffect(() => {
    if (!phenologyTypesData.length && !phenologyTypesLoading)
      updatePhenologyTypes();
  }, []);

  return (
    <Select
      width={120}
      props={props}
      data={phenologyTypesData}
      loading={phenologyTypesLoading}
    />
  );
};

export default PhenologyTypesSelect;
