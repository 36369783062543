import React from 'react';
import { Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getMomentDate } from '../../../utils/general'

import classes from './styles.module.scss';

export const SimpleTable = ({
  data,
  fields,
  fullWidth,
  tableColumns,
  actionColumn,
  tableInfo,
  formatWithYear,
  ...params
}) => {
  const { t } = useTranslation();

  const date = (text) =>
    formatWithYear
      ? getMomentDate(text).format('YYYY-MM-DD')
      : getMomentDate(text).format('MM-DD');
  const columns = tableColumns
    ? tableColumns()
    : fields.map((field) => {
        const [key, details] = field.split('#');

        return {
          title: t(`table:${key}`),
          dataIndex: key,
          key,
          render: (text) => (details === 'date' && text ? date(text) : text),
        };
      });
  const allColumns = actionColumn ? [actionColumn, ...columns] : columns;

  return (
    <div className={classNames(classes.tableContainer)}>
      {tableInfo && (
        <div className={classNames(classes.tableInfo)}>
          <Tooltip
            placement="bottomRight"
            title={
              <div className={classNames(classes.tableInfoContainer)}>
                {tableInfo}
              </div>
            }
            overlayStyle={{ width: 300, maxWidth: 300 }}
            overlayInnerStyle={{ width: 300 }}
          >
            <InfoCircleOutlined className={classNames(classes.tableInfoIcon)} />
          </Tooltip>
        </div>
      )}

      <Table
        {...params}
        className={classNames(
          classes.table,
          fullWidth ? classes.tableFullWidth : ''
        )}
        columns={allColumns}
        dataSource={data}
      />
    </div>
  );
};
