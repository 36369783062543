import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { action } from '../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../pages/PageWithEditableTable';
import {
  openNotificationWithIcon,
  paramsForBE,
  getFormData,
  getMomentDate
} from '../../../utils/general';

import SearchForm from './SearchForm';
import { beSearchConfig, editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const fetchPermit = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PERMITS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const Permit = () => {
  const { t } = useTranslation();
  const { search: searchParams } = useLocation();
  const [form] = Form.useForm();

  const { data, loading, refetch } = useAPI((args) => fetchPermit(args), {
    initialState: { data: {}, loading: false, shouldFetch: false },
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [searchParameters, setSearchParameters] = useState({});
  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  const cancel = () => {
    setEditingKey('');
  };

  const defaultSortFields = [
    'LastName',
    'FirstName',
    'Organization',
    'SubOrganization',
  ];

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;

    refetch(
      paramsToPassInBodyRequest(parameters, searchParam, defaultSortFields)
    );

    setIsRefetchDone(true);

    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();
      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);
    if (searchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          paramsForBE
            ? paramsForBE(searchParamsArray, beSearchConfig)
            : searchParamsArray,
          defaultSortFields
        )
      );
      setSearchParameters(searchParamsArray);
    }
  }, [searchParams]);

  const initialState = {
    permitTypeRequested: '',
    datePermitRequestSubmitted: '',
    datePermitRequestAcknowledged: '',
    datePermitResponseRecd: '',
    permitResponse: '',
    permitStartDate: '',
    permitEndDate: '',
    permitDocument1: '',
    permitDocument2: '',
    permitComment: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();

    const {
      datePermitRequestSubmitted,
      datePermitRequestAcknowledged,
      datePermitResponseRecd,
      permitEndDate,
      permitStartDate,
      permitDocument1,
      permitDocument2,
    } = row;

    form.setFields(
      Object.keys(row).map((name) => ({
        name,
        errors: null,
      }))
    );

    const datePermitRequestSubmittedValue = moment
      .utc(datePermitRequestSubmitted)
      .format('YYYY-MM-DD');
    const datePermitRequestAcknowledgedValue = moment
      .utc(datePermitRequestAcknowledged)
      .format('YYYY-MM-DD');
    const datePermitResponseRecdValue = moment
      .utc(datePermitResponseRecd)
      .format('YYYY-MM-DD');
    const permitStartDateValue = moment
      .utc(permitStartDate)
      .format('YYYY-MM-DD');
    const permitEndDateValue = getMomentDate(permitEndDate).format('YYYY-MM-DD');

    const validationSubmittedAcknowledged = moment(
      datePermitRequestAcknowledgedValue
    ).isBefore(datePermitRequestSubmittedValue);
    const validationEndStart =
      moment(permitEndDateValue).isBefore(permitStartDateValue);
    const validationRecdAcknowledged = moment(
      datePermitResponseRecdValue
    ).isBefore(datePermitRequestAcknowledgedValue);

    const errorSubmittedAcknowledged = {
      name: 'datePermitRequestAcknowledged',
      errors: [
        'Date should be equal or greater then DatePermitRequestSubmitted',
      ],
    };
    const errorRecdAcknowledged = {
      name: 'datePermitResponseRecd',
      errors: [
        'Date should be equal or greater then Date Permit Request Acknowledged',
      ],
    };
    const errorEndStart = {
      name: 'permitEndDate',
      errors: ['Date should be equal or greater then Permit Start Date'],
    };

    if (
      validationSubmittedAcknowledged &&
      !validationRecdAcknowledged &&
      !validationEndStart
    ) {
      form.setFields([errorSubmittedAcknowledged]);
    } else if (
      validationRecdAcknowledged &&
      !validationSubmittedAcknowledged &&
      !validationEndStart
    ) {
      form.setFields([errorRecdAcknowledged]);
    } else if (
      validationEndStart &&
      !validationSubmittedAcknowledged &&
      !validationRecdAcknowledged
    ) {
      form.setFields([errorEndStart]);
    } else if (
      validationRecdAcknowledged &&
      validationSubmittedAcknowledged &&
      !validationEndStart
    ) {
      form.setFields([errorSubmittedAcknowledged, errorRecdAcknowledged]);
    } else if (
      validationRecdAcknowledged &&
      !validationSubmittedAcknowledged &&
      validationEndStart
    ) {
      form.setFields([errorRecdAcknowledged, errorEndStart]);
    } else if (
      validationEndStart &&
      validationSubmittedAcknowledged &&
      !validationRecdAcknowledged
    ) {
      form.setFields([errorRecdAcknowledged, errorSubmittedAcknowledged]);
    } else if (
      validationEndStart &&
      validationSubmittedAcknowledged &&
      validationRecdAcknowledged
    ) {
      form.setFields([
        errorEndStart,
        errorRecdAcknowledged,
        errorSubmittedAcknowledged,
      ]);
    } else {
      if (permitDocument1?.file) {
        const { status, originFileObj } = permitDocument1.file;

        if (status !== 'removed') {
          row.permitDocumentFile1 = originFileObj || permitDocument1.file;
        }
      }
      if (permitDocument2?.file) {
        const { status, originFileObj } = permitDocument2.file;

        if (status !== 'removed') {
          row.permitDocumentFile2 = originFileObj || permitDocument2.file;
        }
      }

      const {
        permitDocument1: initialPermitDocument1,
        permitDocument2: initialPermitDocument2,
        permitDocumentBlob1: initialPermitDocumentBlob1,
        permitDocumentBlob2: initialPermitDocumentBlob2,
        permitDocumentLink1: initialPermitDocumentLink1,
        permitDocumentLink2: initialPermitDocumentLink2,
      } = tableData.find(({ ownerKey }) => ownerKey === key);

      delete row.permitDocument1;
      delete row.permitDocument2;

      if (permitDocument1?.file?.status !== 'removed') {
        row.permitDocument1 = initialPermitDocument1;
        row.permitDocumentBlob1 = initialPermitDocumentBlob1;
        row.permitDocumentLink1 = initialPermitDocumentLink1;
      }

      if (permitDocument2?.file?.status !== 'removed') {
        row.permitDocument2 = initialPermitDocument2;
        row.permitDocumentBlob2 = initialPermitDocumentBlob2;
        row.permitDocumentLink2 = initialPermitDocumentLink2;
      }

      row.datePermitRequestSubmitted = datePermitRequestSubmitted
        ? datePermitRequestSubmittedValue
        : null;
      row.datePermitRequestAcknowledged = datePermitRequestAcknowledged
        ? datePermitRequestAcknowledgedValue
        : null;
      row.datePermitResponseRecd = datePermitResponseRecd
        ? datePermitResponseRecdValue
        : null;
      row.permitEndDate = permitEndDate ? permitEndDateValue : null;
      row.permitStartDate = permitStartDate ? permitStartDateValue : null;

      const formData = getFormData(row);

      APIFetch(ENDPOINT_URLS.UPDATE_PERMIT(key), {
        method: 'put',
        initialHeaders: {},
        body: formData,
      }).then((res) => {
        if (res.ownerKey) {
          const newData = [...tableData];
          const index = newData.findIndex((item) => key === item.ownerKey);

          if (index > -1) {
            newData.splice(index, 1, res);
          } else {
            newData.push(row);
          }

          setData(newData);
          setEditingKey('');

          openNotificationWithIcon('success', 'Data was updated successfully');
        }
      });
    }
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 160,
      renderFunction: (record) => {
        const { ownerKey } = record;
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone,
          `${ownerKey}`,
          (rec) => `${rec.ownerKey}`
        );

        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey,
          '/PCVAdmin/Permit/EnterPermission'
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        const { ownerKey } = record;

        return {
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isCellEditing(
            record,
            editingKey,
            null,
            null,
            null,
            `${ownerKey}`
          ),
        };
      },
    };
  });

  return (
    <PageWithEditableTable
      titleKey="PCVAdministration:Permit"
      descriptionKey="PCVAdministration:PermitDescription"
      searchForm={SearchForm}
      columns={mergedColumns}
      rowKeyFunc={(record) => record.ownerKey}
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
      scroll={{ x: 2000 }}
    />
  );
};

export default Permit;
