import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { action } from '../../../shared/EditableTable/components/Action';
import { paramsForBE } from '../../../utils/general';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetchFieldFormEnterPrior = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_FORM_ENTER_PCV_ADMIN, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const FieldFormEnterPrior = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, { eokey }) => (
        <Space size="middle">
          <Link
            to={{
              pathname: `${pathname}/${eokey}`,
            }}
          >
            {t('table:select')}
          </Link>
        </Space>
      ),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={(args) => fetchFieldFormEnterPrior(args)}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="PCVAdministration:FieldFormEnterPrior:title"
      descriptionKey="PCVAdministration:FieldFormEnterPrior:description"
      searchForm={SearchForm}
      paramsForBE={paramsForBE}
      beSearchConfig={beSearchConfig}
      columns={allColumns}
      rowKey={(record) => record.eokey}
      scroll={{ x: 1300 }}
    />
  );
};

export default FieldFormEnterPrior;
