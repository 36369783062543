import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t('table:role'),
      dataIndex: 'role',
      key: 'role',
      sorter: true,
    },
    {
      title: t('table:agreementAcceptanceDate'),
      dataIndex: 'agreementAcceptanceDate',
      key: 'agreementAcceptanceDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
  ];
};
