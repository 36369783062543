import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { action } from '../../../../shared/EditableTable/components/Action';
import { paramsForBE } from '../../../../utils/general';
import { actionContent } from '../utils';
import PageWithTable from '../../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_STATE_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const SurveyorState = () => {
  const { t } = useTranslation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 60,
      renderFunction: (_, record) => actionContent(record),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={(args) => fetch(args)}
      defaultSortField={['LastName', 'FirstName']}
      titleKey="Surveyors:SurveyorState"
      descriptionKey="Surveyors:SurveyorStateDescription"
      searchForm={SearchForm}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
      columns={allColumns}
      scroll={{ x: 800 }}
      rowKey={(record) => record.surveyorKey}
    />
  );
};

export default SurveyorState;
