import React from 'react';

import StatesSelect from '../../../shared/components/select/StatesSelect';
import TranscribersSelect from '../../../shared/components/select/TranscribersSelect';
import { Select } from '../../../shared/components/select';
import { DATA_YES_NO_ALL } from '../../../global/constants';

export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'EO Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'Transcriber',
    label: 'Transcriber',
    input: (props) => <TranscribersSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'TranscriptionDone',
    label: 'Transcription Done',
    input: (props) => (
      <Select props={props} width={70} data={DATA_YES_NO_ALL} />
    ),
    operatortype: 'Equals',
  },
];

export const subFormConfig = [
  {
    name: 'Transcriber',
    label: 'Transcriber',
    input: (props) => <TranscribersSelect {...props} />,
    operatortype: 'Equals',
  },
];
