import React from 'react';
import { Tabs, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { useLocation } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import PageContent from '../../../shared/components/PageContent';
import { getIsForbidden } from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';

import EmailForm from './EmailForm';

import classes from '../../styles.module.scss';

const { TabPane } = Tabs;

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_ALL_EMAILS, {
    method: 'get',
  });

const HIDDEN_FORMS = [
  'NewUserAdminNotification',
  'FieldFormAccepted',
  'SurveyorFirstFlower',
  'SurveyorLastFlower',
  'SurveyorLastSeed',
  'NewAccountEmail',
];

const EmailManagement = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading } = useAPI(fetch, {
    initialState: { data: [], loading: false },
    shouldFetch: !isForbidden,
  });

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdmin:EmailManagement')}
      </Title>

      <div className={classNames(classes.title)}>
        {loading ? (
          <Spin />
        ) : (
          <Tabs>
            {data
              .sort(({ typeOfEmail }) => (typeOfEmail !== 'Signature' ? -1 : 1))
              .filter(({ typeOfEmail }) => !HIDDEN_FORMS.includes(typeOfEmail))
              .map((item) => {
                const { typeOfEmail } = item;

                return (
                  <TabPane tab={t(`PCVAdmin:${typeOfEmail}`)} key={typeOfEmail}>
                    <EmailForm {...item} />
                  </TabPane>
                );
              })}
          </Tabs>
        )}
      </div>
    </PageContent>
  );
};

export default EmailManagement;
