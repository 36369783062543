import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Spin, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageContent from '../../../../shared/components/PageContent';

import OwnerSurveyInformation from './OwnerSurveyInformation';
import PhenologySurveyInformation from './PhenologySurveyInformation';
import SpeciesPhenologyNotesConservationStatus from './SpeciesPhenologyNotesConservationStatus';
import LookalikeSpecies from './LookalikeSpecies';
import ResearchQuestions from './ResearchQuestions';
import NearbySpecies from './NearbySpecies';
import OwnerNotesAndHistory from './OwnerNotesAndHistory';

import classes from '../../../styles.module.scss';

const { TabPane } = Tabs;

const TABS_KEYS = {
  ownerNotesAndHistory: 'ownerNotesAndHistory',
  ownerSurveyInformation: 'ownerSurveyInformation',
  phenologySurveyInformation: 'phenologySurveyInformation',
  speciesPhenologyNotesConservationStatus:
    'speciesPhenologyNotesConservationStatus',
  lookalikeSpecies: 'lookalikeSpecies',
  researchQuestions: 'researchQuestions',
  nearbySpecies: 'nearbySpecies',
};

const fetchDetails = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYOR_ASSIGNMENT_DETAILS(key), {
        method: 'get',
      })
    : {};

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYOR_ASSIGNMENT, {
        method: 'post',
        body: JSON.stringify({
          orderCriteria: [
            { orderOption: 'Ascending', propertyName: 'SpeciesName' },
          ],
          pagedInfo: { pageSize: 20, pageNumber: 1 },
          searchCriteria: [
            { operatortype: 'Equals', propertyName: 'Eokey', value: key },
          ],
        }),
      })
    : {};

const SurveyorAssignmentDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { key } = useParams();

  const { data, loading } = useAPI(() => fetchDetails(key), {
    initialState: { data: {}, loading: false },
  });

  const { data: parentData } = useAPI(() => fetch(key), {
    initialState: { loading: false },
  });

  useEffect(() => {
    if (parentData?.items?.length) {
      const {
        state: itemState,
        speciesName,
        eonumber,
        subpopulation,
      } = parentData.items[0];

      history.replace({
        state: {
          breadcrumbsName: `${itemState} ${speciesName} ${eonumber} ${subpopulation}`,
        },
      });
    }
  }, [parentData]);

  return (
    <PageContent>
      {loading && (
        <center>
          <Spin />
        </center>
      )}
      {!!Object.keys(data).length && (
        <div className={classNames(classes.title)}>
          <Tabs>
            {(data.ownerNotes || data.history) && (
              <TabPane
                tab={t('AssignmentSummary:OwnerNotesAndHistory')}
                key={TABS_KEYS.ownerNotesAndHistory}
              >
                <OwnerNotesAndHistory
                  data={[
                    {
                      ownerNotes: data.ownerNotes,
                      history: data.history,
                    },
                  ]}
                />
              </TabPane>
            )}
            {data.ownerSurveyInformation && (
              <TabPane
                tab={t('AssignmentSummary:OwnerSurveyInformation')}
                key={TABS_KEYS.ownerSurveyInformation}
              >
                <OwnerSurveyInformation data={data.ownerSurveyInformation} />
              </TabPane>
            )}
            {data.phenologySurveyInformation && (
              <TabPane
                tab={t('AssignmentSummary:PhenologySurveyInformation')}
                key={TABS_KEYS.phenologySurveyInformation}
              >
                <PhenologySurveyInformation
                  data={data.phenologySurveyInformation}
                />
              </TabPane>
            )}
            {data.speciesPhenologyNotesConservationStatus && (
              <TabPane
                tab={t(
                  'AssignmentSummary:SpeciesPhenologyNotesConservationStatus'
                )}
                key={TABS_KEYS.speciesPhenologyNotesConservationStatus}
              >
                <SpeciesPhenologyNotesConservationStatus
                  data={data.speciesPhenologyNotesConservationStatus}
                />
              </TabPane>
            )}
            {data.lookalikeSpecies && (
              <TabPane
                tab={t('AssignmentSummary:LookalikeSpecies')}
                key={TABS_KEYS.lookalikeSpecies}
              >
                <LookalikeSpecies data={data.lookalikeSpecies} />
              </TabPane>
            )}
            {data.researchQuestions && (
              <TabPane
                tab={t('AssignmentSummary:ResearchQuestions')}
                key={TABS_KEYS.researchQuestions}
              >
                <ResearchQuestions data={data.researchQuestions} />
              </TabPane>
            )}
            {data.nearbySpecies && (
              <TabPane
                tab={t('AssignmentSummary:NearbySpecies')}
                key={TABS_KEYS.nearbySpecies}
              >
                <NearbySpecies data={data.nearbySpecies} />
              </TabPane>
            )}
          </Tabs>
        </div>
      )}
    </PageContent>
  );
};

export default SurveyorAssignmentDetail;
