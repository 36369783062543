import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:leadSurvName'),
      dataIndex: 'leadSurvName',
      key: 'leadSurvName',
      sorter: true,
    },
    {
      title: t('table:surv2Name'),
      dataIndex: 'surv2Name',
      key: 'surv2Name',
      sorter: true,
    },
    {
      title: t('table:surv3Name'),
      dataIndex: 'surv3Name',
      key: 'surv3Name',
      sorter: true,
    },
    {
      title: t('table:surv4Name'),
      dataIndex: 'surv4Name',
      key: 'surv4Name',
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:genets'),
      dataIndex: 'genets',
      key: 'genets',
      sorter: true,
    },
    {
      title: t('table:ramets'),
      dataIndex: 'ramets',
      key: 'ramets',
      sorter: true,
    },
    {
      title: t('table:fieldDataType'),
      dataIndex: 'fieldDataType',
      key: 'fieldDataType',
      sorter: true,
    },
    {
      title: t('table:reviewComments'),
      dataIndex: 'reviewComments',
      key: 'reviewComments',
      sorter: true,
    },
  ];
};
