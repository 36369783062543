import { useEffect, useRef, useReducer } from 'react';

const initialState = {
  loading: true,
  data: undefined,
  error: undefined,
};

const DEFAULT_OPTIONS = {
  shouldFetch: true,
  initialState,
  deps: [],
};

const ACTION_TYPES = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const apiReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOADING:
      return { ...state, loading: true, error: undefined };
    case ACTION_TYPES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ACTION_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export function useAPI(reqFn, options = {}) {
  const mergedOptions = { ...DEFAULT_OPTIONS, ...options };

  const [state, dispatch] = useReducer(apiReducer, mergedOptions.initialState);
  let { current: cancelRequest } = useRef(false);

  const fetchData = async (...args) => {
    dispatch({ type: ACTION_TYPES.LOADING });

    try {
      let data = await reqFn(...args);
      if (cancelRequest) return;
      if (data instanceof Error) {
        throw data;
      }

      if (options.mapDataFn) {
        data = options.mapDataFn(data, state.data);
      }

      dispatch({ type: ACTION_TYPES.SUCCESS, payload: data });
    } catch (e) {
      if (cancelRequest) return;
      dispatch({ type: ACTION_TYPES.ERROR, payload: e });
    }
  };

  useEffect(() => {
    if (!mergedOptions.shouldFetch) return;
    fetchData();

    return () => {
      cancelRequest = true;
    };
  }, [...mergedOptions.deps, mergedOptions.shouldFetch]);

  return { ...state, refetch: fetchData };
}
