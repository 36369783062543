import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_NATURAL_COMMUNITIES_EOS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_NATURAL_COMMUNITIES_EOS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const NaturalCommunities = () => (
  <PageWithTable
    fetchExport={fetchExport}
    fetchData={(args) => fetch(args)}
    defaultSortField={[
      'State',
      'SpeciesName',
      'EonumberInt',
      'Subpopulation',
      'SurveyDate',
    ]}
    titleKey="StatusOfPlants:NaturalCommunities"
    descriptionKey="StatusOfPlants:NaturalCommunitiesDescription"
    searchForm={SearchForm}
    columns={columns()}
    scroll={{ x: 1000 }}
  />
);

export default NaturalCommunities;
