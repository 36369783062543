import { v4 } from 'uuid';
import moment from 'moment';

import { AUTH_DATA_KEY } from '../../../global/constants';
import { ENDPOINT_URLS } from '../../../global/api';

import { APIFetchError } from './APIFetchError';

const requestIDHeaderName = 'X-Request-ID';
const authHeaderName = 'Authorization';

const DEFAULT_API_OPTIONS = {
  requiresAuth: true,
};

const defaultHeaders = new Headers({
  'Content-Type': 'application/json',
  'X-Frame-Options': 'DENY',
  'X-Content-Type-Options': 'nosniff',
  'X-XSS-Protection': '1; mode=block',
  'Content-Security-Policy': 'frame-ancestors none',
  'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
});

const defaultFetchOptions = {
  cache: 'no-cache',
};

export function addRequestId(...fetchParams) {
  const [request, params, apiParams] = fetchParams;

  const headers = new Headers(params && params.headers);
  if (!headers.has(requestIDHeaderName)) {
    headers.set(requestIDHeaderName, v4());
  }

  return [request, { ...params, headers }, apiParams];
}

export function addDefaultFetchOptions(...fetchParams) {
  const [req, fetchOptions = {}, apiParams] = fetchParams;
  const { initialHeaders } = fetchOptions;

  const headers = new Headers(initialHeaders || defaultHeaders);
  new Headers(fetchOptions.headers).forEach((v, k) => headers.set(k, v));

  return [req, { ...defaultFetchOptions, ...fetchOptions, headers }, apiParams];
}

const isExpired = (exp) => {
  if (!exp) {
    return false;
  }

  const now = moment().utc();
  const expirationDate = moment(exp).utc();
  return expirationDate < now;
};

const getTokenPair = async () => {
  const authData = JSON.parse(localStorage.getItem(AUTH_DATA_KEY));
  const { tokenPair } = authData;
  const { accessToken, accessTokenExpirationDate, refreshToken } = tokenPair;

  const isTokenExpired = isExpired(accessTokenExpirationDate);
  if (isTokenExpired) {
    try {
      const updatedToken = await fetch(ENDPOINT_URLS.REFRESH_TOKEN, {
        headers: defaultHeaders,
        method: 'POST',
        body: JSON.stringify({ accessToken, refreshToken }),
      }).then((r) => r.json());

      localStorage.setItem(
        AUTH_DATA_KEY,
        JSON.stringify({ ...authData, tokenPair: updatedToken })
      );
      return updatedToken;
    } catch (e) {
      console.log('error', e);
    }
  }

  return tokenPair;
};

export async function addAuthHeaders(...fetchParams) {
  const [request, params, options] = fetchParams;
  const apiOptions = { ...DEFAULT_API_OPTIONS, ...options };

  const endpoint = request.split('/');

  const resultEndpoint = [
    endpoint[endpoint.length - 2],
    endpoint[endpoint.length - 1],
  ].join('/');

  const permittingEndpoints = [
    'Account/ResetPassword',
    'Account/ForgotPassword',
    'DropdownLists/GetStates',
    'DropdownLists/GetDaysAvailableToSurvey',
  ];
  const condition =
    permittingEndpoints[0] === resultEndpoint ||
    permittingEndpoints[1] === resultEndpoint ||
    permittingEndpoints[2] === resultEndpoint ||
    permittingEndpoints[3] === resultEndpoint;
  const { requiresAuth } = apiOptions;
  const headers = new Headers(params && params.headers);
  if (requiresAuth && !condition) {
    const tokenPair = await getTokenPair();
    if (
      tokenPair?.status === 404 ||
      tokenPair?.status === 500 ||
      tokenPair?.status === 401
    ) {
      window.location.replace('/NotAuthorized');
    }
    if (!headers.has(authHeaderName) && tokenPair) {
      const { accessToken } = tokenPair;
      if (accessToken) {
        headers.set(authHeaderName, `Bearer ${accessToken}`);
      }
    }
  }
  if (requiresAuth && condition) {
    return [request, { ...params }, options];
  }

  return [request, { ...params, headers }, options];
}

export async function checkForErrors(...args) {
  const [fetchParams, response] = args;
  const { errors } = response || {};
  if (errors) {
    const [url] = fetchParams;
    return [fetchParams, new APIFetchError(url, errors)];
  }
  return [fetchParams, response];
}
