import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const LocationPrecisionsForFieldEnterSelect = (props) => {
  const {
    locationPrecisionsForFieldEnterData,
    locationPrecisionsForFieldEnterLoading,
    updateLocationPrecisionsForFieldEnter,
  } = useStore();

  useEffect(() => {
    if (
      !locationPrecisionsForFieldEnterData.length &&
      !locationPrecisionsForFieldEnterLoading
    )
      updateLocationPrecisionsForFieldEnter();
  }, []);

  return (
    <Select
      props={props}
      data={locationPrecisionsForFieldEnterData}
      loading={locationPrecisionsForFieldEnterLoading}
    />
  );
};

export default LocationPrecisionsForFieldEnterSelect;
