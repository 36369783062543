import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PlanQATable from '../PlanQATable';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PLAN_QA_SEED_COLL_YES, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const PlanQASeedCollYes = () => (
  <PlanQATable
    titleKey="PCVAdministration:PlanQASeedCollYes"
    descriptionKey="PCVAdministration:PlanQASeedCollYesDescription"
    parentFetch={fetch}
  />
);

export default PlanQASeedCollYes;
