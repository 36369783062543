import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithSimpleTableAndSearch from '../../pages/PageWithSimpleTableAndSearch';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';

const fetchElementOccurrenceDivisionStateStatus = (state) =>
  state
    ? APIFetch(ENDPOINT_URLS.GET_EL_OCC_DIV_STATE_STATUS(state), {
        method: 'get',
      })
    : null;

const fetchExport = (state) =>
  state
    ? APIFetch(ENDPOINT_URLS.GET_EL_OCC_DIV_STATE_STATUS_EXCEL(state), {
        method: 'get',
      })
    : null;

export const DivisionStateStatus = () => (
  <PageWithSimpleTableAndSearch
    fetchData={(state) => fetchElementOccurrenceDivisionStateStatus(state)}
    searchParamKey="state"
    titleKey="ElementOccurrence:ElementOccurrenceDivisionStateStatus"
    descriptionKey="ElementOccurrence:ElementOccurrenceDivisionStateStatusDescription"
    totalValueKey="division"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={({ state, division }) => `${state}-${division}`}
    fetchExport={fetchExport}
  />
);
