import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  openNotificationWithIcon,
  validateMessages,
  getParentPathname,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';
import {
  cleanNamesError,
  cleanOrganizationsError,
  firstNameError,
  firstNameLengthEError,
  lastNameError,
  lastNameLengthEError,
  organizationError,
  organizationLengthError,
  subOrganizationError,
  subOrganizationLengthError,
  emailError,
} from './errorFields';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_OWNER_DETAILS(key), {
        method: 'get',
      })
    : null;

const fetchCreate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_OWNER, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdate = (key, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_OWNER(key), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const OwnerDisplayAndEditForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const { key } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const [initialValues, setInitialValues] = useState(null);

  const {
    allOwnerTypesData,
    updateAllOwnerTypes,
    allOwnerStatusesData,
    updateAllOwnerStatuses,
    allReportMethodsData,
    updateAllReportMethods,
  } = useStore();

  useEffect(() => {
    if (!allOwnerTypesData.length) updateAllOwnerTypes();
    if (!allOwnerStatusesData.length) updateAllOwnerStatuses();
    if (!allReportMethodsData.length) updateAllReportMethods();
  }, []);

  const IS_UPDATE_MODE = key !== 'Create';

  const { data, loading } = useAPI(() => fetch(key), {
    initialState: { data: null, loading: false },
    shouldFetch: IS_UPDATE_MODE,
  });

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI(fetchCreate, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI((args) => fetchUpdate(key, args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    history.replace({
      state: {
        breadcrumbsName: t(
          `Owner:${IS_UPDATE_MODE ? 'Edit' : 'Add'}DisplayAndEditTitle`
        ),
      },
    });
  }, []);

  const condition = IS_UPDATE_MODE ? data : true;
  useEffect(() => {
    if (
      allOwnerTypesData.length &&
      allOwnerStatusesData.length &&
      allReportMethodsData.length &&
      condition
    ) {
      if (IS_UPDATE_MODE) {
        setInitialValues(data);
      } else {
        setInitialValues({
          ownerType: allOwnerTypesData[0].itemKey,
          ownerStatus: allOwnerStatusesData[0].itemKey,
          reportMethod: allReportMethodsData[0].itemKey,
        });
      }
    }
  }, [data, allOwnerTypesData, allOwnerStatusesData, allReportMethodsData]);

  const onFinish = (values) => {
    const { firstName, lastName, organization, subOrganization } = values;

    form.setFields(
      Object.keys(values).map((name) => ({
        name,
        errors: null,
      }))
    );

    if (!firstName && !lastName && !organization && !subOrganization) {
      form.setFields([
        {
          name: 'organization',
          errors: [
            'Pair of fields Organization, Suborganization OR First Name, Last Name are required',
          ],
        },
      ]);
      form.scrollToField('organization');

      return;
    }

    if (!firstName && !lastName && !organization && subOrganization) {
      form.setFields([
        {
          name: 'organization',
          errors: ['Organisation is needed. Please enter it'],
        },
      ]);
      form.scrollToField('organization');

      return;
    }

    if (!firstName && !lastName && organization && !subOrganization) {
      form.setFields([
        {
          name: 'subOrganization',
          errors: ['Suborganisation is needed. Please enter it or enter NONE'],
        },
      ]);
      form.scrollToField('subOrganization');

      return;
    }

    if (!firstName && lastName && !organization && !subOrganization) {
      form.setFields([
        {
          name: 'firstName',
          errors: ['First Name is needed. Please enter it'],
        },
      ]);
      form.scrollToField('firstName');

      return;
    }

    if (firstName && !lastName && !organization && !subOrganization) {
      form.setFields([
        {
          name: 'lastName',
          errors: ['Last Name is needed. Please enter it'],
        },
      ]);
      form.scrollToField('lastName');

      return;
    }

    if (IS_UPDATE_MODE) {
      refetchUpdate(values);
    } else {
      refetchCreate(values);
    }
  };

  const changeFields = (e) => {
    const fieldName = e[0].name[0];
    const fieldValue = e[0].value;

    const organization = form.getFieldValue('organization');
    const subOrganization = form.getFieldValue('subOrganization');
    const firstName = form.getFieldValue('firstName');
    const lastName = form.getFieldValue('lastName');

    if (fieldName === 'organization') {
      if (fieldValue && !subOrganization) {
        form.setFields(subOrganizationError);
      }
      if (!fieldValue && subOrganization) {
        form.setFields(organizationError);
      }

      if (
        (!fieldValue && !subOrganization) ||
        (fieldValue && subOrganization)
      ) {
        if (fieldValue.length > 100) {
          form.setFields(organizationLengthError);
        } else if (subOrganization?.length > 50) {
          form.setFields(subOrganizationLengthError);
        } else {
          form.setFields(cleanOrganizationsError);
        }
      }
    }

    if (fieldName === 'subOrganization') {
      if (fieldValue && !organization) {
        form.setFields(organizationError);
      }
      if (!fieldValue && organization) {
        form.setFields(subOrganizationError);
      }
      if ((!fieldValue && !organization) || (fieldValue && organization)) {
        if (fieldValue?.length > 50) {
          form.setFields(subOrganizationLengthError);
        } else if (organization?.length > 50) {
          form.setFields(organizationLengthError);
        } else {
          form.setFields(cleanOrganizationsError);
        }
      }
    }

    if (fieldName === 'firstName') {
      if (fieldValue && !lastName) {
        form.setFields(lastNameError);
      }
      if (!fieldValue && lastName) {
        form.setFields(firstNameError);
      }
      if ((!fieldValue && !lastName) || (fieldValue && lastName)) {
        if (fieldValue?.length > 50) {
          form.setFields(firstNameLengthEError);
        } else if (lastName?.length > 50) {
          form.setFields(lastNameLengthEError);
        } else {
          form.setFields(cleanNamesError);
        }
      }
    }

    if (fieldName === 'lastName') {
      if (fieldValue && !firstName) {
        form.setFields(firstNameError);
      }
      if (!fieldValue && firstName) {
        form.setFields(lastNameError);
      }
      if ((!fieldValue && !firstName) || (fieldValue && firstName)) {
        if (fieldValue?.length > 50) {
          form.setFields(lastNameLengthEError);
        } else if (firstName?.length > 50) {
          form.setFields(firstNameLengthEError);
        } else {
          form.setFields(cleanNamesError);
        }
      }
    }

    if (fieldName === 'email') {
      if (fieldValue?.length > 80) {
        form.setFields(emailError);
      } else {
        form.setFields([{ name: 'email', errors: null }]);
      }
    }
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData.ownerKey) {
          type = 'success';
          message = 'Data was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update Data';
        }
      } else if (createData.ownerKey) {
        type = 'success';
        message = 'Data was created successfully';
      } else {
        type = 'error';
        message = 'Failed create Data';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        const parentPathname = getParentPathname(pathname);
        history.replace(parentPathname);
      }
    }
  }, [createData, updateData]);

  const disabledFields = [
    'pastContactYear',
    'pastContactMethod',
    'dateReportSent',
  ];

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(`Owner:${IS_UPDATE_MODE ? 'Edit' : 'Add'}DisplayAndEditTitle`)}
      </Title>
      <p className={classNames(classes.title)}>
        {t(`Owner:FormDisplayAndEditDescription`)}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues && !loading ? (
            <Form
              onFieldsChange={(e) => changeFields(e)}
              {...layout}
              scrollToFirstError
              form={form}
              name="ownersDisplayAndEditForm"
              onFinish={onFinish}
              initialValues={initialValues}
              validateMessages={validateMessages}
              validateTrigger={['onBlur', 'onChange']}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  key={name}
                  disabled={disabledFields.includes(name)}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default OwnerDisplayAndEditForm;
