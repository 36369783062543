import React from 'react';

import StatesSelect from '../../../../shared/components/select/StatesSelect';
import OutreachOrganizationTypesSelect from '../../../../shared/components/select/OutreachOrganizationTypesSelect';

export const formConfig = [
  {
    name: 'Organization',
    label: 'Organization name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'OutreachOrganizationType',
    label: 'Organization type',
    input: (props) => <OutreachOrganizationTypesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];
