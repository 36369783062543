import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general'

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
      width: 250,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      width: 120,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 140,
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      width: 200,
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: true,
    },
    {
      title: t('table:eoid'),
      dataIndex: 'eoid',
      key: 'eoid',
      width: 80,
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
      width: 100,
      sorter: true,
    },
  ];
};

export const expandableColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('table:seedAccNbr'),
      dataIndex: 'seedAccNbr',
      key: 'seedAccNbr',
    },
    {
      title: t('table:seedPackageNbr'),
      dataIndex: 'seedPackageNbr',
      key: 'seedPackageNbr',
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:seedRcvdDate'),
      dataIndex: 'seedRcvdDate',
      key: 'seedRcvdDate',
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:seedCollector'),
      dataIndex: 'seedCollector',
      key: 'seedCollector',
    },
    {
      title: t('table:seedNumberPlants'),
      dataIndex: 'seedNumberPlants',
      key: 'seedNumberPlants',
    },
    {
      title: t('table:seedsInPacket1'),
      dataIndex: 'seedsInPacket1',
      key: 'seedsInPacket1',
    },
    {
      title: t('table:seedsForTrials'),
      dataIndex: 'seedsForTrials',
      key: 'seedsForTrials',
    },
    {
      title: t('table:seedDatePackaged'),
      dataIndex: 'seedDatePackaged',
      key: 'seedDatePackaged',
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:popCommentSeedNotes'),
      dataIndex: 'popCommentSeedNotes',
      key: 'popCommentSeedNotes',
    },
    {
      title: t('table:speciesNameOriginal'),
      dataIndex: 'speciesNameOriginal',
      key: 'speciesNameOriginal',
    },
    {
      title: t('table:lastUpdate'),
      dataIndex: 'lastUpdate',
      key: 'lastUpdate',
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:lastSpecCountTotal'),
      dataIndex: 'lastSpecCountTotal',
      key: 'lastSpecCountTotal',
    },
    {
      title: t('table:seedTotal'),
      dataIndex: 'seedTotal',
      key: 'seedTotal',
    },
    {
      title: t('table:materialType'),
      dataIndex: 'materialType',
      key: 'materialType',
    },
    {
      title: t('table:condition'),
      dataIndex: 'condition',
      key: 'condition',
    },
  ];
};
