import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
// eslint-disable-next-line import/named
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAuth } from '../../../../global/stores/auth';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../../pages/PageWithTable';
import { roleTypes } from '../../../../utils/roles';
import {
  openNotificationWithIcon,
  paramsForBE,
} from '../../../../utils/general';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

import classes from '../../../styles.module.scss';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_EOS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchDelete = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.DELETE_ELEMENT_OCC(key), {
        method: 'delete',
      })
    : null;

export const DisplayAndEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI(fetchDelete, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (deleteData) {
      const { eokey } = deleteData;

      if (eokey) {
        openNotificationWithIcon('success', 'Data was deleted successfully');

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', 'Failed delete Data');
      }
    }
  }, [deleteData]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const staticColumns = columns();
  const selectItem = {
    title: t('table:action'),
    key: 'operation',
    fixed: 'left',
    width: 60,
    render: (_, record) => (
      <Link
        style={{
          marginRight: 8,
        }}
        to={`${pathname}/${record.eokey}`}
      >
        {t('table:select')}
      </Link>
    ),
  };
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      render: (_, record) => (
        <>
          <Link
            style={{
              marginRight: 8,
            }}
            to={`${pathname}/${record.eokey}`}
          >
            {t('table:edit')}
          </Link>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => refetchDelete(record.eokey)}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a>{t('table:delete')}</a>
          </Popconfirm>
        </>
      ),
    },
    ...staticColumns,
  ];

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  const { titleKey, descriptionKey } =  role === roleTypes.Heritage
    ? {
      titleKey: "ElementOccurrence:ElOccDisplay",
      descriptionKey: "ElementOccurrence:ElOccDisplayDescription",
    } : {
      titleKey: "ElementOccurrence:ElOccDisplayAndEdit",
      descriptionKey: "ElementOccurrence:ElOccDisplayAndEditDescription",
    };

  return (
    <PageWithTable
      fetchData={fetch}
      fetchExport={fetchExport}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey={titleKey}
      descriptionKey={descriptionKey}
      searchForm={SearchForm}
      columns={role === roleTypes.Heritage ? [selectItem, ...staticColumns] : allColumns}
      rowKey={(record) => record.eokey}
      scroll={{ x: 1300 }}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading}
    >
      {role === roleTypes.Administrator && (
        <div className={classNames(classes.content)}>
          <Button onClick={create}>Create EO</Button>
        </div>
      )}
    </PageWithTable>
  );
};
