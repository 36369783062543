import React from 'react';
import { InputNumber, DatePicker, Input } from 'antd';

import { SURVEYOR_TYPE } from '../../../../global/constants';

import { Select } from '../../../../shared/components/select/Select';
import StatesSelect from '../../../../shared/components/select/StatesSelect';
import { getMomentDate } from '../../../../utils/general';

const { TextArea } = Input;

export const formConfig = [
  {
    name: 'state',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
  },
  {
    name: 'programName',
    label: 'Program Name',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'programDate',
    label: 'Program Date',
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value ? getMomentDate(value) : ''}
        format="YYYY-MM-DD"
        allowClear={false}
      />
    ),
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'programDescription',
    label: 'Program Description',
    rules: [
      {
        required: true,
      },
    ],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'surveyorType',
    label: 'Surveyor Type',
    input: (props) => <Select props={props} data={SURVEYOR_TYPE} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'maximumAttendees',
    label: 'Maximum Attendees',
    input: (props) => (
      <InputNumber
        {...props}
        style={{
          width: '100%',
        }}
      />
    ),
    rules: [
      {
        required: true,
      },
      {
        type: 'integer',
        min: 0,
        max: 2147483647,
      },
    ],
  },
];
