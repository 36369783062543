import React, { useEffect, useState } from 'react';
import { Popconfirm, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithNestedTable from '../../../pages/PageWithNestedTable';
import { roleTypes } from '../../../../utils/roles';
import { useAuth } from '../../../../global/stores/auth';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { openNotificationWithIcon } from '../../../../utils/general';

import { columns, expandableColumns } from './TableColumns';

const defaultSortFields = ['State', 'SpeciesName', 'Eonumber', 'Subpopulation'];

const fetchNestedData = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_MANUAL_EDIT_DETAILS(key), {
        method: 'get',
      })
    : [];

const fetchUpdate = (key, args) =>
  APIFetch(ENDPOINT_URLS.UPDATE_TRANSCRIPTION_STATUS(key), {
    method: 'put',
    body: JSON.stringify(args),
  });

const fetchDelete = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.DELETE_FIELD_DATA_MANUAL_EDIT(key), {
        method: 'delete',
      })
    : null;

const FieldDataManualEdit = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const page = query.get('page');
  const { user } = useAuth();
  const role = user?.role;

  const [isForceRefetch, setIsForceRefetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const defaultBodyRequest = {
    pagedInfo: {
      pageSize: 20,
      pageNumber: currentPage,
    },
    orderCriteria: defaultSortFields.map((propertyName) => ({
      orderOption: 'Ascending',
      propertyName,
    })),
  };

  const { loading, refetch: refetchUpdate } = useAPI(fetchUpdate, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const { data: deleteData, refetch: refetchDelete } = useAPI(fetchDelete, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const fetch = (args) =>
    APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_MANUAL_EDIT_PAGED, {
      method: 'post',
      body: JSON.stringify(args || defaultBodyRequest),
  });

  useEffect(() => {
    if (deleteData) {
      const { ffkey } = deleteData;

      if (ffkey) {
        openNotificationWithIcon('success', 'Data was deleted successfully');

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', 'Failed delete data');
      }
    }
  }, [deleteData]);

  const columnsForNestedTable = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 160,
      render: (_, { ffkey }) => (
        <Space size="middle">
          <Link to={`${pathname}/${ffkey}`}>{t('table:transcribeFile')}</Link>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() =>
              refetchDelete(ffkey).then(() => {
                setIsForceRefetch(true);
              })
            }
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a>{t('table:delete')}</a>
          </Popconfirm>
        </Space>
      ),
    },
    ...expandableColumns(),
  ];

  const actions = ({ transcriptionDone, eokey }) => (
    <Typography.Link
      loading={loading}
      onClick={() =>
        refetchUpdate(eokey, {
          transcriptionDone: !transcriptionDone,
        }).then(() => {
          setIsForceRefetch(true);
        })
      }
      style={{
        marginLeft: 16,
      }}
    >
      {t(
        `table:${
          transcriptionDone ? 'setEoToUntranscribed' : 'setEoToTranscribed'
        }`
      )}
    </Typography.Link>
  );

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const roleAccess =
    role === roleTypes.Transcriber || role === roleTypes.Administrator;

  return (
    <PageWithNestedTable
      fetchData={fetch}
      fetchNestedData={fetchNestedData}
      defaultSortField={defaultSortFields}
      nestedTableKey="eokey"
      titleKey="ElementOccurrence:FieldDataManualEdit"
      descriptionKey="ElementOccurrence:FieldDataManualEditDescription"
      columns={columns()}
      expandableColumns={
        roleAccess ? columnsForNestedTable : expandableColumns()
      }
      rowKeyFunc={(record) => record.eokey}
      scroll={{ x: 1500 }}
      getNestedDataActionText="showFiles"
      actionsColWidth={250}
      actions={roleAccess ? actions : null}
      updateTableData={isForceRefetch}
      withPageRedirect
      setCurrentPage={setCurrentPage}
    />
  );
};

export default FieldDataManualEdit;
