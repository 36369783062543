import React from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { action } from '../../../../shared/EditableTable/components/Action';
import { paramsForBE } from '../../../../utils/general';
import { actionContent } from '../utils';
import PageWithTable from '../../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetchPermissionByOwner = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PERMISSIONS_BY_OWNER_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PERMISSIONS_BY_OWNER_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const PermissionByOwner = () => {
  const { t } = useTranslation();

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 60,
      renderFunction: (_, record) => actionContent(record),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchExport={fetchExport}
      fetchData={(args) => fetchPermissionByOwner(args)}
      defaultSortField={[
        'LastName',
        'FirstName',
        'Organization',
        'SubOrganization',
      ]}
      titleKey="PCVAdministration:PermissionByOwner"
      descriptionKey="PCVAdministration:PermissionByOwnerDescription"
      searchForm={SearchForm}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
      columns={allColumns}
      scroll={{ x: 1600 }}
    />
  );
};

export default PermissionByOwner;
