import React, { useEffect, useState } from 'react';
import { Menu, Tooltip, Dropdown } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'clsx';
import { UserOutlined } from '@ant-design/icons';

import { menuData } from './menuData';
import { useAuth } from '../stores/auth';
import inlineLogotype from '../shared/SPROUT lockup_rgb.png';
import {
  ACCOUNT_SETTINGS_CHANGE_PASSWORD,
  ACCOUNT_SETTINGS_PROFILE,
  BASE_ROUTE,
} from '../../AppRoutes';
import { AUTH_DATA_KEY } from '../constants';

import classes from './styles.module.scss';

const { Item } = Menu;

export const AppHeader = () => {
  const { pathname } = useLocation();

  const [selectedKeys, setSelectedKeys] = useState(['/']);

  const { logout, user, securityAgreementAccepted } = useAuth();

  const authData = JSON.parse(localStorage.getItem(AUTH_DATA_KEY));
  const tokenStatus = authData?.tokenPair?.status;

  const conditionForEmptyHeader =
    tokenStatus === 404 || tokenStatus === 500 || tokenStatus === 401;
  const handleClick = (e) => {
    setSelectedKeys([e.key]);
  };

  const menu = (
    <Menu>
      <Item key="0">
        <Link to={ACCOUNT_SETTINGS_PROFILE}>Profile settings</Link>
      </Item>
      <Item key="1">
        <Link to={ACCOUNT_SETTINGS_CHANGE_PASSWORD}>Change password</Link>
      </Item>
    </Menu>
  );

  const dropdown = (children) => (
    <Dropdown overlay={menu} trigger={['click']}>
      {children}
    </Dropdown>
  );

  useEffect(() => {
    const pathSnippets = pathname.split('/');
    const selectedKey = `/${pathSnippets[1]}`;

    if (selectedKey !== selectedKeys[0]) {
      setSelectedKeys([selectedKey]);
    }
  }, [pathname]);

  return securityAgreementAccepted && !conditionForEmptyHeader ? (
    <Header>
      <div className={classNames(classes.div)}>
        <Link to={BASE_ROUTE} className={classes.logotype}>
          <img src={inlineLogotype} alt="logo" />
        </Link>
        <div className={classNames(classes.nav)}>
          <Menu
            mode="horizontal"
            onClick={handleClick}
            selectedKeys={selectedKeys}
            className={classNames(classes.navMenu)}
          >
            {menuData(user).map(({ path, section }) => (
              <Item key={path}>
                <Link to={path}>{section}</Link>
              </Item>
            ))}
          </Menu>
        </div>
        {securityAgreementAccepted && user && (
          <div className={classNames(classes.userNav)}>
            {user.lastName && (
              <div>
                {dropdown(
                  <span className={classNames(classes.userEmail)}>
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                )}
                {dropdown(
                  <span className={classNames(classes.userIcon)}>
                    <Tooltip
                      title={`${user.firstName} ${user.lastName}`}
                      placement="left"
                    >
                      <UserOutlined />
                    </Tooltip>
                  </span>
                )}
              </div>
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="" onClick={logout} className={classNames(classes.logOut)}>
              Log Out
            </Link>
          </div>
        )}
      </div>
    </Header>
  ) : null;
};
