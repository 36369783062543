import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Autocomplete } from './Autocomplete';

const OwnersAutocomplete = (props) => {
  const { ownersData, ownersLoading, updateOwners } = useStore();

  useEffect(() => {
    if (!ownersData.length && !ownersLoading) updateOwners();
  }, []);

  return (
    <Autocomplete props={props} data={ownersData} loading={ownersLoading} />
  );
};

export default OwnersAutocomplete;
