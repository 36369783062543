import React from 'react';

import StatesSelect from '../../../shared/components/select/StatesSelect';

export const searchFormConfig = [
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} />,
    operatortype: 'Equals',
  },
];
