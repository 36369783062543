import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
      width: 110,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
      width: 130,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 60,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      width: 220,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 240,
    },
    {
      title: t('table:pastContactYear'),
      dataIndex: 'pastContactYear',
      key: 'pastContactYear',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:pastContactMethod'),
      dataIndex: 'pastContactMethod',
      key: 'pastContactMethod',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:numberEos'),
      dataIndex: 'numberEos',
      key: 'numberEos',
      sorter: true,
      width: 80,
    },
    {
      title: t('table:numberEosNotContacted'),
      dataIndex: 'numberEosNotContacted',
      key: 'numberEosNotContacted',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:nptnotes'),
      dataIndex: 'nptnotes',
      key: 'nptnotes',
      sorter: true,
      width: 550,
    },
  ];
};
