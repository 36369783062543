import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:commonName'),
      dataIndex: 'commonName',
      key: 'commonName',
      sorter: true,
    },
    {
      title: t('table:phenologyNote'),
      dataIndex: 'phenologyNote',
      key: 'phenologyNote',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:phenologyMemo'),
      dataIndex: 'phenologyMemo',
      key: 'phenologyMemo',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      sorter: true,
    },
    {
      title: t('table:nptstatus'),
      dataIndex: 'nptstatus',
      key: 'nptstatus',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:comment'),
      dataIndex: 'comment',
      key: 'comment',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:propaguleType'),
      dataIndex: 'propaguleType',
      key: 'propaguleType',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:seedStorageBehavior'),
      dataIndex: 'seedStorageBehavior',
      key: 'seedStorageBehavior',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:collection'),
      dataIndex: 'collection',
      key: 'collection',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:seedNotes'),
      dataIndex: 'seedNotes',
      key: 'seedNotes',
      sorter: true,
      editable: true,
    },
  ];
};
