import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithTable from '../../pages/PageWithTable';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';

const fetchSurveyStatusDetail = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEY_STATUS_DETAIL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const SurveyStatusDetail = () => (
  <PageWithTable
    fetchData={(args) => fetchSurveyStatusDetail(args)}
    defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
    titleKey="PCVAdmin:SurveyStatusDetail"
    descriptionKey="PCVAdmin:SurveyStatusDetailDescription"
    searchForm={SearchForm}
    columns={columns()}
    scroll={{ x: 2500 }}
    tableLayout="fixed"
  />
);

export default SurveyStatusDetail;
