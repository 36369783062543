import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'division',
  'divisionTotal',
  'mgtAlteredHydrology',
  'mgtDirectHumanThreat',
  'mgtErosion',
  'mgtFireSuppression',
  'mgtHerbivory',
  'mgtInvasive',
  'mgtSuccession',
  'mgtNoThreat',
];

const fetchOwnerType = () =>
  APIFetch(ENDPOINT_URLS.GET_MGT_NOTES_TYPE_DIVISION_SPROUT, {
    method: 'get',
  });

const MgtNotesTypeDivisionSPROUT = () => {
  const { data, loading } = useAPI(() => fetchOwnerType(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('division', data);

  return (
    <PageWithSimpleTable
      titleKey="StatusOfPlants:MgtNotesTypeDivisionSPROUT"
      descriptionKey="StatusOfPlants:MgtNotesTypeDivisionSPROUTDescription"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};

export default MgtNotesTypeDivisionSPROUT;
