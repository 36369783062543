import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { useStore } from '../../../../global/stores/store/StoreContext';
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import {
  dataUnknown,
  dataYesNoWithBooleanKeyNone,
} from '../../../../global/constants';
import {
  getParentPathname,
  openNotificationWithIcon,
} from '../../../../utils/general';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../../shared/components/formItem';
import PageContent from '../../../../shared/components/PageContent';

import { formConfig } from './form-config';

import classes from '../../../styles.module.scss';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchCreateElementOccurrenceOwner = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.CREATE_ELEMENT_OCC_OWNER, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchUpdateElementOccurrenceOwner = (key, ownerKey, args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_ELEMENT_OCC_OWNER(key, ownerKey), {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetchGetElementOccurrenceOwner = (key, currentOwner) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_OWNER(key, currentOwner), {
        method: 'get',
      })
    : [];

export const ElementOccurrenceOwnerForm = () => {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const history = useHistory();
  const { eokey, ownerKey } = useParams();
  const {
    location: { pathname, search: searchParams },
  } = history;
  const query = new URLSearchParams(searchParams);
  const currentOwnerQuery = query.getAll('currentOwner')[0];
  
  const [initialValues, setInitialValues] = useState(null);

  const IS_UPDATE_MODE = ownerKey !== 'Create';

  const { sourceOwnersData, updateSourceOwners, ownersData, updateOwners } =
    useStore();

  useEffect(() => {
    if (!sourceOwnersData.length) updateSourceOwners();
    if (!ownersData.length) updateOwners();
  }, []);

  const {
    data: createData,
    loading: createLoading,
    refetch: refetchCreate,
  } = useAPI((args) => fetchCreateElementOccurrenceOwner(args), {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: updateData,
    loading: updateLoading,
    refetch: refetchUpdate,
  } = useAPI(
    (args) => fetchUpdateElementOccurrenceOwner(eokey, ownerKey, args),
    {
      initialState: { loading: false },
      shouldFetch: false,
    }
  );

  const { data: parentChildrenData } = useAPI(
    () => fetchGetElementOccurrenceOwner(eokey, currentOwnerQuery),
    {
      initialState: { loading: false },
      shouldFetch: IS_UPDATE_MODE,
    }
  );

  useEffect(() => {
    history.replace({
      search: `?currentOwner=${currentOwnerQuery}`,
      state: {
        breadcrumbsName: `${IS_UPDATE_MODE ? 'Edit' : 'Create'}`,
      },
    });
  }, []);

  useEffect(() => {
    if (parentChildrenData?.length) {
      const { owner, sourceOwner, assessorLot, currentOwner } =
        parentChildrenData.find((item) => item.ownerKey === +ownerKey);
      setInitialValues({
        ownerKey: owner,
        sourceOwner,
        assessorLot,
        currentOwner: currentOwner ? 'Yes' : 'No',
      });
    }
  }, [parentChildrenData]);

  useEffect(() => {
    if (sourceOwnersData.length && ownersData.length && !IS_UPDATE_MODE) {
      setInitialValues({
        ownerKey: dataUnknown[0].item,
        sourceOwner: sourceOwnersData[0].itemKey,
        currentOwner: dataYesNoWithBooleanKeyNone[0].itemKey,
      });
    }
  }, [sourceOwnersData, ownersData]);

  const onFinish = (values) => {
    const { assessorLot, sourceOwner, currentOwner } = values;
    const currentOwnerValue = ['true', 'Yes'].includes(currentOwner);
    if (IS_UPDATE_MODE) {
      refetchUpdate({
        assessorLot,
        sourceOwner,
        currentOwner: currentOwnerValue,
      });
    } else {
      const {
        ownerKey: updatedOwnerKey,
        currentOwner: currentOwnerForCreate,
        ...others
      } = values;
      const currentOwnerForCreateValue = currentOwner === 'true';
      if (updatedOwnerKey === dataUnknown[0].item) {
        form.setFields([
          {
            name: 'ownerKey',
            errors: ['Owner is required. Please fill it'],
          },
        ]);
      } else {
        refetchCreate({
          eokey: +eokey,
          ownerKey: ownersData.find(({ item }) => item === updatedOwnerKey)
            .itemKey,
          currentOwner: currentOwnerForCreateValue,
          ...others,
        });
      }
    }
  };

  const onClickCancel = () => {
    form.resetFields();

    const parentPathname = getParentPathname(pathname);
    history.replace(parentPathname);
  };

  useEffect(() => {
    if (updateData || createData) {
      let type = '';
      let message = '';

      if (updateData) {
        if (updateData?.eokey) {
          type = 'success';
          message = 'ElementOccurrenceOwner was updated successfully';
        } else {
          type = 'error';
          message = 'Failed update ElementOccurrenceOwner';
        }
      } else if (createData?.eokey) {
        type = 'success';
        message = 'ElementOccurrenceOwner was created successfully';
      } else {
        type = 'error';
        message = 'Failed create ElementOccurrenceOwner';
      }

      openNotificationWithIcon(type, message);

      if (type === 'success') {
        const parentPathname = getParentPathname(pathname);
        history.replace(parentPathname);
      }
    }
  }, [createData, updateData]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(
          `ElementOccurrence:ElementOccurrenceOwner${
            IS_UPDATE_MODE ? 'Edit' : 'Create'
          }Form`
        )}
      </Title>
      <p className={classNames(classes.title)}>
        {t(
          `ElementOccurrence:ElementOccurrenceOwner${
            IS_UPDATE_MODE ? 'Edit' : 'Create'
          }FormDescription`
        )}
      </p>
      <br />
      <Row
        justify="space-around"
        align="middle"
        className={classNames(classes.row)}
      >
        <Col span={12} className={classNames(classes.col)}>
          {initialValues ? (
            <Form
              {...layout}
              scrollToFirstError
              form={form}
              name="createFieldDataManualEnter"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              {formConfig.map(({ name, label, input, ...rest }) => (
                <FormItem
                  disabled={IS_UPDATE_MODE && name === 'ownerKey'}
                  key={uuidv4()}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              ))}

              <Item {...tailLayout} className={classNames(classes.formFooter)}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={createLoading || updateLoading}
                >
                  {t(`form:${IS_UPDATE_MODE ? 'update' : 'insert'}`)}
                </Button>
                <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
    </PageContent>
  );
};
