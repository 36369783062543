import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { action } from '../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../pages/PageWithEditableTable';
import {
  openNotificationWithIcon,
  getIsForbidden,
  getMomentDate
} from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';

import { editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};
const defaultSortField = ['StartDate', 'SignupType', 'SurveyorType'];
const REFETCH_CHANGING_TIMEOUT = 2000;

const fetchSignupDates = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SIGNUP_DATES, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : [];

const SignupDate = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [form] = Form.useForm();

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading, refetch } = useAPI(fetchSignupDates, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [isRefetchDone, setIsRefetchDone] = useState(false);

  const cancel = () => {
    setEditingKey('');
  };

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);

    refetch(paramsToPassInBodyRequest(parameters, null, defaultSortField));

    setIsRefetchDone(true);
    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  useEffect(() => {
    if (!isForbidden)
      refetch(paramsToPassInBodyRequest({}, null, defaultSortField));
  }, []);

  const initialState = {
    startDate: '',
    endDate: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();
    const { startDate, endDate } = row;

    const start = getMomentDate(startDate).format('YYYY-MM-DD');
    const end = getMomentDate(endDate).format('YYYY-MM-DD');

    const [surveyorType, signupType] = key.split('-');

    if (moment.utc(start).isSame(end) || moment.utc(start).isBefore(end) || signupType === 'EndOfYearEmail') {

      APIFetch(ENDPOINT_URLS.UPDATE_SIGNUP_DATES(surveyorType, signupType), {
        method: 'put',
        body: JSON.stringify({
          startDate: moment.utc(startDate).toISOString(),
          endDate: moment.utc(endDate).toISOString(),
        }),
      }).then(() => {
        const newData = [...tableData];
        const index = newData.findIndex(
          (rec) => key === `${rec.surveyorType}-${rec.signupType}`
        );

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      });
    } else {
      form.setFields([
        {
          name: 'endDate',
          errors: ['End Date should be more or the same as Start Date'],
        },
      ]);
    }
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, record) => {
        const { surveyorType, signupType } = record;

        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone,
          `${surveyorType}-${signupType}`,
          (rec) => `${rec.surveyorType}-${rec.signupType}`
        );

        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        const { surveyorType, signupType } = record;

        return {
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isCellEditing(
            record,
            editingKey,
            null,
            null,
            null,
            `${surveyorType}-${signupType}`
          ),
        };
      },
    };
  });

  return (
    <PageWithEditableTable
      titleKey="PCVAdministration:SignupDate"
      descriptionKey="PCVAdministration:SignupDateDescription"
      noteKey="PCVAdministration:SignupDateNote"
      columns={mergedColumns}
      rowKeyFunc={({ surveyorType, signupType }) =>
        `${surveyorType}-${signupType}`
      }
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
      isDataArray
    />
  );
};

export default SignupDate;
