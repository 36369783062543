import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = [
  'speciesName',
  'lookalikeScientificName',
  'lookalikeCharacteristic',
];

const LookalikeSpecies = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t('AssignmentSummary:LookalikeSpeciesDescription')}</p>
      <ContainerWithSimpleTable
        data={data}
        loading={false}
        fields={fields}
        rowKey={uuidv4()}
        scroll={false}
        fullWidth
      />
    </>
  );
};

export default LookalikeSpecies;
