import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { EditableCell } from '../EditableTable/components/EditableCell';
import { ExpandableTable } from '../ExpandableTable';

import classes from '../../views/styles.module.scss';

export const ExpandableEditableTable = ({
  className,
  columns,
  dataSource,
  loading,
  rowKey,
  onChange,
  scroll,
  pagination,
  expandableColumns,
  expandableSubColumns,
  data,
  expandableKey,
  loadingNestedData,
  editableCellsConfig,
  length,
  fetchSubNestedData,
  ...other
}) => {
  const { t } = useTranslation();
  const [newData, setNewData] = useState(data);

  useEffect(() => {
    if (length) {
      while (data.length < length) {
        const item = {
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        }
        data.push(item)
      }
      const newDataArray = data.map((item, index) => {
        const newItem = {...item}
        newItem.spotReserved = index + 1
        return newItem
      })
      setNewData(newDataArray)
    } else {
      setNewData(data)
    }
  }, [data])

  return (
    <Table
      {...other}
      components={{
        body: {
          cell: (args) =>
            EditableCell({ ...args, cellsConfig: editableCellsConfig }),
        },
      }}
      expandable={{
        // eslint-disable-next-line no-unused-vars
        expandedRowRender: (_, index, indent, expanded) =>
          expanded ? (
            <>
              {data.length || loadingNestedData ? (
                <>
                  {expandableSubColumns ? (
                    <ExpandableTable
                      columns={expandableColumns}
                      expandableColumns={expandableSubColumns}
                      dataSource={newData}
                      loading={loadingNestedData}
                      fetchNestedData={fetchSubNestedData}
                    />
                  ) : (
                    <Table
                      className={classNames(classes.expandedTable)}
                      loading={loadingNestedData}
                      columns={expandableColumns}
                      dataSource={newData}
                      scroll={{ x: 1300 }}
                      pagination={false}
                      rowKey={() => uuidv4()}
                    />
                  )}
                </> 
              ) : (
                <p className={classNames(classes.noDataNestedTable)}>
                  {t('table:noData')}
                </p>
              )}
            </>
          ) : null,
        expandedRowKeys: expandableKey,
        expandedRowClassName: () => 'expanded-row',
        expandIcon: () => null,
      }}
      className={className}
      columns={columns}
      rowClassName={(_, index) =>
        `editable-row row-${index % 2 ? 'even' : 'odd'}`
      }
      dataSource={dataSource}
      loading={loading}
      rowKey={rowKey}
      onChange={onChange}
      scroll={scroll}
      pagination={pagination}
    />
  );
};
