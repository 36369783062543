import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: t('table:ownerType'),
      dataIndex: 'ownerType',
      key: 'ownerType',
      sorter: true,
    },
    {
      title: t('table:ownerStatus'),
      dataIndex: 'ownerStatus',
      key: 'ownerStatus',
      sorter: true,
    },
    {
      title: t('table:deleted'),
      dataIndex: 'deleted',
      key: 'deleted',
      sorter: true,
      render: (text) => (text ? 'Yes' : 'No'),
    },
  ];
};
