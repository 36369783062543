import React from 'react';
import { Popover, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { edit } from '../../../shared/EditableTable/tableUtils';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';

const fetchContent = (key) =>
  APIFetch(ENDPOINT_URLS.GET_SURVEY_PLAN_HISTORY_PCVTF(key), {
    method: 'get',
  });

export const actionContent = (
  save,
  editable,
  record,
  cancel,
  editingKey,
  form,
  initialState,
  setEditingKey
) => {
  const { t } = useTranslation();

  const { data } = useAPI(() => fetchContent(record.eokey), {
    initialState: { data: {}, loading: false },
  });

  const popover = (
    <Popover
      content={data?.history || 'No data was returned'}
      trigger="click"
      overlayInnerStyle={{ maxWidth: 500, marginLeft: 80 }}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        style={{
          marginRight: 8,
        }}
      >
        {t('table:history')}
      </a>
    </Popover>
  );

  return editable ? (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        onClick={() => save(record.eokey)}
        style={{
          marginRight: 8,
        }}
      >
        {t('table:update')}
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a onClick={() => cancel()}>{t('table:cancel')}</a>
      {popover}
    </span>
  ) : (
    <Space size="middle">
      <Typography.Link
        disabled={editingKey !== ''}
        onClick={() => {
          edit(record, initialState, form, setEditingKey);
        }}
      >
        {t('table:edit')}
      </Typography.Link>
      {popover}
    </Space>
  );
};
