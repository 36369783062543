import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
} from '../../../shared/sortAndSearchUtils';
import { isCellEditing } from '../../../shared/EditableTable/tableUtils';
import { action } from '../../../shared/EditableTable/components/Action';
import PageWithEditableTable from '../../pages/PageWithEditableTable';
import {
  openNotificationWithIcon,
  getIsForbidden,
} from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';

import { editableCellsConfig } from './form-config';
import { columns } from './TableColumns';
import { actionContent } from './utils';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const PlanQATable = ({ titleKey, descriptionKey, parentFetch }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const [form] = Form.useForm();

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading, refetch } = useAPI(parentFetch, {
    initialState: { data: {}, loading: false },
    shouldFetch: false,
  });

  const [editingKey, setEditingKey] = useState('');
  const [tableData, setData] = useState([]);
  const [isRefetchDone, setIsRefetchDone] = useState(false);
  const REFETCH_CHANGING_TIMEOUT = 2000;

  const cancel = () => {
    setEditingKey('');
  };

  const defaultSortField = [
    'State',
    'SpeciesName',
    'Eonumber',
    'Subpopulation',
  ];
  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);

    refetch(paramsToPassInBodyRequest(parameters, null, defaultSortField));

    setIsRefetchDone(true);
    setTimeout(() => setIsRefetchDone(false), REFETCH_CHANGING_TIMEOUT);
  };

  useEffect(() => {
    if (!isForbidden)
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          null,
          defaultSortField
        )
      );
  }, []);

  const initialState = {
    nextSurveyYear: '',
    nextSeedYear: '',
    surveyInterval: '',
    seedInterval: '',
    surveyComment: '',
  };

  const save = async (key) => {
    const row = await form.validateFields();

    APIFetch(ENDPOINT_URLS.UPDATE_SURVEY_PLAN_QA(key), {
      method: 'put',
      body: JSON.stringify(row),
    }).then(({ status }) => {
      if (status) {
        const newData = [...tableData];
        const index = newData.findIndex((item) => key === item.eokey);

        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, { ...item, ...row });
        } else {
          newData.push(row);
        }

        setData(newData);
        setEditingKey('');

        openNotificationWithIcon('success', 'Data was updated successfully');
      }
    });
  };

  const allColumns = columns();
  const columnsForTable = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      renderFunction: (_, record) => {
        const editable = isCellEditing(
          record,
          editingKey,
          tableData,
          setEditingKey,
          isRefetchDone
        );

        return actionContent(
          save,
          editable,
          record,
          cancel,
          editingKey,
          form,
          initialState,
          setEditingKey
        );
      },
    }),
    ...allColumns,
  ];
  const mergedColumns = columnsForTable.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'surveyComment' ? 'text' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isCellEditing(record, editingKey),
      }),
    };
  });

  return (
    <PageWithEditableTable
      titleKey={titleKey}
      descriptionKey={descriptionKey}
      columns={mergedColumns}
      rowKeyFunc={(record) => record.eokey}
      editableCellsConfig={editableCellsConfig}
      fetch={fetch}
      tableData={tableData}
      setData={setData}
      initialPagination={initialPagination}
      cancel={cancel}
      form={form}
      data={data}
      loading={loading}
    />
  );
};

export default PlanQATable;
