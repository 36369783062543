import React, { useEffect, useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { Form, Button, Spin } from 'antd';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import FormItem from '../../../shared/components/formItem';
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../utils/general';
import { useStore } from '../../../global/stores/store/StoreContext';

import classes from '../../styles.module.scss';
import { formConfig } from './form-config';

const { Item } = Form;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 10 },
};

const fetchSend = (args) =>
  APIFetch(ENDPOINT_URLS.SEND_ORGANIZATIONS_EMAILS, {
    method: 'post',
    body: JSON.stringify(args),
  });

const SendEmailForm = ({ typeOfEmail }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { outreachOrganizationTypesData, updateOutreachOrganizationTypes } =
    useStore();

  useEffect(() => {
    if (!outreachOrganizationTypesData.length)
      updateOutreachOrganizationTypes();
  }, []);

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (typeOfEmail === 'Organization') {
      if (outreachOrganizationTypesData.length) {
        setInitialValues({
          state: '',
          typeOfOrganization: outreachOrganizationTypesData[0].itemKey,
        });
      }
    } else {
      setInitialValues({});
    }
  }, [outreachOrganizationTypesData]);

  const {
    data: sendData,
    loading: sendLoading,
    refetch: sendRefetch,
  } = useAPI(fetchSend, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const onFinish = (values) => {
    const { state, ...other } = values;

    sendRefetch({
      state: state || null,
      ...other,
    });
  };

  useEffect(() => {
    if (typeof sendData === 'boolean' && !sendLoading) {
      let type = '';
      let message = '';

      if (sendData) {
        type = 'success';
        message = 'Email was sent successfully';

        form.resetFields();
      } else {
        type = 'error';
        message = 'Failed send Email';
      }

      openNotificationWithIcon(type, message);
    }
  }, [sendLoading]);

  const onClickCancel = () => {
    form.resetFields();
  };

  return (
    <div>
      <Title level={4}>{t(`PCVAdmin:SendEmailForm${typeOfEmail}Title`)}</Title>
      <p>{t(`PCVAdmin:SendEmailForm${typeOfEmail}Description`)}</p>
      <br />

      {initialValues ? (
        <Form
          {...layout}
          scrollToFirstError
          form={form}
          name="sendEmail"
          onFinish={onFinish}
          initialValues={initialValues}
          validateMessages={validateMessages}
        >
          {formConfig[typeOfEmail].map(({ name, label, input, ...rest }) => (
            <FormItem
              key={name}
              name={name}
              label={label}
              input={input}
              {...rest}
            />
          ))}
          <Item {...tailLayout} className={classNames(classes.formFooter)}>
            <Button type="primary" htmlType="submit" loading={sendLoading}>
              {t('form:sendEmail')}
            </Button>
            <Button onClick={onClickCancel}>{t('form:cancel')}</Button>
          </Item>
        </Form>
      ) : (
        <Spin size="large" />
      )}
    </div>
  );
};

export default SendEmailForm;
