import { parseResponse, parseResponseXMLH } from './FetchDecorators';
import { accessByProgressBarConfig } from '../../../global/accessByRolesConfig';

async function main(url, options) {
  const { body, headers: header, setProgress } = options || {};

  const xhr = new XMLHttpRequest();
  const success = await new Promise((resolve) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        setProgress(Math.round((event.loaded / event.total) * 100));
      }
    });

    xhr.addEventListener('loadend', ({ target }) => {
      const { status, statusText, response } = target || {};
      const obj = JSON.parse(response);
      resolve({ status, statusText, data: obj })
    });
    xhr.open(options.method, url, true);
    xhr.setRequestHeader('authorization', header.get('authorization'));
    xhr.send(body);
  })
  return success;
};

export const Fetch = async (...params) => {
  const [url, options] = params;
  const isAccessProgress = accessByProgressBarConfig
    .find(accessUrl => url.includes(accessUrl));

  try {
    let result;

    if (isAccessProgress) {
      const response = await main(url, options);
      result = await parseResponseXMLH(response);
    } else {
      const response = await fetch(url, options);
      result = await parseResponse(response);
    };

    return result;
  } catch (e) {
    return e;
  }
};