import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:contactDate'),
      dataIndex: 'contactDate',
      key: 'contactDate',
      editable: true,
      sorter: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:contactMethod'),
      dataIndex: 'contactMethod',
      key: 'contactMethod',
      editable: true,
      sorter: true,
      width: 100,
    },
    {
      title: t('table:nptcontactPerson'),
      dataIndex: 'nptcontactPerson',
      key: 'nptcontactPerson',
      width: 120,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:ownerPermission'),
      dataIndex: 'ownerPermission',
      key: 'ownerPermission',
      editable: true,
      sorter: true,
      width: 120,
    },
    {
      title: t('table:ownerComment'),
      dataIndex: 'ownerComment',
      key: 'ownerComment',
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: t('table:currentYearAttempts'),
      dataIndex: 'currentYearAttempts',
      key: 'currentYearAttempts',
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:speciesName'),
      width: 100,
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:survey'),
      width: 90,
      dataIndex: 'survey',
      key: 'survey',
      sorter: true,
    },
    {
      title: t('table:collectSeed'),
      dataIndex: 'collectSeed',
      key: 'collectSeed',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:town'),
      width: 90,
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:latitude'),
      dataIndex: 'latitude',
      key: 'latitude',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:longitude'),
      dataIndex: 'longitude',
      key: 'longitude',
      sorter: true,
      width: 100,
    },
    {
      title: t('table:locationPrecision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      sorter: true,
      width: 90,
    },
  ];
};
