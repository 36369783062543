import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:characterGroup'),
      dataIndex: 'characterGroup',
      key: 'characterGroup',
      sorter: true,
    },
    {
      title: t('table:characterName'),
      dataIndex: 'characterName',
      key: 'characterName',
      sorter: true,
    },
    {
      title: t('table:friendlyName'),
      dataIndex: 'friendlyName',
      key: 'friendlyName',
      sorter: true,
    },
    {
      title: t('table:valueString'),
      dataIndex: 'valueString',
      key: 'valueString',
      sorter: true,
    },
    {
      title: t('table:valueMin'),
      dataIndex: 'valueMin',
      key: 'valueMin',
      sorter: true,
    },
    {
      title: t('table:valueMax'),
      dataIndex: 'valueMax',
      key: 'valueMax',
      sorter: true,
    },
    {
      title: t('table:unit'),
      dataIndex: 'unit',
      key: 'unit',
      sorter: true,
    },
    {
      title: t('table:friendlyText'),
      dataIndex: 'friendlyText',
      key: 'friendlyText',
      sorter: true,
    },
    {
      title: t('table:easeOfObservability'),
      dataIndex: 'easeOfObservability',
      key: 'easeOfObservability',
      sorter: true,
    },
  ];
};
