import React from 'react';

import StatesSelect from '../../../shared/components/select/StatesSelect';
import OutreachOrganizationTypesSelect from '../../../shared/components/select/OutreachOrganizationTypesSelect';

export const formConfig = {
  Organization: [
    {
      name: 'state',
      label: 'State',
      input: (props) => <StatesSelect {...props} width="100%" withAll />,
    },
    {
      name: 'typeOfOrganization',
      label: 'Organization type',
      input: (props) => (
        <OutreachOrganizationTypesSelect {...props} width="100%" />
      ),
    },
  ],
};
