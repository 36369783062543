import React from 'react';
import { Popover } from 'antd';

import { useTranslation } from 'react-i18next';

export const actionContent = (record) => {
  const { t } = useTranslation();

  return (
      <Popover
        content={record?.ownerComment || 'No comment was returned'}
        trigger="click"
        overlayInnerStyle={{ maxWidth: 500, marginLeft: 80 }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a
          style={{
            marginRight: 8,
          }}
        >
          {t('table:comment')}
        </a>
      </Popover>
  );
};
