import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { useAPI } from '../../hooks/use-api/useAPI';
import { openNotificationWithIcon } from '../../../utils/general';

const { confirm } = Modal;

const Uploader = ({
  maxCount,
  value,
  record,
  onDelete,
  manually = false,
  maxSize = NaN,
  ...args
}) => {
  const [fileList, setFileList] = useState(value ? [value] : []);
  const [isCustomDelete, setIsCustomDelete] = useState(!!onDelete);

  const {
    data: dataDelete,
    loading: loadingDelete,
    refetch: refetchDelete,
  } = useAPI(onDelete, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (value) {
      setFileList([value]);
    }
    if (typeof value === 'string') {
      setFileList([
        {
          name: value,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (dataDelete && !loadingDelete) {
      if (dataDelete.ownerKey) {
        openNotificationWithIcon('success', 'File was deleted successfully');
      }
    }
  }, [dataDelete, loadingDelete]);

  const removeFile = (file) =>
    setFileList(fileList.filter(({ uid }) => uid !== file.uid));

  const props = {
    ...args,
    value,
    maxCount,
    beforeUpload: (file) => {
      if (fileList.length >= maxCount) {
        message.error(
          `You can upload only ${maxCount} file${maxCount > 1 ? 's' : ''}`
        );

        return Upload.LIST_IGNORE;
      }

      if (!Number.isNaN(maxSize) && file.size > maxSize) {
        message.error('File size too large, greater than 20MB');

        return Upload.LIST_IGNORE;
      }
      setFileList([...fileList, file]);

      return !manually;
    },
    onRemove: (file) => {
      if (isCustomDelete) {
        confirm({
          title: 'Delete',
          content: 'Are you sure you want to delete?',
          okText: 'Delete',
          onOk() {
            refetchDelete(record);
            removeFile(file);
            setIsCustomDelete(false);
          },
        });
      } else {
        removeFile(file);
      }
    },
    fileList,
  };

  return (
    <Upload {...props}>
      <Button icon={<UploadOutlined />}>Upload file</Button>
    </Upload>
  );
};

export default Uploader;
