import React, { useEffect, useState } from 'react';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, Link, useHistory } from 'react-router-dom';
import classNames from 'clsx';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithTable from '../../../pages/PageWithTable';
import {
  openNotificationWithIcon,
  paramsForBE,
} from '../../../../utils/general';
import { useAuth } from '../../../../global/stores/auth';
import { roleTypes } from '../../../../utils/roles';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

import classes from '../../../styles.module.scss';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

const fetchDelete = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.DELETE_SURVEYOR(key), {
        method: 'delete',
      })
    : null;

const SurveyorDisplayAndEdit = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  const { user } = useAuth();
  const role = user?.role;

  const [isForceRefetch, setIsForceRefetch] = useState(false);

  const {
    data: deleteData,
    loading: deleteLoading,
    refetch: refetchDelete,
  } = useAPI(fetchDelete, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (deleteData) {
      const { surveyorKey } = deleteData;

      if (surveyorKey) {
        openNotificationWithIcon('success', 'Data was deleted successfully');

        setIsForceRefetch(true);
      } else {
        openNotificationWithIcon('error', 'Failed delete Data');
      }
    }
  }, [deleteData]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 120,
      render: (_, { surveyorKey }) => (
        <>
          <Link
            style={{
              marginRight: 8,
            }}
            to={`${pathname}/${surveyorKey}`}
          >
            {t('table:edit')}
          </Link>
          <Popconfirm
            placement="top"
            title="Delete this item?"
            onConfirm={() => refetchDelete(surveyorKey)}
            okText="Yes"
            cancelText="No"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a>{t('table:delete')}</a>
          </Popconfirm>
        </>
      ),
    },
    ...staticColumns,
  ];

  const create = () => {
    history.push({
      pathname: `${pathname}/Create`,
    });
  };

  const { titleKey, descriptionKey } =  role === roleTypes.Heritage
    ? {
      titleKey: "Surveyors:DisplayTitle",
      descriptionKey: "Surveyors:DisplayDescription",
    } : {
      titleKey: "Surveyors:DisplayAndEditTitle",
      descriptionKey: "Surveyors:DisplayAndEditDescription",
    };

  return (
    <PageWithTable
      fetchData={fetch}
      fetchExport={fetchExport}
      defaultSortField={['LastName', 'FirstName']}
      titleKey={titleKey}
      descriptionKey={descriptionKey}
      columns={role === roleTypes.Administrator ? allColumns : staticColumns}
      rowKey={(record) => record.ffkey}
      scroll={{ x: 1700 }}
      searchForm={SearchForm}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
      isForceRefetch={isForceRefetch}
      forceLoading={deleteLoading}
    >
      {role === roleTypes.Administrator && (
        <div className={classNames(classes.content)}>
          <Button onClick={create}>Create Non-User Surveyor</Button>
        </div>
      )}
    </PageWithTable>
  );
};

export default SurveyorDisplayAndEdit;
