import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory, Link } from 'react-router-dom';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithTable from '../../../pages/PageWithTable';

import { columns } from './TableColumns';

const bodyForFirstRequest = {
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: [
    {
      orderOption: 'Ascending',
      propertyName: 'Year',
    },
  ],
};

const fetchFieldForm = (args, key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_FIELD_DATA_SPROUT_PCVTF(key), {
        method: 'post',
        body: args ? JSON.stringify(args) : JSON.stringify(bodyForFirstRequest),
      })
    : {};

const SurveySPROUTFieldForm = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { pathname, search: searchKey } = useLocation();
  const query = new URLSearchParams(searchKey);
  const keyFromUrl = query.getAll('eokey');
  const nameFromUrl = query.getAll('name')[0];

  useEffect(() => {
    if (nameFromUrl) {
      history.replace({
        state: {
          breadcrumbsName: nameFromUrl,
        },
        search: searchKey,
      });
    }
  }, []);

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 70,
      render: (_, record) => (
        <Link to={`${pathname}/SproutFieldFormDetail?ffkey=${record.ffkey}`}>
          {t('table:select')}
        </Link>
      ),
    },
    ...staticColumns,
  ];

  return (
    <PageWithTable
      fetchData={(args) => fetchFieldForm(args, keyFromUrl[0])}
      defaultSortField="Year"
      titleKey="PCVTF:SPROUTFieldForm"
      descriptionKey="PCVTF:SPROUTFieldFormDescription"
      columns={allColumns}
      scroll={{ x: 1300 }}
    />
  );
};

export default SurveySPROUTFieldForm;
