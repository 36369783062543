import React, { useEffect, useState } from 'react';
import classNames from 'clsx';
import { useLocation } from 'react-router-dom';

import { useStore } from '../../../global/stores/store/StoreContext';
import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';
import { getIsForbidden } from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';

// eslint-disable-next-line import/named
import { feSearchConfig } from './form-config';

import classes from '../../styles.module.scss';

const SearchForm = ({ tableLoading }) => {
  const { pathname } = useLocation();

  const {
    surveyResultStatesData,
    surveyResultStatesLoading,
    updateSurveyResultStates,
  } = useStore();
  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  useEffect(() => {
    if (
      !surveyResultStatesData.length &&
      !surveyResultStatesLoading &&
      !isForbidden
    )
      updateSurveyResultStates();
  }, []);

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (surveyResultStatesData.length) {
      setInitialValues({
        State: surveyResultStatesData[0].itemKey,
      });
    }
  }, [surveyResultStatesData]);

  return (
    <div className={classNames(classes.searchForm)}>
      {initialValues && (
        <CustomSearchForm
          searchConfig={feSearchConfig}
          initialValues={initialValues}
          loading={surveyResultStatesLoading || tableLoading}
        />
      )}
    </div>
  );
};

export default SearchForm;
