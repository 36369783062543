import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:leadSurvName'),
      width: 120,
      dataIndex: 'leadSurvName',
      key: 'leadSurvName',
      editable: true,
      sorter: true,
    },
    {
      title: t('table:surv2Name'),
      width: 120,
      dataIndex: 'surv2Name',
      key: 'surv2Name',
      editable: true,
      sorter: true,
    },
    {
      title: t('table:surv3Name'),
      dataIndex: 'surv3Name',
      key: 'surv3Name',
      width: 120,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:surv4Name'),
      dataIndex: 'surv4Name',
      key: 'surv4Name',
      width: 120,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:survey'),
      width: 100,
      dataIndex: 'survey',
      key: 'survey',
      sorter: true,
    },
    {
      title: t('table:collectSeed'),
      width: 120,
      dataIndex: 'collectSeed',
      key: 'collectSeed',
      sorter: true,
    },
    {
      title: t('table:surveyComment'),
      width: 130,
      dataIndex: 'surveyComment',
      key: 'surveyComment',
      sorter: true,
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 130,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 400,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:tfpcv'),
      width: 100,
      dataIndex: 'tfpcv',
      key: 'tfpcv',
      sorter: true,
    },
    {
      title: t('table:state'),
      width: 100,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      width: 100,
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:commonName'),
      dataIndex: 'commonName',
      key: 'commonName',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:eonumber'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      width: 100,
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:town'),
      width: 100,
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:county'),
      dataIndex: 'county',
      key: 'county',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:lastSurvey'),
      width: 100,
      dataIndex: 'lastSurvey',
      key: 'lastSurvey',
      sorter: true,
    },
    {
      title: t('table:lastObs'),
      width: 130,
      dataIndex: 'lastObs',
      key: 'lastObs',
      sorter: true,
    },
    {
      title: t('table:firstRecog'),
      width: 100,
      dataIndex: 'firstRecog',
      key: 'firstRecog',
      sorter: true,
    },
    {
      title: t('table:lastRecog'),
      dataIndex: 'lastRecog',
      key: 'lastRecog',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:firstFlower'),
      dataIndex: 'firstFlower',
      key: 'firstFlower',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:lastFlower'),
      width: 130,
      dataIndex: 'lastFlower',
      key: 'lastFlower',
      sorter: true,
    },
    {
      title: t('table:firstFruit'),
      width: 100,
      dataIndex: 'firstFruit',
      key: 'firstFruit',
      sorter: true,
    },
    {
      title: t('table:lastFruit'),
      width: 100,
      dataIndex: 'lastFruit',
      key: 'lastFruit',
      sorter: true,
    },
    {
      title: t('table:firstSeed'),
      dataIndex: 'firstSeed',
      key: 'firstSeed',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:lastSeed'),
      dataIndex: 'lastSeed',
      key: 'lastSeed',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:phenologyNote'),
      dataIndex: 'phenologyNote',
      key: 'phenologyNote',
      width: 100,
      sorter: true,
    },
  ];
};
