import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const SourceOwnersSelect = (props) => {
  const { sourceOwnersData, sourceOwnersLoading, updateSourceOwners } =
    useStore();

  useEffect(() => {
    if (!sourceOwnersData.length && !sourceOwnersLoading) updateSourceOwners();
  }, []);

  return (
    <Select
      props={props}
      data={sourceOwnersData}
      loading={sourceOwnersLoading}
    />
  );
};

export default SourceOwnersSelect;
