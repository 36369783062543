import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:contactDate'),
      dataIndex: 'contactDate',
      key: 'contactDate',
      editable: true,
      sorter: true,
      width: 140,
      render: (text) => (text ? getMomentDate(text).format('YYYY-MM-DD') : ''),
    },
    {
      title: t('table:contactMethod'),
      dataIndex: 'contactMethod',
      key: 'contactMethod',
      editable: true,
      sorter: true,
      width: 100,
    },
    {
      title: t('table:nptcontactPerson'),
      dataIndex: 'nptcontactPerson',
      key: 'nptcontactPerson',
      width: 120,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:ownerPermission'),
      dataIndex: 'ownerPermission',
      key: 'ownerPermission',
      editable: true,
      sorter: true,
      width: 120,
    },
    {
      title: t('table:ownerComment'),
      dataIndex: 'ownerComment',
      key: 'ownerComment',
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: t('table:currentYearAttempts'),
      dataIndex: 'currentYearAttempts',
      key: 'currentYearAttempts',
      sorter: true,
      editable: true,
      width: 100,
    },
    {
      title: t('table:organization'),
      dataIndex: 'organization',
      key: 'organization',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:subOrganization'),
      dataIndex: 'subOrganization',
      key: 'subOrganization',
      sorter: true,
      width: 120,
    },
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:survey'),
      width: 90,
      dataIndex: 'survey',
      key: 'survey',
      sorter: true,
    },
    {
      title: t('table:collectSeed'),
      dataIndex: 'collectSeed',
      key: 'collectSeed',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      width: 70,
    },
    {
      title: t('table:phone'),
      width: 100,
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:pastContactYearEO'),
      dataIndex: 'pastContactYear',
      key: 'pastContactYear',
      width: 90,
      sorter: true,
    },
    {
      title: t('table:pastContactMethodEO'),
      dataIndex: 'pastContactMethod',
      key: 'pastContactMethod',
      sorter: true,
      width: 90,
    },
    {
      title: t('table:nptnotes'),
      width: 90,
      dataIndex: 'nptnotes',
      key: 'nptnotes',
      sorter: true,
    },
  ];
};
