import React from 'react';

import DaysAvailableToSurveySelect from '../../../shared/components/select/DaysAvailableToSurveySelect';

export const formConfig = [
  {
    name: 'firstName',
    label: 'First Name',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'lastName',
    label: 'Last Name',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'title',
    label: 'Title',
  },
  {
    name: 'jobTitle',
    label: 'Job Title',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'organization',
    label: 'Organization',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'phone',
    label: 'Phone number',
    extra: 'Please separate multiple phone numbers by commas.',
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'address1',
    label: 'Address 1',
    rules: [
      {
        required: true,
      },
      {
        max: 50,
      },
    ],
  },
  {
    name: 'address2',
    label: 'Address 2',
    rules: [
      {
        max: 50,
      },
    ],
  },
  {
    name: 'city',
    label: 'City',
    rules: [
      {
        required: true,
      },
      {
        max: 50,
      },
    ],
  },
  {
    name: 'state',
    label: 'State',
    rules: [
      {
        required: true,
      },
      {
        len: 2,
      },
    ],
  },
  {
    name: 'postalCode',
    label: 'Postcode',
    rules: [
      {
        required: true,
      },
      {
        max: 6,
      },
    ],
  },
  {
    name: 'statesForSurveys',
    label: 'States For Surveys',
  },
  {
    name: 'daysAvailableToSurvey',
    label: 'Days available to survey',
    input: (props) => <DaysAvailableToSurveySelect {...props} />,
    rules: [{ required: true }],
    operatortype: 'Equals',
  },
  {
    name: 'emergencyContactName',
    label: 'Emergency contact name',
    rules: [
      {
        required: false,
      },
    ],
  },
  {
    name: 'emergencyContactPhoneNumber',
    label: 'Emergency contact phone number',
    rules: [
      {
        required: false,
      },
    ],
  },
  {
    name: 'emergencyContactEmailAddress',
    label: 'Emergency contact email address',
    rules: [
      {
        type: 'email',
        max: 80,
      },
    ],
  },
];
