import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import ContainerWithSimpleTable from '../../../containers/ContainerWithSimpleTable';

const fields = ['speciesName', 'commonName'];

const NearbySpecies = ({ data }) => (
  <ContainerWithSimpleTable
    titleKey="PCVAdministration:NearbySpecies"
    descriptionKey="PCVAdministration:NearbySpeciesDescription"
    data={data}
    loading={false}
    fields={fields}
    rowKey={uuidv4()}
    fullWidth
  />
);

export default NearbySpecies;
