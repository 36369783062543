import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../config';
import Section from '../Section';

const SurveyorPage = () => {
  const { t } = useTranslation();
  return (
    <PageContent>
      <Section
        config={sectionsConfig().surveyors}
        title={t('sections:Surveyors:title')}
      />
    </PageContent>
  );
};

export default SurveyorPage;
