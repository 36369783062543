import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { useAPI } from '../../shared/hooks/use-api/useAPI';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
} from '../../shared/sortAndSearchUtils';
import { downloadExcel } from '../../utils/general';

import classes from '../styles.module.scss';

const initialPagination = {
  pageNumber: 1,
  pageSize: 20,
};

const TableUnderTab = ({
  fetchData,
  fetchExport,
  fetchDownload,
  defaultSortField,
  paramsForBE,
  beSearchConfig,
  searchForm,
  columns,
  rowKey,
  isDataArray,
  scroll,
  paramsForRequest,
  ...other
}) => {
  const { t } = useTranslation();
  const { pathname, search: searchParams } = useLocation();

  const { data, loading, refetch } = useAPI(fetchData, {
    initialState: { data: {}, loading: false },
    shouldFetch: false,
  });

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const {
    data: downloadData,
    loading: downloadLoading,
    refetch: refetchDownload,
  } = useAPI(fetchDownload, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [searchParameters, setSearchParameters] = useState({});

  const [pagination, setPagination] = useState(initialPagination);

  const [downloadBody, setDownloadBody] = useState({});

  const onExport = () => {
    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    refetchExport(
      paramsToPassInBodyRequest(
        {
          pagination: initialPagination,
        },
        paramsForBE
          ? paramsForBE(searchParamsArray, beSearchConfig)
          : searchParamsArray,
        defaultSortField
      )
    );
  };

  const onDownload = () => {
    refetchDownload(downloadBody);
  };

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );

  useEffect(
    () => downloadExcel(pathname, downloadData, refetchDownload),
    [downloadData]
  );

  const renderContent = () => (
    <>
      {!!fetchExport && (
        <div>
          <Button type="primary" onClick={onExport} loading={exportLoading}>
            Export to Excel
          </Button>
        </div>
      )}
    </>
  );

  const renderDownload = () => (
    <>
      {!!fetchDownload && (
        <div>
          <Button type="primary" onClick={onDownload} loading={downloadLoading}>
            Download
          </Button>
        </div>
      )}
    </>
  );

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;

    refetch(
      paramsToPassInBodyRequest(
        parameters,
        searchForm ? searchParam : null,
        defaultSortField
      )
    );
    setDownloadBody(
      paramsToPassInBodyRequest(
        parameters,
        searchForm ? searchParam : null,
        defaultSortField
      )
    );
  };

  const handleTableChange = (paginationForRequest, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: paginationForRequest,
      ...filters,
    });
  };

  useEffect(() => {
    if (!searchForm) {
      refetch(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          null,
          defaultSortField
        )
      );
      setDownloadBody(
        paramsToPassInBodyRequest(
          {
            pagination: initialPagination,
          },
          null,
          defaultSortField
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!isDataArray && Object.keys(data).length) {
      setPagination({
        pageSize: data?.pagedInfo?.pageSize,
        pageNumber: data?.pagedInfo?.pageNumber,
        total: data?.totalItemsCount,
      });
    }
  }, [data]);

  useEffect(() => {
    if (searchForm) {
      if (Object.keys(data).length && !searchParams) {
        refetch();

        return;
      }

      const searchParamsArray = paramsForRequest
        ? paramsForRequest
            .map((item) =>
              buildSearchParamsForRequest(searchParams).filter(
                (i) => i.propertyName === item
              )
            )
            .map((i) => i[0])
        : buildSearchParamsForRequest(searchParams);

      if (searchParamsArray.length || Object.keys(data).length) {
        refetch(
          paramsToPassInBodyRequest(
            {
              pagination: initialPagination,
            },
            paramsForBE
              ? paramsForBE(searchParamsArray, beSearchConfig)
              : searchParamsArray,
            defaultSortField
          )
        );
        setDownloadBody(
          paramsToPassInBodyRequest(
            {
              pagination: initialPagination,
            },
            paramsForBE
              ? paramsForBE(searchParamsArray, beSearchConfig)
              : searchParamsArray,
            defaultSortField
          )
        );

        setSearchParameters(searchParamsArray);
      }
    }
  }, [searchParams]);

  return (
    <>
      {isDataArray ? (
        <>
          {data.length ? (
            <>
              {renderContent()}
              {renderDownload()}
              <Table
                {...other}
                columns={columns}
                className={classNames(classes.table, classes.tableFullWidth)}
                rowKey={rowKey || uuidv4()}
                dataSource={data}
                loading={loading}
                onChange={handleTableChange}
                pagination={false}
                scroll={scroll}
              />
              <p className={classNames(classes.simpleTableTotal)}>
                Total:{' '}
                {data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </p>
            </>
          ) : null}
          {!data.length && loading ? (
            <center>
              <Spin />
            </center>
          ) : null}
        </>
      ) : (
        <>
          {Object.keys(data).length && data.items.length ? (
            <>
              {renderContent()}
              {renderDownload()}
              <Table
                {...other}
                columns={columns}
                className={classNames(classes.table, classes.tableFullWidth)}
                rowKey={rowKey || uuidv4()}
                dataSource={data.items}
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                  pageSizeOptions: [20, 50, 100],
                  current: pagination.pageNumber,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
                scroll={scroll}
              />
              <p className={classNames(classes.tableTotal)}>
                Total:{' '}
                {data.totalItemsCount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </p>
            </>
          ) : null}
          {Object.keys(data).length && !data.items.length ? (
            <p className={classNames(classes.noData)}>{t('table:noData')}</p>
          ) : null}
          {!Object.keys(data).length && loading ? (
            <center>
              <Spin />
            </center>
          ) : null}
        </>
      )}
    </>
  );
};

export default TableUnderTab;
