import { Input } from 'antd';

import { validatePassword, confirmPassword } from '../utils';

export const formConfig = [
  {
    name: 'oldPassword',
    label: 'Old Password',
    input: Input.Password,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'newPassword',
    label: 'New Password',
    input: Input.Password,
    rules: [
      {
        required: true,
      },
      ...validatePassword,
    ],
  },
  {
    name: 'confirmedPassword',
    label: 'Confirm New Password',
    input: Input.Password,
    dependencies: ['password'],
    hasFeedback: true,
    rules: [
      {
        required: true,
      },
      ...confirmPassword,
    ],
  },
];
