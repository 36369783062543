import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import PageWithTable from '../../../pages/PageWithTable';
import { action } from '../../../../shared/EditableTable/components/Action';
import { paramsForBE } from '../../../../utils/general';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_USERS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const refetchBlockUser = (userId, loginAllowed) =>
  userId
    ? APIFetch(ENDPOINT_URLS.BLOCK_USER(userId, loginAllowed), {
        method: 'put',
      })
    : null;

const BlockUsers = () => {
  const { t } = useTranslation();

  const [updateTableData, setUpdateTableData] = useState(false);
  const actionContent = (record) => {
    const { id, loginAllowed } = record;

    const label = loginAllowed ? 'Block' : 'Unblock';

    return (
      <span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <a
          onClick={() => {
            refetchBlockUser(id, !loginAllowed)
              .then(() => {
                setUpdateTableData(true);
              })
              .then(() => setUpdateTableData(false));
          }}
        >
          <span>{label}</span>
        </a>
      </span>
    );
  };

  const staticColumns = columns();
  const allColumns = [
    action({
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 70,
      renderFunction: (_, record) => actionContent(record),
    }),
    ...staticColumns,
  ];

  return (
    <PageWithTable
      descriptionKey="PCVAdministration:blockUsersDescription"
      fetchData={fetch}
      defaultSortField={['LastName', 'FirstName']}
      columns={allColumns}
      isWithoutPageContent
      isForceRefetch={updateTableData}
      searchForm={SearchForm}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
    />
  );
};

export default BlockUsers;
