import React from 'react';
import { Input } from 'antd';

import StatesSelect from '../../../shared/components/select/StatesSelect';
import EOStatusesSelect from '../../../shared/components/select/EOStatusesSelect';
import TFPCVSelect from '../../../shared/components/select/TFPCVSelect';
import { dateValidationRules, getRequiredErrMsg } from '../../../utils/general';

const { TextArea } = Input;

export const searchFormConfig = [
  {
    name: 'SpeciesName',
    label: 'Element occurrence species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'Town',
    label: 'ElementOccurrence town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SiteName',
    label: 'ElementOccurrence sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <StatesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
  {
    name: 'NextSurveyYear',
    label: 'Survey year from',
    rules: [
      dateValidationRules('Survey year'),
      {
        required: true,
      },
    ],
    operatortype: 'GreaterThan',
  },
  {
    name: 'NextSeedYear',
    label: 'Survey year to',
    rules: [
      dateValidationRules('Survey year to'),
      {
        required: true,
      },
    ],
    operatortype: 'LessThan',
  },
  {
    name: 'SurveyInterval',
    label: 'Seed year from',
    rules: [
      dateValidationRules('Seed year'),
      {
        required: true,
      },
    ],
    operatortype: 'GreaterThan',
  },
  {
    name: 'SeedInterval',
    label: 'Seed year to',
    rules: [
      dateValidationRules('Seed year to'),
      {
        required: true,
      },
    ],
    operatortype: 'LessThan',
  },
  {
    name: 'Eostatus',
    label: 'EOStatus',
    input: (props) => <EOStatusesSelect {...props} withAll />,
    operatortype: 'Equals',
  },
];

export const editableCellsConfig = [
  {
    name: 'nextSurveyYear',
    rules: [
      dateValidationRules('NextSurveyYear'),
      {
        required: true,
        message: getRequiredErrMsg('NextSurveyYear'),
      },
    ],
  },
  {
    name: 'nextSeedYear',
    rules: [
      dateValidationRules('NextSeedYear'),
      {
        required: true,
        message: getRequiredErrMsg('NextSeedYear'),
      },
    ],
  },
  {
    name: 'surveyInterval',
    rules: [
      {
        type: 'number',
        min: -1,
        max: 30,
        message: `SurveyInterval must be between -1 and 30`,
      },
      {
        required: true,
        message: getRequiredErrMsg('SurveyInterval'),
      },
    ],
  },
  {
    name: 'seedInterval',
    rules: [
      {
        type: 'number',
        min: -1,
        max: 30,
        message: `SeedInterval must be between -1 and 30`,
      },
      {
        required: true,
        message: getRequiredErrMsg('SeedInterval'),
      },
    ],
  },
  {
    name: 'surveyComment',
    rules: [
      {
        max: 2000,
        message: `Should contain less than 2000 characters.`,
      },
    ],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'tfpcv',
    rules: [
      {
        required: true,
        message: `Please Input TFPCV!`,
      },
    ],
    input: (props) => <TFPCVSelect {...props} />,
  },
];
