import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
    'surveyorType1',
    'description',
];

const fetchSurveyorType = () =>
    APIFetch(ENDPOINT_URLS.GET_SURVEYOR_TYPE_DATA, {
        method: 'post',
        body: JSON.stringify([{
            "orderOption": "Ascending",
            "propertyName": "SurveyorType1"
        }]),
    });

const SurveyorType = () => {
    const { data, loading } = useAPI(() => fetchSurveyorType(), {
        initialState: { data: [], loading: false },
    });

    return (
        <PageWithSimpleTable
            titleKey="Validation:SurveyorType"
            data={data}
            loading={loading}
            fields={fields}
            rowKey={(record) => record.state}
        />
    );
};

export default SurveyorType;