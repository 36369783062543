import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'division',
  'eototal',
  'eoactive',
  'eoinactive',
  'eoinactiveSprout',
  'eohistorical',
  'eoextirpated',
];

const fetchElementOccurrenceDivisionStatus = () =>
  APIFetch(ENDPOINT_URLS.GET_EL_OCC_DIV_STATUS, {
    method: 'get',
  });

const fetchExport = () =>
  APIFetch(ENDPOINT_URLS.GET_EL_OCC_DIV_STATUS_EXCEL, {
    method: 'get',
  });

export const DivisionStatus = () => {
  const { data, loading } = useAPI(
    () => fetchElementOccurrenceDivisionStatus(),
    {
      initialState: { data: [], loading: false },
    }
  );

  const tableRows = highlightTotalValue('division', data);

  return (
    <PageWithSimpleTable
      titleKey="ElementOccurrence:ElementOccurrenceDivisionStatus"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.division}
      fetchExport={fetchExport}
    />
  );
};
