import React from 'react';
import { Input } from 'antd';

import SurveyResultStatesSelect from '../../../shared/components/select/SurveyResultStatesSelect';

const { TextArea } = Input;

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'EO Species name',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'Town',
    label: 'EO Town',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'SiteName',
    label: 'EO Sitename',
    rules: [
      {
        required: true,
      },
    ],
    operatortype: 'Contains',
  },
  {
    name: 'State',
    label: 'State',
    input: (props) => <SurveyResultStatesSelect {...props} />,
    operatortype: 'Equals',
  },
];

export const editableCellsConfig = [
  {
    name: 'surveyComment',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
];

export const beSearchConfig = [
  {
    propertyName: 'SpeciesName',
    operatortype: 'Contains',
  },
  {
    propertyName: 'Town',
    operatortype: 'Contains',
  },
  {
    propertyName: 'SiteName',
    operatortype: 'Contains',
  },
  {
    propertyName: 'State',
    operatortype: 'Equals',
  },
  {
    propertyName: 'Synonyms',
    operatortype: 'Contains',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    propertyName: 'Town',
    operatortype: 'Contains',
  },
  {
    propertyName: 'SiteName',
    operatortype: 'Contains',
  },
  {
    propertyName: 'State',
    operatortype: 'Equals',
  },
];
