import React from 'react';
import classNames from 'clsx';

import { SearchForm as CustomSearchForm } from '../../../shared/components/search-form';
import { LIST_OF_NUMBERS } from '../../../global/constants';

import { searchFormConfig } from './search-form-config';

import classes from '../../styles.module.scss';

const SearchForm = ({ tableLoading }) => (
  <div className={classNames(classes.searchForm)}>
    <CustomSearchForm
      searchConfig={searchFormConfig}
      initialValues={{
        nbrEos: LIST_OF_NUMBERS[0].itemKey,
      }}
      loading={tableLoading}
    />
  </div>
);

export default SearchForm;
