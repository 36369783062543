import React, { useState, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Spin } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import { useStore } from '../../../global/stores/store/StoreContext';
import PageContent from '../../../shared/components/PageContent';
import FormItem from '../../../shared/components/formItem';
import StatesSelect from '../../../shared/components/select/StatesSelect';
import { Autocomplete } from '../../../shared/components/autocomplete/Autocomplete';
import { validateMessages, getIsForbidden } from '../../../utils/general';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { Map as CustomMap } from '../../../shared/components/Map';
import { useAuth } from '../../../global/stores/auth';

import { getCoordinates } from './utils';

import classes from '../../styles.module.scss';

const { Item } = Form;

const fetch = (key) =>
  key
    ? APIFetch(ENDPOINT_URLS.GET_BA_EO_COORDINATES(key), {
        method: 'get',
      })
    : {};

const fetchEOs = (state) =>
  APIFetch(ENDPOINT_URLS.GET_BA_ALL_EOS_FOR_STATE(state), {
    method: 'get',
  });

const Map = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, search: searchParams } = useLocation();

  const [form] = Form.useForm();

  const { statesData, updateStates } = useStore();
  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  useEffect(() => {
    if (!statesData.length && !isForbidden) updateStates();
  }, []);

  const [initialValues, setInitialValues] = useState();
  const [coordinates, setCoordinates] = useState();
  const [center, setCenter] = useState();
  const [cancel, setCancel] = useState();
  const [zoom, setZoom] = useState();

  const { data, loading, refetch } = useAPI(fetch, {
    initialState: { data: {}, loading: false },
    shouldFetch: false,
  });

  const {
    data: eosData,
    loading: eosLoading,
    refetch: refetchEOs,
  } = useAPI(fetchEOs, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (statesData.length) {
      setInitialValues({
        state: statesData[0].itemKey,
      });

      if (searchParams) {
        const query = new URLSearchParams(searchParams);
        const state = query.get('state');
        const eo = query.get('eo');

        refetchEOs(state);

        form.setFieldsValue({ state, eo });
      } else {
        refetchEOs(statesData[0].itemKey);
      }
    }
  }, [statesData]);

  const onFinish = (values) => {
    const mappedSearchParams = Object.keys(values).map((key) => ({
      [key]: values[key],
    }));

    const newSearchParams = mappedSearchParams.reduce((acc, item) => {
      const key = Object.keys(item)[0];
      return { ...acc, [key]: item[key] };
    }, {});

    const searchParamsString = new URLSearchParams(newSearchParams).toString();

    history.push({
      pathname,
      search: searchParamsString,
    });

    setCancel(false);
  };

  const onClickCancel = () => {
    form.resetFields();

    refetchEOs(statesData[0].itemKey);
    setCoordinates([]);

    history.push({
      pathname,
      search: '',
    });
  };

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      refetch();

      return;
    }

    const query = new URLSearchParams(searchParams);
    const eo = query.getAll('eo')[0];

    if (eo && eosData.length) {
      const eokey = eosData.find(({ item }) => item === eo)?.itemKey;

      if (eokey) refetch(eokey);
    }
  }, [searchParams, eosData]);

  const onValuesChange = (values) => {
    if (values.state) {
      form.setFieldsValue({
        eo: '',
      });

      refetchEOs(values.state);
    }
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      const newCoordinates = getCoordinates(data);

      setCoordinates(newCoordinates);
      setCenter({ lat: newCoordinates[0].lat, lng: newCoordinates[0].lng });
      if (newCoordinates.length > 0) setZoom(6);
    }
  }, [data]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t('PCVAdmin:Map')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVAdmin:MapDescription')}
      </p>
      <br />
      <Row>
        <Col span={24}>
          {initialValues ? (
            <Form
              scrollToFirstError
              form={form}
              name="search-map"
              layout="inline"
              onFinish={onFinish}
              onValuesChange={onValuesChange}
              validateMessages={validateMessages}
              initialValues={initialValues}
              className={classNames(classes.formWrapper)}
            >
              <FormItem
                className={classNames(classes.formItem)}
                name="state"
                label="State"
                input={(props) => <StatesSelect {...props} />}
              />

              <FormItem
                className={classNames(classes.formItem)}
                name="eo"
                label="EO Species Name"
                input={(props) => (
                  <Autocomplete
                    props={{ ...props, style: { width: 350 } }}
                    data={eosData}
                    loading={eosLoading}
                  />
                )}
                rules={[
                  {
                    required: true,
                  },
                ]}
              />

              <Item className={classNames(classes.formFooter)}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('form:showPointsOnTheMap')}
                </Button>
                <Button onClick={onClickCancel}>{t('form:clearMap')}</Button>
              </Item>
            </Form>
          ) : (
            <Spin size="large" />
          )}
        </Col>
      </Row>
      <br />
      {coordinates && (
        <CustomMap
          coordinates={coordinates}
          center={center}
          zoom={zoom}
          cancel={cancel}
          info="eo"
        />
      )}
    </PageContent>
  );
};

export default Map;
