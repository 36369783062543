import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:latitude'),
      dataIndex: 'latitude',
      key: 'latitude',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:longitude'),
      dataIndex: 'longitude',
      key: 'longitude',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:eostatus'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:seedCollected'),
      dataIndex: 'seedCollected',
      key: 'seedCollected',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:extent'),
      dataIndex: 'extent',
      key: 'extent',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:locationPrecision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      width: 70,
      sorter: true,
    },
  ];
};
