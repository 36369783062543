import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';

import PlanQATable from '../PlanQATable';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_PLAN_QA_SURVEY_15_YEAR, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const PlanQASurvey15Year = () => (
  <PlanQATable
    titleKey="PCVAdministration:PlanQASurvey15Year"
    descriptionKey="PCVAdministration:PlanQASurvey15YearDescription"
    parentFetch={(args) => fetch(args)}
  />
);

export default PlanQASurvey15Year;
