import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonumberInt'),
      dataIndex: 'eonumberInt',
      key: 'eonumberInt',
      sorter: true,
    },
    {
      title: t('table:subpopulation'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      sorter: true,
    },
    {
      title: t('table:town'),
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:lastObs'),
      dataIndex: 'lastObs',
      key: 'lastObs',
      sorter: true,
    },
    {
      title: t('table:surveyDate'),
      dataIndex: 'surveyDate',
      key: 'surveyDate',
      sorter: true,
    },
    {
      title: t('table:latestSeedColl'),
      dataIndex: 'latestSeedColl',
      key: 'latestSeedColl',
      sorter: true,
    },
    {
      title: t('table:nextSurveyYear'),
      dataIndex: 'nextSurveyYear',
      key: 'nextSurveyYear',
      sorter: true,
    },
    {
      title: t('table:nextSeedYear'),
      dataIndex: 'nextSeedYear',
      key: 'nextSeedYear',
      sorter: true,
    },
    {
      title: t('table:locationPrecision'),
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      sorter: true,
    },
    {
      title: t('table:eotype'),
      dataIndex: 'eotype',
      key: 'eotype',
      sorter: true,
    },
    {
      title: t('table:division'),
      dataIndex: 'division',
      key: 'division',
      sorter: true,
    },
  ];
};
