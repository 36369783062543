import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:firstName'),
      dataIndex: 'firstName',
      key: 'firstName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:lastName'),
      dataIndex: 'lastName',
      key: 'lastName',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:address1'),
      dataIndex: 'address1',
      key: 'address1',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:address2'),
      dataIndex: 'address2',
      key: 'address2',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:city'),
      dataIndex: 'city',
      key: 'city',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      width: 60,
      sorter: true,
    },
    {
      title: t('table:postalCode'),
      dataIndex: 'postalCode',
      key: 'postalCode',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:phone'),
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:email'),
      dataIndex: 'email',
      key: 'email',
      width: 70,
      sorter: true,
    },
    {
      title: t('table:surveyorType'),
      dataIndex: 'surveyorType',
      key: 'surveyorType',
      width: 120,
      sorter: true,
    },
    {
      title: t('table:yearLastActive'),
      dataIndex: 'yearLastActive',
      key: 'yearLastActive',
      width: 150,
      sorter: true,
    },
  ];
};
