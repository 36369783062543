import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = [
  'state',
  'eototal',
  'eodivision1',
  'eodivision2',
  'eodivision2a',
  'eodivision3a',
  'eodivision3ab',
  'eodivision3b',
  'eodivision4',
  'eodivisionInd',
  'eodivisionNone',
];

const fetchElementOccurrenceStateDivision = () =>
  APIFetch(ENDPOINT_URLS.GET_EL_OCC_STATE_DIV, {
    method: 'get',
  });

const fetchExport = () =>
  APIFetch(ENDPOINT_URLS.GET_EL_OCC_STATE_DIV_EXCEL, {
    method: 'get',
  });

export const StateDivision = () => {
  const { data, loading } = useAPI(
    () => fetchElementOccurrenceStateDivision(),
    {
      initialState: { data: [], loading: false },
    }
  );

  const tableRows = highlightTotalValue('state', data);

  return (
    <PageWithSimpleTable
      titleKey="ElementOccurrence:ElementOccurrenceStateDivision"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
      fetchExport={fetchExport}
    />
  );
};
