export const feSearchConfig = [
  {
    name: 'SurveyorName',
    label: 'Surveyor name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
];

export const beSearchConfig = [
  {
    operatortype: 'Contains',
    propertyName: 'LeadSurvName',
    equivalentPropertyName: 'SurveyorName',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Surv2Name',
    equivalentPropertyName: 'SurveyorName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Surv3Name',
    equivalentPropertyName: 'SurveyorName',
    previousSearchBlockConnectionOperator: 'Or',
  },
  {
    operatortype: 'Contains',
    propertyName: 'Surv4Name',
    equivalentPropertyName: 'SurveyorName',
    previousSearchBlockConnectionOperator: 'Or',
  },
];
