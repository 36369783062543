import React from 'react';
import { InputNumber, Input, Checkbox } from 'antd';
import moment from 'moment';

import StatesSelect from '../../../../shared/components/select/StatesSelect';
import SpeciesNamesAutocomplete from '../../../../shared/components/autocomplete/SpeciesNamesAutocomplete';
import CountiesAutocomplete from '../../../../shared/components/autocomplete/CountiesAutocomplete';
import LocationPrecisionsSelect from '../../../../shared/components/select/LocationPrecisionsSelect';
import EOStatusesSelect from '../../../../shared/components/select/EOStatusesSelect';
import TFPCVSelect from '../../../../shared/components/select/TFPCVSelect';

const minYear = 1800;
const maxYear = +moment().format('YYYY');

const dateValidationRule = (label) => ({
  type: 'number',
  validator: (_, value) => {
    const isValid = ['', undefined, null].includes(value)
      ? true
      : (+value >= minYear && +value <= maxYear) || +value === 0;

    return isValid
      ? Promise.resolve()
      : Promise.reject(
          new Error(`${label} must be between ${minYear} and ${maxYear} or 0`)
        );
  },
});

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const { TextArea } = Input;

export const formConfig = [
  {
    name: 'state',
    label: 'State',
    input: (props) => <StatesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'speciesKey',
    label: 'Species Name',
    input: (props) => <SpeciesNamesAutocomplete {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'eonumberInt',
    label: 'EO Number',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 1,
        max: 2999,
      },
      {
        required: true,
      },
    ],
  },
  {
    name: 'subpopulation',
    label: 'Subpopulation',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: -999,
        max: 999,
      },
      {
        required: true,
      },
    ],
  },
  {
    name: 'countyKey',
    label: 'County',
    input: (props) => <CountiesAutocomplete {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'town',
    label: 'Town',
    rules: [
      {
        required: true,
        max: 60,
      },
    ],
  },
  {
    name: 'siteName',
    label: 'Site Name',
    rules: [
      {
        max: 100,
      },
    ],
  },
  { name: 'topO_NAMES', label: 'Quad Name', rules: [{ max: 255 }] },
  { name: 'topO_NUMS', label: 'Quad Code', rules: [{ max: 255 }] },
  {
    name: 'ownerNotes',
    label: 'Owner Notes',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'commentsRegardingSite',
    label: 'Comments Regarding Site',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'history',
    label: 'History',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'eoid',
    label: 'EOID',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 99999,
      },
    ],
  },
  {
    name: 'latitude',
    label: 'Latitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'number',
        min: 0,
        max: 47.5,
      },
    ],
  },
  {
    name: 'longitude',
    label: 'Longitude',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'number',
        min: -74,
        max: 0,
      },
    ],
  },
  {
    name: 'locationPrecision',
    label: 'Location Precision',
    input: (props) => <LocationPrecisionsSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'eostatus',
    label: 'EO Status',
    input: (props) => <EOStatusesSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'nextSurveyYear',
    label: 'Next Survey Year',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'integer',
      },
    ],
  },
  {
    name: 'nextSeedYear',
    label: 'Next Seed Year',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'integer',
      },
    ],
  },
  {
    name: 'surveyInterval',
    label: 'Survey Interval',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'integer',
        min: -1,
        max: 30,
      },
    ],
  },
  {
    name: 'seedInterval',
    label: 'Seed Interval',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        required: true,
      },
      {
        type: 'integer',
        min: -1,
        max: 30,
      },
    ],
  },
  {
    name: 'popOrigin',
    label: 'Pop Origin',
    rules: [
      {
        max: 30,
      },
    ],
  },
  {
    name: 'directions',
    label: 'Directions',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'surveyDate',
    label: 'Survey Date',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [dateValidationRule('Survey Date')],
  },
  {
    name: 'tfpcv',
    label: 'TFPCV',
    input: (props) => <TFPCVSelect {...props} />,
    rules: [
      {
        required: true,
      },
    ],
  },
  {
    name: 'lastObs',
    label: 'Last Obs',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [dateValidationRule('Last Obs')],
  },
  {
    name: 'firstObs',
    label: 'First Obs',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [dateValidationRule('First Obs')],
  },
  {
    name: 'minElev',
    label: 'Min Elev (ft)',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  {
    name: 'maxElev',
    label: 'Max Elev (ft)',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  {
    name: 'mgtNotes',
    label: 'Mgt Notes',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'sensitive',
    label: 'Sensitive',
    rules: [
      {
        max: 10,
      },
    ],
  },
  {
    name: 'popArea',
    label: 'Pop Area (m2)',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'number',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'specimens',
    label: 'Specimens',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'eorank',
    label: 'EO Rank',
  },
  {
    name: 'eorankDate',
    label: 'EO Rank Date',
  },
  {
    name: 'subEo',
    label: 'Sub EO',
  },
  {
    name: 'parentEo',
    label: 'Parent EO',
  },
  {
    name: 'parentYn',
    label: 'Parent YN',
  },
  {
    name: 'idconfirm',
    label: 'ID Confirm',
  },
  {
    name: 'eocondition',
    label: 'EO Condition',
  },
  {
    name: 'eosize',
    label: 'EO Size',
  },
  {
    name: 'eolandscape',
    label: 'EO Landscape',
  },
  {
    name: 'eotracking',
    label: 'EO Tracking',
  },
  {
    name: 'normspecies',
    label: 'NORM Species',
  },
  {
    name: 'gisspecies',
    label: 'GIS Species',
  },
  {
    name: 'stateSpecies',
    label: 'State Species',
  },
  {
    name: 'owners',
    label: 'Owners',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'sF_LAT_LONG',
    label: 'Additional Locations',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'shape_Leng',
    label: 'Shape Leng',
  },
  {
    name: 'shape_Area',
    label: 'Shape Area',
  },
  {
    name: 'otherRef',
    label: 'Other Ref',
    input: (props) => <TextArea autoSize={{ minRows: 1 }} {...props} />,
  },
  {
    name: 'deleted',
    label: 'Mark as delete',
    valuePropName: 'checked',
    input: (props) => <Checkbox {...props} />,
  },
];
