import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../shared/components/PageContent';
import { sectionsConfig } from '../sections/config';
import Section from '../sections/Section';

const ElementOccurrenceStatusPage = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Section
        config={sectionsConfig().elementOccurrenceStatus}
        title={t('ElementOccurrenceStatus:title')}
        pageDescription={t('ElementOccurrenceStatus:description')}
        onlyTable
      />
    </PageContent>
  );
};

export default ElementOccurrenceStatusPage;
