import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const RolesSelect = (props) => {
  const { rolesData, rolesLoading, updateRoles } = useStore();

  useEffect(() => {
    if (!rolesData.length && !rolesLoading) updateRoles();
  }, []);

  return <Select props={props} data={rolesData} loading={rolesLoading} />;
};

export default RolesSelect;
