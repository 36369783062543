import React from 'react';
import { AuthContext } from './AuthContext';
import { useAuthentication } from './useAuthentication/useAuthentication';

const Auth = ({ children }) => {
  const {
    login,
    logout,
    isAuthenticated,
    agreement,
    checkingAuthentication,
    token,
    refreshToken,
    refreshedToken,
    user,
    securityAgreementAccepted,
    redirectToBaseRoute,
    setAuthState,
  } = useAuthentication();

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        agreement,
        checkingAuthentication,
        token,
        refreshToken,
        refreshedToken,
        user,
        securityAgreementAccepted,
        redirectToBaseRoute,
        setAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default Auth;
