import React, { useMemo } from 'react';
import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useLocation } from 'react-router-dom';

import PageContent from '../../../shared/components/PageContent';
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { getIsForbidden } from '../../../utils/general';
import { useAuth } from '../../../global/stores/auth';

import styles from '../../sections/Section/styles.module.scss';
import { sectionAdditionalResources } from '../../PCVAdmin/PCVInformationManagementPages/const';

const fetch = () =>
  APIFetch(ENDPOINT_URLS.GET_LINKS, {
    method: 'get',
  });

const Information = () => {
  const { pathname } = useLocation();

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const { data: fetchedData, loading } = useAPI(fetch, {
    initialState: { data: [], loading: false },
    shouldFetch: !isForbidden,
  });

  const data = useMemo(() => {
    if (
      fetchedData.findIndex(
        (section) => section.section === sectionAdditionalResources
      ) > -1
    ) {
      fetchedData.push(
        fetchedData.splice(
          fetchedData.findIndex(
            (section) => section.section === sectionAdditionalResources
          ),
          1
        )[0]
      );
    }
    return fetchedData;
  }, [fetchedData]);

  return (
    <PageContent>
      <Title level={2}>PCV and TF Workspace</Title>
      {data.length || loading
        ? data.map((i) => (
            <div className={styles.root} key={i.section}>
              <Card>
                <Title level={3}>{i.section}</Title>

                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th className={styles.tableHead}>Link</th>
                      <th className={styles.tableHead}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {i.links.map((link) => (
                      <tr key={link.linkId}>
                        <td className={styles.cell}>
                          <a href={link.link1}>{link.linkTitle}</a>
                        </td>
                        <td className={styles.secondSale}>
                          {link.description}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Card>
            </div>
          ))
        : null}
    </PageContent>
  );
};

export default Information;
