import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import { useAuth } from '../../../../global/stores/auth';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { roleTypes } from '../../../../utils/roles';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_ELEMENT_OCC_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

export const ElementOccurrenceOwner = () => {
  const { t } = useTranslation();

  const { user } = useAuth();
  const role = user?.role;

  const selectActions = [
    {
      title: t('table:select'),
      currentOwner: true,
    },
    {
      title: t('table:selectPastOwners'),
      currentOwner: false,
    }
  ];

  const staticColumns = columns();
  const allColumns = [
    {
      title: t('table:action'),
      key: 'operation',
      fixed: 'left',
      width: 200,
      render: (_, { eokey }) => (
        selectActions.map((item) => (
          <Link 
            style={{
              marginRight: 16,
            }}
            to={`/ElementOccurrence/ElementOccurrenceOwner/${eokey}?currentOwner=${item.currentOwner}`}
          >
            {item.title}
          </Link>
        ))
      ),
    },
    ...staticColumns,
  ];

  const descriptionKey = role === roleTypes.Heritage
    ? "ElementOccurrence:ElementOccurrenceOwnerDisplayDescription"
    : "ElementOccurrence:ElementOccurrenceOwnerDescription";

  return (
    <PageWithTable
      fetchData={fetch}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="ElementOccurrence:ElementOccurrenceOwner"
      descriptionKey={descriptionKey}
      searchForm={SearchForm}
      columns={allColumns}
      rowKey={(record) => record.eokey}
      scroll={{ x: 1300 }}
    />
  );
};
