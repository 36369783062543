import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import Title from 'antd/lib/typography/Title';
import { Spin } from 'antd';

import { SimpleTable } from '../../shared/components/simpleTable';

import classes from '../styles.module.scss';

const ContainerWithSimpleTable = ({
  titleKey,
  descriptionKey,
  data,
  loading,
  fields,
  rowKey,
  fullWidth,
  scroll = { x: 1300 },
  tableColumns,
  actionColumn,
  formatWithYear,
  tableInfo,
  isWithoutRowCountLabel,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {data?.length ? (
        <>
          {titleKey && <Title level={3}>{t(titleKey)}</Title>}
          {descriptionKey && <p>{t(descriptionKey)}</p>}
          <SimpleTable
            data={data}
            fields={fields}
            pagination={false}
            rowKey={rowKey}
            loading={loading}
            fullWidth={fullWidth}
            scroll={scroll}
            tableColumns={tableColumns}
            actionColumn={actionColumn}
            tableInfo={tableInfo}
            formatWithYear={formatWithYear}
          />
          {!isWithoutRowCountLabel && (
            <p className={classNames(classes.simpleTableTotal)}>
              Total:{' '}
              {data.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            </p>
          )}
        </>
      ) : null}
      {!data.length && !loading ? (
        <p
          className={classNames(
            classes.noData,
            fullWidth ? classes.noDataFullWidth : ''
          )}
        >
          {t('table:noData')}
        </p>
      ) : null}

      {!data.length && loading ? (
        <center>
          <Spin />
        </center>
      ) : null}
    </>
  );
};
export default ContainerWithSimpleTable;
