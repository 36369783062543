import React from 'react';
import { InputNumber, DatePicker, Input } from 'antd';
import moment from 'moment';

import TopoPositionsSelect from '../../../../shared/components/select/TopoPositionsSelect';
import AspectsSelect from '../../../../shared/components/select/AspectsSelect';
import LightsExistingSelect from '../../../../shared/components/select/LightsExistingSelect';
import ExcitingMoistureRegimesSelect from '../../../../shared/components/select/ExcitingMoistureRegimesSelect';

const { TextArea } = Input;

const CustomInputNumber = (props) => (
  <InputNumber
    {...props}
    style={{
      width: '100%',
    }}
  />
);

const pctValidationRules = [
  {
    type: 'integer',
    min: 0,
    max: 100,
  },
];

const currentYear = +moment().format('YYYY');

const disabledDate = (current) => {
  const tooLate = current.year() > currentYear;
  const tooEarly = current < moment().set('year', 1970).startOf('year');

  return tooEarly || tooLate;
};

export const formConfig = [
  {
    name: 'eokey',
    label: 'EO',
    input: (props) => <CustomInputNumber {...props} disabled />,
  },
  {
    name: 'surveyDate',
    label: 'Survey Date',
    rules: [
      {
        required: true,
      },
    ],
    input: ({ value, ...props }) => (
      <DatePicker
        {...props}
        value={value}
        disabledDate={disabledDate}
        format="YYYY-MM-DD"
        style={{
          width: '100%',
        }}
      />
    ),
  },
  {
    name: 'town',
    label: 'Town',
    rules: [
      {
        max: 60,
      },
    ],
  },
  {
    name: 'siteName',
    label: 'Site Name',
    rules: [
      {
        max: 100,
      },
    ],
  },
  {
    name: 'latitude',
    label: 'Latitude',
    rules: [
      {
        max: 20,
      },
    ],
  },
  {
    name: 'longitude',
    label: 'Longitude',
    rules: [
      {
        max: 20,
      },
    ],
  },
  {
    name: 'latitudeDecimalDegrees',
    label: 'LatitudeDecimalDegrees',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: 40,
        max: 47.5,
      },
    ],
  },
  {
    name: 'longitudeDecimalDegrees',
    label: 'longitudeDecimalDegrees',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: -74,
        max: -66.5,
      },
    ],
  },
  {
    name: 'minRamet',
    label: 'MinRamet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'maxRamet',
    label: 'MaxRamet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'minGenet',
    label: 'MinGenet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'maxGenet',
    label: 'MaxGenet',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'populationArea',
    label: 'PopulationArea (m2)',
    input: (props) => <CustomInputNumber {...props} step={0.00000001} />,
    rules: [
      {
        type: 'number',
        min: 0,
        max: 1000000,
      },
    ],
  },
  {
    name: 'slopeMin',
    label: 'SlopeMin',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 100,
      },
    ],
  },
  {
    name: 'slopeMax',
    label: 'SlopeMax',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 100,
      },
    ],
  },
  {
    name: 'elevationMin',
    label: 'ElevationMin (ft):',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  {
    name: 'elevationMax',
    label: 'ElevationMax (ft):',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: [
      {
        type: 'integer',
        min: 0,
        max: 6288,
      },
    ],
  },
  {
    name: 'topoPosition',
    label: 'TopoPosition',
    input: (props) => <TopoPositionsSelect {...props} />,
  },
  {
    name: 'aspect',
    label: 'Aspect',
    input: (props) => <AspectsSelect {...props} />,
  },
  {
    name: 'light',
    label: 'Light',
    input: (props) => <LightsExistingSelect {...props} />,
  },
  {
    name: 'moistureRegime',
    label: 'Moisture Regime',
    input: (props) => <ExcitingMoistureRegimesSelect {...props} />,
  },
  {
    name: 'naturalCommunity',
    label: 'Natural Community',
    rules: [
      {
        max: 60,
      },
    ],
  },
  {
    name: 'invasiveSpecies',
    label: 'Invasive Species',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'associatedSpecies',
    label: 'Associated Species',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'pctLeaf',
    label: 'Phenology: PctLeaf:',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctBud',
    label: 'Pct Bud',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctFlower',
    label: 'Pct Flower',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctImmFruit',
    label: 'Pct Immature Fruit',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctMatFruit',
    label: 'Pct Mature Fruit',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSeed',
    label: 'Pct Seed',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSenescent',
    label: 'Pct Senescent',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctDormant',
    label: 'Pct Dormant',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSeedling',
    label: 'Age Distribution: PctSeedling:',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctImmature',
    label: 'Pct Immature',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctVegSprout',
    label: 'Pct Veg Sprout',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pct1stYear',
    label: 'Pct 1st Year',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctMature',
    label: 'Pct Mature',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctSenesc',
    label: 'Pct Senescent',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'pctUnknown',
    label: 'Pct Unknown',
    input: (props) => <CustomInputNumber {...props} step={1} />,
    rules: pctValidationRules,
  },
  {
    name: 'threats',
    label: 'Threats',
  },
  {
    name: 'mgtNeeds',
    label: 'MgtNeeds',
  },
];
