import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import {
  createPdf,
  getCurrentDateSeparatedByHyphens,
  standardizePdf,
} from '../../../utils/general';

const bodyForFirstRequest = (key) => ({
  pagedInfo: {
    pageSize: 20,
    pageNumber: 1,
  },
  orderCriteria: [
    {
      orderOption: 'Ascending',
      propertyName: 'SurveyDate',
    },
  ],
  searchCriteria: [
    {
      operatortype: 'Equals',
      propertyName: 'Ffkey',
      value: `${key}`,
    },
  ],
});

const fetchAdditionalContent = (key) =>
  APIFetch(ENDPOINT_URLS.GET_HISTORY_SPROUT_PAGED_PCVTF, {
    method: 'post',
    body: JSON.stringify(bodyForFirstRequest(key)),
  });

const fetchContent = (key) =>
  APIFetch(ENDPOINT_URLS.GET_HISTORY_SPROUT_DETAILS_PCVTF(key), {
    method: 'get',
  });

export const actionContent = (record) => {
  const { t } = useTranslation();
  const { data } = useAPI(() => fetchContent(record.ffkey), {
    initialState: { data: {}, loading: false },
  });

  const { data: additionalData } = useAPI(
    () => fetchAdditionalContent(record.ffkey),
    {
      initialState: { data: {}, loading: false },
    }
  );

  const createPdfTable = () => {
    if (data?.length > 0 && additionalData?.items?.length > 0) {
      const dataFromRow = data[0];
      const {
        state,
        speciesName,
        eonumberInt,
        subpopulation,
        surveyDate,
        town,
        siteName,
      } = additionalData?.items[0];
      const year = +moment(surveyDate).format('YYYY');
      const finalData = {
        state,
        speciesName,
        eonumberInt,
        subpopulation,
        town,
        siteName,
        ...dataFromRow,
      };
      const pdfDataFields = Object.keys(finalData).map((propKey) => {
        const value = finalData[propKey];
        return {
          name: t(`table:${propKey}`),
          value,
        };
      });

      const sortedAndRenamedPdfDataFields = standardizePdf(
        [
          ...pdfDataFields,
          { name: 'Quad Name', value: record?.topO_NAMES },
          { name: 'Quad Code', value: record?.topO_NUMS },
        ],
        state
      );

      createPdf(
        sortedAndRenamedPdfDataFields,
        `${state} ${speciesName} ${eonumberInt} ${year}`,
        `${state}_${speciesName}_${eonumberInt}-survey-history-sprout-FF(${getCurrentDateSeparatedByHyphens()}).pdf`,
        true
      );
    }
  };

  return (
    <span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <a
        style={{
          marginRight: 8,
        }}
        onClick={createPdfTable}
      >
        Download
      </a>
    </span>
  );
};
