import { useTranslation } from 'react-i18next';
import { getMomentDate } from '../../../utils/general';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:surveyorType'),
      dataIndex: 'surveyorType',
      key: 'surveyorType',
      sorter: true,
    },
    {
      title: t('table:signupType'),
      dataIndex: 'signupType',
      key: 'signupType',
      sorter: true,
    },
    {
      title: t('table:startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      editable: true,
      render: (text) => getMomentDate(text).format('YYYY-MM-DD'),
    },
    {
      title: t('table:endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      editable: true,
      render: (text) => getMomentDate(text).format('YYYY-MM-DD'),
    },
  ];
};
