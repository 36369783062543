import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { Spin, Button } from 'antd';

import { useAPI } from '../../shared/hooks/use-api/useAPI';
import {
  paramsToPassInBodyRequest,
  getRandomuserParams,
  buildSearchParamsForRequest,
} from '../../shared/sortAndSearchUtils';
import { downloadExcel, getIsForbidden } from '../../utils/general';
// eslint-disable-next-line import/no-cycle
import PageContent from '../../shared/components/PageContent';
import { ExpandableEditableTable } from '../../shared/ExpandableEditableTable';
import { useAuth } from '../../global/stores/auth';

import classes from '../styles.module.scss';

const PageWithNestedTable = ({
  fetchData,
  fetchNestedData,
  fetchNestedPastData,
  fetchSubNestedData,
  fetchExport,
  defaultSortField,
  nestedTableKey,
  titleKey,
  descriptionKey,
  paramsForBE,
  beSearchConfig,
  searchForm,
  columns,
  children,
  expandableColumns,
  expandableSubColumns,
  rowKeyFunc,
  scroll,
  getNestedDataActionText = 'select',
  actions,
  actionsColWidth = 80,
  parametersForNestedTableRequest,
  isDataArray,
  updateTableData = false,
  setBreadcrumbsName,
  orderCriteria,
  setCurrentPage,
  withPageRedirect = false,
  isPastSelect = false,
  setIsPastSelect,
  deleteLoading = false,
}) => {
  const { t } = useTranslation();
  const { pathname, search: searchParams } = useLocation();

  const initialPagination = {
    pageNumber: 1,
    pageSize: 20,
  };

  const { user } = useAuth();
  const role = user?.role;

  const isForbidden = getIsForbidden(role, pathname);

  const { data, loading, refetch } = useAPI(fetchData, {
    initialState: { data: {}, loading: false },
    shouldFetch: isForbidden ? false : !searchForm,
  });

  const {
    data: nestedData,
    loading: loadingNestedData,
    refetch: refetchNestedData,
  } = useAPI(fetchNestedData, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: nestedPastData,
    loading: loadingNestedPastData,
    refetch: refetchNestedPastData,
  } = useAPI(fetchNestedPastData, {
    initialState: { data: [], loading: false },
    shouldFetch: false,
  });

  const {
    data: exportData,
    loading: exportLoading,
    refetch: refetchExport,
  } = useAPI(fetchExport, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  const [searchParameters, setSearchParameters] = useState({});
  const [pagination, setPagination] = useState(initialPagination);

  const fetch = (params = {}) => {
    const parameters = getRandomuserParams(params);
    const searchParam = paramsForBE
      ? paramsForBE(searchParameters, beSearchConfig)
      : searchParameters;

    refetch(
      paramsToPassInBodyRequest(
        parameters,
        searchForm ? searchParam : null,
        defaultSortField
      )
    );
  };

  const [rowKey, setRowKey] = useState();
  const [countRows, setCountRows] = useState();

  const setSelectedRow = (record) => {
    const key = record ? rowKeyFunc(record) : undefined;

    if (setBreadcrumbsName) {
      if (key) {
        const { state, speciesName, eonumber, subpopulation } = record;
        const breadcrumbsName = `${state} ${speciesName} ${eonumber} ${subpopulation}`;
        setBreadcrumbsName(breadcrumbsName);
      } else {
        setBreadcrumbsName();
      }
    }
    setRowKey(key);
  };

  const getNestedData = (record, id) => {
    const key = rowKeyFunc(record);
    const refetchNested = (id && id === "past") ? refetchNestedPastData : refetchNestedData;

    if (rowKey && rowKey === key) {
      setSelectedRow();
    } else {
      if (!searchForm) {
        if (record.maximumAttendees) setCountRows(record.maximumAttendees)
        refetchNested(record[nestedTableKey]);        
      }

      if (searchForm) {
        const params = {};

        if (parametersForNestedTableRequest) {
          parametersForNestedTableRequest.map((i) => {
            params[`${i[0].toUpperCase() + i.substring(1)}`] = record[i];
            return i;
          });
        } else {
          searchParameters
            .filter(({ operatortype }) => !operatortype)
            .map((param) => {
              const { propertyName: name, value } = param;

              params[name] = value;
              return param;
            });
        }

        if (setIsPastSelect) setIsPastSelect(id === "past");

        refetchNested(record[nestedTableKey], params);
      }

      setSelectedRow(record);
    }
  };

  const [paginationForRefetch, setPaginationForRefetch] =
    useState(initialPagination);

  const handleTableChange = (paginationForRequest, filters, sorter) => {
    setPaginationForRefetch(paginationForRequest);
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: paginationForRequest,
      ...filters,
    });

    setSelectedRow();
  };

  const columnsForTable = [
    {
      title: t('table:action'),
      key: 'operation',
      width: actionsColWidth,
      render: (_, record) => (
        <>
          <span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a onClick={() => getNestedData(record)}>
              {t(`table:${getNestedDataActionText}`)}
            </a>
          </span>
          {'  '}
          {actions && actions(record, getNestedData)}
        </>
      ),
    },
    ...columns,
  ];

  const onExport = () => {
    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    refetchExport(
      paramsToPassInBodyRequest(
        { pagination: initialPagination },
        paramsForBE
          ? paramsForBE(searchParamsArray, beSearchConfig)
          : searchParamsArray,
        defaultSortField
      )
    );
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      if (withPageRedirect) {
        setCurrentPage(data?.pagedInfo?.pageNumber);
      }
      setPagination({
        pageSize: data?.pagedInfo?.pageSize,
        pageNumber: data?.pagedInfo?.pageNumber,
        total: data?.totalItemsCount,
      });
    }

  }, [data]);

  useEffect(() => {
    if (Object.keys(data).length && !searchParams) {
      if (orderCriteria) {
        refetch({
          orderCriteria,
          pagedInfo: {
            pageSize: paginationForRefetch.pageSize,
            pageNumber: paginationForRefetch.current,
          },
        });
        setSelectedRow();

        return;
      }
      refetch();
      setSelectedRow();

      return;
    }

    const searchParamsArray = buildSearchParamsForRequest(searchParams);

    if (searchParamsArray.length || Object.keys(data).length) {
      refetch(
        paramsToPassInBodyRequest(
          { pagination: paginationForRefetch },
          paramsForBE
            ? paramsForBE(searchParamsArray, beSearchConfig)
            : searchParamsArray,
          defaultSortField
        )
      );

      setSearchParameters(searchParamsArray);
      setSelectedRow();
    }
  }, [searchParams, updateTableData]);

  useEffect(
    () => downloadExcel(pathname, exportData, refetchExport),
    [exportData]
  );
  
  useEffect(() => {
    if (deleteLoading && isPastSelect) refetchNestedPastData(rowKey);
  }, [deleteLoading]);

  return (
    <PageContent>
      <Title className={classNames(classes.title)} level={3}>
        {t(titleKey)}
      </Title>
      {descriptionKey && (
        <p className={classNames(classes.title)}>{t(descriptionKey)}</p>
      )}

      {searchForm && searchForm({ tableLoading: loading })}

      {children}

      {Object.keys(data).length && (data?.items?.length || data?.length) ? (
        <>
          {!!fetchExport && (
            <div className={classNames(classes.content)}>
              <Button type="primary" onClick={onExport} loading={exportLoading}>
                Export to Excel
              </Button>
            </div>
          )}
          
          <ExpandableEditableTable
            columns={columnsForTable}
            className={classNames(classes.table)}
            rowKey={rowKeyFunc || uuidv4()}
            dataSource={isDataArray ? data : data.items}
            loading={loading}
            onChange={handleTableChange}
            pagination={{
              pageSizeOptions: [20, 50, 100],
              current: pagination.pageNumber,
              pageSize: pagination.pageSize,
              total: pagination.total,
            }}
            scroll={scroll}
            data={
              isPastSelect
                ? nestedPastData
                : nestedData?.items || nestedData
            }
            expandableColumns={expandableColumns}
            expandableSubColumns={expandableSubColumns}
            expandableKey={rowKey ? [rowKey] : []}
            loadingNestedData={
              isPastSelect
                ? loadingNestedPastData || deleteLoading
                : loadingNestedData
            }
            length={countRows}
            fetchSubNestedData={fetchSubNestedData}
          />
          <p className={classNames(classes.tableTotal)}>
            Total:{' '}
            {(data?.length || data?.totalItemsCount)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          </p>
        </>
      ) : null}
      {(
        isDataArray
          ? data?.length === 0
          : Object.keys(data)?.length && !data?.items?.length
      ) ? (
        <p className={classNames(classes.noData)}>{t('table:noData')}</p>
      ) : null}
      {!Object.keys(data).length && loading ? (
        <center>
          <Spin />
        </center>
      ) : null}
    </PageContent>
  );
};

export default PageWithNestedTable;
