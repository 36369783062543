import React from 'react';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { APIFetch } from '../../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../../global/api';
import ValidationData from '../../../ValidationData/ValidationDataPages/ValidationData';
import { paramsForBE } from '../../../../utils/general';

import { columns } from './TableColumns';
import SearchForm from './SearchForm';
import { beSearchConfig } from './form-config';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_SURVEYORS_PAGED_NEW_USER, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchDelete = (id) =>
  id
    ? APIFetch(ENDPOINT_URLS.DELETE_NEW_USER(id), {
        method: 'put',
      })
    : null;

const Users = () => {
  const { t } = useTranslation();

  const actionColumn = (refetchDelete) => ({
    title: t('table:action'),
    key: 'operation',
    fixed: 'left',
    width: 120,
    render: (_, record) => (
      <>
        <Popconfirm
          placement="top"
          title="Remove this user?"
          onConfirm={() => refetchDelete(record?.id)}
          okText="Yes"
          cancelText="No"
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a
            style={{
              marginRight: 8,
            }}
          >
            {t('table:removeUser')}
          </a>
        </Popconfirm>
      </>
    ),
  });

  return (
    <ValidationData
      pageKey="Users"
      addBtnTxt="Add New User"
      fetch={fetch}
      defaultSortField={['LastName', 'FirstName']}
      columns={columns()}
      actionColumn={actionColumn}
      pageName="Users"
      notValidationData="PCVAdministration"
      searchForm={SearchForm}
      beSearchConfig={beSearchConfig}
      paramsForBE={paramsForBE}
      fetchDelete={fetchDelete}
    />
  );
};

export default Users;
