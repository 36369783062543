import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

export const feSearchConfig = [
  {
    name: 'SpeciesName',
    label: 'Species name',
    operatortype: 'Contains',
    rules: [
      {
        required: true,
      },
    ],
  },
];

export const beSearchConfig = [
  {
    propertyName: 'SpeciesName',
    operatortype: 'Contains',
  },
  {
    propertyName: 'Synonyms',
    operatortype: 'Contains',
    equivalentPropertyName: 'SpeciesName',
    previousSearchBlockConnectionOperator: 'Or',
  },
];

export const editableCellsConfig = [
  {
    name: 'phenologyNote',
    rules: [
      {
        max: 250,
      },
    ],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'phenologyMemo',
    rules: [
      {
        max: 2000,
      },
    ],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'nptstatus',
    rules: [
      {
        max: 10,
      },
    ],
  },
  {
    name: 'comment',
    rules: [
      {
        max: 250,
      },
    ],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'propaguleType',
    rules: [{ max: 60 }],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'seedStorageBehavior',
    rules: [{ max: 60 }],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'collection',
    rules: [{ max: 50 }],
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
  {
    name: 'seedNotes',
    input: (props) => <TextArea autoSize={{ minRows: 3 }} {...props} />,
  },
];
