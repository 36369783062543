import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

export const columns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('table:nextSurveyYear'),
      width: 150,
      dataIndex: 'nextSurveyYear',
      key: 'nextSurveyYear',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:nextSeedYear'),
      width: 150,
      dataIndex: 'nextSeedYear',
      key: 'nextSeedYear',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:surveyInterval'),
      dataIndex: 'surveyInterval',
      key: 'surveyInterval',
      width: 130,
      sorter: true,
      editable: true,
    },
    {
      title: t('table:seedInterval'),
      dataIndex: 'seedInterval',
      key: 'seedInterval',
      width: 130,
      editable: true,
      sorter: true,
    },
    {
      title: t('table:surveyComment'),
      width: 130,
      dataIndex: 'surveyComment',
      key: 'surveyComment',
      sorter: true,
      editable: true,
      onCell: () => ({
        style: {
          whiteSpace: 'nowrap',
          maxWidth: 130,
        },
      }),
      render: (text) => (
        // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-filename-extension
        <Tooltip
          title={text}
          overlayInnerStyle={{
            minWidth: 200,
            minHeight: 50,
            maxWidth: 400,
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {/* eslint-disable-next-line react/react-in-jsx-scope */}
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('table:tfpcv'),
      width: 100,
      dataIndex: 'tfpcv',
      key: 'tfpcv',
      sorter: true,
      editable: true,
    },
    {
      title: t('table:state'),
      width: 100,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: t('table:speciesName'),
      width: 200,
      dataIndex: 'speciesName',
      key: 'speciesName',
      sorter: true,
    },
    {
      title: t('table:eonbr'),
      dataIndex: 'eonumber',
      key: 'eonumber',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:subpop'),
      dataIndex: 'subpopulation',
      key: 'subpopulation',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:eoid'),
      width: 100,
      dataIndex: 'eoid',
      key: 'eoid',
      sorter: true,
    },
    {
      title: t('table:town'),
      width: 200,
      dataIndex: 'town',
      key: 'town',
      sorter: true,
    },
    {
      title: t('table:siteName'),
      dataIndex: 'siteName',
      key: 'siteName',
      width: 200,
      sorter: true,
    },
    {
      title: t('table:status'),
      dataIndex: 'eostatus',
      key: 'eostatus',
      width: 150,
      sorter: true,
    },
    {
      title: t('table:eotype'),
      width: 100,
      dataIndex: 'eotype',
      key: 'eotype',
      sorter: true,
    },
    {
      title: t('table:lastSurvey'),
      width: 120,
      dataIndex: 'latestFieldForm',
      key: 'latestFieldForm',
      sorter: true,
    },
    {
      title: t('table:lastSeed'),
      dataIndex: 'latestSeedColl',
      key: 'latestSeedColl',
      width: 110,
      sorter: true,
    },
    {
      title: t('table:lastObs'),
      dataIndex: 'latestObserved',
      key: 'latestObserved',
      width: 110,
      sorter: true,
    },
    {
      title: t('table:nbrSurvey'),
      width: 120,
      dataIndex: 'nbrPastSurveys',
      key: 'nbrPastSurveys',
      sorter: true,
    },
    {
      title: t('table:evYear'),
      width: 100,
      dataIndex: 'latestEventYear',
      key: 'latestEventYear',
      sorter: true,
    },
    {
      title: t('table:latestEvent'),
      dataIndex: 'latestEvent',
      key: 'latestEvent',
      width: 150,
      sorter: true,
    },
    {
      title: t('table:div'),
      dataIndex: 'division',
      key: 'division',
      width: 100,
      sorter: true,
    },
    {
      title: t('table:precision'),
      width: 100,
      dataIndex: 'locationPrecision',
      key: 'locationPrecision',
      sorter: true,
    },
    {
      title: t('table:srank'),
      width: 100,
      dataIndex: 'stateRank',
      key: 'stateRank',
      sorter: true,
    },
    {
      title: t('table:maxExtent'),
      dataIndex: 'extent',
      key: 'extent',
      width: 150,
      sorter: true,
    },
    {
      title: t('table:latestGenet'),
      dataIndex: 'latestGenet',
      key: 'latestGenet',
      width: 150,
      sorter: true,
    },
    {
      title: t('table:latestRamet'),
      dataIndex: 'latestRamet',
      key: 'latestRamet',
      width: 150,
      sorter: true,
    },
    {
      title: t('table:maxGenet'),
      width: 100,
      dataIndex: 'maxGenet',
      key: 'maxGenet',
      sorter: true,
    },
    {
      title: t('table:maxRamet'),
      width: 100,
      dataIndex: 'maxRamet',
      key: 'maxRamet',
      sorter: true,
    },
  ];
};
