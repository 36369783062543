import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
import { paramsForBE } from '../../../utils/general';
import PageWithTable from '../../pages/PageWithTable';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { beSearchConfig } from './form-config';

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_EOS_WITH_DATA_ERRORS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const fetchExport = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_EOS_WITH_DATA_ERRORS_EXCEL, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : null;

export const EOsWithDataErrors = () => (
  <PageWithTable
    fetchData={fetch}
    fetchExport={fetchExport}
    defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
    titleKey="ElementOccurrence:EOsWithDataErrors"
    descriptionKey="ElementOccurrence:EOsWithDataErrorsDescription"
    searchForm={SearchForm}
    columns={columns()}
    rowKey={(record) => record.eokey}
    scroll={{ x: 1300 }}
    beSearchConfig={beSearchConfig}
    paramsForBE={paramsForBE}
  />
);
