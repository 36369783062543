import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Form } from 'antd';
import classNames from 'clsx';
import Title from 'antd/lib/typography/Title';

// eslint-disable-next-line import/no-cycle
import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { ENDPOINT_URLS } from '../../../global/api';
// eslint-disable-next-line import/no-cycle
import {
  openNotificationWithIcon,
  validateMessages,
} from '../../../utils/general';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
// eslint-disable-next-line import/no-cycle
import PageWithTable from '../../pages/PageWithTable';
import FormItem from '../../../shared/components/formItem';

import SearchForm from './SearchForm';
import { columns } from './TableColumns';
import { subFormConfig } from './form-config';

import classes from '../../styles.module.scss';

const { Item } = Form;

const fetchUpdate = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.UPDATE_TRANSCRIBTIONS, {
        method: 'put',
        body: JSON.stringify(args),
      })
    : null;

const fetch = (args) =>
  args
    ? APIFetch(ENDPOINT_URLS.GET_TRANSCRIBTIONS_PAGED, {
        method: 'post',
        body: JSON.stringify(args),
      })
    : {};

const TranscriptionManagement = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isForceRefetch, setIsForceRefetch] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { data, loading, refetch } = useAPI(fetchUpdate, {
    initialState: { loading: false },
    shouldFetch: false,
  });

  useEffect(() => {
    if (data) {
      if (data.length) {
        form.resetFields();

        setSelectedRowKeys([]);
        setIsForceRefetch(true);

        openNotificationWithIcon('success', 'Data was updated successfully');
      } else {
        openNotificationWithIcon('error', 'Failed update Data');
      }
    }
  }, [data]);

  useEffect(() => {
    if (isForceRefetch) {
      setIsForceRefetch(false);
    }
  }, [isForceRefetch]);

  const onSelectChange = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  const onFinish = (values) => {
    const { Transcriber } = values;

    refetch(
      selectedRowKeys.map((row) => ({
        eokey: row,
        transcriber: +Transcriber,
      }))
    );
  };

  const resetOnAction = () => {
    setSelectedRowKeys([]);
    form.resetFields();
  };

  const checkFetchParams = (params) => {
    const { sortField, ...rest } = params;

    return {
      ...rest,
      sortField:
        sortField === 'transcriber'
          ? ['TranscriberFirstName', 'TranscriberLastName']
          : sortField,
    };
  };

  return (
    <PageWithTable
      fetchData={fetch}
      defaultSortField={['State', 'SpeciesName', 'Eonumber', 'Subpopulation']}
      titleKey="PCVAdmin:TranscriptionManagement"
      descriptionKey="PCVAdmin:TranscriptionManagementDescription"
      columns={columns()}
      scroll={{ x: 1300 }}
      isForceRefetch={isForceRefetch}
      searchForm={SearchForm}
      rowKey={(record) => record.eokey}
      isDataObject
      showChildrenWithTable
      rowSelection={{
        selectedRowKeys,
        onChange: onSelectChange,
      }}
      resetOnAction={resetOnAction}
      checkFetchParams={checkFetchParams}
    >
      <Title className={classNames(classes.title)} level={4}>
        {t('PCVAdmin:TranscriptionManagementSubTitle')}
      </Title>
      <p className={classNames(classes.title)}>
        {t('PCVAdmin:TranscriptionManagementSubDescription')}
      </p>
      <Row className={classNames(classes.title)}>
        <Col span={24}>
          <Form
            form={form}
            name="search-form"
            layout="inline"
            onFinish={onFinish}
            initialValues={{
              Transcriber: '0',
              TranscriptionDone: 'No',
            }}
            validateMessages={validateMessages}
            validateTrigger={['onBlur', 'onChange']}
            className="search-form"
          >
            {subFormConfig.map(
              ({ name, label, input, operatortype, ...rest }) => (
                <FormItem
                  key={name}
                  name={name}
                  label={label}
                  input={input}
                  {...rest}
                />
              )
            )}

            <Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!selectedRowKeys.length}
              >
                Apply
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </PageWithTable>
  );
};

export default TranscriptionManagement;
