import React from 'react';
import { Table } from 'antd';
import classNames from 'clsx';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAPI } from '../../shared/hooks/use-api/useAPI';

import classes from '../styles.module.scss';

const PageWithTableByQueryKey = ({ fetchData, queryKey, columns, rowKey }) => {
  const { t } = useTranslation();
  const { search: searchKey } = useLocation();

  const query = new URLSearchParams(searchKey);
  const keyFromUrl = query.getAll(queryKey);

  const { data, loading } = useAPI(() => fetchData(keyFromUrl[0]), {
    initialState: { data: [], loading: false },
  });

  return (
    <div>
      {data.length || loading ? (
        <Table
          columns={columns}
          rowKey={rowKey}
          className={classNames(classes.table)}
          dataSource={data}
          loading={loading}
          pagination={false}
        />
      ) : null}
      {!data.length && !loading ? (
        <p className={classNames(classes.noData)}>{t('table:noData')}</p>
      ) : null}
    </div>
  );
};

export default PageWithTableByQueryKey;
