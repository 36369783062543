import React from 'react';
import Title from 'antd/lib/typography/Title';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';

import classes from '../../styles.module.scss';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <PageContent withBreadcrumb={false}>
      <Title className={classNames(classes.title)} level={3}>
        {t(`errorsPages:pageNotFound`)}
      </Title>
      <p className={classNames(classes.title)}>
        {t(`errorsPages:pageNotFoundDescription`)}
      </p>
    </PageContent>
  );
};

export default PageNotFound;
