import React, { useEffect } from 'react';

import { useStore } from '../../../global/stores/store/StoreContext';

import { Select } from './Select';

const ManagementTypesSelect = (props) => {
  const { managementTypesData, managementTypesLoading, updateManagementTypes } =
    useStore();

  useEffect(() => {
    if (!managementTypesData.length && !managementTypesLoading)
      updateManagementTypes();
  }, []);

  return (
    <Select
      props={props}
      width={200}
      data={managementTypesData}
      loading={managementTypesLoading}
    />
  );
};

export default ManagementTypesSelect;
