import React from 'react';

import { APIFetch } from '../../../shared/services/api-fetch/APIFetch';
import { useAPI } from '../../../shared/hooks/use-api/useAPI';
import { ENDPOINT_URLS } from '../../../global/api';
import { highlightTotalValue } from '../../../utils/general';

import PageWithSimpleTable from '../../pages/PageWithSimpleTable';

const fields = ['recognizable', 'flower', 'fruit', 'seedCollection'];

const fetchSpeciesPhenology = () =>
  APIFetch(ENDPOINT_URLS.GET_SPECIES_PHENOLOGY, {
    method: 'get',
  });

export const SpeciesPhenology = () => {
  const { data, loading } = useAPI(() => fetchSpeciesPhenology(), {
    initialState: { data: [], loading: false },
  });

  const tableRows = highlightTotalValue('recognizable', data);

  return (
    <PageWithSimpleTable
      titleKey="Species:SpeciesPhenology"
      data={tableRows}
      loading={loading}
      fields={fields}
      rowKey={(record) => record.state}
    />
  );
};
