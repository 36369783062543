import Text from 'antd/lib/typography/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PageContent from '../../../shared/components/PageContent';
import { sectionsConfig } from '../config';
import Section from '../Section';

import styles from './styles.module.scss';

const PlantsStatusPage = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Section
        config={sectionsConfig().plantsStatus}
        title={t('sections:PlantsStatus:title')}
      >
        <Text strong className={styles.subtitle}>
          {t('sections:PlantsStatus:subtitle')}
        </Text>
      </Section>
    </PageContent>
  );
};

export default PlantsStatusPage;
