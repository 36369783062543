import { useTranslation } from 'react-i18next';

export const columns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('table:state'),
      dataIndex: 'state',
      key: 'state',
      render: (text) => text || 'All',
      sorter: true,
    },
    {
      title: t('table:announcementTitle'),
      dataIndex: 'announcementTitle',
      key: 'announcementTitle',
      sorter: true,
    },
    {
      title: t('table:announcementText'),
      dataIndex: 'announcementText',
      key: 'announcementText',
      sorter: true,
    },
    {
      title: t('table:surveyorType'),
      dataIndex: 'surveyorType',
      key: 'surveyorType',
      sorter: true,
    },
  ];
};
